import type { FC } from 'react';
import { useState } from 'react';

import { Button, Modal } from '@zen/DesignSystem';
import NewIssue from '@zen/Issues/NewIssue';
import type { IssueAssociatedObject } from '@zen/Issues/types';
import useTracking from '@zen/utils/hooks/useTracking';

interface Props {
  associatedObjectId?: string;
  associatedObjectReference: string;
  associatedType: IssueAssociatedObject;
  canEditIssueVisibility: boolean;
  onIssueChange?: () => void;
}

const AddIssueButton: FC<Props> = (props) => {
  const { canEditIssueVisibility, associatedType, associatedObjectReference, associatedObjectId, onIssueChange } = props;
  const [isIssueFormOpened, setIsIssueFormOpened] = useState<boolean>(false);
  const { trackEvent } = useTracking();

  const handleIssueModalClose = (): void => {
    setIsIssueFormOpened(false);
  };

  const handleIssueModalOpen = (): void => {
    trackEvent({
      action: 'CreateIssue',
      category: 'Issues',
      label: 'OpenForm'
    });

    setIsIssueFormOpened(true);
  };

  const associatedId: string = associatedObjectId || associatedObjectReference;

  return (
    <>
      <Button iconLeft="zicon-add" onClick={handleIssueModalOpen} size="compact" variant="secondary">
        Add an issue
      </Button>
      <Modal
        data-tracking-name="new-issue-modal"
        isOpen={isIssueFormOpened}
        onClose={handleIssueModalClose}
        tagline={associatedObjectReference || associatedId}
        title="New issue"
      >
        <NewIssue
          associatedId={associatedId}
          associatedType={associatedType}
          canEditVisibility={canEditIssueVisibility}
          onCancel={handleIssueModalClose}
          onIssueChange={onIssueChange}
          onSuccess={handleIssueModalClose}
        />
      </Modal>
    </>
  );
};

export default AddIssueButton;
