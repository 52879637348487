import type { FC } from 'react';
import { useState } from 'react';

import { Button } from '@zen/DesignSystem';

import NonSubscriberList from '../NonSubscriberList';
import SubscriberList from '../SubscriberList';
import SubscriptionButton from '../SubscriptionButton';
import type { Stakeholder, Subscriber, SubscriptionStakeholder } from '../types';

interface Props {
  currentUser?: SubscriptionStakeholder;
  isCurrentUserSubscribed: boolean;
  onSubscribe: (stakeholder: SubscriptionStakeholder) => void;
  onUnsubscribe: (stakeholder: SubscriptionStakeholder) => void;
  stakeholders: Stakeholder[];
  subscribers: Subscriber[];
}

const SubscriptionsMenu: FC<Props> = (props) => {
  const { currentUser, stakeholders, onSubscribe, onUnsubscribe, subscribers, isCurrentUserSubscribed } = props;
  const [isSubscribeMode, setIsSubscribeMode] = useState<boolean>(false);

  const renderContent = () => {
    if (isSubscribeMode) {
      return <NonSubscriberList onSubscribe={onSubscribe} stakeholders={stakeholders} />;
    }

    return <SubscriberList onUnsubscribe={onUnsubscribe} subscribers={subscribers} />;
  };

  const renderButtons = () => {
    if (isSubscribeMode) {
      return <Button onClick={() => setIsSubscribeMode(false)}>Done</Button>;
    }

    return (
      <>
        <Button onClick={() => setIsSubscribeMode(true)}>Add</Button>
        {currentUser && (
          <SubscriptionButton
            onSubscribe={onSubscribe}
            onUnsubscribe={onUnsubscribe}
            stakeholder={currentUser}
            subscribed={isCurrentUserSubscribed}
          />
        )}
      </>
    );
  };

  return (
    <div
      className="flex flex-col bg-white border border-solid rounded shadow-popover min-w-64 max-w-64 border-grey-lighter"
      data-testid="subscriptions-menu"
    >
      {renderContent()}
      <div className="flex justify-center py-6 space-x-3 border-t border-solid border-grey-lighter">{renderButtons()}</div>
    </div>
  );
};

export default SubscriptionsMenu;
