import { bookingModeOfTransportLabelMapping } from '@zen/types';

import type { ModeOfTransportOption } from './types';
import { ModeOfTransport } from './types';

export const modeOfTransportOptions: ModeOfTransportOption[] = [
  { label: bookingModeOfTransportLabelMapping[ModeOfTransport.TRUCK], value: ModeOfTransport.TRUCK },
  { label: bookingModeOfTransportLabelMapping[ModeOfTransport.OCEAN], value: ModeOfTransport.OCEAN },
  { label: bookingModeOfTransportLabelMapping[ModeOfTransport.AIR], value: ModeOfTransport.AIR },
  { label: bookingModeOfTransportLabelMapping[ModeOfTransport.RAIL], value: ModeOfTransport.RAIL }
];
