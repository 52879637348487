import type { FC, ReactNode } from 'react';

import { ChargeTypeEnum } from '@zen/Accruals/ChargeTypes/types';
import type { FormInstance } from '@zen/Components';
import { Form, FormButtons, FormChargeTypeSelect, FormCurrencyInput } from '@zen/Components';
import FormIncotermSelect from '@zen/Components/Form/FormIncotermSelect';
import FormTerminalsSelect from '@zen/Components/Form/FormTerminalsSelect';
import LabelledValue from '@zen/Components/LabelledValue';
import { Banner, Button } from '@zen/DesignSystem';
import {
  formatChargeBasis,
  formatChargeType,
  formatPort,
  formatUnit
} from '@zen/RateCards/LegacyRateCards/utils/tableDataFormatting';
import { Currency, TerminalTypeValue } from '@zen/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { CentralPortCharge } from '../../types';
import { getValidationSchema } from './chargeForm.validation';
import { prepareInitialValues } from './helpers';
import type { ChargeFormInitialValues, ChargeFormValues } from './types';

interface Props {
  initialValues?: CentralPortCharge;
  onCancel?: () => void;
  onSubmit: (values: ChargeFormValues) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
  type: 'origin' | 'destination';
}

const defaultInitialValues: ChargeFormInitialValues = {
  chargeType: null,
  price: {
    value: null,
    currency: Currency.USD
  },
  incoterms: null,
  port: null
};

const ChargeForm: FC<Props> = ({ onSubmit, initialValues, onSuccess, type, onCancel }) => {
  const chargeType: ChargeTypeEnum = type === 'origin' ? ChargeTypeEnum.ORIGIN : ChargeTypeEnum.DESTINATION;
  const portLabel: string = type === 'origin' ? 'Port of origin' : 'Port of destination';
  const hasInitialValues: boolean = !!initialValues;
  const submitButtonLabel: string = hasInitialValues ? 'Update charge' : 'Save charge';

  const initialFormValues: ChargeFormInitialValues = initialValues ? prepareInitialValues(initialValues) : defaultInitialValues;

  const renderFormButtons = ({ isSubmitting }: FormInstance<ChargeFormValues>) => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text={submitButtonLabel}>
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  const renderFormFields = (values: ChargeFormValues): ReactNode => (
    <>
      <div className="mb-6 mt-2">
        <Banner
          icon="zicon-alert"
          message="If there is an active charge with the same details you're trying to add, we will deactivate this charge and replace it with the charge you are currently adding."
        />
      </div>
      <FormChargeTypeSelect
        disabled={hasInitialValues}
        isClearable={true}
        label="Charge type"
        name="chargeType"
        renderMenuInPortal={true}
        type={chargeType}
      />
      <div className="grid grid-cols-2 gap-x-4 mb-6" data-testid="form-meta-data">
        <LabelledValue label="Charge basis">{formatChargeBasis(values.chargeType)}</LabelledValue>
        <LabelledValue label="Unit">{formatUnit(values.chargeType)}</LabelledValue>
      </div>
      <FormTerminalsSelect
        isClearable={true}
        isDisabled={hasInitialValues}
        label={portLabel}
        name="port"
        renderMenuInPortal={true}
        terminalTypes={[TerminalTypeValue.SEAPORT]}
      />
    </>
  );

  const renderMetaData = (values: ChargeFormValues): ReactNode => (
    <div className="grid grid-cols-4 gap-x-4 mb-6" data-testid="form-meta-data">
      <LabelledValue label={portLabel}>{formatPort(values.port)}</LabelledValue>
      <LabelledValue label="Charge type">{formatChargeType(values.chargeType)}</LabelledValue>
      <LabelledValue label="Charge basis">{formatChargeBasis(values.chargeType)}</LabelledValue>
      <LabelledValue label="Unit">{formatUnit(values.chargeType)}</LabelledValue>
    </div>
  );

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={getValidationSchema(type)}
    >
      {({ values }: FormInstance<ChargeFormValues>) => (
        <>
          {hasInitialValues ? renderMetaData(values) : renderFormFields(values)}
          <div className="grid grid-cols-2 gap-x-4">
            <FormIncotermSelect
              isMulti={true}
              label="Incoterms"
              name="incoterms"
              renderMenuInPortal={true}
              showIncotermsExplanation={false}
            />
            <FormCurrencyInput label="Price" name="price" />
          </div>
        </>
      )}
    </Form>
  );
};

export default ChargeForm;
