import type { ObjectSchema } from 'yup';
import { number, object, string } from 'yup';

import { LooseCargoTypeEnum, type ModeOfTransport } from '@zen/types';
import type { Nullable } from '@zen/utils/typescript';

import { dimensionsValidator, quantityValidator } from '../../cargo.validation';

const grossWeightMetricValidator = number().nullable().positive('Must be greater than 0.');
const grossWeightValueValidator = string().nullable();

export const getLooseCargoFormValidationSchema = (
  modeOfTransport: Nullable<ModeOfTransport>,
  isDimensionsAndWeightMandatory: boolean = false
): ObjectSchema<{}> =>
  object().shape({
    loose: getLooseCargoFieldsValidation(modeOfTransport, isDimensionsAndWeightMandatory)
  });

export const getLooseCargoFieldsValidation = (
  modeOfTransport: Nullable<ModeOfTransport>,
  isDimensionsAndWeightMandatory: boolean = false
) =>
  object().shape({
    dimensions: object()
      .when('type', {
        is: (val: Nullable<LooseCargoTypeEnum>) => val !== LooseCargoTypeEnum.ASSORTED_CARGO,
        then: () => dimensionsValidator(modeOfTransport, isDimensionsAndWeightMandatory).nullable()
      })
      .nullable(),
    grossWeight: object().shape({
      ...(isDimensionsAndWeightMandatory
        ? {
            metric: grossWeightValueValidator.required('Please select unit.'),
            value: grossWeightMetricValidator.required('Please enter gross weight.')
          }
        : {
            metric: grossWeightValueValidator,
            value: grossWeightMetricValidator
          })
    }),
    chargeableWeight: object().shape({
      value: number().nullable().positive('Must be greater than 0.')
    }),
    type: string().nullable().required('Please select cargo variant.'),
    value: string()
      .nullable()
      .when('type', {
        is: (val: Nullable<LooseCargoTypeEnum>) => val === LooseCargoTypeEnum.PALLETS,
        then: () => string().nullable().required('Please select pallet type.')
      }),
    quantity: number().when('type', {
      is: (val: Nullable<LooseCargoTypeEnum>) => val !== LooseCargoTypeEnum.ASSORTED_CARGO,
      then: () => quantityValidator()
    }),
    volume: number()
      .nullable()
      .positive('Must be greater than 0.')
      .when('type', {
        is: (val: Nullable<LooseCargoTypeEnum>) => val === LooseCargoTypeEnum.ASSORTED_CARGO,
        then: () => number().nullable().positive('Must be greater than 0.').required('Please enter volume.')
      })
  });
