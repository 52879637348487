import pluralize from 'pluralize';
import type { FC, SyntheticEvent } from 'react';
import { useContext, useEffect, useState } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import ChevronIcon from '@zen/Components/Icons/ChevronIcon';
import Slideout, { SlideoutHeader } from '@zen/Components/Slideout';
import { Button, Checkbox } from '@zen/DesignSystem';
import LotForm from '@zen/Orders/OrderDetails/LotForm';
import UpdateCRDContext from '@zen/Orders/OrderDetails/updateCRDContext';
import type { OrderDetailsLineItem, OrderLot, UpdateLotsInput } from '@zen/Orders/types';
import { getQuantityFulfilled, getTotalCbm } from '@zen/Orders/utils';
import { productRoutes } from '@zen/Routes';
import type { LocationDescriptor } from '@zen/types';
import { Currency } from '@zen/types';
import { getToday } from '@zen/utils/date';
import { formatNumber } from '@zen/utils/formatting';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import Lot from './Lot';
import type { LotPayload } from './Lot/Lot';
import ProductName from './ProductName';

interface Props {
  isExpanded: boolean;
  item: OrderDetailsLineItem;
  onFulfimentSubmit: (values: UpdateLotsInput) => Promise<IOkOrErrorResult>;
  onUpdateSchedule: (lot: LotPayload) => void;
}

const defaultPrice = {
  currency: Currency.GBP,
  value: 0
};

const getInitialValues = (lots: OrderLot[], orderedLineItemId: string) => ({
  orderedLineItemId,
  lots: lots.map(({ id, cbm, quantityFulfilled }) => ({ id, cbm, quantityFulfilled }))
});

const LineItem: FC<Props> = ({ item, isExpanded, onUpdateSchedule, onFulfimentSubmit }) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const { displayMask, isSelected, selectLots } = useContext(UpdateCRDContext);
  const { check } = useGlobalPermissions();
  const canAccessProductsRoute = check('products.canAccessRoute');
  const { id, cbm, product, lots } = item;
  // This needs to be changed on the backend as you can not create a PO without quantity.
  const quantityOrdered: number = item.quantityOrdered ? item.quantityOrdered : 0;
  const productId = product?.id || '';
  const name = product?.name;
  const skuCode = product?.skuCode;
  const costPrice = product?.costPrice;
  const lotIds = lots.map((lot) => lot.id);
  const isChecked = lotIds.every(isSelected);
  const totalCbm = getTotalCbm<OrderLot>(lots);
  const orderDetailsRoute = {
    pathname: productRoutes.orderDetails.getUrl(productId),
    search: `?startDate=${getToday()}&period=4`
  };
  const productUrl: LocationDescriptor | undefined = canAccessProductsRoute ? orderDetailsRoute : undefined;

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const renderLotForm = () => {
    return (
      <Slideout className="!top-0" onOutsideClick={() => setIsFormOpen(false)} overlay={true} show={isFormOpen}>
        <SlideoutHeader onClose={() => setIsFormOpen(false)} title="Update/add a new lot" />
        <div className="h-[calc(100vh-8rem)] px-6 pt-6 pb-20 overflow-y-scroll w-160">
          <LotForm
            initialValues={getInitialValues(lots, id)}
            onSubmit={onFulfimentSubmit}
            onSuccess={() => {
              setIsFormOpen(false);
            }}
            productPrice={costPrice || defaultPrice}
            quantityOrdered={quantityOrdered}
          />
        </div>
      </Slideout>
    );
  };

  const handleSelect = (e: SyntheticEvent) => {
    e.stopPropagation();

    selectLots(lotIds);
  };

  const topTextClassName: string = 'font-medium group-hover:font-bold text-grey-dark text-lg xl:text-sm';
  const bottomTextClassName: string = 'group-hover:font-bold text-grey-base text-base xl:text-xs pt-3';

  return (
    <div>
      <div className="flex cursor-pointer group hover:bg-azure-lightest" onClick={() => setExpanded(!expanded)}>
        <div className="flex items-center justify-center w-16 py-4">
          {displayMask && <Checkbox checked={isChecked} onChange={handleSelect} />}
          {!displayMask && <ChevronIcon expanded={expanded} />}
        </div>
        <div className="flex-[2.5] py-4">
          <p className={topTextClassName}>
            <ProductName url={productUrl}>{name}</ProductName>
          </p>
          <p className={bottomTextClassName}>SKUs code: {skuCode}</p>
        </div>
        <div className="flex-1 py-4">
          <p className={topTextClassName}>
            {totalCbm ? formatNumber(totalCbm) : '-'}/{cbm ? formatNumber(cbm) : '-'}
          </p>
          <p className={bottomTextClassName}> </p>
        </div>
        <div className="flex-1 py-4">
          <p className={topTextClassName}>
            {formatNumber(getQuantityFulfilled(lots))}/{formatNumber(quantityOrdered)}
          </p>
          <p className={bottomTextClassName}>
            Made of {lots.length} {pluralize('lot', lots.length)}
          </p>
        </div>
      </div>
      {expanded && (
        <div className="mb-4">
          <div className="mb-4" data-testid="lots">
            {item.lots.map((lot, i) => (
              <Lot
                key={i}
                index={i + 1}
                lot={lot}
                lotCount={lots.length}
                onUpdateSchedule={onUpdateSchedule}
                quantityOrdered={quantityOrdered}
              />
            ))}
          </div>
          <div className="px-4 mb-6">
            <Button
              className="w-full"
              disabled={displayMask}
              iconLeft="zicon-add"
              onClick={() => setIsFormOpen(true)}
              variant="secondary"
            >
              Update/add a new lot
            </Button>
          </div>
        </div>
      )}
      {renderLotForm()}
    </div>
  );
};

export default LineItem;
