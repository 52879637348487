import type { FC } from 'react';

import ActivityFeedDates from '@zen/ActivityFeed/components/ActivityFeedDates';
import ActivityFeedItemDetails from '@zen/ActivityFeed/components/ActivityFeedItemDetails';
import ActivityFeedItemIcon from '@zen/ActivityFeed/components/ActivityFeedItemIcon';
import ActivityFeedItemWrapper from '@zen/ActivityFeed/components/ActivityFeedItemWrapper';
import type { Activity, DeliveryItemType, PurchaseOrderDeliveryEstimatesMetadataType } from '@zen/ActivityFeed/types';

export interface Props {
  activityMetadata: Activity;
  deliveryItem: DeliveryItemType;
}

const DeliveryItem: FC<Props> = ({ activityMetadata, deliveryItem }) => {
  if (!deliveryItem?.lotsRequiredDeliveryDate) {
    return null;
  }

  const { date, sameForAllLots } = deliveryItem.lotsRequiredDeliveryDate;
  const { createdAt, metadata, user } = activityMetadata;
  const { additionalInformation, deliveryDate, confidenceLevel } = metadata as PurchaseOrderDeliveryEstimatesMetadataType;
  const text: string = `Additional information: ${additionalInformation || '-'}`;

  return (
    <ActivityFeedItemWrapper className="flex justify-between">
      <ActivityFeedItemDetails
        additionalText={text}
        createdAt={createdAt}
        icon={<ActivityFeedItemIcon />}
        title="Delivery date updated"
        user={user}
      />
      <ActivityFeedDates
        actualDate={deliveryDate}
        confidenceLevel={confidenceLevel}
        dateLabel="Delivery date"
        hasInitialDateForAll={sameForAllLots}
        initialDate={date}
      />
    </ActivityFeedItemWrapper>
  );
};

export default DeliveryItem;
