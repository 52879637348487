import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetAccountUserGroupsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type GetAccountUserGroupsQueryResult = { __typename?: 'Query' } & {
  accountUserGroups: Array<
    { __typename?: 'AccountUserGroup' } & Pick<
      GraphQLTypes.AccountUserGroup,
      'description' | 'isDefaultRole' | 'name' | 'userGroupId'
    >
  >;
};

export const GetAccountUserGroupsDocument = /* #__PURE__ */ gql`
  query getAccountUserGroups($accountId: String!) {
    accountUserGroups(accountId: $accountId) {
      description
      isDefaultRole
      name
      userGroupId
    }
  }
`;

/**
 * __useGetAccountUserGroupsQuery__
 *
 * To run a query within a React component, call `useGetAccountUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountUserGroupsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountUserGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountUserGroupsQueryResult, GetAccountUserGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAccountUserGroupsQueryResult, GetAccountUserGroupsQueryVariables>(
    GetAccountUserGroupsDocument,
    options
  );
}
export function useGetAccountUserGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountUserGroupsQueryResult, GetAccountUserGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAccountUserGroupsQueryResult, GetAccountUserGroupsQueryVariables>(
    GetAccountUserGroupsDocument,
    options
  );
}
export type GetAccountUserGroupsQueryHookResult = ReturnType<typeof useGetAccountUserGroupsQuery>;
export type GetAccountUserGroupsLazyQueryHookResult = ReturnType<typeof useGetAccountUserGroupsLazyQuery>;
