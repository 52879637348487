import { filter, includes } from 'lodash';
import type { ObjectSchema, TestContext } from 'yup';
import { array, object, string } from 'yup';

import type { Optional } from '@zen/utils/typescript';

const invalidCarrierLabel = 'SCAC must be four letters.';

export const validationSchema: ObjectSchema<{}> = object().shape({
  oceanCarrierName: string().required('Please enter carrier name.'),
  carrierScac: string().required('Please enter SCAC.'),
  synonyms: array().of(
    string()
      .test('duplicate test', (currentValue: Optional<string>, context: TestContext) => {
        const fields = context.from?.find(({ value }) => value?.carrierScac);
        const scacCodes = [fields?.value.carrierScac, ...(fields?.value?.synonyms || [])].filter((scac) => !!scac);
        const duplicatedScacCodes = filter(scacCodes, (value, index, iteratee) => includes(iteratee, value, index + 1));

        if (currentValue && duplicatedScacCodes.includes(currentValue)) {
          return context.createError({ message: 'SCAC must be unique.' });
        }

        return true;
      })
      .min(4, invalidCarrierLabel)
      .max(4, invalidCarrierLabel)
      .matches(/^[aA-zZ\s]+$/, invalidCarrierLabel)
      .required('Please enter synonym.')
  )
});
