import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetSubscribersQueryVariables = GraphQLTypes.Exact<{
  targetId: GraphQLTypes.Scalars['String'];
  targetType: GraphQLTypes.NotificationsTargetTypeEnum;
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type GetSubscribersQueryResult = { __typename?: 'Query' } & {
  notifications?: GraphQLTypes.Maybe<
    { __typename?: 'Notifications' } & {
      subscribers?: GraphQLTypes.Maybe<
        Array<
          { __typename?: 'NotificationsSubscriberType' } & {
            canReceiveNotifications: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            canSeeOriginalCompanyName: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            canUnsubscribe: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            contactDetails?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'id' | 'firstName' | 'lastName'> & {
                  organisation?: GraphQLTypes.Maybe<
                    | ({ __typename?: 'AgentOrganisation' } & Pick<GraphQLTypes.AgentOrganisation, 'name'>)
                    | ({ __typename?: 'ForwarderOrganisation' } & Pick<GraphQLTypes.ForwarderOrganisation, 'name'>)
                    | ({ __typename?: 'MyOrganisation' } & Pick<GraphQLTypes.MyOrganisation, 'name'>)
                    | ({ __typename?: 'SavedOrganisation' } & Pick<GraphQLTypes.SavedOrganisation, 'name'>)
                  >;
                }
            >;
          }
        >
      >;
      stakeholders?: GraphQLTypes.Maybe<
        Array<
          { __typename?: 'NotificationsStakeholderType' } & {
            contactDetails?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'id' | 'firstName' | 'lastName'> & {
                  organisation?: GraphQLTypes.Maybe<
                    | ({ __typename?: 'AgentOrganisation' } & Pick<GraphQLTypes.AgentOrganisation, 'name'>)
                    | ({ __typename?: 'ForwarderOrganisation' } & Pick<GraphQLTypes.ForwarderOrganisation, 'name'>)
                    | ({ __typename?: 'MyOrganisation' } & Pick<GraphQLTypes.MyOrganisation, 'name'>)
                    | ({ __typename?: 'SavedOrganisation' } & Pick<GraphQLTypes.SavedOrganisation, 'name'>)
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export const GetSubscribersQueryDocument = /* #__PURE__ */ gql`
  query getSubscribersQuery($targetId: String!, $targetType: NotificationsTargetTypeEnum!, $accountId: String!) {
    notifications {
      subscribers(targetId: $targetId, targetType: $targetType) {
        canReceiveNotifications {
          value
        }
        canSeeOriginalCompanyName {
          value
        }
        canUnsubscribe {
          value
        }
        contactDetails {
          id
          firstName
          lastName
          organisation {
            name
          }
        }
      }
      stakeholders(targetId: $targetId, targetType: $targetType, accountId: $accountId) {
        contactDetails {
          id
          firstName
          lastName
          organisation {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetSubscribersQuery__
 *
 * To run a query within a React component, call `useGetSubscribersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscribersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscribersQuery({
 *   variables: {
 *      targetId: // value for 'targetId'
 *      targetType: // value for 'targetType'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetSubscribersQuery(
  baseOptions: Apollo.QueryHookOptions<GetSubscribersQueryResult, GetSubscribersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetSubscribersQueryResult, GetSubscribersQueryVariables>(GetSubscribersQueryDocument, options);
}
export function useGetSubscribersQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSubscribersQueryResult, GetSubscribersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetSubscribersQueryResult, GetSubscribersQueryVariables>(GetSubscribersQueryDocument, options);
}
export type GetSubscribersQueryHookResult = ReturnType<typeof useGetSubscribersQuery>;
export type GetSubscribersQueryLazyQueryHookResult = ReturnType<typeof useGetSubscribersQueryLazyQuery>;
