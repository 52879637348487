import type { FC } from 'react';

import type { DatePickerInputProps } from '@zen/DesignSystem';
import { DatePickerInput, Icon, Tooltip } from '@zen/DesignSystem';
import { getToday } from '@zen/utils/date';
import { formatTimeZone } from '@zen/utils/dateTime';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import type { FormFieldInputProps, FormInstance } from '../types';

type Props = Omit<DatePickerInputProps, 'onDayChange'> &
  FormFieldProps & {
    onChange?: (date: Nullable<string>) => void;
    timeZone?: Optional<string>;
  };

const FormDatePickerInput: FC<Props> = (props) => {
  const today: string = getToday();

  return (
    <FormField {...props}>
      {(field: FormFieldInputProps<string>, form: FormInstance<unknown>) => {
        const handleDateChange = (date: Nullable<string>): void => {
          form.setFieldValue(props.name, date);

          props.onChange?.(date);
        };

        return (
          <div className="flex items-center">
            <DatePickerInput
              {...field}
              disabledDates={props.disabledDates}
              hasError={field.error}
              onBlur={form.handleBlur}
              onDayChange={handleDateChange}
              selectedDate={field.value}
              tooltip={props.tooltip}
            />
            {props.timeZone && (
              <Tooltip placement="right" tooltipContent={formatTimeZone(field.value || today, props.timeZone)}>
                <Icon className="pl-2 pr-1" icon="zicon-information" />
              </Tooltip>
            )}
          </div>
        );
      }}
    </FormField>
  );
};

export default FormDatePickerInput;
