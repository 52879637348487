import cx from 'classnames';
import type { FC, PropsWithChildren, ReactElement, SyntheticEvent } from 'react';

import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import Counter from '../../Counter';

interface Props {
  active: boolean;
  className?: string;
  onClick?: () => void;
  totalCount?: number | string;
}

const displayName = 'Design System/Tab';

const Tab: FC<PropsWithChildren<Props>> = ({ active, onClick, children, totalCount, className, ...rest }) => {
  const { trackEvent } = useTracking();

  const tabClassName = cx(
    {
      'text-grey-dark border-azure-base active-tab': active
    },
    {
      'text-grey-base border-transparent': !active
    },
    'text-sm pb-2.5 mr-6 font-medium cursor-pointer border-b-2 inline-flex',
    'border-solid whitespace-nowrap',
    'transition-all duration-150 ease-linear hover:border-azure-base group',
    'items-center leading-snug',
    className
  );

  const handleClick = (event: SyntheticEvent): void => {
    const trackingEvent = createTrackingEvent(displayName, 'click', event);

    trackEvent(trackingEvent);
    onClick?.();
  };

  const renderCountBadge = (count: number | string): ReactElement => {
    const counterType = active ? 'selected' : 'deselected';
    const counterClassNames = 'transition-all duration-150 ease-linear';

    return <Counter className="ml-2" counterClassNames={counterClassNames} type={counterType} value={count} />;
  };

  return (
    <div
      className={tabClassName}
      data-component="tab"
      data-testid="tab"
      onClick={handleClick}
      {...createTrackingParentAttribute('tab')}
      {...createTrackingLabelAttribute('tab')}
      {...rest}
    >
      {children}
      {!!totalCount && renderCountBadge(totalCount)}
    </div>
  );
};

export default Tab;
