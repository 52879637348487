import type { FC } from 'react';

import CountrySelect from '@zen/Components/CountrySelect';

import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import type { CountryCode } from './types';

interface Props extends FormFieldProps {
  disabled?: boolean;
  name: string;
  onChange?: (country: string, code: CountryCode) => void;
}

type FormCountriesField = {
  error: string;
  name: string;
  value: CountryCode;
};

type FormCountriesForm = {
  setFieldValue: (name: string, value: string) => void;
};

const FormCountriesSelect: FC<Props> = (props) => {
  return (
    <FormField {...props}>
      {({ error, name, value, ...rest }: FormCountriesField, form: FormCountriesForm) => (
        <CountrySelect
          {...rest}
          hasError={!!error}
          name={name}
          onChange={(code: CountryCode, country: string) => {
            form.setFieldValue(name, code);

            props.onChange?.(country, code);
          }}
          placeholder="Select country"
          value={value}
        />
      )}
    </FormField>
  );
};

export default FormCountriesSelect;
