import { ModeOfTransport } from '@zen/Booking';
import { bookingModeOfTransportLabelMapping, type SVGComponent } from '@zen/types';

import Air from './images/air.svg?react';
import Ocean from './images/ocean.svg?react';
import Rail from './images/rail.svg?react';
import Road from './images/road.svg?react';

interface ImageConfig {
  alt: string;
  imageComponent: SVGComponent;
}

export const modeOfTransportIconMapping: Record<ModeOfTransport, ImageConfig> = {
  [ModeOfTransport.AIR]: {
    imageComponent: Air,
    alt: bookingModeOfTransportLabelMapping[ModeOfTransport.AIR]
  },
  [ModeOfTransport.OCEAN]: {
    imageComponent: Ocean,
    alt: bookingModeOfTransportLabelMapping[ModeOfTransport.OCEAN]
  },
  [ModeOfTransport.RAIL]: {
    imageComponent: Rail,
    alt: bookingModeOfTransportLabelMapping[ModeOfTransport.RAIL]
  },
  [ModeOfTransport.TRUCK]: {
    imageComponent: Road,
    alt: bookingModeOfTransportLabelMapping[ModeOfTransport.TRUCK]
  }
};
