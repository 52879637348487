import type { Product } from '@zen/Products/types';
import type { Nullable } from '@zen/utils/typescript';

import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import ProductSelect from '../ProductSelect';
import type { FormFieldInputProps, FormInstance } from '../types';

interface Props<T> extends FormFieldProps {
  onSelect: (name: string, value: Nullable<Product>, form: FormInstance<T>) => void;
}

const FormProductsSelect = <T extends {}>(props: Props<T>) => (
  <FormField {...props}>
    {(field: FormFieldInputProps<Product>, form: FormInstance<T>) => {
      const { label, ...rest } = field;

      return (
        <ProductSelect
          {...rest}
          onChange={(option) => {
            form.setFieldValue(field.name, option);
            if (props.onSelect) {
              props.onSelect(field.name, option, form);
            }
          }}
        />
      );
    }}
  </FormField>
);

export default FormProductsSelect;
