import cx from 'classnames';
import type { FC, ReactNode } from 'react';
import { Fragment } from 'react';
import { useWindowSize } from 'react-use';

import { checkPermission } from '@zen/Auth/authHelper';
import { isAirBooking, isOceanBooking, isRoadBooking } from '@zen/Booking';
import { ForwarderInlineField, HouseBillOfLadingInlineField } from '@zen/Booking/BookingDetails/components/forms';
import CargoModeInlineField from '@zen/Booking/BookingDetails/components/forms/CargoModeInlineField';
import ContractIdInlineField from '@zen/Booking/BookingDetails/components/forms/ContractIdInlineField';
import ForwarderReferenceInlineField from '@zen/Booking/BookingDetails/components/forms/ForwarderReferenceInlineField';
import IncotermsInlineField from '@zen/Booking/BookingDetails/components/forms/IncotermsInlineField';
import ModeOfTransportInlineField from '@zen/Booking/BookingDetails/components/forms/ModeOfTransportInlineField';
import MultiStopInlineField from '@zen/Booking/BookingDetails/components/forms/MultiStopInlineField';
import ShipmentTypeInlineField from '@zen/Booking/BookingDetails/components/forms/ShipmentTypeInlineField';
import TradeRoleInlineField from '@zen/Booking/BookingDetails/components/forms/TradeRoleInlineField';
import TruckSwapInlineField from '@zen/Booking/BookingDetails/components/forms/TruckSwapInlineField';
import PricingRequiredToggle from '@zen/Booking/BookingDetails/components/PricingRequiredToggle';
import type { CargoConsolidatedItemType } from '@zen/Cargo';
import { CargoModeEnum, consolidatedCargoLabelMapping, formatTotalWeight } from '@zen/Cargo';
import CollapsibleSection from '@zen/Components/CollapsibleSection';
import LabelledValue from '@zen/Components/LabelledValue';
import { Breakpoint, cargoTypeLabelMapping } from '@zen/types';
import { formatNumber } from '@zen/utils/formatting';
import type { Optional } from '@zen/utils/typescript';

import type { BookingSummaryData } from '../../types';

interface Props {
  booking: BookingSummaryData;
  canViewForwarder: boolean;
}

const BookingSummary: FC<Props> = ({ booking, canViewForwarder }) => {
  const { width } = useWindowSize();

  const {
    bookingManagementSettings,
    calculatedInfo,
    cargo,
    coreCargos,
    customsOnly,
    forwarder,
    forwarderReference,
    houseBillOfLading,
    incoterms,
    modeOfTransport,
    multiStop,
    shipmentType,
    tradeRole,
    truckSwap,
    zencargoReference
  } = booking;

  const { cargoSummary } = calculatedInfo;

  const canAccessBookingManagement: boolean = checkPermission<BookingSummaryData>(booking, 'canUpdateBookingManagementSettings');
  const canManageCargo: boolean = checkPermission<BookingSummaryData>(booking, 'canManageCargo');
  const canViewContractId: boolean = checkPermission<BookingSummaryData>(booking, 'canViewContractId');
  const canUpdateHouseBillOfLading: boolean = checkPermission<BookingSummaryData>(booking, 'canUpdateHouseBillOfLading');
  const canUpdateIncoterms: boolean = checkPermission<BookingSummaryData>(booking, 'canUpdateIncoterms');
  const canUpdateModeOfTransport: boolean = checkPermission<BookingSummaryData>(booking, 'canUpdateModeOfTransport');
  const canUpdateMultiStop: boolean = checkPermission<BookingSummaryData>(booking, 'canUpdateMultiStop');
  const canUpdatePricingRequired: boolean = checkPermission<BookingSummaryData>(booking, 'canUpdatePricingRequired');
  const canUpdateShipmentType: boolean = checkPermission<BookingSummaryData>(booking, 'canUpdateShipmentType');
  const canUpdateTruckSwap: boolean = checkPermission<BookingSummaryData>(booking, 'canUpdateTruckSwap');
  const canUpdateTradeRole: boolean = checkPermission<BookingSummaryData>(booking, 'canUpdateTradeRole');
  const canAccessPriciningRequiredToggle: boolean = canAccessBookingManagement && !customsOnly;
  const consolidatedCargoItems: Optional<CargoConsolidatedItemType[]> = cargo?.consolidatedCargo?.consolidatedCargoItems || [];
  const consolidatedCargoLabel: string = cargo?.mode ? consolidatedCargoLabelMapping[cargo.mode] : 'Cargo';

  const renderCargo = ({ quantity, subType }: CargoConsolidatedItemType): ReactNode => {
    const prefix: string = quantity ? `${quantity} x ` : '';

    return (
      <div key={subType} className="leading-normal">
        {prefix} {cargoTypeLabelMapping[subType]}
      </div>
    );
  };

  const renderCargoItems = (): ReactNode => {
    if (consolidatedCargoItems?.length === 0) {
      return '-';
    }

    return <div className="py-2.5">{consolidatedCargoItems?.map(renderCargo)}</div>;
  };

  const classNames: string = cx(
    {
      'w-1/3': width >= Breakpoint.XXL
    },
    'border-b 2xl:border-b-0 2xl:border-l border-solid border-grey-lighter p-6'
  );

  const isFullTruckLoad: boolean = cargo?.mode === CargoModeEnum.FTL;
  const totalValueTooltip: string = 'This is calculated as a sum of all cargo items';

  const renderLabelWithValue = (label: string, element: ReactNode, tooltip?: string): ReactNode => {
    return (
      <LabelledValue label={label} tooltip={tooltip} variant="default">
        {element || '-'}
      </LabelledValue>
    );
  };

  const fieldsConfiguration: { element: ReactNode; isVisible?: boolean }[] = [
    {
      element: (
        <ModeOfTransportInlineField
          canUpdateModeOfTransport={canUpdateModeOfTransport}
          hasCargoItems={(coreCargos || []).length > 0}
          modeOfTransport={modeOfTransport}
          zencargoReference={zencargoReference}
        />
      )
    },
    {
      element: (
        <CargoModeInlineField canManageCargo={canManageCargo} cargoMode={cargo?.mode} zencargoReference={zencargoReference} />
      )
    },
    {
      element: (
        <IncotermsInlineField
          canUpdateIncoterms={canUpdateIncoterms}
          incoterms={incoterms?.value}
          zencargoReference={zencargoReference}
        />
      )
    },
    {
      element: renderLabelWithValue(consolidatedCargoLabel, renderCargoItems()),
      isVisible: !isFullTruckLoad
    },
    {
      element: renderLabelWithValue('Total gross weight', formatTotalWeight(cargoSummary?.totalGrossWeight), totalValueTooltip),
      isVisible: !isFullTruckLoad
    },
    {
      element: renderLabelWithValue(
        'Total CBM',
        cargoSummary?.totalActualCbm && formatNumber(cargoSummary.totalActualCbm),
        totalValueTooltip
      ),
      isVisible: !isFullTruckLoad
    },
    {
      element: renderLabelWithValue(
        'Total chargeable weight',
        formatTotalWeight(cargoSummary?.totalChargeableWeight),
        totalValueTooltip
      ),
      isVisible: isAirBooking(modeOfTransport)
    },
    {
      element: (
        <ShipmentTypeInlineField
          canUpdateShipmentType={canUpdateShipmentType}
          shipmentType={shipmentType}
          zencargoReference={zencargoReference}
        />
      )
    },
    {
      element: (
        <TradeRoleInlineField
          canUpdateTradeRole={canUpdateTradeRole}
          tradeRole={tradeRole}
          zencargoReference={zencargoReference}
        />
      )
    },
    {
      element: <ContractIdInlineField zencargoReference={zencargoReference} />,
      isVisible: isOceanBooking(modeOfTransport) && canViewContractId
    },
    {
      element: <MultiStopInlineField multiStop={multiStop} zencargoReference={zencargoReference} />,
      isVisible: isFullTruckLoad && canUpdateMultiStop
    },
    {
      element: <TruckSwapInlineField truckSwap={truckSwap} zencargoReference={zencargoReference} />,
      isVisible: isFullTruckLoad && canUpdateTruckSwap
    },
    {
      element: (
        <HouseBillOfLadingInlineField
          canUpdateHouseBillOfLading={canUpdateHouseBillOfLading}
          houseBillOfLading={houseBillOfLading || ''}
          modeOfTransport={modeOfTransport}
          zencargoReference={zencargoReference}
        />
      ),
      isVisible: !isRoadBooking(modeOfTransport)
    },
    {
      element: (
        <PricingRequiredToggle
          canUpdatePricingRequired={canUpdatePricingRequired}
          isPricingRequired={!!bookingManagementSettings?.pricingRequired}
          zencargoReference={zencargoReference}
        />
      ),
      isVisible: canAccessPriciningRequiredToggle
    },
    {
      element: <ForwarderInlineField forwarder={forwarder} variant="inline" zencargoReference={zencargoReference} />,
      isVisible: canViewForwarder
    },
    {
      element: (
        <ForwarderReferenceInlineField
          forwarderReference={forwarderReference || ''}
          label="Forwarder reference"
          zencargoReference={zencargoReference}
        />
      ),
      isVisible: canViewForwarder
    }
  ];

  const visibleFields: ReactNode[] = fieldsConfiguration
    .filter(({ isVisible = true }) => isVisible)
    .map(({ element }, index: number) => <Fragment key={index}>{element}</Fragment>);

  const renderSummary = (): ReactNode => {
    if (width >= Breakpoint.XXL) {
      const items: ReactNode[] = visibleFields.map((field: ReactNode, index: number) => {
        const className: string | undefined = index ? 'mt-2' : undefined;

        return (
          <div key={index} className={className}>
            {field}
          </div>
        );
      });

      return (
        <CollapsibleSection collapseLabel="Show less" expandLabel="Show more" initialDisplayedItems={6} renderItems={items} />
      );
    }

    return <div className="grid grid-cols-3 2xl:grid-cols-1 gap-y-4 gap-x-4">{visibleFields}</div>;
  };

  return <div className={classNames}>{renderSummary()}</div>;
};

export default BookingSummary;
