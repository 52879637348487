import type { FC } from 'react';
import { useState } from 'react';

import { InlineEmptyState } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';

import type { QuoteOption as QuoteOptionType } from '../types';
import { QuoteOptionState } from '../types';
import NewQuoteOption from './NewQuoteOption';
import QuoteOption from './QuoteOption';

interface Props {
  accountId: string;
  quotesOptions: QuoteOptionType[];
  refetch: () => void;
  showAddNewQuote?: boolean;
  zencargoReference: string;
}

const QuoteOptions: FC<Props> = (props) => {
  const { accountId, quotesOptions, refetch, zencargoReference, showAddNewQuote } = props;

  const isAccepted = quotesOptions.some((q: QuoteOptionType) => q.state === QuoteOptionState.ACCEPTED);
  const { addSuccess } = useNotification();
  const [selectedQuoteOption, setSelectedQuoteOption] = useState<string>('');
  const isQuotesOptionsListEmpty: boolean = quotesOptions.length === 0;

  const onSuccess = (): void => {
    refetch();
    addSuccess('Quote option saved.');
  };

  const handleQuoteOptionAction = (quoteOption: QuoteOptionType): void => {
    refetch();

    if (quoteOption.state === QuoteOptionState.ARCHIVED) {
      addSuccess('Quote option has been archived.');
    } else {
      addSuccess('Quote option updated.');
    }
  };

  return (
    <>
      {showAddNewQuote && (
        <div className="flex justify-end mb-4">
          <NewQuoteOption onSuccess={refetch} zencargoReference={zencargoReference} />
        </div>
      )}
      {isQuotesOptionsListEmpty && (
        <InlineEmptyState title="There are no quote options created for this booking.">
          Quote options are a list of possible costs choices for the transportation service.
        </InlineEmptyState>
      )}
      {!isQuotesOptionsListEmpty && (
        <div className="grid gap-4">
          {quotesOptions.map((quoteOption, i) => (
            <QuoteOption
              key={i}
              accountId={accountId}
              handleAction={handleQuoteOptionAction}
              isAccepted={isAccepted}
              isSelected={!isAccepted && selectedQuoteOption === quoteOption.id}
              onSuccess={onSuccess}
              quoteOption={quoteOption}
              setSelectedQuoteOption={setSelectedQuoteOption}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default QuoteOptions;
