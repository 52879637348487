import type { FC } from 'react';

import type { FormInstance } from '@zen/Components';
import { Form, FormButtons, FormChargeTypeSelect, FormCurrencyInput } from '@zen/Components';
import type { Terminal } from '@zen/Components/TerminalSelect';
import { Button } from '@zen/DesignSystem';
import { NetworksContactFormInput } from '@zen/Networks';
import FormFreightChargePortSelect from '@zen/RateCards/LegacyRateCards/FormFreightChargePortSelect';
import type { HaulageChargePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';
import { Currency } from '@zen/types';
import useAccount from '@zen/utils/hooks/useAccount';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { getValidationSchema } from './haulageForm.validation';
import { getHaulageFormLabels, prepareInitialValues, preparePayload } from './helpers';
import type { HaulageFormInitialValues, HaulageFormValues } from './types';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, ChargeTypeEnum } from './types';

interface Props {
  initialValues?: HaulageChargePayload;
  onCancel?: () => void;
  onSubmit: (values: HaulageChargePayload) => void;
  onSuccess: () => void;
  ports: Terminal[];
  type: 'origin' | 'destination';
}

const defaultInitialValues: HaulageFormInitialValues = {
  chargeType: null,
  price: {
    value: null,
    currency: Currency.USD
  },
  location: null,
  port: null
};

const HaulageForm: FC<Props> = ({ onSubmit, initialValues, onSuccess, type, onCancel, ports }) => {
  const { accountUuid } = useAccount();
  const labelConfig: Record<keyof HaulageFormInitialValues, string> = getHaulageFormLabels(type);
  const chargeType: ChargeTypeEnum = type === 'origin' ? ChargeTypeEnum.ORIGIN : ChargeTypeEnum.DESTINATION;

  const handleSubmit = (values: HaulageFormValues): Promise<IOkOrErrorResult> => {
    const haulageCharge: HaulageChargePayload = preparePayload(values);

    onSubmit(haulageCharge);

    return Promise.resolve({ ok: true, error: null });
  };

  const initialFormValues: HaulageFormInitialValues = initialValues ? prepareInitialValues(initialValues) : defaultInitialValues;

  const renderFormButtons = ({ isSubmitting }: FormInstance<HaulageFormInitialValues>) => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed">
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  const assignmentType: AssignmentTypeValue =
    type === 'origin' ? AssignmentTypeValue.COLLECTION_LOCATION : AssignmentTypeValue.DELIVERY_LOCATION;

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      validationSchema={getValidationSchema(labelConfig)}
    >
      {() => (
        <>
          <FormChargeTypeSelect label={labelConfig.chargeType} name="chargeType" type={chargeType} />
          <FormFreightChargePortSelect label={labelConfig.port} name="port" ports={ports} />
          <NetworksContactFormInput
            accountUuid={accountUuid}
            assignmentType={assignmentType}
            domainName={AssignmentTargetTypeEnum.BOOKING_CARGO}
            entityType="location"
            label={labelConfig.location}
            name="location"
            showAddButton={false}
          />
          <div className="grid grid-cols-2">
            <FormCurrencyInput label={labelConfig.price} name="price" />
          </div>
        </>
      )}
    </Form>
  );
};

export default HaulageForm;
