import type { FC } from 'react';

import type { PillProps } from '@zen/DesignSystem';
import { Headline, Pill } from '@zen/DesignSystem';

import SSOKeyForm from './SSOKeyForm';
import type { AccountSSODetails, SSOKeyFormValues } from './types';

interface Props {
  accountId: string;
  accountName: string;
  ssoDetails: AccountSSODetails;
}

const Authentication: FC<Props> = ({ accountName, accountId, ssoDetails }) => {
  const { enabled, connectionName, provisionMethod: initialProvisionMethod, domains, defaultLocation } = ssoDetails;

  const initialValues: SSOKeyFormValues = {
    provisionMethod: initialProvisionMethod,
    connectionName,
    domains: domains.join(', '),
    defaultLocation
  };

  const pillProps: PillProps = enabled ? { children: 'Enabled', type: 'success' } : { children: 'Not enabled', type: 'pending' };

  return (
    <div>
      <Headline level={2}>Single sign on</Headline>
      <Pill className="my-4" {...pillProps} />
      <SSOKeyForm accountId={accountId} accountName={accountName} initialValues={initialValues} isSSOEnabled={enabled} />
    </div>
  );
};

export default Authentication;
export type { Props as AuthenticationTabProps };
