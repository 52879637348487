import { createContext } from 'react';

import type { VisitedAccounts } from './types';

const initialContext: VisitedAccounts = {
  getVisitedAccounts: () => [],
  updateVisitedAccounts: () => {},
  getLastVisitedAccounts: () => [],
  getMostVisitedAccounts: () => []
};

const VisitedAccountsContext = createContext<VisitedAccounts>(initialContext);

export default VisitedAccountsContext;
