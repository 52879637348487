import { type FC, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { Modal } from '@zen/DesignSystem';
import useDefaultCharges from '@zen/RateCards/graphql/hooks/useDefaultCharges';
import type { DefaultChargeFilter } from '@zen/RateCards/RateCardForm/types';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { LocationType } from '../../components/types';
import PortChargeForm from '../PortChargeForm';
import PortChargeTable from '../PortChargeTable';
import { formatPortCharges, preparePortChargePayload } from './helpers';
import type { CostTrackingLocation } from './types';

interface Props {
  filters: DefaultChargeFilter[];
  onAdd: (values: RateCardCharge[]) => void;
  onCustomAdd: (values: RateCardCharge[]) => void;
  onDelete: (index: number) => void;
  onUpdate: (index: number, values: Partial<RateCardCharge>) => void;
  onVisibilityUpdate: (id: string, disable: boolean) => void;
  portCharges: RateCardCharge[];
  ports: CostTrackingLocation[];
  type: LocationType;
}

const PortChargeSection: FC<Props> = (props) => {
  const { filters, portCharges, onAdd, onCustomAdd, onDelete, onUpdate, onVisibilityUpdate, ports, type } = props;

  const { data, isLoading } = useDefaultCharges({ applicabilityTraits: filters }, !filters.length);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  useDeepCompareEffect(() => {
    if (data && !isLoading) {
      const charges: RateCardCharge[] = preparePortChargePayload(data, type);

      onAdd?.(charges);
    }
  }, [data, isLoading]);

  const handleCustomAdd = (): void => {
    setModalOpen(true);
  };

  const handleClose = (): void => {
    setModalOpen(false);
  };

  return (
    <>
      <PortChargeTable
        charges={formatPortCharges(portCharges)}
        onAdd={handleCustomAdd}
        onDelete={onDelete}
        onUpdate={onUpdate}
        onVisibilityUpdate={onVisibilityUpdate}
        type={type}
      />
      <Modal
        closeOnClickAway={false}
        isOpen={isModalOpen}
        modalOverflowY="scroll"
        onClose={handleClose}
        title="Add port charges"
        width="full"
      >
        <PortChargeForm locationType={type} onCancel={handleClose} onSubmit={onCustomAdd} onSuccess={handleClose} ports={ports} />
      </Modal>
    </>
  );
};

export type { Props as PortChargeSectionProps };

export default PortChargeSection;
