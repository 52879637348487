import {
  initialShipmentsTableFilters,
  newShipmentsHiddenColumns,
  prepareShipmentsTableFilters,
  SHIPMENTS_TABLE_EMPTY_VALUE
} from '@zen/Shipments/ShipmentsTable/helpers';
import type { Optional } from '@zen/utils/typescript';

import type { OperationsShipmentsTableCargoItem, OperationsShipmentsTableFilters } from './types';
import { ShipmentsTableCargoGroupsGroupByColumn } from './types';

export const getAccountName = (account: Optional<OperationsShipmentsTableCargoItem['account']>): string => {
  if (account?.accountName) {
    return account.accountName;
  }

  if (account?.legacyAccount) {
    return account.legacyAccount?.division?.name || account.legacyAccount?.name;
  }

  return SHIPMENTS_TABLE_EMPTY_VALUE;
};

export const prepareAllShipmentsTableFilters = (filters: OperationsShipmentsTableFilters) => {
  const { accountIds, bookingCoordinatorIds, ...shipmentFilters } = filters;

  return {
    ...prepareShipmentsTableFilters(shipmentFilters),
    bookingCoordinator: bookingCoordinatorIds,
    customerAccountId: accountIds
  };
};

export const allShipmentsInitialValues = {
  groupBy: ShipmentsTableCargoGroupsGroupByColumn.ZENCARGO_REFERENCE,
  search: undefined,
  sortInput: undefined,
  hiddenColumns: newShipmentsHiddenColumns
};

export const allShipmentsInitialView = {
  filters: initialShipmentsTableFilters,
  ...allShipmentsInitialValues
};

export const prepareInitialFilters = (persistedFilters: OperationsShipmentsTableFilters) => {
  const { accountIds, bookingCoordinatorIds } = persistedFilters;

  return {
    ...initialShipmentsTableFilters,
    bookingCoordinatorIds: bookingCoordinatorIds || [],
    accountIds: accountIds || [],
    ...allShipmentsInitialValues
  };
};
