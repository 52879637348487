import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import type { ModeOfTransport } from '@zen/Booking';
import ValueWithDefault from '@zen/Components/ValueWithDefault';
import PathIndicator from '@zen/Journey/components/PathIndicator';
import type { Optional } from '@zen/utils/typescript';

import type { CargoSummaryScheduledStop } from '../../../types';
import { getFormattedDateOrPlaceholderLabel, getScheduledLocationLabels } from '../helpers';
import LocationIcon from '../LocationIcon';

interface Props {
  bookingModeOfTransport: Optional<ModeOfTransport>;
  destinationType: 'origin' | 'destination';
  highlightDate: boolean;
  scheduledStop: CargoSummaryScheduledStop;
  showStopIcon?: boolean;
}

const ScheduledStopPathIndicator: FC<Props> = (props) => {
  const { bookingModeOfTransport, destinationType, highlightDate, showStopIcon = false, scheduledStop } = props;
  const { dateDetails, isCompleted } = scheduledStop;
  const dateValue: string = getFormattedDateOrPlaceholderLabel(dateDetails?.date, dateDetails?.dateType);
  const { originLabel, destinationLabel } = getScheduledLocationLabels(bookingModeOfTransport);
  const labelText: string = destinationType === 'origin' ? originLabel : destinationLabel;
  const dateLabel: string = destinationType === 'origin' ? 'Departure' : 'Arrival';

  const dateValueClassName: string = cx({
    'text-red-dark': dateDetails?.hasDateExceptionBetweenInitialAndFinalDates,
    'text-sm font-bold': highlightDate
  });

  const renderIndicator = (): ReactNode => {
    if (showStopIcon) {
      return <LocationIcon isCompleted={isCompleted} />;
    }

    return <PathIndicator currentIndicator="tick" isCompleted={isCompleted} isCurrent={false} />;
  };

  return (
    <div className="flex items-center mt-3">
      <div className="w-48 text-xs text-right shrink-0">
        {dateValue && <div className="text-xs text-grey-base">{dateLabel}</div>}
        <div className={dateValueClassName}>{dateValue}</div>
      </div>
      {renderIndicator()}
      <div>
        <div className="text-xs text-grey-base">{labelText}</div>
        <ValueWithDefault value={scheduledStop.terminal?.label} />
      </div>
    </div>
  );
};

export default ScheduledStopPathIndicator;
