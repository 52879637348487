import { union, without, xor } from 'lodash';
import type { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';
import { useLocalStorage } from 'react-use';

import BasketHeader from '@zen/Components/BasketHeader';
import FixedHeader from '@zen/Components/FixedHeader';
import QueryHandler from '@zen/Components/QueryHandler';
import { Pagination } from '@zen/DesignSystem';
import type { OrderDetailsLineItem } from '@zen/Orders';
import CargoReadyDateSlideout from '@zen/Orders/CargoReadyDateSlideout';
import type { OrderDetailsLineItemsQueryResult, OrderDetailsLineItemsQueryVariables } from '@zen/Orders/graphql';
import { useOrderDetailsLineItemsQuery } from '@zen/Orders/graphql';
import { PageSize } from '@zen/types';
import { usePagination } from '@zen/utils/hooks/pagination';
import type { Optional, Undefinable } from '@zen/utils/typescript';

import LotEstimatesSlideout from '../../LotEstimatesSlideout';
import type { OrderLineItem, OrderLot } from '../../types';
import { getLotDeliveryDate, getLots, getOrderLotIds } from '../../utils';
import UpdateCRDContext from '../updateCRDContext';
import LineItems from './LineItems';

const checkCanUpdateAllDeliveryDates = (lineItems: OrderLineItem[], lotsIdsToUpdate: string[]): boolean => {
  const lineItemsLotsMappedToAbility: Record<string, boolean> = lineItems.reduce((lineItemsAcc, lineItem) => {
    const lineItemsMapped = lineItem.lots.reduce((lotsAcc: Record<string, boolean>, lot) => {
      lotsAcc[lot.id] = lineItem.canUpdateDeliveryDate.value;

      return lotsAcc;
    }, {});

    return {
      ...lineItemsAcc,
      ...lineItemsMapped
    };
  }, {});

  return lotsIdsToUpdate.every((lotId) => lineItemsLotsMappedToAbility[lotId]);
};

interface Props {
  orderDate: Optional<string>;
  orderId: string;
}

export const PO_LINE_ITEMS_PAGE_SIZE_KEY = 'poLineItemsPageSize';

const LineItemsContainer: FC<Props> = ({ orderId, orderDate }) => {
  const [isMaskOpen, setIsMaskOpen] = useState<boolean>(false);
  const [isSlideoutOpen, setIsSlideoutOpen] = useState<boolean>(false);
  const [idsToUpdate, setIdsToUpdate] = useState<string[]>([]);
  const [pageSize, setPageSize] = useLocalStorage(PO_LINE_ITEMS_PAGE_SIZE_KEY, PageSize.TEN);

  const {
    loading,
    error,
    nodes: orderedLineItems,
    paginationInfo
  } = usePagination<OrderDetailsLineItemsQueryResult, OrderDetailsLineItemsQueryVariables, OrderDetailsLineItem>(
    useOrderDetailsLineItemsQuery,
    'purchaseOrderOrderedLineItems',
    { purchaseOrderId: orderId },
    pageSize,
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  );

  const toggleSlideout = (): void => setIsSlideoutOpen(!isSlideoutOpen);

  const toggleMask = (): void => setIsMaskOpen(!isMaskOpen);

  const isSelected = (id: string): boolean => idsToUpdate.includes(id);

  const deselectAll = (): void => setIdsToUpdate([]);

  const selectAll = (): void => {
    const ids: string[] = orderedLineItems ? getOrderLotIds({ orderedLineItems }) : [];

    setIdsToUpdate(ids);
  };

  const selectLot = (lotId: string) => setIdsToUpdate(xor(idsToUpdate, [lotId]));

  const selectLots = (lotIds: string[]) => {
    const shouldRemoveItems = lotIds.every((id) => idsToUpdate.includes(id));

    if (shouldRemoveItems) {
      setIdsToUpdate(without(idsToUpdate, ...lotIds));
    } else {
      setIdsToUpdate(union(idsToUpdate, lotIds));
    }
  };

  const renderSlideout = (): ReactNode => {
    const canUpdateAllDeliveryDates: boolean = checkCanUpdateAllDeliveryDates(orderedLineItems, idsToUpdate);

    const estimates = {
      cargoReadyDate: selectLatestCRD(orderedLineItems),
      deliveryDate: selectLatestDeliveryDate(orderedLineItems)
    };

    const commonSlideoutProps = {
      isVisible: isSlideoutOpen,
      lotIds: idsToUpdate,
      onClose: toggleSlideout,
      onSuccess: () => {
        toggleMask();
        toggleSlideout();
        deselectAll();
      },
      orderDate
    };

    if (canUpdateAllDeliveryDates) {
      return <LotEstimatesSlideout className="!top-0" estimates={estimates} {...commonSlideoutProps} />;
    }

    return (
      <CargoReadyDateSlideout
        className="!top-0"
        initialValues={{
          date: selectLatestCRD(orderedLineItems)
        }}
        {...commonSlideoutProps}
      />
    );
  };

  const selectLatestCRD = (items: OrderLineItem[]): Undefinable<string> => {
    const lot: Undefinable<OrderLot> = getLots(items)
      .filter((item) => idsToUpdate.includes(item.id) && item.cargoReadyDate?.date)
      .sort((a, b) =>
        a?.cargoReadyDate?.date && b?.cargoReadyDate?.date && a.cargoReadyDate.date > b.cargoReadyDate.date ? -1 : 1
      )
      .shift();

    return lot?.cargoReadyDate?.date ? lot?.cargoReadyDate?.date : undefined;
  };

  const selectLatestDeliveryDate = (items: OrderLineItem[]) => {
    const lot = getLots(items)
      .filter((item) => idsToUpdate.includes(item.id) && getLotDeliveryDate(item))
      .sort((a, b) => (getLotDeliveryDate(a) > getLotDeliveryDate(b) ? -1 : 1))
      .shift();

    return lot ? getLotDeliveryDate(lot) : undefined;
  };

  return (
    <UpdateCRDContext.Provider value={{ idsToUpdate, selectLot, selectLots, displayMask: isMaskOpen, isSelected }}>
      {isMaskOpen && (
        <FixedHeader classNames="px-10 py-4 -mt-16 z-20 -ml-8" reduceWidthBySidebar={false}>
          <BasketHeader
            className="items-center py-8 border-b border-solid border-grey-lighter"
            headline="Select all the line items you want to update."
            isSubmitDisabled={!idsToUpdate.length}
            onCancel={toggleMask}
            onDeselectAll={deselectAll}
            onSelectAll={selectAll}
            onSubmit={toggleSlideout}
            submitText="Update"
          />
        </FixedHeader>
      )}
      <QueryHandler data={orderedLineItems} error={!!error} isLoading={loading}>
        {(items: OrderDetailsLineItem[]): ReactElement => (
          <>
            <LineItems
              items={items}
              onPageSizeChange={setPageSize}
              onUpdateAll={toggleMask}
              orderDate={orderDate}
              pageSize={pageSize}
            />
            <Pagination pageInfo={paginationInfo} />
          </>
        )}
      </QueryHandler>
      {isSlideoutOpen && renderSlideout()}
    </UpdateCRDContext.Provider>
  );
};

export default LineItemsContainer;
