import type { FC, ReactNode } from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { Button, Headline } from '@zen/DesignSystem';
import type { QueryParams } from '@zen/utils/QueryParams';
import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';

import BasketContext from '../OrderBasket/BasketContext';

interface Props {
  className?: string;
  headline: ReactNode;
  isSubmitDisabled: boolean;
  onCancel: () => void;
  onDeselectAll?: () => void;
  onSelectAll?: () => void;
  onSubmit: () => void;
  openCustomerModal?: () => void;
  submitText: string;
}

const BasketHeader: FC<Props> = (props) => {
  const { customer } = useContext(BasketContext);
  const { check } = useGlobalPermissions();
  const { search } = useLocation();
  const queryParams: QueryParams = parseQueryParams(search);
  const onlyOwnAccount: boolean = !!queryParams.onlyOwnAccount;
  const canViewOrdersCustomers = check('purchaseOrders.canViewCustomers') && !onlyOwnAccount;

  const {
    className = 'items-center py-10',
    submitText,
    headline,
    isSubmitDisabled,
    openCustomerModal,
    onSelectAll,
    onDeselectAll,
    onCancel,
    onSubmit
  } = props;
  const displayLinks = onSelectAll || onDeselectAll;

  return (
    <div className={`flex justify-between ${className}`} data-testid="basket-header">
      <div className="font-bold text-navy-base">
        <Headline level={1}>{headline}</Headline>
        {canViewOrdersCustomers && customer && (
          <div className="flex items-baseline mt-2" data-testid="customer-basket-details">
            <p className="mr-2 text-2xl">{customer.account?.tradingName || customer.name}</p>
            <p className="underline" data-testid="header-open-modal-link" onClick={openCustomerModal}>
              Change customer
            </p>
          </div>
        )}
        {displayLinks && (
          <div className="pt-6 text-sm underline cursor-pointer">
            {onSelectAll && (
              <span className="mr-10" onClick={onSelectAll}>
                Select all
              </span>
            )}
            {onDeselectAll && <span onClick={onDeselectAll}>De-select all</span>}
          </div>
        )}
      </div>
      <div className="flex">
        <Button className="mr-4" data-testid="cancel-button" onClick={onCancel} variant="ghost">
          Cancel
        </Button>
        <Button data-testid="submit-button" disabled={isSubmitDisabled} onClick={onSubmit}>
          {submitText}
        </Button>
      </div>
    </div>
  );
};

export { BasketHeader };
export type { Props as BasketHeaderProps };
export default BasketHeader;
