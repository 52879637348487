import type { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import type { CreateOrganisationLocationInput } from '@zen/Networks';
import { useNetworksContext } from '@zen/Networks';
import { createBusinessHoursInput } from '@zen/Networks/businessHours.mock';
import type { IAddressForm } from '@zen/Networks/Form/FormAddressFields/types';
import LocationForm from '@zen/Networks/Form/LocationForm';
import { useNetworksCreateOrganisationLocationMutation, useOrganisationContactsQuery } from '@zen/Networks/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';
import type { DeepNullable, Optional } from '@zen/utils/typescript';

import SkeletonNewLocationLoading from './Loading/SkeletonNewLocationLoading';
import { prepareLocationRequestData } from './prepareLocationRequestData';

interface Props {
  networkId: string;
}

export interface IOrganisationLocationValues extends IAddressForm {
  name: string;
}

export const newLocationInitialValues: DeepNullable<IOrganisationLocationValues> = {
  name: '',
  businessHours: createBusinessHoursInput(),
  city: '',
  countryCode: null,
  lat: null,
  lng: null,
  locationAddress: '',
  locationType: null,
  postalCode: '',
  state: '',
  street: '',
  timeZone: ''
};

type RouteParams = { organisationId: string };

const NewLocation: FC<Props> = ({ networkId }) => {
  const { organisationId } = useParams<RouteParams>() as RouteParams;
  const location = useLocation();
  const { navigate } = useNavigationHistory();
  const locationState: Optional<{ fromMyOrganisationPage?: boolean }> = location.state;
  const isFromMyOrganisationPage: boolean = !!locationState?.fromMyOrganisationPage;
  const [createOrganisationLocation] = useNetworksCreateOrganisationLocationMutation();
  const { addError } = useNotification();

  const { data, loading, error } = useOrganisationContactsQuery({
    variables: {
      accountUuid: networkId,
      ids: [organisationId],
      nameContains: undefined
    },
    fetchPolicy: 'no-cache'
  });
  const { addSuccess } = useNotification();
  const { routes } = useNetworksContext();
  const myOrganisationIndex: string = routes.myOrganisation.index.getUrl();

  const handleSubmit = async (values: IOrganisationLocationValues): Promise<IOkOrErrorResult> => {
    const input: CreateOrganisationLocationInput = prepareLocationRequestData(values, networkId, organisationId);

    return performFormMutation({
      mutationFn: () =>
        createOrganisationLocation({
          variables: {
            input
          }
        }),
      onError: () => addError()
    });
  };

  const organisationPage: string = isFromMyOrganisationPage
    ? myOrganisationIndex
    : routes.organisation.details.getUrl(organisationId);

  const handleSuccess = (): void => {
    addSuccess('Your new location has been added successfully.');
    navigate(organisationPage);
  };

  const handleCancel = () => navigate(organisationPage);

  return (
    <QueryHandler
      data={data?.network?.organisations?.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonNewLocationLoading />}
    >
      {(organisation) => {
        const { name } = organisation;

        return (
          <Page
            browserTitle="New location"
            defaultBackUrl={myOrganisationIndex}
            title={`Add a new location to ${name}`}
            width="narrow"
          >
            <LocationForm
              initialValues={newLocationInitialValues}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              onSuccess={handleSuccess}
            />
          </Page>
        );
      }}
    </QueryHandler>
  );
};

export default NewLocation;
