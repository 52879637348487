import type { FC } from 'react';
import { useEffect, useReducer, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import NavigationPrompt from '@zen/Components/NavigationPrompt';
import type { DateRange } from '@zen/DesignSystem';
import { Button } from '@zen/DesignSystem';
import useAccount from '@zen/utils/hooks/useAccount';

import FreightChargeSection from '../FreightCharges/FreightChargeSection';
import HaulageChargeSection from '../HaulageCharges/HaulageChargeSection';
import OtherChargeSection from '../OtherCharges/OtherChargeSection';
import PortChargeSection from '../PortCharges/PortChargeSection';
import { RateCardProvider } from '../RateCardContext';
import RateCardNotes from '../RateCardNotes';
import type { RateCardReducerState } from '../reducer';
import { ActionType, prepareFreightPorts, rateCardReducer } from '../reducer';
import RateCardOverview from './components/RateCardOverview';
import { useRateCardValidityDatesQuery } from './graphql';
import { checkRelevantCharges, getNextAvailableDateRange, getRateCardsActions, prepareChargeFilters } from './helpers';
import { Applicability } from './types';

interface Props {
  initialValues: RateCardReducerState;
  isLoading?: boolean;
  onSubmit: (values: RateCardReducerState) => void;
}

const RateCardForm: FC<Props> = ({ initialValues, isLoading = false, onSubmit }) => {
  const [state, dispatch] = useReducer(rateCardReducer, initialValues);
  const [shouldPromptOnLeave, setShouldPromptOnLeave] = useState<boolean>(true);
  const { accountUuid } = useAccount();

  const { data } = useRateCardValidityDatesQuery({
    variables: { customerId: accountUuid, modeOfTransport: state.modeOfTransport, cargoType: state.cargoType },
    fetchPolicy: 'no-cache'
  });

  const prepareExistingValidityDates = (): DateRange[] => {
    const existingRateCardValidityDates: DateRange[] = data?.getRateCardValidityDates?.validityDates || [];

    if (initialValues) {
      return existingRateCardValidityDates.filter(
        ({ endDate, startDate }: DateRange) => endDate !== initialValues.endDate && startDate !== initialValues.startDate
      );
    }

    return existingRateCardValidityDates;
  };

  const existingDateRanges: DateRange[] = prepareExistingValidityDates();

  useEffect(() => {
    if (!state.startDate && !state.endDate) {
      const { startDate, endDate }: DateRange = getNextAvailableDateRange(existingDateRanges);

      dispatch({ type: ActionType.UPDATE_RATE_CARD_INPUT, payload: { startDate, endDate } });
    }
  }, [data, existingDateRanges, state.endDate, state.startDate]);

  useDeepCompareEffect(() => {
    dispatch({ type: ActionType.REINITIALIZE, payload: initialValues });
  }, [initialValues]);

  const { destinationCharges, destinationHaulageCharges, freightCharges, otherCharges, originCharges, originHaulageCharges } =
    state;
  const { originPortChargeFilters, destinationPortChargeFilters } = prepareChargeFilters(freightCharges);
  const rateCardActions: ReturnType<typeof getRateCardsActions> = getRateCardsActions(dispatch);
  const { destinationPorts, originPorts } = prepareFreightPorts(state.freightCharges);
  const shouldDisplayOriginCharges: boolean = checkRelevantCharges(freightCharges, Applicability.ORIGIN);
  const shouldDisplayDestinationCharges: boolean = checkRelevantCharges(freightCharges, Applicability.DESTINATION);
  const shouldDisplayOtherCharges: boolean = !!freightCharges.length;

  const handleSubmit = async (): Promise<void> => {
    await setShouldPromptOnLeave(false);

    onSubmit(state);
  };

  return (
    <RateCardProvider
      cargoType={state.cargoType}
      freightCharges={state.freightCharges}
      isEditable={true}
      modeOfTransport={state.modeOfTransport}
    >
      <div className="flex flex-col gap-y-6">
        <RateCardOverview
          disabledDateRanges={prepareExistingValidityDates()}
          onChange={rateCardActions.handleOverviewChange}
          values={state}
        />
        <FreightChargeSection
          freightCharges={freightCharges}
          onAdd={rateCardActions.handleAddFreightCharge}
          onDelete={rateCardActions.handleDeleteFreightCharge}
          onUpdate={rateCardActions.handleUpdateFreightCharge}
        />
        {shouldDisplayOriginCharges && (
          <HaulageChargeSection
            haulageCharges={originHaulageCharges}
            onAdd={rateCardActions.handleAddOriginHaulageCharge}
            onDelete={rateCardActions.handleDeleteOriginHaulageCharge}
            onUpdate={rateCardActions.handleUpdateOriginHaulageCharge}
            ports={originPorts}
            type="origin"
          />
        )}
        {shouldDisplayOriginCharges && (
          <PortChargeSection
            filters={originPortChargeFilters}
            onAdd={rateCardActions.handleAddOriginCharges}
            onCustomAdd={rateCardActions.handleAddCustomOriginCharge}
            onDelete={rateCardActions.handleDeleteCustomOriginCharge}
            onUpdate={rateCardActions.handleUpdateOriginCharge}
            onVisibilityUpdate={rateCardActions.handleVisibilityChangeOriginCharge}
            portCharges={originCharges}
            ports={originPorts}
            type="origin"
          />
        )}
        {shouldDisplayDestinationCharges && (
          <PortChargeSection
            filters={destinationPortChargeFilters}
            onAdd={rateCardActions.handleAddDestinationCharges}
            onCustomAdd={rateCardActions.handleAddCustomDestinationCharge}
            onDelete={rateCardActions.handleDeleteCustomDestinationCharge}
            onUpdate={rateCardActions.handleUpdateDestinationCharge}
            onVisibilityUpdate={rateCardActions.handleVisibilityChangeDestinationCharge}
            portCharges={destinationCharges}
            ports={destinationPorts}
            type="destination"
          />
        )}
        {shouldDisplayDestinationCharges && (
          <HaulageChargeSection
            haulageCharges={destinationHaulageCharges}
            onAdd={rateCardActions.handleAddDestinationHaulageCharge}
            onDelete={rateCardActions.handleDeleteDestinationHaulageCharge}
            onUpdate={rateCardActions.handleUpdateDestinationHaulageCharge}
            ports={destinationPorts}
            type="destination"
          />
        )}
        {shouldDisplayOtherCharges && (
          <OtherChargeSection
            onAdd={rateCardActions.handleAddOtherCharge}
            onDelete={rateCardActions.handleDeleteOtherCharge}
            onUpdate={rateCardActions.handleUpdateOtherCharge}
            otherCharges={otherCharges}
            ports={[...destinationPorts]}
          />
        )}
        <RateCardNotes editable={true} onChange={rateCardActions.handleNoteChange} value={state.note || ''} />
        <div className="flex items-center justify-between">
          <Button isLoading={isLoading} onClick={handleSubmit}>
            Publish
          </Button>
        </div>
        <NavigationPrompt
          confirmationNeeded={shouldPromptOnLeave}
          description="All unsaved changes will be lost."
          header="Leave page?"
          leaveLabel="Leave page"
          stayLabel="Stay"
        />
      </div>
    </RateCardProvider>
  );
};

export default RateCardForm;
