import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type BookingRateCardQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  originPort?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  destinationPort?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  modeOfTransport: GraphQLTypes.ModeOfTransport;
  cargoType: GraphQLTypes.CargoModeEnum;
  validOn?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Date']>;
}>;

export type BookingRateCardQueryResult = { __typename?: 'Query' } & {
  legacyGetRateCards?: GraphQLTypes.Maybe<
    { __typename?: 'LegacyRateCardConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'LegacyRateCardSummary' } & Pick<GraphQLTypes.LegacyRateCardSummary, 'name' | 'rateCardId'>
          >
        >
      >;
    }
  >;
};

export const BookingRateCardDocument = /* #__PURE__ */ gql`
  query bookingRateCard(
    $accountId: String!
    $originPort: String
    $destinationPort: String
    $modeOfTransport: ModeOfTransport!
    $cargoType: CargoModeEnum!
    $validOn: Date
  ) {
    legacyGetRateCards(
      customerDivisionId: $accountId
      originPort: $originPort
      destinationPort: $destinationPort
      modeOfTransport: $modeOfTransport
      cargoType: $cargoType
      validOn: $validOn
    ) {
      nodes {
        name
        rateCardId
      }
    }
  }
`;

/**
 * __useBookingRateCardQuery__
 *
 * To run a query within a React component, call `useBookingRateCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingRateCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingRateCardQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      originPort: // value for 'originPort'
 *      destinationPort: // value for 'destinationPort'
 *      modeOfTransport: // value for 'modeOfTransport'
 *      cargoType: // value for 'cargoType'
 *      validOn: // value for 'validOn'
 *   },
 * });
 */
export function useBookingRateCardQuery(
  baseOptions: Apollo.QueryHookOptions<BookingRateCardQueryResult, BookingRateCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BookingRateCardQueryResult, BookingRateCardQueryVariables>(BookingRateCardDocument, options);
}
export function useBookingRateCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingRateCardQueryResult, BookingRateCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<BookingRateCardQueryResult, BookingRateCardQueryVariables>(BookingRateCardDocument, options);
}
export type BookingRateCardQueryHookResult = ReturnType<typeof useBookingRateCardQuery>;
export type BookingRateCardLazyQueryHookResult = ReturnType<typeof useBookingRateCardLazyQuery>;
