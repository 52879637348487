import pDebounce from 'p-debounce';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { highlightQuery } from '@zen/Components/Form/utils';
import type { AsyncSelectProps } from '@zen/DesignSystem';
import { AsyncSelect, Modal, TextLink } from '@zen/DesignSystem';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import OrgLocForm from '@zen/Networks/Form/OrgLocForm';
import type { AssignmentTargetTypeEnum, NetworksOrgLoc, NetworksOrgLocOption } from '@zen/Networks/types';
import { AssignmentTypeValue } from '@zen/Networks/types';
import type { Division } from '@zen/types';
import { SEARCH_DEBOUNCE_DELAY } from '@zen/utils/constants';
import { useDivisionQuery } from '@zen/utils/hooks/graphql';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { IHandleChangeCallback } from '../networksContactHelper';
import { getOrgLocLabel, handleChangeCallback } from '../networksContactHelper';

interface Props extends Omit<AsyncSelectProps<NetworksOrgLoc>, 'formatOptionLabel' | 'loadOptions' | 'name'> {
  accountUuid: string;
  assignmentType?: AssignmentTypeValue;
  cargoId?: string;
  domainName?: AssignmentTargetTypeEnum;
  formTitle?: string;
  name: string;
  onChange: (value: Nullable<NetworksOrgLoc>) => void;
  showAddButton?: boolean;
}

const NetworksContactInput: FC<Props> = (props) => {
  const {
    accountUuid,
    assignmentType,
    autoFocus,
    cargoId,
    domainName,
    hasError,
    formTitle = 'Add a new location',
    isClearable = true,
    isLoading = false,
    isDisabled = false,
    name,
    onBlur,
    onChange,
    placeholder,
    showAddButton = true,
    value,
    variant = 'default'
  } = props;
  const [showForm, setShowForm] = useState<boolean>(false);
  const { check } = useGlobalPermissions();
  const { data } = useDivisionQuery({ variables: { id: accountUuid } });
  const accountsMigrationInProgress: boolean = useFeatureIsOn('accounts-migration-in-progress');

  const division: Optional<Division> = data?.legacyAccount?.division;
  const shouldUseDivisionAccount: boolean = !!division && assignmentType !== AssignmentTypeValue.ORIGIN_AGENT;
  const accountId: string = (shouldUseDivisionAccount ? division?.parentAccountId : accountUuid) || '';
  const canManageNetworks: boolean = check('networks.canManage');
  const isAddButtonVisible: boolean = (canManageNetworks || !!accountUuid || showAddButton) && !accountsMigrationInProgress;

  const handleInputChange: IHandleChangeCallback = handleChangeCallback({
    accountUuid: accountId,
    assignmentType,
    cargoId,
    domainName
  });

  const closeModal = (): void => setShowForm(false);

  const formatOptionLabel = (organisationLocation: NetworksOrgLocOption, inputValue: string): ReactNode =>
    highlightQuery(getOrgLocLabel(organisationLocation), inputValue);

  const handleAddNewLocation = (orgLoc: NetworksOrgLoc): void => {
    closeModal();
    onChange(orgLoc);
  };

  const debouncedHandleInputChange = pDebounce(handleInputChange, SEARCH_DEBOUNCE_DELAY);

  const renderFooter = (): ReactNode => {
    return <TextLink onClick={() => setShowForm(true)}>{formTitle}</TextLink>;
  };

  return (
    <>
      <div data-testid="networks-contact-input">
        <AsyncSelect<NetworksOrgLoc>
          autoFocus={autoFocus}
          defaultOptions={value ? [value] : undefined}
          dropdownFooterRenderer={isAddButtonVisible ? renderFooter : undefined}
          formatOptionLabel={formatOptionLabel}
          hasError={hasError}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isLoading={isLoading}
          loadOptions={debouncedHandleInputChange}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          optionKey="id"
          placeholder={placeholder}
          renderMenuInPortal={true}
          value={value}
          variant={variant}
        />
      </div>
      <Modal closeOnClickAway={true} isOpen={showForm} onClose={closeModal} title={formTitle}>
        <OrgLocForm accountUuid={accountUuid} buttonsLayout="fixed" handleAdd={handleAddNewLocation} handleCancel={closeModal} />
      </Modal>
    </>
  );
};

export default NetworksContactInput;

export type { Props as NetworksContactInputProps };
