import cx from 'classnames';
import type { FC, PropsWithChildren } from 'react';

type ContentWidth = 'full' | 'wide' | 'medium' | 'narrow' | 'narrower';

interface Props {
  className?: string;
  width?: ContentWidth;
}

const PageContent: FC<PropsWithChildren<Props>> = (props) => {
  const { width = 'full', className } = props;

  const classNames = cx(
    {
      'max-w-screen-xl': width === 'wide',
      'max-w-screen-lg': width === 'medium',
      'max-w-screen-md': width === 'narrow',
      'max-w-screen-sm': width === 'narrower'
    },
    'mx-6 lg:mx-10',
    className
  );

  return (
    <div className={classNames} data-testid="content">
      {props.children}
    </div>
  );
};

export default PageContent;
