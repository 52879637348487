import moment from 'moment';
import type { FC } from 'react';
import { useState } from 'react';

import Slideout, { SlideoutHeader } from '@zen/Components/Slideout';
import { Headline } from '@zen/DesignSystem';
import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation, performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import CargoReadyDateForm from '../components/CargoReadyDateForm';
import type { RichDateInput } from '../components/CargoReadyDateForm/types';
import DeliveryDateForm from '../components/DeliveryDateForm';
import { usePurchaseOrdersUpdateLotEstimatesMutation } from '../graphql';

interface Props {
  className?: string;
  estimates: {
    cargoReadyDate?: string;
    deliveryDate?: string;
  };
  isVisible: boolean;
  lotIds: string[];
  onClose: () => void;
  onSuccess: () => void;
  orderDate: Optional<string>;
}

enum EstimateStep {
  CARGO_READY_DATE = 1,
  DELIVERY_DATE = 2
}

const isCargoReadyStep = (step: EstimateStep) => step === EstimateStep.CARGO_READY_DATE;

const LotEstimatesSlideout: FC<Props> = ({ className, estimates, isVisible, lotIds, onClose, onSuccess, orderDate }) => {
  const [step, setStep] = useState<EstimateStep>(EstimateStep.CARGO_READY_DATE);
  const [cargoReadyDate, setCargoReadyDate] = useState<RichDateInput | undefined>();
  const [updateEstimates] = usePurchaseOrdersUpdateLotEstimatesMutation();
  const { addSuccess, addError } = useNotification();

  const handleCargoReadyDateSubmit = (values: RichDateInput) => {
    setCargoReadyDate(values);
    setStep(EstimateStep.DELIVERY_DATE);

    return Promise.resolve({ ok: true, error: null });
  };

  const handleSubmit = async (deliveryEstimate: RichDateInput): Promise<IOkOrErrorResult> => {
    if (!cargoReadyDate) {
      // this should never happen according to the form validation but need a return value
      return { ok: null, error: true };
    }

    return performFormMutation({
      mutationFn: () => updateEstimates({ variables: { input: { lotIds, cargoReadyDate, deliveryEstimate } } }),
      onError: () => addError()
    });
  };

  const handleSubmitSuccess = (): void => {
    addSuccess('Cargo ready date and delivery estimate have been updated.');
    onSuccess();
  };

  const handleSkip = async (): Promise<IOkOrErrorResult> => {
    if (!cargoReadyDate) {
      // this should never happen according to the form validation but need a return value
      return { ok: null, error: true };
    }

    return performMutation({
      mutationFn: () => updateEstimates({ variables: { input: { lotIds, cargoReadyDate } } }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Cargo ready date has been updated.');
        onSuccess();
      }
    });
  };

  const renderCargoReadyDateForm = () => (
    <>
      <Headline className="mb-4" level={3}>
        Step 1 / 2 - Update cargo ready date
      </Headline>
      <CargoReadyDateForm cargoReadyDate={estimates.cargoReadyDate} onSubmit={handleCargoReadyDateSubmit} orderDate={orderDate} />
    </>
  );

  const renderDeliveryDateForm = () => {
    const delayInDays = cargoReadyDate ? moment(cargoReadyDate.date).diff(estimates.cargoReadyDate, 'days') : 0;
    const newDate = moment(estimates?.deliveryDate).add(delayInDays, 'd').format(FORMAT_DATE_TRANSFERABLE);
    const newDeliveryDate = estimates.deliveryDate ? newDate : undefined;

    return (
      <>
        <Headline className="mb-4" level={3}>
          Step 2 / 2 - Update delivery estimate
        </Headline>
        <DeliveryDateForm
          cargoReadyDate={cargoReadyDate?.date}
          deliveryDate={newDeliveryDate}
          onSkip={handleSkip}
          onSubmit={handleSubmit}
          onSuccess={handleSubmitSuccess}
          previousDeliveryDate={estimates.deliveryDate}
        />
      </>
    );
  };

  return (
    <Slideout className={className} onOutsideClick={onClose} overlay={true} show={isVisible}>
      <div className="relative flex flex-col h-full" style={{ width: '38rem' }}>
        <SlideoutHeader onClose={onClose} title="Updating PO schedule" />
        <div className="px-6 pt-6 pb-20 overflow-y-auto">
          {isCargoReadyStep(step) ? renderCargoReadyDateForm() : renderDeliveryDateForm()}
        </div>
      </div>
    </Slideout>
  );
};

export default LotEstimatesSlideout;
