import { type FC, useState } from 'react';

import { Modal } from '@zen/DesignSystem';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import OtherChargeForm from '../OtherChargeForm';
import OtherChargeTable from '../OtherChargeTable';
import type { CostTrackingLocation } from './types';

interface Props {
  onAdd: (values: RateCardCharge[]) => void;
  onDelete: (index: number) => void;
  onUpdate: (index: number, values: Partial<RateCardCharge>) => void;
  otherCharges: RateCardCharge[];
  ports: CostTrackingLocation[];
}

const OtherChargeSection: FC<Props> = (props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { otherCharges, onAdd, onDelete, onUpdate, ports } = props;

  const handleAdd = (): void => {
    setModalOpen(true);
  };

  const handleClose = (): void => {
    setModalOpen(false);
  };

  return (
    <>
      <OtherChargeTable charges={otherCharges} onAdd={handleAdd} onDelete={onDelete} onUpdate={onUpdate} />
      <Modal
        closeOnClickAway={false}
        isOpen={isModalOpen}
        modalOverflowY="scroll"
        onClose={handleClose}
        title="Add other charges"
        width="full"
      >
        <OtherChargeForm onCancel={handleClose} onSubmit={onAdd} onSuccess={handleClose} ports={ports} />
      </Modal>
    </>
  );
};

export type { Props as OtherChargeSectionProps };

export default OtherChargeSection;
