import type { Account } from '@zen/Accounts/graphql/types';
import type { PermittedParties, PermittedParty } from '@zen/ActivityFeed';
import type { TargetPermissions } from '@zen/Booking/BookingDetails/components/ActivityFeedContainer/ActivityFeedContainer';
import type { AuthorizationResult, NetworksOrgLoc } from '@zen/Networks/types';
import { Role } from '@zen/Networks/types';

export interface PermittedRoles {
  manufacturers: NetworksOrgLoc[];
  organisationName: string;
  originAgent: NetworksOrgLoc;
  permissions: {
    canSendTextMessageToAgentForwarder: AuthorizationResult;
    canSendTextMessageToCustomerUser: AuthorizationResult;
    canSendTextMessageToManufacturer: AuthorizationResult;
  };
  supplier?: Account;
}

export default function createPermittedParties(
  permittedRoles: PermittedRoles,
  userRole?: Role,
  targetPermissions?: TargetPermissions
): PermittedParties {
  const permittedParties: PermittedParties = {};

  const { manufacturers, originAgent, organisationName, permissions, supplier } = permittedRoles;

  const {
    canSendTextMessageToAgentForwarder: { value: toAgent },
    canSendTextMessageToCustomerUser: { value: toCustomer },
    canSendTextMessageToManufacturer: { value: toManufacturer }
  } = permissions;

  const showCustomerPermissions: boolean =
    !targetPermissions?.isCustomerOnTarget || (targetPermissions?.isCustomerOnTarget && targetPermissions?.isSupplierOnTarget);

  if (toCustomer && organisationName && showCustomerPermissions) {
    permittedParties.customerUser = {
      role: Role.CUSTOMER_USER,
      organisationName
    };
  }

  if (toAgent && originAgent && userRole !== Role.AGENT_FORWARDER) {
    permittedParties.agentForwarder = {
      role: Role.AGENT_FORWARDER,
      organisationName: originAgent?.organisation?.name || ''
    };
  }

  const showSupplierPermissions: boolean =
    !targetPermissions?.isSupplierOnTarget || (targetPermissions?.isCustomerOnTarget && targetPermissions?.isSupplierOnTarget);

  if (toManufacturer && manufacturers && showSupplierPermissions) {
    permittedParties.manufacturer = manufacturers.map(
      (item): PermittedParty => ({
        role: Role.MANUFACTURER,
        organisationName: supplier?.tradingName || item.organisation?.name || ''
      })
    );
  }

  permittedParties.forwarder = {
    role: Role.ADMIN,
    organisationName: 'Zencargo'
  };

  return permittedParties;
}
