import type { FC } from 'react';

import NetworkContactItem from '@zen/Components/NetworkContactItem';
import type { OrganisationLocation } from '@zen/Networks/types';
import type { Optional } from '@zen/utils/typescript';

interface OrderOtherDetails {
  label: string;
  value: Optional<OrganisationLocation>;
}

export interface Props {
  buyer: Optional<OrganisationLocation>;
  collectionWarehouse: Optional<OrganisationLocation>;
  deliveryWarehouse: Optional<OrganisationLocation>;
  manufacturer: Optional<OrganisationLocation>;
  seller: Optional<OrganisationLocation>;
}

const Details: FC<Props> = (props) => {
  const { buyer, manufacturer, collectionWarehouse, deliveryWarehouse, seller } = props;

  const orderOtherDetails: OrderOtherDetails[] = [
    { label: 'manufacturer', value: manufacturer },
    { label: 'seller', value: seller },
    { label: 'buyer', value: buyer },
    { label: 'collection warehouse', value: collectionWarehouse },
    { label: 'delivery warehouse', value: deliveryWarehouse }
  ];

  return (
    <div className="grid grid-cols-2 gap-6" data-testid="order-other-details">
      {orderOtherDetails.map((detail: OrderOtherDetails) => {
        const { label, value } = detail;

        return (
          <div key={label} className="w-full max-w-lg mr-4">
            <NetworkContactItem className="h-full" isEditable={false} name={label} value={value} />
          </div>
        );
      })}
    </div>
  );
};

export default Details;
