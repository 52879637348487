import { matchRoutes } from 'react-router-dom';

import {
  accountSettingsRoutes,
  accountsRoutes,
  accrualRoutes,
  apiConsoleRoutes,
  bookingRequestRoutes,
  consolidationRoutes,
  customerAdminRoutes,
  dashboardRoutes,
  documentRoutes,
  exchangeRatesRoutes,
  financeRoutes,
  intakePlanningRoutes,
  networks,
  opsDashboardRoutes,
  opsQuoteRoutes,
  opsSettingsRoutes,
  opsShipmentRoutes,
  orderRoutes,
  productRoutes,
  productSettingsRoutes,
  quoteRoutes,
  quotesRoutes,
  rateCardRoutes,
  rulesEngineRoutes,
  settingsRoutes,
  shipmentRoutes,
  taskListRoutes,
  utilisationRoutes,
  warehouseIntakeRoutes,
  workflowsRoutes
} from '@zen/Routes';

import useGlobalPermissions from '../useGlobalPermissions';

const { manufacturerRequestsDetailsPage, rejectedDetailsPage, readyForApprovalDetailsPage } = bookingRequestRoutes;
const { orderDetails, orderList, orderDetailsPage } = orderRoutes;
const { shipmentIndex, shipmentDetailsPage } = shipmentRoutes;
const {
  legacyConsolidationIndex: consolidationIndex,
  consolidationShipmentsDetailsPage,
  consolidationAddShipmentsDetailsPage
} = consolidationRoutes;

const customerNetworksRoutes = networks('customer');
const zencargoNetworksRoutes = networks('zencargo');

interface Route {
  path: string;
}

export const routeToEntityMapping: Record<string, string> = {
  [accountSettingsRoutes.index.path]: 'accounts',
  [accountsRoutes.accountsIndex.path]: 'accounts',
  [accrualRoutes.index.path]: 'accruals',
  [bookingRequestRoutes.requestIndex.path]: 'bookingRequests',
  [consolidationAddShipmentsDetailsPage.path]: 'consolidation',
  [consolidationIndex.path]: 'consolidation',
  [consolidationShipmentsDetailsPage.path]: 'consolidation',
  [customerAdminRoutes.allShipments.path]: 'customerAdmin',
  [customerAdminRoutes.index.path]: 'customerAdmin',
  [customerNetworksRoutes.myOrganisation.index.path]: 'networks',
  [dashboardRoutes.dashboard.path]: 'dashboard',
  [documentRoutes.index.path]: 'documentCreation',
  [exchangeRatesRoutes.manageExchangeRatesIndex.path]: 'backoffice',
  [financeRoutes.index.path]: 'freightSpend',
  [intakePlanningRoutes.index.path]: 'intakePlanning',
  [manufacturerRequestsDetailsPage.path]: 'bookingRequests',
  [opsDashboardRoutes.dashboardIndex.path]: 'operationsDashboard',
  [opsQuoteRoutes.quoteIndex.path]: 'operationsDashboard',
  [opsSettingsRoutes.opsSettingsIndex.path]: 'operationsSettings',
  [opsShipmentRoutes.allShipments.path]: 'operationsDashboard',
  [opsShipmentRoutes.shipmentIndex.path]: 'operationsDashboard',
  [orderDetails.path]: 'purchaseOrders',
  [orderDetailsPage.path]: 'purchaseOrders',
  [orderList.path]: 'purchaseOrders',
  [productRoutes.index.path]: 'products',
  [productSettingsRoutes.productSettingsIndex.path]: 'productSettings',
  [quoteRoutes.quoteDetailsPage.path]: 'quotes',
  [quotesRoutes.quoteIndex.path]: 'quotes',
  [readyForApprovalDetailsPage.path]: 'bookingRequests',
  [rejectedDetailsPage.path]: 'bookingRequests',
  [rulesEngineRoutes.index.path]: 'rulesEngine',
  [settingsRoutes.index.path]: 'settings',
  [shipmentDetailsPage.path]: 'bookings',
  [shipmentIndex.path]: 'bookings',
  [taskListRoutes.taskListAllOpenTasks.path]: 'taskList',
  [taskListRoutes.taskListIndex.path]: 'taskList',
  [taskListRoutes.taskListUnassignedTasks.path]: 'taskList',
  [taskListRoutes.taskListYourTasks.path]: 'taskList',
  [workflowsRoutes.workflowRulesIndex.path]: 'workflows',
  [workflowsRoutes.workflowsFlows.path]: 'workflows',
  [workflowsRoutes.workflowsIndex.path]: 'workflows',
  [workflowsRoutes.workflowsTriggers.path]: 'workflows',
  [zencargoNetworksRoutes.myOrganisation.index.path]: 'zencargoNetwork'
};

const routes: Route[] = Object.keys(routeToEntityMapping).map((route) => ({ path: route }));

const routesWhitelist: Route[] = [
  { path: apiConsoleRoutes.index.path },
  { path: shipmentRoutes.trackedShipmentIndex.path },
  { path: utilisationRoutes.index.path },
  { path: rateCardRoutes.index.path },
  { path: warehouseIntakeRoutes.index.path }
];

const useRoutePermissions = () => {
  const { check } = useGlobalPermissions();

  const canAccessRoute = (path: string): boolean => {
    const isRouteWhitelisted: boolean = routesWhitelist.findIndex((route) => route.path === path) !== -1;

    if (isRouteWhitelisted) return true;

    const entity: string | undefined = routeToEntityMapping[path];

    return entity ? check(`${entity}.canAccessRoute`) : false;
  };

  const canAccessUrl = (url: string): boolean => {
    const isUrlWhitelisted: boolean = !!matchRoutes(routesWhitelist, url);

    if (isUrlWhitelisted) return true;

    const matchedPath: string | undefined = matchRoutes(routes, url)?.[0]?.route?.path;

    return matchedPath ? check(`${routeToEntityMapping[matchedPath]}.canAccessRoute`) : false;
  };

  return {
    canAccessRoute,
    canAccessUrl
  };
};

export default useRoutePermissions;
