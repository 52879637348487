import type { FC } from 'react';

import type { DateWithTimeFields } from '@zen/Components/DateWithTimeForm';
import type { DateWithTimePickerInputProps } from '@zen/DesignSystem';
import { DateWithTimePickerInput } from '@zen/DesignSystem';

import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import type { FormFieldInputProps, FormInstance } from '../types';

type Props = Omit<DateWithTimePickerInputProps, 'onChange'> &
  FormFieldProps & {
    onChange?: (date: DateWithTimeFields) => void;
  };

const FormDateWithTimePickerInput: FC<Props> = (props) => {
  return (
    <FormField {...props}>
      {(field: FormFieldInputProps<DateWithTimeFields>, form: FormInstance<DateWithTimeFields>) => {
        const handleChange = (dateWithTime: DateWithTimeFields): void => {
          form.setFieldValue(props.name, dateWithTime);

          props.onChange?.(dateWithTime);
        };

        return <DateWithTimePickerInput {...field} hasError={field.error} onChange={handleChange} />;
      }}
    </FormField>
  );
};

export default FormDateWithTimePickerInput;
