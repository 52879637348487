import type { FC } from 'react';

import type { FormFieldProps as Props } from '../FormField';
import FormField from '../FormField';

const FormHiddenInput: FC<Props> = (props) => {
  return (
    <FormField hideLabel={true} noMargin={true} {...props}>
      {(field: { name: string; value: string | number }) => <input name={field.name} type="hidden" value={field.value || ''} />}
    </FormField>
  );
};

export default FormHiddenInput;
