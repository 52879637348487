import type { FC } from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useRole from '@zen/Auth/hooks/useRole';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import BookingNavigationPrompt from '@zen/Booking/NewBooking/BookingNavigationPrompt';
import BasketHeader from '@zen/Components/BasketHeader';
import FixedHeader from '@zen/Components/FixedHeader';
import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import OrderBasketList from '@zen/Components/OrderBasketList';
import PageContent from '@zen/Components/PageContent';
import { Role } from '@zen/Networks';
import { newBookingRoutes, orderRoutes, requestBookingRoutes } from '@zen/Routes';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { QueryParams } from '@zen/utils/QueryParams';
import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';

interface Props {
  onCustomerChangeRequest: () => void;
}

const OrderBasketHeader: FC<Props> = ({ onCustomerChangeRequest }) => {
  const { check } = useGlobalPermissions();
  const role = useRole();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { navigateBack } = useNavigationHistory();
  const { items, hasItems, removeAllItems, removeItems } = useContext(BasketContext);
  const queryParams: QueryParams = parseQueryParams(search);
  const onlyOwnAccount: boolean = !!queryParams.onlyOwnAccount;

  const canRequestBooking: boolean =
    ((check('bookings.canRequest') && role === Role.MANUFACTURER) || (check('bookings.canRequest') && !onlyOwnAccount)) &&
    role !== Role.AGENT_FORWARDER;

  const newBookingRawUrl: string = newBookingRoutes.newBookingIndex.getUrl();
  const newBookingUrl: string = onlyOwnAccount ? `${newBookingRawUrl}?onlyOwnAccount=true` : newBookingRawUrl;
  const requestBookingUrl: string = requestBookingRoutes.requestBooking.getUrl();

  const bookingUrl: string = canRequestBooking ? requestBookingUrl : newBookingUrl;

  const handleLeave = (): void => {
    removeAllItems?.();
  };

  const handleCancel = (): void => {
    navigateBack(orderRoutes.orderList.getUrl());

    handleLeave();
  };

  const handleSubmit = (): void => navigate(bookingUrl);

  return (
    <div className="flex flex-col w-full -ml-10 bg-azure-lightest">
      <FixedHeader>
        <BasketHeader
          className="items-center min-h-[7rem] mx-10"
          headline="Select all the line items / lots you want to book."
          isSubmitDisabled={!hasItems}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          openCustomerModal={onCustomerChangeRequest}
          submitText="Book"
        />
        <PageContent className="border-t border-b border-solid border-grey-lighter">
          {!hasItems && <div className="py-12 italic text-center text-grey-base">No lots selected</div>}
          {hasItems && <OrderBasketList items={items} onDelete={(id) => removeItems([id])} />}
        </PageContent>
      </FixedHeader>
      <BookingNavigationPrompt allowedPaths={[newBookingRawUrl, requestBookingUrl]} onLeave={handleLeave} />
    </div>
  );
};

export default OrderBasketHeader;
