import type { Option } from '@zen/DesignSystem';

import type { ChargeBasis } from './types';

export const chargeBasisLabelMapping: Record<string, string> = {
  CONTAINER_X20DV: "20' standard",
  CONTAINER_X20HC: "20' high cube",
  CONTAINER_X40DV: "40' standard",
  CONTAINER_X40HC: "40' high cube",
  CONTAINER_X45HC: "45' high cube"
};

export const getOptions = (list: ChargeBasis[]): Option<string>[] => {
  return list.map(
    ({ id, name }: ChargeBasis): Option<string> => ({
      label: chargeBasisLabelMapping[name] || name,
      value: id
    })
  );
};
