import cx from 'classnames';
import type { FC, ReactElement, ReactNode, SyntheticEvent } from 'react';

import type { IconName } from '@zen/Styleguide';
import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import Icon from '../Icon';
import Tooltip from '../Tooltip';
import { iconMapping } from './helpers';
import Pin from './pin.svg?react';
import PinShadow from './pin-shadow.svg?react';
import type { MarkerColor, MarkerIcon } from './types';

interface Props {
  color?: MarkerColor;
  icon?: MarkerIcon;
  isDisabled?: boolean;
  isHighlighted?: boolean;
  isSelected?: boolean;
  lat: number;
  lng: number;
  onClick?: () => void;
  tooltipContent?: ReactNode;
}

export const displayName = 'Design System/Marker';

const Marker: FC<Props> = (props) => {
  const { trackEvent } = useTracking();

  const {
    color = 'blue',
    icon = 'pin',
    isHighlighted = false,
    isDisabled = false,
    isSelected = false,
    onClick,
    tooltipContent
  } = props;

  const iconName: IconName = iconMapping[icon];
  const isActive: boolean = isHighlighted || isSelected;

  const wrapperClassNames: string = cx(
    {
      'z-20': isSelected,
      'z-30': isHighlighted && !isDisabled,
      '-translate-y-full group z-10 hover:z-40': !isDisabled
    },
    'relative flex justify-center items-center transform -translate-x-1/2'
  );

  const pinWrapperClassNames: string = cx(
    'absolute inset-0 z-10 flex items-center justify-center transition hover:-translate-y-1',
    { 'transition -translate-y-1': isHighlighted }
  );

  const pinClassNames: string = cx(
    {
      'text-azure-base': color === 'blue',
      'fill-azure-lighter': color === 'blue' && !isSelected,
      'text-green-base fill-green-base': color === 'green',
      'fill-green-light': color === 'green' && !isSelected,
      'text-red-base fill-red-base': color === 'red',
      'fill-red-light': color === 'red' && !isSelected,
      'text-orange-base fill-orange-base': color === 'orange',
      'fill-orange-light': color === 'orange' && !isSelected,
      'fill-azure-base': isActive,
      'cursor-pointer ': !isDisabled
    },
    'absolute z-10'
  );

  const iconClassNames: string = cx(
    { 'text-navy-dark': !isSelected },
    { 'text-white': isSelected },
    'pointer-events-none -mt-0.5 z-20'
  );

  const handleClick = (event: SyntheticEvent): void => {
    if (isDisabled) {
      return;
    }

    const trackingEvent = createTrackingEvent(displayName, 'click', event);

    trackEvent(trackingEvent);
    onClick?.();
  };

  const renderMarker = (): ReactElement => {
    return (
      <div
        className={wrapperClassNames}
        data-testid="marker"
        onClick={handleClick}
        style={{ width: '32px', height: '34px' }}
        {...createTrackingParentAttribute('marker')}
      >
        <span className={pinWrapperClassNames}>
          <Pin className={pinClassNames} />
          <span className={iconClassNames} style={{ fontSize: '16px' }} {...createTrackingLabelAttribute(iconName)}>
            <Icon className="pointer-events-none " icon={iconName} />
          </span>
        </span>
        <PinShadow className="absolute" />
      </div>
    );
  };

  const renderMarkerWithTooltip = (): ReactElement => {
    return (
      <Tooltip placement="bottom-start" tooltipContent={tooltipContent}>
        {renderMarker()}
      </Tooltip>
    );
  };

  return tooltipContent ? renderMarkerWithTooltip() : renderMarker();
};

export type { Props as MarkerType };

export default Marker;
