import type { FC } from 'react';

import type { GlobalSearchResultItem } from '../types';
import { buildSubtitle, buildTitle } from './helpers';

interface Props {
  query: string;
  result: GlobalSearchResultItem;
}

const SearchResult: FC<Props> = ({ result, query }) => {
  return (
    <>
      <div className="text-grey-dark text-sm leading-5 mb-1">{buildTitle(result, query)}</div>
      <div className="text-grey-light text-xs leading-4">{buildSubtitle(result)}</div>
    </>
  );
};

export default SearchResult;
