import { useContext, useMemo } from 'react';

import type { BusinessUnit, UserProfile } from '@zen/Auth/UserDataProvider/types';
import UserContext from '@zen/Auth/UserDataProvider/UserContext';
import type { AccountStatus } from '@zen/graphql/types.generated';

import type { Optional } from '../typescript';

interface Account {
  accountStatus: Optional<AccountStatus>;
  accountUuid: string;
  divisions: BusinessUnit[];
  parentAccountId: string;
  referencePrefix: Optional<string>;
  refetchUser: () => void;
  userProfile: UserProfile;
}

const useAccount = (): Account => {
  const { accountUuid, accountStatus, parentAccountId, referencePrefix, userProfile, refetchUser, divisions } =
    useContext(UserContext);

  return useMemo(
    () => ({ accountUuid, accountStatus, parentAccountId, referencePrefix, userProfile, refetchUser, divisions }),
    [accountUuid, accountStatus, parentAccountId, referencePrefix, userProfile, refetchUser, divisions]
  );
};

export default useAccount;
