import type { FC } from 'react';

import EditableContentContainer from '@zen/Components/EditableContentContainer';

import DeactivateAccountButton from '../DeactivateAccountButton';

interface Props {
  accountId: string;
  tradingName: string;
}

const DeactivateAccount: FC<Props> = ({ accountId, tradingName }) => {
  return (
    <EditableContentContainer heading="Deactivate account">
      <div className="col-span-full">
        Deactivating this account will immediately suspend access for all members. Accounts can be reactivated at any time.
      </div>
      <DeactivateAccountButton accountId={accountId} tradingName={tradingName} />
    </EditableContentContainer>
  );
};

export default DeactivateAccount;
