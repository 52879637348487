import type { FC } from 'react';

import AccountSwitcherForm from '@zen/Components/AccountSwitcherForm';

import VisitedAccountsMenu from '../VisitedAccountsMenu';

interface Props {
  className?: string;
  onAccountChange?: () => void;
}

const AccountsMenu: FC<Props> = ({ className, onAccountChange }) => {
  return (
    <div className={className}>
      <AccountSwitcherForm label="Account name" onAccountChange={onAccountChange} submitOnChange={true} />
      <VisitedAccountsMenu onAccountChange={onAccountChange} />
    </div>
  );
};

export default AccountsMenu;
