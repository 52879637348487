import type { FC } from 'react';

import type { AsyncSelectProps } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import type { Contact, ContactTypeEnum } from '../../ContactInput';
import ContactInput from '../../ContactInput';
import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import type { FormFieldInputProps, FormInstance } from '../types';

interface Props extends FormFieldProps, Omit<AsyncSelectProps<Contact>, 'formatOptionLabel' | 'loadOptions' | 'name'> {
  contactTypes?: ContactTypeEnum[];
  onSelect?: (contact: Nullable<Contact>) => {};
}

const FormContactInput: FC<Props> = (props) => {
  const { contactTypes } = props;

  return (
    <FormField {...props}>
      {(field: FormFieldInputProps<Contact>, form: FormInstance<Contact>) => {
        const { label, ...fieldRest } = field;

        return (
          <ContactInput
            {...fieldRest}
            contactTypes={contactTypes}
            hasError={field.error}
            onChange={(value: Nullable<Contact>) => {
              form.setFieldValue(field.name, value);

              props.onSelect?.(value);
            }}
          />
        );
      }}
    </FormField>
  );
};

export default FormContactInput;
