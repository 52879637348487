import type { ReactNode } from 'react';
import { useMemo } from 'react';

import type { TableProps } from '@zen/DesignSystem';
import { Table } from '@zen/DesignSystem';

import SkeletonLoading from '../SkeletonLoading';

type Props<T extends {}> = Pick<TableProps<T>, 'className' | 'columns' | 'hiddenColumns' | 'order' | 'tableId' | 'title'> & {
  rowCount?: number;
  showTitle?: boolean;
};

const GeneratedSkeleton = () => {
  return <SkeletonLoading className="ml-0 mr-0" height={20} width="w-full" />;
};

const SkeletonTableLoading = <T extends {}>({
  className,
  rowCount = 20,
  columns,
  order,
  hiddenColumns = [],
  tableId,
  showTitle = true,
  title = <SkeletonLoading height={20} width="w-60" />
}: Props<T>) => {
  const generatedRowCount: number[] = useMemo(() => Array.from({ length: rowCount }), [rowCount]);

  const generatedColumns = columns.map((column) => {
    return {
      title: column.title,
      dataIndex: column.key,
      key: column.key,
      sortKey: column.sortKey,
      ...(column?.sortable && { sortable: column.sortable }),
      ...(column?.resizable && { resizable: column.resizable }),
      ...(column?.fixed && { fixed: column.fixed }),
      ...(column?.width && { width: column.width })
    };
  });

  const loadingRowCount = useMemo(
    () =>
      generatedColumns.reduce((prev, { key }) => {
        return { ...prev, [key]: <GeneratedSkeleton /> };
      }, {}),
    [generatedColumns]
  );
  const data = generatedRowCount.map(() => ({ ...loadingRowCount }));
  const tableTitle: ReactNode = showTitle && title;

  return (
    <Table
      className={className}
      columns={generatedColumns}
      data={data}
      hiddenColumns={hiddenColumns}
      loading={true}
      order={order}
      tableId={tableId}
      title={tableTitle}
    />
  );
};

export type { Props as SkeletonTableLoadingProps };

export default SkeletonTableLoading;
