import type { ChangeEvent, FocusEvent, Ref } from 'react';
import { forwardRef } from 'react';

import type { FormInstance } from '@zen/Components/Form/types';
import { Input } from '@zen/DesignSystem';

import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';

interface Props extends FormFieldProps {
  autoFocus?: boolean;
  disabled?: boolean;
  id?: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>, name: string, form: FormInstance<unknown>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>, name: string, form: FormInstance<unknown>) => void;
  readOnly?: boolean;
  ref?: Ref<HTMLInputElement>;
  step?: number;
  type?: string;
}

interface FieldType {
  name: string;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

const FormInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { onBlur, onChange, type, autoFocus = false, ...rest } = props;

  return (
    <FormField {...rest}>
      {(field: FieldType, form: FormInstance<unknown>) => (
        <Input
          {...field}
          ref={ref}
          autoFocus={autoFocus}
          id={field.name}
          onBlur={(event: FocusEvent<HTMLInputElement>) => {
            field.onBlur(event);

            if (onBlur) {
              onBlur(event, field.name, form);
            }
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            field.onChange(event);

            if (onChange) {
              onChange(event, field.name, form);
            }
          }}
          type={type || 'text'}
        />
      )}
    </FormField>
  );
});

export default FormInput;
