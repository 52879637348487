import type { RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

export const useHover = <T extends HTMLElement = HTMLInputElement>(): [RefObject<T>, boolean] => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const ref = useRef<T>(null);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    const node = ref.current;

    if (!node) {
      return;
    }

    node.addEventListener('mouseenter', handleMouseEnter);
    node.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      node.removeEventListener('mouseenter', handleMouseEnter);
      node.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return [ref, isHovered];
};
