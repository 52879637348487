import type { ReactElement } from 'react';
import type { ChevronProps } from 'react-day-picker';

import type { IconName } from '@zen/Styleguide';

import Icon from '../../../Icon';

export type Orientation = Exclude<ChevronProps['orientation'], undefined>;

const Chevron = ({ orientation = 'left' }: ChevronProps): ReactElement => {
  const icons: Record<Orientation, IconName> = {
    left: 'zicon-arrow-left',
    right: 'zicon-arrow-right',
    up: 'zicon-arrow-up',
    down: 'zicon-arrow-down'
  };

  return <Icon icon={icons[orientation]} interactive={true} />;
};

export default Chevron;
export type { ChevronProps } from 'react-day-picker';
