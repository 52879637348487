import type { FC } from 'react';
import { useState } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import type { MenuItemType } from '@zen/DesignSystem';
import { ContextMenu } from '@zen/DesignSystem';
import BookingRequestApproveModal from '@zen/LegacyBookingRequests/BookingRequestApproveModal';
import BookingRequestRejectModal from '@zen/LegacyBookingRequests/BookingRequestRejectModal';
import type { BookingRequestField } from '@zen/LegacyBookingRequests/types';
import { bookingRequestRoutes } from '@zen/Routes';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  bookingRequest: BookingRequestField;
  zencargoReference: string;
}

const ActionHandleContainer: FC<Props> = ({ bookingRequest, zencargoReference }) => {
  const [isApproveModalOpen, setShowApproveModal] = useState<boolean>(false);
  const [isRejectModalOpen, setShowRejectModal] = useState<boolean>(false);
  const { navigate } = useNavigationHistory();

  const { forwarderApproved, rejected } = bookingRequest;

  const permission: keyof BookingRequestField = !forwarderApproved ? 'canUpdateForwarderApproved' : 'canUpdateCustomerApproved';
  const canApprove: boolean = checkPermission<BookingRequestField>(bookingRequest, permission);
  const canReject: boolean = checkPermission<BookingRequestField>(bookingRequest, 'canReject');
  const canEdit: boolean =
    checkPermission<BookingRequestField>(bookingRequest, 'canRevertRejection') ||
    checkPermission<BookingRequestField>(bookingRequest, 'canRevertRejectionAsManufacturer');

  const showApproveOption: boolean = canApprove && !rejected;
  const showRejectOption: boolean = canReject && !rejected;
  const showEditOption: Optional<boolean> = canEdit && rejected;

  const { rejected: rejectedRoute } = bookingRequestRoutes;
  const rejectedBookingUrl: string = `${rejectedRoute.getUrl()}/details/${zencargoReference}`;

  const menuItems: MenuItemType[] = [
    ...(showApproveOption
      ? [
          {
            icon: 'zicon-tick' as const,
            label: 'Approve',
            onClick: () => setShowApproveModal(true)
          }
        ]
      : []),
    ...(showRejectOption
      ? [
          {
            icon: 'zicon-close' as const,
            label: 'Reject',
            onClick: () => setShowRejectModal(true)
          }
        ]
      : []),
    ...(showEditOption
      ? [
          {
            icon: 'zicon-edit' as const,
            label: 'Edit booking',
            onClick: () => navigate(rejectedBookingUrl)
          }
        ]
      : [])
  ];

  const showContextMenu: boolean = menuItems.length > 0;

  return (
    <>
      {showContextMenu && <ContextMenu inline={false} items={menuItems} />}
      {isApproveModalOpen && (
        <BookingRequestApproveModal
          forwarderApproved={!!forwarderApproved}
          isOpen={isApproveModalOpen}
          onClose={() => setShowApproveModal(false)}
          zencargoReference={zencargoReference}
        />
      )}
      {isRejectModalOpen && (
        <BookingRequestRejectModal
          isOpen={isRejectModalOpen}
          onClose={() => setShowRejectModal(false)}
          zencargoReference={zencargoReference}
        />
      )}
    </>
  );
};

export default ActionHandleContainer;
