import type { BookingStageEnum } from '@zen/Booking';
import { isRoadBooking, JourneyLegTypeEnum } from '@zen/Booking';
import type { CoreCargo } from '@zen/Cargo';
import { CargoItemTypeEnum, coreCargoTypeLabelMapping, getCargoQuantityAndLabel } from '@zen/Cargo';
import { getLegacyCargoItemType } from '@zen/Cargo/cargoDictionaryMapping.helper';
import type { TabViewConfig } from '@zen/Components/TabViewController/types';
import type { JourneyLeg } from '@zen/Journey';
import { getMilestones } from '@zen/Journey';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { CanAddCargoReasonEnum, ModeOfTransport } from '../types';
import type { CargoOverviewContextData } from './CargoOverviewContext';
import CargoAndRouteDetails from './components/CargoAndRouteDetails';
import type { CargoJourney, CargoOverviewData, TabsConfigParams } from './types';

export const getTabLabel = (cargo: CoreCargo): string => {
  const { cargoType, containerNumber } = cargo;

  const legacyCargoType: Optional<CargoItemTypeEnum> = getLegacyCargoItemType(cargoType);
  const isContainerType: boolean = legacyCargoType === CargoItemTypeEnum.CONTAINER;

  if (isContainerType) {
    const cargoTypeLabel: string = cargoType ? coreCargoTypeLabelMapping[cargoType] : '';

    return containerNumber ? `${cargoTypeLabel}: ${containerNumber}` : cargoTypeLabel;
  }

  return getCargoQuantityAndLabel(cargo);
};

export const getTabsConfig = (params: TabsConfigParams, highlightedCargoId: string): TabViewConfig[] => {
  const {
    canManageCargo,
    canViewShipmentTracking,
    cargoList,
    cargoMode,
    cargoReadyDate,
    cargoSummary,
    customsOnly,
    hasJourneyInformation,
    modeOfTransport,
    zencargoReference
  } = params;

  return cargoList.map((cargo) => ({
    label: getTabLabel(cargo),
    isDefault: cargo.id === highlightedCargoId,
    component: (
      <CargoAndRouteDetails
        key={cargo.id}
        canManageCargo={canManageCargo}
        canViewShipmentTracking={canViewShipmentTracking}
        cargo={cargo}
        cargoCount={cargoList.length}
        cargoMode={cargoMode}
        cargoReadyDate={cargoReadyDate}
        cargoSummary={cargoSummary}
        customsOnly={customsOnly}
        hasJourneyInformation={hasJourneyInformation}
        modeOfTransport={modeOfTransport}
        zencargoReference={zencargoReference}
      />
    )
  }));
};

interface ContextArgs {
  accountId: string;
  bookingStage: Optional<BookingStageEnum>;
  cargoList: Optional<CoreCargo[]>;
  cargoReadyDate: Optional<string>;
  isCollectionEnabled: Optional<boolean>;
  modeOfTransport: Optional<ModeOfTransport>;
  zencargoReference: string;
}

export const getCargoOverviewContext = (args: ContextArgs): CargoOverviewContextData => {
  const { accountId, bookingStage, cargoReadyDate, cargoList, isCollectionEnabled, modeOfTransport, zencargoReference } = args;

  return {
    accountId,
    bookingStage,
    cargoCount: cargoList?.length || 0,
    cargoIds: cargoList?.map(({ id }) => id) || [],
    cargoPreLegsMilestones: getCargoMilestones(cargoList, 'preLegs'),
    cargoMilestones: getCargoMilestones(cargoList),
    cargoReadyDate,
    carriageLegs: getCarriageLegs(cargoList),
    isCollectionEnabled: !!isCollectionEnabled,
    modeOfTransport,
    zencargoReference
  };
};

export const getCarriageLegs = (coreCargos: Optional<CoreCargo[]>): JourneyLeg[] => {
  const legs = coreCargos?.[0]?.journey?.legs || [];

  return legs.filter((leg: JourneyLeg) => leg.type === JourneyLegTypeEnum.CARRIAGE);
};

export const getCargoMilestones = (coreCargos: Optional<CoreCargo[]>, legType: 'preLegs' | 'legs' = 'legs') => {
  if (!coreCargos?.length) return {};

  return coreCargos?.reduce((prev, { id, journey }) => {
    return {
      ...prev,
      [id]: legType === 'legs' ? getMilestones(journey?.legs || []) : journey?.preLegsShippingMilestones || []
    };
  }, {});
};

export const getAddCargoTooltipMessage = (
  reason: Optional<CanAddCargoReasonEnum>,
  modeOfTransport: ModeOfTransport
): Nullable<string> => {
  if (!reason || isRoadBooking(modeOfTransport)) return null;

  const locationType: Record<ModeOfTransport, string> = {
    [ModeOfTransport.AIR]: 'origin airport',
    [ModeOfTransport.OCEAN]: 'port of loading',
    [ModeOfTransport.RAIL]: 'origin rail station',
    [ModeOfTransport.TRUCK]: ''
  };

  const reasonLabel: Record<CanAddCargoReasonEnum, string> = {
    [CanAddCargoReasonEnum.BOOKING_DEPARTED_ORIGIN_TERMINAL]: `You cannot add more cargos after your booking has departed the ${locationType[modeOfTransport]}`
  };

  return reasonLabel[reason];
};

export const hasJourneyData = (data: Optional<CargoOverviewData>): boolean => {
  const cargoJourneys = data?.cargoJourneys?.journeys || [];
  const cargoItems = data?.cargo?.cargoItems || [];

  return (
    cargoItems.length === cargoJourneys.length &&
    cargoItems.length > 0 &&
    cargoJourneys.every(({ journey }: CargoJourney) => journey?.legs && journey.legs.length > 0)
  );
};
