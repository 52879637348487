import pDebounce from 'p-debounce';
import { useEffect } from 'react';

import type { Option } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import { useAllAccountsLazyQuery } from '../graphql/allAccounts.generated';
import type { Account } from '../graphql/types';
import { AccountStatus } from '../types';

type SearchableAccountOptionsArguments = {
  selectedValues?: string[]; // TODO - IMPLEMENT QUERY FILTER TO TAKE ACCOUNT_IDS
};

export const useSearchableAccountOptions = (args: SearchableAccountOptionsArguments) => {
  const { selectedValues } = args;
  const [fetchAccounts, { data, loading, error }] = useAllAccountsLazyQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (selectedValues) {
      fetchAccounts({
        variables: {
          accountsFiltersInput: {
            accountIds: selectedValues,
            status: [AccountStatus.ACTIVE]
          },
          nestedBusinessUnits: false
        }
      });
    } else {
      fetchAccounts({
        variables: {
          accountsFiltersInput: {
            searchQuery: '',
            status: [AccountStatus.ACTIVE]
          },
          nestedBusinessUnits: false
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (query: string): void => {
    if (query) {
      fetchAccounts({
        variables: {
          accountsFiltersInput: {
            status: [AccountStatus.ACTIVE],
            searchQuery: query
          },
          nestedBusinessUnits: false
        }
      });
    }
  };

  const debounceDelay: number = 300;
  const debouncedHandleInputChange = pDebounce(handleInputChange, debounceDelay);

  const createAccountOption = (account: Optional<Account>): Option<string> => ({
    label: account?.tradingName || '',
    value: account?.id || ''
  });

  const options: Option<string>[] = data?.accounts?.nodes?.map(createAccountOption) || [];

  return { options, loading, error, onSearch: debouncedHandleInputChange };
};
