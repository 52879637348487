import { type FC, type ReactNode, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { AsyncSelect } from '@zen/DesignSystem';
import { useTimeTracker } from '@zen/utils/hooks/useTimeTracking';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Nullable } from '@zen/utils/typescript';

import { useLiveGlobalSearchLazyQuery } from './graphql';
import { rankResults } from './helpers';
import SearchResult from './SearchResult';
import { getEntityType, getResultUrl, getSearchableFieldLabel } from './SearchResult/helpers';
import type { GlobalSearchResultItem, SearchableField } from './types';
import { LiveGlobalSearchTrackingAction, LiveGlobalSearchTrackingCategory } from './types';

const GlobalSearch: FC = () => {
  const [search] = useLiveGlobalSearchLazyQuery();

  const searchReference = useRef<string>('');
  const numberOfResults = useRef<number>(0);
  const searching = useRef<boolean>(false);
  const navigate = useNavigate();

  const { resetTime, getTimeElapsed } = useTimeTracker();
  const { trackEvent } = useTracking();

  const renderOptionLabel = (value: GlobalSearchResultItem, query: string): ReactNode => {
    return <SearchResult query={query} result={value} />;
  };

  const handleInputChange = async (query: string): Promise<Array<GlobalSearchResultItem>> => {
    searchReference.current = query;

    resetTime();

    try {
      const { data } = await search({
        variables: {
          query
        }
      });

      const results: GlobalSearchResultItem[] = rankResults(data?.liveViewsGlobalSearch.searchResult || []);

      numberOfResults.current = results.length;

      const loadTime = getTimeElapsed();

      trackEvent({
        action: LiveGlobalSearchTrackingAction.SEARCH,
        category: LiveGlobalSearchTrackingCategory,
        label: query,
        value: loadTime,
        properties: {
          numberOfResults: results.length
        }
      });

      if (searching.current) {
        trackEvent({
          action: LiveGlobalSearchTrackingAction.SEARCH_WITH_NO_RESULT_SELECTED,
          category: LiveGlobalSearchTrackingCategory,
          label: query,
          properties: {
            topResults: results.slice(0, 3).map((item) => {
              const entityLabel: string = item.description;
              const entityType: string = getEntityType(item.type);

              return { label: entityLabel, type: entityType };
            })
          }
        });
      }

      searching.current = true;

      return results;
    } catch {
      return [];
    }
  };

  const handleChange = (item: Nullable<GlobalSearchResultItem>): void => {
    if (item) {
      const entityLabel: string = item.description;
      const entityType: string = getEntityType(item.type);
      const matchedField: SearchableField | undefined = item.matchedFields[0]?.type;
      const field: string = matchedField ? getSearchableFieldLabel(matchedField) : '';

      trackEvent({
        action: LiveGlobalSearchTrackingAction.RESULT_SELECTED,
        category: LiveGlobalSearchTrackingCategory,
        label: `${entityLabel}|${searchReference}`,
        value: item.rank,
        properties: {
          entityType,
          entityLabel,
          searchTerm: searchReference,
          rank: item.rank,
          matchedField: field,
          matchedFieldValue: item.matchedFields[0]?.value,
          numberOfResults: numberOfResults.current
        }
      });

      searching.current = false;

      navigate(getResultUrl(item.type, item.id), {
        state: {
          searchTerm: searchReference
        }
      });
    }
  };

  const noOptionsMessage: ReactNode = (
    <div className="text-left">
      <div className="leading-5 text-sm text-grey-dark">No results found</div>
      <div className="text-grey-base text-xs">
        We checked shipments, orders, products and networks and didn’t find any matches.
      </div>
    </div>
  );

  return (
    <AsyncSelect<GlobalSearchResultItem>
      cacheOptions={false}
      formatOptionLabel={renderOptionLabel}
      loadOptions={handleInputChange}
      noOptionsMessage={noOptionsMessage}
      onChange={handleChange}
      placeholder="Find anything"
      value={null}
    />
  );
};

export default GlobalSearch;
