import type { FC } from 'react';

import type { PillType } from '@zen/DesignSystem';
import { Pill } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  rolePermission: {
    label: string;
    value: boolean;
  };
}

const RolePermissionStatus: FC<Props> = ({ rolePermission }) => {
  const isEnabled: boolean = rolePermission.value;
  const iconName: IconName = isEnabled ? 'zicon-visible' : 'zicon-hidden';
  const pillType: PillType = isEnabled ? 'success' : 'problem';

  return (
    <div className="mr-2 leading-normal text-center">
      <Pill iconLeft={iconName} type={pillType}>
        {rolePermission.label}
      </Pill>
    </div>
  );
};

export type { Props as RolePermissionStatusProps };

export default RolePermissionStatus;
