import { capitalize } from 'lodash';
import type { ReactNode } from 'react';

import EditBusinessUnitButton from '@zen/Accounts/AccountDetails/BusinessUnits/EditBusinessUnitButton';
import type { TableColumn } from '@zen/DesignSystem';

import type { BusinessUnitTableItem } from '../types';

export default (canEditBusinessUnit: boolean): TableColumn<BusinessUnitTableItem>[] => {
  const nameColumn: TableColumn<BusinessUnitTableItem> = {
    key: 'tradingName',
    title: 'Business unit',
    resizable: false,
    sortable: true
  };

  const typeColumn: TableColumn<BusinessUnitTableItem> = {
    key: 'type',
    sortable: false,
    alignment: 'left',
    title: 'Type',
    render: (_, { type }: BusinessUnitTableItem): ReactNode => {
      return capitalize(type);
    }
  };

  const actionColumn: TableColumn<BusinessUnitTableItem> = {
    key: 'action',
    title: '',
    render: (_, { id, referencePrefix, tradingName }: BusinessUnitTableItem): ReactNode => {
      return <EditBusinessUnitButton id={id} name={tradingName} referencePrefix={referencePrefix} />;
    },
    resizable: false,
    sortable: false,
    width: '5%'
  };

  return [nameColumn, typeColumn, ...(canEditBusinessUnit ? [actionColumn] : [])];
};
