import type { ReactNode } from 'react';

import ValueWithDefault from '@zen/Components/ValueWithDefault';
import type { MenuItemType, TableColumn } from '@zen/DesignSystem';
import { ContextMenu } from '@zen/DesignSystem';

import type { OriginAgentAssignmentRule } from '../types';

export const getColumnsConfiguration = (
  onDelete: (agentAssignmentId: string) => void,
  onEdit: (agentAssignmentId: string) => void
): TableColumn<OriginAgentAssignmentRule>[] => {
  return [
    {
      key: 'account',
      fixed: 'left',
      title: 'Account',
      render: (_, { account }): ReactNode => {
        return <span>{account.name}</span>;
      },
      sortable: true
    },
    {
      key: 'modeOfTransport',
      title: 'Mode of transport',
      render: (_, { modeOfTransport }): ReactNode => <span>{modeOfTransport}</span>,
      sortable: true
    },
    {
      key: 'portOfLoad',
      title: 'Port of loading',
      render: (_, { portOfLoad }): ReactNode => {
        return <ValueWithDefault value={portOfLoad?.label} />;
      },
      sortable: false
    },
    {
      key: 'portOfDestination',
      title: 'Port of destination',
      render: (_, { portOfDestination }): ReactNode => {
        return <ValueWithDefault value={portOfDestination?.label} />;
      },
      sortable: false
    },
    {
      key: 'collectionWarehouse',
      title: 'Collection warehouse',
      render: (_, { collectionWarehouse }): ReactNode => {
        return <ValueWithDefault value={collectionWarehouse?.label?.long} />;
      },
      sortable: false
    },
    {
      key: 'deliveryWarehouse',
      title: 'Delivery warehouse',
      render: (_, { deliveryWarehouse }): ReactNode => {
        return <ValueWithDefault value={deliveryWarehouse?.label?.long} />;
      },
      sortable: false
    },
    {
      key: 'originAgent',
      title: 'Origin agent',
      render: (_, { originAgent }): ReactNode => {
        return <ValueWithDefault value={originAgent?.label?.long} />;
      },
      sortable: false
    },
    {
      key: 'actions',
      fixed: 'right',
      title: 'Actions',
      render: (_, { id }): ReactNode => {
        const items: MenuItemType[] = [
          {
            label: 'Edit',
            onClick: () => onEdit(id),
            icon: 'zicon-edit'
          },
          {
            label: 'Delete',
            onClick: () => onDelete(id),
            icon: 'zicon-trash'
          }
        ];

        return <ContextMenu inline={false} items={items} />;
      },
      sortable: false,
      width: '100px'
    }
  ];
};
