import type { FC } from 'react';

import useAccount from '@zen/utils/hooks/useAccount';
import useVisitedAccounts from '@zen/utils/hooks/useVisitedAccounts';

import VisitedAccountsList from '../VisitedAccountsList';

interface Props {
  onAccountChange?: () => void;
}

const VisitedAccountsMenu: FC<Props> = ({ onAccountChange }) => {
  const { getLastVisitedAccounts, getMostVisitedAccounts } = useVisitedAccounts();
  const { userProfile } = useAccount();

  const lastVisitedAccounts = getLastVisitedAccounts(userProfile.uuid);
  const mostVisitedAccounts = getMostVisitedAccounts(userProfile.uuid);

  return (
    <>
      {lastVisitedAccounts && lastVisitedAccounts.length > 0 && (
        <VisitedAccountsList onAccountChange={onAccountChange} type="lastVisited" visitedAccounts={lastVisitedAccounts} />
      )}

      {mostVisitedAccounts && mostVisitedAccounts.length > 0 && (
        <VisitedAccountsList
          onAccountChange={onAccountChange}
          type="mostFrequentlyVisited"
          visitedAccounts={mostVisitedAccounts}
        />
      )}
    </>
  );
};

export default VisitedAccountsMenu;
