import type { FC } from 'react';

import EditableContentContainer from '@zen/Components/EditableContentContainer';

import ActivateAccountButton from '../ActivateAccountButton';

interface Props {
  accountId: string;
}

const ActivateAccount: FC<Props> = ({ accountId }) => {
  return (
    <EditableContentContainer heading="Reactivate account">
      <div className="col-span-full">
        Reactivating this account will immediately provide access for all members. Accounts can be deactivated at any time.
      </div>
      <ActivateAccountButton accountId={accountId} />
    </EditableContentContainer>
  );
};

export default ActivateAccount;
