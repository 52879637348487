import cx from 'classnames';
import type { ReactNode } from 'react';

import type { Option } from '@zen/DesignSystem';

import { useGetAccountUserGroupsQuery } from '../../graphql';
import type { AccountUserGroup } from '../../types';

export type UserGroupOption = Option<string> & {
  accountUserGroup: AccountUserGroup;
  renderLabel: () => ReactNode;
  renderOptionLabel: (descriptionClassName?: string) => ReactNode;
};

export const useUserGroupsOptions = (accountId: string): UserGroupOption[] => {
  const { data } = useGetAccountUserGroupsQuery({ variables: { accountId } });
  const userGroups = data?.accountUserGroups || [];

  return userGroups.map((userGroup) => ({
    accountUserGroup: userGroup,
    label: `${userGroup.name} - ${userGroup.description}`,
    renderLabel: (): ReactNode => {
      return (
        <>
          {userGroup.name}
          <span className="text-grey-base"> - {userGroup.description}</span>
        </>
      );
    },
    renderOptionLabel: (descriptionClassName?: string): ReactNode => {
      const classNames: string = cx(descriptionClassName, 'text-xs leading-4');

      return (
        <div>
          <div className="text-sm leading-5">{userGroup.name}</div>
          <div className={classNames}>{userGroup.description}</div>
        </div>
      );
    },
    value: userGroup.userGroupId
  }));
};
