import type { FC } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { isOceanBooking } from '@zen/Booking/helpers';
import { Button, IconButton } from '@zen/DesignSystem';
import { newBookingRoutes } from '@zen/Routes';
import type { Optional } from '@zen/utils/typescript';

import type { ModeOfTransport, TrackingDetails } from '../../types';
import { TrackingStatus } from '../../types';
import { MiniTaskListToggler } from '..';
import ArchiveShipment from '../ArchiveShipment';
import PinShipment from '../PinShipment';
import DisableOceanTrackingModal from './OceanTracking/DisableOceanTrackingModal';
import EnableOceanTrackingModal from './OceanTracking/EnableOceanTrackingModal';

interface Props {
  canArchive: boolean;
  canClone: boolean;
  canPin: boolean;
  canToggleTracking: boolean;
  canViewTaskList: boolean;
  isArchived: boolean;
  isPinned: boolean;
  modeOfTransport: Optional<ModeOfTransport>;
  trackingDetails: Optional<TrackingDetails>;
  zencargoReference: string;
}

const BookingActionButtons: FC<Props> = (props) => {
  const {
    canArchive,
    canClone,
    canPin,
    canToggleTracking,
    canViewTaskList,
    isArchived,
    isPinned,
    modeOfTransport,
    trackingDetails,
    zencargoReference
  } = props;

  const [showEnableTrackingModal, setShowEnableTrackingModal] = useState<boolean>(false);
  const [showDisableTrackingModal, setShowDisableTrackingModal] = useState<boolean>(false);

  const isOcean: boolean = isOceanBooking(modeOfTransport);
  const isTrackingEnabled: boolean = isOcean && trackingDetails?.status === TrackingStatus.TRACKED;
  const isTrackingDisabled: boolean = isOcean && trackingDetails?.status === TrackingStatus.TRACKING_DISABLED;
  const showDisableTrackingButton: boolean = canToggleTracking && isTrackingEnabled;
  const showEnableTrackingButton: boolean = canToggleTracking && isTrackingDisabled;

  const showArchiveShipmentIcon: boolean = canArchive && !isArchived;

  return (
    <div className="flex items-center mb-3 space-x-4">
      {showEnableTrackingButton && (
        <Button iconLeft="zicon-tick" onClick={() => setShowEnableTrackingModal(true)} variant="secondary">
          Enable tracking
        </Button>
      )}
      <EnableOceanTrackingModal
        isOpen={showEnableTrackingModal}
        onClose={() => setShowEnableTrackingModal(false)}
        zencargoReference={zencargoReference}
      />
      {showDisableTrackingButton && (
        <Button iconLeft="zicon-close" onClick={() => setShowDisableTrackingModal(true)} variant="secondary">
          Disable tracking
        </Button>
      )}
      <DisableOceanTrackingModal
        isOpen={showDisableTrackingModal}
        onClose={() => setShowDisableTrackingModal(false)}
        zencargoReference={zencargoReference}
      />
      {canClone && (
        <Link data-testid="shipment-clone-button" to={newBookingRoutes.newBookingFromZencargoReference.getUrl(zencargoReference)}>
          <IconButton icon="zicon-copy" title="Clone this booking" variant="secondary" />
        </Link>
      )}
      {canPin && <PinShipment iconSize="large" isPinned={isPinned} zencargoReference={zencargoReference} />}
      {canViewTaskList && <MiniTaskListToggler />}
      {showArchiveShipmentIcon && <ArchiveShipment zencargoReference={zencargoReference} />}
    </div>
  );
};

export default BookingActionButtons;
