import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import InformationIcon from '@zen/Components/InformationIcon';
import LabelledValue from '@zen/Components/LabelledValue';
import type { PopoverArguments } from '@zen/DesignSystem';
import { Popover } from '@zen/DesignSystem';
import { formatDate } from '@zen/utils/dateTime';
import type { Nullable, Optional } from '@zen/utils/typescript';

import PriceCalculationDatePicker from '../PriceCalculationDatePicker';
import { PriceCalculationMilestone } from '../PriceCalculationSection/types';
import { getClassNames, getReadOnlyContent, priceCalculationMilestoneLabelMapping } from './helpers';

interface Props {
  date: Optional<string>;
  isEditable: boolean;
  milestone: Nullable<PriceCalculationMilestone>;
  zencargoReference: string;
}

const PriceCalculationDate: FC<Props> = ({ date, isEditable, milestone, zencargoReference }) => {
  const handlePopover = ({ close }: PopoverArguments): ReactNode => {
    return (
      <PriceCalculationDatePicker date={date} milestone={milestone} onChange={close} zencargoReference={zencargoReference} />
    );
  };

  const contentClassName: string = cx({ 'text-grey-base': !date });

  const renderDateContent = (): ReactNode => {
    const isReadonly: boolean = !isEditable || (!date && milestone !== PriceCalculationMilestone.CARRIER_PUBLISHED);

    if (isReadonly) {
      return <div className={contentClassName}>{getReadOnlyContent(date, milestone)}</div>;
    }

    return (
      <Popover popover={handlePopover} renderInPortal={true} triggerClassName="flex-1">
        {({ isPopoverVisible }) => {
          const classNames: string = getClassNames({
            interactive: !!date || (!date && milestone === PriceCalculationMilestone.CARRIER_PUBLISHED),
            isPopoverVisible
          });

          return <div className={classNames}>{formatDate(date || '')}</div>;
        }}
      </Popover>
    );
  };

  const classNames: string = cx({ 'mt-1': !isEditable }, 'flex items-center w-1/4');

  return (
    <LabelledValue label="Price calculation date">
      <div className={classNames}>
        {renderDateContent()}
        {milestone && (
          <InformationIcon className="flex ml-1" content={`${priceCalculationMilestoneLabelMapping[milestone]} date`} />
        )}
      </div>
    </LabelledValue>
  );
};

export type { Props as PriceCalculationDateProps };

export default PriceCalculationDate;
