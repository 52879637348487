import cx from 'classnames';
import type { FC } from 'react';

import { useNetworksAssignMutation } from '@zen/Networks/graphql';
import NetworksForwarderInput from '@zen/Networks/NetworksContactPicker/NetworksForwarderInput';
import type { NetworksAssignableInterface } from '@zen/Networks/types';
import { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

interface Props {
  forwarder: Optional<NetworksAssignableInterface>;
  variant?: 'default' | 'inline';
  zencargoReference: string;
}

const ForwarderInlineField: FC<Props> = ({ forwarder, variant, zencargoReference }) => {
  const { addSuccess, addError } = useNotification();
  const [assignAction, { loading }] = useNetworksAssignMutation();

  const className: string = cx({ '-ml-3': variant === 'inline' });

  const handleInputChange = async (value: Nullable<NetworksAssignableInterface>): Promise<void> => {
    if (value?.id) {
      const inputVariables = {
        assignmentName: AssignmentTypeValue.FORWARDER,
        targetId: zencargoReference,
        targetType: AssignmentTargetTypeEnum.BOOKING,
        assignableId: value.id
      };

      performMutation({
        mutationFn: () =>
          assignAction({
            awaitRefetchQueries: true,
            refetchQueries: ['bookingSummary', 'getBookingTradeParties'],
            variables: { input: inputVariables }
          }),
        onError: () => addError(),
        onSuccess: () => addSuccess('Forwarder has been changed.')
      });
    }
  };

  return (
    <div className={className}>
      <NetworksForwarderInput
        domainName={AssignmentTargetTypeEnum.BOOKING}
        isLoading={loading}
        onChange={handleInputChange}
        value={forwarder}
        variant={variant}
      />
    </div>
  );
};

export default ForwarderInlineField;
