import type { ObjectSchema } from 'yup';
import { array, number, object } from 'yup';

const unitPriceValidator = object({ value: number().nullable() }).test(
  'unit price value test',
  'Please enter a unit price.',
  (unitPriceValue) => {
    return unitPriceValue.value !== null;
  }
);

export const getChargeItemsValidator = (chargeTypeEnabled: boolean = true) => {
  return array().of(
    object().shape({
      chargeBasis: array().of(object()).nullable().min(1, 'Please select a basis.').required('Please select a basis.'),
      ...(chargeTypeEnabled ? { chargeType: object().nullable().required('Please select a charge type.') } : {}),
      unitPrice: unitPriceValidator
    })
  );
};

const portValidationMessage: string = 'Please select a port.';

export const validationSchema: ObjectSchema<{}> = object().shape({
  chargeItems: getChargeItemsValidator(),
  fromLocation: object().nullable().required(portValidationMessage),
  toLocation: object().nullable().required(portValidationMessage)
});
