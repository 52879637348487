import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Page from '@zen/Components/Page';
import type { CreateOrganisationInput } from '@zen/Networks';
import { useNetworksContext } from '@zen/Networks';
import { createBusinessHoursInput } from '@zen/Networks/businessHours.mock';
import OrganisationForm from '@zen/Networks/Form/OrganisationForm';
import type { IOrganisationFormFields } from '@zen/Networks/Form/OrganisationForm/types';
import { useNetworksCreateOrganisationMutation } from '@zen/Networks/graphql';
import useAllowedOrganisationTypes from '@zen/Networks/hooks/useAllowedOrganisationTypes';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';
import type { DeepNullable } from '@zen/utils/typescript';

import { prepareRequestData } from '../prepareRequestData';

export const newOrganisationInitialValues: DeepNullable<IOrganisationFormFields> = {
  name: '',
  type: null,
  businessHours: createBusinessHoursInput(),
  city: '',
  countryCode: null,
  lat: null,
  lng: null,
  locationAddress: '',
  locationType: null,
  postalCode: '',
  state: '',
  street: ''
};

interface Props {
  networkId: string;
}

const NewOrganisation: FC<Props> = ({ networkId }) => {
  const navigate = useNavigate();
  const organisationTypes = useAllowedOrganisationTypes(networkId);
  const [createOrganisation] = useNetworksCreateOrganisationMutation();
  const { addSuccess, addError } = useNotification();
  const { routes } = useNetworksContext();
  const myOrganisationIndex: string = routes.myOrganisation.index.getUrl();

  if (organisationTypes.length === 1) {
    newOrganisationInitialValues.type = organisationTypes[0].value;
  }

  const handleSubmit = async (values: IOrganisationFormFields): Promise<IOkOrErrorResult> => {
    const input: CreateOrganisationInput = prepareRequestData(values, networkId);

    return performFormMutation({
      mutationFn: () =>
        createOrganisation({
          variables: {
            input
          }
        }),
      onError: () => addError()
    });
  };

  const handleSuccess = ({ organisation }: { organisation: { id: string } }) => {
    addSuccess('New organisation has been created.');

    navigate(routes.organisation.details.getUrl(organisation.id));
  };

  const handleCancel = (): void => navigate(myOrganisationIndex);

  return (
    <Page defaultBackUrl={myOrganisationIndex} title="Add a new organisation" width="narrow">
      <OrganisationForm
        initialValues={newOrganisationInitialValues}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        organisationTypes={organisationTypes}
      />
    </Page>
  );
};

export default NewOrganisation;
