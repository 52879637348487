import { sortBy } from 'lodash';

import type { SortInput } from '@zen/types';
import { SortingOrder } from '@zen/types';

import type { BusinessUnitTableItem } from '../types';

const sortBusinessUnits = (sortableBusinessUnits: BusinessUnitTableItem[], order: SortInput): BusinessUnitTableItem[] => {
  const field = order.field as keyof BusinessUnitTableItem;
  const sorted = sortBy(sortableBusinessUnits, (unit) => unit?.[field]?.toLowerCase());

  return order.direction === SortingOrder.ASC ? sorted : sorted.reverse();
};

export { sortBusinessUnits };
