import useAccount from '@zen/utils/hooks/useAccount';
import type { Nullable } from '@zen/utils/typescript';

import { usePriceCalculationQuery } from '../graphql';
import type { PriceCalculation, PriceCalculationMilestone } from '../types';

const usePriceCalculation = (zencargoReference: string): NonNullable<PriceCalculation> => {
  const { accountUuid: customerId } = useAccount();

  const { data } = usePriceCalculationQuery({ variables: { zencargoReference, customerId }, fetchPolicy: 'no-cache' });

  const date: Nullable<string> = data?.bookings.nodes?.[0]?.priceCalculation?.date || null;

  const milestone: Nullable<PriceCalculationMilestone> = data?.bookings.nodes?.[0]?.priceCalculation?.milestone || null;

  return { date, milestone };
};

export default usePriceCalculation;
