import type { FC, PropsWithChildren } from 'react';
import { useAsync, useLocalStorage } from 'react-use';

import { useGetMembersLazyQuery } from '@zen/Auth/graphql';
import useAuth from '@zen/Auth/hooks/useAuth';
import useApiAuthoriser from '@zen/Auth/RouteAuthenticator/useApiAuthoriser';
import { SplashScreen } from '@zen/DesignSystem';

import AccountIdContext from './AccountIdContext';

const AccountIdProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const ACCOUNT_UUID_KEY = 'current_account_uuid';
  const [accountId, setAccountId, removeAccountId] = useLocalStorage<string>(ACCOUNT_UUID_KEY, '');
  const { logout } = useAuth();
  const [getMembers] = useGetMembersLazyQuery();
  const { hasApiAccessToken } = useApiAuthoriser();

  const { loading, error } = useAsync(async () => {
    if (hasApiAccessToken) {
      initialiseAccountId();
    }
  }, [hasApiAccessToken]);

  const fetchAccountId = async (): Promise<string> => {
    const { data } = await getMembers();

    const currentAccountId: string = data?.currentUserDetails?.account.id || data?.members?.[0]?.account?.uuid || '';

    return currentAccountId;
  };

  const initialiseAccountId = async () => {
    if (accountId) {
      return;
    }

    const id: string = await fetchAccountId();

    setAccountId(id);
  };

  if (loading) {
    return <SplashScreen />;
  }

  if (error) {
    logout();

    return null;
  }

  return (
    <AccountIdContext.Provider value={{ accountId: accountId || '', setAccountId, removeAccountId, fetchAccountId }}>
      {children}
    </AccountIdContext.Provider>
  );
};

export default AccountIdProvider;
