import cx from 'classnames';
import type { FC, SyntheticEvent } from 'react';

import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import type { GeoCoordinates } from '../types';
import type { ClusterColor, WidthStyle } from './types';

interface Props extends GeoCoordinates {
  color?: ClusterColor;
  isHighlighted?: boolean;
  onClick: () => void;
  pointCount: number;
  totalPoints: number;
}

export const displayName: string = 'Component/Map/Cluster';

const Cluster: FC<Props> = ({ color = 'blue', isHighlighted, onClick, pointCount, totalPoints }) => {
  const { trackEvent } = useTracking();

  const getMarkerWidthStyle = (borderWidth: number = 0): WidthStyle => {
    const size: string = `${30 + (pointCount / totalPoints) * 20 - borderWidth}px`;

    return { width: size, height: size };
  };

  const handleClick = (event: SyntheticEvent): void => {
    const trackingEvent = createTrackingEvent(displayName, 'click', event);

    trackEvent(trackingEvent);
    onClick?.();
  };

  const innerMarkerClassName: string = cx(
    {
      'bg-azure-base': color === 'blue',
      'bg-green-base': color === 'green',
      'bg-orange-base': color === 'orange',
      'bg-red-base': color === 'red'
    },
    'w-8 h-8 cursor-pointer rounded-full flex items-center font-sans justify-center',
    'm-auto text-white relative pointer-events-none'
  );

  const outerMarkerClassName: string = cx('absolute transition-all duration-500 hover:opacity-100 rounded-full', {
    'opacity-80 transform': !isHighlighted,
    'bg-azure-lighter hover:bg-azure-base': color === 'blue' && !isHighlighted,
    'bg-azure-base': color === 'blue' && isHighlighted,
    'bg-red-light hover:bg-red-base': color === 'red' && !isHighlighted,
    'bg-red-base': color === 'red' && isHighlighted,
    'bg-green-light hover:bg-green-base': color === 'green' && !isHighlighted,
    'bg-green-base': color === 'green' && isHighlighted,
    'bg-orange-light hover:bg-orange-base': color === 'orange' && !isHighlighted,
    'bg-orange-base': color === 'orange' && isHighlighted
  });

  const wrapperMarkerClassName: string =
    'relative z-20 flex text-sm transform -translate-x-1/2 -translate-y-1/2 rounded-full hover:z-40';

  const markerBorderWidth: number = 15;

  return (
    <div className={wrapperMarkerClassName} style={getMarkerWidthStyle()}>
      <div
        className={outerMarkerClassName}
        data-testid="cluster"
        onClick={handleClick}
        style={getMarkerWidthStyle()}
        {...createTrackingParentAttribute('cluster')}
        {...createTrackingLabelAttribute('cluster')}
      />
      <div className={innerMarkerClassName} style={getMarkerWidthStyle(markerBorderWidth)}>
        {pointCount}
      </div>
    </div>
  );
};

export type { Props as ClusterType };

export default Cluster;
