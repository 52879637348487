import { ModeOfTransport } from '@zen/Booking';
import { getLegacyCargoItemType } from '@zen/Cargo/cargoDictionaryMapping.helper';
import CargoOptions from '@zen/Cargo/components/CargoOptions';
import CargoValue from '@zen/Cargo/components/CargoValue';
import { formatNumber } from '@zen/utils/formatting';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { cargoLabelMapping, formatDimensions, formatWeight } from './helpers';
import type {
  CargoDetailsFieldsConfig,
  CargoFieldConfig,
  CargoFieldName,
  CargoModeEnum,
  CoreCargo,
  CoreCargoExtended,
  Money,
  RiskLevelsEnum
} from './types';
import { CargoItemTypeEnum, coreCargoTypeLabelMapping } from './types';

type Summary = { highestRiskLevel?: Nullable<RiskLevelsEnum>; totalValueOfGoods?: Nullable<Money[]> };

const formatLooseCargo = (looseCargo: Optional<CoreCargoExtended>): Nullable<string> => {
  if (!looseCargo?.cargoType) {
    return null;
  }

  const { quantity, cargoType } = looseCargo;
  const cargoTypeLabel: string = coreCargoTypeLabelMapping[cargoType];

  return quantity ? `${quantity} x ${cargoTypeLabel}` : cargoTypeLabel;
};

const cargoTypeFieldsMapping: Record<CargoItemTypeEnum, CargoFieldName[]> = {
  [CargoItemTypeEnum.CONTAINER]: [
    'containerNumber',
    'cargoType',
    'looseCargo',
    'grossWeight',
    'volume',
    'containerSealNumber',
    'dryStandard',
    'flatRack',
    'garmentsOnHangers',
    'hazardous',
    'openTop',
    'overweight',
    'refrigerated'
  ],
  [CargoItemTypeEnum.LOOSE_CARGO]: [
    'cargoType',
    'dimensions',
    'grossWeight',
    'volume',
    'chargeableWeight',
    'containerNumber',
    'hazardous',
    'refrigerated',
    'stackable',
    'vehiclePlateNumber',
    'trailerId',
    'driverDetails'
  ],
  [CargoItemTypeEnum.VEHICLE]: [
    'cargoType',
    'looseCargo',
    'dimensions',
    'valueOfCargo',
    'grossWeight',
    'volume',
    'vehiclePlateNumber',
    'trailerId',
    'driverDetails',
    'hazardous',
    'healthCertificate',
    'refrigerated',
    'tailLift'
  ]
};

const getFieldsConfig = (
  cargo: CoreCargo,
  cargoMode: Optional<CargoModeEnum>,
  cargoSummary: Optional<Summary>
): CargoFieldConfig => {
  const {
    chargeableWeight,
    collection,
    containerNumber,
    containerSealNumber,
    dimensions,
    delivery,
    dryStandard,
    flatRack,
    garmentsOnHangers,
    grossWeight,
    hazardous,
    healthCertificate,
    openTop,
    overweight,
    refrigerated,
    stackable,
    tailLift,
    trailerId,
    volume
  } = cargo;

  return {
    cargoType: {
      label: cargoMode ? cargoLabelMapping[cargoMode] : 'Cargo',
      value: getCargoQuantityAndLabel(cargo)
    },
    chargeableWeight: {
      label: 'Chargeable weight',
      value: formatWeight(chargeableWeight)
    },
    containerNumber: {
      label: 'Container number',
      value: containerNumber
    },
    containerSealNumber: {
      label: 'Seal number',
      value: containerSealNumber
    },
    collectionReference: {
      label: 'Collection reference',
      value: collection?.reference
    },
    dimensions: {
      label: 'Loose cargo dimensions',
      value: formatDimensions(dimensions)
    },
    deliveryReference: {
      label: 'Delivery reference',
      value: delivery?.reference
    },
    driverDetails: {
      label: 'Driver details',
      value: collection?.driverDetails
    },
    dryStandard: {
      label: null,
      value: dryStandard && <CargoOptions dryStandard={dryStandard} />
    },
    flatRack: {
      label: null,
      value: flatRack && <CargoOptions flatRack={flatRack} />
    },
    garmentsOnHangers: {
      label: null,
      value: garmentsOnHangers && <CargoOptions garmentsOnHangers={garmentsOnHangers} />
    },
    grossWeight: {
      label: 'Gross weight',
      value: grossWeight && formatWeight(grossWeight)
    },
    hazardous: {
      label: null,
      value: hazardous && <CargoOptions hazardous={hazardous} />
    },
    healthCertificate: {
      label: null,
      value: healthCertificate && <CargoOptions healthCertificate={healthCertificate} />
    },
    looseCargo: {
      label: 'Loose cargo quantity & type',
      value: formatLooseCargo(cargo.looseCargo)
    },
    openTop: {
      label: null,
      value: openTop && <CargoOptions openTop={openTop} />
    },
    overweight: {
      label: null,
      value: overweight && <CargoOptions overweight={overweight} />
    },
    refrigerated: {
      label: null,
      value: refrigerated && <CargoOptions refrigerated={refrigerated} />
    },
    stackable: {
      label: null,
      value: stackable && <CargoOptions stackable={stackable} />
    },
    tailLift: {
      label: null,
      value: tailLift && <CargoOptions tailLift={tailLift} />
    },
    trailerId: {
      label: 'Trailer ID',
      value: trailerId
    },
    valueOfCargo: {
      label: 'Value of cargo',
      value: <CargoValue highestRiskLevel={cargoSummary?.highestRiskLevel} totalValueOfGoods={cargoSummary?.totalValueOfGoods} />
    },
    vehiclePlateNumber: {
      label: 'Vehicle plate number',
      value: collection?.vehiclePlateNumber
    },
    volume: {
      label: 'CBM',
      value: volume?.value && formatNumber(volume?.value)
    }
  };
};

const filterFieldsByModeOfTransport = (fields: CargoFieldName[], modeOfTransport: ModeOfTransport) => {
  const fieldsAvailableOnModeOfTransports = {
    chargeableWeight: [ModeOfTransport.AIR],
    containerNumber: [ModeOfTransport.OCEAN],
    vehiclePlateNumber: [ModeOfTransport.TRUCK],
    trailerId: [ModeOfTransport.TRUCK],
    driverDetails: [ModeOfTransport.TRUCK]
  };

  return fields.filter((field) => {
    const modeOfTransports: ModeOfTransport[] | undefined =
      fieldsAvailableOnModeOfTransports[field as keyof typeof fieldsAvailableOnModeOfTransports];

    if (!modeOfTransports) {
      return true;
    }

    return modeOfTransports.includes(modeOfTransport);
  });
};

export const getCargoDetails = (
  cargo: CoreCargo,
  cargoMode: Optional<CargoModeEnum>,
  cargoSummary: Optional<Summary>,
  modeOfTransport: Optional<ModeOfTransport>,
  fieldConfig: Partial<CargoDetailsFieldsConfig> = {}
) => {
  if (!modeOfTransport) {
    return [];
  }

  const cargoItemType = getLegacyCargoItemType(cargo?.cargoType);

  const cargoDetailsFieldsConfig: Record<CargoItemTypeEnum, CargoFieldName[]> = {
    ...cargoTypeFieldsMapping,
    ...(fieldConfig as Record<CargoItemTypeEnum, CargoFieldName[]>)
  };

  const fields: CargoFieldName[] = cargoItemType ? cargoDetailsFieldsConfig[cargoItemType] : [];

  const config: CargoFieldConfig = getFieldsConfig(cargo, cargoMode, cargoSummary);
  const availableFields: CargoFieldName[] =
    cargoItemType === CargoItemTypeEnum.LOOSE_CARGO ? filterFieldsByModeOfTransport(fields, modeOfTransport) : fields;

  return availableFields.map((field: CargoFieldName) => config[field]);
};

export const getCargoQuantityAndLabel = ({ cargoType, quantity }: CoreCargo): string => {
  const cargoTypeLabel: string = cargoType ? coreCargoTypeLabelMapping[cargoType] : '';
  const legacyCargoType: Optional<CargoItemTypeEnum> = getLegacyCargoItemType(cargoType);
  const isContainerOrVehicleType: boolean =
    legacyCargoType === CargoItemTypeEnum.CONTAINER || legacyCargoType === CargoItemTypeEnum.VEHICLE;

  if (!quantity || isContainerOrVehicleType) {
    return cargoTypeLabel;
  }

  return `${quantity} x ${cargoTypeLabel}`;
};
