import type { FC } from 'react';

import FilterComponent from '@zen/Components/Filters';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import { buildFilters } from './helpers';
import type { AccountConnectionFilters as AccountConnectionFiltersType, AccountFilterItem } from './types';

const AccountConnectionFilters: FC = () => {
  const { filters, setFilters } = useUrlFilters<AccountConnectionFiltersType>();

  const filterItems: AccountFilterItem[] = buildFilters();

  return <FilterComponent items={filterItems} onChange={setFilters} value={filters} />;
};

export default AccountConnectionFilters;
