import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AssignableAccountsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  searchAssignableAccountsFiltersInput: GraphQLTypes.SearchAssignableAccountsFiltersInput;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
}>;

export type AssignableAccountsQueryResult = { __typename?: 'Query' } & {
  searchAssignableAccounts?: GraphQLTypes.Maybe<
    { __typename?: 'AccountConnectionTypeConnection' } & {
      nodes: Array<
        GraphQLTypes.Maybe<
          { __typename?: 'AccountConnectionType' } & Pick<GraphQLTypes.AccountConnectionType, 'connectionType'> & {
              account: { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName'> & {
                  label?: GraphQLTypes.Maybe<{ __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>>;
                };
            }
        >
      >;
    }
  >;
};

export const AssignableAccountsDocument = /* #__PURE__ */ gql`
  query assignableAccounts(
    $accountId: String!
    $searchAssignableAccountsFiltersInput: SearchAssignableAccountsFiltersInput!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    searchAssignableAccounts(
      accountId: $accountId
      searchAssignableAccountsFiltersInput: $searchAssignableAccountsFiltersInput
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        account {
          id
          tradingName
          label {
            long
          }
        }
        connectionType
      }
    }
  }
`;

/**
 * __useAssignableAccountsQuery__
 *
 * To run a query within a React component, call `useAssignableAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignableAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignableAccountsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      searchAssignableAccountsFiltersInput: // value for 'searchAssignableAccountsFiltersInput'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAssignableAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<AssignableAccountsQueryResult, AssignableAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AssignableAccountsQueryResult, AssignableAccountsQueryVariables>(AssignableAccountsDocument, options);
}
export function useAssignableAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssignableAccountsQueryResult, AssignableAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AssignableAccountsQueryResult, AssignableAccountsQueryVariables>(
    AssignableAccountsDocument,
    options
  );
}
export type AssignableAccountsQueryHookResult = ReturnType<typeof useAssignableAccountsQuery>;
export type AssignableAccountsLazyQueryHookResult = ReturnType<typeof useAssignableAccountsLazyQuery>;
