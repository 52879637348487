import type { ReactNode } from 'react';

import { Pill, type TableColumn } from '@zen/DesignSystem';

import type { AccountConnectionsSummary } from '../types';
import DeleteConnectionButton from './DeleteConnectionButton';
import { connectionTypeMapping, statusMapping } from './helpers';

const getColumnsConfiguration = (accountId: string): TableColumn<AccountConnectionsSummary>[] => {
  return [
    {
      key: 'company',
      title: 'Company',
      render: (_, { account }: AccountConnectionsSummary): ReactNode => {
        return <div>{account.tradingName}</div>;
      },
      sortable: false
    },
    {
      key: 'connectionType',
      title: 'Type',
      render: (_, { connectionType }: AccountConnectionsSummary): ReactNode => {
        return connectionTypeMapping[connectionType];
      },
      sortable: false
    },
    {
      key: 'status',
      title: '',
      render: (_, { account }: AccountConnectionsSummary): ReactNode => {
        const { label, pillType } = statusMapping[account.status];

        return <Pill type={pillType}>{label}</Pill>;
      },
      sortable: false,
      width: '10%'
    },
    {
      key: 'actions',
      title: '',
      render: (_, { account, connectionType }: AccountConnectionsSummary): ReactNode => {
        const { id, tradingName } = account;

        return (
          <DeleteConnectionButton
            connectionType={connectionType}
            sourceAccountId={accountId}
            targetAccountId={id}
            targetAccountName={tradingName}
          />
        );
      },
      sortable: false,
      width: '5%'
    }
  ];
};

export default getColumnsConfiguration;
