import type { FC } from 'react';
import { useState } from 'react';

import { Button } from '@zen/DesignSystem';
import type { QuoteOption } from '@zen/Quotes/types';
import { QuoteOptionRejectionReason } from '@zen/Quotes/types';

import OtherRejectReasonForm from './OtherRejectReasonForm';

const reasonsList: QuoteOptionRejectionReason[] = [
  QuoteOptionRejectionReason.PRICE,
  QuoteOptionRejectionReason.SCHEDULE,
  QuoteOptionRejectionReason.RESPONSE_TIME,
  QuoteOptionRejectionReason.OTHER
];

interface Props {
  handleReject: (reason: QuoteOptionRejectionReason, text?: string) => void;
  quoteOption: QuoteOption;
}

const RejectReason: FC<Props> = (props) => {
  const { quoteOption } = props;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Maybe<QuoteOptionRejectionReason... Remove this comment to see the full error message
  const reasonProvided = reasonsList.includes(quoteOption.rejectionReason);
  const [selected, setSelected] = useState(quoteOption.rejectionReason);

  const renderOption = (value: QuoteOptionRejectionReason, label: string) => {
    const isSelected = selected === value;
    const buttonVariant = isSelected ? 'primary' : 'secondary';

    const handleSelect = () => {
      setSelected(value);

      if (value !== QuoteOptionRejectionReason.OTHER) {
        props.handleReject(value);
      }
    };

    return (
      <Button className="mx-2" disabled={reasonProvided} onClick={handleSelect} variant={buttonVariant}>
        {label}
      </Button>
    );
  };

  const showTextArea = selected === QuoteOptionRejectionReason.OTHER;

  const handleSubmit = (values: { reason: string }) => {
    props.handleReject(QuoteOptionRejectionReason.OTHER, values.reason);
  };

  return (
    <div className="bg-grey-lightest p-6">
      <div className="font-bold">Can I ask for the reason you rejected this quote option?</div>
      <div className="flex justify-between py-4 -mx-2">
        {renderOption(QuoteOptionRejectionReason.PRICE, 'Price')}
        {renderOption(QuoteOptionRejectionReason.SCHEDULE, 'Schedule')}
        {renderOption(QuoteOptionRejectionReason.RESPONSE_TIME, 'Response time')}
        {renderOption(QuoteOptionRejectionReason.OTHER, 'Other')}
      </div>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message */}
      {showTextArea && <OtherRejectReasonForm handleSubmit={handleSubmit} reasonDescription={quoteOption.reasonDescription} />}
      {reasonProvided && <p>Thank you for your feedback</p>}
    </div>
  );
};

export default RejectReason;
