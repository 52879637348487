import { cloneDeep } from 'lodash';

import type { Activity } from '@zen/ActivityFeed';
import { ActivityFeedItemTypeEnum } from '@zen/ActivityFeed';
import { isItemWithMetadata } from '@zen/ActivityFeed/helpers/activityFeedHelper';

import type { ShipmentActivityFeedItemsQueryResult } from '../graphql';
import type { ActivityFeedDataState, ShipmentActivityFeedItemData } from '../types';

type ActivityFeedItemsIds = {
  actionItemsIds: string[];
  quoteOptionsIds: string[];
  textMessagesIds: string[];
};

export function prepareActivityItemsIds(activityFeedData: Activity[]): ActivityFeedItemsIds {
  const activityFeedItemsIds: ActivityFeedItemsIds = {
    actionItemsIds: [],
    textMessagesIds: [],
    quoteOptionsIds: []
  };

  const arrayMatcher: Record<string, string[]> = {
    [ActivityFeedItemTypeEnum.QUOTE_OPTION]: activityFeedItemsIds.quoteOptionsIds,
    [ActivityFeedItemTypeEnum.ACCEPTED_QUOTE_OPTION]: activityFeedItemsIds.quoteOptionsIds,
    [ActivityFeedItemTypeEnum.REJECTED_QUOTE_OPTION]: activityFeedItemsIds.quoteOptionsIds,
    [ActivityFeedItemTypeEnum.AUTOMATICALLY_REJECTED_QUOTE_OPTION]: activityFeedItemsIds.quoteOptionsIds,
    [ActivityFeedItemTypeEnum.TEXT_MESSAGE]: activityFeedItemsIds.textMessagesIds,
    [ActivityFeedItemTypeEnum.ZENSEI_TEXT_MESSAGE]: activityFeedItemsIds.textMessagesIds
  };

  activityFeedData.forEach((activityFeed: Activity) => {
    if (isItemWithMetadata(activityFeed.itemType) || !arrayMatcher[activityFeed.itemType]) return;

    const arrayToPush: string[] = arrayMatcher[activityFeed.itemType];

    arrayToPush.push(activityFeed.itemId);
  });

  return activityFeedItemsIds;
}

export function mergeItemsData(
  state: ActivityFeedDataState,
  update: ShipmentActivityFeedItemsQueryResult,
  activityFeedItems: Activity[]
): ActivityFeedDataState {
  const newState: ActivityFeedDataState = cloneDeep(state);

  const updateState = (collection: ShipmentActivityFeedItemData[] | ShipmentActivityFeedItemData): void => {
    if (!collection) {
      return;
    }

    if (!Array.isArray(collection)) {
      const rejectedBookingItem = activityFeedItems.find(
        (item) => item.itemType === ActivityFeedItemTypeEnum.REJECTED_BOOKING_REQUEST
      );

      if (rejectedBookingItem) {
        newState[rejectedBookingItem?.id] = collection;
      }

      return;
    }

    collection.forEach((item: ShipmentActivityFeedItemData): void => {
      const activityItems: Activity[] = activityFeedItems.filter((activity: Activity) => activity.itemId === item.id);

      activityItems.forEach((activity: Activity) => {
        newState[activity.id] = item;
      });
    });
  };

  const { bookings, activityFeed } = update;

  // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
  updateState(bookings.nodes[0].quoteOptions);
  // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
  updateState(bookings.nodes[0].actionItems);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Maybe<({ __typename?: "TextMessa... Remove this comment to see the full error message
  updateState(activityFeed?.textMessages);
  // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
  updateState(bookings.nodes[0].bookingRequest);

  return newState;
}
