import type { FC } from 'react';

import CurrencyInput from '@zen/Components/CurrencyInput';
import type { Currency, Money } from '@zen/types';

import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import type { FormInstance } from '../types';

interface Props extends FormFieldProps {
  availableCurrencies?: Currency[];
  className?: string;
  isDisabled?: boolean;
  onChange?: (value: Money) => void;
  value?: Money;
}

const FormCurrencyInput: FC<Props> = (props) => {
  const { className, isDisabled, onChange, ...rest } = props;

  return (
    <FormField {...rest}>
      {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type. */}
      {(field, form: FormInstance<unknown>) => {
        return (
          <CurrencyInput
            {...field}
            className={className}
            isDisabled={isDisabled}
            onBlur={() => {
              form.setFieldTouched(field.name);
            }}
            onChange={(value: Money) => {
              if (onChange) {
                return onChange(value);
              }

              form.setFieldValue(field.name, value);
            }}
          />
        );
      }}
    </FormField>
  );
};

export default FormCurrencyInput;
