import type { AsyncSelectProps } from '@zen/DesignSystem';
import { AsyncSelect } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import type { FormFieldInputProps, FormInstance } from '../types';

interface Props<OptionType> extends FormFieldProps, Omit<AsyncSelectProps<OptionType>, 'name'> {
  onSelect?: (name: string, value: Nullable<OptionType>, form: FormInstance<OptionType>) => void;
}

const FormAsyncSelect = <OptionType extends {}>(props: Props<OptionType>) => {
  const { className, label, ...rest } = props;

  return (
    <FormField {...props}>
      {(field: FormFieldInputProps<OptionType>, form: FormInstance<OptionType>) => {
        const { label: fieldLabel, ...fieldRest } = field;

        return (
          <AsyncSelect<OptionType>
            {...fieldRest}
            {...rest}
            hasError={field.error}
            onChange={(value: Nullable<OptionType>) => {
              form.setFieldValue(field.name, value);

              props.onSelect?.(field.name, value, form);
            }}
          />
        );
      }}
    </FormField>
  );
};

export default FormAsyncSelect;
