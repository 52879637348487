import type { Booking } from '@zen/Booking/types';
import type { SortInput } from '@zen/types';
import { PageSize } from '@zen/types';
import { useUrlPagination } from '@zen/utils/hooks/pagination';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import { prepareFilterVariables } from '../Filters/helpers';
import type { CustomerAdminShipmentFilters } from '../Filters/types';
import type { CustomerAdminAllShipmentsQueryResult, CustomerAdminAllShipmentsQueryVariables } from '../graphql';
import { useCustomerAdminAllShipmentsQuery } from '../graphql';

interface Params {
  order: SortInput;
}

export const useAllShipments = ({ order }: Params) => {
  const { filters, search } = useUrlFilters<CustomerAdminShipmentFilters>();

  return useUrlPagination<CustomerAdminAllShipmentsQueryResult, CustomerAdminAllShipmentsQueryVariables, Booking>(
    useCustomerAdminAllShipmentsQuery,
    'bookings',
    {
      searchQuery: search,
      ...prepareFilterVariables(filters),
      order
    },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' },
    PageSize.TWENTY
  );
};
