import type { FC } from 'react';

import ActivityFeedItemDetails from '@zen/ActivityFeed/components/ActivityFeedItemDetails';
import ActivityFeedItemIcon from '@zen/ActivityFeed/components/ActivityFeedItemIcon';
import ActivityFeedItemWrapper from '@zen/ActivityFeed/components/ActivityFeedItemWrapper';
import type { Activity, PurchaseOrderClosedMetadataType } from '@zen/ActivityFeed/types';
import { ActivityFeedItemTypeEnum } from '@zen/ActivityFeed/types';

interface Props {
  activityMetadata: Activity;
}

const OrderItem: FC<Props> = ({ activityMetadata }) => {
  const { user, createdAt, itemType } = activityMetadata;

  const metadata: PurchaseOrderClosedMetadataType = activityMetadata.metadata as PurchaseOrderClosedMetadataType;
  const shouldShowUserDetails: boolean = itemType !== ActivityFeedItemTypeEnum.REQUIRED_CUSTOMER_APPROVAL;
  const closedLabel: string = `Purchase order closed ${metadata && !metadata?.manually ? '/ fully delivered' : ''}`;

  const orderItemsTitleMapping: Record<string, string> = {
    [ActivityFeedItemTypeEnum.CREATED_PURCHASE_ORDER]: 'Purchase order created',
    [ActivityFeedItemTypeEnum.CLOSED_PURCHASE_ORDER]: closedLabel
  };

  return (
    <ActivityFeedItemWrapper>
      <ActivityFeedItemDetails
        createdAt={createdAt}
        icon={<ActivityFeedItemIcon />}
        title={orderItemsTitleMapping[itemType]}
        user={shouldShowUserDetails ? user : null}
      />
    </ActivityFeedItemWrapper>
  );
};

export default OrderItem;
