import type { ReactNode } from 'react';

import type {
  AuthorizationResult,
  Cargo,
  CargoConsolidatedItemType,
  CargoContainerType,
  CargoDimensionsType,
  CargoItemTypeEnum,
  CargoLooseCargoType,
  CargoModeEnum,
  CargoVehicleType,
  CargoWeight,
  CargoWeightType,
  CollectionDetails,
  ConsolidatedLooseCargoItem,
  CoreCargo as CoreCargoInitial,
  DeliveryDetails,
  Dimensions,
  LooseCargoTypeEnum,
  Money,
  PalletTypeEnum,
  RiskLevelsEnum,
  VehicleTypeEnum
} from '@zen/graphql/types.generated';
import { ContainerTypeEnum } from '@zen/graphql/types.generated';
import type { IconName } from '@zen/Styleguide';
import { getContainerLabel } from '@zen/types';
import type { DeepNonNullable, DeepNullable, Modify, Nullable, Optional } from '@zen/utils/typescript';

import type { CargoItemsQueryResult } from './graphql';

export type {
  AuthorizationResult,
  Cargo,
  CargoCargoItem,
  CargoConsolidatedItemType,
  CargoContainerType,
  CargoDimensionsInputType,
  CargoDimensionsType,
  CargoLooseCargoType,
  CargoRelation,
  CargoSummary,
  CargoVehicleType,
  CargoWeight,
  CargoWeightInputType,
  CargoWeightType,
  CollectionDetails,
  ConsolidatedCargo,
  ConsolidatedLooseCargoItem,
  CoreCargo as CoreCargoInitial,
  CreateContainerCargoItemInput,
  CreateLooseCargoItemInput,
  CreateVehicleCargoItemInput,
  DeliveryDetails,
  Dimensions,
  Money,
  UpdateContainerCargoItemInput,
  UpdateLooseCargoItemInput,
  UpdateVehicleCargoItemInput,
  ValueOfGoods,
  Volume
} from '@zen/graphql/types.generated';
export {
  CargoDimensionsUnitEnum,
  CargoItemSubTypeEnum,
  CargoItemTypeEnum,
  CargoModeEnum,
  CargoWeightUnitEnum,
  CollectionAndDeliveryState,
  ContainerTypeEnum,
  Currency,
  LooseCargoTypeEnum,
  ModeOfTransport,
  PalletTypeEnum,
  RiskLevelsEnum,
  VehicleTypeEnum,
  WeightUnitEnum
} from '@zen/graphql/types.generated';

export enum TruckType {
  BOXED = 'BOXED',
  CURTAIN_SIDED = 'CURTAIN_SIDED',
  HIAB = 'HIAB',
  REFRIGERATED_TRUCK = 'REFRIGERATED_TRUCK',
  SLIDING_ROOF = 'SLIDING_ROOF'
}

export enum CargoFullContainerVariant {
  DRY = 'dryStandard',
  FLATRACK = 'flatRack',
  OPEN_TOP = 'openTop',
  REFRIGERATED = 'refrigerated'
}

export enum CargoParam {
  dryStandard = 'dryStandard',
  flatRack = 'flatRack',
  garmentsOnHangers = 'garmentsOnHangers',
  hazardous = 'hazardous',
  healthCertificate = 'healthCertificate',
  openTop = 'openTop',
  overweight = 'overweight',
  refrigerated = 'refrigerated',
  stackable = 'stackable',
  tailLift = 'tailLift'
}

export type CargoType = ContainerTypeEnum | LooseCargoTypeEnum | PalletTypeEnum | VehicleTypeEnum;
export type CargoItemDetailsType = CoreCargoType | CargoType | TruckType | CargoFullContainerVariant;

export type CoreCargoExtended = Modify<
  CoreCargoInitial,
  {
    cargoType: Nullable<CoreCargoType>;
  }
>;

export interface CoreCargo extends Omit<CoreCargoInitial, 'deletedAt' | 'cargoType' | 'cargoRelations'> {
  canManageAssignedLots?: AuthorizationResult;
  canManageCollectionLocation?: AuthorizationResult;
  canManageDeliveryLocation?: AuthorizationResult;
  canUpdateVehicleTrailerId?: AuthorizationResult;
  canViewCollectionDetails?: AuthorizationResult;
  canViewDeliveryDetails?: AuthorizationResult;
  cargoType: Optional<CoreCargoType>;
  collection?: CollectionDetails;
  delivery?: DeliveryDetails;
  id: string;
  legacyCargo?: boolean;
  looseCargo?: Optional<CoreCargoExtended>;
}

export interface CargoTransition {
  cargoList: Optional<CoreCargo[]>;
  mode?: CargoModeEnum;
}

export type LegacyCargo = Partial<
  Modify<
    Cargo,
    {
      cargoItems?: Nullable<CargoItem[]>;
    }
  >
>;

export interface CargoItemType {
  canManageAssignedLots: {
    __typename?: 'AuthorizationResult';
    value: boolean;
  };
  canManageCollectionLocation: {
    __typename?: 'AuthorizationResult';
    value: boolean;
  };
  canManageDeliveryLocation: {
    __typename?: 'AuthorizationResult';
    value: boolean;
  };

  canUpdateVehicleTrailerId: {
    __typename?: 'AuthorizationResult';
    value: boolean;
  };
  canViewCollectionDetails: {
    __typename?: 'AuthorizationResult';
    value: boolean;
  };
  canViewDeliveryDetails: {
    __typename?: 'AuthorizationResult';
    value: boolean;
  };
  cargoDetails?: string;
  cbm?: number;
  chargeableWeight?: CargoWeightType;
  collection?: CollectionDetails;
  containerNumber?: string;
  containerSealNumber?: string;
  containerType?: ContainerTypeEnum;
  delivery?: DeliveryDetails;
  dimensions?: Nullable<CargoDimensionsType>;
  grossWeight?: CargoWeightType;
  hazardous?: boolean;
  healthCertificate?: false;
  id: string;
  label?: string;
  looseCargoType?: LooseCargoTypeEnum;
  overweight?: boolean;
  palletType?: PalletTypeEnum;
  quantity?: number;
  reefer?: boolean;
  riskLevel?: RiskLevelsEnum;
  scheduledAt?: string;
  stackable?: boolean;
  tailLift?: boolean;
  title?: string;
  trailerId?: string;
  type?: CargoItemTypeEnum;
  valueOfGoods?: Money;
  vehicleType?: VehicleTypeEnum;
}

export interface LooseCargoFormData {
  actualCbm: Optional<number>;
  cargoType: Optional<CoreCargoType>;
  chargeableWeight: DeepNullable<CargoWeight>;
  containerNumber: Optional<string>;
  dimensions: Optional<Dimensions>;
  grossWeight: DeepNullable<CargoWeight>;
  hazardous: Optional<boolean>;
  id?: string;
  looseCargoType: Optional<CoreCargoType>;
  quantity: Optional<number>;
  refrigerated: Optional<boolean>;
  stackable: Optional<boolean>;
  trailerId: Optional<string>;
}

export interface VehicleCargoFormData {
  actualCbm: Optional<number>;
  dimensions: Optional<Dimensions>;
  grossWeight: DeepNullable<CargoWeight>;
  hazardous: Optional<boolean>;
  healthCertificate: Optional<boolean>;
  looseCargoType: Optional<CoreCargoType>;
  quantity: Optional<number>;
  refrigerated: Optional<boolean>;
  riskLevel: Optional<RiskLevelsEnum>;
  tailLift: Optional<boolean>;
  trailerId: Optional<string>;
  valueOfGoods: Optional<Money>;
  vehicleType: Optional<CoreCargoType>;
}

export interface FullContainerFormData {
  actualCbm: Optional<number>;
  containerNumber: Optional<string>;
  containerSealNumber: Optional<string>;
  containerType: CoreCargoType;
  dryStandard: Optional<boolean>;
  flatRack: Optional<boolean>;
  garmentsOnHangers: Optional<boolean>;
  grossWeight: DeepNullable<CargoWeight>;
  hazardous: Optional<boolean>;
  looseCargoType: Optional<CoreCargoType>;
  openTop: Optional<boolean>;
  overweight: Optional<boolean>;
  quantity: Optional<number>;
  refrigerated: Optional<boolean>;
}

export type CargoItemsData = DeepNonNullable<CargoItemsQueryResult, 'nodes'>['bookings']['nodes'];

export interface CargoItem
  extends Partial<Omit<CargoContainerType, '__typename'>>,
    Partial<Omit<CargoLooseCargoType, '__typename'>>,
    Partial<Omit<CargoVehicleType, '__typename'>> {
  __typename?: string;
  id: string;
}

export interface CargoUpdateMutationResponse {
  cargoItem: {
    type: CargoItemTypeEnum;
  };
}

export type ContainerCoreCargoType = Extract<
  CoreCargoType,
  'containerX20Dv' | 'containerX20Hc' | 'containerX40Dv' | 'containerX40Hc' | 'containerX45Hc'
>;

export type PalletCoreCargoType = Extract<
  CoreCargoType,
  'loosePalletEuro' | 'loosePalletOther' | 'loosePalletUk' | 'loosePalletUs'
>;

export const CargoManagementTrackingCategory = 'CargoManagement';

export enum CargoManagementTrackingAction {
  ADD_NEW_CARGO_ITEM = 'AddNewCargoItem',
  CHANGE_CARGO_MODE = 'ChangeCargoMode',
  DELETE_CARGO_ITEM = 'DeleteCargoItem',
  UPDATE_CARGO_ITEM = 'UpdateCargoItem'
}

export interface CargoSummaryConsolidatedItems {
  consolidatedCargoItems?: Optional<CargoConsolidatedItemType[]>;
  consolidatedLooseCargoItems?: Optional<ConsolidatedLooseCargoItem[]>;
}

export interface CalculatedInfoCargoSummary {
  highestRiskLevel?: Optional<RiskLevelsEnum>;
  label?: Optional<string>;
  totalActualCbm?: Optional<number>;
  totalChargeableWeight?: Optional<CargoWeightType[]>;
  totalGrossWeight?: Optional<CargoWeightType[]>;
  totalValueOfGoods?: Optional<Money[]>;
  type?: Optional<CargoItemTypeEnum>;
}

// TODO map values directly from cargo dictionary
export const CARGO_DICTIONARY_VALUES = [
  'containerX20Dv',
  'containerX20Hc',
  'containerX40Dv',
  'containerX40Hc',
  'containerX45Hc',
  'looseAssortedCargo',
  'looseBoxesOrCrates',
  'loosePalletEuro',
  'loosePalletLegacy',
  'loosePalletOther',
  'loosePalletUk',
  'loosePalletUs',
  'straightTruck',
  'trailer136MBox',
  'trailer136MCurtainSided',
  'trailer136MHiab',
  'trailer136MMegatrailer',
  'trailer136MMegatrailerCurtainSided',
  'trailer136MRefrigerated',
  'trailer136MSlidingRoof',
  'trailer18TBox',
  'trailer18THiab',
  'trailer18TCurtainSided',
  'trailer18TRefrigerate',
  'trailer18TSlidingRoof',
  '35TBox',
  '35TCurtainSided',
  '53FtTrailer',
  'trailer75TBox',
  'trailer75TCurtainSided',
  'trailer75TSlidingRoof',
  'trailerVanBox',
  'trailerVanCurtainSided',
  'trailerVanSlidingRoof'
] as const;

// Generation cargo types union to get at least part of control on cargo type values uncless we provide enum
export type CoreCargoType = (typeof CARGO_DICTIONARY_VALUES)[number];

// Generate instead of enum object that will contain all possible cargo dictionary values
export const cargoValues = CARGO_DICTIONARY_VALUES.reduce<{ [P in CoreCargoType]: P }>(
  (prev: { [P in CoreCargoType]: P }, next: CoreCargoType) => {
    return {
      ...prev,
      [next]: next
    };
  },
  {} as { [P in CoreCargoType]: P }
);

export interface ContainerType {
  label: string;
  type: CoreCargoType;
}

type CoreCargoContainerTypeToLabel = Record<ContainerCoreCargoType, { long: string; short: string }>;
export const coreCargoContainerTypeLabelMapping: CoreCargoContainerTypeToLabel = {
  [cargoValues.containerX20Dv]: {
    short: "20' ST",
    long: "20' standard"
  },
  [cargoValues.containerX20Hc]: {
    short: "20' HC",
    long: "20' high cube"
  },
  [cargoValues.containerX40Dv]: {
    short: "40' ST",
    long: "40' standard"
  },
  [cargoValues.containerX40Hc]: {
    short: "40' HC",
    long: "40' high cube"
  },
  [cargoValues.containerX45Hc]: {
    short: "45' HC",
    long: "45' high cube"
  }
};

export const coreCargoTypeLabelMapping: Record<CoreCargoType, string> = {
  [cargoValues.containerX20Dv]: getContainerLabel(ContainerTypeEnum.X20DV),
  [cargoValues.containerX20Hc]: getContainerLabel(ContainerTypeEnum.X20HC),
  [cargoValues.containerX40Dv]: getContainerLabel(ContainerTypeEnum.X40DV),
  [cargoValues.containerX40Hc]: getContainerLabel(ContainerTypeEnum.X40HC),
  [cargoValues.containerX45Hc]: getContainerLabel(ContainerTypeEnum.X45HC),
  [cargoValues.looseBoxesOrCrates]: 'Box or crate',
  [cargoValues.looseAssortedCargo]: 'Assorted cargo',
  [cargoValues.loosePalletEuro]: 'Euro pallet',
  [cargoValues.loosePalletUk]: 'UK standard pallet',
  [cargoValues.loosePalletUs]: 'US standard pallet',
  [cargoValues.loosePalletOther]: 'Other pallet',
  [cargoValues.loosePalletLegacy]: 'Pallet',
  [cargoValues.straightTruck]: 'Straight truck',
  [cargoValues.trailer136MBox]: '13.6m box',
  [cargoValues.trailer136MCurtainSided]: '13.6m curtain sided',
  [cargoValues.trailer136MHiab]: '13.6m hiab',
  [cargoValues.trailer136MMegatrailer]: '13.6m megatrailer',
  [cargoValues.trailer136MMegatrailerCurtainSided]: 'Mega curtain sided',
  [cargoValues.trailer136MRefrigerated]: '13.6m refrigerated',
  [cargoValues.trailer136MSlidingRoof]: '13.6m sliding Roof',
  [cargoValues.trailer18TBox]: '18t box',
  [cargoValues.trailer18THiab]: '18t hiab',
  [cargoValues.trailer18TCurtainSided]: '18t curtain sided',
  [cargoValues.trailer18TRefrigerate]: '18t refrigerated',
  [cargoValues.trailer18TSlidingRoof]: '18t sliding roof',
  [cargoValues['35TBox']]: '3.5t box',
  [cargoValues['35TCurtainSided']]: '3.5t curtain sided',
  [cargoValues['53FtTrailer']]: '53ft trailer',
  [cargoValues.trailer75TBox]: '7.5t box',
  [cargoValues.trailer75TCurtainSided]: '7.5t curtain sided',
  [cargoValues.trailer75TSlidingRoof]: '7.5t sliding roof',
  [cargoValues.trailerVanBox]: 'Van box',
  [cargoValues.trailerVanCurtainSided]: 'Van curtain sided',
  [cargoValues.trailerVanSlidingRoof]: 'Van sliding roof'
};

export const coreCargoTypeIconMapping: Record<CoreCargoType, IconName> = {
  [cargoValues.containerX20Hc]: 'zicon-shipment',
  [cargoValues.containerX20Dv]: 'zicon-shipment',
  [cargoValues.containerX40Dv]: 'zicon-shipment',
  [cargoValues.containerX40Hc]: 'zicon-shipment',
  [cargoValues.containerX45Hc]: 'zicon-shipment',
  [cargoValues.straightTruck]: 'zicon-road',
  [cargoValues.trailer136MBox]: 'zicon-road',
  [cargoValues.trailer136MCurtainSided]: 'zicon-road',
  [cargoValues.trailer136MHiab]: 'zicon-road',
  [cargoValues.trailer136MMegatrailer]: 'zicon-road',
  [cargoValues.trailer136MMegatrailerCurtainSided]: 'zicon-road',
  [cargoValues.trailer136MRefrigerated]: 'zicon-road',
  [cargoValues.trailer136MSlidingRoof]: 'zicon-road',
  [cargoValues.trailer18TBox]: 'zicon-road',
  [cargoValues.trailer18TCurtainSided]: 'zicon-road',
  [cargoValues.trailer18THiab]: 'zicon-road',
  [cargoValues.trailer18TRefrigerate]: 'zicon-road',
  [cargoValues.trailer18TSlidingRoof]: 'zicon-road',
  [cargoValues['35TBox']]: 'zicon-road' as IconName,
  [cargoValues['35TCurtainSided']]: 'zicon-road' as IconName,
  [cargoValues['53FtTrailer']]: 'zicon-road' as IconName,
  [cargoValues.trailer75TBox]: 'zicon-road' as IconName,
  [cargoValues.trailer75TCurtainSided]: 'zicon-road',
  [cargoValues.trailer75TSlidingRoof]: 'zicon-road',
  [cargoValues.trailerVanBox]: 'zicon-road',
  [cargoValues.trailerVanCurtainSided]: 'zicon-road',
  [cargoValues.trailerVanSlidingRoof]: 'zicon-road',
  [cargoValues.loosePalletEuro]: 'zicon-pallet',
  [cargoValues.loosePalletOther]: 'zicon-pallet',
  [cargoValues.loosePalletUk]: 'zicon-pallet',
  [cargoValues.loosePalletUs]: 'zicon-pallet',
  [cargoValues.looseAssortedCargo]: 'zicon-shipment',
  [cargoValues.looseBoxesOrCrates]: 'zicon-box',
  [cargoValues.loosePalletLegacy]: 'zicon-pallet'
};

export type CargoFieldName =
  | 'cargoType'
  | 'looseCargo'
  | 'chargeableWeight'
  | 'collectionReference'
  | 'containerNumber'
  | 'containerSealNumber'
  | 'deliveryReference'
  | 'driverDetails'
  | 'dimensions'
  | 'dryStandard'
  | 'flatRack'
  | 'garmentsOnHangers'
  | 'grossWeight'
  | 'hazardous'
  | 'healthCertificate'
  | 'openTop'
  | 'overweight'
  | 'refrigerated'
  | 'stackable'
  | 'tailLift'
  | 'trailerId'
  | 'valueOfCargo'
  | 'vehiclePlateNumber'
  | 'volume';

export type CargoDetailsFieldsConfig = {
  [CargoItemTypeEnum.CONTAINER]: Array<
    Omit<
      CargoFieldName,
      | 'chargeableWeight'
      | 'driverDetails'
      | 'dimensions'
      | 'healthCertificate'
      | 'stackable'
      | 'valueOfCargo'
      | 'tailLift'
      | 'trailerId'
      | 'vehiclePlateNumber'
    >
  >;
  [CargoItemTypeEnum.LOOSE_CARGO]: Array<
    Omit<
      CargoFieldName,
      | 'looseCargo'
      | 'containerSealNumber'
      | 'dryStandard'
      | 'flatRack'
      | 'garmentsOnHangers'
      | 'healthCertificate'
      | 'openTop'
      | 'overweight'
      | 'tailLift'
      | 'valueOfCargo'
    >
  >;

  [CargoItemTypeEnum.VEHICLE]: Array<
    Omit<
      CargoFieldName,
      | 'chargeableWeight'
      | 'containerSealNumber'
      | 'dryStandard'
      | 'flatRack'
      | 'garmentsOnHangers'
      | 'openTop'
      | 'overweight'
      | 'stackable'
    >
  >;
};

export type CargoFieldConfig = Record<CargoFieldName, { label: Nullable<string>; value: ReactNode }>;

export enum CargoParamIconSize {
  NORMAL = 'text-base',
  SMALL = 'text-sm'
}
