import type { FC, ReactNode } from 'react';

import { Modal } from '@zen/DesignSystem';
import type { Undefinable } from '@zen/utils/typescript';

import type { CreateEditModalConfiguration, CreateEditModalType, CreateFormProps, EditFormProps } from './types';

interface Props<InitialValueType> {
  configuration?: CreateEditModalConfiguration<InitialValueType>;
  createForm: FC<CreateFormProps>;
  editForm: FC<EditFormProps<InitialValueType>>;
  onClose: () => void;
}

const CreateEditModal = <InitialValueType extends {}>(props: Props<InitialValueType>) => {
  const { configuration, onClose, editForm: EditForm, createForm: CreateForm } = props;
  const type: Undefinable<CreateEditModalType> = configuration?.type;
  const initialValues: Undefinable<InitialValueType> = configuration?.initialValues;
  const modalTitle: string = configuration?.title || '';
  const modalOverflowY = configuration?.modalOverflowY || 'scroll';

  const isEdit: boolean = type === 'edit';

  const renderForm = (): ReactNode => {
    if (isEdit && initialValues) {
      return <EditForm item={initialValues} onClose={onClose} />;
    }

    return <CreateForm onClose={onClose} />;
  };

  return (
    <Modal
      closeOnClickAway={false}
      isOpen={!!configuration}
      modalOverflowY={modalOverflowY}
      onClose={onClose}
      tagline={configuration?.tagline}
      title={modalTitle}
    >
      <>{renderForm()}</>
    </Modal>
  );
};

export default CreateEditModal;
