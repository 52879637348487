import { groupBy } from 'lodash';

import { IncotermsValue, ModeOfTransport } from '@zen/Booking';
import { CargoModeEnum } from '@zen/Cargo';
import type { DateRange } from '@zen/DesignSystem';
import { getToday } from '@zen/utils/date';
import { add, compareDate } from '@zen/utils/dateTime';
import type { Undefinable } from '@zen/utils/typescript';

import type { PortChargeFilter, PortChargeInput } from '../types';
import { OriginDestinationChargeTypeEnum } from '../types';
import type {
  ContainerChargeInput,
  ContainerChargePayload,
  FreightChargeInput,
  FreightChargePayload,
  HaulageChargeInput,
  HaulageChargePayload,
  PortChargePayload,
  RateCardInput,
  RateCardReducerState
} from './reducer/types';

export const prepareInitialState = (issuedBy: string, initialValues?: RateCardReducerState): RateCardReducerState => {
  return {
    startDate: initialValues?.startDate || '',
    endDate: initialValues?.endDate || '',
    cargoType: initialValues?.cargoType || CargoModeEnum.FCL,
    destinationCharges: initialValues?.destinationCharges || [],
    freightCharges: initialValues?.freightCharges || [],
    modeOfTransport: initialValues?.modeOfTransport || ModeOfTransport.OCEAN,
    name: initialValues?.name || '',
    originCharges: initialValues?.originCharges || [],
    note: initialValues?.note || '',
    issuedBy: initialValues?.issuedBy || issuedBy,
    originHaulageCharges: initialValues?.originHaulageCharges || [],
    destinationHaulageCharges: initialValues?.destinationHaulageCharges || []
  };
};

const getInitialDateRange = (): DateRange => ({
  startDate: getToday(),
  endDate: add(getToday(), { days: 13 })
});

export const prepareRateCardInput = (values: RateCardReducerState, accountName: string = ''): RateCardInput => {
  const freightCharges: FreightChargeInput[] = prepareFreightChargeInputs(values.freightCharges);
  const originCharges: PortChargeInput[] = preparePortCharges(values.originCharges, OriginDestinationChargeTypeEnum.ORIGIN);
  const destinationCharges: PortChargeInput[] = preparePortCharges(
    values.destinationCharges,
    OriginDestinationChargeTypeEnum.DESTINATION
  );
  const destinationHaulageCharges: HaulageChargeInput[] = prepareHaulageCharges(
    values.destinationHaulageCharges,
    OriginDestinationChargeTypeEnum.DESTINATION
  );
  const originHaulageCharges: HaulageChargeInput[] = prepareHaulageCharges(
    values.originHaulageCharges,
    OriginDestinationChargeTypeEnum.ORIGIN
  );

  const { issuedBy } = values;

  const name: string = createRateCardName(values, accountName);

  return {
    ...values,
    name,
    issuedBy,
    freightCharges,
    originCharges,
    destinationCharges,
    destinationHaulageCharges,
    originHaulageCharges
  };
};

const createRateCardName = (
  { modeOfTransport, cargoType, endDate, startDate }: RateCardReducerState,
  accountName: string
): string => {
  return `${accountName}-${modeOfTransport}-${cargoType}-${startDate}-${endDate}`;
};

const prepareFreightChargeInputs = (freightCharges: FreightChargePayload[]): FreightChargeInput[] => {
  return freightCharges.map((freightCharge: FreightChargePayload) => {
    const { destinationPort, originPort, chargeType, incoterms } = freightCharge;
    const containerCharges: ContainerChargeInput[] = prepareContainerCharges(freightCharge.containerCharges);

    return {
      chargeTypeId: chargeType.id,
      containerCharges,
      destinationPortId: destinationPort.unlocode,
      originPortId: originPort.unlocode,
      incoterms
    };
  });
};

const preparePortCharges = (portCharges: PortChargePayload[], type: OriginDestinationChargeTypeEnum): PortChargeInput[] => {
  return portCharges.map((portCharge: PortChargePayload) => createPortChargeInput(portCharge, type));
};

const createPortChargeInput = (portCharge: PortChargePayload, type: OriginDestinationChargeTypeEnum): PortChargeInput => {
  const { centralPortChargeId, chargeType, chargeValue, customChargeValue, customCurrency, currency, incoterms, port, disabled } =
    portCharge;

  return {
    portChargeId: centralPortChargeId || null,
    chargeTypeId: chargeType.id,
    chargeValue: customChargeValue || chargeValue,
    currency: customCurrency || currency,
    incoterms,
    portId: port.unlocode,
    portChargeType: type,
    excluded: disabled
  };
};

const prepareHaulageCharges = (
  haulageCharges: HaulageChargePayload[],
  haulageType: OriginDestinationChargeTypeEnum
): HaulageChargeInput[] => {
  return haulageCharges.map((haulageCharge: HaulageChargePayload): HaulageChargeInput => {
    const { port, location, incoterms, chargeType, chargeValue, currency } = haulageCharge;

    return {
      port: port.unlocode,
      location: location.id || '',
      chargeType: chargeType.id,
      chargeValue,
      incoterms,
      currency,
      haulageType
    };
  });
};

const prepareContainerCharges = (containerCharges: ContainerChargePayload[]): ContainerChargeInput[] => {
  return containerCharges.map((containerCharge: ContainerChargePayload) => ({
    ...containerCharge,
    chargeValue: containerCharge.chargeValue || 0
  }));
};

export const getNextAvailableDateRange = (dateRanges: DateRange[]): DateRange => {
  const lastAvailableDateRange: Undefinable<DateRange> = [...sortDateRanges(dateRanges)].pop();

  if (!lastAvailableDateRange) {
    return getInitialDateRange();
  }

  const startDate: string = add(lastAvailableDateRange.endDate, { days: 1 });
  const endDate: string = add(startDate, { days: 13 });

  return { startDate, endDate };
};

const sortDateRanges = (dateRanges: DateRange[]): DateRange[] => {
  return [...dateRanges].sort((previous: DateRange, next: DateRange) => {
    return compareDate(previous.startDate).isBefore(next.startDate) ? -1 : 1;
  });
};

export const ORIGIN_INCOTERMS = [
  IncotermsValue.EXWORKS,
  IncotermsValue.CFR,
  IncotermsValue.CIF,
  IncotermsValue.CPT,
  IncotermsValue.CIP,
  IncotermsValue.DDP,
  IncotermsValue.DAP,
  IncotermsValue.DAT
];
export const DESTINATION_INCOTERMS = [
  IncotermsValue.EXWORKS,
  IncotermsValue.FOB,
  IncotermsValue.FCA,
  IncotermsValue.DDP,
  IncotermsValue.DAP,
  IncotermsValue.DAT
];

export const shouldAddCharges = (freightCharges: FreightChargePayload[], haulageType: 'origin' | 'destination'): boolean => {
  const requiredIncoterms: IncotermsValue[] = haulageType === 'origin' ? ORIGIN_INCOTERMS : DESTINATION_INCOTERMS;

  return freightCharges.some((freightCharge: FreightChargePayload) => {
    return freightCharge.incoterms.some((incoterm: IncotermsValue) => requiredIncoterms.includes(incoterm));
  });
};

type PortChargeFilters = {
  destinationPortChargeFilters: PortChargeFilter[];
  originPortChargeFilters: PortChargeFilter[];
};

export const preparePortChargeFilters = (freightCharges: FreightChargePayload[]): PortChargeFilters => {
  const originPortChargeFilters: PortChargeFilter[] = [];
  const destinationPortChargeFilters: PortChargeFilter[] = [];

  freightCharges.forEach(({ incoterms, originPort, destinationPort }: FreightChargePayload) => {
    originPortChargeFilters.push({ incoterms, port: originPort.unlocode, portType: OriginDestinationChargeTypeEnum.ORIGIN });
    destinationPortChargeFilters.push({
      incoterms,
      port: destinationPort.unlocode,
      portType: OriginDestinationChargeTypeEnum.DESTINATION
    });
  });

  return {
    originPortChargeFilters: mergeFiltersByPort(originPortChargeFilters),
    destinationPortChargeFilters: mergeFiltersByPort(destinationPortChargeFilters)
  };
};

const mergeFiltersByPort = (portChargeFilters: PortChargeFilter[]) => {
  return Object.values(groupBy(portChargeFilters, 'port')).map((filters: PortChargeFilter[]) =>
    createFilterWithAllIncoterms(filters)
  );
};

const createFilterWithAllIncoterms = (filters: PortChargeFilter[]): PortChargeFilter =>
  filters.reduce((previousValue: PortChargeFilter, nextPortChargeFilter: PortChargeFilter) => {
    const incoterms: Set<IncotermsValue> = new Set([
      ...(previousValue?.incoterms || []),
      ...(nextPortChargeFilter?.incoterms || [])
    ]);

    return {
      ...nextPortChargeFilter,
      incoterms: [...incoterms]
    };
  }, {});
