import { InMemoryCache } from '@apollo/client/cache';

import fragmentTypes from '@zen/graphql/fragmentTypes.generated';

import AuthorizationResult from './typePolicies/AuthorizationResult';
import Booking from './typePolicies/Booking';
import BookingManagementSettings from './typePolicies/BookingManagementSettings';
import BookingStageCheck from './typePolicies/BookingStageCheck';
import CalculatedInfo from './typePolicies/CalculatedInfo';
import Cargo from './typePolicies/Cargo';
import CargoConsolidatedItemType from './typePolicies/CargoConsolidatedItemType';
import CargoDimensionsType from './typePolicies/CargoDimensionsType';
import CargoSummary from './typePolicies/CargoSummary';
import CargoWeightType from './typePolicies/CargoWeightType';
import Carriage from './typePolicies/Carriage';
import ConsolidatedLooseCargoItem from './typePolicies/ConsolidatedLooseCargoItem';
import ConsolidatedShipment from './typePolicies/ConsolidatedShipment';
import Delay from './typePolicies/Delay';
import InFull from './typePolicies/InFull';
import LocalDateTimeRange from './typePolicies/LocalDateTimeRange';
import LocalDateTimeType from './typePolicies/LocalDateTimeType';
import MetricValueWithSI from './typePolicies/MetricValueWithSI';
import Money from './typePolicies/Money';
import NetworksContact from './typePolicies/NetworksContact';
import NetworksLabelType from './typePolicies/NetworksLabelType';
import PackageInfo from './typePolicies/PackageInfo';
import Permissions from './typePolicies/Permissions';
import PerUnitCbm from './typePolicies/PerUnitCbm';
import PortOfDestination from './typePolicies/PortOfDestination';
import PortOfLoad from './typePolicies/PortOfLoad';
import PurchaseOrder from './typePolicies/PurchaseOrder';
import PurchaseOrderEstimatedDateType from './typePolicies/PurchaseOrderEstimatedDateType';
import PurchaseOrderLotsDateType from './typePolicies/PurchaseOrderLotsDateType';
import Query from './typePolicies/Query';
import RichDate from './typePolicies/RichDate';
import RichDateTime from './typePolicies/RichDateTime';
import RoadTrackedShipment from './typePolicies/RoadTrackedShipment';
import ShipmentsTableFilterValue from './typePolicies/ShipmentsTableFilterValue';
import ShipmentsTableLocation from './typePolicies/ShipmentsTableLocation';
import Stage from './typePolicies/Stage';
import Summary from './typePolicies/Summary';
import Terminal from './typePolicies/Terminal';
import Tracking from './typePolicies/Tracking';

export const cache = new InMemoryCache({
  possibleTypes: fragmentTypes.possibleTypes,
  typePolicies: {
    AuthorizationResult,
    Booking,
    BookingDocumentTemplate: {
      keyFields: ['legacyId']
    },
    BookingCustomerType: {
      keyFields: ['uuid']
    },
    BookingManagementSettings,
    BookingStageCheck,
    Cargo,
    CargoConsolidatedItemType,
    CargoDimensionsType,
    CargoWeightType,
    Carriage,
    ConsolidatedLooseCargoItem,
    ConsolidatedShipment,
    Customer: {
      keyFields: ['uuid']
    },
    Delay,
    Money,
    MetricValueWithSI,
    NetworksLabelType,
    CargoSummary,
    NetworksContact,
    NotifiedParty: {
      keyFields: ['notifiedPartyId']
    },
    PackageInfo,
    Permissions,
    PerUnitCbm,
    PortOfLoad,
    PortOfDestination,
    PurchaseOrderEstimatedDateType,
    PurchaseOrderLotsDateType,
    PurchaseOrder,
    RichDateTime,
    RichDate,
    RoadTrackedShipment,
    Stage,
    InFull,
    Summary,
    Terminal,
    Tracking,
    Query,
    CalculatedInfo,
    LocalDateTimeRange,
    LocalDateTimeType,
    ShipmentsTableCargoGroup: {
      keyFields: ['groupId']
    },
    ShipmentsTableCargo: {
      keyFields: ['cargoId']
    },
    ShipmentsTableFilterValue,
    ShipmentsTableLocation
  }
});
