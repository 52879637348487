import type { FC } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import RateCardNotes from '@zen/RateCards/RateCardNotes';
import TermsAndConditionsLink from '@zen/RateCards/TermsAndConditionsLink';
import { rateCardRoutes } from '@zen/Routes';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import FreightChargeTable from '../FreightCharges/FreightChargeTable';
import HaulageChargeTable from '../HaulageCharges/HaulageChargeTable';
import OtherChargeTable from '../OtherCharges/OtherChargeTable';
import PortChargeTable from '../PortCharges/PortChargeTable';
import RateCardActions from '../RateCardActions';
import { RateCardProvider } from '../RateCardContext';
import RateCardTitle from '../RateCardTitle';
import type { RateCardCharge } from '../reducer';
import { formatRateCardData } from './helpers';
import { useRateCardDetails } from './hooks';
import RateCardActionsButtons from './RateCardActionsButtons';
import RateCardOverviewDetails from './RateCardOverviewDetails';
import type { FormattedRateCardDetails, RateCard } from './types';

type ActionType = (() => void) | undefined;

const RateCardDetails: FC = () => {
  const { check } = useGlobalPermissions();
  const { data, loading, error } = useRateCardDetails();
  const { navigateBack } = useNavigationHistory();

  const handleDeleteCompleted = (): void => {
    navigateBack(rateCardRoutes.index.getUrl());
  };

  return (
    <QueryHandler data={data?.getRateCard?.rateCard} error={!!error} isLoading={loading}>
      {(rateCard: RateCard) => {
        const rateCardDetails: FormattedRateCardDetails = formatRateCardData(rateCard, false);

        const {
          cargoType,
          destinationCharges,
          destinationHaulageCharges,
          freightCharges,
          modeOfTransport,
          name,
          note,
          originCharges,
          originHaulageCharges,
          otherCharges,
          rateCardId
        } = rateCardDetails;

        const preparePortCharges = (portCharges: RateCardCharge[]): RateCardCharge[] =>
          portCharges?.filter((portCharge) => !portCharge.defaultChargeHidden) || [];

        const originPortCharges: RateCardCharge[] = preparePortCharges(originCharges);
        const destinationPortCharges: RateCardCharge[] = preparePortCharges(destinationCharges);
        const shouldDisplayOriginCharges: boolean = !!originCharges?.length;
        const shouldDisplayDestinationCharges: boolean = !!destinationCharges?.length;
        const shouldDisplayOriginHaulageCharges: boolean = !!originHaulageCharges?.length;
        const shouldDisplayDestinationHaulageCharges: boolean = !!destinationHaulageCharges?.length;
        const shouldDisplayOtherCharges: boolean = !!otherCharges?.length;
        const shouldDisplayNote: boolean = !!note;

        return (
          <RateCardProvider cargoType={cargoType} isEditable={false} modeOfTransport={modeOfTransport}>
            <RateCardActions onDeleteCompleted={handleDeleteCompleted}>
              {({ handleClone, handleDelete, handleEdit }) => {
                const canCreateRateCards: boolean = check('accounts.canCreateRateCards');
                const canDeleteRateCards: boolean = check('accounts.canDeleteRateCards');

                const handleRateCardClone: ActionType = !canCreateRateCards ? undefined : () => handleClone(rateCardId);
                const handleRateCardDelete: ActionType = !canDeleteRateCards ? undefined : () => handleDelete(rateCardId);
                const handleRateCardEdit: ActionType = !canCreateRateCards ? undefined : () => handleEdit(rateCardId);

                return (
                  <Page
                    actionButtons={
                      <RateCardActionsButtons
                        onClone={handleRateCardClone}
                        onDelete={handleRateCardDelete}
                        onEdit={handleRateCardEdit}
                      />
                    }
                    defaultBackUrl={rateCardRoutes.index.getUrl()}
                    title={<RateCardTitle modeOfTransport={modeOfTransport} rateCardName={name} />}
                  >
                    <div className="flex flex-col space-y-6">
                      <RateCardOverviewDetails rateCard={rateCardDetails} />
                      <FreightChargeTable charges={freightCharges} />
                      {shouldDisplayOriginHaulageCharges && <HaulageChargeTable charges={originHaulageCharges} type="origin" />}
                      {shouldDisplayOriginCharges && <PortChargeTable charges={originPortCharges} type="origin" />}
                      {shouldDisplayDestinationCharges && <PortChargeTable charges={destinationPortCharges} type="destination" />}
                      {shouldDisplayDestinationHaulageCharges && (
                        <HaulageChargeTable charges={destinationHaulageCharges} type="destination" />
                      )}
                      {shouldDisplayOtherCharges && <OtherChargeTable charges={otherCharges} />}
                      {shouldDisplayNote && <RateCardNotes editable={false} value={note || ''} />}
                      <TermsAndConditionsLink />
                    </div>
                  </Page>
                );
              }}
            </RateCardActions>
          </RateCardProvider>
        );
      }}
    </QueryHandler>
  );
};

export default RateCardDetails;
