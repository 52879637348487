import type { ActivityFeedUser, ActivityType } from '@zen/ActivityFeed';
import type { Nullable } from '@zen/utils/typescript';

export const getActivityFeedUserInformation = (
  legacyUser?: Nullable<ActivityType['legacyUser']>,
  user?: Nullable<ActivityType['user']>,
  userDetails?: Nullable<ActivityType['userDetails']>
): ActivityFeedUser => {
  if (user) {
    const { firstName, lastName, email, account } = user;

    return {
      assignedRoles: [],
      avatarUrl: '',
      firstName,
      lastName,
      email,
      organisationName: account?.tradingName || ''
    };
  }

  if (userDetails) {
    const { firstName, lastName, email, organisationName } = userDetails;

    return {
      assignedRoles: [],
      avatarUrl: '',
      firstName,
      lastName,
      email,
      organisationName
    };
  }

  return {
    assignedRoles: legacyUser?.assignedRoles || [],
    avatarUrl: legacyUser?.avatarUrl || '',
    firstName: legacyUser?.firstName || '',
    lastName: legacyUser?.lastName || '',
    email: legacyUser?.email || '',
    organisationName: legacyUser?.organisation?.name || ''
  };
};
