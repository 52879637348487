import type { FC } from 'react';

import { FormInput, FormNumberInput, useForm } from '@zen/Components';
import FormCargoDimensionsInput from '@zen/Components/Form/FormCargoDimensionsInput';
import type { Nullable } from '@zen/utils/typescript';

import type { CargoDimensionsType, CargoItemDetailsType, CargoParam, CargoWeightType, PalletTypeEnum } from '../../../types';
import { CargoModeEnum, LooseCargoTypeEnum } from '../../../types';
import CargoParams from '../../CargoParams';
import FormCargoVariantTiles from '../../FormCargoVariantTiles';
import FormCargoWeightInput from '../../FormCargoWeightInput';
import FormPalletTypeSelect from '../../FormPalletTypeSelect';
import { getDefaultGrossWeight, getPalletDimensions, initialDimensions, isTemplatedPalletType } from './helpers';
import type { LooseFormValues } from './types';

interface Props {
  isDisabled?: boolean;
  showCargoParams?: boolean;
  showChargeableWeight?: boolean;
  showContainerNumber?: boolean;
}

const LooseFormFields: FC<Props> = ({ isDisabled, showCargoParams = true, showContainerNumber, showChargeableWeight }) => {
  const { values, setFieldValue, setTouched, setValues, initialValues } = useForm<LooseFormValues>();

  const { type, value: palletValue, dimensions, grossWeight } = values.loose;

  const isPalletSelected: boolean = type === LooseCargoTypeEnum.PALLETS;
  const isAssortedCargoSelected: boolean = type === LooseCargoTypeEnum.ASSORTED_CARGO;
  const isCargoDimensionsDisabled: boolean = palletValue ? isTemplatedPalletType(palletValue as PalletTypeEnum) : false;

  const handlePalletTypeChange = (value: Nullable<PalletTypeEnum>) => {
    const palletDimensions: CargoDimensionsType = value ? getPalletDimensions(value, dimensions, palletValue) : initialDimensions;
    const palletGrossWeight: CargoWeightType = getDefaultGrossWeight(value, grossWeight?.value);

    setValues({
      ...values,
      loose: {
        ...values.loose,
        value,
        dimensions: palletDimensions,
        grossWeight: palletGrossWeight
      }
    });
  };

  const handleCargoVariantChange = (cargoVariant: CargoItemDetailsType) => {
    if (cargoVariant !== LooseCargoTypeEnum.PALLETS) {
      setFieldValue('loose.dimensions', initialValues?.loose?.dimensions);
      setFieldValue('loose.value', null);
    } else {
      setFieldValue('loose.value', initialValues?.loose?.value);
    }
    setTouched({});
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-4" data-testid="loose-cargo-form">
        <FormCargoVariantTiles
          cargoMode={CargoModeEnum.LOOSE}
          className="col-span-3"
          isDisabled={isDisabled}
          name="loose.type"
          onChange={handleCargoVariantChange}
        />
        {isPalletSelected && (
          <FormPalletTypeSelect
            isDisabled={isDisabled}
            label="Pallet type"
            name="loose.value"
            onPalletTypeChange={handlePalletTypeChange}
          />
        )}
        {!isAssortedCargoSelected && (
          <FormNumberInput disabled={isDisabled} min={0} name="loose.quantity" placeholder="eg. 12345" step={1} />
        )}
        {showContainerNumber && <FormInput label="Container number" name="loose.containerNumber" />}
        <div className="col-span-3">
          <div className="grid grid-cols-3 gap-x-4" data-testid="cargo-measurement">
            {!isAssortedCargoSelected && (
              <div className="col-span-2">
                <FormCargoDimensionsInput
                  hideErrorMessage={true}
                  initialDimensionsDisabled={isCargoDimensionsDisabled}
                  isDisabled={isDisabled}
                  name="loose.dimensions"
                />
              </div>
            )}

            <FormCargoWeightInput
              className="row-start-2"
              isDisabled={isDisabled}
              label="Gross weight"
              min={0}
              name="loose.grossWeight"
            />
            <FormNumberInput
              className="row-start-2"
              disabled={isDisabled}
              label="CBM"
              name="loose.volume"
              placeholder="0.0"
              step={1}
            />
            {showChargeableWeight && (
              <FormCargoWeightInput
                className="row-start-2"
                isDisabled={isDisabled}
                label="Chargeable weight"
                min={0}
                name="loose.chargeableWeight"
              />
            )}
          </div>
        </div>
      </div>
      {showCargoParams && !!type && (
        <CargoParams cargoType={type} className="grid grid-cols-3 gap-4" getFieldName={(param: CargoParam) => `loose.${param}`} />
      )}
    </>
  );
};

export default LooseFormFields;
