import type { FC, ReactNode } from 'react';

import type { FormInstance } from '@zen/Components';
import { Form, FormButtons, FormHeadline } from '@zen/Components';
import FormTextarea from '@zen/Components/Form/FormTextarea';
import { Button, Modal } from '@zen/DesignSystem';
import type { DeleteTaskFormValues } from '@zen/Tasks/TaskLists/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { validationSchema } from './deleteTaskForm.validation';

interface Props {
  closeModal: () => void;
  isModalOpen: boolean;
  onConfirm: ({ taskId, deleteReason }: { deleteReason: string; taskId: string }) => Promise<IOkOrErrorResult>;
  taskId: string;
}

const DeleteTaskModal: FC<Props> = (props) => {
  const { isModalOpen = false, closeModal, taskId, onConfirm } = props;

  const renderFormButtons = ({ isSubmitting }: FormInstance<{ members: string[] }>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Delete task">
      <Button data-testid="cancel-button" onClick={() => closeModal()} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const renderFormContent = (): ReactNode => {
    return (
      <>
        <FormHeadline
          level={3}
          tagline="Let us know so we can help improve this workflow for you and your colleagues."
          text="Why are you deleting this task?"
        />
        <FormTextarea name="reason" textareaRows={4} />
      </>
    );
  };

  const handleSubmit = async ({ reason }: DeleteTaskFormValues): Promise<IOkOrErrorResult<{}>> => {
    return onConfirm({ taskId, deleteReason: reason });
  };

  const initialFormValues: DeleteTaskFormValues = { reason: '' };

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal} title="Delete task">
      <Form
        formButtons={renderFormButtons}
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        onSuccess={closeModal}
        validationSchema={validationSchema}
      >
        {renderFormContent}
      </Form>
    </Modal>
  );
};

export default DeleteTaskModal;
