import type { FC } from 'react';

import { useUpdateVehicleCargoItemMutation } from '@zen/Cargo/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import {
  CargoManagementTrackingAction,
  CargoManagementTrackingCategory,
  type CoreCargo,
  type UpdateVehicleCargoItemInput
} from '../../../types';
import FullTruckForm from '../FullTruckForm';
import type { FullTruckFormValues } from '../FullTruckForm/types';
import { prepareFullTruckValues } from '../helpers';
import { prepareFullTruckFormInitialValues } from './helpers';

interface Props {
  cargo: CoreCargo;
  onCancel: () => void;
  onSuccess: () => void;
}

const EditFullTruck: FC<Props> = ({ cargo, onCancel, onSuccess }) => {
  const [editVehicle] = useUpdateVehicleCargoItemMutation();
  const { addError, addSuccess } = useNotification();
  const { trackEvent } = useTracking();

  const handleSubmit = async (values: FullTruckFormValues): Promise<IOkOrErrorResult> => {
    const input: UpdateVehicleCargoItemInput = {
      id: cargo.id,
      ...prepareFullTruckValues(values)
    };

    return performMutation({
      mutationFn: () =>
        editVehicle({
          variables: {
            input
          },
          refetchQueries: [
            'bookingDetails',
            'bookingOverview',
            'bookingSummary',
            'cargoJourneysSummary',
            'cargoOverview',
            'cargoSummary'
          ],
          awaitRefetchQueries: true
        }),
      onError: () => addError()
    });
  };

  const handleSuccess = () => {
    addSuccess('Cargo successfully updated');
    onSuccess();

    trackEvent({
      category: CargoManagementTrackingCategory,
      action: CargoManagementTrackingAction.UPDATE_CARGO_ITEM,
      label: cargo.cargoType || ''
    });
  };

  return (
    <FullTruckForm
      initialValues={prepareFullTruckFormInitialValues(cargo)}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
    />
  );
};

export default EditFullTruck;
