import type { FC } from 'react';
import { useState } from 'react';

import { InlineEmptyState } from '@zen/DesignSystem';
import type { Issue } from '@zen/Issues/types';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Nullable } from '@zen/utils/typescript';

import IssueModalContainer from '../IssueModalContainer';
import IssueListSection from './IssueListSection';

interface Props {
  associatedObjectReference: string;
  issues: Issue[];
  onIssueChange: () => void;
}

const Issues: FC<Props> = (props) => {
  const { issues, associatedObjectReference, onIssueChange } = props;
  const { trackEvent } = useTracking();
  const [currentIssue, setCurrentIssue] = useState<Nullable<Issue>>(null);

  const hasIssues: boolean = issues.length > 0;

  const handleIssueDetailsClose = () => {
    setCurrentIssue(null);
  };

  const handleIssueClick = (issueItem: Issue) => {
    trackEvent({
      action: 'ShowIssue',
      category: 'Issues',
      label: issueItem.category
    });

    setCurrentIssue(issueItem);
  };

  return (
    <div className="mt-4">
      {!hasIssues && (
        <InlineEmptyState title="There are no issues raised for this booking.">
          Issues are requests that affect the status of an active shipment.
        </InlineEmptyState>
      )}
      <IssueListSection issues={issues} onIssueClick={handleIssueClick} />
      {currentIssue && (
        <IssueModalContainer
          associatedObjectReference={associatedObjectReference}
          issue={currentIssue}
          onClose={handleIssueDetailsClose}
          onIssueChange={onIssueChange}
        />
      )}
    </div>
  );
};

export default Issues;
