import type { FC } from 'react';

import { Button } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useActivateInactiveAccountMutation } from '../../graphql';

interface Props {
  accountId: string;
  onSuccess?: () => void;
}

const ActivateAccountButton: FC<Props> = ({ accountId, onSuccess }) => {
  const { addError, addSuccess } = useNotification();
  const [activateAccount, { loading }] = useActivateInactiveAccountMutation();

  const handleActivateAccount = async () => {
    await performMutation({
      mutationFn: () =>
        activateAccount({
          awaitRefetchQueries: false,
          refetchQueries: ['getAccountDetails'],
          variables: {
            accountId
          }
        }),
      onError: () => {
        addError('Something went wrong when trying to activate account.');
      },
      onSuccess: () => {
        addSuccess('Account activated.');
        onSuccess?.();
      }
    });
  };

  return (
    <Button disabled={loading} onClick={handleActivateAccount} variant="secondary">
      Reactivate account
    </Button>
  );
};

export default ActivateAccountButton;
