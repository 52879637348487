import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import type { FormInstance } from '@zen/Components';
import { Form, FormButtons, FormInput } from '@zen/Components';
import { Headline } from '@zen/DesignSystem';
import { dashboardRoutes } from '@zen/Routes';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import FormTermsAndConditionsCheckbox from '../components/forms/FormTermsAndConditionsCheckbox/FormTermsAndConditionsCheckbox';
import { validationSchema } from './captureProfileUsersName.validation';
import { useUpdateAccountMemberProfileInformationMutation } from './graphql';
import Logo from './logo.svg?react';

interface UsersName {
  firstName: string;
  lastName: string;
  terms: boolean;
}

interface Props {
  onSuccess?: () => void;
}

const CaptureProfileUsersName: FC<Props> = ({ onSuccess }) => {
  const initialValues: UsersName = {
    firstName: '',
    lastName: '',
    terms: false
  };
  const { addError } = useNotification();
  const [updateAccountMember] = useUpdateAccountMemberProfileInformationMutation();
  const navigate = useNavigate();

  const renderFormButtons = ({ isSubmitting }: FormInstance<UsersName>): ReactNode => {
    return <FormButtons className="flex-1" isSubmitting={isSubmitting} text="Complete profile" />;
  };

  const handleUpdateAccountMemberProfile = ({ firstName, lastName }: UsersName): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateAccountMember({
          variables: {
            input: {
              firstName,
              lastName
            }
          }
        }),
      onError: () => addError()
    });
  };

  const handleSuccess = (): void => {
    return onSuccess ? onSuccess() : navigate(dashboardRoutes.dashboard.getUrl());
  };

  return (
    <div
      className="fixed inset-0 flex flex-col items-center justify-center p-20 bg-white"
      data-testid="capture-profile-users-name-page"
    >
      <div className="flex flex-col max-w-xs">
        <Logo className="w-32 mb-16" data-testid="logo" />
        <Headline level={1}>Complete your profile</Headline>
        <p className="mt-2 mb-8 text-grey-base">Please enter your first and late name.</p>
        <Form
          formButtons={renderFormButtons}
          initialValues={initialValues}
          onSubmit={handleUpdateAccountMemberProfile}
          onSuccess={handleSuccess}
          validationSchema={validationSchema}
        >
          {() => {
            return (
              <div className="flex flex-col text-left">
                <FormInput name="firstName" />
                <FormInput name="lastName" />
                <FormTermsAndConditionsCheckbox className="pr-9" name="terms" />
              </div>
            );
          }}
        </Form>
      </div>
    </div>
  );
};

export default CaptureProfileUsersName;
