import type { ChargeType } from '../types';
import { ChargeBasisEnum, ChargeTypeEnum } from '../types';

export const createChargeType = (override: Partial<ChargeType> = {}): ChargeType => ({
  ...chargeType,
  ...override
});

const chargeType: ChargeType = {
  chargeBasis: ChargeBasisEnum.BOOKING,
  chargeType: ChargeTypeEnum.FREIGHT,
  fortyFiveFtHc: false,
  fortyFtDv: false,
  fortyFtHc: false,
  hazardous: false,
  id: 'ac9a3f05-471a-45c0-9837-06224031cf85',
  modeOfTransport: null,
  name: 'Additional Driver',
  overweight: false,
  reefer: false,
  twentyFtDv: false,
  twentyFtHc: false,
  vatApplicable: false
};
