import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import { ModeOfTransportIcon } from '@zen/Booking/BookingDetails/components';
import CargoTypeImage from '@zen/Cargo/components/CargoTypeImage';
import NoResultsComponent from '@zen/Components/NoResults';
import type { ModeOfTransport } from '@zen/types';
import { CargoModeEnum, LooseCargoTypeEnum } from '@zen/types';

import NewCargoModal from '../NewCargoModal';

interface Props {
  canManageCargo: boolean;
  cargoMode: CargoModeEnum;
  modeOfTransport: ModeOfTransport;
  zencargoReference: string;
}

const NoResults: FC<Props> = ({ canManageCargo, modeOfTransport, cargoMode, zencargoReference }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const buttonConfig = canManageCargo ? { label: 'Add cargo', onClick: () => setIsModalVisible(true) } : undefined;
  const tagline: string = canManageCargo ? 'Please add a cargo item.' : 'Our Operations team will add cargo in due course.';
  const image: ReactNode =
    cargoMode === CargoModeEnum.LOOSE ? (
      <CargoTypeImage cargoType={LooseCargoTypeEnum.PALLETS} className="w-28" />
    ) : (
      <ModeOfTransportIcon modeOfTransport={modeOfTransport} size="large" />
    );

  return (
    <>
      <NoResultsComponent
        button={buttonConfig}
        headline="You don’t have any cargo items in this shipment"
        image={image}
        tagline={tagline}
      />
      <NewCargoModal
        cargoMode={cargoMode}
        isOpen={isModalVisible}
        modeOfTransport={modeOfTransport}
        onClose={() => setIsModalVisible(false)}
        zencargoReference={zencargoReference}
      />
    </>
  );
};

export default NoResults;
