import type { FC } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';

import { type Account, AccountStatus } from '../types';
import ActivateAccount from './ActivateAccount';
import DeactivateAccount from './DeactivateAccount';
import DemoAccountDetails from './DemoAccountDetails';
import InternalSupplyDetails from './InternalSupplyDetails';
import LegalDetails from './LegalDetails';
import MainDetails from './MainDetails';

interface Props {
  accountDetails: Account;
}
const GeneralAccountDetails: FC<Props> = ({ accountDetails }) => {
  const {
    businessUnits,
    demoMode,
    hasBusinessUnitsThatSupplyEachOther,
    tradingName,
    referencePrefix,
    legalInformation,
    id: accountId,
    status
  } = accountDetails;

  const { check } = useGlobalPermissions();

  const canActivateAccount: boolean = check('accounts.canActivate');
  const canDeactivateAccount: boolean = check('accounts.canDeactivate');
  const canToggleDemoMode: boolean = check('accounts.canUpdateDemoMode');
  const isAccountInactive: boolean = status === AccountStatus.INACTIVE;
  const hasBusinessUnits: boolean = businessUnits.length > 0;

  return (
    <div className="grid grid-cols-8 gap-8 xl:gap-6 xl:grid-cols-12">
      <MainDetails accountId={accountId} referencePrefix={referencePrefix} tradingName={tradingName} />
      <LegalDetails {...legalInformation} accountId={accountId} />
      {canToggleDemoMode && !isAccountInactive && <DemoAccountDetails accountId={accountId} demoMode={demoMode} />}
      {hasBusinessUnits && (
        <InternalSupplyDetails
          accountId={accountId}
          hasBusinessUnitsThatSupplyEachOther={!!hasBusinessUnitsThatSupplyEachOther}
        />
      )}
      {canActivateAccount && isAccountInactive && <ActivateAccount accountId={accountId} />}
      {canDeactivateAccount && !isAccountInactive && <DeactivateAccount accountId={accountId} tradingName={tradingName} />}
    </div>
  );
};

export default GeneralAccountDetails;
