import type { FC } from 'react';

import type { FormInstance } from '@zen/Components';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import FormField from '@zen/Components/Form/FormField';
import type { Nullable } from '@zen/utils/typescript';

import CourierSelect from '../CourierSelect';

interface Props extends FormFieldProps {
  isClearable?: boolean;
  isDisabled?: boolean;
}

const FormCourierSelect: FC<Props> = (props) => {
  const { label, ...rest } = props;

  return (
    <FormField {...props}>
      {(field: { error: boolean; label: string; name: string; value: Nullable<string> }, form: FormInstance<unknown>) => {
        const { label: fieldLabel, ...fieldRest } = field;

        return (
          <CourierSelect
            {...rest}
            {...fieldRest}
            error={field.error}
            onChange={(value: Nullable<string>) => {
              form.setFieldValue(field.name, value);
            }}
          />
        );
      }}
    </FormField>
  );
};

export default FormCourierSelect;
