import type { FC } from 'react';

import { isBookingConfirmed } from '@zen/Booking/helpers';
import QueryHandler from '@zen/Components/QueryHandler';
import { getCarrier, getLocation } from '@zen/CostTracking';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import type { CargoModeEnum } from '@zen/types';
import type { Nullable, Optional } from '@zen/utils/typescript';

import BookingRateCard from './BookingRateCard';
import { useRateCardBookingDetailsQuery } from './graphql';
import PriceCalculationSection from './PriceCalculationSection';
import { isRateCardAvailable } from './PriceCalculationSection/helpers';
import RateCardBookingDetails from './RateCardBookingDetails';
import { Applicability, type RateCardBookingDetailsType, type Terminal } from './types';

interface Props {
  editable?: boolean;
  onCreateAccrual?: (rateCardId?: string, params?: string) => void;
  zencargoReference: string;
}

const RateCardSection: FC<Props> = ({ zencargoReference, editable = true, onCreateAccrual }) => {
  const { data, loading, error } = useRateCardBookingDetailsQuery({ variables: { zencargoReference } });
  const isLegacyRateCardsEnabled: boolean = useFeatureIsOn('legacy-rate-cards');
  const isRateCardsEnabled: boolean = useFeatureIsOn('rate-cards');

  return (
    <div>
      <QueryHandler data={data?.bookings.nodes?.[0]} error={!!error} isLoading={loading}>
        {(bookingDetails: RateCardBookingDetailsType) => {
          const { bookingStage, cargo, cargoJourneys, modeOfTransport } = bookingDetails;
          const originTerminal: Nullable<Terminal> = getLocation(cargoJourneys, Applicability.ORIGIN);
          const destinationTerminal: Nullable<Terminal> = getLocation(cargoJourneys, Applicability.DESTINATION);
          const cargoMode: Optional<CargoModeEnum> = cargo?.mode;
          const carrier: Optional<string> = getCarrier(cargoJourneys);
          const shouldRenderRateCardInformation =
            isRateCardAvailable(modeOfTransport, cargoMode) && (isLegacyRateCardsEnabled || isRateCardsEnabled);

          if (!shouldRenderRateCardInformation) {
            return null;
          }

          return (
            <div className="space-y-2">
              {modeOfTransport && cargoMode && (
                <BookingRateCard
                  cargoType={cargoMode}
                  destinationPort={destinationTerminal}
                  editable={editable}
                  isBookingConfirmed={isBookingConfirmed(bookingStage?.name)}
                  modeOfTransport={modeOfTransport}
                  onCreateAccrual={onCreateAccrual}
                  originPort={originTerminal}
                  zencargoReference={zencargoReference}
                />
              )}
              <RateCardBookingDetails
                carrier={carrier}
                destinationTerminal={destinationTerminal}
                originTerminal={originTerminal}
                showCarrier={editable}
              />
              <PriceCalculationSection editable={editable} zencargoReference={zencargoReference} />
            </div>
          );
        }}
      </QueryHandler>
    </div>
  );
};

export default RateCardSection;
