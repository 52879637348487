import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import type { UserProfile } from '@zen/Auth/UserDataProvider/types';
import type { Role } from '@zen/graphql/types.generated';
import { authRoutes } from '@zen/Routes';
import applicationVersion from '@zen/utils/applicationVersion';

import TrackingContext from './TrackingContext';
import type { TrackingEvent, TrackingPageContext } from './types';

interface TrackingProviderProps {
  children: ReactNode;
}

const restrictedPaths: string[] = [authRoutes.resetPassword.getUrl()];

const getRestrictedPageContext = (hideUrlDetails: boolean): Partial<TrackingPageContext> => {
  const urlWithoutSearchParams: string = window.location.href.split('?')[0];

  return hideUrlDetails ? { search: 'RESTRICTED', url: urlWithoutSearchParams } : {};
};

export const trackEvent = (event: TrackingEvent, hideUrlDetails: boolean = false): void => {
  if (!window.analytics) return;
  window.analytics.track(
    event.action,
    {
      category: event.category,
      label: event.label,
      value: event.value,
      ...event.properties,
      applicationVersion
    },
    {
      context: {
        page: getRestrictedPageContext(hideUrlDetails),
        app: {
          name: 'Web',
          version: applicationVersion
        }
      }
    }
  );
};

const TrackingProvider = ({ children }: TrackingProviderProps) => {
  const location = useLocation();

  const shouldHideUrlDetails: boolean = restrictedPaths.some((path: string) => location.pathname.includes(path));

  // Send pageviews
  useEffect(() => {
    if (!window.analytics) return;

    if (shouldHideUrlDetails) {
      return window.analytics.page(getRestrictedPageContext(shouldHideUrlDetails), {
        context: {
          app: {
            name: 'Web',
            version: applicationVersion
          }
        }
      });
    }

    window.analytics.page(
      {},
      {
        context: {
          app: {
            name: 'Web',
            version: applicationVersion
          }
        }
      }
    );
  }, [location, shouldHideUrlDetails]);

  const identify = (accountUuid: string, userProfile: UserProfile, role: Role): void => {
    const { uuid, email, firstName, lastName, phoneNumber, registeredCompanyName, isAdmin } = userProfile;

    if (!window.analytics) return;
    window.analytics.identify(
      uuid,
      {
        uuid,
        accountUuid,
        email,
        firstName,
        lastName,
        displayName: `${firstName || ''} ${lastName || ''}`.trim(),
        companyName: registeredCompanyName,
        userRole: role,
        // Intercom
        isAdmin,
        phone: phoneNumber
      },
      {
        context: {
          app: {
            name: 'Web',
            version: applicationVersion
          },
          page: getRestrictedPageContext(shouldHideUrlDetails)
        }
      }
    );
    if (!isAdmin) {
      // Send the group only if the user isn't an admin
      window.analytics.group(accountUuid, {
        company: registeredCompanyName
      });
    }
  };

  return <TrackingContext.Provider value={{ trackEvent, identify }}>{children}</TrackingContext.Provider>;
};

export default TrackingProvider;
