import type { EventLog, EventLogActionType } from '@zen/Components/EventLog/types';
import type { NetworksContact } from '@zen/Networks';
import { formatDateAndTimeRange } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

import type { JourneyMilestoneDateLog, LocalDateTimeRange } from './types';
import {
  JourneyMilestoneDateLogDateTypeEnum,
  JourneyMilestoneDateLogEventTypeEnum,
  JourneyModificationSourceEnum
} from './types';

const eventTypeMapping: Record<JourneyMilestoneDateLogEventTypeEnum, EventLogActionType> = {
  [JourneyMilestoneDateLogEventTypeEnum.ADDED]: 'added',
  [JourneyMilestoneDateLogEventTypeEnum.UPDATED]: 'updated',
  [JourneyMilestoneDateLogEventTypeEnum.REMOVED]: 'removed'
};

const dateTypeMapping: Record<JourneyMilestoneDateLogDateTypeEnum, string> = {
  [JourneyMilestoneDateLogDateTypeEnum.ACTUAL]: 'actual',
  [JourneyMilestoneDateLogDateTypeEnum.DRAFT]: 'calculated',
  [JourneyMilestoneDateLogDateTypeEnum.PLANNED]: 'booked',
  [JourneyMilestoneDateLogDateTypeEnum.REQUESTED]: 'requested'
};

const eventTypePrefixMapping: Record<JourneyMilestoneDateLogEventTypeEnum, string> = {
  [JourneyMilestoneDateLogEventTypeEnum.ADDED]: 'of',
  [JourneyMilestoneDateLogEventTypeEnum.REMOVED]: '',
  [JourneyMilestoneDateLogEventTypeEnum.UPDATED]: 'to'
};

export const getDateTypeLabel = (dateType: JourneyMilestoneDateLogDateTypeEnum, isSelectedMilestoneShared: boolean): string => {
  if (isSelectedMilestoneShared && dateType === JourneyMilestoneDateLogDateTypeEnum.REQUESTED) return 'planned';

  return dateTypeMapping[dateType];
};

export const getUsername = (
  source: Optional<JourneyModificationSourceEnum>,
  createdBy: Optional<Pick<NetworksContact, 'firstName' | 'lastName'>>
) => {
  if (source === JourneyModificationSourceEnum.TRACKING) return 'Live tracking';
  if (source === JourneyModificationSourceEnum.AIS_TRACKING) return 'AIS tracking';
  if (source === JourneyModificationSourceEnum.CARRIER_TRACKING) return 'Carrier tracking';
  if (source === JourneyModificationSourceEnum.PREDICTION_TRACKING) return 'AI algorithm';
  if (source === JourneyModificationSourceEnum.SYSTEM) return 'System';

  return createdBy ? `${createdBy.firstName} ${createdBy.lastName.charAt(0)}.` : 'Unknown';
};

export const getDateTimeTypeLabel = (date: Optional<LocalDateTimeRange>): string => {
  if (!date || !date.startDateTime.time) return 'date';

  return 'date and time';
};

export const getDateLabel = (date: Optional<LocalDateTimeRange>, eventType: JourneyMilestoneDateLogEventTypeEnum): string => {
  if (!date) return '';

  return `${eventTypePrefixMapping[eventType]} ${formatDateAndTimeRange(date.startDateTime, date.endDateTime)}`.trim();
};

export const getEventLabel = (
  dateType: JourneyMilestoneDateLogDateTypeEnum,
  date: Optional<LocalDateTimeRange>,
  eventType: JourneyMilestoneDateLogEventTypeEnum,
  isSelectedMilestoneShared: boolean
) => {
  const dateTimeLabel: string = getDateTimeTypeLabel(date);
  const label: string = getDateLabel(date, eventType);

  return (
    <>
      <span className="font-bold">
        {getDateTypeLabel(dateType, isSelectedMilestoneShared)} {dateTimeLabel}{' '}
      </span>
      <span className="text-grey-base">{label}</span>
    </>
  );
};

export const getEventTooltip = (secondaryDate: Optional<LocalDateTimeRange>): string | undefined => {
  if (!secondaryDate) return undefined;

  return `Carrier tracking of ${formatDateAndTimeRange(secondaryDate.startDateTime, secondaryDate.endDateTime)}`;
};

export const prepareEvents = (logs: JourneyMilestoneDateLog[], isSelectedMilestoneShared: boolean): EventLog[] => {
  return logs.map(({ createdAt, createdBy, createdByUser, date, dateType, eventType, secondaryDate, source }) => ({
    createdAt,
    eventAction: eventTypeMapping[eventType],
    eventLabel: getEventLabel(dateType, date, eventType, isSelectedMilestoneShared),
    username: getUsername(source, createdByUser || createdBy),
    tooltip: getEventTooltip(secondaryDate)
  }));
};
