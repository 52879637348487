import cx from 'classnames';
import type { FC, PropsWithChildren, SyntheticEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { HEADER_HEIGHT_IN_REM, SLIDEOUT_OVERLAY_ZINDEX, SLIDEOUT_ZINDEX } from '@zen/Layout';
import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingParentAttribute } from '@zen/utils/tracking';

export interface Props {
  className?: string;
  disableOverlay?: boolean;
  isTransformed?: boolean;
  onOutsideClick?: () => void;
  overlay?: boolean;
  show: boolean;
  unmountOnClose?: boolean;
}

export const displayName = 'Components/Slideout';

const Slideout: FC<PropsWithChildren<Props>> = (props) => {
  const {
    className,
    overlay = false,
    children,
    onOutsideClick = () => {},
    show,
    unmountOnClose = false,
    isTransformed = true,
    disableOverlay = false
  } = props;
  const [contentMounted, setContentMounted] = useState<boolean>(show);
  const { trackEvent } = useTracking();

  const handleOutsideClick = (event: SyntheticEvent): void => {
    const trackingEvent = createTrackingEvent(displayName, 'outside-click', event);

    trackEvent(trackingEvent);
    onOutsideClick?.();
  };

  const unmountContent = useCallback(() => setTimeout(() => setContentMounted(false), 500), [setContentMounted]);

  useEffect(() => {
    if (!show) {
      if (unmountOnClose) {
        unmountContent();
      }

      return;
    }

    setContentMounted(true);
  }, [show, unmountContent, unmountOnClose]);

  const slideoutClassNames = cx(
    {
      'translate-x-0': isTransformed,
      'translate-x-full slideout-closed': !show,
      'slideout-opened': show
    },
    'fixed right-0 bottom-0 overflow-hidden bg-white',
    'border border-grey-lighter shadow-fixed-right transition-all transform duration-500',
    className,
    SLIDEOUT_ZINDEX
  );

  const overlayClassNames: string = cx(
    {
      'bg-white opacity-80': overlay,
      'opacity-0': !overlay
    },
    'fixed top-0 right-0 left-0 bottom-0',
    SLIDEOUT_OVERLAY_ZINDEX
  );

  const renderContentComponent = () => (
    <div
      className={slideoutClassNames}
      data-testid="slideout"
      style={{ top: `${HEADER_HEIGHT_IN_REM}rem` }}
      {...createTrackingParentAttribute('slideout')}
    >
      {contentMounted && children}
    </div>
  );

  if (disableOverlay) {
    return renderContentComponent();
  }

  return (
    <>
      {show && <div className={overlayClassNames} onClick={handleOutsideClick} />}
      {renderContentComponent()}
    </>
  );
};

export default Slideout;
