import type { NextLink, Operation } from '@apollo/client';
import { ApolloClient, ApolloLink, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { getMainDefinition } from '@apollo/client/utilities';
import { createConsumer } from '@rails/actioncable';
import type { GraphQLError, OperationDefinitionNode } from 'graphql';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import omitDeep from 'omit-deep-lodash';
import type { ReactNode } from 'react';

import authStore from '@zen/Auth/authStore';
import { trackEvent } from '@zen/Components/TrackingProvider';
import { ErrorsTrackingAction, ErrorsTrackingCategory } from '@zen/types';
import applicationVersion from '@zen/utils/applicationVersion';
import csrfToken from '@zen/utils/csrfToken';
import staticConfig from '@zen/utils/staticConfig';

import { cache } from './cache';

const setUriLink = setContext(() => {
  return { uri: staticConfig.unproxiedGraphqlUrl };
});

const hasSubscriptionOperation = ({ query: { definitions } }: Operation): boolean => {
  return definitions.some(
    ({ kind, operation }: { kind: string; operation?: string }) => kind === 'OperationDefinition' && operation === 'subscription'
  );
};

const getTransportLinks = (token?: string) => {
  const cable = createConsumer(`${staticConfig.subscriptionsUrl}${token ? `?token=${token}` : ''}`);

  const wsLink = new ActionCableLink({
    cable
  });

  return ApolloLink.split(hasSubscriptionOperation, wsLink, new HttpLink());
};

// we omit `__typename` fields before executing mutations
// it's useful especially when we fetch and pass data directly into a form
// so there is no need to manually omit __typename fields before executing mutation
const cleanTypenameLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  const keysToOmit = ['__typename'];

  const def = getMainDefinition(operation.query) as OperationDefinitionNode;

  if (def && def.operation === 'mutation') {
    operation.variables = omitDeep(operation.variables, keysToOmit);
  }

  return forward ? forward(operation) : null;
});

const authenticationLink = new ApolloLink((operation, forward) => {
  const { getAccessToken } = authStore();
  const token = getAccessToken();

  operation.setContext({
    headers: {
      'X-CSRF-Token': csrfToken.get(),
      ...(token ? { Authorization: `Bearer ${token}` } : {})
    }
  });

  return forward(operation);
});

const networkErrorLink = onError(({ networkError }) => {
  if (networkError && 'statusCode' in networkError) {
    switch (networkError.statusCode) {
      case 503:
        window.location.reload();
        break;
      case 401:
        window.localStorage.clear();
        window.location.reload();
        break;
    }
  }
});

const errorTrackingLink = onError(({ graphQLErrors, operation }) => {
  const definition = getMainDefinition(operation.query) as OperationDefinitionNode;
  const operationType = definition?.operation;
  const { operationName } = operation;

  const error: string = JSON.stringify(
    graphQLErrors?.map((graphqlError: GraphQLError) => {
      return graphqlError.message;
    })
  );

  trackEvent({
    category: ErrorsTrackingCategory,
    action: operationType === 'mutation' ? ErrorsTrackingAction.GRAPHQL_MUTATION_ERROR : ErrorsTrackingAction.GRAPHQL_QUERY_ERROR,
    label: 'GraphqlProvider',
    properties: {
      operationName,
      operationType,
      error
    }
  });
});

const getLinks = (token?: string) =>
  ApolloLink.from([
    setUriLink,
    cleanTypenameLink,
    authenticationLink,
    networkErrorLink,
    errorTrackingLink,
    getTransportLinks(token)
  ]);

export const apolloClient = new ApolloClient({
  connectToDevTools: true,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    }
  },
  link: getLinks(),
  name: window.location.hostname,
  version: applicationVersion,
  cache
});

interface GraphQLProviderProps {
  children: ReactNode;
}

const GraphQLProvider = ({ children }: GraphQLProviderProps) => <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;

const updateApolloClientLinksWithNewToken = (({ detail: token }: CustomEvent<string>) => {
  if (token) {
    apolloClient.setLink(getLinks(token));
  }
}) as EventListener;

window.addEventListener('tokenset', updateApolloClientLinksWithNewToken);

export default GraphQLProvider;
