import { useCurrentEditor } from '@tiptap/react';
import cx from 'classnames';
import { type FC, type ReactNode, useRef } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  className?: string;
}

interface ToolbarItem {
  iconClassName?: string;
  iconName: IconName;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

export const EditorToolbar: FC<Props> = ({ className }) => {
  const toolbarClassNames: string = cx('bg-grey-lightest flex items-center flex-wrap text-base py-1 pl-5 pr-3', className);

  const { editor } = useCurrentEditor();
  const fileInputRef = useRef<HTMLInputElement>(null);

  if (!editor) {
    return null;
  }

  const renderToolbarButton = (item: ToolbarItem): ReactNode => {
    const buttonClassName: string = cx(
      'h-8 w-10 py-1 px-2 hover:text-azure-base',
      {
        'text-black-light': !item.iconClassName
      },
      {
        'text-azure-base': item.isActive
      },
      item.iconClassName
    );

    return (
      <button key={item.iconName} className={buttonClassName} disabled={item.isDisabled} onClick={item.onClick} type="button">
        <Icon icon={item.iconName} />
      </button>
    );
  };

  const handleFileChange = (): void => {
    const file = fileInputRef.current?.files?.[0];

    if (!file) {
      return;
    }
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const dataUrl = fileReader.result as string;

      editor.chain().focus().setImage({ src: dataUrl }).run();
    };

    fileReader.readAsDataURL(file);
  };

  const handleAddImage = () => {
    fileInputRef.current?.click();
  };

  const toolbarItems: ToolbarItem[] = [
    {
      iconName: 'zicon-bold',
      onClick: () => editor.chain().focus().toggleBold().run(),
      isActive: editor.isActive('bold'),
      isDisabled: !editor.can().chain().focus().toggleBold().run()
    },
    {
      iconName: 'zicon-italic',
      onClick: () => editor.chain().focus().toggleItalic().run(),
      isActive: editor.isActive('italic'),
      isDisabled: !editor.can().chain().focus().toggleItalic().run()
    },
    {
      iconName: 'zicon-strike-through',
      onClick: () => editor.chain().focus().toggleStrike().run(),
      isActive: editor.isActive('strike'),
      isDisabled: !editor.can().chain().focus().toggleStrike().run()
    },
    {
      iconName: 'zicon-list',
      onClick: () => editor.chain().focus().toggleBulletList().run(),
      isActive: editor.isActive('bulletList')
    },
    { iconName: 'zicon-attach', onClick: handleAddImage },
    {
      iconName: 'zicon-undo',
      onClick: () => editor.chain().focus().undo().run(),
      isDisabled: !editor.can().undo()
    },
    {
      iconName: 'zicon-redo',
      onClick: () => editor.chain().focus().redo().run(),
      isDisabled: !editor.can().redo()
    }
  ];

  return (
    <div className={toolbarClassNames} data-testid="editor-toolbar">
      <div className="flex items-center gap-1 py-2 flex-nowrap">{toolbarItems.map(renderToolbarButton)}</div>
      <input ref={fileInputRef} accept="image/*" hidden={true} onChange={handleFileChange} type="file" />
    </div>
  );
};

export default EditorToolbar;
