import { omit } from 'lodash';

import { defaultMapLatitude, defaultMapLongitude } from '@zen/Components/Map';
import type { AddressInput } from '@zen/graphql/types.generated';
import { OrganizationalRoleEnum } from '@zen/graphql/types.generated';
import { CountryCode } from '@zen/Networks';
import type { Nullable } from '@zen/utils/typescript';

import type { CreateAccountMutationVariables } from './graphql';
import type { AccountFormData, Address, DayOfWeek, WorkingHours } from './types';

const getDefaultWorkingHours = (): WorkingHours => {
  return {
    startTime: '08:00',
    endTime: '17:00'
  };
};

const getDefaultLatLng = () => {
  return { latitude: defaultMapLatitude, longitude: defaultMapLongitude };
};

const daysOfWeek: DayOfWeek[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const createMockAddress = (prefix: string = ''): AddressInput => {
  return {
    city: `${prefix}city`,
    country: CountryCode.GB,
    countyOrState: `${prefix}county`,
    line1: `${prefix}street`,
    line2: `${prefix}other`,
    postalCodeOrZip: `${prefix}zip`
  };
};

const getDefaultWorkDays = (): Record<DayOfWeek, Nullable<WorkingHours>> => {
  return {
    monday: { ...getDefaultWorkingHours() },
    tuesday: { ...getDefaultWorkingHours() },
    wednesday: { ...getDefaultWorkingHours() },
    thursday: { ...getDefaultWorkingHours() },
    friday: { ...getDefaultWorkingHours() },
    saturday: null,
    sunday: null
  };
};

const prepareAddressForSubmission = (address: Address): AddressInput => {
  return {
    ...address,
    country: address.country?.code,
    line1: address.line1 || ''
  } as AddressInput;
};

const addressValues: Address = {
  city: '',
  country: undefined,
  countyOrState: '',
  line1: '',
  line2: '',
  postalCodeOrZip: ''
};

const initialFormValues = {
  accountDetails: {
    tradingName: '',
    referencePrefix: 'Z'
  },
  legalInformation: {
    registeredName: '',
    vatIdentificationNumber: '',
    eoriNumber: '',
    companyRegistrationNumber: ''
  },
  registeredAddress: prepareAddressForSubmission(addressValues),
  shippingLocationAddress: prepareAddressForSubmission(addressValues),
  shippingLocation: {
    businessHoursOption: 'standard' as const,
    companyName: '',
    geolocation: { isPinPlacementConfirmed: false, ...getDefaultLatLng() },
    name: '',
    usageContext: null
  },
  businessHours: getDefaultWorkDays(),
  demoMode: false
};

const prepareFormValues = (values: AccountFormData): CreateAccountMutationVariables['input'] => {
  const { accountDetails, legalInformation, shippingLocation, shippingLocationAddress, businessHours, demoMode } = values;
  const { businessHoursOption, geolocation, ...shippingLocationRest } = shippingLocation;
  const isStandardOpeningHours: boolean = businessHoursOption === 'standard';

  return {
    demoMode,
    legalInformation: {
      ...legalInformation
    },
    organizationalRole: OrganizationalRoleEnum.CONSIGNMENT_PARTY,
    referencePrefix: accountDetails.referencePrefix,
    shippingLocation: {
      ...shippingLocationRest,
      companyName: accountDetails.tradingName,
      geolocation: omit(geolocation, 'isPinPlacementConfirmed') || getDefaultLatLng(),
      businessHours: isStandardOpeningHours ? getDefaultWorkDays() : businessHours,
      address: {
        ...shippingLocationAddress
      }
    },
    tradingName: accountDetails.tradingName
  };
};

export {
  createMockAddress,
  daysOfWeek,
  getDefaultLatLng,
  getDefaultWorkDays,
  getDefaultWorkingHours,
  initialFormValues,
  prepareAddressForSubmission,
  prepareFormValues
};
