import useAuth from './useAuth';

const useSwitchAccount = () => {
  const { loginWithAccountId, clearSensitiveData } = useAuth();

  const switchToAccount = (accountId: string): void => {
    loginWithAccountId(accountId);
    clearSensitiveData();
  };

  return { switchToAccount };
};

export default useSwitchAccount;
