import { useCallback, useMemo } from 'react';

import { useAccountPermissionsContext } from './AccountPermissionsContext';
import { checkPermission } from './authHelper';

const useAccountPermissions = (): { check: (key: string) => boolean } => {
  // Temp solutions, globalPermission type should come from the autogenerated types from graphql schema
  const accountPermissions: any = useAccountPermissionsContext();
  const check = useCallback(
    (key: string): boolean => {
      return checkPermission<any>(accountPermissions, key);
    },
    [accountPermissions]
  );

  return useMemo(() => ({ check, accountPermissions }), [accountPermissions, check]);
};

export default useAccountPermissions;
