import type { FC, ReactNode } from 'react';

import type { FormFieldProps } from '@zen/Components/Form/FormField';
import FormTerminalsSelect from '@zen/Components/Form/FormTerminalsSelect';
import { NetworksContactFormInput } from '@zen/Networks';
import useAccount from '@zen/utils/hooks/useAccount';

import { CostTrackingLocationType } from './types';

interface Props extends FormFieldProps {
  locationType?: CostTrackingLocationType;
  renderMenuInPortal?: boolean;
}

const FormCostTrackingLocationSelect: FC<Props> = (props) => {
  const { label, locationType = CostTrackingLocationType.TERMINAL, name, renderMenuInPortal = true } = props;
  const { accountUuid: accountId } = useAccount();

  const terminalSelect: ReactNode = <FormTerminalsSelect label={label} name={name} renderMenuInPortal={true} />;
  const locationsSelect: ReactNode = (
    <NetworksContactFormInput
      accountUuid={accountId}
      entityType="location"
      label={label}
      name={name}
      renderMenuInPortal={renderMenuInPortal}
      showAddButton={false}
    />
  );

  return (
    <div className="w-full" data-testid="cost-tracking-location-select">
      {locationType === CostTrackingLocationType.TERMINAL ? terminalSelect : locationsSelect}
    </div>
  );
};

export type { Props as FormCostTrackingLocationSelectProps };

export default FormCostTrackingLocationSelect;
