import { uniqueId } from 'lodash';

import { prepareLocation } from '@zen/OperationsSettings';
import { getEmptyChargeItem } from '@zen/RateCards/RateCardForm/components/RateCardChargeFormItems';
import { Applicability, type ChargeItemFormValues } from '@zen/RateCards/RateCardForm/types';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { FreightApplicabilityLanes, FreightChargeFormInitialValues, FreightChargeFormValues } from './types';

export const freightApplicabilityOptions: FreightApplicabilityLanes[] = [Applicability.ORIGIN, Applicability.DESTINATION];

export const initialValues: FreightChargeFormInitialValues = {
  [Applicability.DESTINATION]: false,
  [Applicability.ORIGIN]: false,
  chargeItems: [getEmptyChargeItem()],
  fromLocation: null,
  toLocation: null
};

export const preparePayload = (values: FreightChargeFormValues): RateCardCharge[] => {
  const applicability: FreightApplicabilityLanes[] = [];
  const { fromLocation, toLocation } = values;

  freightApplicabilityOptions.forEach((applicabilityItem: FreightApplicabilityLanes) => {
    if (values[applicabilityItem]) {
      applicability.push(applicabilityItem);
    }
  });

  return values.chargeItems.map((chargeItem: ChargeItemFormValues) => {
    const { chargeBasis, cargoOptions, chargeName, chargeType, unitPrice } = chargeItem;

    return {
      applicability,
      chargeBasis,
      cargoOptions,
      chargeTypeId: chargeType?.id,
      chargeType,
      currency: unitPrice.currency,
      fromLocation: prepareLocation(fromLocation),
      id: uniqueId(),
      itemName: chargeName,
      toLocation: prepareLocation(toLocation),
      unitPrice: unitPrice.value
    };
  });
};
