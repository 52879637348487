import './tiptap.css';

import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Image from '@tiptap/extension-image';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';
import type { Editor, EditorOptions } from '@tiptap/react';
import { EditorProvider } from '@tiptap/react';
import type { FC } from 'react';

import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import type { FormInstance } from '../types';
import EditorToolbar from './RichTextEditor/EditorToolbar';

interface Field {
  name: string;
  value: string;
}

interface Props extends FormFieldProps {
  className?: string;
  placeholder?: string;
  toolbarClassName?: string;
}

const FormRichText: FC<Props> = (props) => {
  const { toolbarClassName, placeholder, className = 'h-48' } = props;

  const extensions = [
    // required
    Document,
    Text,
    Paragraph,
    // styles
    Bold,
    Italic,
    Strike,
    BulletList.configure({
      HTMLAttributes: {
        class: 'list-disc ml-4'
      }
    }),
    ListItem,
    // nodes
    Image.configure({
      allowBase64: true,
      inline: true
    }),
    // functional
    Placeholder.configure({
      placeholder
    }),
    // undo/redo
    History
  ];

  return (
    <FormField {...props} noMargin={!!toolbarClassName}>
      {(field: Field, form: FormInstance<unknown>) => {
        const handleUpdate = ({ editor }: { editor: Editor }): void => {
          form.setFieldValue(field.name, editor.getHTML());
        };

        const editorProps: EditorOptions['editorProps'] = {
          attributes: {
            class: 'py-4 px-6 h-full outline-none overflow-auto',
            'data-name': field.name
          }
        };

        return (
          <div className="[&_strong]:font-bold [&_em]:italic [&_a]:underline" data-testid="form-rich-text">
            <EditorProvider
              content={field.value || ''}
              editorContainerProps={{
                className
              }}
              editorProps={editorProps}
              extensions={extensions}
              injectCSS={false}
              onUpdate={handleUpdate}
            >
              <EditorToolbar className={toolbarClassName} />
            </EditorProvider>
          </div>
        );
      }}
    </FormField>
  );
};

export default FormRichText;
