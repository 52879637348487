import type { TableColumn } from '@zen/DesignSystem';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import {
  chargeTypeColumn,
  getActionsColumn,
  getCargoOptionsColumn,
  getChargeBasisColumn,
  getChargeNameColumn,
  getCurrencyColumn,
  getLocationColumn,
  getUnitPriceColumn
} from '../../components/helpers';
import type { ChargeTableAction, LocationType } from '../../components/types';
import { locationsConfig } from '../HaulageChargeForm/helpers';

export const getHaulageChargeTableColumns = (
  actions: ChargeTableAction,
  isEditable: boolean = false,
  type: LocationType
): TableColumn<RateCardCharge>[] => {
  const { onUpdate } = actions;
  const isOrigin: boolean = type === 'origin';
  const { portLabel, warehouseLabel } = locationsConfig[type];
  const fromLocationLabel: string = isOrigin ? warehouseLabel : portLabel;
  const toLocationLabel: string = isOrigin ? portLabel : warehouseLabel;

  return [
    getLocationColumn('fromLocation', fromLocationLabel),
    getLocationColumn('toLocation', toLocationLabel, { width: 180 }),
    ...(isEditable ? [chargeTypeColumn] : []),
    getChargeNameColumn(isEditable, onUpdate),
    getChargeBasisColumn(isEditable, onUpdate),
    getCargoOptionsColumn(isEditable, onUpdate),
    getUnitPriceColumn(isEditable, onUpdate),
    getCurrencyColumn(isEditable, onUpdate),
    ...(isEditable ? [getActionsColumn(actions)] : [])
  ];
};
