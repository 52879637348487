import type { FC, ReactNode, ReactText } from 'react';

import type { DateFormat } from '@zen/utils/dateTime';
import { formatDate } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

import ValueWithDefault from '../ValueWithDefault';

interface Props {
  date: Optional<string>;
  defaultValue?: ReactNode;
  formatDateType?: DateFormat;
}

const DateWithDefault: FC<Props> = ({ date, defaultValue, formatDateType = 'MMM_D_YYYY' }) => {
  return (
    <ValueWithDefault
      defaultValue={defaultValue}
      formatValue={(value: ReactText) => formatDate(value as string, formatDateType)}
      value={date}
    />
  );
};

export default DateWithDefault;
