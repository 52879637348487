import type { SyntheticEvent } from 'react';
import type React from 'react';

export type { FormArrayHelpers } from './FormArray';
export type { Error, ServerError } from '@zen/types';

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * An object containing error messages whose keys correspond to FormikValues.
 * Should always be an object of strings, but any is allowed to support i18n libraries.
 */

type FormErrors<Values> = {
  [K in keyof Values]?: Values[K] extends any[]
    ? // [number] is the special sauce to get the type of array's element.
      // More here https://github.com/Microsoft/TypeScript/pull/21316
      Values[K][number] extends object
      ? FormErrors<Values[K][number]>[] | string | string[]
      : string | string[]
    : Values[K] extends object
      ? FormErrors<Values[K]>
      : string;
};

/**
 * An object containing touched state of the form whose keys correspond to FormikValues.
 */
type FormTouched<Values> = {
  [K in keyof Values]?: Values[K] extends any[]
    ? // [number] is the special sauce to get the type of array's element.
      // More here https://github.com/Microsoft/TypeScript/pull/21316
      Values[K][number] extends object
      ? FormTouched<Values[K][number]>[]
      : boolean
    : Values[K] extends object
      ? FormTouched<Values[K]>
      : boolean;
};

interface FormActions<Values> {
  resetForm: (formValues?: { values: Values }) => void;
}

interface FormInstance<Values> extends FormActions<Values> {
  dirty: boolean;
  errors: FormErrors<Values>;
  handleBlur: (event: SyntheticEvent) => void;
  initialValues: Values;
  isSubmitting: boolean;
  isValid: boolean;
  isValidating: boolean;
  setFieldError: (field: string, message: string) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setTouched: (touched: FormTouched<Values>, shouldValidate?: boolean) => void;
  setValues: (values: Values) => void;
  status?: { formName?: string };
  submitForm(): Promise<void>;
  touched: FormTouched<Values>;
  validateForm(): Promise<FormErrors<Values>>;
  values: Values;
}

interface FormFieldInputProps<Value> {
  checked?: boolean;
  error: boolean;
  label?: string;
  multiple?: boolean;
  name: string;
  onBlur: (event: React.FocusEvent) => void;
  onChange: (event: React.ChangeEvent) => void;
  value: Value;
}

export type { FormActions, FormErrors, FormFieldInputProps, FormInstance, FormTouched };

export const FormTrackingCategory = 'FormTracking';

export enum FormTrackingAction {
  SERVER_ERROR = 'ServerError',
  TIME_TO_COMPLETE = 'TimeToComplete',
  VALIDATION_ERROR = 'ValidationError'
}
