import type { FC } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import FormField from '@zen/Components/Form/FormField';

import CargoVariantTiles from '../../components/CargoVariantTiles';
import type { CargoItemDetailsType, CargoModeEnum } from '../../types';

interface Props extends FormFieldProps {
  cargoMode: CargoModeEnum;
  hasError?: boolean;
  isDisabled?: boolean;
  onChange?: (cargoVariant: CargoItemDetailsType) => void;
}

const FormCargoVariantTiles: FC<Props> = (props) => {
  return (
    <FormField hideLabel={true} {...props}>
      {(field: { error: boolean; name: string; value: string }, form: FormInstance<{}>) => {
        const { isDisabled, hasError, onChange, cargoMode } = props;

        return (
          <CargoVariantTiles
            cargoMode={cargoMode}
            hasError={field.error || !!hasError}
            isDisabled={isDisabled}
            onChange={(selectedCargoType: CargoItemDetailsType): void => {
              form.setFieldValue(field.name, selectedCargoType);

              onChange?.(selectedCargoType);
            }}
            value={field.value}
          />
        );
      }}
    </FormField>
  );
};

export default FormCargoVariantTiles;
