import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ActivityAddedSubscriptionVariables = GraphQLTypes.Exact<{
  targetType: GraphQLTypes.ActivityFeedTargetTypeEnum;
  targetId: GraphQLTypes.Scalars['String'];
}>;

export type ActivityAddedSubscriptionResult = { __typename?: 'Subscription' } & {
  activityAdded: { __typename?: 'Activity' } & Pick<
    GraphQLTypes.Activity,
    'id' | 'createdAt' | 'itemId' | 'itemType' | 'targetId' | 'targetType'
  > & {
      legacyUser?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksContact' } & Pick<
          GraphQLTypes.NetworksContact,
          'firstName' | 'lastName' | 'avatarUrl' | 'email' | 'assignedRoles'
        > & {
            organisation?: GraphQLTypes.Maybe<
              | ({ __typename?: 'AgentOrganisation' } & Pick<GraphQLTypes.AgentOrganisation, 'name'>)
              | ({ __typename?: 'ForwarderOrganisation' } & Pick<GraphQLTypes.ForwarderOrganisation, 'name'>)
              | ({ __typename?: 'MyOrganisation' } & Pick<GraphQLTypes.MyOrganisation, 'name'>)
              | ({ __typename?: 'SavedOrganisation' } & Pick<GraphQLTypes.SavedOrganisation, 'name'>)
            >;
          }
      >;
      userDetails?: GraphQLTypes.Maybe<
        { __typename?: 'ActivityUserDetails' } & Pick<
          GraphQLTypes.ActivityUserDetails,
          'firstName' | 'lastName' | 'email' | 'organisationName'
        >
      >;
      permissions: { __typename?: 'Permissions' } & Pick<
        GraphQLTypes.Permissions,
        'agentForwarder' | 'customerUser' | 'manufacturer'
      >;
      metadata?: GraphQLTypes.Maybe<
        | ({ __typename?: 'BookingCollectionMetadataType' } & Pick<
            GraphQLTypes.BookingCollectionMetadataType,
            'collectedOn' | 'reference' | 'locationLabel' | 'scheduledAt'
          >)
        | ({ __typename?: 'BookingDeliveryMetadataType' } & Pick<
            GraphQLTypes.BookingDeliveryMetadataType,
            'deliveredOn' | 'reference' | 'locationLabel' | 'scheduledAt'
          >)
        | ({ __typename?: 'CargoMetadataContainerType' } & Pick<
            GraphQLTypes.CargoMetadataContainerType,
            'containerNumber' | 'containerType' | 'cargoType'
          >)
        | ({ __typename?: 'CargoMetadataLooseCargoType' } & Pick<
            GraphQLTypes.CargoMetadataLooseCargoType,
            'palletType' | 'quantity' | 'looseCargoType' | 'cargoType'
          >)
        | ({ __typename?: 'CargoMetadataVehicleType' } & Pick<
            GraphQLTypes.CargoMetadataVehicleType,
            'vehicleType' | 'quantity' | 'looseCargoType' | 'cargoType'
          >)
        | ({ __typename?: 'IssueActivityMetadataType' } & Pick<
            GraphQLTypes.IssueActivityMetadataType,
            'category' | 'problemDescription' | 'severity' | 'state' | 'title'
          > & {
              openedBy: { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>;
              resolvedBy?: GraphQLTypes.Maybe<
                { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
              >;
              startedBy?: GraphQLTypes.Maybe<
                { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
              >;
            })
        | ({ __typename?: 'PurchaseOrderCargoReadyDatesMetadataType' } & Pick<
            GraphQLTypes.PurchaseOrderCargoReadyDatesMetadataType,
            'additionalInformation' | 'confidenceLevel' | 'cargoReadyDate' | 'lotIds' | 'reasonCategory'
          >)
        | ({ __typename?: 'PurchaseOrderClosedMetadataType' } & Pick<GraphQLTypes.PurchaseOrderClosedMetadataType, 'manually'>)
        | ({ __typename?: 'PurchaseOrderDeliveryEstimatesMetadataType' } & Pick<
            GraphQLTypes.PurchaseOrderDeliveryEstimatesMetadataType,
            'additionalInformation' | 'confidenceLevel' | 'deliveryDate' | 'lotIds'
          >)
        | ({ __typename?: 'ShipmentEtaMetadataType' } & Pick<GraphQLTypes.ShipmentEtaMetadataType, 'reason' | 'description'> & {
              newEstimate?: GraphQLTypes.Maybe<
                { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
              >;
              previousEstimate?: GraphQLTypes.Maybe<
                { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date'>
              >;
            })
      >;
    };
};

export const ActivityAddedDocument = /* #__PURE__ */ gql`
  subscription activityAdded($targetType: ActivityFeedTargetTypeEnum!, $targetId: String!) {
    activityAdded(targetType: $targetType, targetId: $targetId) {
      id
      createdAt
      legacyUser {
        firstName
        lastName
        avatarUrl
        email
        assignedRoles
        organisation {
          name
        }
      }
      userDetails {
        firstName
        lastName
        email
        organisationName
      }
      itemId
      itemType
      targetId
      targetType
      permissions {
        agentForwarder
        customerUser
        manufacturer
      }
      metadata {
        ... on BookingCollectionMetadataType {
          collectedOn
          reference
          locationLabel
          scheduledAt
        }
        ... on BookingDeliveryMetadataType {
          deliveredOn
          reference
          locationLabel
          scheduledAt
        }
        ... on IssueActivityMetadataType {
          category
          openedBy {
            firstName
            lastName
          }
          problemDescription
          resolvedBy {
            firstName
            lastName
          }
          severity
          startedBy {
            firstName
            lastName
          }
          state
          title
        }
        ... on CargoMetadataContainerType {
          containerNumber
          containerType
          cargoType
        }
        ... on CargoMetadataVehicleType {
          vehicleType
          quantity
          looseCargoType
          cargoType
        }
        ... on CargoMetadataLooseCargoType {
          palletType
          quantity
          looseCargoType
          cargoType
        }
        ... on PurchaseOrderDeliveryEstimatesMetadataType {
          additionalInformation
          confidenceLevel
          deliveryDate
          lotIds
        }
        ... on PurchaseOrderCargoReadyDatesMetadataType {
          additionalInformation
          confidenceLevel
          cargoReadyDate
          lotIds
          reasonCategory
        }
        ... on PurchaseOrderClosedMetadataType {
          manually
        }
        ... on ShipmentEtaMetadataType {
          newEstimate {
            date
            timeZone
          }
          previousEstimate {
            date
          }
          reason
          description
        }
      }
    }
  }
`;

/**
 * __useActivityAddedSubscription__
 *
 * To run a query within a React component, call `useActivityAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActivityAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityAddedSubscription({
 *   variables: {
 *      targetType: // value for 'targetType'
 *      targetId: // value for 'targetId'
 *   },
 * });
 */
export function useActivityAddedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<ActivityAddedSubscriptionResult, ActivityAddedSubscriptionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSubscription<ActivityAddedSubscriptionResult, ActivityAddedSubscriptionVariables>(
    ActivityAddedDocument,
    options
  );
}
export type ActivityAddedSubscriptionHookResult = ReturnType<typeof useActivityAddedSubscription>;
