import type { FC } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import { Form, FormButtons, FormSegmentedControl } from '@zen/Components/Form';
import FormDatePicker from '@zen/Components/Form/FormDatePicker';
import FormPurchaseOrderDelayReasonSelect from '@zen/Components/Form/FormPurchaseOrderDelayReasonSelect';
import FormTextarea from '@zen/Components/Form/FormTextarea';
import type { SegmentedControlOption } from '@zen/DesignSystem';
import type { CargoReadyDateMutationResponse } from '@zen/Orders/CargoReadyDateSlideout';
import { getToday } from '@zen/utils/date';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Optional } from '@zen/utils/typescript';

import { ConfidenceLevel } from '../../types';
import DatesIndicator from '../DatesIndicator';
import { validationSchema } from './cargoReadyDateForm.validation';
import type { CargoReadyDateFormInitialValues, RichDateInput } from './types';

const initialValues: CargoReadyDateFormInitialValues = {
  date: null,
  reasonForChange: {
    reasonId: null,
    reasonDescription: ''
  },
  confidenceLevel: ConfidenceLevel.ESTIMATED
};

const options: SegmentedControlOption<ConfidenceLevel>[] = [
  { label: 'Estimate', value: ConfidenceLevel.ESTIMATED },
  { label: 'Actual', value: ConfidenceLevel.ACTUAL }
];

interface Props {
  cargoReadyDate?: string;
  onSubmit: (values: RichDateInput) => Promise<IOkOrErrorResult>;
  onSuccess?: (values: CargoReadyDateMutationResponse) => void;
  orderDate: Optional<string>;
}

const CargoReadyDateForm: FC<Props> = (props) => {
  const { cargoReadyDate, onSubmit, onSuccess, orderDate } = props;

  const getMaxDate = ({ confidenceLevel }: RichDateInput): string | undefined => {
    if (confidenceLevel === ConfidenceLevel.ACTUAL) {
      return getToday();
    }

    return undefined;
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<RichDateInput>) => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Submit" />
  );

  return (
    <Form
      enableReinitialize={true}
      formButtons={renderFormButtons}
      formName="CargoReadyDateForm"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {({ values }) => {
        return (
          <>
            <FormSegmentedControl label="Estimate or actual date?" name="confidenceLevel" options={options} />
            {cargoReadyDate && <DatesIndicator newDate={values.date} previousDate={cargoReadyDate} />}
            <FormDatePicker disabled={{ minDate: orderDate, maxDate: getMaxDate(values) }} hideLabel={true} name="date" />
            {cargoReadyDate && (
              <FormPurchaseOrderDelayReasonSelect label="Select reason for delay" name="reasonForChange.reasonId" />
            )}
            <FormTextarea label="Add any additional information" name="reasonForChange.reasonDescription" textareaRows={7} />
          </>
        );
      }}
    </Form>
  );
};

export default CargoReadyDateForm;
