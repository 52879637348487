import cx from 'classnames';
import type { FC, Ref } from 'react';
import { useRef, useState } from 'react';

import AccountAvatar from '@zen/Components/AccountAvatar';
import ChevronIcon from '@zen/Components/Icons/ChevronIcon';
import useAccount from '@zen/utils/hooks/useAccount';
import useClickAway from '@zen/utils/hooks/useClickAway';

import AccountsMenu from '../AccountsMenu';

const AccountSwitcher: FC = () => {
  const { userProfile } = useAccount();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const ref: Ref<HTMLDivElement> = useRef(null);

  const { division, registeredCompanyName } = userProfile;

  useClickAway(ref, () => setIsExpanded(false));

  const classNames: string = cx(
    { 'bg-grey-lightest': isExpanded },
    'flex items-center border border-solid border-grey-lighter py-2.5 pl-4 pr-3',
    'cursor-pointer hover:bg-grey-lightest transition-all duration-200 ease-linear rounded'
  );

  const menuClassNames: string = cx(
    {
      'opacity-0 pointer-events-none': !isExpanded,
      'opacity-100 pointer-events-auto': isExpanded
    },
    'absolute top-18 left-0 right-0 bg-white shadow-popover px-3 pt-4 pb-2 transition-all duration-300 easy'
  );

  const accountLabel: string = division ? 'Division' : 'Account';

  return (
    <div ref={ref} className="relative px-3 pt-2 border-t border-solid border-grey-lighter">
      <div className={classNames} data-testid="current-account" onClick={() => setIsExpanded(!isExpanded)}>
        <AccountAvatar accountName={registeredCompanyName} className="mr-2.5" />
        <div className="flex-1 shrink-0 min-w-0 pr-2.5">
          <div className="text-grey-base text-xs mb-0.5 pb-px">{accountLabel}</div>
          <div className="text-navy-base text-sm truncate">{division ? division.name : registeredCompanyName}</div>
        </div>
        <ChevronIcon className="text-lg" expanded={isExpanded} size="small" />
      </div>
      <div className={menuClassNames} data-testid="accounts-menu">
        <AccountsMenu onAccountChange={() => setIsExpanded(false)} />
      </div>
    </div>
  );
};

export default AccountSwitcher;
