import cx from 'classnames';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import PageTitle from '@zen/Components/PageTitle';
import { HEADER_HEIGHT } from '@zen/Layout';
import useIsVisible from '@zen/utils/hooks/useIsVisible';
import { createTrackingParentAttribute } from '@zen/utils/tracking';
import type { Undefinable } from '@zen/utils/typescript';

import BackLink from '../BackLink';

export const STICKY_HEADER_HEIGHT = 64;

interface Props {
  actionButtons?: ReactNode;
  browserTitle?: string;
  contentClassName?: string;
  defaultBackUrl?: string;
  headerClassName?: string;
  stickyHeader?: ReactNode;
  tabsComponent?: ReactNode;
  tagline?: ReactNode;
  title: ReactNode;
  width?: 'full' | 'extra-wide' | 'wide' | 'medium' | 'narrow';
}

const Page: FC<PropsWithChildren<Props>> = (props) => {
  const [staticHeaderRef, isStaticHeaderVisible] = useIsVisible<HTMLDivElement>({
    rootMargin: `-${HEADER_HEIGHT}px 0px 0px 0px`
  });
  const {
    actionButtons,
    defaultBackUrl,
    browserTitle,
    children,
    contentClassName = 'pt-6 pb-10 px-10',
    headerClassName,
    stickyHeader,
    tabsComponent,
    tagline,
    title,
    width = 'full'
  } = props;

  const widthClassName = cx({
    'max-w-screen-3xl': width === 'extra-wide',
    'max-w-screen-xl': width === 'wide',
    'max-w-screen-lg': width === 'medium',
    'max-w-screen-md': width === 'narrow'
  });
  const headerClassNames = cx(
    {
      'pb-6': !tabsComponent
    },
    'relative border-b border-solid border-grey-lighter',
    headerClassName,
    widthClassName
  );

  const headerWrapperClassName: string = cx('px-10 pt-6', widthClassName);

  const helmetTitle: Undefinable<string> = typeof title === 'string' ? browserTitle || title : browserTitle;

  const stickyHeaderClassNames: string = cx(
    { 'opacity-0 pointer-events-none duration-75': isStaticHeaderVisible, 'opacity-100 duration-500': !isStaticHeaderVisible },
    'fixed left-0 right-0 top-16 ml-60 z-20 bg-white border-b border-solid border-grey-lighter transition-opacity ease-in-out'
  );

  return (
    <div className="flex flex-col h-full" data-testid="page" {...createTrackingParentAttribute('page')}>
      <Helmet title={helmetTitle} />
      <div ref={staticHeaderRef} className={headerWrapperClassName} data-testid="page-header">
        <div className={headerClassNames}>
          {defaultBackUrl && (
            <div className="inline-block -mt-2 -ml-1.5">
              <BackLink path={defaultBackUrl} />
            </div>
          )}
          <div className="flex justify-between">
            <PageTitle className="flex-1 min-w-0" tagline={tagline} title={title} />
            {actionButtons && <div className="flex items-start">{actionButtons}</div>}
          </div>
          {tabsComponent && <div className="mt-2 -mb-px">{tabsComponent}</div>}
        </div>
      </div>
      {stickyHeader && (
        <div className={stickyHeaderClassNames} data-testid="page-sticky-header">
          <div className={cx('px-10 py-3 flex flex-col justify-center', widthClassName)}>{stickyHeader}</div>
        </div>
      )}
      <div className={cx('flex flex-col h-full', contentClassName, widthClassName)} data-testid="page-content">
        {children}
      </div>
    </div>
  );
};

export default Page;
