import type { FC } from 'react';

import { FormInput, FormNumberInput, useForm } from '@zen/Components';
import type { Nullable } from '@zen/utils/typescript';

import type { CargoFullContainerVariant, CargoItemDetailsType, ContainerTypeEnum } from '../../../types';
import { CargoModeEnum } from '../../../types';
import CargoParams from '../../CargoParams';
import FormCargoVariantTiles from '../../FormCargoVariantTiles';
import FormContainerTypeSelect from '../../FormContainerTypeSelect';

interface Props {
  showContainerDetails?: boolean;
}

const FullContainerFormFields: FC<Props> = ({ showContainerDetails }) => {
  const {
    values: { containerVariant, type },
    setFieldValue
  } = useForm<{ containerVariant: Nullable<CargoFullContainerVariant>; type: ContainerTypeEnum }>();

  const handleVariantChange = (value: CargoItemDetailsType): void => {
    setFieldValue('type', null);
    setFieldValue('containerVariant', value);
  };

  return (
    <div data-testid="full-container-form">
      <div className="grid grid-cols-3 gap-4">
        <FormCargoVariantTiles
          cargoMode={CargoModeEnum.FCL}
          className="col-span-3"
          name="containerVariant"
          onChange={handleVariantChange}
        />
        <FormContainerTypeSelect
          containerVariant={containerVariant}
          isDisabled={!containerVariant}
          label="Container size"
          name="type"
        />
        {showContainerDetails && (
          <>
            <FormInput label="Container number" name="containerNumber" />
            <FormInput label="Seal number" name="containerSealNumber" />
          </>
        )}
        {!showContainerDetails && <FormNumberInput name="quantity" placeholder="eg. 10" step={1} />}
      </div>
      {!!type && <CargoParams cargoType={type} className="grid grid-cols-3 gap-4" variant={containerVariant} />}
    </div>
  );
};

export default FullContainerFormFields;
