import type { FC, ReactNode } from 'react';

import type { ModeOfTransport } from '@zen/Booking';
import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import CollapsibleElement from '@zen/Components/CollapsibleElement';
import { prepareBusinessHoursInitialValues } from '@zen/Networks/networksHelpers';
import type { Optional } from '@zen/utils/typescript';

import type { JourneyLeg, JourneyShippingMilestone, JourneyStopWithTransitInfo, TransitInfo } from '../../types';
import { JourneyStopActionEnum, JourneyStopLocationTypeEnum } from '../../types';
import CollectionNotHandledInformation from '../CollectionNotHandledInformation';
import CompletedMilestonesSwitcher from '../CompletedMilestonesSwitcher/CompletedMilestonesSwitcher';
import InTransitMilestone from '../InTransitMilestone';
import { useJourneyContext } from '../JourneyDetails/JourneyDetailsContext';
import JourneyStop from '../JourneyStop';
import Milestone from '../Milestone';
import VerticalPathProvider from '../VerticalPathProvider';
import {
  getCollectionLocationBusinessHours,
  getCollectionStopTimeZone,
  getLocationName,
  prepareStopReferences,
  prepareStops
} from './helpers';

interface Props {
  legs: JourneyLeg[];
  modeOfTransport: Optional<ModeOfTransport>;
  preLegsShippingMilestones: JourneyShippingMilestone[];
}

const JourneyLegs: FC<Props> = (props) => {
  const { legs, modeOfTransport, preLegsShippingMilestones } = props;
  const {
    completedMilestoneCount,
    isCompletedMilestonesSwitcherVisible,
    isLastCompletedMilestone,
    milestones,
    onCompletedMilestonesVisibilityChange,
    showCompletedMilestones
  } = useJourneyContext();
  const { isCollectionEnabled } = useCargoOverviewContext();
  const stops: JourneyStopWithTransitInfo[] = prepareStops(legs);
  const hasPreLegsShippingMilestones: boolean = preLegsShippingMilestones.length > 0;

  const renderInTransitMilestone = (transitInfo: TransitInfo, isVisible: boolean): ReactNode => {
    const { isCompleted, isInTransit, legType, transitTimeInMinutes, vehicleId, vehicleProperties } = transitInfo;

    return (
      <InTransitMilestone
        className="pt-4 pb-6"
        isCompleted={isCompleted}
        isInTransit={isInTransit}
        isVisible={isVisible}
        modeOfTransport={transitInfo.modeOfTransport}
        stopReferences={prepareStopReferences(stops, legType, modeOfTransport)}
        transitTimeInMinutes={transitTimeInMinutes}
        vehicleId={vehicleId}
        vehicleProperties={vehicleProperties}
      />
    );
  };

  const renderStop = (stop: JourneyStopWithTransitInfo, index: number): ReactNode => {
    const { outgoingTransitInfo, shippingMilestones, stopAction, locationType } = stop;

    const lastMilestone: JourneyShippingMilestone = shippingMilestones[shippingMilestones.length - 1];

    const allMilestonesCompleted: boolean = shippingMilestones.every(({ completed }) => completed);
    const isStopVisible: boolean = !(
      allMilestonesCompleted &&
      !isLastCompletedMilestone(lastMilestone?.id) &&
      !showCompletedMilestones
    );

    return (
      <div key={index}>
        <CollapsibleElement animateOpacity={false} duration={600} isOpened={isStopVisible}>
          <div className="pt-1.5">
            <JourneyStop
              canUpdateLocation={stop.canUpdateLocation}
              isStopVisible={isStopVisible}
              locationBusinessHours={prepareBusinessHoursInitialValues(stop.warehouse?.businessHours)}
              locationId={stop?.warehouseLocation?.id || stop?.warehouse?.id || stop.terminal?.unlocode || ''}
              locationName={getLocationName(stop)}
              locationType={locationType}
              milestones={shippingMilestones}
              stopAction={stopAction}
              stopId={stop.id}
              timeZone={stop?.warehouse?.timeZone || stop.terminal?.timeZone}
            />
          </div>
        </CollapsibleElement>
        {outgoingTransitInfo && (
          <CollapsibleElement animateOpacity={false} duration={600} isOpened={isStopVisible}>
            {renderInTransitMilestone(outgoingTransitInfo, isStopVisible)}
          </CollapsibleElement>
        )}
      </div>
    );
  };

  const renderMilestone = (milestone: JourneyShippingMilestone, index: number): ReactNode => {
    const { completed, name } = milestone;

    const isPreviousMilestoneCompleted: boolean = index ? preLegsShippingMilestones[index - 1]?.completed : false;
    const isCurrent: boolean = isPreviousMilestoneCompleted && !completed;

    return (
      <div key={name}>
        <Milestone
          isCurrent={isCurrent}
          isLast={false}
          locationBusinessHours={getCollectionLocationBusinessHours(stops)}
          locationType={JourneyStopLocationTypeEnum.WAREHOUSE}
          milestone={milestone}
          stopAction={JourneyStopActionEnum.COLLECTION}
          timeZone={getCollectionStopTimeZone(stops)}
        />
      </div>
    );
  };

  return (
    <VerticalPathProvider dependencies={[showCompletedMilestones, completedMilestoneCount, milestones.length, stops.length]}>
      {!isCollectionEnabled && <CollectionNotHandledInformation />}
      {isCompletedMilestonesSwitcherVisible && (
        <CompletedMilestonesSwitcher
          completedMilestoneCount={completedMilestoneCount}
          isVisible={showCompletedMilestones}
          legs={legs}
          modeOfTransport={modeOfTransport}
          onVisibilityChange={onCompletedMilestonesVisibilityChange}
        />
      )}
      {hasPreLegsShippingMilestones && (
        <div data-testid="pre-legs-shipping-milestones">{preLegsShippingMilestones.map(renderMilestone)}</div>
      )}
      <div className="mb-4">{stops.map(renderStop)}</div>
    </VerticalPathProvider>
  );
};

export default JourneyLegs;
