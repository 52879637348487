import type { FC } from 'react';

import type { ModeOfTransport } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useUpdateLooseCargoItemMutation } from '../../../graphql';
import {
  CargoItemTypeEnum,
  CargoManagementTrackingAction,
  CargoManagementTrackingCategory,
  type CoreCargo
} from '../../../types';
import { prepareLooseCargoInitialValues, prepareLooseCargoInput } from '../helpers';
import LooseCargoForm from '../LooseCargoForm/LooseCargoForm';
import type { LooseFormValues } from '../LooseFormFields/types';

interface Props {
  cargo: CoreCargo;
  modeOfTransport: ModeOfTransport;
  onCancel: () => void;
  onSuccess: () => void;
}

const EditLooseCargo: FC<Props> = ({ onSuccess, cargo, modeOfTransport, onCancel }) => {
  const [editLooseCargo] = useUpdateLooseCargoItemMutation();
  const { trackEvent } = useTracking();
  const { addSuccess } = useNotification();

  const handleSuccess = (): void => {
    addSuccess('Cargo successfully updated');
    onSuccess();

    trackEvent({
      category: CargoManagementTrackingCategory,
      action: CargoManagementTrackingAction.UPDATE_CARGO_ITEM,
      label: CargoItemTypeEnum.LOOSE_CARGO
    });
  };

  const handleSubmit = (values: LooseFormValues): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        editLooseCargo({
          variables: { input: { id: cargo.id, ...prepareLooseCargoInput(values) } },
          refetchQueries: [
            'bookingDetails',
            'bookingOverview',
            'bookingSummary',
            'cargoJourneysSummary',
            'cargoOverview',
            'cargoSummary'
          ],
          awaitRefetchQueries: true
        })
    });
  };

  return (
    <LooseCargoForm
      initialValues={prepareLooseCargoInitialValues(cargo)}
      modeOfTransport={modeOfTransport}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
    />
  );
};

export default EditLooseCargo;
