import useRole from '@zen/Auth/hooks/useRole';
import { Role } from '@zen/Networks';
import type { SortInput } from '@zen/types';
import type { PaginatedQueryResult } from '@zen/utils/hooks/pagination';
import { usePagination } from '@zen/utils/hooks/pagination';
import useAccount from '@zen/utils/hooks/useAccount';

import { prepareFilterVariables } from '../components/Filters/helpers';
import type { OrderTableQueryResult, OrderTableQueryVariables } from '../graphql';
import { useOrderTableQuery } from '../graphql';
import type { OrderFilters, OrderTableView } from '../types';

const useOrdersTableView = (
  order: SortInput,
  filters: OrderFilters = {}
): PaginatedQueryResult<OrderTableQueryResult, OrderTableQueryVariables, OrderTableView> => {
  const role = useRole();
  const { accountUuid: accountId, userProfile, divisions } = useAccount();
  const getFilter = () => {
    // WHEN the parent account is selected and we are not a admin OR we are an agent user.
    // THEN we should pass nothing to get everything that we are entitled to.
    if ((userProfile.isParent && role !== Role.ADMIN) || role === Role.AGENT_FORWARDER) {
      return {};
    }

    // WHEN the parent account is selected and we ARE an admin.
    // THEN we should pass the division ids of the parent only.
    if (userProfile.isParent && role === Role.ADMIN && divisions.length > 0) {
      return { accountIds: divisions.map((d) => d.id) };
    }

    return { accountIds: [accountId] };
  };

  const results = usePagination<OrderTableQueryResult, OrderTableQueryVariables, OrderTableView>(
    useOrderTableQuery,
    'purchaseOrders',
    {
      ...prepareFilterVariables(filters),
      ...getFilter(),
      order
    },
    20,
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  );

  return results;
};

export default useOrdersTableView;
