import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateRateCardMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateRateCardInput;
}>;

export type UpdateRateCardMutationResult = { __typename?: 'Mutation' } & {
  updateRateCard?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateRateCardPayload' } & {
      errors?: GraphQLTypes.Maybe<
        Array<GraphQLTypes.Maybe<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>
      >;
    }
  >;
};

export const UpdateRateCardDocument = /* #__PURE__ */ gql`
  mutation updateRateCard($input: UpdateRateCardInput!) {
    updateRateCard(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateRateCardMutation__
 *
 * To run a mutation, you first call `useUpdateRateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRateCardMutation, { data, loading, error }] = useUpdateRateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRateCardMutationResult, UpdateRateCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateRateCardMutationResult, UpdateRateCardMutationVariables>(UpdateRateCardDocument, options);
}
export type UpdateRateCardMutationHookResult = ReturnType<typeof useUpdateRateCardMutation>;
