import type { TestContext } from 'yup';
import { object, string } from 'yup';

export const createValidationSchema = (isDeliveryRequired: boolean) =>
  object({
    haulier: object({ id: string() })
      .nullable()
      .test({
        name: 'haulier test',
        message: 'Please select haulier.',
        test: (haulier) => {
          return !!haulier?.id;
        }
      }),
    collection: object({ date: string() }).test('collection test', 'Please enter collection date and time.', (collection) => {
      return !!collection?.date;
    }),
    delivery: object({ date: string() })
      .nullable()
      .test('delivery test', (delivery, context: TestContext) => {
        const collectionDate = context.parent.collection?.date;
        const deliveryDate = delivery?.date;

        if (isDeliveryRequired && !deliveryDate) {
          return context.createError({ message: 'Please enter delivery date and time.' });
        }

        if (collectionDate && deliveryDate && collectionDate > deliveryDate) {
          return context.createError({ message: 'Delivery date can not be earlier than collection date.' });
        }

        return true;
      })
  });
