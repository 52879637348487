import { type FC, useEffect, useMemo } from 'react';

import type { RateCardMultiSelectProps } from '@zen/RateCards/components/RateCardMultiSelect';
import RateCardMultiSelect from '@zen/RateCards/components/RateCardMultiSelect';
import { useRateCardContext } from '@zen/RateCards/RateCardContext';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useGetChargeBasisQuery } from './graphql';
import { getOptions } from './helpers';
import type { ChargeBasis, ChargeBasisGroup } from './types';

interface Props extends Omit<RateCardMultiSelectProps<string>, 'children' | 'onChange' | 'options'> {
  chargeBasisGroup: Optional<ChargeBasisGroup>;
  onChange: (values: ChargeBasis[]) => void;
  prefillWithAll?: boolean;
}

const ChargeBasisSelect: FC<Props> = (props) => {
  const { chargeBasisGroup, hasError, isDisabled, onBlur, onChange, prefillWithAll = false, value, variant } = props;
  const { cargoType, modeOfTransport } = useRateCardContext();

  const { data, loading } = useGetChargeBasisQuery({
    fetchPolicy: 'cache-and-network',
    skip: !chargeBasisGroup,
    variables: {
      cargoType,
      chargeBasisGroup: chargeBasisGroup as ChargeBasisGroup,
      modeOfTransport
    }
  });

  const chargeBasisList = useMemo(() => (data?.getChargeBasis || []) as ChargeBasis[], [data?.getChargeBasis]);

  useEffect(() => {
    if (chargeBasisList?.length === 1) {
      onChange([chargeBasisList[0]]);
    }

    if (prefillWithAll && !isDisabled) {
      onChange(chargeBasisList);
    }
  }, [chargeBasisList, chargeBasisGroup]);

  const handleChange = (values: Nullable<string[]>): void => {
    const items: ChargeBasis[] = chargeBasisList.filter((chargeBasisItem: ChargeBasis) => values?.includes(chargeBasisItem.id));

    onChange(items);
  };

  const disabled: boolean = chargeBasisList?.length === 1 || !!isDisabled;

  return (
    <RateCardMultiSelect
      hasError={hasError}
      isDisabled={disabled}
      loading={loading}
      onBlur={onBlur}
      onChange={handleChange}
      options={getOptions(chargeBasisList)}
      selectAllLabel="Per container"
      value={value || null}
      variant={variant}
    />
  );
};

export default ChargeBasisSelect;
