import type { FC } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { SIDEBAR_ZINDEX } from '@zen/Layout';

import AccountSwitcher from '../components/AccountSwitcher';
import { HEADER_HEIGHT_IN_REM } from '../constants';
import AdminLinks from './AdminLinks';
import CustomerAdminLinks from './CustomerAdminLinks';
import CustomerLinks from './CustomerLinks';
import useAdminSidebarCounters from './hooks/useAdminSidebarCounters';
import useCustomerSidebarCounters from './hooks/useCustomerSidebarCounters';

const Sidebar: FC = () => {
  const { check } = useGlobalPermissions();
  const { pathname } = useLocation();
  const { adminCounters, adminCountersRefetch } = useAdminSidebarCounters();
  const { customerCounters, customerCountersRefetch } = useCustomerSidebarCounters();
  const isCreateAccountFeatureIsOn = useFeatureIsOn('create-account');

  const canSwitchAccount: boolean = check('accounts.canSwitch');
  const canViewAdminNavigation: boolean = check('adminPanel.canView');
  const canViewCustomerAdminNavigation: boolean = check('customerAdmin.canAccessRoute');
  const shouldRenderCustomerAdminLinks = canViewCustomerAdminNavigation && !isCreateAccountFeatureIsOn;
  const classNames: string = `flex flex-col fixed w-60 ${SIDEBAR_ZINDEX} bg-white overflow-y-auto border-r border-solid border-grey-lighter`;
  const height: string = `calc(100vh - ${HEADER_HEIGHT_IN_REM}rem)`;

  const shouldRefetchCustomerCounters: boolean =
    pathname.includes('/dashboard/quotes') || pathname.includes('/dashboard/booking-requests');

  const shouldRefetchAdminCounters: boolean =
    pathname.includes('/dashboard/ops-shipments') || pathname.includes('/dashboard/task-list/active');

  useEffect(() => {
    if (shouldRefetchCustomerCounters) {
      customerCountersRefetch();
    }
    if (shouldRefetchAdminCounters) {
      setTimeout(() => {
        adminCountersRefetch();
      }, 3000);
    }
  }, [adminCountersRefetch, shouldRefetchAdminCounters, customerCountersRefetch, shouldRefetchCustomerCounters]);

  return (
    <div className="w-60" style={{ height }}>
      <div className={classNames} style={{ height }}>
        {shouldRenderCustomerAdminLinks && <CustomerAdminLinks />}
        {canViewAdminNavigation && <AdminLinks counters={adminCounters} />}
        {canSwitchAccount && <AccountSwitcher />}
        <CustomerLinks counters={customerCounters} />
      </div>
    </div>
  );
};

export default Sidebar;
