import type { ReactNode } from 'react';

import type { CargoItemDetailsType } from '@zen/Cargo';
import type { SVGComponent } from '@zen/types';
import { cargoTypeLabelMapping, ContainerTypeEnum, VehicleTypeEnum } from '@zen/types';
import type { Optional } from '@zen/utils/typescript';

import type { CargoType, CoreCargoType } from '../../types';
import {
  CargoFullContainerVariant,
  cargoValues,
  coreCargoTypeLabelMapping,
  LooseCargoTypeEnum,
  PalletTypeEnum,
  TruckType
} from '../../types';
import AssortedCargo from './images/assorted-cargo.svg?react';
import BoxedTruck from './images/boxed-truck.svg?react';
import BoxesAndCrates from './images/boxes-and-crates.svg?react';
import CurtainSidedTruck from './images/curtain-sided-truck.svg?react';
import DryStandardContainer from './images/dry-standard-container.svg?react';
import FlatRackContainer from './images/flat-rack-container.svg?react';
import HiabTruck from './images/hiab-truck.svg?react';
import OpenTopContainer from './images/open-top-container.svg?react';
import Pallet from './images/pallet.svg?react';
import RefrigeratedContainer from './images/refrigerated-container.svg?react';
import RefrigeratedTruck from './images/refrigerated-truck.svg?react';
import SlidingRoofTruck from './images/sliding-roof-truck.svg?react';

const imageSourceMapping: Record<Exclude<CargoItemDetailsType, CoreCargoType>, SVGComponent> = {
  [ContainerTypeEnum.X20DV]: DryStandardContainer,
  [ContainerTypeEnum.X20HC]: DryStandardContainer,
  [ContainerTypeEnum.X40DV]: DryStandardContainer,
  [ContainerTypeEnum.X40HC]: DryStandardContainer,
  [ContainerTypeEnum.X45HC]: DryStandardContainer,
  [PalletTypeEnum.EURO]: Pallet,
  [PalletTypeEnum.UK]: Pallet,
  [PalletTypeEnum.OTHER]: Pallet,
  [PalletTypeEnum.US]: Pallet,
  [LooseCargoTypeEnum.PALLETS]: Pallet,
  [LooseCargoTypeEnum.BOXES_OR_CRATES]: BoxesAndCrates,
  [LooseCargoTypeEnum.ASSORTED_CARGO]: AssortedCargo,
  [VehicleTypeEnum.STRAIGHT_TRUCK]: BoxedTruck,
  [VehicleTypeEnum.TRAILER_13_6_M_BOX]: BoxedTruck,
  [VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED]: CurtainSidedTruck,
  [VehicleTypeEnum.TRAILER_13_6_M_HIAB]: HiabTruck,
  [VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER]: BoxedTruck,
  [VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER_CURTAIN_SIDED]: CurtainSidedTruck,
  [VehicleTypeEnum.TRAILER_13_6_M_SLIDING_ROOF]: SlidingRoofTruck,
  [VehicleTypeEnum.TRAILER_13_6_M_REFRIGERATED]: RefrigeratedTruck,
  [VehicleTypeEnum.TRAILER_18_T_BOX]: BoxedTruck,
  [VehicleTypeEnum.TRAILER_18_T_HIAB]: HiabTruck,
  [VehicleTypeEnum.TRAILER_18_T_CURTAIN_SIDED]: CurtainSidedTruck,
  [VehicleTypeEnum.TRAILER_18_T_REFRIGERATED]: RefrigeratedTruck,
  [VehicleTypeEnum.TRAILER_18_T_SLIDING_ROOF]: SlidingRoofTruck,
  [VehicleTypeEnum.TRAILER_3_5_T_BOX]: BoxedTruck,
  [VehicleTypeEnum.TRAILER_3_5_T_CURTAIN_SIDED]: CurtainSidedTruck,
  [VehicleTypeEnum.TRAILER_53_FT_BOXED]: BoxedTruck,
  [VehicleTypeEnum.TRAILER_7_5_T_BOX]: BoxedTruck,
  [VehicleTypeEnum.TRAILER_7_5_T_CURTAIN_SIDED]: CurtainSidedTruck,
  [VehicleTypeEnum.TRAILER_7_5_T_SLIDING_ROOF]: SlidingRoofTruck,
  [VehicleTypeEnum.VAN_BOX]: BoxedTruck,
  [VehicleTypeEnum.VAN_CURTAIN_SIDED]: CurtainSidedTruck,
  [VehicleTypeEnum.VAN_SLIDING_ROOF]: SlidingRoofTruck,
  [TruckType.BOXED]: BoxedTruck,
  [TruckType.CURTAIN_SIDED]: CurtainSidedTruck,
  [TruckType.HIAB]: HiabTruck,
  [TruckType.REFRIGERATED_TRUCK]: RefrigeratedTruck,
  [TruckType.SLIDING_ROOF]: SlidingRoofTruck,
  [CargoFullContainerVariant.DRY]: DryStandardContainer,
  [CargoFullContainerVariant.FLATRACK]: FlatRackContainer,
  [CargoFullContainerVariant.OPEN_TOP]: OpenTopContainer,
  [CargoFullContainerVariant.REFRIGERATED]: RefrigeratedContainer
};

const coreCargoImageSourceMapping: Record<CoreCargoType, SVGComponent> = {
  [cargoValues.containerX20Dv]: DryStandardContainer,
  [cargoValues.containerX20Hc]: DryStandardContainer,
  [cargoValues.containerX40Dv]: DryStandardContainer,
  [cargoValues.containerX40Hc]: DryStandardContainer,
  [cargoValues.containerX45Hc]: DryStandardContainer,
  [cargoValues.loosePalletEuro]: Pallet,
  [cargoValues.loosePalletLegacy]: Pallet,
  [cargoValues.loosePalletOther]: Pallet,
  [cargoValues.loosePalletUk]: Pallet,
  [cargoValues.loosePalletUs]: Pallet,
  [cargoValues.looseBoxesOrCrates]: BoxesAndCrates,
  [cargoValues.looseAssortedCargo]: AssortedCargo,
  [cargoValues.straightTruck]: BoxedTruck,
  [cargoValues.trailer136MBox]: BoxedTruck,
  [cargoValues.trailer136MCurtainSided]: CurtainSidedTruck,
  [cargoValues.trailer136MHiab]: HiabTruck,
  [cargoValues.trailer136MMegatrailer]: BoxedTruck,
  [cargoValues.trailer136MMegatrailerCurtainSided]: CurtainSidedTruck,
  [cargoValues.trailer136MSlidingRoof]: SlidingRoofTruck,
  [cargoValues.trailer136MRefrigerated]: RefrigeratedTruck,
  [cargoValues.trailer18TBox]: BoxedTruck,
  [cargoValues.trailer18THiab]: HiabTruck,
  [cargoValues.trailer18TCurtainSided]: CurtainSidedTruck,
  [cargoValues.trailer18TRefrigerate]: RefrigeratedTruck,
  [cargoValues.trailer18TSlidingRoof]: SlidingRoofTruck,
  [cargoValues['35TBox']]: BoxedTruck,
  [cargoValues['35TCurtainSided']]: CurtainSidedTruck,
  [cargoValues['53FtTrailer']]: BoxedTruck,
  [cargoValues.trailer75TBox]: BoxedTruck,
  [cargoValues.trailer75TCurtainSided]: CurtainSidedTruck,
  [cargoValues.trailer75TSlidingRoof]: SlidingRoofTruck,
  [cargoValues.trailerVanBox]: BoxedTruck,
  [cargoValues.trailerVanCurtainSided]: CurtainSidedTruck,
  [cargoValues.trailerVanSlidingRoof]: SlidingRoofTruck
};

export const getCargoIconComponent = (cargoType: CargoItemDetailsType, isCoreCargo: boolean = false): ReactNode => {
  const IconComponent: SVGComponent = isCoreCargo
    ? coreCargoImageSourceMapping[cargoType as CoreCargoType]
    : imageSourceMapping[cargoType as CargoType];

  return <IconComponent className="w-full h-full" />;
};

export const getCargoLabel = (cargoType: Optional<CargoItemDetailsType>, isCoreCargo: boolean = false): string => {
  if (!cargoType) {
    return '';
  }

  return isCoreCargo ? coreCargoTypeLabelMapping[cargoType as CoreCargoType] : cargoTypeLabelMapping[cargoType as CargoType];
};
