import type { Option } from '@zen/DesignSystem';

import type { CargoItemDetailsType, PalletTypeEnum } from '../../types';
import { CargoFullContainerVariant, CargoModeEnum, LooseCargoTypeEnum, TruckType } from '../../types';

const fullContainerTypesOptions: Option<CargoFullContainerVariant>[] = [
  { label: 'Dry', value: CargoFullContainerVariant.DRY },
  { label: 'Refrigerated', value: CargoFullContainerVariant.REFRIGERATED },
  { label: 'Open top', value: CargoFullContainerVariant.OPEN_TOP },
  { label: 'Flat rack', value: CargoFullContainerVariant.FLATRACK }
];

const looseCargoVariantLabelMapping: Record<LooseCargoTypeEnum, string> = {
  [LooseCargoTypeEnum.PALLETS]: 'Pallets',
  [LooseCargoTypeEnum.BOXES_OR_CRATES]: 'Boxes and crates',
  [LooseCargoTypeEnum.ASSORTED_CARGO]: 'Assorted cargos'
};

const looseCargoOptions: Option<PalletTypeEnum | LooseCargoTypeEnum>[] = [
  { value: LooseCargoTypeEnum.PALLETS, label: looseCargoVariantLabelMapping[LooseCargoTypeEnum.PALLETS] },
  {
    value: LooseCargoTypeEnum.BOXES_OR_CRATES,
    label: looseCargoVariantLabelMapping[LooseCargoTypeEnum.BOXES_OR_CRATES]
  },
  {
    value: LooseCargoTypeEnum.ASSORTED_CARGO,
    label: looseCargoVariantLabelMapping[LooseCargoTypeEnum.ASSORTED_CARGO]
  }
];

const fullTruckTypesOptions: Option<TruckType>[] = [
  {
    label: 'Boxed',
    value: TruckType.BOXED
  },
  {
    label: 'Curtain sided',
    value: TruckType.CURTAIN_SIDED
  },
  {
    label: 'Refrigerated',
    value: TruckType.REFRIGERATED_TRUCK
  },
  {
    label: 'Sliding roof',
    value: TruckType.SLIDING_ROOF
  },
  {
    label: 'Hiab',
    value: TruckType.HIAB
  }
];

export const cargoVariantTileOptions: Record<CargoModeEnum, Option<CargoItemDetailsType>[]> = {
  [CargoModeEnum.FCL]: fullContainerTypesOptions,
  [CargoModeEnum.FTL]: fullTruckTypesOptions,
  [CargoModeEnum.LOOSE]: looseCargoOptions
};
