import type { FC } from 'react';
import { useState } from 'react';

import { Button } from '@zen/DesignSystem';

import AddMemberModal from '../AddMemberModal';

interface Props {
  accountHasBusinessUnits: boolean;
  accountId: string;
  onSuccess: () => void;
}

const AddMemberModalButton: FC<Props> = ({ accountId, accountHasBusinessUnits, onSuccess }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen((isOpen: boolean): boolean => !isOpen);

  const handleSuccess = (): void => {
    toggleModal();
    onSuccess();
  };

  return (
    <>
      <AddMemberModal
        accountHasBusinessUnits={accountHasBusinessUnits}
        accountId={accountId}
        isOpen={isModalOpen}
        onClose={toggleModal}
        onSuccess={handleSuccess}
      />
      <Button onClick={toggleModal} size="compact">
        Add member
      </Button>
    </>
  );
};

export default AddMemberModalButton;
