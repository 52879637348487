import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import { CargoModeEnum } from '@zen/Booking';
import { Button, Modal, Tooltip } from '@zen/DesignSystem';
import { getStopTimeZone, isCollectionStop, isDeliveryStop, prepareDateAndTimePayload } from '@zen/Journey/helpers';
import { prepareBusinessHoursInitialValues } from '@zen/Networks/networksHelpers';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import type { JourneyStop } from '../../types';
import ConfirmRoadLegForm from '../forms/ConfirmRoadLegForm/ConfirmRoadLegForm';
import type { RoadLegValues } from '../forms/ConfirmRoadLegForm/types';
import { journeyRefetchQueryList } from '../forms/helpers';
import { useJourneyContext } from '../JourneyDetails/JourneyDetailsContext';
import { useConfirmJourneyRoadLegMutation } from './graphql';

interface Props {
  isDisabled?: boolean;
  tooltipContent?: Optional<string>;
}

const ConfirmRoadLegButton: FC<Props> = ({ isDisabled = false, tooltipContent }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { addError, addSuccess } = useNotification();
  const [confirmRoadLeg] = useConfirmJourneyRoadLegMutation();
  const { cargoId, cargoMode, cargoReadyDate, carriageLeg, stops, zencargoReference } = useJourneyContext();

  const collectionStop: Optional<JourneyStop> = stops.find(({ stopAction }: JourneyStop) => isCollectionStop(stopAction));
  const deliveryStop: Optional<JourneyStop> = stops.find(({ stopAction }: JourneyStop) => isDeliveryStop(stopAction));

  const isFTLShipment: boolean = cargoMode === CargoModeEnum.FTL;

  const handleCloseModal = (): void => setIsModalVisible(false);

  const handleSubmit = async ({ collection, delivery, haulier }: RoadLegValues): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        confirmRoadLeg({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              cargoId,
              collectionDate: prepareDateAndTimePayload(collection),
              deliveryDate: delivery?.date ? prepareDateAndTimePayload(delivery) : null,
              haulierId: haulier?.id || '',
              journeyLegId: carriageLeg?.id || '',
              vehicleId: carriageLeg?.vehicleId || '',
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Booking has been confirmed.');
        handleCloseModal();
      }
    });
  };

  const renderButton = (): ReactNode => {
    const label: string = 'Confirm booking';

    if (isDisabled) {
      return (
        <Tooltip tooltipContent={tooltipContent}>
          <Button disabled={isDisabled} iconLeft="zicon-tick" size="compact" variant="secondary">
            {label}
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button iconLeft="zicon-tick" onClick={() => setIsModalVisible(true)} size="compact" variant="secondary">
        {label}
      </Button>
    );
  };

  return (
    <>
      {renderButton()}
      <Modal
        closeOnClickAway={false}
        isOpen={isModalVisible}
        modalOverflowY="visible"
        onClose={handleCloseModal}
        title="Confirm booking"
      >
        <ConfirmRoadLegForm
          cargoReadyDate={cargoReadyDate}
          collectionBusinessHours={prepareBusinessHoursInitialValues(collectionStop?.warehouse?.businessHours)}
          collectionTimeZone={getStopTimeZone(collectionStop)}
          deliveryBusinessHours={prepareBusinessHoursInitialValues(deliveryStop?.warehouse?.businessHours)}
          deliveryTimeZone={getStopTimeZone(deliveryStop)}
          isDeliveryRequired={isFTLShipment}
          onCancel={handleCloseModal}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};

export default ConfirmRoadLegButton;
