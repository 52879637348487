import type { BrowserJsPlumbInstance } from '@jsplumb/browser-ui';
import { newInstance } from '@jsplumb/browser-ui';
import type { FC, ReactNode, Ref } from 'react';
import { useEffect, useRef } from 'react';
import { useDeepCompareEffect, useMeasure } from 'react-use';

import { Color } from '@zen/Styleguide';

interface Props {
  children: ReactNode;
  color?: Color;
  dependencies?: unknown[];
  pointClassName?: string;
  style?: 'solid' | 'dashed';
}

const LineProvider: FC<Props> = ({
  children,
  color = Color.GREY_LIGHTER,
  dependencies = [],
  pointClassName = 'point',
  style = 'dashed'
}) => {
  const plumbInstance = useRef<BrowserJsPlumbInstance>();
  const ref: Ref<HTMLDivElement> = useRef(null);
  const [wrapperRef, { width, height }] = useMeasure<HTMLDivElement>();

  const drawLines = (): void => {
    if (ref.current) {
      const dataPointElements = ref.current.querySelectorAll(`.${pointClassName}`);

      dataPointElements.forEach((el, index, arr) => {
        if (index === 0 || !plumbInstance.current) return;

        plumbInstance.current.connect({
          source: arr[index - 1],
          target: el,
          paintStyle: {
            stroke: color,
            strokeWidth: 1,
            ...(style === 'dashed' ? { dashstyle: '2 2' } : {})
          }
        });
      });
    }
  };

  useEffect(() => {
    if (ref.current) {
      plumbInstance.current = newInstance({
        anchor: 'Center',
        container: ref.current,
        endpointStyle: {
          stroke: 'none',
          fill: 'none',
          outlineWidth: 0
        },
        reattachConnections: true
      });

      drawLines();
    }
  }, []);

  useDeepCompareEffect(() => {
    if (plumbInstance.current && ref.current) {
      plumbInstance?.current?.deleteEveryConnection();
      drawLines();
    }
  }, dependencies);

  useEffect(() => {
    if (plumbInstance.current && ref.current) {
      plumbInstance.current.revalidate(ref.current);
    }
  }, [width, height]);

  return (
    <div ref={wrapperRef} data-testid="line-provider">
      <div ref={ref} className="relative z-0">
        {children}
      </div>
    </div>
  );
};

export default LineProvider;
