import type { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import CreateEditModal from '@zen/Components/EditCreateModal';
import type { CreateEditModalConfiguration } from '@zen/Components/EditCreateModal/types';
import OperationsSettingsSearch from '@zen/Components/OperationsSettingsSearch';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Button, Table } from '@zen/DesignSystem';
import type { ShipmentActionType } from '@zen/graphql/types.generated';
import opsSettingsRoutes from '@zen/Routes/opsSettings';
import type { SortInput } from '@zen/types';
import { SortingOrder } from '@zen/types';
import usePagination from '@zen/utils/hooks/pagination/usePagination';

import EditShipmentActionType from '../EditShipmentActionType';
import type { GetShipmentActionTypesQueryResult, GetShipmentActionTypesQueryVariables } from '../graphql';
import { useGetShipmentActionTypesQuery } from '../graphql';
import NewShipmentActionType from '../NewShipmentActionType';
import { getShipmentActionTypesColumnsConfiguration } from './shipmentActionTypesTableConfiguration';

const initialOrder: SortInput = {
  field: 'name',
  direction: SortingOrder.ASC
};

const ShipmentActionTypesList: FC = () => {
  const [modalConfiguration, setModalConfiguration] = useState<CreateEditModalConfiguration<ShipmentActionType>>();

  const [order, setOrder] = useState<SortInput>(initialOrder);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { loading, error, nodes, paginationInfo, totalCount } = usePagination<
    GetShipmentActionTypesQueryResult,
    GetShipmentActionTypesQueryVariables,
    ShipmentActionType
  >(useGetShipmentActionTypesQuery, 'shipmentActionTypes', { order, searchQuery }, 20);

  const handleEdit = (shipmentActionType: ShipmentActionType): void => {
    setModalConfiguration({
      type: 'edit',
      initialValues: shipmentActionType,
      title: 'Edit shipment action type'
    });
  };

  const handleCreate = (): void => {
    setModalConfiguration({
      type: 'create',
      title: 'New shipment action type'
    });
  };

  const handleCloseModal = (): void => setModalConfiguration(undefined);

  const addNewShipmentActionTypeButton: ReactNode = (
    <Button onClick={handleCreate} variant="secondary">
      New shipment action type
    </Button>
  );

  const columns = getShipmentActionTypesColumnsConfiguration(handleEdit);

  return (
    <Page
      actionButtons={addNewShipmentActionTypeButton}
      defaultBackUrl={opsSettingsRoutes.opsSettingsIndex.getUrl()}
      tagline="A list of all shipment action types. Manage categories for shipment status updates."
      title="Shipment action types"
    >
      <>
        <OperationsSettingsSearch onSearch={setSearchQuery} placeholder="Search for a shipment action type..." />

        <QueryHandler
          data={nodes}
          error={!!error}
          isLoading={loading}
          loadingComponent={<SkeletonTableLoading columns={columns} order={order} tableId="shipmentActionTypesList" />}
        >
          {(shipmentActionTypesList: ShipmentActionType[]): ReactElement => (
            <Table<ShipmentActionType>
              columns={columns}
              data={shipmentActionTypesList}
              onOrderChange={setOrder}
              order={order}
              paginationInfo={paginationInfo}
              tableId="shipmentActionTypesList"
              totalCountConfig={{
                totalCount,
                entityName: 'shipment action type'
              }}
            />
          )}
        </QueryHandler>

        <CreateEditModal
          configuration={modalConfiguration}
          createForm={NewShipmentActionType}
          editForm={EditShipmentActionType}
          onClose={handleCloseModal}
        />
      </>
    </Page>
  );
};

export default ShipmentActionTypesList;
