import type { ObjectSchema } from 'yup';
import { object, string } from 'yup';

import { getChargeItemsValidator } from '@zen/RateCards/FreightCharges/FreightChargeForm/freightCharge.validation';

import type { PortFieldKey, WarehouseFieldKey } from './types';

export const getValidationSchema = (portFieldName: PortFieldKey, warehouseFieldName: WarehouseFieldKey): ObjectSchema<{}> =>
  object().shape({
    chargeItems: getChargeItemsValidator(),
    [warehouseFieldName]: object().nullable().required('Please select an address.'),
    [portFieldName]: string().nullable().required('Please select a port.')
  });
