import type { FC } from 'react';

import { ChargeBasisEnum, ChargeTypeEnum } from '@zen/Accruals/ChargeTypes/types';
import type { FormInstance } from '@zen/Components';
import { Form, FormArray, FormButtons, FormSelect } from '@zen/Components';
import FormChargeTypeSelect from '@zen/Components/Form/FormChargeTypeSelect';
import FormCurrencyInput from '@zen/Components/Form/FormCurrencyInput';
import FormIncotermSelect from '@zen/Components/Form/FormIncotermSelect';
import FormTerminalsSelect from '@zen/Components/Form/FormTerminalsSelect';
import { Button, IconButton } from '@zen/DesignSystem';
import type { FreightChargeValidationValues } from '@zen/RateCards/LegacyRateCards/RateCardForm/FreightChargeSection/types';
import type { FreightChargePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';
import type { ContainerTypeEnum } from '@zen/types';
import { TerminalTypeValue } from '@zen/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { getValidationSchema } from './freightChargeForm.validation';
import {
  getAvailableContainerTypeOptions,
  getEmptyContainerCharge,
  hasAvailableContainerCharge,
  prepareInitialValues,
  preparePayload
} from './helpers';
import type { FreightChargeFormInitialValues, FreightChargeFormType, InitialContainerCharge } from './types';

interface Props {
  initialValues?: FreightChargePayload;
  onCancel?: () => void;
  onSubmit: (values: FreightChargePayload) => void;
  onSuccess: () => void;
  selectedFreightCharges?: FreightChargeValidationValues[];
}
const defaultInitialValues: FreightChargeFormInitialValues = {
  chargeType: null,
  originPort: null,
  destinationPort: null,
  containerCharges: [getEmptyContainerCharge()],
  incoterms: []
};

const FreightChargeForm: FC<Props> = ({ initialValues, onSubmit, onSuccess, onCancel, selectedFreightCharges = [] }) => {
  const handleSubmit = (values: FreightChargeFormType): Promise<IOkOrErrorResult> => {
    const freightChargePayload = preparePayload(values);

    onSubmit(freightChargePayload);

    return Promise.resolve({ ok: {}, error: null });
  };

  const formInitialValues: FreightChargeFormInitialValues = initialValues
    ? prepareInitialValues(initialValues)
    : defaultInitialValues;

  const renderFormButtons = ({ isSubmitting }: FormInstance<FreightChargeFormInitialValues>) => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed">
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      validationSchema={getValidationSchema(selectedFreightCharges)}
    >
      {(form: FormInstance<FreightChargeFormType>) => {
        const selectedContainerTypes: ContainerTypeEnum[] = form.values.containerCharges.map(
          (containerCharge: InitialContainerCharge) => containerCharge.unitType
        );

        return (
          <>
            <FormChargeTypeSelect basis={ChargeBasisEnum.CONTAINER} name="chargeType" type={ChargeTypeEnum.FREIGHT} />
            <FormTerminalsSelect name="originPort" terminalTypes={[TerminalTypeValue.SEAPORT]} />
            <FormTerminalsSelect name="destinationPort" terminalTypes={[TerminalTypeValue.SEAPORT]} />
            <FormIncotermSelect isMulti={true} name="incoterms" showIncotermsExplanation={false} />
            <FormArray<InitialContainerCharge>
              addButtonText="Add container price"
              empty={getEmptyContainerCharge(selectedContainerTypes)}
              hideAddButton={!hasAvailableContainerCharge(selectedContainerTypes)}
              path="containerCharges"
              values={form.values.containerCharges}
            >
              {({ getFieldName, remove, value }) => {
                const selectedContainerType: ContainerTypeEnum = value.unitType;
                const otherSelectedContainerTypes: ContainerTypeEnum[] = selectedContainerTypes.filter(
                  (containerType) => containerType !== selectedContainerType
                );

                return (
                  <div className="grid grid-cols-2 gap-4">
                    <FormSelect
                      className="w-full"
                      label="Container type"
                      name={getFieldName('unitType')}
                      options={getAvailableContainerTypeOptions(otherSelectedContainerTypes)}
                      renderMenuInPortal={true}
                    />
                    <div className="flex w-full space-x-4">
                      <FormCurrencyInput label="Price" name={getFieldName('containerValue')} />
                      <IconButton
                        className="mt-6 shrink-0"
                        icon="zicon-trash"
                        onClick={remove}
                        title="Remove"
                        variant="secondary"
                      />
                    </div>
                  </div>
                );
              }}
            </FormArray>
          </>
        );
      }}
    </Form>
  );
};

export default FreightChargeForm;
