import type { Option } from '@zen/DesignSystem';
import { useAllPaginatedResults } from '@zen/utils/hooks/pagination';

import type { GetAccountNetworkContactsQueryResult, GetAccountNetworkContactsQueryVariables } from '../graphql';
import { GetAccountNetworkContactsDocument } from '../graphql';
import type { NetworkContactItem } from '../types';

export const useNetworkContactOptions = (accountId: string): Option<string>[] => {
  const { data: networkContacts } = useAllPaginatedResults<
    GetAccountNetworkContactsQueryResult,
    GetAccountNetworkContactsQueryVariables,
    NetworkContactItem
  >({
    document: GetAccountNetworkContactsDocument,
    responsePath: 'network.myOrganisation.contacts',
    variables: {
      accountId
    },
    skip: !accountId
  });

  return networkContacts.map(
    (contact: NetworkContactItem): Option<string> => ({
      value: contact.id,
      label: `${contact.firstName} ${contact.lastName}`
    })
  );
};
