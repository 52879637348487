import type { FC, ReactNode } from 'react';

import type { FormInstance } from '@zen/Components';
import { Form, FormButtons, FormInput } from '@zen/Components';
import { Button, Modal } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import type { CreateAccountDivisionInput } from '../../graphql';
import { useCreateAccountDivisionMutation } from '../../graphql';
import businessUnitValidation from './createBusinessUnitModal.validation';

interface Props {
  accountId: string;
  isOpen: boolean;
  onClose: () => void;
  referencePrefix: string;
}

const CreateBusinessUnitModal: FC<Props> = ({ isOpen, onClose, accountId, referencePrefix }) => {
  const [createAccountDivision] = useCreateAccountDivisionMutation();
  const { addError } = useNotification();

  const initialValues: CreateAccountDivisionInput = {
    divisionName: '',
    referencePrefix,
    accountId
  };

  const handleCreateAccountDivision = async (values: CreateAccountDivisionInput): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        createAccountDivision({
          awaitRefetchQueries: true,
          refetchQueries: ['getAccountDetails', 'getAccountBusinessUnits'],
          variables: {
            input: values
          }
        }),
      onError: () => addError()
    });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<{ members: string[] }>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Create division">
      <Button data-testid="cancel-button" onClick={onClose} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      tagline="Manage separate bookings, products and notifications."
      title="Create division"
    >
      <Form
        formButtons={renderFormButtons}
        initialValues={initialValues}
        onSubmit={handleCreateAccountDivision}
        onSuccess={onClose}
        validationSchema={businessUnitValidation}
      >
        {() => {
          return <FormInput helperText='For example: "EMEA".' label="Division name" name="divisionName" noMargin={true} />;
        }}
      </Form>
    </Modal>
  );
};

export default CreateBusinessUnitModal;
