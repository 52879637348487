import type { RefObject } from 'react';
import { useEffect } from 'react';
import { useKey } from 'react-use';

const useClickAway = (ref: RefObject<HTMLElement | null>, onClickAway: (event: MouseEvent | KeyboardEvent) => void) => {
  // Wherever clickAway is implied, "Escape" button should work as well!
  useKey('Escape', onClickAway);
  useEffect(() => {
    const handler = (event: MouseEvent) => {
      const { current: el } = ref;

      if (el && !el.contains(event.target as Node)) {
        onClickAway(event);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });
};

export default useClickAway;
