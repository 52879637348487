import type { ReactNode } from 'react';

import { highlightQuery } from '@zen/Components/Form/utils';
import { consolidationRoutes, orderRoutes, productRoutes, quoteRoutes, shipmentRoutes } from '@zen/Routes';
import type { Optional } from '@zen/utils/typescript';

import { EntityType, type GlobalSearchResultItem, SearchableField } from '../types';

export const getSearchableFieldLabel = (field: SearchableField): string => {
  switch (field) {
    case SearchableField.ZENCARGO_REFERENCE:
    case SearchableField.ORDER_NUMBER:
      return '';
    case SearchableField.SKU_CODE:
      return 'SKU code - ';
    case SearchableField.CLIENT_REFERENCE:
      return 'Client reference - ';
    case SearchableField.CONSOLIDATION_REFERENCE:
      return 'Consolidation reference - ';
    case SearchableField.PRODUCT_NAME:
      return 'Product name - ';
    case SearchableField.CARRIER_BOOKING_REFERENCE:
      return 'Carrier booking reference - ';
    case SearchableField.SCAC_CODE:
      return 'SCAC code - ';
    case SearchableField.CONTAINER_NUMBER:
      return 'Container number - ';
    case SearchableField.COURIER_TRACKING_NUMBER:
      return 'Courier tracking number - ';
    case SearchableField.COLLECTION_LOCATION:
      return 'Collection location - ';
    case SearchableField.DELIVERY_LOCATION:
      return 'Delivery location - ';
    case SearchableField.FLIGHT_NUMBER:
      return 'Flight number - ';
    case SearchableField.HOUSE_BILL_OF_LADING:
      return 'House bill of lading - ';
    case SearchableField.MANUFACTURER:
      return 'Manufacturer - ';
    case SearchableField.MASTER_AIRWAY_BILL:
      return 'Master airway bill - ';
    case SearchableField.MASTER_BILL_OF_LADING:
      return 'Master bill of lading - ';
    case SearchableField.PORT_OF_DESTINATION:
      return 'Port of destination - ';
    case SearchableField.PORT_OF_LOADING:
      return 'Port of loading - ';
    case SearchableField.VEHICLE_PLATE:
      return 'Plate number - ';
    case SearchableField.VESSEL_NAME:
      return 'Vessel name - ';
    case SearchableField.CONSIGNEE:
      return 'Consignee - ';
  }
};

export const getEntityType = (entityType: EntityType): string => {
  switch (entityType) {
    case EntityType.BOOKING_REQUEST:
      return 'Booking request';
    case EntityType.CONSOLIDATED_SHIPMENT:
      return 'Consolidated shipment';
    case EntityType.PRODUCT:
      return 'Product';
    case EntityType.QUOTE:
      return 'Quote';
    case EntityType.PURCHASE_ORDER:
      return 'Purchase order';
    case EntityType.SHIPMENT:
      return 'Shipment';
    case EntityType.ARCHIVED_SHIPMENT:
      return 'Archived shipment';
  }
};

export const getResultUrl = (entityType: EntityType, id: string): string => {
  switch (entityType) {
    case EntityType.BOOKING_REQUEST:
      return shipmentRoutes.shipmentDetailsPage.getUrl(id);
    case EntityType.CONSOLIDATED_SHIPMENT:
      return consolidationRoutes.consolidationDetails.getUrl(id);
    case EntityType.PRODUCT:
      return productRoutes.details.getUrl(id);
    case EntityType.QUOTE:
      return quoteRoutes.quoteDetailsPage.getUrl(id);
    case EntityType.PURCHASE_ORDER:
      return orderRoutes.orderDetails.getUrl(id);
    case EntityType.SHIPMENT:
      return shipmentRoutes.shipmentDetailsPage.getUrl(id);
    case EntityType.ARCHIVED_SHIPMENT:
      return shipmentRoutes.shipmentDetailsPage.getUrl(id);
  }
};

export const buildSubtitle = (result: GlobalSearchResultItem): string => {
  const matchedFieldValue = result.matchedFields[0]?.value;
  const entityReference = matchedFieldValue === result.description ? null : result.description;
  const additionalInformation = result.matchedFields[0]?.additionalInformation;
  const fields: Optional<string>[] = [additionalInformation, entityReference, getEntityType(result.type), result.accountName];

  return fields.filter(Boolean).join(' · ');
};

export const buildTitle = (result: GlobalSearchResultItem, query: string = ''): ReactNode => {
  const fieldValue = result.matchedFields[0]?.value || '';
  const reference = highlightQuery(fieldValue, query);
  const fieldName = result.matchedFields[0]?.type ? getSearchableFieldLabel(result.matchedFields[0].type) : '';

  return (
    <>
      {fieldName}
      {reference}
    </>
  );
};
