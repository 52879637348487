import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import '@zen/Styleguide/assets/fonts.css';
import './styles/main.css';
import './styles/animate.css';
import './styles/style.css';

import type { FC, ReactNode } from 'react';
// Initialize embed configuration
import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import AuthProvider from '@zen/Auth/AuthProvider';
import AutomaticAppUpdates from '@zen/Components/AutomaticAppUpdates';
import ErrorBoundary from '@zen/Components/ErrorBoundary';
import StatusPage from '@zen/Components/StatusPage';
import FeatureFlagProvider from '@zen/FeatureFlag';
import { configure } from '@zen/utils/api';
import logger from '@zen/utils/logger';
import NavigationHistoryProvider from '@zen/utils/NavigationHistory/NavigationHistoryProvider';

import NotificationProvider from '../Components/NotificationProvider';
import TrackingProvider from '../Components/TrackingProvider';
import GraphQLProvider from '../graphql/GraphQLProvider';
import staticConfig from '../utils/staticConfig';
import AccountIdProvider from './AccountIdContext';
import Routes from './Routes';

configure();

const App: FC = () => {
  const { environment } = staticConfig;
  const isProduction: boolean = environment === 'production';

  useEffect(() => {
    if (isProduction) {
      logger.disable();
    } else {
      logger.enable();
      logger.info('Logger enabled');
    }
  }, [isProduction]);

  const renderApp = (): ReactNode => (
    <ErrorBoundary>
      <AutomaticAppUpdates isProduction={isProduction}>
        <AuthProvider>
          <FeatureFlagProvider>
            <GraphQLProvider>
              <AccountIdProvider>
                <TrackingProvider>
                  <NotificationProvider>
                    <NavigationHistoryProvider>
                      <Routes env={environment} />
                    </NavigationHistoryProvider>
                  </NotificationProvider>
                </TrackingProvider>
              </AccountIdProvider>
            </GraphQLProvider>
          </FeatureFlagProvider>
        </AuthProvider>
      </AutomaticAppUpdates>
      {isProduction && <StatusPage />}
    </ErrorBoundary>
  );

  // this is so-called data router in react router v6
  // it is required for certain new hooks to work 🤷🏼‍♂️
  const router = createBrowserRouter([
    {
      path: '/*',
      element: renderApp()
    }
  ]);

  return <RouterProvider router={router} />;
};

export default App;
