import type { FC } from 'react';

import { formatDate } from '@zen/utils/dateTime';

import type { LastUpdatedType } from './types';
import ZencargoIcon from './zencargo-icon.svg?react';

interface Props {
  lastUpdate: LastUpdatedType;
}

const LastUpdated: FC<Props> = ({ lastUpdate }) => {
  const { updatedAt, updatedBy } = lastUpdate;

  const renderUpdateBy = () => (
    <span className="flex items-center space-x-1">
      by {updatedBy?.zencargoEmployee && <ZencargoIcon className="w-5 ml-1" data-testid="zencargo-icon" />}
      <span className="ml-1 font-bold">{updatedBy?.username}</span>
    </span>
  );

  return (
    <div className="flex items-center space-x-1 text-xs text-grey-dark">
      {updatedAt && <span>Last updated: {formatDate(updatedAt)}</span>}
      {updatedBy && updatedBy.username && renderUpdateBy()}.
    </div>
  );
};

export type { Props as LastUpdatedProps };

export default LastUpdated;
