import cx from 'classnames';
import { isEqual } from 'lodash';
import type { ReactElement, ReactNode } from 'react';
import SelectComponent from 'react-select';

import type { LabelPlacement } from '@zen/Components/Label';
import Label from '@zen/Components/Label';
import { useHover } from '@zen/utils/hooks/useHover';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { ClearIndicator, getMenuList, getNoOptionsMessage, ValueContainer } from '../components';
import DropdownIndicator from '../components/DropdownIndicator';
import LoadingMessage from '../components/LoadingMessage';
import { getAllOptions } from '../helpers';
import { customStyles, customTheme } from '../select-styles';
import type { CommonSelectProps, Group, Option, SelectVariant } from '../types';

interface Props<Value> extends CommonSelectProps<Value> {
  label?: ReactNode;
  labelPlacement?: LabelPlacement;
  labelTooltip?: ReactNode;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: Nullable<Value>) => void;
  renderMenuInPortal?: boolean;
  showSearchIcon?: boolean;
  size?: 'default' | 'compact';
  value?: Optional<Value>;
  variant?: SelectVariant;
  wrapperClassName?: string;
}

const Select = <Value extends Nullable<{}>>(props: Props<Value>) => {
  const {
    autoFocus,
    className,
    dropdownFooterRenderer,
    formatGroupLabel,
    formatOptionLabel,
    hasError,
    inputValue,
    isClearable,
    isDisabled,
    isLoading,
    isSearchable,
    isOptionDisabled,
    label,
    labelTooltip,
    labelPlacement = 'top',
    hideSelectedOptions,
    name,
    onBlur,
    onChange,
    onInputChange,
    options,
    placeholder,
    renderMenuInPortal = false,
    shouldFilterResults = true,
    showSearchIcon,
    size = 'default',
    suggestion,
    value,
    variant = 'default',
    wrapperClassName
  } = props;

  const [ref, isHovered] = useHover();

  const getSelectedOption = (): Option<Value> | null => {
    const allOptions: Option<Value>[] = getAllOptions(options);

    return allOptions.find((option) => isEqual(option.value, value)) || null;
  };

  const handleChange = (option: Nullable<Option<Value>>): void => {
    onChange?.(option?.value || null);
  };

  const formatLabel = (option: Option<Value>, metadata: { context: 'menu' | 'value'; selectValue: readonly Option<Value>[] }) => {
    const isSelected: boolean = !!metadata.selectValue.find((selectedValue) => selectedValue.value === option.value);

    return formatOptionLabel?.(option, isSelected, metadata.context) || option.label;
  };

  const formatGroup = (group: Group<Value>): ReactNode => {
    return formatGroupLabel?.(group) || group.label;
  };

  const handleInputChange = (query: Nullable<string>) => {
    onInputChange?.(query || '');
  };

  const filterOption = shouldFilterResults ? undefined : () => true;
  const shouldEnableBlurOnSelect: boolean = variant !== 'default';
  const select: ReactElement = (
    <div ref={ref} className={cx('relative min-w-20', wrapperClassName)}>
      <SelectComponent<Option<Value>, false>
        autoFocus={autoFocus}
        blurInputOnSelect={shouldEnableBlurOnSelect}
        className={className}
        classNames={{ option: () => 'select-option' }}
        components={{
          DropdownIndicator,
          ClearIndicator,
          MenuList: getMenuList(dropdownFooterRenderer),
          NoOptionsMessage: getNoOptionsMessage(suggestion),
          ...(showSearchIcon ? { ValueContainer } : {})
        }}
        filterOption={filterOption}
        formatGroupLabel={formatGroup}
        formatOptionLabel={formatLabel}
        hideSelectedOptions={hideSelectedOptions}
        inputId={name}
        inputValue={inputValue}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isOptionDisabled={isOptionDisabled}
        isSearchable={isSearchable}
        loadingMessage={LoadingMessage}
        menuPlacement="auto"
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        placeholder={placeholder}
        styles={customStyles({
          hasError,
          variant,
          displaySeparator: false,
          isHovered,
          isCompactSize: size === 'compact'
        })}
        theme={customTheme}
        value={getSelectedOption()}
        {...(renderMenuInPortal ? { menuPortalTarget: document.body } : {})}
      />
    </div>
  );

  if (!label) {
    return select;
  }

  return (
    <Label
      additionalInformation={labelTooltip}
      content={label}
      labelPlacement={labelPlacement}
      name={name || 'select'}
      variant={variant}
    >
      {select}
    </Label>
  );
};

export type { Props as SelectProps };

export default Select;
