import type { FC } from 'react';

import { ModeOfTransport } from '@zen/Booking';
import { CargoModeEnum } from '@zen/Cargo';
import { FormLabel } from '@zen/Components';
import CargoModeSelect from '@zen/Components/CargoModeSelect';
import ModeOfTransportSelect from '@zen/Components/ModeOfTransportSelect';
import NetworkContactSelect from '@zen/Components/NetworkContactSelect';
import type { DateRange } from '@zen/DesignSystem';
import { Container, DatePickerInput } from '@zen/DesignSystem';
import useZencargoNetwork from '@zen/Networks/hooks/useZencargoNetwork';
import type { RateCardInputUpdatePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';
import { cargoModeLabel } from '@zen/types';
import { getToday } from '@zen/utils/date';
import { add, compareDate } from '@zen/utils/dateTime';
import type { Nullable } from '@zen/utils/typescript';

interface Props {
  disabledDateRanges?: DateRange[];
  endDate: string;
  issuedBy?: string;
  onChange: (payload: RateCardInputUpdatePayload) => void;
  startDate: string;
}

const RateCardOverview: FC<Props> = ({ onChange, startDate, endDate, issuedBy, disabledDateRanges }) => {
  const { data } = useZencargoNetwork();
  const zencargoNetworkId = data?.zencargoNetwork?.id;
  const initialStartDate: string = getToday();
  const initialEndDate: string = add(getToday(), { days: 13 });

  const handleStartDateChange = (date: Nullable<string>): void => {
    onChange({ startDate: date || initialStartDate });

    if (date && compareDate(date).isAfter(endDate)) {
      handleEndDateChange(add(date, { days: 1 }));
    }
  };

  const handleEndDateChange = (date: Nullable<string>): void => {
    onChange({ endDate: date || initialEndDate });
  };

  const handleCoordinatorChange = (id: Nullable<string>): void => {
    onChange({ issuedBy: id || '' });
  };

  return (
    <Container title="Rate card overview">
      <div className="grid grid-cols-3 gap-x-6 gap-y-4">
        <div>
          <FormLabel label="Start date" />
          <DatePickerInput
            disabledDates={{ dateRanges: disabledDateRanges }}
            name="startDate"
            onDayChange={handleStartDateChange}
            selectedDate={startDate}
          />
        </div>
        <div>
          <FormLabel label="End date" />
          <DatePickerInput
            disabledDates={{
              dateRanges: disabledDateRanges,
              minDate: startDate
            }}
            name="endDate"
            onDayChange={handleEndDateChange}
            selectedDate={endDate}
          />
        </div>
        <div>
          <FormLabel label="Issued by" />
          <NetworkContactSelect
            accountId={zencargoNetworkId}
            isMulti={false}
            name="issuedBy"
            onChange={handleCoordinatorChange}
            value={issuedBy || null}
          />
        </div>
        <div>
          <FormLabel label="Mode" />
          <ModeOfTransportSelect isDisabled={true} name="modeOfTransport" value={ModeOfTransport.OCEAN} />
        </div>
        <div>
          <FormLabel label={cargoModeLabel} />
          <CargoModeSelect isDisabled={true} name="cargoType" value={CargoModeEnum.FCL} />
        </div>
      </div>
    </Container>
  );
};

export default RateCardOverview;
