import type { FC } from 'react';
import { forwardRef } from 'react';

interface Props {
  handleAxis?: () => void;
  isDragging?: boolean;
}

const ResizeHandle: FC<Props> = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { handleAxis, isDragging, ...restProps } = props;

  return (
    <div
      ref={ref}
      className="absolute top-0 bottom-0 right-0 w-1 cursor-col-resize zen-table-resizer"
      data-component="table-resize-handle"
      data-testid="resize-handle"
      onClick={(e) => e.stopPropagation()}
      {...restProps}
    >
      <span className="cursor-line bg-azure-base w-full h-3 z-30" />

      <span className="line z-30 bg-azure-light" />
    </div>
  );
});

export default ResizeHandle;
