import type { FC } from 'react';

import type { SelectProps } from '@zen/DesignSystem';
import { Select } from '@zen/DesignSystem';

import { multiStopOptions } from './helpers';
import type { MultiStopEnum } from './types';

interface Props extends Omit<SelectProps<MultiStopEnum>, 'options'> {}

const MultiStopSelect: FC<Props> = (props) => {
  return (
    <Select
      {...props}
      isClearable={false}
      isSearchable={false}
      label="Multi-stop booking"
      labelTooltip="A booking with more than one collection or delivery stop"
      options={multiStopOptions}
    />
  );
};

export default MultiStopSelect;
