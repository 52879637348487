import cx from 'classnames';
import { ErrorMessage } from 'formik';
import { get, kebabCase } from 'lodash';
import type { FC } from 'react';
import { useEffect } from 'react';

import useTracking from '@zen/utils/hooks/useTracking';
import type { Optional } from '@zen/utils/typescript';

import { FormTrackingAction, FormTrackingCategory } from '../types';
import { useForm } from '../useForm';

export const buildTestId = (fieldName: string): string => {
  return `form-field-error-${kebabCase(fieldName)}`;
};

interface Props {
  className?: string;
  fieldName: string;
}

const FormError: FC<Props> = ({ className, fieldName }) => {
  const { errors, status } = useForm<{}>();
  const { trackEvent } = useTracking();

  const classNames: string = cx('text-red-dark leading-tight mt-2 text-sm', className);
  const errorMessage: Optional<string> = get(errors, fieldName);

  useEffect(() => {
    if (!errorMessage) return;

    trackEvent({
      category: FormTrackingCategory,
      action: FormTrackingAction.VALIDATION_ERROR,
      label: errorMessage,
      properties: {
        errorMessage,
        fieldName,
        formName: status?.formName || 'unknown'
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  return <ErrorMessage className={classNames} component="div" data-testid={buildTestId(fieldName)} name={fieldName} />;
};

export default FormError;
