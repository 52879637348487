import cx from 'classnames';
import type { ChangeEvent, ForwardedRef, KeyboardEvent, MutableRefObject, ReactElement, ReactNode, Ref } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import Icon from '../Icon';
import type { InputProps } from '../Input';
import Input from '../Input';

type CommonProps = {
  collapsible?: boolean;
  onChange: (value: string) => void;
  onClear?: () => void;
  onSearch?: () => void;
  ref?: Ref<HTMLInputElement>;
  value: string;
} & Omit<InputProps, 'type' | 'iconLeft' | 'iconRight' | 'onChange'>;

type CollapsibleProps = {
  collapsible: true;
  widthClassName: string;
} & Omit<CommonProps, 'collapsible'>;

type Props = CommonProps | CollapsibleProps;

const SearchInput = React.forwardRef<HTMLInputElement, Props>((props, ref: ForwardedRef<HTMLInputElement>) => {
  const { onChange, onClear, onSearch, value, collapsible, widthClassName, ...rest } = props as CollapsibleProps;
  const hasText: boolean = !!value && value.length > 0;
  const [expanded, setExpanded] = useState<boolean>(false);
  const expandedWidth: string = widthClassName || '';
  const inputRef = useRef<HTMLInputElement>();
  const input = (ref || inputRef) as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    setExpanded(hasText);
  }, [hasText]);

  const classNames = cx({
    '!w-0 !p-0 !pl-7 !m-0 border-grey-lighter': !expanded && collapsible,
    [expandedWidth]: expanded && collapsible,
    'transition-[width] transition-[padding] transition-[margin] ease-linear duration-500': collapsible
  });

  const searchIconClassNames = cx(
    {
      'text-grey-dark': !expanded && collapsible
    },
    ' transition-all ease-linear duration-300'
  );
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onSearch) {
      onSearch();
    }
  };

  const handleFocus = () => {
    setExpanded(true);
  };

  const handleBlur = () => {
    if (!hasText) {
      setExpanded(false);
    }
  };

  const clearField = () => {
    onChange('');

    input?.current.focus();
  };

  const renderSearchIcon = (): ReactElement => <Icon className={searchIconClassNames} icon="zicon-search" />;

  const renderClearIcon = (): ReactNode => {
    if (!hasText || !onClear) {
      return null;
    }

    return (
      <Icon
        className="p-3 cursor-pointer font-bold"
        icon="zicon-close"
        onClick={() => {
          clearField();
          onClear();
        }}
      />
    );
  };

  return (
    <Input
      {...rest}
      ref={input}
      className={classNames}
      data-component="search-input"
      data-testid="search-input"
      fullWidth={!collapsible}
      iconLeft={renderSearchIcon()}
      iconRight={renderClearIcon()}
      onBlur={handleBlur}
      onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
      onFocus={handleFocus}
      onKeyPress={handleKeyPress}
      value={value}
    />
  );
});

export type { Props as SearchInputProps };

export default SearchInput;
