import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type RateCardBookingDetailsQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type RateCardBookingDetailsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'modeOfTransport' | 'zencargoReference'> & {
              bookingStage?: GraphQLTypes.Maybe<{ __typename?: 'BookingStage' } & Pick<GraphQLTypes.BookingStage, 'name'>>;
              cargo?: GraphQLTypes.Maybe<{ __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'>>;
              cargoJourneys?: GraphQLTypes.Maybe<
                { __typename?: 'CargoJourneys' } & {
                  journeys: Array<
                    { __typename?: 'CargoJourney' } & {
                      journey?: GraphQLTypes.Maybe<
                        { __typename?: 'Journey' } & {
                          legs?: GraphQLTypes.Maybe<
                            Array<
                              { __typename?: 'JourneyLeg' } & Pick<GraphQLTypes.JourneyLeg, 'type' | 'id'> & {
                                  from: { __typename?: 'JourneyStop' } & {
                                    terminal?: GraphQLTypes.Maybe<
                                      { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'id' | 'label' | 'unlocode'>
                                    >;
                                  };
                                  vehicle: { __typename?: 'JourneyVehicle' } & Pick<GraphQLTypes.JourneyVehicle, 'id'> & {
                                      properties?: GraphQLTypes.Maybe<
                                        Array<
                                          { __typename?: 'JourneyVehicleProperty' } & Pick<
                                            GraphQLTypes.JourneyVehicleProperty,
                                            'name' | 'value'
                                          >
                                        >
                                      >;
                                    };
                                  to: { __typename?: 'JourneyStop' } & {
                                    terminal?: GraphQLTypes.Maybe<
                                      { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'id' | 'label' | 'unlocode'>
                                    >;
                                  };
                                }
                            >
                          >;
                        }
                      >;
                    }
                  >;
                }
              >;
            }
        >
      >
    >;
  };
};

export const RateCardBookingDetailsDocument = /* #__PURE__ */ gql`
  query rateCardBookingDetails($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        bookingStage {
          name
        }
        cargo {
          mode
        }
        cargoJourneys {
          journeys {
            journey {
              legs {
                from {
                  terminal {
                    id
                    label
                    unlocode
                  }
                }
                type
                vehicle {
                  id
                  properties {
                    name
                    value
                  }
                }
                id
                to {
                  terminal {
                    id
                    label
                    unlocode
                  }
                }
              }
            }
          }
        }
        modeOfTransport
        zencargoReference
      }
    }
  }
`;

/**
 * __useRateCardBookingDetailsQuery__
 *
 * To run a query within a React component, call `useRateCardBookingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateCardBookingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateCardBookingDetailsQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useRateCardBookingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<RateCardBookingDetailsQueryResult, RateCardBookingDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RateCardBookingDetailsQueryResult, RateCardBookingDetailsQueryVariables>(
    RateCardBookingDetailsDocument,
    options
  );
}
export function useRateCardBookingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RateCardBookingDetailsQueryResult, RateCardBookingDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RateCardBookingDetailsQueryResult, RateCardBookingDetailsQueryVariables>(
    RateCardBookingDetailsDocument,
    options
  );
}
export type RateCardBookingDetailsQueryHookResult = ReturnType<typeof useRateCardBookingDetailsQuery>;
export type RateCardBookingDetailsLazyQueryHookResult = ReturnType<typeof useRateCardBookingDetailsLazyQuery>;
