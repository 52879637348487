import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import type { ModeOfTransport } from '@zen/Booking';
import CargoJourneysSummaryLoadingSkeleton from '@zen/Booking/BookingDetails/components/skeletons/CargoJourneysSummaryLoadingSkeleton';
import QueryHandler from '@zen/Components/QueryHandler';
import VerticalPathProvider from '@zen/Journey/components/VerticalPathProvider';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { Optional } from '@zen/utils/typescript';

import CargoReadyDateMilestone from './CargoReadyDateMilestone';
import CollectionOrDeliveryStop from './CollectionOrDeliveryStop';
import { useCargoJourneysSummaryQuery } from './graphql';
import {
  getCollectionDeliveryLabel,
  getMilestonesByProgressStage,
  hasTerminalAsCollectionOrDelivery,
  isLocationTypeTerminal,
  transformCargos
} from './helpers';
import ScheduledStopPathIndicator from './ScheduledStopPathIndicator';
import SummaryMilestone from './SummaryMilestone';
import type {
  CargoJourneysSummaryData,
  CargoJourneysSummaryMilestone,
  CargoJourneysSummaryType,
  JourneyShippingCargoReadyDateMilestone
} from './types';
import { CargoJourneysSummaryMilestoneProgressStage } from './types';

interface Props {
  bookingModeOfTransport: Optional<ModeOfTransport>;
  zencargoReference: string;
}

const CargoJourneysSummary: FC<Props> = ({ bookingModeOfTransport, zencargoReference }) => {
  const { data, error, loading } = useCargoJourneysSummaryQuery({
    variables: { zencargoReference }
  });

  const location = useLocation();
  const { navigate } = useNavigationHistory();

  return (
    <QueryHandler
      data={data?.bookings?.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<CargoJourneysSummaryLoadingSkeleton />}
    >
      {(cargoJourneysSummaryData: CargoJourneysSummaryData) => {
        const { cargoJourneys, cargoJourneysSummary, customsOnly, networksOrigin, networksDestination } =
          cargoJourneysSummaryData;

        const {
          collectedCargos,
          cargosNotCollected,
          deliveredCargos,
          collectionStops,
          deliveryStops,
          milestones,
          scheduledOriginStop,
          scheduledDestinationStop
        } = cargoJourneysSummary as CargoJourneysSummaryType;

        const cargoReadyDateMilestone = cargoJourneys?.cargoReadyDate;

        const navigateToCargoDetails = (cargoId: string): void => {
          navigate({
            pathname: location.pathname,
            state: { highlightedCargoId: cargoId }
          });
        };

        const hasCollectedCargos = collectedCargos.length > 0;
        const hasDeliveredCargos = deliveredCargos.length > 0;

        const bookingOriginLabel = networksOrigin?.label?.long;
        const bookingDestinationLabel = networksDestination?.label?.long;
        const collectionAddress: string = getCollectionDeliveryLabel(
          bookingOriginLabel,
          customsOnly,
          collectionStops,
          'collection'
        );
        const deliveryAddress: string = getCollectionDeliveryLabel(
          bookingDestinationLabel,
          customsOnly,
          deliveryStops,
          'delivery'
        );

        const hasTerminalAsCollectionStop: boolean = hasTerminalAsCollectionOrDelivery(collectionStops);
        const hasTerminalAsDeliveryStop: boolean = hasTerminalAsCollectionOrDelivery(deliveryStops);
        const shouldRenderCollectionStop: boolean = !hasTerminalAsCollectionStop;
        const shouldRenderDeliveryStop: boolean = !hasTerminalAsDeliveryStop;

        const milestonesAfterCollectionAndBeforeScheduledOriginStop = getMilestonesByProgressStage(
          milestones,
          CargoJourneysSummaryMilestoneProgressStage.COLLECTED_NOT_DEPARTED_FROM_FIRST_SCHEDULED_STOP
        );

        const milestonesAfterScheduledOriginStopAndBeforeScheduledDestinationStop = getMilestonesByProgressStage(
          milestones,
          CargoJourneysSummaryMilestoneProgressStage.DEPARTED_FROM_FIRST_SCHEDULED_STOP_NOT_ARRIVED_AT_LAST_SCHEDULED_STOP
        );

        const milestonesAfterDestinationStopBeforeDelivery = getMilestonesByProgressStage(
          milestones,
          CargoJourneysSummaryMilestoneProgressStage.ARRIVED_AT_LAST_SCHEDULED_STOP_NOT_DELIVERED
        );

        const deliveredMilestones = getMilestonesByProgressStage(
          milestones,
          CargoJourneysSummaryMilestoneProgressStage.DELIVERED
        );

        return (
          <>
            {cargoReadyDateMilestone && (
              <CargoReadyDateMilestone
                bookingModeOfTransport={bookingModeOfTransport}
                cargosNotCollected={transformCargos(cargosNotCollected) || []}
                milestone={cargoReadyDateMilestone as JourneyShippingCargoReadyDateMilestone}
                onCargoButtonClick={navigateToCargoDetails}
                zencargoReference={zencargoReference}
              />
            )}

            <VerticalPathProvider dependencies={[milestones]}>
              {shouldRenderCollectionStop && (
                <CollectionOrDeliveryStop
                  isCompleted={hasCollectedCargos || hasDeliveredCargos}
                  label="Collection"
                  locationAddress={collectionAddress}
                />
              )}
              {milestonesAfterCollectionAndBeforeScheduledOriginStop.map((milestone: CargoJourneysSummaryMilestone) => (
                <SummaryMilestone
                  key={milestone.name}
                  bookingModeOfTransport={bookingModeOfTransport}
                  milestone={milestone}
                  onClick={navigateToCargoDetails}
                />
              ))}
              {scheduledOriginStop && isLocationTypeTerminal(scheduledOriginStop.locationType) && (
                <ScheduledStopPathIndicator
                  bookingModeOfTransport={bookingModeOfTransport}
                  destinationType="origin"
                  highlightDate={milestonesAfterCollectionAndBeforeScheduledOriginStop.length > 0}
                  scheduledStop={scheduledOriginStop}
                  showStopIcon={hasTerminalAsCollectionStop}
                />
              )}
              {milestonesAfterScheduledOriginStopAndBeforeScheduledDestinationStop.map(
                (milestone: CargoJourneysSummaryMilestone) => (
                  <SummaryMilestone
                    key={milestone.name}
                    bookingModeOfTransport={bookingModeOfTransport}
                    milestone={milestone}
                    onClick={navigateToCargoDetails}
                  />
                )
              )}
              {scheduledDestinationStop && isLocationTypeTerminal(scheduledDestinationStop.locationType) && (
                <ScheduledStopPathIndicator
                  bookingModeOfTransport={bookingModeOfTransport}
                  destinationType="destination"
                  highlightDate={milestonesAfterScheduledOriginStopAndBeforeScheduledDestinationStop.length > 0}
                  scheduledStop={scheduledDestinationStop}
                  showStopIcon={hasTerminalAsDeliveryStop}
                />
              )}
              {milestonesAfterDestinationStopBeforeDelivery.map((milestone: CargoJourneysSummaryMilestone) => (
                <SummaryMilestone
                  key={milestone.name}
                  bookingModeOfTransport={bookingModeOfTransport}
                  milestone={milestone}
                  onClick={navigateToCargoDetails}
                />
              ))}
              {shouldRenderDeliveryStop && (
                <CollectionOrDeliveryStop isCompleted={hasDeliveredCargos} label="Delivery" locationAddress={deliveryAddress} />
              )}
              {deliveredMilestones.map((milestone: CargoJourneysSummaryMilestone) => (
                <SummaryMilestone
                  key={milestone.name}
                  bookingModeOfTransport={bookingModeOfTransport}
                  milestone={milestone}
                  onClick={navigateToCargoDetails}
                />
              ))}
            </VerticalPathProvider>
          </>
        );
      }}
    </QueryHandler>
  );
};

export default CargoJourneysSummary;
