import type { FC, ReactNode } from 'react';

import ActivityFeedItemDetails from '@zen/ActivityFeed/components/ActivityFeedItemDetails';
import ActivityFeedItemIcon from '@zen/ActivityFeed/components/ActivityFeedItemIcon';
import ActivityFeedItemWithIconWrapper from '@zen/ActivityFeed/components/ActivityFeedItemWithIconWrapper';
import type { Activity, CargoMetadata } from '@zen/ActivityFeed/types';
import type { CargoType } from '@zen/Cargo';
import CargoTypeImage from '@zen/Cargo/components/CargoTypeImage';
import type { Optional } from '@zen/utils/typescript';

import type { RestrictedActivityFeedItemTypes } from './helpers';
import { activityFeedItemMapping, getCargoTypeForIcon, getSubtitle } from './helpers';

export interface Props {
  activityMetadata: Activity;
}

const CargoItem: FC<Props> = ({ activityMetadata }) => {
  const { createdAt, itemType, user } = activityMetadata;
  const { title, iconName, iconColor } = activityFeedItemMapping[itemType as RestrictedActivityFeedItemTypes];

  const metadata: CargoMetadata = activityMetadata.metadata as CargoMetadata;

  const getBackgroundIcon = (): ReactNode => {
    const cargoType: Optional<CargoType> = getCargoTypeForIcon(metadata);

    if (!cargoType) {
      return null;
    }

    return (
      <div className="absolute z-20 w-48" data-testid="cargo-image" style={{ top: '-7rem', left: '-8.5rem' }}>
        <CargoTypeImage cargoType={cargoType} className="h-20" />
      </div>
    );
  };

  return (
    <ActivityFeedItemWithIconWrapper backgroundIcon={getBackgroundIcon()} className="group">
      <ActivityFeedItemDetails
        createdAt={createdAt}
        icon={<ActivityFeedItemIcon color={iconColor} iconName={iconName} />}
        subtitle={getSubtitle(itemType, metadata)}
        title={title}
        user={user}
      />
    </ActivityFeedItemWithIconWrapper>
  );
};

export default CargoItem;
