import cx from 'classnames';
import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import RolePermissionList from '@zen/Components/RolePermissions/RolePermissionList';
import type { Issue } from '@zen/Issues/types';
import { IssueState } from '@zen/Issues/types';

interface Props {
  className?: string;
  issue: Pick<Issue, 'canUpdate' | 'problemDescription' | 'state' | 'solutionDescription' | 'visibility'>;
}

const IssueDetails: FC<Props> = ({ issue, className }) => {
  const showIssueVisibility: boolean = checkPermission(issue, 'canUpdate'); // no BE permission for canView, basing on edit rights instead
  const { visibility, solutionDescription, problemDescription, state } = issue;
  const labelClassName: string = 'font-bold';
  const problemLabel: string = state === IssueState.RESOLVED ? 'What was the issue?' : 'What is the issue?';
  const solutionLabel: string = state === IssueState.RESOLVED ? 'What action was required?' : 'What action is required?';

  return (
    <div className={cx('text-grey-dark leading-normal', className)}>
      <p className={labelClassName}>{problemLabel}</p>
      <p className="text-sm mb-6 break-words">{problemDescription}</p>

      {solutionDescription && (
        <>
          <p className={labelClassName}>{solutionLabel}</p>
          <p className="text-sm mb-6 break-words">{solutionDescription}</p>
        </>
      )}

      {showIssueVisibility && (
        <>
          <p className={cx(labelClassName, 'mb-2')}>Issues visible to...</p>
          <RolePermissionList className="mb-4" rolePermissions={visibility} />
        </>
      )}
    </div>
  );
};

export default IssueDetails;
