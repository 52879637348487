import moment from 'moment';

import type { PillType } from '@zen/DesignSystem';
import { Pill } from '@zen/DesignSystem';
import { getDateDifference } from '@zen/utils/date';

interface Props {
  className?: string;
  date: string;
  noDifferenceMessage?: string;
  referenceDate: string;
  renderOnTime?: boolean;
}

const DatesDifference = (props: Props) => {
  const { className, date, referenceDate, noDifferenceMessage = 'On time', renderOnTime = true } = props;
  const delayInDays: number = moment(date).diff(referenceDate, 'days');
  const label: string = getDateDifference(delayInDays) || noDifferenceMessage;

  if (!renderOnTime) {
    return null;
  }

  const renderPill = () => {
    const getPillType = (): PillType | undefined => {
      if (delayInDays > 0) {
        return 'problem';
      }

      if (delayInDays < 0) {
        return 'warning';
      }
    };

    return (
      <Pill className={className} type={getPillType()}>
        {label}
      </Pill>
    );
  };

  return (
    <div className={className} data-testid="dates-difference">
      {renderPill()}
    </div>
  );
};

export default DatesDifference;
