import type { FC, ReactElement } from 'react';
import { useEffect } from 'react';

import FormCurrencyInput from '@zen/Components/Form/FormCurrencyInput';
import FormIncotermSelect from '@zen/Components/Form/FormIncotermSelect';
import LabelledValue from '@zen/Components/LabelledValue';
import { Banner } from '@zen/DesignSystem';
import type { PortChargePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';
import {
  formatChargeBasis,
  formatChargeType,
  formatIncoterms,
  formatPort,
  formatUnit
} from '@zen/RateCards/LegacyRateCards/utils/tableDataFormatting';

import { getEditCentralPortChargeFormLabelConfig, getEditCustomPortChargeFormLabelConfig, prepareDefaultPrice } from '../helpers';
import type { PortChargeFormFieldLabelConfig, PortChargeFormLabelConfig, PortChargeFormValues } from '../types';

interface Props {
  initialValues: PortChargePayload;
  labelConfig: PortChargeFormFieldLabelConfig;
  setConfig: (value: PortChargeFormLabelConfig) => void;
  values: PortChargeFormValues;
}

const EditPortChargeFields: FC<Props> = ({ setConfig, labelConfig, initialValues, values }) => {
  const editingCentralCharge: boolean = !!initialValues?.centralPortChargeId;

  useEffect(() => {
    if (editingCentralCharge) {
      setConfig(
        getEditCentralPortChargeFormLabelConfig({
          value: initialValues?.customChargeValue,
          currency: initialValues?.customCurrency
        })
      );
    } else {
      setConfig(getEditCustomPortChargeFormLabelConfig());
    }
  }, [editingCentralCharge, initialValues, setConfig]);

  const renderEditCustomChargeForm = (): ReactElement => {
    return (
      <>
        <div className="grid grid-cols-4 mb-6 gap-x-4" data-testid="form-meta-data">
          <LabelledValue label={labelConfig.chargeType}>{formatChargeType(values.chargeType)}</LabelledValue>
          <LabelledValue label={labelConfig.port}>{formatPort(values.port)}</LabelledValue>
          <LabelledValue label="Charge basis">{formatChargeBasis(values.chargeType)}</LabelledValue>
          <LabelledValue label="Unit">{formatUnit(values.chargeType)}</LabelledValue>
        </div>
        <div className="grid grid-cols-2 gap-x-4">
          <FormIncotermSelect isMulti={true} label={labelConfig.incoterms} name="incoterms" showIncotermsExplanation={false} />
          <FormCurrencyInput label={labelConfig.price} name="price" />
        </div>
      </>
    );
  };

  const renderEditCentralChargeForm = (): ReactElement => {
    return (
      <>
        <div className="mt-2 mb-6">
          <Banner
            icon="zicon-alert"
            message="When you set a custom price, it replaces the default price from the central charges database and is used instead in the rate card."
          />
        </div>
        <div className="grid grid-cols-4 mb-6 gap-x-4" data-testid="form-meta-data">
          <LabelledValue label={labelConfig.chargeType}>{formatChargeType(values.chargeType)}</LabelledValue>
          <LabelledValue label={labelConfig.port}>{formatPort(values.port)}</LabelledValue>
          <LabelledValue label={labelConfig.incoterms}>{formatIncoterms(values.incoterms)}</LabelledValue>
          <LabelledValue label="Unit">{formatUnit(values.chargeType)}</LabelledValue>
        </div>
        <div className="grid grid-cols-2 gap-x-4">
          <FormCurrencyInput label={labelConfig.price} name="price" />
          <FormCurrencyInput
            isDisabled={true}
            label="Default price"
            name="defaultPrice"
            value={prepareDefaultPrice(initialValues)}
          />
        </div>
      </>
    );
  };

  return (
    <div data-testid="edit-port-charge-fields">
      {editingCentralCharge ? renderEditCentralChargeForm() : renderEditCustomChargeForm()}
    </div>
  );
};

export default EditPortChargeFields;
