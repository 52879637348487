import type { FC } from 'react';

import type { TableColumn } from '@zen/DesignSystem';
import { Table } from '@zen/DesignSystem';

import { daysOfWeek } from '../../../AccountForms/helpers';
import type { DayOfWeek } from '../../../AccountForms/types';
import OpenStatusSwitch from '../OpenStatusSwitch';
import TimeInput from '../TimeInput';

type TableData = { day: DayOfWeek };

const columnWidth: string = '25%';
const tableConfig: TableColumn<TableData>[] = [
  {
    key: 'day',
    title: 'Day',
    render: (_: unknown, { day }: TableData) => {
      return <div className="capitalize">{day}</div>;
    },
    sortable: false,
    width: columnWidth,
    resizable: false
  },
  {
    key: 'openStatus',
    title: '',
    render: (_: unknown, { day }: TableData) => {
      return <OpenStatusSwitch day={day} />;
    },
    sortable: false,
    width: columnWidth,
    resizable: false
  },
  {
    title: 'Opens at',
    key: 'startTime',
    render: (_: unknown, { day }: TableData) => {
      return <TimeInput day={day} timeType="start" />;
    },
    sortable: false,
    width: columnWidth,
    resizable: false
  },
  {
    title: 'Closes at',
    key: 'endTime',
    render: (_: unknown, { day }: TableData) => {
      return <TimeInput day={day} timeType="end" />;
    },
    sortable: false,
    width: columnWidth,
    resizable: false
  }
];

const BusinessHoursFieldsTable: FC = () => {
  // The table data needs to consist of objects for the rowKey attribute to work properly
  const daysPreparedForTable: TableData[] = daysOfWeek.map((day: DayOfWeek) => ({ day }));

  return (
    <Table<TableData> className="mt-6" columns={tableConfig} data={daysPreparedForTable} rowKey="day" tableId="business-hours" />
  );
};

export default BusinessHoursFieldsTable;
