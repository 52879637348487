import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import useRole from '@zen/Auth/hooks/useRole';
import useAccountPermissions from '@zen/Auth/useAccountPermissions';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { Role } from '@zen/Networks';
import { NEW_BOOKING_ROUTE_PREFIX } from '@zen/Routes/newBooking';

import BookingButton from './BookingButton';
import BookingRequestButton from './BookingRequestButton';

const BookingButtonContainer: FC = () => {
  const createAccountFlagIsOn: boolean = useFeatureIsOn('create-account');
  const role = useRole();
  const { pathname, search } = useLocation();
  const { check } = useGlobalPermissions();
  const { check: accountCheck } = useAccountPermissions();

  const isBookingPage: boolean = pathname.includes(NEW_BOOKING_ROUTE_PREFIX) || search.includes('booking=true');
  const canCreateBooking: boolean =
    createAccountFlagIsOn && role !== Role.AGENT_FORWARDER ? accountCheck('bookings.canCreate') : check('bookings.canCreate');
  const canRequestBooking: boolean = check('bookings.canRequest');
  const legacyCanRequestBooking: boolean = canRequestBooking && role === Role.MANUFACTURER;

  if (isBookingPage) {
    return null;
  }

  if (canCreateBooking) {
    return <BookingButton canRequestBooking={createAccountFlagIsOn && canRequestBooking} />;
  }

  return legacyCanRequestBooking ? <BookingRequestButton /> : null;
};

export default BookingButtonContainer;
