import { uniqBy } from 'lodash';

import type { Optional } from '@zen/utils/typescript';

import type { ChargeLocationType } from '../components/types';
import type {
  Action,
  CostTrackingLocation,
  FreightPorts,
  RateCardCharge,
  RateCardChargeItemTypesKeys,
  RateCardReducerState,
  UpdateRateCardInput
} from './types';
import { ActionType, Applicability } from './types';

const getStateWithUpdatedRateCardInput = (state: RateCardReducerState, action: UpdateRateCardInput): RateCardReducerState => {
  return {
    ...state,
    ...action.payload
  };
};

export const rateCardReducer = (state: RateCardReducerState, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_CUSTOM_DESTINATION_CHARGE: {
      return getStateWithNewCharge(state, 'destinationCharges', action.payload);
    }
    case ActionType.ADD_CUSTOM_ORIGIN_CHARGE: {
      return getStateWithNewCharge(state, 'originCharges', action.payload);
    }
    case ActionType.ADD_DESTINATION_CHARGES: {
      return getStateWithNewPortCharge(state, 'destinationCharges', action.payload);
    }
    case ActionType.ADD_DESTINATION_HAULAGE_CHARGE: {
      return getStateWithNewCharge(state, 'destinationHaulageCharges', action.payload);
    }
    case ActionType.ADD_FREIGHT_CHARGE: {
      return getStateWithNewCharge(state, 'freightCharges', action.payload);
    }
    case ActionType.ADD_ORIGIN_CHARGES: {
      return getStateWithNewPortCharge(state, 'originCharges', action.payload);
    }
    case ActionType.ADD_ORIGIN_HAULAGE_CHARGE: {
      return getStateWithNewCharge(state, 'originHaulageCharges', action.payload);
    }
    case ActionType.ADD_OTHER_CHARGE: {
      return getStateWithNewCharge(state, 'otherCharges', action.payload);
    }
    case ActionType.DELETE_CUSTOM_DESTINATION_CHARGE: {
      return getStateWithoutCharge(state, 'destinationCharges', action.payload);
    }
    case ActionType.DELETE_CUSTOM_ORIGIN_CHARGE: {
      return getStateWithoutCharge(state, 'originCharges', action.payload);
    }
    case ActionType.DELETE_DESTINATION_HAULAGE_CHARGE: {
      return getStateWithoutCharge(state, 'destinationHaulageCharges', action.payload);
    }
    case ActionType.DELETE_FREIGHT_CHARGE: {
      return getStateWithoutCharge(state, 'freightCharges', action.payload);
    }
    case ActionType.DELETE_ORIGIN_HAULAGE_CHARGE: {
      return getStateWithoutCharge(state, 'originHaulageCharges', action.payload);
    }
    case ActionType.DELETE_OTHER_CHARGE: {
      return getStateWithoutCharge(state, 'otherCharges', action.payload);
    }
    case ActionType.DISABLE_DESTINATION_CHARGE: {
      return getStateWithDisabledPortCharges(state, 'destinationCharges', action.payload.id);
    }
    case ActionType.DISABLE_ORIGIN_CHARGE: {
      return getStateWithDisabledPortCharges(state, 'originCharges', action.payload.id);
    }
    case ActionType.ENABLE_DESTINATION_CHARGE: {
      return getStateWithEnabledPortCharges(state, 'destinationCharges', action.payload.id);
    }
    case ActionType.ENABLE_ORIGIN_CHARGE: {
      return getStateWithEnabledPortCharges(state, 'originCharges', action.payload.id);
    }
    case ActionType.REINITIALIZE: {
      return action.payload;
    }
    case ActionType.UPDATE_DESTINATION_HAULAGE_CHARGE: {
      return getStateWithUpdatedCharge(state, 'destinationHaulageCharges', action.payload);
    }
    case ActionType.UPDATE_DESTINATION_CHARGE: {
      return getStateWithUpdatedCharge(state, 'destinationCharges', action.payload);
    }
    case ActionType.UPDATE_FREIGHT_CHARGE: {
      return getStateWithUpdatedCharge(state, 'freightCharges', action.payload);
    }
    case ActionType.UPDATE_ORIGIN_HAULAGE_CHARGE: {
      return getStateWithUpdatedCharge(state, 'originHaulageCharges', action.payload);
    }
    case ActionType.UPDATE_ORIGIN_CHARGE: {
      return getStateWithUpdatedCharge(state, 'originCharges', action.payload);
    }
    case ActionType.UPDATE_OTHER_CHARGE: {
      return getStateWithUpdatedCharge(state, 'otherCharges', action.payload);
    }
    case ActionType.UPDATE_RATE_CARD_INPUT: {
      return getStateWithUpdatedRateCardInput(state, action);
    }

    default: {
      return state;
    }
  }
};

const getChargesWithNewCharge = (charges: Optional<RateCardCharge[]>, newCharges: RateCardCharge[]): RateCardCharge[] => {
  return [...getChargesArray(charges), ...newCharges];
};

const getChargesArray = (charges: Optional<RateCardCharge[]>): RateCardCharge[] => {
  return [...(charges || [])];
};

export const prepareFreightPorts = (freightCharges: RateCardCharge[]): FreightPorts => {
  const originPorts: CostTrackingLocation[] = [];
  const destinationPorts: CostTrackingLocation[] = [];

  freightCharges.forEach((freightCharge: RateCardCharge) => {
    if (freightCharge.applicability.includes(Applicability.ORIGIN) && freightCharge.fromLocation) {
      originPorts.push(freightCharge.fromLocation);
    }

    if (freightCharge.applicability.includes(Applicability.DESTINATION) && freightCharge.toLocation) {
      destinationPorts.push(freightCharge.toLocation);
    }
  });

  return {
    originPorts: uniqBy(originPorts, 'id'),
    destinationPorts: uniqBy(destinationPorts, 'id')
  };
};

const filterByPort = (
  charge: RateCardCharge,
  ports: CostTrackingLocation[],
  locationKey: ChargeLocationType = 'fromLocation'
): boolean => {
  const portKeys: string[] = ports.map((port: CostTrackingLocation) => port.id || '');

  return portKeys.includes(charge[locationKey]?.id || '');
};

const getStateWithUpdatedCharges = (state: RateCardReducerState): RateCardReducerState => {
  const { destinationPorts, originPorts } = prepareFreightPorts(state.freightCharges);

  return {
    ...state,
    originHaulageCharges: state.originHaulageCharges.filter((originHaulageCharge: RateCardCharge) =>
      filterByPort(originHaulageCharge, originPorts, 'toLocation')
    ),
    destinationHaulageCharges: state.destinationHaulageCharges.filter((destinationHaulageCharge: RateCardCharge) =>
      filterByPort(destinationHaulageCharge, destinationPorts)
    ),
    originCharges: state.originCharges.filter((originCharge: RateCardCharge) => filterByPort(originCharge, originPorts)),
    destinationCharges: state.destinationCharges.filter((destinationCharge: RateCardCharge) =>
      filterByPort(destinationCharge, destinationPorts, 'toLocation')
    )
  };
};

const getStateWithNewPortCharge = (
  state: RateCardReducerState,
  chargeField: RateCardChargeItemTypesKeys,
  payload: RateCardCharge[]
): RateCardReducerState => {
  const stateWithNewCharge: RateCardReducerState = {
    ...state,
    [chargeField]: uniqBy(getChargesWithNewCharge(state[chargeField], payload), 'defaultCharge.id')
  };

  return getStateWithUpdatedCharges(stateWithNewCharge);
};

const getStateWithNewCharge = (
  state: RateCardReducerState,
  chargeField: RateCardChargeItemTypesKeys,
  payload: RateCardCharge[]
): RateCardReducerState => {
  const stateWithNewCharge: RateCardReducerState = {
    ...state,
    [chargeField]: getChargesWithNewCharge(state[chargeField], payload)
  };

  return getStateWithUpdatedCharges(stateWithNewCharge);
};

const getUpdatedCharges = (charges: RateCardCharge[], atIndex: number, value: Partial<RateCardCharge>): RateCardCharge[] => {
  const chargeArray = getChargesArray(charges);

  chargeArray[atIndex] = {
    ...chargeArray[atIndex],
    ...value
  };

  return chargeArray;
};

const getStateWithUpdatedCharge = (
  state: RateCardReducerState,
  chargeField: RateCardChargeItemTypesKeys,
  payload: { atIndex: number; value: Partial<RateCardCharge> }
): RateCardReducerState => {
  const { atIndex, value } = payload;

  const stateWithUpdatedCharge: RateCardReducerState = {
    ...state,
    [chargeField]: getUpdatedCharges(state[chargeField], atIndex, value)
  };

  return getStateWithUpdatedCharges(stateWithUpdatedCharge);
};

const getChargesWithoutChargeAtIndex = (charges: Optional<RateCardCharge[]>, atIndex: number): RateCardCharge[] => {
  return getChargesArray(charges).filter((_, index: number) => index !== atIndex);
};

const getStateWithoutCharge = (
  state: RateCardReducerState,
  chargeField: RateCardChargeItemTypesKeys,
  payload: { atIndex: number }
): RateCardReducerState => {
  const { atIndex } = payload;

  const stateWithoutCharge: RateCardReducerState = {
    ...state,
    [chargeField]: getChargesWithoutChargeAtIndex(state[chargeField], atIndex)
  };

  return getStateWithUpdatedCharges(stateWithoutCharge);
};

const markPortChargeDisabled = (portCharge: RateCardCharge): RateCardCharge => {
  return { ...portCharge, defaultChargeHidden: true };
};

const markPortChargeEnabled = (portCharge: RateCardCharge): RateCardCharge => {
  return { ...portCharge, defaultChargeHidden: false };
};

const getStateWithDisabledPortCharges = (
  state: RateCardReducerState,
  chargeField: 'originCharges' | 'destinationCharges',
  id: string
): RateCardReducerState => {
  const newPortCharges: RateCardCharge[] = state[chargeField].map((portCharge: RateCardCharge) => {
    return id === portCharge.defaultCharge?.id ? markPortChargeDisabled(portCharge) : portCharge;
  });

  return {
    ...state,
    [chargeField]: newPortCharges
  };
};

const getStateWithEnabledPortCharges = (
  state: RateCardReducerState,
  chargeField: 'originCharges' | 'destinationCharges',
  id: string
): RateCardReducerState => {
  const newPortCharges: RateCardCharge[] = state[chargeField].map((portCharge: RateCardCharge) => {
    return id === portCharge.defaultCharge?.id ? markPortChargeEnabled(portCharge) : portCharge;
  });

  return {
    ...state,
    [chargeField]: newPortCharges
  };
};
