import cx from 'classnames';
import { isNil } from 'lodash';
import type { InputHTMLAttributes, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';

import HelperText from '@zen/Components/HelperText';

import { useInputStyles } from '../hooks/useInputStyles';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'size' | 'required'> {
  className?: string;
  error?: boolean;
  fullWidth?: boolean;
  hasPrefix?: boolean;
  hasSuffix?: boolean;
  helperText?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  inline?: boolean;
  locked?: boolean;
  ref?: Ref<HTMLInputElement>;
  size?: 'default' | 'compact';
  value: string | number | null;
  variant?: 'default' | 'inline';
}

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    disabled,
    error,
    fullWidth = true,
    hasPrefix,
    hasSuffix,
    helperText,
    iconLeft,
    iconRight,
    inline = false,
    locked,
    name,
    onBlur,
    onChange,
    onKeyPress,
    placeholder,
    size = 'default',
    type = 'text',
    value = '',
    variant = 'default',
    ...rest
  } = props;

  const inputValue: string | number = isNil(value) ? '' : value;
  const inputClassNames: string = useInputStyles({
    disabled,
    error,
    inline
  });
  const classNames: string = cx(
    inputClassNames,
    {
      'pl-8': !!iconLeft,
      'pr-12': !!iconRight,
      'rounded-l-none border-l-0': hasPrefix,
      'rounded-r-none border-r-0': hasSuffix,
      'w-full': fullWidth,
      'h-10': size === 'default',
      'text-right': type === 'number',
      'h-8 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none':
        size === 'compact',
      'border-none bg-transparent hover:bg-grey-lighter hover:placeholder:text-grey-base': variant === 'inline'
    },
    'flex items-center px-3',
    className
  );
  const isDisabled = disabled || locked;

  const iconClassNames: string = cx(
    {
      'text-grey-light': isDisabled,
      'text-grey-base hover:text-grey-dark': !isDisabled
    },
    'absolute flex items-center justify-center'
  );

  return (
    <>
      <div className="relative flex items-center">
        {iconLeft && (
          <div className={`left-2 pointer-events-none ${iconClassNames}`} data-testid="icon-left">
            {iconLeft}
          </div>
        )}
        <input
          data-component="input"
          {...rest}
          ref={ref}
          className={classNames}
          disabled={isDisabled}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          type={type}
          value={inputValue}
        />
        {iconRight && (
          <div className={`right-2 ${iconClassNames}`} data-testid="icon-right">
            {iconRight}
          </div>
        )}
      </div>
      <HelperText helperText={helperText} />
    </>
  );
});

export type { Props as InputProps };

export default Input;
