import { deburr, sortBy } from 'lodash';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';

import type { Option } from '@zen/DesignSystem';
import { Select } from '@zen/DesignSystem';
import type { CountryCode } from '@zen/Networks';
import type { Nullable } from '@zen/utils/typescript';

import { useCountriesQuery } from './graphql';
import type { Country } from './types';

interface Props {
  className?: string;
  disabled?: boolean;
  formatLabel?: (option: Option<CountryCode>) => ReactNode;
  generateCountriesOptions?: (countries: Country[]) => Option<CountryCode>[];
  hasError?: boolean;
  name: string;
  onChange: (code: CountryCode, country: string) => void;
  placeholder?: string;
  value?: CountryCode;
}

const CountrySelect: FC<Props> = ({
  name,
  disabled,
  onChange,
  value,
  formatLabel,
  placeholder,
  hasError,
  className,
  generateCountriesOptions
}) => {
  const { data } = useCountriesQuery({ fetchPolicy: 'cache-and-network' });

  const handleChange = (code: Nullable<CountryCode>): void => {
    if (code) {
      const selectedCountry: string = options.find((option: Option<CountryCode>) => option.value === code)?.label || '';

      onChange(code, selectedCountry);
    }
  };

  const options: Option<CountryCode>[] = useMemo(() => {
    const countries: Country[] = data?.countries || [];

    const countriesOptions =
      generateCountriesOptions?.(countries) ||
      countries.map((country: Country) => ({ label: country.name, value: country.code }));

    return sortBy<Option<CountryCode>>(countriesOptions, (option: Option<CountryCode>) =>
      deburr(option.label as string).toLowerCase()
    );
  }, [data, generateCountriesOptions]);

  return (
    <Select
      className={className}
      formatOptionLabel={formatLabel}
      hasError={hasError}
      isDisabled={disabled}
      isSearchable={true}
      name={name}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      renderMenuInPortal={true}
      value={value}
    />
  );
};

export default CountrySelect;
