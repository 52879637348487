import type { FC } from 'react';
import { useState } from 'react';

import type { PermittedParties } from '@zen/ActivityFeed/types';
import { Role } from '@zen/ActivityFeed/types';
import CollapsibleElement from '@zen/Components/CollapsibleElement';
import ChevronIcon from '@zen/Components/Icons/ChevronIcon';

import PermissionControls from './PermissionControls';
import type { PermissionsInput } from './types';

interface Props {
  onPermissionChange: (key: keyof PermissionsInput, permissionValue: boolean) => void;
  permissions: PermissionsInput;
  permittedParties: PermittedParties;
  userRole: Role;
}

const ActivityFeedInputPermissions: FC<Props> = ({ permittedParties, permissions, onPermissionChange, userRole }) => {
  const { agentForwarder, customerUser, manufacturer, forwarder } = permittedParties;
  const [showPermittedPartySettings, setShowPermittedParties] = useState<boolean>(false);
  const [permitAll, setPermitAll] = useState<boolean>(false);

  const getSharedParties = (): string[] => {
    const sharedParties: string[] = [];

    const shouldAddCustomerToList: boolean =
      (userRole !== Role.CUSTOMER_USER && customerUser && permissions.customerUser) || false;
    const shouldAddManufacturerToList: boolean =
      (userRole !== Role.MANUFACTURER && manufacturer?.length && permissions.manufacturer) || false;

    if (shouldAddCustomerToList) {
      sharedParties.push('customer');
    }

    if (shouldAddManufacturerToList) {
      sharedParties.push('manufacturer');
    }

    return sharedParties;
  };

  const generateSharedPartiesLabel = (): string => {
    if (permitAll) {
      return 'everyone';
    }

    const sharedParties: string[] = getSharedParties();

    if (forwarder && !sharedParties.length) {
      return forwarder.organisationName;
    }
    const lastPermittedParty: string = sharedParties.pop() || '';

    return sharedParties.length >= 1 ? `${sharedParties.join(', ')} & ${lastPermittedParty}` : lastPermittedParty;
  };

  const handlePermitAll = (isChecked: boolean): void => {
    setPermitAll(isChecked);
    if (customerUser) {
      onPermissionChange('customerUser', isChecked);
    }
    if (agentForwarder) {
      onPermissionChange('agentForwarder', isChecked);
    }
    if (manufacturer) {
      onPermissionChange('manufacturer', isChecked);
    }
  };

  return (
    <>
      <CollapsibleElement duration={500} isOpened={showPermittedPartySettings}>
        <div>
          <div
            className="flex items-center justify-between p-6 border-b border-solid rounded cursor-pointer border-grey-lighter bg-grey-lightest"
            onClick={() => setShowPermittedParties(false)}
          >
            <div className="text-lg font-bold">Who will see my message?</div>
            <div className="pl-5">
              <ChevronIcon expanded={showPermittedPartySettings} />
            </div>
          </div>

          <PermissionControls
            onPermissionChange={onPermissionChange}
            onPermitAll={handlePermitAll}
            permissions={permissions}
            permitAll={permitAll}
            permittedParties={permittedParties}
          />
        </div>
      </CollapsibleElement>

      <div className="flex items-center justify-between px-6 py-5 border-t border-solid rounded-b border-grey-lighter bg-grey-lightest">
        <div className="flex items-center">
          Sharing with&nbsp;
          <span
            className="font-bold cursor-pointer text-navy-base hover:underline"
            data-testid="parties-label"
            onClick={() => setShowPermittedParties(!showPermittedPartySettings)}
          >
            {generateSharedPartiesLabel()}
          </span>
        </div>
      </div>
    </>
  );
};

export default ActivityFeedInputPermissions;
