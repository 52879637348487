import { cloneDeep } from 'lodash';

import type { Activity } from '@zen/ActivityFeed';
import { ActivityFeedItemTypeEnum } from '@zen/ActivityFeed';
import { isItemWithMetadata } from '@zen/ActivityFeed/helpers/activityFeedHelper';

import type { OrderActivityFeedItemsQueryResult } from '../graphql';
import type { ActivityFeedDataState, OrderActivityFeedItemData } from '../types';

type ActivityFeedItemsIds = {
  textMessagesIds: string[];
};

export function prepareActivityItemsIds(activityFeedData: Activity[]): ActivityFeedItemsIds {
  const activityFeedItemsIds = {
    textMessagesIds: []
  };

  const arrayMatcher = {
    [ActivityFeedItemTypeEnum.TEXT_MESSAGE]: activityFeedItemsIds.textMessagesIds,
    [ActivityFeedItemTypeEnum.ZENSEI_TEXT_MESSAGE]: activityFeedItemsIds.textMessagesIds
  };

  activityFeedData.forEach((activityFeed: Activity) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (isItemWithMetadata(activityFeed.itemType) || !arrayMatcher[activityFeed.itemType]) return;

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const arrayToPush = arrayMatcher[activityFeed.itemType];

    arrayToPush.push(activityFeed.itemId);
  });

  return activityFeedItemsIds;
}

export function mergeItemsData(
  state: ActivityFeedDataState,
  update: OrderActivityFeedItemsQueryResult,
  activityFeedItems: Activity[]
): ActivityFeedDataState {
  const newState: ActivityFeedDataState = cloneDeep(state);

  // @ts-expect-error ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
  const updateState = (collection: OrderActivityFeedItemData[]): ActivityFeedDataState => {
    if (!collection) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Activi... Remove this comment to see the full error message
      return;
    }

    collection.forEach((item: OrderActivityFeedItemData): void => {
      const activityItems: Activity[] = activityFeedItems.filter((activity: Activity) => activity.itemId === item.id);

      activityItems.forEach((activity: Activity) => {
        newState[activity.id] = item;
      });
    });
  };

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Maybe<({ __typename?: "TextMessa... Remove this comment to see the full error message
  updateState(update.activityFeed.textMessages);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Maybe<Maybe<{ __typename?: "Purc... Remove this comment to see the full error message
  updateState(update.purchaseOrders.nodes);

  return newState;
}
