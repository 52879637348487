import type { Option } from '@zen/DesignSystem';

import type { ContractIdType } from './types';
import { ContractType } from './types';

export const contractTypeMapping: Record<ContractType, string> = {
  [ContractType.BLOCK_SPACE]: 'Block space',
  [ContractType.CUSTOMER_OWNED]: 'Customer-owned',
  [ContractType.FAK]: 'FAK (short term)',
  [ContractType.INDEX_LINKED]: 'Index linked',
  [ContractType.NAC]: 'NAC (long term)',
  [ContractType.NAC_BASKET_LONG_TERM]: 'NAC basket (long term)',
  [ContractType.NAC_BASKET_SHORT_TERM]: 'NAC basket (short term)',
  [ContractType.PREPAID]: 'Prepaid',
  [ContractType.SPOT]: 'Spot'
};

export const contractTypeOptions: Option<ContractType>[] = Object.entries(contractTypeMapping).map(([value, label]) => ({
  value: value as ContractType,
  label
}));

export const getContractTypeLabel = (contractIdType: ContractIdType): string =>
  (contractIdType?.contractType && contractTypeMapping[contractIdType?.contractType]) || '';
