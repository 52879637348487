import { type FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetAccountBusinessUnitsQuery } from '@zen/Accounts/AccountDetails/graphql';
import CreateEditModal from '@zen/Components/EditCreateModal';
import type { CreateEditModalConfiguration } from '@zen/Components/EditCreateModal/types';
import SecretsModal from '@zen/Components/SecretsModal';
import { Button, Table } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import CreateWebhook from './CreateWebhook';
import EditWebhook from './EditWebhook';
import { useDeleteWebhookMutation, useGetWebhooksQuery } from './graphql';
import { getColumns } from './tableConfiguration';
import type { Webhook } from './types';

interface Props {
  accountId: string;
}

const Webhooks: FC<Props> = ({ accountId }) => {
  const { addError, addSuccess } = useNotification();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const { data: businessUnitsData, loading: businessUnitsLoading } = useGetAccountBusinessUnitsQuery({
    variables: { accountId, first: 20 }
  });

  const businessUnitIds: string[] = (businessUnitsData?.accountBusinessUnits?.nodes || []).map(
    (businessUnit) => businessUnit?.id || ''
  );
  const accountHasBusinessUnits: boolean = businessUnitIds.length > 0;

  const [modalConfiguration, setModalConfiguration] = useState<CreateEditModalConfiguration<Webhook>>();

  const secretToken: Optional<string> = state?.secretToken;

  const { data, loading, error } = useGetWebhooksQuery({
    variables: {
      accountIds: accountHasBusinessUnits ? businessUnitIds : [accountId]
    },
    skip: businessUnitsLoading
  });

  const [deleteWebhook] = useDeleteWebhookMutation({});

  const webhooks: Webhook[] = data?.webhookSubscriptionsWebhooks || [];

  const handleEdit = (webhook: Webhook): void => {
    setModalConfiguration({
      type: 'edit',
      initialValues: webhook,
      title: 'Edit Webhook'
    });
  };

  const handleCreate = (): void => {
    setModalConfiguration({
      type: 'create',
      title: 'Create Webhook'
    });
  };

  const handleCloseModal = (): void => setModalConfiguration(undefined);

  const handleCloseSecretModal = (): void => navigate(pathname, { replace: true });

  const handleDelete = async (webhookId: string): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        deleteWebhook({
          variables: {
            input: {
              webhookId
            }
          },
          refetchQueries: ['getWebhooks'],
          awaitRefetchQueries: true
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Webhook has been deleted.');
      }
    });
  };

  return (
    <div data-testid="webhooks">
      <Table
        actions={
          <Button onClick={handleCreate} size="compact">
            Create Webhook
          </Button>
        }
        columns={getColumns({ accountHasBusinessUnits, onEdit: handleEdit, onDelete: handleDelete })}
        data={webhooks}
        error={!!error}
        loading={loading}
        tableId="webhooks"
        title="Webhooks"
        totalCountConfig={{ totalCount: webhooks.length, entityName: 'Webhook' }}
      />
      <CreateEditModal
        configuration={modalConfiguration}
        createForm={({ onClose }) => (
          <CreateWebhook accountHasBusinessUnits={accountHasBusinessUnits} accountId={accountId} onClose={onClose} />
        )}
        editForm={({ onClose, item }) => <EditWebhook accountId={accountId} item={item} onClose={onClose} />}
        onClose={handleCloseModal}
      />
      {secretToken && <SecretsModal isOpened={!!secretToken} onClose={handleCloseSecretModal} secret={secretToken} />}
    </div>
  );
};

export default Webhooks;
