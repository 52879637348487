import type { FC } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import type { Option } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import IncotermSelect from '../../IncotermSelect';
import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import type { IncotermsValue } from './types';

interface Props extends FormFieldProps {
  isClearable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  renderMenuInPortal?: boolean;
  showIncotermsExplanation?: boolean;
}

const FormIncotermSelect: FC<Props> = ({ isMulti, showIncotermsExplanation = true, renderMenuInPortal, className, ...rest }) => {
  return (
    <FormField {...rest}>
      {(
        field: { error: boolean; name: string; options: Option<IncotermsValue>[]; value: IncotermsValue },
        form: FormInstance<unknown>
      ) => (
        <IncotermSelect
          className={className}
          isMulti={isMulti}
          onBlur={form.handleBlur}
          renderMenuInPortal={renderMenuInPortal}
          {...field}
          hasError={field.error}
          onChange={(value: Nullable<IncotermsValue>) => {
            form.setFieldValue(field.name, value || null);
          }}
          showIncotermsExplanation={showIncotermsExplanation}
          value={field.value}
        />
      )}
    </FormField>
  );
};

export default FormIncotermSelect;
