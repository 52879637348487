import type { QueryResult } from '@apollo/client';

import type { DeepNonNullable } from '../typescript';
import type { CustomersQueryResult } from './graphql';
import { useCustomersQuery } from './graphql';

export type Customer = Omit<DeepNonNullable<CustomersQueryResult, 'nodes'>['customers']['nodes'], '__typename'>;

interface CustomersResponse extends QueryResult {
  data: Customer[];
}

const useCustomers = (): CustomersResponse => {
  const { data, ...rest } = useCustomersQuery();

  return {
    ...rest,
    data: (data?.customers?.nodes || []) as Customer[]
  };
};

export default useCustomers;
