import { CargoOptions } from '@zen/Cargo';
import type { TableColumn } from '@zen/DesignSystem';
import { ContextMenu } from '@zen/DesignSystem';
import type { ContainerChargePayload, FreightChargePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';
import {
  formatChargeType,
  formatContainerType,
  formatCost,
  formatIncoterms,
  formatPort
} from '@zen/RateCards/LegacyRateCards/utils/tableDataFormatting';
import type { ContainerTypeEnum } from '@zen/types';

type FreightChargeTableAction = {
  onDelete?: (index: number) => void;
  onUpdate?: (index: number, payload: FreightChargePayload) => void;
};

export const getFreightChargeTableColumns = (
  unitTypes: ContainerTypeEnum[],
  shouldRenderCharacteristics: boolean,
  { onDelete, onUpdate }: FreightChargeTableAction = {},
  isEditable: boolean = false
): TableColumn<FreightChargePayload>[] => [
  {
    key: 'originPort',
    title: 'Port of origin',
    render: (_, { originPort }: FreightChargePayload) => {
      return formatPort(originPort);
    }
  },
  {
    key: 'destinationPort',
    title: 'Port of destination',
    render: (_, { destinationPort }: FreightChargePayload) => {
      return formatPort(destinationPort);
    }
  },
  {
    key: 'chargeType',
    title: 'Charge type',
    render: (_, { chargeType }: FreightChargePayload) => {
      return formatChargeType(chargeType);
    }
  },
  {
    key: 'incoterms',
    title: 'Incoterms',
    render: (_, { incoterms }: FreightChargePayload) => {
      return formatIncoterms(incoterms);
    }
  },
  ...(shouldRenderCharacteristics
    ? [
        {
          key: 'characteristics',
          title: 'Characteristics',
          render: (_: unknown, { chargeType }: FreightChargePayload) => {
            return (
              <CargoOptions
                hazardous={chargeType.hazardous}
                overweight={chargeType.overweight}
                refrigerated={chargeType.reefer}
              />
            );
          }
        }
      ]
    : []),
  ...[...unitTypes.sort()].map(
    (unitType: ContainerTypeEnum): TableColumn<FreightChargePayload> => ({
      alignment: 'right',
      key: unitType,
      title: formatContainerType(unitType),
      sortable: false,
      render: (_: unknown, { containerCharges }: FreightChargePayload) => {
        const containerCharge = containerCharges.find((value: ContainerChargePayload) => value.unitType === unitType);

        return formatCost(containerCharge?.chargeValue, containerCharge?.currency);
      }
    })
  ),
  ...(isEditable
    ? [
        {
          title: '',
          key: 'actions',
          render: (_: unknown, item: FreightChargePayload, index: number) => (
            <ContextMenu
              inline={false}
              items={[
                { label: 'Update', icon: 'zicon-edit', onClick: () => onUpdate?.(index, item) },
                { label: 'Delete', icon: 'zicon-trash', onClick: () => onDelete?.(index) }
              ]}
            />
          ),
          sortable: false
        }
      ]
    : [])
];
