import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AccountNameQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type AccountNameQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<{ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'tradingName'>>;
  legacyAccount?: GraphQLTypes.Maybe<{ __typename?: 'LegacyAccount' } & Pick<GraphQLTypes.LegacyAccount, 'name'>>;
};

export const AccountNameDocument = /* #__PURE__ */ gql`
  query accountName($id: String!) {
    account(accountId: $id) {
      tradingName
    }
    legacyAccount(uuid: $id) {
      name
    }
  }
`;

/**
 * __useAccountNameQuery__
 *
 * To run a query within a React component, call `useAccountNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountNameQuery(baseOptions: Apollo.QueryHookOptions<AccountNameQueryResult, AccountNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AccountNameQueryResult, AccountNameQueryVariables>(AccountNameDocument, options);
}
export function useAccountNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountNameQueryResult, AccountNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AccountNameQueryResult, AccountNameQueryVariables>(AccountNameDocument, options);
}
export type AccountNameQueryHookResult = ReturnType<typeof useAccountNameQuery>;
export type AccountNameLazyQueryHookResult = ReturnType<typeof useAccountNameLazyQuery>;
