import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type RateCardQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
  customerId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  originLocations?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  destinationLocations?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  originPorts?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  destinationPorts?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  incoterms?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.IncotermsValue> | GraphQLTypes.IncotermsValue>;
}>;

export type RateCardQueryResult = { __typename?: 'Query' } & {
  legacyGetRateCard?: GraphQLTypes.Maybe<
    { __typename?: 'LegacyRateCard' } & Pick<
      GraphQLTypes.LegacyRateCard,
      'rateCardId' | 'name' | 'startDate' | 'endDate' | 'modeOfTransport' | 'cargoType' | 'note' | 'issuedAt' | 'updatedAt'
    > & {
        freightCharges: Array<
          { __typename?: 'FreightCharge' } & Pick<GraphQLTypes.FreightCharge, 'incoterms'> & {
              chargeType: { __typename?: 'ChargeType' } & Pick<
                GraphQLTypes.ChargeType,
                | 'id'
                | 'name'
                | 'chargeType'
                | 'modeOfTransport'
                | 'chargeBasis'
                | 'vatApplicable'
                | 'twentyFtDv'
                | 'twentyFtHc'
                | 'fortyFtDv'
                | 'fortyFtHc'
                | 'fortyFiveFtHc'
                | 'hazardous'
                | 'reefer'
                | 'overweight'
              >;
              originPort: { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'name' | 'label'>;
              destinationPort: { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'name' | 'label'>;
              containerCharges: Array<
                { __typename?: 'ContainerCharge' } & Pick<GraphQLTypes.ContainerCharge, 'chargeValue' | 'currency' | 'unitType'>
              >;
            }
        >;
        originCharges?: GraphQLTypes.Maybe<
          Array<
            { __typename?: 'PortCharge' } & Pick<
              GraphQLTypes.PortCharge,
              'chargeValue' | 'currency' | 'centralPortChargeId' | 'excluded' | 'incoterms'
            > & {
                chargeType: { __typename?: 'ChargeType' } & Pick<
                  GraphQLTypes.ChargeType,
                  | 'id'
                  | 'name'
                  | 'chargeType'
                  | 'modeOfTransport'
                  | 'chargeBasis'
                  | 'vatApplicable'
                  | 'twentyFtDv'
                  | 'twentyFtHc'
                  | 'fortyFtDv'
                  | 'fortyFtHc'
                  | 'fortyFiveFtHc'
                  | 'hazardous'
                  | 'reefer'
                  | 'overweight'
                >;
                port: { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'name' | 'label'>;
              }
          >
        >;
        destinationCharges?: GraphQLTypes.Maybe<
          Array<
            { __typename?: 'PortCharge' } & Pick<
              GraphQLTypes.PortCharge,
              'chargeValue' | 'currency' | 'centralPortChargeId' | 'excluded' | 'incoterms'
            > & {
                chargeType: { __typename?: 'ChargeType' } & Pick<
                  GraphQLTypes.ChargeType,
                  | 'id'
                  | 'name'
                  | 'chargeType'
                  | 'modeOfTransport'
                  | 'chargeBasis'
                  | 'vatApplicable'
                  | 'twentyFtDv'
                  | 'twentyFtHc'
                  | 'fortyFtDv'
                  | 'fortyFtHc'
                  | 'fortyFiveFtHc'
                  | 'hazardous'
                  | 'reefer'
                  | 'overweight'
                >;
                port: { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'name' | 'label'>;
              }
          >
        >;
        originHaulageCharges?: GraphQLTypes.Maybe<
          Array<
            { __typename?: 'HaulageCharge' } & Pick<GraphQLTypes.HaulageCharge, 'chargeValue' | 'currency' | 'incoterms'> & {
                chargeType: { __typename?: 'ChargeType' } & Pick<
                  GraphQLTypes.ChargeType,
                  | 'id'
                  | 'name'
                  | 'chargeType'
                  | 'modeOfTransport'
                  | 'chargeBasis'
                  | 'vatApplicable'
                  | 'twentyFtDv'
                  | 'twentyFtHc'
                  | 'fortyFtDv'
                  | 'fortyFtHc'
                  | 'fortyFiveFtHc'
                  | 'hazardous'
                  | 'reefer'
                  | 'overweight'
                >;
                port: { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'name' | 'label'>;
                location?: GraphQLTypes.Maybe<
                  { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    }
                >;
                locationNew?: GraphQLTypes.Maybe<
                  { __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    }
                >;
              }
          >
        >;
        destinationHaulageCharges?: GraphQLTypes.Maybe<
          Array<
            { __typename?: 'HaulageCharge' } & Pick<GraphQLTypes.HaulageCharge, 'chargeValue' | 'currency' | 'incoterms'> & {
                chargeType: { __typename?: 'ChargeType' } & Pick<
                  GraphQLTypes.ChargeType,
                  | 'id'
                  | 'name'
                  | 'chargeType'
                  | 'modeOfTransport'
                  | 'chargeBasis'
                  | 'vatApplicable'
                  | 'twentyFtDv'
                  | 'twentyFtHc'
                  | 'fortyFtDv'
                  | 'fortyFtHc'
                  | 'fortyFiveFtHc'
                  | 'hazardous'
                  | 'reefer'
                  | 'overweight'
                >;
                port: { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'name' | 'label'>;
                location?: GraphQLTypes.Maybe<
                  { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    }
                >;
                locationNew?: GraphQLTypes.Maybe<
                  { __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    }
                >;
              }
          >
        >;
        issuedBy?: GraphQLTypes.Maybe<
          { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName' | 'id'>
        >;
      }
  >;
};

export const RateCardDocument = /* #__PURE__ */ gql`
  query rateCard(
    $id: String!
    $customerId: String
    $originLocations: [String!]
    $destinationLocations: [String!]
    $originPorts: [String!]
    $destinationPorts: [String!]
    $incoterms: [IncotermsValue!]
  ) {
    legacyGetRateCard(
      id: $id
      customerId: $customerId
      originLocations: $originLocations
      destinationLocations: $destinationLocations
      originPorts: $originPorts
      destinationPorts: $destinationPorts
      incoterms: $incoterms
    ) {
      rateCardId
      name
      startDate
      endDate
      modeOfTransport
      cargoType
      freightCharges {
        chargeType {
          id
          name
          chargeType
          modeOfTransport
          chargeBasis
          vatApplicable
          twentyFtDv
          twentyFtHc
          fortyFtDv
          fortyFtHc
          fortyFiveFtHc
          hazardous
          reefer
          overweight
        }
        originPort {
          unlocode
          name
          label
        }
        incoterms
        destinationPort {
          unlocode
          name
          label
        }
        containerCharges {
          chargeValue
          currency
          unitType
        }
      }
      originCharges {
        chargeType {
          id
          name
          chargeType
          modeOfTransport
          chargeBasis
          vatApplicable
          twentyFtDv
          twentyFtHc
          fortyFtDv
          fortyFtHc
          fortyFiveFtHc
          hazardous
          reefer
          overweight
        }
        chargeValue
        currency
        centralPortChargeId
        excluded
        incoterms
        port {
          unlocode
          name
          label
        }
      }
      destinationCharges {
        chargeType {
          id
          name
          chargeType
          modeOfTransport
          chargeBasis
          vatApplicable
          twentyFtDv
          twentyFtHc
          fortyFtDv
          fortyFtHc
          fortyFiveFtHc
          hazardous
          reefer
          overweight
        }
        chargeValue
        currency
        centralPortChargeId
        excluded
        incoterms
        port {
          unlocode
          name
          label
        }
      }
      originHaulageCharges {
        chargeType {
          id
          name
          chargeType
          modeOfTransport
          chargeBasis
          vatApplicable
          twentyFtDv
          twentyFtHc
          fortyFtDv
          fortyFtHc
          fortyFiveFtHc
          hazardous
          reefer
          overweight
        }
        chargeValue
        currency
        incoterms
        port {
          unlocode
          name
          label
        }
        location {
          id
          label {
            short
            long
          }
        }
        locationNew {
          id
          label {
            short
            long
          }
        }
      }
      destinationHaulageCharges {
        chargeType {
          id
          name
          chargeType
          modeOfTransport
          chargeBasis
          vatApplicable
          twentyFtDv
          twentyFtHc
          fortyFtDv
          fortyFtHc
          fortyFiveFtHc
          hazardous
          reefer
          overweight
        }
        chargeValue
        currency
        incoterms
        port {
          unlocode
          name
          label
        }
        location {
          id
          label {
            short
            long
          }
        }
        locationNew {
          id
          label {
            short
            long
          }
        }
      }
      note
      issuedBy {
        firstName
        lastName
        id
      }
      issuedAt
      updatedAt
    }
  }
`;

/**
 * __useRateCardQuery__
 *
 * To run a query within a React component, call `useRateCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *      originLocations: // value for 'originLocations'
 *      destinationLocations: // value for 'destinationLocations'
 *      originPorts: // value for 'originPorts'
 *      destinationPorts: // value for 'destinationPorts'
 *      incoterms: // value for 'incoterms'
 *   },
 * });
 */
export function useRateCardQuery(baseOptions: Apollo.QueryHookOptions<RateCardQueryResult, RateCardQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RateCardQueryResult, RateCardQueryVariables>(RateCardDocument, options);
}
export function useRateCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RateCardQueryResult, RateCardQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RateCardQueryResult, RateCardQueryVariables>(RateCardDocument, options);
}
export type RateCardQueryHookResult = ReturnType<typeof useRateCardQuery>;
export type RateCardLazyQueryHookResult = ReturnType<typeof useRateCardLazyQuery>;
