import type { Option } from '@zen/DesignSystem';

import type { CargoOption } from './types';
import { CargoOptionEnum } from './types';

export const cargoOptionsLabelMapping: Record<CargoOptionEnum, string> = {
  [CargoOptionEnum.DRY_STANDARD]: 'Dry standard',
  [CargoOptionEnum.FLAT_RACK]: 'Flat rack',
  [CargoOptionEnum.FORK_LIFT]: 'Fork lift',
  [CargoOptionEnum.GARMENTS_ON_HANGERS]: 'Garments on hangers',
  [CargoOptionEnum.HAZARDOUS]: 'Hazardous',
  [CargoOptionEnum.HEALTH_CERTIFICATE]: 'Health certificate',
  [CargoOptionEnum.OPEN_TOP]: 'Open top',
  [CargoOptionEnum.OVERWEIGHT]: 'Overweight',
  [CargoOptionEnum.REFRIGERATED]: 'Refrigerated',
  [CargoOptionEnum.STACKABLE]: 'Stackable',
  [CargoOptionEnum.TAIL_LIFT]: 'Tail lift'
};

export const getOptions = (list: CargoOption[]): Option<CargoOptionEnum>[] => {
  return list
    .map((cargoOptionItem: CargoOption) => ({
      label: cargoOptionsLabelMapping[cargoOptionItem.name],
      value: cargoOptionItem.name
    }))
    .sort((a: Option<CargoOptionEnum>, b: Option<CargoOptionEnum>) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }

      return 0;
    });
};
