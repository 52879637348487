import type { FC, ReactNode } from 'react';

import { Container } from '@zen/DesignSystem';

import type { FormattedRateCardDetails } from '../types';
import { getOverviewSections } from './helpers';
import type { OverviewSection } from './types';

interface Props {
  rateCard: FormattedRateCardDetails;
}

const RateCardOverviewDetails: FC<Props> = ({ rateCard }) => {
  const overviewSections: OverviewSection[] = getOverviewSections(rateCard);

  const renderOverviewItem = (section: OverviewSection, index: number): ReactNode => {
    return (
      <div key={`overview-section-${index}`}>
        <div className="text-xs text-grey-base">{section.title}</div>
        <div>{section.detail}</div>
      </div>
    );
  };

  return (
    <Container title="Overview">
      <div className="grid grid-cols-6 gap-4">{overviewSections.map(renderOverviewItem)}</div>
    </Container>
  );
};

export default RateCardOverviewDetails;
