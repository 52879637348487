import type { DeepNonNullable } from '@zen/utils/typescript';

import type { LiveGlobalSearchQueryResult } from './graphql';

export { EntityType, SearchableField } from '@zen/graphql/types.generated';

export type GlobalSearchResultItem = DeepNonNullable<
  LiveGlobalSearchQueryResult,
  'searchResult'
>['liveViewsGlobalSearch']['searchResult'] & { rank?: number };

export enum LiveGlobalSearchTrackingAction {
  RESULT_SELECTED = 'RESULT_SELECTED',
  SEARCH = 'SEARCH',
  SEARCH_WITH_NO_RESULT_SELECTED = 'SEARCH_WITH_NO_RESULT_SELECTED'
}

export const LiveGlobalSearchTrackingCategory = 'LiveGlobalSearch';
