import type { FC } from 'react';
import { useContext } from 'react';

import CustomiseTableButton from '@zen/Components/CustomiseTableButton';
import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import type { TableColumn } from '@zen/DesignSystem';
import { useTableConfiguration } from '@zen/DesignSystem';
import Filters from '@zen/Orders/components/Filters';
import type { OrderFilters, OrderTableView } from '@zen/Orders/types';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';

import { getColumns } from '../TableView/ordersTableConfiguration';
import type { OrderListCapabilities } from '../TableView/types';
import SavedFilters from './SavedFilters';

interface Props {
  canViewCustomer: boolean;
  showTableView: boolean;
}

export const OrdersToolbar: FC<Props> = ({ canViewCustomer, showTableView }) => {
  const { isBasketMode } = useContext(BasketContext);
  const { setAppliedFilters } = useAppliedFilters<OrderFilters>();
  const { hiddenColumns, setHiddenColumns } = useTableConfiguration();
  const capabilities: OrderListCapabilities = { canViewCustomer };

  const columns: TableColumn<OrderTableView>[] = getColumns({ capabilities });

  const clearFilters = () => setAppliedFilters({});

  return (
    <div className="flex flex-col mb-6">
      <div className="flex items-center justify-between mb-4">
        <div className="overflow-hidden">
          <div className="overflow-x-scroll scrollbar-hidden">
            <Filters showSearch={isBasketMode} />
          </div>
        </div>
        <div className="flex justify-end">
          {showTableView && (
            <CustomiseTableButton
              columns={columns}
              defaultFilterValue="orderReferenceNumber"
              hiddenColumns={hiddenColumns}
              onChange={setHiddenColumns}
              size="default"
            />
          )}
        </div>
      </div>
      {!isBasketMode && <SavedFilters clearFilters={clearFilters} />}
    </div>
  );
};

export default OrdersToolbar;
