import type { FC } from 'react';
import { useEffect } from 'react';

const StatusPage: FC = () => {
  const scriptUrl: string = 'https://0qrl31drzf3g.statuspage.io/embed/script.js';

  const createScript = (): HTMLScriptElement => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = scriptUrl;
    script.async = true;

    return script;
  };

  useEffect(() => {
    const script: HTMLScriptElement = createScript();

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  });

  return null;
};

export default StatusPage;
