import type { ReactNode } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import { Form, FormButtons, FormInput } from '@zen/Components/Form';
import { Button, Modal } from '@zen/DesignSystem';
import { useSavedFilters } from '@zen/utils/Filtering/contexts/SavedFiltersContext';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { QUICK_FILTERS_CATEGORY, QuickFiltersAction } from '../ShipmentsQuickFilters/types';
import { validationSchema } from './saveQuickFiltersForm.validation';

interface FormValues {
  name: string;
}

interface Props<T extends {}> {
  filters: T;
  onCancel: () => void;
  setShowModal: (showModal: boolean) => void;
  showModal: boolean;
}

const SaveQuickFiltersForm = <T extends {}>({ onCancel, showModal, setShowModal, filters }: Props<T>) => {
  const { addSavedFilter } = useSavedFilters();
  const { trackEvent } = useTracking();

  const onSubmit = ({ name }: FormValues): Promise<IOkOrErrorResult> => {
    trackEvent({
      category: QUICK_FILTERS_CATEGORY,
      action: QuickFiltersAction.ADD_QUICK_FILTER,
      label: name
    });

    addSavedFilter({ name, filters });
    setShowModal(false);

    return Promise.resolve({ ok: true, error: null });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<T>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Submit">
      <Button data-testid="cancel-button" onClick={onCancel} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Name your saved filter">
      <Form
        formButtons={renderFormButtons}
        formName="SaveQuickFiltersForm"
        initialValues={{ name: '' }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <>
            <div className="mb-4">Providing a name for your filter helps to identify it easily.</div>
            <FormInput hideLabel={true} label="Name" name="name" placeholder="Filter name" />
          </>
        )}
      </Form>
    </Modal>
  );
};

export default SaveQuickFiltersForm;
