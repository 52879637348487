import pDebounce from 'p-debounce';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { AsyncSelect, Icon, TextLink } from '@zen/DesignSystem';
import { productRoutes } from '@zen/Routes';
import { SEARCH_DEBOUNCE_DELAY } from '@zen/utils/constants';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Nullable } from '@zen/utils/typescript';

import { useGlobalSearchLazyQuery } from './graphql';
import { prepareResults } from './helpers';
import type { GlobalSearchAutosuggestItem, GlobalSearchResult } from './types';
import { GlobalSearchTrackingAction, GlobalSearchTrackingCategory } from './types';

const GlobalSearch: FC = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const { check } = useGlobalPermissions();
  const [resultCount, setResultCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [globalSearch] = useGlobalSearchLazyQuery();

  const renderFooter = (inputValue: string): ReactNode => {
    const canAccessProductsRoute: boolean = check('products.canAccessRoute');

    const handleClick = (): void => {
      trackEvent({
        action: GlobalSearchTrackingAction.GLOBAL_SEARCH_RESULT_SELECTED,
        category: GlobalSearchTrackingCategory,
        label: `Products|${inputValue}`
      });
    };

    if (canAccessProductsRoute) {
      return (
        <>
          Looking for a product?{' '}
          <TextLink linkTo={{ pathname: productRoutes.index.getUrl(), state: { searchTerm: inputValue } }} onClick={handleClick}>
            Go to the products page
          </TextLink>
        </>
      );
    }
  };

  const getLabel = ({ name, icon, accountName, entityLabel }: GlobalSearchAutosuggestItem): ReactNode => (
    <div className="flex items-center justify-between">
      <div>
        <div className="text-sm text-grey-dark">{name}</div>
        {accountName && <div className="mt-2 text-xs text-grey-base">{accountName}</div>}
      </div>
      <div className="flex text-grey-dark">
        <div className="mr-2 text-sm">in {entityLabel}</div>
        <Icon icon={icon} />
      </div>
    </div>
  );

  const handleChange = (suggestion: Nullable<GlobalSearchAutosuggestItem>): void => {
    if (suggestion) {
      const { entityLabel, url: pathname } = suggestion;

      trackEvent({
        action: GlobalSearchTrackingAction.GLOBAL_SEARCH_RESULT_SELECTED,
        category: GlobalSearchTrackingCategory,
        label: `${entityLabel}|${searchTerm}`,
        value: resultCount,
        properties: {
          entityType: entityLabel,
          resultCount,
          searchTerm
        }
      });

      navigate(pathname, {
        state: {
          searchTerm
        }
      });
    }
  };

  const trackSearchTerm = (searchInputValue: string, totalResults: number): void => {
    trackEvent({
      action: GlobalSearchTrackingAction.GLOBAL_SEARCH_INPUT_CHANGED,
      category: GlobalSearchTrackingCategory,
      label: searchInputValue,
      value: totalResults,
      properties: {
        resultCount: totalResults,
        searchTerm: searchInputValue
      }
    });
  };

  const handleInputChange = async (query: string): Promise<Array<GlobalSearchAutosuggestItem>> => {
    setSearchTerm(query);

    try {
      const { data } = await globalSearch({
        variables: {
          query
        }
      });

      const queryResults: GlobalSearchResult[] = data?.globalSearch || [];

      const totalResultCount: number = queryResults.length;

      setResultCount(totalResultCount);

      trackSearchTerm(query, totalResultCount);

      return prepareResults(queryResults, query);
    } catch {
      return [];
    }
  };

  const debouncedHandleInputChange = pDebounce(handleInputChange, SEARCH_DEBOUNCE_DELAY);

  return (
    <AsyncSelect<GlobalSearchAutosuggestItem>
      cacheOptions={false}
      className="fs-global-search"
      dropdownFooterRenderer={renderFooter}
      formatOptionLabel={getLabel}
      isClearable={false}
      loadOptions={debouncedHandleInputChange}
      onChange={handleChange}
      placeholder="Search shipment refs, Zencargo refs, PO numbers & more..."
      value={null}
    />
  );
};

export default GlobalSearch;
