import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import type { CoreCargo } from '@zen/Cargo';
import { CargoModeEnum, EditFullContainer, EditFullTruck, EditLooseCargo } from '@zen/Cargo';
import { Modal } from '@zen/DesignSystem';
import type { ModeOfTransport } from '@zen/types';

interface Props {
  cargo: CoreCargo;
  cargoMode: CargoModeEnum;
  children: (openModal: () => void) => ReactNode;
  modeOfTransport: ModeOfTransport;
  zencargoReference: string;
}

const EditCargo: FC<Props> = ({ modeOfTransport, cargoMode, cargo, zencargoReference, children }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const openModal = (): void => setIsModalVisible(true);

  const closeModal = (): void => setIsModalVisible(false);

  const renderForm = (): ReactNode => {
    switch (cargoMode) {
      case CargoModeEnum.LOOSE: {
        return <EditLooseCargo cargo={cargo} modeOfTransport={modeOfTransport} onCancel={closeModal} onSuccess={closeModal} />;
      }
      case CargoModeEnum.FCL: {
        return <EditFullContainer cargo={cargo} modeOfTransport={modeOfTransport} onCancel={closeModal} onSuccess={closeModal} />;
      }
      case CargoModeEnum.FTL: {
        return <EditFullTruck cargo={cargo} onCancel={closeModal} onSuccess={closeModal} />;
      }
    }
  };

  return (
    <>
      {children(openModal)}
      <Modal
        closeOnClickAway={false}
        isOpen={isModalVisible}
        modalOverflowY="scroll"
        onClose={closeModal}
        tagline={zencargoReference}
        title="Edit cargo"
      >
        <div className="py-2">{renderForm()}</div>
      </Modal>
    </>
  );
};

export default EditCargo;
