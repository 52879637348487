import qs from 'qs';

import { orderRoutes } from '@zen/Routes';

interface Params {
  onlyCurrentSupplier?: boolean;
  onlyOwnAccount?: boolean;
}

const getOrdersUrl = (params?: Params): { pathname: string; search: string } => {
  return {
    pathname: orderRoutes.orderList.getUrl(),
    search: qs.stringify(
      {
        booking: true,
        ...(params?.onlyOwnAccount ? { onlyOwnAccount: true } : {}),
        ...(params?.onlyCurrentSupplier ? { onlyCurrentSupplier: true } : {})
      },
      { addQueryPrefix: true, encode: false }
    )
  };
};

export { getOrdersUrl };
