import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useLocalStorage } from 'react-use';

import { Role } from '@zen/Auth';
import useRole from '@zen/Auth/hooks/useRole';
import Page from '@zen/Components/Page';
import PageSizeSelector from '@zen/Components/PageSizeSelector';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import type { TableColumn } from '@zen/DesignSystem';
import { Button, Table } from '@zen/DesignSystem';
import { rateCardRoutes } from '@zen/Routes';
import type { SortInput } from '@zen/types';
import { PageSize, SortingOrder } from '@zen/types';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import { useExportRateCard } from './ExportRateCard';
import Filters from './Filters';
import { useRateCardSummaries } from './graphql/hooks';
import RateCardActions from './RateCardActions/RateCardActions';
import { getRateCardsTableConfiguration } from './rateCardsTableConfiguration';
import type { RateCardsFilters, RateCardSummary } from './types';

const initialOrder: SortInput = {
  direction: SortingOrder.DESC,
  field: 'startDate'
};

const RATE_CARDS_PAGE_SIZE_KEY = 'rateCardsTablePageSize';

const RateCards: FC = () => {
  const { navigate } = useNavigationHistory();
  const { appliedFilters } = useAppliedFilters<RateCardsFilters>();
  const [onExport] = useExportRateCard();
  const role = useRole();
  const { date, modeOfTransport, cargoType } = appliedFilters;
  const [order, setOrder] = useState<SortInput>(initialOrder);
  const [pageSize = PageSize.TWENTY, setPageSize] = useLocalStorage(RATE_CARDS_PAGE_SIZE_KEY, PageSize.TWENTY);
  const { accountUuid } = useAccount();
  const allowEdit: boolean = role === Role.ADMIN;
  const { nodes, loading, error, totalCount, paginationInfo, refetch } = useRateCardSummaries(
    {
      customerDivisionId: accountUuid,
      validOn: date,
      cargoType,
      modeOfTransport
    },
    pageSize
  );

  const handleNewRateCardClick = (): void => {
    navigate(rateCardRoutes.new.getUrl());
  };

  const handleExport = (id: string) => {
    onExport(id, accountUuid);
  };

  const newRateCardButton: ReactNode = allowEdit ? (
    <Button onClick={handleNewRateCardClick} variant="secondary">
      New rate card
    </Button>
  ) : null;

  return (
    <RateCardActions onDeleteCompleted={refetch}>
      {({ handleClone, handleEdit, handleDelete }) => {
        const columns: TableColumn<RateCardSummary>[] = getRateCardsTableConfiguration(allowEdit, {
          onDelete: handleDelete,
          onEdit: handleEdit,
          onClone: handleClone,
          onExport: handleExport
        });

        return (
          <Page actionButtons={newRateCardButton} tagline="Overview of freight rates and charges." title="Rate cards">
            <div className="flex justify-between mb-6">
              <Filters />
              <PageSizeSelector onChange={setPageSize} pageSize={pageSize} />
            </div>
            <QueryHandler
              data={nodes}
              error={!!error}
              isLoading={loading}
              loadingComponent={<SkeletonTableLoading columns={columns} tableId="rate-cards-table" />}
            >
              {(rateCards: RateCardSummary[]) => (
                <Table
                  columns={columns}
                  data={rateCards}
                  onOrderChange={setOrder}
                  order={order}
                  paginationInfo={paginationInfo}
                  tableId="rateCardsTable"
                  totalCountConfig={{ entityName: 'rate card', totalCount }}
                />
              )}
            </QueryHandler>
          </Page>
        );
      }}
    </RateCardActions>
  );
};

export default RateCards;
