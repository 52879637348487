import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import { Dialog } from '@zen/DesignSystem';

type RenderFunction = ({ requestConfirm }: { requestConfirm: () => void }) => ReactNode;

interface Props {
  cancelLabel?: string;
  children: ReactNode | RenderFunction;
  confirmLabel?: string;
  disabled?: boolean;
  header?: string;
  message: ReactNode;
  onClose?: () => void;
  onConfirm: () => void;
}

const WithConfirmationModal: FC<Props> = ({
  cancelLabel,
  children,
  confirmLabel,
  disabled,
  header,
  message,
  onConfirm,
  onClose
}) => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const requestConfirm = () => {
    setShowConfirmation(true);
  };

  const renderChildren = () => {
    const isRenderFunction = typeof children === 'function';

    if (isRenderFunction) {
      const childrenFunction = children as RenderFunction;

      return childrenFunction({ requestConfirm });
    }

    return <span onClick={requestConfirm}>{children}</span>;
  };

  const handleClose = (): void => {
    setShowConfirmation(false);

    onClose?.();
  };

  const handleConfirm = (): void => {
    setShowConfirmation(false);

    onConfirm();
  };

  return (
    <>
      {renderChildren()}

      <Dialog
        cancelLabel={cancelLabel}
        confirmLabel={confirmLabel}
        disabled={disabled}
        header={header}
        isOpen={showConfirmation}
        message={message}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export type { Props as WithConfirmationModalProps };

export default WithConfirmationModal;
