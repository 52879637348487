import type { FC, ReactElement } from 'react';
import { useState } from 'react';

import { Dialog } from '@zen/DesignSystem';
import { rateCardRoutes } from '@zen/Routes';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import { performMutation } from '@zen/utils/performMutation';

import { useDeleteRateCardMutation } from '../graphql';

interface Props {
  children: (actions: RateCardActionsType) => ReactElement;
  onDeleteCompleted: () => void;
}

type RateCardActionsType = {
  handleClone: (id: string) => void;
  handleDelete: (id: string) => void;
  handleEdit: (id: string) => void;
};

const RateCardActions: FC<Props> = ({ children, onDeleteCompleted }) => {
  const { navigate } = useNavigationHistory();
  const [rateCardIdToDelete, setRateCardIdToDelete] = useState<string>();
  const [deleteRateCard] = useDeleteRateCardMutation();
  const { addError, addSuccess } = useNotification();

  const performDeleteRateCard = async (id: string): Promise<void> => {
    await performMutation({
      mutationFn: () => deleteRateCard({ variables: { input: { id } } }),
      onError: () => addError(),
      onSuccess: handleSuccess
    });
  };

  const handleSuccess = (): void => {
    addSuccess('Rate card removed successfully.');
    handleCloseModal();
    onDeleteCompleted();
  };

  const handleConfirm = async (): Promise<void> => {
    if (rateCardIdToDelete) {
      await performDeleteRateCard(rateCardIdToDelete);
    }
  };

  const handleCloseModal = (): void => {
    setRateCardIdToDelete(undefined);
  };

  const handleDelete = (id: string): void => {
    setRateCardIdToDelete(id);
  };

  const handleEdit = (id: string): void => {
    navigate(rateCardRoutes.edit.getUrl(id));
  };

  const handleClone = (id: string): void => {
    navigate(rateCardRoutes.new.getUrl(id));
  };

  return (
    <>
      {children({ handleClone, handleDelete, handleEdit })}
      <Dialog
        confirmLabel="Delete"
        header="Delete rate card?"
        isOpen={!!rateCardIdToDelete}
        message="This rate card will no longer be available to customer or Zencargo users. Any connections with existing bookings will be removed."
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default RateCardActions;
