import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';
import { useSessionStorage } from 'react-use';

import useAccount from '@zen/utils/hooks/useAccount';

interface FiltersContextValues<T> {
  appliedFilters: T;
  setAppliedFilters: (filters: Partial<T>) => void;
}

const initialContext = {
  appliedFilters: {},
  setAppliedFilters: () => {}
};

interface Props<T> {
  children: ReactNode;
  filterName: string;
  initialFilters?: T;
}

const FiltersContext = createContext<FiltersContextValues<any>>(initialContext);

const useAppliedFilters = <T extends {}>() => useContext<FiltersContextValues<T>>(FiltersContext);

function FiltersContextProvider<T>({ children, initialFilters, filterName }: Props<T>) {
  const { accountUuid } = useAccount();
  const sessionStorageKey = `${accountUuid}-${filterName}`;
  const [appliedFilters, setAppliedFilters] = useSessionStorage<T>(sessionStorageKey, initialFilters);

  return (
    <FiltersContext.Provider
      value={{
        appliedFilters,
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: T) => void' is not assignable to typ... Remove this comment to see the full error message
        setAppliedFilters
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export { FiltersContext, FiltersContextProvider, useAppliedFilters };
