import type { Option } from '@zen/DesignSystem';

import ChipSelect from '../../ChipSelect';
import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import type { FormInstance } from '../types';

interface Props<T> extends FormFieldProps {
  disabled?: boolean;
  options: Option<T>[];
}

const FormChipSelect = <T extends {}>(props: Props<T>) => {
  return (
    <FormField {...props}>
      {(field: { name: string; value: T }, form: FormInstance<unknown>) => (
        <ChipSelect<T>
          disabled={props.disabled}
          onChange={(value) => form.setFieldValue(field.name, value)}
          options={props.options}
          value={field.value}
        />
      )}
    </FormField>
  );
};

export default FormChipSelect;
