import cx from 'classnames';
import type { FieldProps } from 'formik';
import { Field } from 'formik';
import { capitalize, get, has, isEmpty, kebabCase } from 'lodash';
import type { ReactNode } from 'react';

import HelperText from '@zen/Components/HelperText';
import { Tooltip } from '@zen/DesignSystem';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';
import { createTrackingParentAttribute } from '@zen/utils/tracking';

import FormError from '../FormError/FormError';
import FormLabel from '../FormLabel';
import type { FormFieldTooltipConfig } from './types';

export interface FormFieldProps {
  autoFocus?: boolean;
  className?: string;
  errorClassName?: string;
  helperText?: ReactNode;
  hideErrorMessage?: boolean;
  hideLabel?: boolean;
  inputWrapperClassName?: string;
  isOptional?: boolean;
  isRequired?: boolean;
  label?: ReactNode;
  labelDescription?: string;
  name: string;
  noMargin?: boolean;
  placeholder?: string;
  tooltipConfig?: FormFieldTooltipConfig;
  warning?: string;
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'componentProps' implicitly has an 'any'... Remove this comment to see the full error message
const FormField = (componentProps) => {
  const {
    children,
    className = null,
    errorClassName,
    name,
    label = null,
    labelDescription = null,
    hideLabel = false,
    hint,
    warning,
    hideErrorMessage,
    inputWrapperClassName,
    isOptional,
    isRequired,
    noMargin,
    helperText,
    tooltipConfig,
    ...rest
  } = componentProps;

  const renderLabel = (content: ReactNode): ReactNode => (
    <FormLabel
      isOptional={isOptional}
      isRequired={isRequired}
      label={label || capitalize(removeSpecialCharactersAndUpperFirst(name))}
      labelDescription={labelDescription}
      name={name}
    >
      {content}
    </FormLabel>
  );

  return (
    <Field id={name} label={label} name={name}>
      {({ field, form, meta }: FieldProps) => {
        const { touched, errors } = form;
        const fieldName = field.name;
        const hasError = has(touched, fieldName) && has(errors, fieldName) && !isEmpty(get(errors, fieldName));
        const newProps = { ...field, label, error: hasError, ...rest };

        const classNames = cx(
          {
            'mb-4': !noMargin,
            'mb-0': noMargin,
            'js-field-error': hasError
          },
          className,
          'formField'
        );

        const renderField = (): ReactNode => {
          const fieldNode: ReactNode = <div className={inputWrapperClassName}>{children(newProps, form, meta)}</div>;

          if (tooltipConfig) {
            return (
              <Tooltip offset={tooltipConfig.offset} placement={tooltipConfig.placement} tooltipContent={tooltipConfig.content}>
                {fieldNode}
              </Tooltip>
            );
          }

          return fieldNode;
        };

        const fieldContent: ReactNode = (
          <>
            {renderField()}
            {warning && <div className="mt-2 text-sm leading-tight text-orange-base">{warning}</div>}
            {hideErrorMessage || !hasError ? (
              <HelperText helperText={helperText} />
            ) : (
              <FormError className={errorClassName} fieldName={fieldName} />
            )}
          </>
        );

        return (
          <div
            className={classNames}
            data-testid={`form-field-${kebabCase(name)}`}
            {...createTrackingParentAttribute('form-field')}
          >
            {!hideLabel ? renderLabel(fieldContent) : fieldContent}
          </div>
        );
      }}
    </Field>
  );
};

export default FormField;
