import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import type { FormInstance } from '@zen/Components/Form';
import { Form, FormButtons, FormCheckbox, FormInput, FormSelect } from '@zen/Components/Form';
import type { Option } from '@zen/DesignSystem';
import { Button } from '@zen/DesignSystem';
import opsSettingsRoutes from '@zen/Routes/opsSettings';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { ChargeType } from '../types';
import { ChargeBasisEnum, ChargeTypeEnum } from '../types';
import { validationSchema } from './changeTypeForm.validation';

interface Props {
  initialValues?: Omit<ChargeType, 'id'>;
  onSubmit: (chargeType: ChargeType) => Promise<IOkOrErrorResult>;
  onSuccess?: () => void;
  submitButtonText?: string;
}

const chargeOptions: Option<ChargeBasisEnum>[] = [
  { value: ChargeBasisEnum.CONTAINER, label: 'Container' },
  { value: ChargeBasisEnum.CHARGEABLE_WEIGHT, label: 'Chargeable weight' },
  { value: ChargeBasisEnum.BILL, label: 'Bill' },
  { value: ChargeBasisEnum.BOOKING, label: 'Booking' },
  { value: ChargeBasisEnum.PALLET, label: 'Pallet' },
  { value: ChargeBasisEnum.HS_CODE, label: 'HS code' },
  { value: ChargeBasisEnum.DAY, label: 'Day' },
  { value: ChargeBasisEnum.HOUR, label: 'Hour' },
  { value: ChargeBasisEnum.LABEL, label: 'Label' },
  { value: ChargeBasisEnum.TEU, label: 'TEU' },
  { value: ChargeBasisEnum.WEIGHT_MEASURE_OCEAN, label: 'W/M ocean' },
  { value: ChargeBasisEnum.WEIGHT_MEASURE_AIR, label: 'W/M air' },
  { value: ChargeBasisEnum.OTHER, label: 'Other' }
];

const chargeTypeOptions: Option<ChargeTypeEnum>[] = [
  { value: ChargeTypeEnum.DESTINATION, label: 'Destination' },
  { value: ChargeTypeEnum.FREIGHT, label: 'Freight' },
  { value: ChargeTypeEnum.ORIGIN, label: 'Origin' }
];

const ChargeTypeForm: FC<Props> = ({ initialValues, onSubmit, onSuccess, submitButtonText }) => {
  const navigate = useNavigate();

  const renderFields = (): ReactNode => {
    return (
      <div className="mb-8">
        <FormInput name="name" />
        <FormSelect name="chargeType" options={chargeTypeOptions} />
        <FormSelect name="chargeBasis" options={chargeOptions} />
        <div className="my-6 text-sm">Additional details</div>
        <FormCheckbox className="mb-5" label="Hazardous" name="hazardous" />
        <FormCheckbox className="mb-5" label="Reefer" name="reefer" />
        <FormCheckbox className="mb-5" label="Overweight" name="overweight" />
        <FormCheckbox className="mb-5" label="20ft HC" name="twentyFtHc" />
        <FormCheckbox className="mb-5" label="40ft HC" name="fortyFtHc" />
        <FormCheckbox className="mb-5" label="45ft HC" name="fortyFiveFtHc" />
        <FormCheckbox className="mb-5" label="20ft DV" name="twentyFtDv" />
        <FormCheckbox className="mb-5" label="40ft DV" name="fortyFtDv" />
        <FormCheckbox className="mb-5" label="VAT applicable" name="vatApplicable" />
      </div>
    );
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<ChargeType>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} text={submitButtonText}>
      <Button data-testid="cancel-button" onClick={() => navigate(opsSettingsRoutes.chargeTypesIndex.getUrl())} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Form
      className="max-w-xl"
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {() => <>{renderFields()}</>}
    </Form>
  );
};

export default ChargeTypeForm;
