import type { FC, ReactNode } from 'react';

import type { ModeOfTransport } from '@zen/Cargo';
import { CargoModeEnum, NewFullContainer, NewFullTruck, NewLooseCargo } from '@zen/Cargo';
import { Modal } from '@zen/DesignSystem';

interface Props {
  cargoMode: CargoModeEnum;
  isOpen: boolean;
  modeOfTransport: ModeOfTransport;
  onClose: () => void;
  onSuccess?: (cargoId: string) => void;
  zencargoReference: string;
}

const NewCargoModal: FC<Props> = ({ cargoMode, modeOfTransport, isOpen, onClose, onSuccess, zencargoReference }) => {
  const handleSuccess = (cargoId: string): void => {
    onSuccess?.(cargoId);
    onClose();
  };

  const renderForm = (): ReactNode => {
    switch (cargoMode) {
      case CargoModeEnum.LOOSE: {
        return (
          <NewLooseCargo
            modeOfTransport={modeOfTransport}
            onCancel={onClose}
            onSuccess={handleSuccess}
            zencargoReference={zencargoReference}
          />
        );
      }
      case CargoModeEnum.FCL: {
        return (
          <NewFullContainer
            modeOfTransport={modeOfTransport}
            onCancel={onClose}
            onSuccess={handleSuccess}
            zencargoReference={zencargoReference}
          />
        );
      }
      case CargoModeEnum.FTL: {
        return <NewFullTruck onCancel={onClose} onSuccess={handleSuccess} zencargoReference={zencargoReference} />;
      }
    }
  };

  return (
    <Modal
      closeOnClickAway={false}
      isOpen={isOpen}
      modalOverflowY="scroll"
      onClose={onClose}
      tagline={zencargoReference}
      title="Add cargo"
    >
      <div className="py-2" data-testid="new-cargo-modal">
        {renderForm()}
      </div>
    </Modal>
  );
};

export default NewCargoModal;
