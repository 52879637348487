import type { Nullable } from '@zen/utils/typescript';

import type { CargoType } from '../../types';
import {
  CargoFullContainerVariant,
  CargoParam,
  ContainerTypeEnum,
  LooseCargoTypeEnum,
  PalletTypeEnum,
  VehicleTypeEnum
} from '../../types';

export const cargoTypeParamsMapping: Record<CargoType, CargoParam[]> = {
  // FTL
  [VehicleTypeEnum.VAN_BOX]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_3_5_T_BOX]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_7_5_T_BOX]: [CargoParam.healthCertificate, CargoParam.hazardous, CargoParam.tailLift],
  [VehicleTypeEnum.STRAIGHT_TRUCK]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_18_T_BOX]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_13_6_M_BOX]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_53_FT_BOXED]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_3_5_T_CURTAIN_SIDED]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_7_5_T_CURTAIN_SIDED]: [CargoParam.healthCertificate, CargoParam.hazardous, CargoParam.tailLift],
  [VehicleTypeEnum.TRAILER_18_T_CURTAIN_SIDED]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER_CURTAIN_SIDED]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_18_T_REFRIGERATED]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_13_6_M_REFRIGERATED]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_18_T_SLIDING_ROOF]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_13_6_M_SLIDING_ROOF]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_18_T_HIAB]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.TRAILER_13_6_M_HIAB]: [CargoParam.healthCertificate, CargoParam.hazardous],
  [VehicleTypeEnum.VAN_CURTAIN_SIDED]: [],
  [VehicleTypeEnum.VAN_SLIDING_ROOF]: [],
  [VehicleTypeEnum.TRAILER_7_5_T_SLIDING_ROOF]: [],
  // FCL
  [ContainerTypeEnum.X20DV]: [CargoParam.hazardous, CargoParam.overweight],
  [ContainerTypeEnum.X40DV]: [CargoParam.hazardous, CargoParam.overweight],
  [ContainerTypeEnum.X40HC]: [CargoParam.hazardous, CargoParam.overweight],
  [ContainerTypeEnum.X45HC]: [CargoParam.hazardous, CargoParam.overweight],
  [ContainerTypeEnum.X20HC]: [],
  // LOOSE
  [PalletTypeEnum.EURO]: [CargoParam.hazardous, CargoParam.refrigerated, CargoParam.stackable],
  [PalletTypeEnum.OTHER]: [CargoParam.hazardous, CargoParam.refrigerated, CargoParam.stackable],
  [PalletTypeEnum.UK]: [CargoParam.hazardous, CargoParam.refrigerated, CargoParam.stackable],
  [PalletTypeEnum.US]: [CargoParam.hazardous, CargoParam.refrigerated, CargoParam.stackable],
  [LooseCargoTypeEnum.PALLETS]: [CargoParam.hazardous, CargoParam.refrigerated, CargoParam.stackable],
  [LooseCargoTypeEnum.BOXES_OR_CRATES]: [CargoParam.hazardous, CargoParam.refrigerated, CargoParam.stackable],
  [LooseCargoTypeEnum.ASSORTED_CARGO]: [CargoParam.hazardous, CargoParam.refrigerated, CargoParam.stackable]
};

export const getCargoParams = (type: CargoType, variant?: Nullable<CargoFullContainerVariant>): CargoParam[] => {
  const params = cargoTypeParamsMapping[type];
  const garmentsOnHangersContainerTypes: CargoType[] = [
    ContainerTypeEnum.X20DV,
    ContainerTypeEnum.X40DV,
    ContainerTypeEnum.X40HC
  ];
  const shouldAddGarmentsOnHangers = garmentsOnHangersContainerTypes.includes(type) && variant === CargoFullContainerVariant.DRY;

  if (shouldAddGarmentsOnHangers) {
    return [...params, CargoParam.garmentsOnHangers];
  }

  return params;
};
