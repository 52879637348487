import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrderActivityFeedQueryVariables = GraphQLTypes.Exact<{
  orderId: GraphQLTypes.Scalars['String'];
}>;

export type OrderActivityFeedQueryResult = { __typename?: 'Query' } & {
  purchaseOrders: { __typename?: 'PurchaseOrderConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'accountId' | 'id'> & {
              canSendTextMessageToAgentForwarder: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              canSendTextMessageToCustomerUser: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              canSendTextMessageToManufacturer: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              customer?: GraphQLTypes.Maybe<{ __typename?: 'Customer' } & Pick<GraphQLTypes.Customer, 'name' | 'uuid'>>;
              manufacturer?: GraphQLTypes.Maybe<
                | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'tradingName' | 'id'>)
                | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'>)
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLegacyOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksLegacyOrgLocOrganisation,
                          'name'
                        >
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksOrgLocOrganisation,
                          'id' | 'name'
                        >
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'>)
              >;
              originAgent?: GraphQLTypes.Maybe<
                | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'>)
                | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'>)
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLegacyOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksLegacyOrgLocOrganisation,
                          'name'
                        >
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksOrgLocOrganisation,
                          'id' | 'name'
                        >
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'>)
              >;
            }
        >
      >
    >;
  };
};

export const OrderActivityFeedDocument = /* #__PURE__ */ gql`
  query orderActivityFeed($orderId: String!) {
    purchaseOrders(id: $orderId) {
      nodes {
        accountId
        canSendTextMessageToAgentForwarder {
          value
        }
        canSendTextMessageToCustomerUser {
          value
        }
        canSendTextMessageToManufacturer {
          value
        }
        customer {
          name
          uuid
        }
        id
        manufacturer {
          id
          ... on Account {
            tradingName
          }
          ... on NetworksOrgLoc {
            organisation {
              id
              name
            }
          }
          ... on NetworksLegacyOrgLoc {
            organisation {
              name
            }
          }
        }
        originAgent {
          id
          ... on NetworksOrgLoc {
            organisation {
              id
              name
            }
          }
          ... on NetworksLegacyOrgLoc {
            organisation {
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useOrderActivityFeedQuery__
 *
 * To run a query within a React component, call `useOrderActivityFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderActivityFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderActivityFeedQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderActivityFeedQuery(
  baseOptions: Apollo.QueryHookOptions<OrderActivityFeedQueryResult, OrderActivityFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrderActivityFeedQueryResult, OrderActivityFeedQueryVariables>(OrderActivityFeedDocument, options);
}
export function useOrderActivityFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderActivityFeedQueryResult, OrderActivityFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrderActivityFeedQueryResult, OrderActivityFeedQueryVariables>(OrderActivityFeedDocument, options);
}
export type OrderActivityFeedQueryHookResult = ReturnType<typeof useOrderActivityFeedQuery>;
export type OrderActivityFeedLazyQueryHookResult = ReturnType<typeof useOrderActivityFeedLazyQuery>;
