import type { AppState } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import type { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import NoResults from '@zen/Components/NoResults/NoResults';
import staticConfig from '@zen/utils/staticConfig';

const AuthProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  // must be a descendant of the BrowserRouter to have access to the browser history
  const navigate = useNavigate();

  const { auth0Audience } = staticConfig;
  const clientId: string = staticConfig.auth0ClientId;
  const domain: string = staticConfig.auth0Domain;
  const redirectUri: string = window.location.origin;

  const onRedirectCallback = (appState?: AppState) => {
    // This is the method that handles the event where Auth0 redirects users
    // from the Universal Login page to the React application
    navigate(appState?.returnTo || `${window.location.pathname}`);
  };

  if (!(domain && clientId)) {
    // this should never be seen
    return <NoResults headline="Missing configuration" />;
  }

  return (
    <Auth0Provider
      authorizationParams={{
        audience: auth0Audience,
        scope: 'read:current_user',
        // This object is passed as query params to the Auth0 '/authorize' endpoint
        // the redirectUri is where Auth0 will redirect users back to the application
        redirect_uri: redirectUri
      }}
      clientId={clientId}
      domain={domain}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
