import type { FC } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import OrderBasket from '@zen/Components/OrderBasket';
import { useViewMode } from '@zen/Components/ViewSwitcher';
import { TableConfigurationProvider } from '@zen/DesignSystem';
import type { SortInput } from '@zen/types';
import { SortingOrder } from '@zen/types';
import { FiltersContextProvider } from '@zen/utils/Filtering/contexts/FiltersContext';
import type { QueryParams } from '@zen/utils/QueryParams';
import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';

import { initialFilters } from '../components/Filters/helpers';
import type { OrderFilters } from '../types';
import ListView from './ListView';
import OrdersToolbar from './OrdersToolbar/OrdersToolbar';
import TableView from './TableView';

const initialSortOrder: SortInput = {
  field: 'createdAt',
  direction: SortingOrder.DESC
};

const OrderList: FC = () => {
  const { check } = useGlobalPermissions();
  const { search } = useLocation();
  const { isTableView } = useViewMode();
  const [sortOrder, setSortOrder] = useState<SortInput>(initialSortOrder);

  const queryParams: QueryParams = parseQueryParams(search);
  const isBasketMode: boolean = !!queryParams?.booking;
  const onlyOwnAccount: boolean = !!queryParams?.onlyOwnAccount;
  const canViewOrdersCustomers: boolean = check('purchaseOrders.canViewCustomers') && !onlyOwnAccount;
  const showTableView: boolean = !isBasketMode && isTableView;
  const className: string | undefined = isBasketMode ? 'mt-32' : undefined;

  return (
    <OrderBasket isEnabled={isBasketMode}>
      <TableConfigurationProvider tableId="orders">
        <div className={className}>
          {showTableView && (
            <FiltersContextProvider filterName="ordersTableFilter" initialFilters={initialFilters}>
              <OrdersToolbar canViewCustomer={canViewOrdersCustomers} showTableView={showTableView} />
              <TableView
                canViewCustomer={canViewOrdersCustomers}
                onSortChange={(sortInput: SortInput) => setSortOrder(sortInput)}
                sortOrder={sortOrder}
              />
            </FiltersContextProvider>
          )}
          {!showTableView && (
            <FiltersContextProvider<OrderFilters> filterName="orderListFilters" initialFilters={initialFilters}>
              <OrdersToolbar canViewCustomer={canViewOrdersCustomers} showTableView={showTableView} />
              <ListView canViewOrdersCustomers={canViewOrdersCustomers} />
            </FiltersContextProvider>
          )}
        </div>
      </TableConfigurationProvider>
    </OrderBasket>
  );
};

export default OrderList;
