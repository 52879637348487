import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetContractIdsQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  textContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type GetContractIdsQueryResult = { __typename?: 'Query' } & {
  getContracts?: GraphQLTypes.Maybe<
    { __typename?: 'ContractConnection' } & Pick<GraphQLTypes.ContractConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'ContractPayload' } & Pick<
                GraphQLTypes.ContractPayload,
                'carrierId' | 'contractId' | 'contractType' | 'id' | 'simplifiedContractId'
              > & {
                  oceanCarrier?: GraphQLTypes.Maybe<
                    { __typename?: 'OceanCarrier' } & Pick<GraphQLTypes.OceanCarrier, 'oceanCarrierName'>
                  >;
                }
            >
          >
        >;
        pageInfo?: GraphQLTypes.Maybe<
          { __typename: 'PageInfo' } & Pick<
            GraphQLTypes.PageInfo,
            'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
          >
        >;
      }
  >;
};

export const GetContractIdsDocument = /* #__PURE__ */ gql`
  query getContractIds($after: String, $before: String, $first: Int, $last: Int, $textContains: String) {
    getContracts(after: $after, before: $before, first: $first, last: $last, textContains: $textContains) {
      nodes {
        carrierId
        contractId
        contractType
        id
        oceanCarrier {
          oceanCarrierName
        }
        simplifiedContractId
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetContractIdsQuery__
 *
 * To run a query within a React component, call `useGetContractIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractIdsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      textContains: // value for 'textContains'
 *   },
 * });
 */
export function useGetContractIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetContractIdsQueryResult, GetContractIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetContractIdsQueryResult, GetContractIdsQueryVariables>(GetContractIdsDocument, options);
}
export function useGetContractIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContractIdsQueryResult, GetContractIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetContractIdsQueryResult, GetContractIdsQueryVariables>(GetContractIdsDocument, options);
}
export type GetContractIdsQueryHookResult = ReturnType<typeof useGetContractIdsQuery>;
export type GetContractIdsLazyQueryHookResult = ReturnType<typeof useGetContractIdsLazyQuery>;
