import type { FC } from 'react';
import { useState } from 'react';

import { Button } from '@zen/DesignSystem';

import AddLocationModal from '../AddLocationModal';

interface Props {
  accountId: string;
  accountName: string;
}

const AddLocationButton: FC<Props> = ({ accountId, accountName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen((isOpen: boolean): boolean => !isOpen);

  return (
    <>
      <AddLocationModal accountId={accountId} accountName={accountName} isOpen={isModalOpen} onClose={toggleModal} />
      <Button onClick={toggleModal} size="compact">
        Create location
      </Button>
    </>
  );
};

export default AddLocationButton;
