import { createContext } from 'react';

import type { UserContextData } from './types';

export const initialContext: UserContextData = {
  accountUuid: '',
  accountStatus: null,
  parentAccountId: '',
  referencePrefix: null,
  divisions: [],
  userProfile: {
    division: null,
    isAdmin: false,
    isDivision: false,
    isParent: false,
    isProfileCompleted: false,
    isSingleSignOn: false,
    firstName: '',
    lastName: '',
    role: null,
    email: '',
    phoneNumber: '',
    uuid: '',
    registeredCompanyName: ''
  },
  refetchUser: () => {}
};

const UserContext = createContext<UserContextData>(initialContext);

export default UserContext;
