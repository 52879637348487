import { size } from 'lodash';
import type { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { checkPermission } from '@zen/Auth/authHelper';
import PageContent from '@zen/Components/PageContent';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Table, useTableConfiguration } from '@zen/DesignSystem';
import useOrdersTableView from '@zen/Orders/hooks/useOrdersTableView';
import type { OrderFilters, OrderTableView } from '@zen/Orders/types';
import type { SortInput } from '@zen/types';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';
import { useConsumeLocationState } from '@zen/utils/hooks/useConsumeLocationState';
import type { QueryParams } from '@zen/utils/QueryParams';
import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';

import NoResults from '../NoResults';
import { getColumns } from './ordersTableConfiguration';
import type { OrderListCapabilities } from './types';

interface Props {
  canViewCustomer?: boolean;
  onSortChange: (sortInput: SortInput) => void;
  sortOrder: SortInput;
}

const capability = { canPerformOrderActions: false, canViewCustomer: false };

const TableView: FC<Props> = ({ canViewCustomer = true, onSortChange, sortOrder }) => {
  const { hiddenColumns, tableId } = useTableConfiguration();
  const { setAppliedFilters, appliedFilters } = useAppliedFilters();
  const { search } = useLocation();
  const queryParams: QueryParams = parseQueryParams(search);
  const hasFilters: boolean = size(appliedFilters) > 0 || !!queryParams?.textContains;

  const noResults: ReactNode = (
    <PageContent width="wide">
      <NoResults hasFilters={hasFilters} />
    </PageContent>
  );

  useConsumeLocationState<{ filters: Partial<OrderFilters> }>((filters) => {
    if (filters) {
      setAppliedFilters(filters);
    }
  }, 'filters');

  const { nodes, paginationInfo, loading, error, totalCount } = useOrdersTableView(sortOrder, appliedFilters);

  return (
    <QueryHandler
      data={nodes}
      error={!!error}
      isLoading={loading}
      loadingComponent={
        <SkeletonTableLoading
          columns={getColumns({ capabilities: capability })}
          hiddenColumns={hiddenColumns}
          tableId={tableId}
        />
      }
      noResults={noResults}
    >
      {(purchaseOrders: OrderTableView[]) => {
        const canPerformAction = (key: keyof OrderTableView): boolean => checkPermission<OrderTableView>(purchaseOrders[0], key);
        const permissionsList: Array<keyof OrderTableView> = ['canRemove', 'canUpdate', 'canClose'];
        const canPerformOrderActions: boolean = permissionsList.some(canPerformAction);

        const capabilities: OrderListCapabilities = { canPerformOrderActions, canViewCustomer };

        return (
          <div data-testid="order-table-view">
            <Table<OrderTableView>
              columns={getColumns({ capabilities })}
              data={purchaseOrders}
              hiddenColumns={hiddenColumns}
              isDraggingEnabled={true}
              onOrderChange={onSortChange}
              order={sortOrder}
              paginationInfo={paginationInfo}
              tableId={tableId}
              totalCountConfig={{
                totalCount,
                entityName: 'order'
              }}
            />
          </div>
        );
      }}
    </QueryHandler>
  );
};

export default TableView;
