import type { CSSObjectWithLabel, Theme } from 'react-select';

import { Color, FontSize } from '@zen/Styleguide';

import type { SelectVariant } from './types';

type SelectState = {
  isDisabled?: boolean;
  isFocused?: boolean;
  selectProps: {
    isSearchable: boolean;
  };
};

interface CustomStyles {
  displaySeparator?: boolean;
  hasError?: boolean;
  isCompactSize?: boolean;
  isHovered?: boolean;
  variant: SelectVariant;
}

export const customStyles = ({
  hasError = false,
  variant,
  displaySeparator = false,
  isHovered = false,
  isCompactSize = false
}: CustomStyles) => {
  const borderColor: Record<SelectVariant, Color> = {
    default: Color.GREY_LIGHT,
    inline: Color.TRANSPARENT
  };

  const getIndicatorStyles = (state: SelectState): CSSObjectWithLabel => {
    const { isDisabled, isFocused } = state;

    const shouldHideIndicators: boolean = isDisabled || (variant === 'inline' && !isFocused && !isHovered);

    return { display: shouldHideIndicators ? 'none' : 'block' };
  };

  return {
    control: (styles: CSSObjectWithLabel, state: SelectState): CSSObjectWithLabel => {
      const shouldApplyHoverStyles: boolean = variant === 'inline' && !state.isFocused;

      const getBorderColor = (): Color => {
        if (hasError) {
          return Color.RED_DARK;
        }

        return state.isFocused ? Color.AZURE_BASE : borderColor[variant];
      };

      const getHoveredBorderColor = (): Color => {
        return state.isFocused ? getBorderColor() : Color.TRANSPARENT;
      };

      const getControlHoveredStyle = (): CSSObjectWithLabel => {
        const cssRule: CSSObjectWithLabel = {};

        if (shouldApplyHoverStyles) {
          cssRule['&:hover'] = {
            borderColor: getHoveredBorderColor(),
            ...(variant === 'inline'
              ? { backgroundColor: state.isDisabled ? Color.TRANSPARENT : Color.GREY_LIGHTER, color: Color.GREY_BASE }
              : {})
          };
        }

        return cssRule;
      };

      const getBackground = (): Color => {
        if (variant === 'inline') {
          return Color.TRANSPARENT;
        }
        if (state.isDisabled) {
          return Color.GREY_LIGHTEST;
        }

        return Color.WHITE;
      };

      const getCursor = (): string => {
        if (state.isDisabled) {
          return 'not-allowed';
        }

        return state.selectProps.isSearchable ? 'text' : 'pointer';
      };

      return {
        ...styles,
        cursor: getCursor(),
        outline: 'none',
        padding: '0',
        minHeight: isCompactSize ? '2rem' : '2.5rem',
        borderRadius: '0.25rem',
        border: `1px solid ${getBorderColor()}`,
        boxShadow: 'none',
        color: Color.GREY_DARK,
        background: getBackground(),
        ...getControlHoveredStyle(),
        fontFamily: 'inherit',
        fontSize: isCompactSize ? FontSize.XS : FontSize.SM,
        lineHeight: 'inherit'
      };
    },
    clearIndicator: (styles: CSSObjectWithLabel, state: SelectState): CSSObjectWithLabel => ({
      ...styles,
      ...getIndicatorStyles(state),
      padding: '0rem 0.125rem'
    }),
    dropdownIndicator: (styles: CSSObjectWithLabel, state: SelectState): CSSObjectWithLabel => ({
      ...styles,
      ...getIndicatorStyles(state),
      padding: '0rem 0.125rem'
    }),
    group: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      borderTop: `solid 1px ${Color.GREY_LIGHTER}`,
      '&:first-child': { borderTop: 'none', paddingTop: 0 }
    }),
    groupHeading: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      textTransform: 'none',
      color: Color.GREY_BASE,
      fontSize: '0.875rem',
      display: 'flex',
      margin: '0',
      padding: '0.5rem 1rem 0.75rem 1rem',
      width: '100%'
    }),
    indicatorsContainer: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      padding: '0rem 0.5rem 0rem 0rem'
    }),
    input: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      margin: 0
    }),
    singleValue: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      color: Color.GREY_DARK,
      marginLeft: 0
    }),
    valueContainer: (styles: CSSObjectWithLabel, state: { hasValue: boolean; isMulti: boolean }): CSSObjectWithLabel => ({
      ...styles,
      padding: variant === 'inline' ? '0.125rem 0.75rem' : '0.125rem 0rem 0.125rem 0.75rem',
      height: '100%',
      columnGap: '0.25rem',
      rowGap: '0.25rem',
      paddingLeft: state.hasValue && state.isMulti ? '0.25rem' : '0.75rem'
    }),
    option: (styles: CSSObjectWithLabel, { isDisabled }: SelectState): CSSObjectWithLabel => ({
      ...styles,
      lineHeight: '1.25rem',
      padding: '0.5rem 1rem',
      color: isDisabled ? Color.GREY_LIGHT : styles.color,
      backgroundColor: isDisabled ? Color.TRANSPARENT : styles.backgroundColor,
      cursor: isDisabled ? 'unset' : 'pointer'
    }),
    placeholder: (styles: CSSObjectWithLabel, state: SelectState): CSSObjectWithLabel => {
      const placeholderColor: Color =
        variant === 'inline' && isHovered && !state.isFocused && !state.isDisabled ? Color.GREY_BASE : Color.GREY_LIGHT;

      return {
        ...styles,
        color: placeholderColor,
        margin: 0
      };
    },
    indicatorSeparator: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel => {
      return displaySeparator ? baseStyles : ({ display: 'none' } as CSSObjectWithLabel);
    },
    menu: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.15)',
      borderRadius: '4px',
      border: '1px solid #E6E7E9',
      overflow: 'hidden',
      zIndex: 150
    }),
    menuList: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      padding: '0.5rem 0'
    }),
    menuPortal: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      zIndex: 150
    })
  };
};

export const customTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral30: Color.AZURE_BASE,
    primary25: Color.AZURE_LIGHTEST,
    primary50: Color.AZURE_LIGHT,
    primary: Color.AZURE_BASE
  }
});

export const multiSelectStyles = (
  hasError: boolean = false,
  variant: SelectVariant = 'default',
  displaySeparator: boolean = false
) => {
  return {
    ...customStyles({ hasError, variant, displaySeparator }),
    multiValueLabel: (baseStyles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...baseStyles,
      fontSize: '0.875rem',
      padding: '0.375rem 0.5rem'
    })
  };
};

export const asyncSelectStyles = ({ hasError = false, variant = 'default', isHovered, isCompactSize }: CustomStyles) => {
  return {
    ...customStyles({ hasError, variant, displaySeparator: false, isHovered, isCompactSize }),
    dropdownIndicator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      display: 'none'
    })
  };
};
