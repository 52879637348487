import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import type { FormArrayHelpers, FormInstance } from '@zen/Components/Form';
import { Form, FormArray, FormButtons, FormCheckbox, FormInput, FormSelect } from '@zen/Components/Form';
import type { FormButtonsLayout } from '@zen/Components/Form/FormButtons';
import FormHeadline from '@zen/Components/Form/FormHeadline';
import FormLabel from '@zen/Components/Form/FormLabel';
import type { Option } from '@zen/DesignSystem';
import { Button, IconButton } from '@zen/DesignSystem';
import opsSettingsRoutes from '@zen/Routes/opsSettings';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { getEmptyNetworkPersonContact } from '../NewContact/helpers';
import type { NetworkContact, NetworkContactPerson } from '../types';
import { ContactStatus } from '../types';
import { validationSchema } from './contactForm.validation';
import type { FormNetworkContactPerson } from './types';

const statusOptions: Option<ContactStatus>[] = [
  { value: ContactStatus.ACTIVE, label: 'Active' },
  { value: ContactStatus.ARCHIVED, label: 'Archived' },
  { value: ContactStatus.GDPRREQUEST, label: 'GDPR request' }
];

interface Props {
  formButtonsLayout?: FormButtonsLayout;
  initialValues: NetworkContact;
  isNested?: boolean;
  onSubmit: (contact: NetworkContact) => Promise<IOkOrErrorResult>;
  onSuccess?: () => void;
  submitButtonText?: string;
}

const ContactForm: FC<Props> = ({ formButtonsLayout, initialValues, isNested, onSubmit, onSuccess, submitButtonText }) => {
  const navigate = useNavigate();

  const renderFormButtons = ({ isSubmitting }: FormInstance<NetworkContact>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout={formButtonsLayout} text={submitButtonText}>
      <Button data-testid="cancel-button" onClick={() => navigate(opsSettingsRoutes.contactsIndex.path)} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Form
      className="max-w-xl"
      formButtons={renderFormButtons}
      formName="ContactForm"
      initialValues={initialValues}
      isNested={isNested}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {(form) => {
        const handleMainContactChange = (name: string): void => {
          form.values.contactPeople.forEach((item: NetworkContactPerson, i: number) => {
            const fieldName = `contactPeople[${i}].mainContact`;

            if (name !== fieldName) {
              form.setFieldValue(fieldName, false);
            }
          });
        };

        return (
          <>
            <FormInput isRequired={true} label="Account name" name="name" placeholder="Add account name" />
            <FormInput label="Bank account number" name="bankAccountDetails" placeholder="Add bank account number" />
            <FormSelect isRequired={true} name="status" options={statusOptions} placeholder="Select a status" />
            <FormInput name="taxNumber" placeholder="Add tax number" />

            <FormLabel label="Contact type" />
            <div className="flex">
              <FormCheckbox className="mr-5" name="customer" />
              <FormCheckbox className="mr-5" name="supplier" />
              <FormCheckbox className="mr-5" name="warehouse" />
              <FormCheckbox className="mr-5" name="haulier" />
              <FormCheckbox name="carrier" />
            </div>

            {form.values.carrier && (
              <div className="mt-6">
                <FormInput isRequired={true} label="Carrier (SCAC)" name="scacCode" />
                <FormInput isRequired={true} label="BIP code" name="bipCode" />
              </div>
            )}
            <FormHeadline className="mt-6" text="Contact people" />
            <FormArray
              addButtonText="Add additional contact"
              empty={getEmptyNetworkPersonContact()}
              path="contactPeople"
              values={form.values.contactPeople}
            >
              {({ remove, getFieldName }: FormArrayHelpers<FormNetworkContactPerson>) => (
                <div className="flex flex-col p-4 mb-4 bg-grey-lightest">
                  <FormInput isRequired={true} label="First name" name={getFieldName('firstName')} placeholder="Add first name" />
                  <FormInput isRequired={true} label="Last name" name={getFieldName('lastName')} placeholder="Add last name" />
                  <FormInput isRequired={true} label="Email" name={getFieldName('email')} placeholder="Add email" />
                  <FormInput
                    isRequired={true}
                    label="Phone number"
                    name={getFieldName('phoneNumber')}
                    placeholder="Add phone number"
                  />
                  <FormCheckbox label="Main contact" name={getFieldName('mainContact')} onChange={handleMainContactChange} />
                  <IconButton className="mt-4" icon="zicon-trash" onClick={remove} title="Remove contact" variant="secondary" />
                </div>
              )}
            </FormArray>
          </>
        );
      }}
    </Form>
  );
};

export default ContactForm;
