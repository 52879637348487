import cx from 'classnames';
import type { FC, MouseEvent } from 'react';

import type { IconName } from '@zen/Styleguide';
import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

interface Props {
  className?: string;
  disabled?: boolean;
  icon: IconName;
  interactive?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

export interface IconConfig {
  className?: string;
  icon: IconName;
}

export const displayName = 'Design System/Icon';

const Icon: FC<Props> = ({ className, disabled = false, icon, onClick, interactive = false, ...rest }) => {
  const { trackEvent } = useTracking();

  const iconStyles = cx(
    {
      'opacity-50 cursor-not-allowed': disabled,
      'hover:bg-grey-lighter hover:text-grey-dark rounded p-2 active:bg-grey-base cursor-pointer': interactive
    },
    'zicon',
    icon,
    className
  );

  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    if (!disabled) {
      const trackingEvent = createTrackingEvent(displayName, 'click', event);

      trackEvent(trackingEvent);
      onClick?.(event);
    }
  };

  return (
    <span
      className={iconStyles}
      data-component="icon"
      data-testid={`icon-${icon}`}
      onClick={handleClick}
      {...createTrackingParentAttribute('icon')}
      {...createTrackingLabelAttribute(icon)}
      {...rest}
    />
  );
};

export type { Props as IconProps };
export default Icon;
