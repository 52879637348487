import type { CargoMetadata } from '@zen/ActivityFeed/types';
import { ActivityFeedItemTypeEnum } from '@zen/ActivityFeed/types';
import type { CargoType } from '@zen/Cargo';
import { CargoItemTypeEnum, LooseCargoTypeEnum } from '@zen/Cargo';
import { cargoItemTypeLabelMapping, getLooseCargoLabel } from '@zen/Cargo/helpers';
import type { IconName } from '@zen/Styleguide';
import { cargoModeLabel, cargoTypeLabelMapping } from '@zen/types';
import type { Nullable, Optional } from '@zen/utils/typescript';

interface CargoConfiguration {
  iconColor: string;
  iconName: IconName;
  title: string;
}

export type RestrictedActivityFeedItemTypes =
  | ActivityFeedItemTypeEnum.UPDATED_CARGO_TYPE
  | ActivityFeedItemTypeEnum.ADDED_CARGO_ITEM
  | ActivityFeedItemTypeEnum.DELETED_CARGO_ITEM
  | ActivityFeedItemTypeEnum.UPDATED_CARGO_ITEM;

export const activityFeedItemMapping: Record<RestrictedActivityFeedItemTypes, CargoConfiguration> = {
  [ActivityFeedItemTypeEnum.UPDATED_CARGO_TYPE]: {
    title: `${cargoModeLabel} has been changed`,
    iconName: 'zicon-tickoval',
    iconColor: 'text-green-dark'
  },
  [ActivityFeedItemTypeEnum.ADDED_CARGO_ITEM]: {
    title: 'Cargo has been added',
    iconName: 'zicon-tickoval',
    iconColor: 'text-green-dark'
  },
  [ActivityFeedItemTypeEnum.DELETED_CARGO_ITEM]: {
    title: 'Cargo has been removed',
    iconName: 'zicon-cross-oval',
    iconColor: 'text-red-dark'
  },
  [ActivityFeedItemTypeEnum.UPDATED_CARGO_ITEM]: {
    title: 'Cargo has been updated',
    iconName: 'zicon-tickoval',
    iconColor: 'text-green-dark'
  }
};

export const getSubtitle = (itemType: ActivityFeedItemTypeEnum, metadata: CargoMetadata): Nullable<string> => {
  if (!metadata) {
    return null;
  }

  const { containerNumber, containerType, cargoType, looseCargoType, palletType, vehicleType, quantity } = metadata;
  const looseCargoLabel: string = looseCargoType ? cargoTypeLabelMapping[looseCargoType] : '';
  const isAssortedCargo: boolean = looseCargoType === LooseCargoTypeEnum.ASSORTED_CARGO;
  const cargoDetails: string = quantity && !isAssortedCargo ? `${quantity} x ${looseCargoLabel}` : looseCargoLabel;

  if (itemType === ActivityFeedItemTypeEnum.UPDATED_CARGO_TYPE) {
    return cargoType ? cargoItemTypeLabelMapping[cargoType] : '';
  }

  if (cargoType === CargoItemTypeEnum.CONTAINER) {
    const labelSuffix: string = containerNumber ? ` - ${containerNumber}` : '';

    return containerType ? `${cargoTypeLabelMapping[containerType]}${labelSuffix}` : '';
  }

  if (cargoType === CargoItemTypeEnum.LOOSE_CARGO) {
    const labelPrefix: string = quantity ? `${quantity} x ` : '';
    const label: string = looseCargoType ? getLooseCargoLabel(looseCargoType, palletType) : '';

    return `${labelPrefix}${label}`;
  }

  return vehicleType ? `${cargoTypeLabelMapping[vehicleType]} - ${cargoDetails}` : '';
};

export const getCargoTypeForIcon = (metadata: CargoMetadata): Optional<CargoType> => {
  if (!metadata?.cargoType) {
    return null;
  }
  const { containerType, cargoType, looseCargoType, vehicleType } = metadata;

  const cargoTypeMapping: Record<CargoItemTypeEnum, Optional<CargoType>> = {
    [CargoItemTypeEnum.CONTAINER]: containerType,
    [CargoItemTypeEnum.LOOSE_CARGO]: looseCargoType,
    [CargoItemTypeEnum.VEHICLE]: vehicleType
  };

  return cargoTypeMapping[cargoType];
};
