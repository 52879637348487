import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UnarchiveAccountLocationMutationVariables = GraphQLTypes.Exact<{
  locationId: GraphQLTypes.Scalars['String'];
}>;

export type UnarchiveAccountLocationMutationResult = { __typename?: 'Mutation' } & {
  unarchiveAccountLocation?: GraphQLTypes.Maybe<
    { __typename?: 'UnarchiveAccountLocationPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path'>>>;
    }
  >;
};

export const UnarchiveAccountLocationDocument = /* #__PURE__ */ gql`
  mutation unarchiveAccountLocation($locationId: String!) {
    unarchiveAccountLocation(locationId: $locationId) {
      errors {
        path
      }
    }
  }
`;

/**
 * __useUnarchiveAccountLocationMutation__
 *
 * To run a mutation, you first call `useUnarchiveAccountLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveAccountLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveAccountLocationMutation, { data, loading, error }] = useUnarchiveAccountLocationMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useUnarchiveAccountLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveAccountLocationMutationResult, UnarchiveAccountLocationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UnarchiveAccountLocationMutationResult, UnarchiveAccountLocationMutationVariables>(
    UnarchiveAccountLocationDocument,
    options
  );
}
export type UnarchiveAccountLocationMutationHookResult = ReturnType<typeof useUnarchiveAccountLocationMutation>;
