import type { FC } from 'react';
import { useEffect } from 'react';

import useSwitchAccount from '@zen/Auth/hooks/useSwitchAccount';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import useAccount from '@zen/utils/hooks/useAccount';

interface Props {
  accountIdForUrl: string;
}

const AutomaticAccountSwitcher: FC<Props> = ({ accountIdForUrl }) => {
  const { accountUuid, userProfile } = useAccount();
  const { switchToAccount } = useSwitchAccount();
  const { check } = useGlobalPermissions();
  const isCreateAccountFeatureEnabled: boolean = useFeatureIsOn('create-account');

  const canSwitchAccount = check('accounts.canSwitch');

  useEffect(() => {
    const accountIdMismatch: boolean = accountIdForUrl !== accountUuid;
    const shouldSwitchAccount: boolean = isCreateAccountFeatureEnabled
      ? userProfile.isAdmin && accountIdMismatch
      : canSwitchAccount && accountIdMismatch;

    if (shouldSwitchAccount) {
      switchToAccount(accountIdForUrl);
    }
  });

  return null;
};

export default AutomaticAccountSwitcher;
