import cx from 'classnames';
import type { ReactNode } from 'react';

import { Button } from '@zen/DesignSystem';

import type { FormFieldArrayHelpers } from '../FormFieldArray';
import FormFieldArray from '../FormFieldArray';

export type FormArrayHelpers<T> = {
  getFieldName: (key?: string) => string;
  index: number;
  insert: () => void;
  remove: () => void;
  value: T;
};

interface Props<T> {
  addButtonText?: string;
  children: (helpers: FormArrayHelpers<T>) => ReactNode;
  className?: string;
  empty?: T;
  hideAddButton?: boolean;
  isAddDisabled?: boolean;
  isCompact?: boolean;
  noMargin?: boolean;
  onAddItem?: () => void;
  path: string;
  values: T[];
}

const FormArray = <T extends {}>(props: Props<T>) => {
  const {
    addButtonText = 'Add',
    children,
    className,
    empty,
    hideAddButton = false,
    isAddDisabled = false,
    isCompact,
    noMargin,
    onAddItem,
    path,
    values
  } = props;

  return (
    <FormFieldArray name={path} noMargin={noMargin}>
      {(arrayHelpers: FormFieldArrayHelpers): ReactNode => {
        const add = (): void => {
          onAddItem?.();
          arrayHelpers.push(empty);
        };

        const classNames: string = cx({
          'mt-4': !isCompact,
          'mb-0': isCompact
        });

        return (
          <>
            {values.map((value: T, index: number) => {
              const remove = (): void => arrayHelpers.remove(index);

              const insert = (): void => {
                onAddItem?.();
                arrayHelpers.insert(index + 1, empty);
              };

              const getFieldName = (key?: string): string => (key ? `${path}[${index}].${key}` : `${path}[${index}]`);

              return (
                <div key={index} className={className} data-testid={`${path}-${index}`}>
                  {children({ remove, getFieldName, index, value, insert })}
                </div>
              );
            })}
            {!hideAddButton && (
              <Button
                className={classNames}
                disabled={isAddDisabled}
                iconLeft="zicon-add"
                onClick={add}
                size="compact"
                variant="secondary"
              >
                {addButtonText}
              </Button>
            )}
          </>
        );
      }}
    </FormFieldArray>
  );
};

export default FormArray;
