import { getLegacyCargoType } from '@zen/Cargo/cargoDictionaryMapping.helper';
import { getWeight } from '@zen/Cargo/helpers';
import type { CoreCargo, Currency, RiskLevelsEnum, ValueOfGoods, VehicleTypeEnum } from '@zen/Cargo/types';
import { TruckType } from '@zen/Cargo/types';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { ftlConfiguration } from '../../FormVehicleTypeSelect/helpers';
import { getLooseCargoDimensions, initialDimensions, initialWeightValues, mapLooseCargoType } from '../../LooseCargo/helpers';
import { initialValueOfGoods } from '../FullTruckForm/helpers';
import type { FullTruckFormInitialValues } from '../FullTruckForm/types';

export const prepareFullTruckFormInitialValues = (cargo: CoreCargo): FullTruckFormInitialValues => {
  const {
    cargoType,
    grossWeight,
    hazardous,
    dimensions,
    looseCargo,
    refrigerated,
    valueOfGoods,
    volume,
    tailLift,
    riskLevel,
    healthCertificate
  } = cargo;
  const type = getLegacyCargoType(cargoType) as VehicleTypeEnum;
  const truckVariant = getTruckVariant(type);
  const cargoAndPalletType = mapLooseCargoType(looseCargo?.cargoType);

  return {
    loose: {
      ...cargoAndPalletType,
      volume: volume?.value || null,
      grossWeight: getWeight(grossWeight) || initialWeightValues,
      dimensions: getLooseCargoDimensions(dimensions, cargoAndPalletType.value) || initialDimensions,
      quantity: looseCargo?.quantity || 1
    },
    type,
    riskLevel: riskLevel ? (riskLevel as RiskLevelsEnum) : null,
    healthCertificate: !!healthCertificate,
    tailLift: !!tailLift,
    refrigerated: truckVariant === TruckType.REFRIGERATED_TRUCK || !!refrigerated,
    truckVariant,
    hazardous: !!hazardous,
    valueOfGoods: getValueOfGoods(valueOfGoods)
  };
};

const getTruckVariant = (type: VehicleTypeEnum): Nullable<TruckType> => {
  const truckType =
    Object.keys(ftlConfiguration).find((key) => ftlConfiguration[key as keyof typeof ftlConfiguration].includes(type)) || null;

  return truckType as Nullable<TruckType>;
};

const getValueOfGoods = (valueOfGoods: Optional<ValueOfGoods>): { currency: Currency; value: Nullable<number> } => {
  if (valueOfGoods?.value) {
    return {
      currency: valueOfGoods.currency as Currency,
      value: valueOfGoods.value
    };
  }

  return initialValueOfGoods;
};
