import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateAccountMemberUserGroupMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateAccountMemberUserGroupInput;
}>;

export type UpdateAccountMemberUserGroupMutationResult = { __typename?: 'Mutation' } & {
  updateAccountMemberUserGroup?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateAccountMemberUserGroupPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const UpdateAccountMemberUserGroupDocument = /* #__PURE__ */ gql`
  mutation updateAccountMemberUserGroup($input: UpdateAccountMemberUserGroupInput!) {
    updateAccountMemberUserGroup(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateAccountMemberUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMemberUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMemberUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMemberUserGroupMutation, { data, loading, error }] = useUpdateAccountMemberUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMemberUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountMemberUserGroupMutationResult,
    UpdateAccountMemberUserGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateAccountMemberUserGroupMutationResult, UpdateAccountMemberUserGroupMutationVariables>(
    UpdateAccountMemberUserGroupDocument,
    options
  );
}
export type UpdateAccountMemberUserGroupMutationHookResult = ReturnType<typeof useUpdateAccountMemberUserGroupMutation>;
