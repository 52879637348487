import type { FC } from 'react';
import { useState } from 'react';

import { IconButton } from '@zen/DesignSystem';

import DeleteMemberModal from '../DeleteMemberModal';

interface Props {
  isProfileCompleted: boolean;
  memberId: string;
  memberName: string;
  onSuccess: () => void;
}

const DeleteMemberButton: FC<Props> = ({ isProfileCompleted, memberName, memberId, onSuccess }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen((isOpen: boolean) => !isOpen);
  const title: string = isProfileCompleted ? 'Delete' : 'Revoke invitation';

  return (
    <>
      <DeleteMemberModal
        isOpen={isModalOpen}
        isProfileCompleted={isProfileCompleted}
        memberId={memberId}
        memberName={memberName}
        onClose={toggleModal}
        onSuccess={onSuccess}
      />
      <IconButton icon="zicon-trash" onClick={toggleModal} size="medium" title={title} variant="ghost" />
    </>
  );
};

export default DeleteMemberButton;
