import { type FC, useState } from 'react';

import { Button, Dialog } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useDeactivateAccountMutation } from '../../graphql';

interface Props {
  accountId: string;
  tradingName: string;
}

const DeactivateAccountButton: FC<Props> = ({ accountId, tradingName }) => {
  const { addError, addSuccess } = useNotification();
  const [deactivateAccount, { loading }] = useDeactivateAccountMutation();
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState<boolean>(false);

  const handleDeactiveAccount = async () => {
    await performMutation({
      mutationFn: () =>
        deactivateAccount({
          awaitRefetchQueries: false,
          refetchQueries: ['getAccountDetails'],
          variables: {
            accountId
          }
        }),
      onError: () => {
        addError('Something went wrong when trying to deactivate account.');
      },
      onSuccess: () => {
        addSuccess('Account deactivated.');
        setIsConfirmDialogVisible(false);
      }
    });
  };

  return (
    <>
      <Button onClick={() => setIsConfirmDialogVisible(true)} variant="destructive">
        Deactivate account
      </Button>
      <Dialog
        confirmLabel="Deactivate"
        header={`Deactivate ${tradingName}?`}
        isLoading={loading}
        isOpen={isConfirmDialogVisible}
        message="Deactivating this account will immediately suspend access for all members. Accounts can be reactivated at any time."
        onClose={() => setIsConfirmDialogVisible(false)}
        onConfirm={handleDeactiveAccount}
      />
    </>
  );
};

export default DeactivateAccountButton;
