import type { BusinessHours, TimeRange } from '@zen/Networks';
import { hasTimeRange } from '@zen/Networks/networksHelpers';
import { formatDate } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

import { TimeRangeEnum } from '../Form/FormTimeFields/TimeRange';
import type { TimeRangeMapping } from '../Form/FormTimeFields/types';
import type { DateWithTimeFields } from './types';
import { TimeOption } from './types';

const getTimeOption = ({ time, startTime }: Partial<DateWithTimeFields>, timeConfig: TimeOption[]) => {
  if (time) return TimeOption.POINT_IN_TIME;

  return startTime ? TimeOption.TIME_RANGE : timeConfig[0];
};

export const prepareInitialValues = (values: Partial<DateWithTimeFields>, timeConfig: TimeOption[]): DateWithTimeFields => {
  return {
    date: '',
    time: '',
    startTime: '',
    endTime: '',
    timeOption: getTimeOption(values, timeConfig),
    ...values
  };
};

export const prepareValues = <T extends DateWithTimeFields>(values: T): T => {
  return {
    ...values,
    time: values.timeOption === TimeOption.POINT_IN_TIME ? values.time : '',
    startTime: values.timeOption === TimeOption.TIME_RANGE ? values.startTime : '',
    endTime: values.timeOption === TimeOption.TIME_RANGE ? values.endTime : ''
  };
};

export const defaultAllDayTimeRange: TimeRange = {
  startTime: '08:00',
  endTime: '17:00'
};

export const getAllDayTimeRange = (date: Optional<string>, businessHours: Optional<BusinessHours>): TimeRange => {
  if (!date || !businessHours) {
    return defaultAllDayTimeRange;
  }

  const dayName = formatDate(date, 'DDDD').toLocaleLowerCase() as keyof BusinessHours;
  const timeRange: TimeRange = businessHours[dayName];

  return hasTimeRange(timeRange) ? timeRange : defaultAllDayTimeRange;
};

export const getTimeRangeMapping = (date: Optional<string>, businessHours: Optional<BusinessHours>): TimeRangeMapping => {
  return {
    [TimeRangeEnum.MORNING]: {
      startTime: '08:00',
      endTime: '12:00'
    },
    [TimeRangeEnum.AFTERNOON]: {
      startTime: '12:00',
      endTime: '17:00'
    },
    [TimeRangeEnum.ALL_DAY]: getAllDayTimeRange(date, businessHours)
  };
};

const covertTimeRangeMappingToTimeMapping = (timeRangeMapping: TimeRangeMapping): Record<string, TimeRangeEnum> => {
  return Object.entries(timeRangeMapping).reduce(
    (prev, [key, { startTime, endTime }]) => ({
      ...prev,
      [`${startTime}_${endTime}`]: key
    }),
    {}
  );
};

export const calculateTimeRangeValue = (
  startTime: string,
  endTime: string,
  timeRangeMapping: TimeRangeMapping
): TimeRangeEnum | null => {
  const timeMapping = covertTimeRangeMappingToTimeMapping(timeRangeMapping);

  return timeMapping[`${startTime}_${endTime}`] || null;
};
