import type { FC, ReactElement } from 'react';

import type { Account } from '@zen/Accounts/types';
import type { PermittedParties } from '@zen/ActivityFeed';
import createPermittedParties from '@zen/ActivityFeed/helpers/createPermittedParties';
import useRole from '@zen/Auth/hooks/useRole';
import type { TargetPermissions } from '@zen/Booking/BookingDetails/components/ActivityFeedContainer/ActivityFeedContainer';
import QueryHandler from '@zen/Components/QueryHandler';
import type { NetworksOrgLoc } from '@zen/Networks';
import { Role } from '@zen/Networks';
import useAccount from '@zen/utils/hooks/useAccount';

import { useOrderActivityFeedQuery } from './graphql';
import OrderActivityFeed from './OrderActivityFeed';

interface Props {
  orderId: string;
  targetPermissions?: TargetPermissions;
}

const OrderActivityFeedContainer: FC<Props> = ({ orderId, targetPermissions }) => {
  const role: Role = useRole();
  const { userProfile } = useAccount();
  const { loading, error, data } = useOrderActivityFeedQuery({ variables: { orderId }, fetchPolicy: 'cache-and-network' });

  return (
    <QueryHandler data={data?.purchaseOrders?.nodes?.[0]} error={!!error} isLoading={loading}>
      {(order): ReactElement => {
        if (!order.accountId) {
          return <div />;
        }

        const {
          canSendTextMessageToAgentForwarder,
          canSendTextMessageToCustomerUser,
          canSendTextMessageToManufacturer,
          customer,
          manufacturer,
          originAgent
        } = order;

        const customerName: string = customer?.name || '';
        const organisationName: string = role === Role.CUSTOMER_USER ? userProfile.registeredCompanyName : customerName;

        const permittedParties: PermittedParties = createPermittedParties(
          {
            manufacturers: manufacturer ? [manufacturer as NetworksOrgLoc] : [],
            originAgent: originAgent as NetworksOrgLoc,
            organisationName,
            permissions: {
              canSendTextMessageToAgentForwarder,
              canSendTextMessageToCustomerUser,
              canSendTextMessageToManufacturer
            },
            supplier: manufacturer as Account
          },
          role,
          targetPermissions
        );

        return (
          <OrderActivityFeed
            accountId={order.accountId}
            orderId={orderId}
            permittedParties={permittedParties}
            targetPermissions={targetPermissions}
          />
        );
      }}
    </QueryHandler>
  );
};

export default OrderActivityFeedContainer;
