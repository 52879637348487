import type { DatePrefix } from '@zen/Components/DateTimeRange';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { StatusCount } from './components/StatusFilters/types';
import type { RoadTrackedShipmentFiltersQueryResult } from './graphql';
import type { RoadTrackedShipmentFilter, RoadTrackingStopUpdate, StopType } from './types';
import { ConfidenceLevel, RoadTrackedShipmentStatus } from './types';

export const buildStatusCounts = (queryResult: Optional<RoadTrackedShipmentFiltersQueryResult>): StatusCount[] => {
  if (!queryResult?.roadTrackedShipmentFilters) return [];

  return queryResult.roadTrackedShipmentFilters;
};

export const getInitialActiveStatus = (roadTrackedShipmentFilters: RoadTrackedShipmentFilter[]): RoadTrackedShipmentStatus => {
  const hasShipmentsWithStatus = (shipmentStatus: RoadTrackedShipmentStatus): boolean => {
    return !!roadTrackedShipmentFilters.find(({ status }) => status === shipmentStatus)?.count;
  };

  if (!hasActiveShipments(roadTrackedShipmentFilters) || hasShipmentsWithStatus(RoadTrackedShipmentStatus.ON_TIME)) {
    return RoadTrackedShipmentStatus.ON_TIME;
  }

  return hasShipmentsWithStatus(RoadTrackedShipmentStatus.LATE)
    ? RoadTrackedShipmentStatus.LATE
    : RoadTrackedShipmentStatus.EARLY;
};

export const getShipmentStop = (
  latestStopUpdates: Optional<RoadTrackingStopUpdate[]>,
  stopType: StopType
): Optional<RoadTrackingStopUpdate> => {
  return latestStopUpdates?.find((latestStopUpdate) => latestStopUpdate?.stopType?.includes(stopType));
};

export const hasActiveShipments = (filters: RoadTrackedShipmentFilter[]): boolean => filters.some(({ count }) => count > 0);

const datePrefixMapping: Record<ConfidenceLevel, DatePrefix> = {
  [ConfidenceLevel.ACTUAL]: 'act.',
  [ConfidenceLevel.ESTIMATED]: 'est.'
};

export const getDatePrefix = (confidenceLevel: Optional<ConfidenceLevel>): Nullable<DatePrefix> => {
  if (!confidenceLevel) {
    return null;
  }

  return datePrefixMapping[confidenceLevel];
};
