import type {
  AirlineCarrier,
  CanAddCargoResult,
  Courier,
  Haulier,
  Journey,
  JourneyLastUpdated,
  JourneyLeg,
  JourneyManageDatesValidationResult,
  JourneyRemoveActualDateValidationResult,
  JourneyScheduledLegInput,
  JourneyShippingCargoReadyDateMilestone,
  JourneyShippingMilestone,
  JourneyStop,
  JourneyToggleCollectionValidationResult,
  JourneyVehicle,
  JourneyVehicleProperty,
  LocalDateTimeRange,
  LocalDateTimeRangeInput,
  LocalDateTimeType,
  OceanCarrier,
  RailwayCompany,
  ScheduledLegInput,
  VehiclePropertyInput
} from '@zen/graphql/types.generated';
import {
  JourneyActualDateReasonEnum,
  JourneyLegTypeEnum,
  JourneyManageDatesReasonEnum,
  JourneyModeOfTransportEnum,
  JourneyModificationSourceEnum,
  JourneyPlannedDateReasonEnum,
  JourneyRemoveActualDateReasonEnum,
  JourneyRequestedDateReasonEnum,
  JourneyShippingMilestoneCategoryEnum,
  JourneyShippingMilestoneNameEnum,
  JourneyShippingMilestoneRequestedByEnum,
  JourneyStopActionEnum,
  JourneyStopLocationTypeEnum,
  JourneyToggleCollectionReasonEnum,
  JourneyVehicleTypeEnum,
  ShippingMilestoneDateTypeEnum,
  ShippingMilestoneTypeEnum,
  UpdateScheduleType,
  VehiclePropertyNameEnum
} from '@zen/graphql/types.generated';
import type { Nullable, Optional } from '@zen/utils/typescript';

export {
  JourneyActualDateReasonEnum,
  JourneyLegTypeEnum,
  JourneyManageDatesReasonEnum,
  JourneyModeOfTransportEnum,
  JourneyModificationSourceEnum,
  JourneyPlannedDateReasonEnum,
  JourneyRemoveActualDateReasonEnum,
  JourneyRequestedDateReasonEnum,
  JourneyShippingMilestoneCategoryEnum,
  JourneyShippingMilestoneNameEnum,
  JourneyShippingMilestoneRequestedByEnum,
  JourneyStopActionEnum,
  JourneyStopLocationTypeEnum,
  JourneyToggleCollectionReasonEnum,
  JourneyVehicleTypeEnum,
  ShippingMilestoneDateTypeEnum,
  ShippingMilestoneTypeEnum,
  UpdateScheduleType,
  VehiclePropertyNameEnum
};

export type {
  AirlineCarrier,
  CanAddCargoResult,
  Courier,
  Haulier,
  Journey,
  JourneyLastUpdated,
  JourneyLeg,
  JourneyManageDatesValidationResult,
  JourneyRemoveActualDateValidationResult,
  JourneyScheduledLegInput,
  JourneyShippingCargoReadyDateMilestone,
  JourneyShippingMilestone,
  JourneyStop,
  JourneyToggleCollectionValidationResult,
  JourneyVehicle,
  JourneyVehicleProperty,
  LocalDateTimeRange,
  LocalDateTimeRangeInput,
  LocalDateTimeType,
  OceanCarrier,
  RailwayCompany,
  ScheduledLegInput,
  VehiclePropertyInput
};

export interface MilestoneDatesType {
  actual: Optional<LocalDateTimeType>;
  initialEstimate: Optional<LocalDateTimeRange>;
  latestEstimate: Optional<LocalDateTimeRange>;
}

export interface MilestoneType {
  dates: MilestoneDatesType;
  isCompleted: boolean;
  name: JourneyShippingMilestoneNameEnum;
}

export interface TransitInfo {
  isCompleted: boolean;
  isInTransit: boolean;
  legType: JourneyLegTypeEnum;
  modeOfTransport: JourneyModeOfTransportEnum;
  transitTimeInMinutes: Optional<number>;
  vehicleId: string;
  vehicleProperties: JourneyVehicleProperty[];
}

export interface JourneyStopWithTransitInfo extends JourneyStop {
  outgoingTransitInfo?: TransitInfo;
}

export const JourneyTrackingCategory: string = 'Journey';

export enum JourneyTrackingAction {
  CARGO_JOURNEY_VISIBLE = 'CargoJourneyVisible',
  COMPLETED_MILESTONES_VISIBILITY_CHANGE = 'CompletedMilestonesVisibilityChange',
  MILESTONE_HISTORY_MILESTONE_SELECTED = 'MilestoneHistoryMilestoneSelected',
  MILESTONE_HISTORY_OPENED = 'MilestoneHistoryOpened'
}

export type JourneyStopReference = Pick<
  JourneyStop,
  'canUpdateReference' | 'canViewReference' | 'id' | 'reference' | 'stopAction'
>;

export interface CarriageLeg {
  canManagePlannedDates: boolean;
  id: string;
  vehicleId: string;
}

export enum MilestoneDateType {
  ACTUAL = 'ACTUAL',
  DRAFT = 'DRAFT',
  PLANNED = 'PLANNED',
  REQUESTED = 'REQUESTED'
}

export interface JourneyMilestoneWithMetadata extends JourneyShippingMilestone {
  stopAction: JourneyStopActionEnum;
}

export type CargoJourneyDetails<T extends { name: JourneyShippingMilestoneNameEnum }, K extends JourneyLegStop<T>> = {
  journey?: Nullable<{
    legs?: Nullable<Array<LegWithMetaData<T, K>>>;
  }>;
};

export type MilestoneWithMetadata<T extends { name: JourneyShippingMilestoneNameEnum }, K extends JourneyLegStop<T>> = Optional<
  K & K['shippingMilestones'][0]
>;

export type JourneyLegStop<T extends { name: JourneyShippingMilestoneNameEnum }> = {
  locationType: JourneyStopLocationTypeEnum;
  reference?: Optional<string>;
  shippingMilestones: T[];
  stopAction: JourneyStopActionEnum;
};

export type LegWithMetaData<T extends { name: JourneyShippingMilestoneNameEnum }, K extends JourneyLegStop<T>> = {
  from?: K;
  to?: K;
};
