import type { FC, PropsWithChildren, ReactNode } from 'react';

import { Container, IconButton } from '@zen/DesignSystem';

interface Props {
  heading: string;
  onEdit?: () => void;
}
const EditableContentContainer: FC<PropsWithChildren<Props>> = ({ onEdit, heading, children }) => {
  const editButton: ReactNode = onEdit ? (
    <IconButton icon="zicon-edit" onClick={onEdit} size="medium" title="Edit" variant="ghost" />
  ) : undefined;

  return (
    <Container actions={editButton} className="col-span-8" title={heading}>
      <div className="grid grid-cols-4 gap-3">{children}</div>
    </Container>
  );
};

export default EditableContentContainer;
