import type { FC, ReactNode } from 'react';

import { Banner } from '@zen/DesignSystem';
import type { JourneyLastUpdated } from '@zen/Journey/types';
import { formatDate } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

import { getUsername } from '../MilestoneDatesHistory/helpers';

interface Props {
  action?: ReactNode;
  lastUpdated: Optional<JourneyLastUpdated>;
}

const LastUpdated: FC<Props> = ({ action, lastUpdated }) => {
  const message: ReactNode = (
    <div className="flex space-x-2">
      <div>
        Last updated: {formatDate(lastUpdated?.updatedAt || '')}{' '}
        <span>{`by ${getUsername(lastUpdated?.updatedBySource, lastUpdated?.updatedByUser || lastUpdated?.updatedBy)}`}</span>
      </div>
      {action && <div>{action}</div>}
    </div>
  );

  return <Banner data-testid="last-updated" icon="zicon-refresh" message={message} />;
};

export default LastUpdated;
