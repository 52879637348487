import type { FC, ReactNode } from 'react';
import { useSessionStorage } from 'react-use';

import type { Customer, OrderBasketLot, OrderListViewItem, OrderListViewLot } from '@zen/Orders/types';
import { getLotIds } from '@zen/Orders/utils';
import type { Optional } from '@zen/utils/typescript';

import BasketContext from './BasketContext';
import { prepareBasketItems } from './utils';

interface Props {
  children: ReactNode;
  isEnabled?: boolean;
}

const OrderBasket: FC<Props> = ({ children, isEnabled = true }) => {
  const [items, setItems] = useSessionStorage<OrderBasketLot[]>('orderBasketList', []);
  const [customer, setCustomer] = useSessionStorage<Optional<Customer>>('customer', null);

  const hasItems: boolean = items.length > 0;

  const addItems = (orderLots: OrderListViewLot[]): void => {
    const newBasketItems: OrderBasketLot[] = prepareBasketItems(orderLots);

    setItems([...items, ...newBasketItems]);
  };

  const isOrderSelected = (order: OrderListViewItem, orderLots: Optional<OrderListViewLot[]>): boolean => {
    return !!(orderLots && getLotIds(orderLots).every(isSelected));
  };

  const isOrderPartiallySelected = (order: OrderListViewItem, orderLots: Optional<OrderListViewLot[]>): boolean => {
    return !!(orderLots && getLotIds(orderLots).some(isSelected) && !isOrderSelected(order, orderLots));
  };

  const isSelected = (id: string): boolean => items.some((item) => item.id === id);

  const removeItems = (ids: string[]): void => setItems([...items.filter((i) => !ids.includes(i.id))]);

  const removeAllItems = (): void => {
    window.sessionStorage.removeItem('customer');
    window.sessionStorage.removeItem('orderBasketList');
  };

  const updateItem = (id: string, values: Partial<OrderBasketLot>): void => {
    const updatedItems: OrderBasketLot[] = items.map((item) => (item.id === id ? { ...item, ...values } : item));

    setItems(updatedItems);
  };

  const initializeOrderLots = () => {};

  const context = {
    addItems,
    customer,
    hasItems,
    initializeOrderLots,
    isBasketMode: isEnabled,
    items,
    isOrderSelected,
    isOrderPartiallySelected,
    isSelected,
    removeItems,
    updateItem,
    removeAllItems,
    setCustomer
  };

  return <BasketContext.Provider value={context}>{children}</BasketContext.Provider>;
};

export default OrderBasket;
