import axios from 'axios';
import type { FC, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import { version } from '@zen/version.json';

import UpdateRequiredModal from './UpdateRequiredModal';

const thirtySecondsInMilliseconds: number = 30000;

interface Props {
  isProduction: boolean;
}

const AutomaticAppUpdates: FC<PropsWithChildren<Props>> = ({ children, isProduction }) => {
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);

  const handleConfirm = (): void => window.location.reload();

  useEffect(() => {
    if (!isProduction) return;

    const getLatestApplicationVersion = async () => {
      try {
        const { data } = await axios.get('/version.json');

        return data.version;
      } catch {
        return -1;
      }
    };

    const timer = setInterval(async () => {
      if (isDialogVisible) return;

      const latestVersion = await getLatestApplicationVersion();

      if (latestVersion > version) {
        setIsDialogVisible(true);
      }
    }, thirtySecondsInMilliseconds);

    return () => clearInterval(timer);
  }, [isDialogVisible, isProduction]);

  return (
    <div>
      {children}
      <UpdateRequiredModal isVisible={isDialogVisible} onConfirm={handleConfirm} />
    </div>
  );
};

export default AutomaticAppUpdates;
