import type { FC } from 'react';

import { getOrganisationName, getPermittedRoleLabel } from '@zen/ActivityFeed/helpers/partiesDetailsHelper';
import type { PermittedParty } from '@zen/ActivityFeed/types';
import { Role } from '@zen/ActivityFeed/types';
import useRole from '@zen/Auth/hooks/useRole';

interface Props {
  party: PermittedParty;
}

const PermittedPartiesItem: FC<Props> = ({ party }) => {
  const currentUserRole = useRole();

  const { organisationName, role } = party;
  const isCustomerViewingAgent = currentUserRole === Role.CUSTOMER_USER && role === Role.AGENT_FORWARDER;

  return (
    <div className="flex items-center mt-2" data-testid="permitted-parties-item">
      <div className="text-xs leading-tight">
        <p className="text-grey-base">{getPermittedRoleLabel(role, isCustomerViewingAgent)}</p>
        <p className="text-grey-dark truncate">{getOrganisationName(organisationName, isCustomerViewingAgent)}</p>
      </div>
    </div>
  );
};

export default PermittedPartiesItem;
