import type { FC } from 'react';

import RoleProvider from '@zen/Auth/RoleProvider';
import UserDataProvider from '@zen/Auth/UserDataProvider';
import { VisitedAccountsProvider } from '@zen/Components/VisitedAccounts';

import Dashboard from './Dashboard';

const DashboardContainer: FC = () => {
  return (
    <VisitedAccountsProvider>
      <UserDataProvider>
        <RoleProvider>
          <Dashboard />
        </RoleProvider>
      </UserDataProvider>
    </VisitedAccountsProvider>
  );
};

export default DashboardContainer;
