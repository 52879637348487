import type { FC } from 'react';

import type { CargoModeEnum } from '@zen/Cargo';
import { cargoModeOptions } from '@zen/Cargo';
import type { SelectProps } from '@zen/DesignSystem';
import { Select } from '@zen/DesignSystem';

interface Props extends Omit<SelectProps<CargoModeEnum>, 'options'> {}

const CargoModeSelect: FC<Props> = (props) => {
  const { className, onBlur, onChange, value, name, isLoading, label, hasError, isClearable, isDisabled, variant } = props;

  return (
    <Select
      className={className}
      hasError={hasError}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={cargoModeOptions}
      renderMenuInPortal={true}
      value={value}
      variant={variant}
    />
  );
};

export default CargoModeSelect;
