import type { ObjectSchema, TestContext } from 'yup';
import { array, object, string } from 'yup';

import type { ModeOfTransport } from '@zen/Booking';
import { isAirBooking, isOceanBooking, isRailBooking } from '@zen/Booking';
import type { Optional } from '@zen/utils/typescript';

import type { Stop } from './types';
import { AirScheduleCarrier } from './types';

const getStopsAndCurrentIndex = (context: TestContext) => {
  const stops: Stop[] = context.from?.find(({ value }) => value?.stops)?.value.stops;
  const currentIndex: number = parseInt(context.path.split('[')[1].split(']')[0], 10);

  return {
    stops: stops || [],
    currentIndex
  };
};

const isAirCourierShipment = (context: TestContext) => {
  return (
    context.from?.find(({ value }) => value?.airScheduleCarrierType)?.value.airScheduleCarrierType === AirScheduleCarrier.COURIER
  );
};

const hasDuplicates = (list: string[]): boolean =>
  list.some((item) => {
    return list.indexOf(item) !== list.lastIndexOf(item);
  });

export const getValidationSchema = (modeOfTransport: Optional<ModeOfTransport>): ObjectSchema<{}> =>
  object().shape({
    carrier: string().test('carrier test', (value, context: TestContext) => {
      if (value) return true;

      if (isAirBooking(modeOfTransport) && isAirCourierShipment(context)) {
        return context.createError({ message: 'Please select courier.' });
      }

      if (isAirBooking(modeOfTransport)) {
        return context.createError({ message: 'Please select airline.' });
      }

      if (isOceanBooking(modeOfTransport)) {
        return context.createError({ message: 'Please select carrier.' });
      }

      if (isRailBooking(modeOfTransport)) {
        return context.createError({ message: 'Please select railway company.' });
      }
    }),
    trackingNumber: string()
      .nullable()
      .test('carrier test', (value, context: TestContext) => {
        if (isAirCourierShipment(context) && !value) {
          return context.createError({ message: 'Please enter tracking number.' });
        }

        return true;
      }),
    stops: array().of(
      object().shape({
        location: object()
          .nullable()
          .test('location test', (value, context: TestContext) => {
            const { stops } = getStopsAndCurrentIndex(context);

            const locationIds: string[] = stops
              .filter((stop) => stop?.location?.unlocode)
              .map(({ location }) => location?.unlocode) as string[];

            if (!value) {
              return context.createError({ message: 'Please select location.' });
            }

            if (hasDuplicates(locationIds)) {
              return context.createError({ message: 'Location must be unique.' });
            }

            return true;
          }),
        arrivalDate: string().test('arrival date test', (value, context: TestContext) => {
          const { currentIndex } = getStopsAndCurrentIndex(context);
          const isFirstStop: boolean = currentIndex === 0;

          if (isFirstStop || value) return true;

          return context.createError({ message: 'Please pick date.' });
        }),
        departureDate: string().test('departure date test', (value, context: TestContext) => {
          const { currentIndex, stops } = getStopsAndCurrentIndex(context);
          const isLastStop: boolean = currentIndex === stops.length - 1;

          if (isLastStop || value) return true;

          return context.createError({ message: 'Please pick date.' });
        }),
        flightNumber: string()
          .nullable()
          .test('flightNumber', function test(value, context: TestContext) {
            const { currentIndex, stops } = getStopsAndCurrentIndex(context);
            const isLastStop: boolean = currentIndex === stops.length - 1;

            const flightNumbers: string[] = stops
              .filter((stop) => stop?.voyageNumber)
              .map(({ voyageNumber }) => voyageNumber || '');

            if (isLastStop || !isAirBooking(modeOfTransport) || isAirCourierShipment(context)) return true;

            if (!value) return context.createError({ message: 'Please enter flight number.' });

            if (hasDuplicates(flightNumbers)) {
              return context.createError({ message: 'Flight number must be unique.' });
            }

            return true;
          }),
        vesselName: string()
          .nullable()
          .test('vesselName', function test(value, context: TestContext) {
            const { currentIndex, stops } = getStopsAndCurrentIndex(context);
            const isLastStop: boolean = currentIndex === stops.length - 1;

            if (isLastStop || value || !isOceanBooking(modeOfTransport)) return true;

            return this.createError({ message: 'Please enter vessel name.' });
          }),
        voyageNumber: string()
          .nullable()
          .test('voyageNumber', function test(value, context: TestContext) {
            const { currentIndex, stops } = getStopsAndCurrentIndex(context);
            const isLastStop: boolean = currentIndex === stops.length - 1;

            const voyageNumbers: string[] = stops
              .filter((stop) => stop?.voyageNumber)
              .map(({ voyageNumber }) => voyageNumber || '');

            if (isLastStop || !isOceanBooking(modeOfTransport)) return true;

            if (!value) return context.createError({ message: 'Please enter voyage number.' });

            if (hasDuplicates(voyageNumbers)) {
              return context.createError({ message: 'Voyage number must be unique.' });
            }

            return true;
          })
      })
    )
  });
