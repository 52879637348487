import type { FC } from 'react';
import { useState } from 'react';

import { useBulkSelectionContext } from '@zen/Components';
import type { Optional } from '@zen/utils/typescript';

import type { TaskUrgency } from '../../TaskLists/types';
import DeleteAction from './DeleteAction';
import MarkCompletedAction from './MarkCompletedAction';

interface Props {
  canCompleteTask?: boolean;
  canDeleteTask?: boolean;
  onTaskListChange?: () => void;
  tableId?: string;
  taskDescription: string;
  taskId: string;
  taskUrgency?: TaskUrgency;
  zencargoReference: Optional<string>;
}

const TaskListActions: FC<Props> = (props) => {
  const { onTaskListChange, taskId, tableId, canCompleteTask = false, canDeleteTask = false, ...trackingProps } = props;

  const { removeItems } = useBulkSelectionContext();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleActionStart = (): void => setIsDisabled(true);
  const handleActionEnd = (): void => {
    setIsDisabled(false);

    if (tableId) {
      removeItems(tableId, [taskId]);
    }
  };

  return (
    <div className="flex items-center justify-center gap-3" data-testid="tasklist-options">
      {canCompleteTask && (
        <MarkCompletedAction
          isDisabled={isDisabled}
          onActionEnd={handleActionEnd}
          onActionStart={handleActionStart}
          onActionSuccess={onTaskListChange}
          taskId={taskId}
          trackingProps={trackingProps}
        />
      )}
      {canDeleteTask && (
        <DeleteAction
          isDisabled={isDisabled}
          onActionEnd={handleActionEnd}
          onActionStart={handleActionStart}
          onActionSuccess={onTaskListChange}
          taskId={taskId}
          trackingProps={trackingProps}
        />
      )}
    </div>
  );
};

export type { Props as TaskListActionsProps };

export default TaskListActions;
