import type { TableColumn } from '@zen/DesignSystem';
import { Pill } from '@zen/DesignSystem';
import { formatDate } from '@zen/utils/dateTime';

import type { ScimApiKey } from '../graphql';
import { ApiKeyStatus } from '../graphql';
import DeleteKeyButton from './DeleteKeyButton';

const statusColumn: TableColumn<ScimApiKey> = {
  key: 'status',
  title: 'Status',
  width: '150px',
  render: (_, { status }) => {
    return status === ApiKeyStatus.ACTIVE ? <Pill type="success">Active</Pill> : <Pill type="pending">Expired</Pill>;
  },
  resizable: false,
  sortable: false
};

const idColumn: TableColumn<ScimApiKey> = {
  key: 'apiKeyId',
  title: 'ID',
  render: (_, { id }) => {
    return id;
  },
  resizable: false,
  sortable: false
};

const expiresColumn: TableColumn<ScimApiKey> = {
  key: 'expiryDate',
  title: 'Expires',
  width: '150px',
  render: (_, { expiryDate }) => {
    return formatDate(expiryDate);
  },
  resizable: false,
  sortable: false
};

const actionColumn: TableColumn<ScimApiKey> = {
  key: 'deleteKey',
  title: '',
  fixed: 'right',
  render: (_, { id }) => {
    return <DeleteKeyButton apiKeyId={id} />;
  },
  resizable: false,
  sortable: false,
  width: '5%'
};

const getColumns = () => [statusColumn, idColumn, expiresColumn, actionColumn];

export { getColumns };
