import type { CSSProperties, FC } from 'react';
import { useEffect, useState } from 'react';
import { VictoryLabel, VictoryPie } from 'victory';

import { Color } from '@zen/Styleguide';

const defaultStyles = { fontSize: 90, fill: Color.NAVY_BASE };

const getChartData = (value: number) => {
  return [
    { x: 1, y: value },
    { x: 2, y: 100 - value }
  ];
};

const calculateWidthParameters = (width: number, pieRadius: number): { circleRadius: number; pieInnerRadius: number } => {
  const circleRadius = pieRadius - width / 2;
  const pieInnerRadius = pieRadius - width;

  return { circleRadius, pieInnerRadius };
};

export interface Props {
  color: string;
  label?: string | string[];
  labelLineHeight?: (string | number)[];
  labelStyle?: CSSProperties | CSSProperties[];
  labelVerticalPosition?: number;
  lineWidth?: number;
  value: number;
}

const DonutChart: FC<Props> = (props) => {
  const [currentValue, setCurrentValue] = useState<number>(0);
  const { value, color, label, labelStyle = defaultStyles, labelLineHeight, labelVerticalPosition = 200, lineWidth = 34 } = props;
  const pieRadius = 174;
  const { circleRadius, pieInnerRadius } = calculateWidthParameters(lineWidth, pieRadius);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <svg height="100%" viewBox="0 0 400 400" width="100%">
      <circle cx={200} cy={200} fill="transparent" r={circleRadius} stroke="#eee" strokeWidth={lineWidth} />
      <VictoryPie
        animate={{ duration: 1000 }}
        cornerRadius={25}
        data={getChartData(currentValue)}
        height={400}
        innerRadius={pieInnerRadius}
        labels={() => null}
        padding={25}
        radius={pieRadius}
        standalone={false}
        style={{ data: { fill: ({ datum }) => (datum.x === 1 ? color : 'transparent') } }}
        width={400}
      />
      <VictoryLabel
        lineHeight={labelLineHeight}
        style={labelStyle}
        text={label || value}
        textAnchor="middle"
        verticalAnchor="middle"
        x={200}
        y={labelVerticalPosition}
      />
    </svg>
  );
};

export default DonutChart;
