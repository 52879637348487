import { type FC, useMemo, useState } from 'react';

import { Table } from '@zen/DesignSystem';
import { SortingOrder, type SortInput } from '@zen/types';

import type { BusinessUnitTableItem } from '../types';
import getColumnsConfiguration from './businessUnitsSubListTableConfiguration';
import { sortBusinessUnits } from './helpers';

interface Props {
  businessUnits: BusinessUnitTableItem[];
  canEditBusinessUnit?: boolean;
  tableId: string;
}

const initialOrder: SortInput = {
  direction: SortingOrder.DESC,
  field: 'tradingName'
};

const BusinessUnitsSubList: FC<Props> = ({ businessUnits, canEditBusinessUnit = true, tableId }) => {
  const [order, setOrder] = useState<SortInput>(initialOrder);

  const columns = useMemo(() => {
    return getColumnsConfiguration(canEditBusinessUnit);
  }, []);

  return (
    <Table<BusinessUnitTableItem>
      className="flex-1 my-2"
      columns={columns}
      data={sortBusinessUnits(businessUnits, order)}
      onOrderChange={setOrder}
      order={order}
      rowKey="id"
      tableId={tableId}
    />
  );
};

export default BusinessUnitsSubList;
export type { Props as BusinessUnitsSubListProps };
