import cx from 'classnames';
import type { ReactNode } from 'react';

import { FilterMultiSelect } from '@zen/Components/Filters';
import { IconButton, type Option } from '@zen/DesignSystem';
import { useInputStyles } from '@zen/DesignSystem';
import { useRateCardContext } from '@zen/RateCards/RateCardContext';
import type { ArrayElement, Nullable, Optional } from '@zen/utils/typescript';

import RateCardMultiSelectLabel from '../RateCardMultiSelectLabel';
import { getSelectedOptions } from './helpers';

interface Props<T> {
  className?: string;
  hasError?: boolean;
  isDisabled?: boolean;
  loading?: boolean;
  onBlur?: () => void;
  onChange: (values: Nullable<ArrayElement<T>[]>) => void;
  options: Option<ArrayElement<T>>[];
  selectAllLabel?: string;
  value: Nullable<ArrayElement<T>[]>;
  variant?: 'default' | 'inline';
}

const RateCardMultiSelect = <T extends {}>(props: Props<T>) => {
  const {
    className,
    hasError = false,
    isDisabled = false,
    onBlur,
    loading = false,
    onChange,
    options,
    selectAllLabel,
    value,
    variant = 'default'
  } = props;

  const { isEditable } = useRateCardContext();
  const handleChange = (newValues: Optional<ArrayElement<T>[]>): void => {
    onChange(newValues?.sort() || []);
  };
  const isInline: boolean = variant === 'inline';

  const classNames = cx(
    {
      'p-1': !!value?.length && isEditable,
      'px-3 py-1.5': !value?.length,
      [useInputStyles({ disabled: isDisabled, error: hasError })]: !isInline,
      'hover:bg-grey-lighter rounded transition duration-300 ease-in-out': isInline && !isDisabled
    },
    'w-full h-10 flex items-center justify-between',
    className
  );

  const handleClear = (): void => {
    onChange([]);
  };
  const shouldRenderClearButton: boolean = !!value?.length && !isInline && !isDisabled;

  return (
    <div className={classNames} data-testid="rate-card-multi-select">
      <FilterMultiSelect
        className="w-full"
        deselectFilter={() => {
          onBlur?.();
          handleChange(value);
        }}
        disabled={isDisabled}
        loading={loading}
        onChange={handleChange}
        onDelete={() => {}}
        options={options}
        placement="bottom-start"
        selectAllLabel={selectAllLabel}
        selectAllOption={true}
        value={value}
      >
        {(label: ReactNode) => {
          return (
            <RateCardMultiSelectLabel
              content={label}
              isDisabled={isDisabled}
              isSelectAll={options.length > 1 && value?.length === options.length}
              selectAllLabel={selectAllLabel || ''}
              selectedOptions={getSelectedOptions(options, value)}
              variant={variant}
            />
          );
        }}
      </FilterMultiSelect>
      {shouldRenderClearButton && <IconButton icon="zicon-close" onClick={handleClear} size="small" variant="ghost" />}
    </div>
  );
};

export type { Props as RateCardMultiSelectProps };

export default RateCardMultiSelect;
