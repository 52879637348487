import type { FC } from 'react';

import type { Account, AccountLocation } from '@zen/Accounts/types';
import Address from '@zen/Components/Address';
import type { NetworksOrgLoc } from '@zen/Networks/types';

import type { TradeParty } from './types';

export const isNetworksOrgLocType = (arg: TradeParty): arg is NetworksOrgLoc => 'location' in arg;
export const isAccountLocationType = (arg: TradeParty): arg is AccountLocation => 'address' in arg;
export const isAccountType = (arg: TradeParty): arg is Account => 'tradingName' in arg;

interface Props {
  tradeParty: TradeParty;
}

const NetworkContactDetails: FC<Props> = ({ tradeParty }) => {
  if (isAccountType(tradeParty)) {
    return <p className="font-bold leading-normal">{tradeParty.tradingName}</p>;
  }

  if (isAccountLocationType(tradeParty)) {
    const { line1, line2, ...rest } = tradeParty.address;

    return (
      <div>
        {tradeParty.name && <p className="font-bold leading-normal">{tradeParty.name}</p>}
        <Address address={{ ...rest, street: `${line1} ${line2}` }} />
      </div>
    );
  }

  if (isNetworksOrgLocType(tradeParty)) {
    const contact = tradeParty?.contact;
    const organisation = tradeParty?.organisation;
    const location = tradeParty?.location;
    const address = location?.address;

    const fullName: string = contact ? `${contact.firstName} ${contact.lastName}` : '-';

    return (
      <div className="relative w-full text-sm text-grey-dark" data-testid="network-contact-details">
        {contact && <p className="font-bold leading-normal">{fullName}</p>}
        {organisation && <p className="mb-1 text-xs text-grey-base">{organisation?.name}</p>}
        {contact && (
          <div>
            <p>{contact.email}</p>
            <p className="mb-4">{contact.phoneNumber}</p>
          </div>
        )}
        <div className="mt-4">
          {location?.name && <p className="font-bold">{location.name}</p>}
          {address && <Address address={address} />}
        </div>
      </div>
    );
  }

  return null;
};

export default NetworkContactDetails;
