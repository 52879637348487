import cx from 'classnames';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import { Icon, Tooltip } from '@zen/DesignSystem';

import StakeholderDetails from '../../StakeholderDetails';
import type { Subscriber } from '../../types';

interface Props {
  isDisabled: boolean;
  onUnsubscribe: (stakeholder: Subscriber) => void;
  stakeholder: Subscriber;
}

const SubscriberListItem: FC<Props> = ({ isDisabled, onUnsubscribe, stakeholder }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const className: string = cx(
    {
      'hover:bg-grey-lightest': !isDisabled
    },
    'py-3 text-sm flex justify-between items-center group px-6 h-12'
  );

  const iconClassName: string = cx(
    {
      'cursor-pointer': !isDisabled,
      'cursor-not-allowed text-grey-lighter': isDisabled
    },
    'hidden group-hover:block text-xs'
  );

  const handleClick = () => {
    if (isDisabled) {
      return;
    }

    setIsEditMode(true);
  };

  const handleUnsubscribe = (): void => {
    onUnsubscribe(stakeholder);
  };

  const renderConfirmationButtons = () => {
    return (
      <div>
        <span className="text-red-base mr-2 cursor-pointer" onClick={handleUnsubscribe}>
          Unsubscribe
        </span>
        <span className="text-grey-base cursor-pointer" onClick={() => setIsEditMode(false)}>
          Cancel
        </span>
      </div>
    );
  };

  const renderIcon = () => {
    const getIcon = (isButtonDisabled: boolean): ReactNode => (
      <Icon className={iconClassName} disabled={isButtonDisabled} icon="zicon-trash" interactive={true} onClick={handleClick} />
    );

    if (isDisabled) {
      return <Tooltip tooltipContent="You can not unsubscribe this user">{getIcon(true)}</Tooltip>;
    }

    return getIcon(false);
  };

  return (
    <div className={className} data-testid="subscriber-list-item">
      <StakeholderDetails showCompanyName={!isEditMode} stakeholder={stakeholder} />
      {isEditMode ? renderConfirmationButtons() : renderIcon()}
    </div>
  );
};

export default SubscriberListItem;
