import type { FC, ReactNode } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import { Form, FormButtons } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import NetworksContactFormInput from '@zen/Networks/NetworksContactPicker/NetworksContactFormInput';
import { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { OriginDestinationFormData, UpdateOriginAndDestinationInput } from '../types';
import { checkInitialValueEqual } from './helpers';

interface Props {
  accountId: string;
  initialValues: OriginDestinationFormData;
  onCancel?: () => void;
  onSubmit: (input: UpdateOriginAndDestinationInput) => Promise<IOkOrErrorResult>;
  onSuccess: (successMessage: string) => void;
}

const OriginAndDestinationForm: FC<Props> = ({ accountId, onSuccess, initialValues, onCancel, onSubmit }) => {
  const renderFormButtons = ({ isSubmitting, values }: FormInstance<OriginDestinationFormData>): ReactNode => {
    const shouldDisabledButtonOrigin: boolean = checkInitialValueEqual(values, initialValues, 'networksOrigin');
    const shouldDisableButtonDestination: boolean = checkInitialValueEqual(values, initialValues, 'networksDestination');

    const isDisabled: boolean = (shouldDisabledButtonOrigin && shouldDisableButtonDestination) || isSubmitting;

    return (
      <FormButtons isDisabled={isDisabled} isSubmitting={isSubmitting} layout="fixed" text="Save">
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  const handleSubmit = async (formData: OriginDestinationFormData): Promise<IOkOrErrorResult> => {
    const { networksOrigin, networksDestination } = formData;

    const shouldUpdateOrigin: boolean = !checkInitialValueEqual(formData, initialValues, 'networksOrigin');
    const shouldUpdateDestination: boolean = !checkInitialValueEqual(formData, initialValues, 'networksDestination');

    if (shouldUpdateOrigin) {
      await onSubmit({
        assignmentName: AssignmentTypeValue.ORIGIN,
        assignableId: networksOrigin?.id
      });
      onSuccess('Origin updated');
    }

    if (shouldUpdateDestination) {
      await onSubmit({
        assignmentName: AssignmentTypeValue.DESTINATION,
        assignableId: networksDestination?.id
      });
      onSuccess('Destination updated');
    }

    return Promise.resolve({ ok: '', error: null });
  };

  return (
    <Form formButtons={renderFormButtons} initialValues={initialValues} onSubmit={handleSubmit}>
      {(form: FormInstance<OriginDestinationFormData>) => {
        return (
          <>
            <NetworksContactFormInput
              accountUuid={accountId}
              assignmentType={AssignmentTypeValue.ORIGIN}
              domainName={AssignmentTargetTypeEnum.BOOKING}
              entityType="location"
              formTitle="Update origin"
              isClearable={false}
              label="Origin"
              name="networksOrigin"
              showAddButton={false}
            />
            <NetworksContactFormInput
              accountUuid={accountId}
              assignmentType={AssignmentTypeValue.DESTINATION}
              domainName={AssignmentTargetTypeEnum.BOOKING}
              entityType="location"
              formTitle="Update destination"
              isClearable={false}
              label="Destination"
              name="networksDestination"
              showAddButton={false}
            />
          </>
        );
      }}
    </Form>
  );
};

export default OriginAndDestinationForm;
