import cx from 'classnames';
import type { FC, SyntheticEvent } from 'react';

import type { IconButtonSize } from '@zen/DesignSystem';
import { IconButton } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  className?: string;
  iconSize?: IconButtonSize;
  isDisabled?: boolean;
  isPinned?: boolean;
  onClick?: (event: SyntheticEvent) => void;
}

const PinButton: FC<Props> = (props) => {
  const { className, iconSize = 'small', isDisabled = false, isPinned = false, onClick } = props;

  const icon: IconName = isPinned ? 'zicon-un-pinner' : 'zicon-pinner';
  const title: string = isPinned ? 'Unpin shipment from dashboard' : 'Pin shipment to dashboard';

  const classNames: string = cx(
    {
      'text-grey-base cursor-not-allowed': isDisabled,
      'cursor-pointer': !isDisabled
    },
    className
  );

  return (
    <IconButton
      className={classNames}
      data-testid="pin-button"
      icon={icon}
      onClick={onClick}
      size={iconSize}
      title={title}
      variant="secondary"
    />
  );
};

export default PinButton;
