import type { CostTrackingCostItem } from '@zen/graphql/types.generated';
import { Applicability, CostTrackingCostItemStatus, CostTrackingRevenueItemStatus } from '@zen/graphql/types.generated';
import type { DeepNonNullable, Optional } from '@zen/utils/typescript';

import { ChargeItemType } from '../types';
import type { GetChargesQueryResult } from './graphql/getCharges.generated';

export type { CostTrackingRevenueItem } from '@zen/graphql/types.generated';
export { ChargeBasisGroup, Currency } from '@zen/graphql/types.generated';

export type ChargeItem = DeepNonNullable<GetChargesQueryResult, 'charges'>['bookings']['nodes']['costTracking']['charges'];

interface CommonChargeListItem extends Omit<CostTrackingCostItem, 'status' | '__typename'> {
  applicability: Applicability;
  chargeId: string;
  chargeType: ChargeItem['chargeType'];
  chargeTypeGroup: ChargeItemType;
}

export interface ChargeCostItem extends CommonChargeListItem {
  status?: Optional<CostTrackingCostItemStatus>;
}

export interface ChargeRevenueItem extends CommonChargeListItem {
  status?: Optional<CostTrackingRevenueItemStatus>;
}

// To be replaced with BE enum
export enum ChargeGroup {
  ADDITIONAL_CHARGES = 'Additional charges',
  BOOKING_MANAGEMENT = 'Booking management',
  CUSTOMS_CLEARANCE = 'Customs clearance',
  FREIGHT = 'Freight',
  HAULAGE = 'Haulage',
  PORT_CHARGES = 'Port charges',
  WAREHOUSING = 'Warehousing'
}

export type ChargeListItem = ChargeCostItem | ChargeRevenueItem;

export { Applicability, ChargeItemType, CostTrackingCostItemStatus, CostTrackingRevenueItemStatus };
export type { CostTrackingCostItem };
