import { lowerCase } from 'lodash';

import { applicabilityLabelMapping } from '@zen/CostTracking';
import { SortingOrder, type SortInput } from '@zen/types';

import type { Applicability } from '../types';

export const oceanChargeTableInitialOrder: SortInput = {
  field: 'createdAt',
  direction: SortingOrder.DESC
};

export const getOceanChargeTableId = (applicability: Applicability) => {
  return `ocean-${lowerCase(applicabilityLabelMapping[applicability])}-charges`;
};
