import type { FC, ReactNode } from 'react';

import type { DateWithTimeFields } from '@zen/Components/DateWithTimeForm';
import { TimeOption } from '@zen/Components/DateWithTimeForm';
import DateWithTimeForm from '@zen/Components/DateWithTimeForm/DateWithTimeForm';
import { Button, Tooltip } from '@zen/DesignSystem';
import type { JourneyRemoveActualDateValidationResult } from '@zen/Journey/types';
import { MilestoneDateType } from '@zen/Journey/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useJourneyContext } from '../../JourneyDetails/JourneyDetailsContext';
import { removeActualDateReasonLabelMapping } from '../../MilestoneDatesDialog/helpers';
import { journeyRefetchQueryList } from '../helpers';
import { useRemoveMilestoneActualDateMutation, useUpdateMilestoneActualDateMutation } from './graphql';

interface Props {
  canRemoveActualDateCurrently: Optional<JourneyRemoveActualDateValidationResult>;
  hasActualDate: boolean;
  initialValues: DateWithTimeFields;
  milestoneId: string;
  onSuccess?: () => void;
  timeZone?: Nullable<string>;
  title: string;
}

const ActualDateForm: FC<Props> = ({
  canRemoveActualDateCurrently,
  hasActualDate,
  initialValues,
  milestoneId,
  onSuccess,
  timeZone,
  title
}) => {
  const { addError, addSuccess } = useNotification();

  const [removeActualDate, { loading }] = useRemoveMilestoneActualDateMutation();
  const [updateActualDate] = useUpdateMilestoneActualDateMutation();
  const { getMilestoneDisabledDates, onMilestoneDateChange, zencargoReference } = useJourneyContext();
  const { disabledDates, tooltip } = getMilestoneDisabledDates(milestoneId, MilestoneDateType.ACTUAL);

  const handleClear = async (): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        removeActualDate({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              milestoneId,
              zencargoReference
            }
          }
        }),
      onError: () => {
        addError();
      },
      onSuccess: () => {
        addSuccess('Actual date has been removed');
        onSuccess?.();
      }
    });
  };

  const handleSubmit = ({ date, time }: DateWithTimeFields): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateActualDate({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              milestoneId,
              dateTime: {
                date,
                time: time || undefined
              },
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess(`Actual date has been ${hasActualDate ? 'updated' : 'added'}.`);
      }
    });
  };

  const buttonText: string = hasActualDate ? 'Update actual date' : 'Add actual date';

  const renderActionButton = () => {
    if (!hasActualDate) return;

    const button: ReactNode = (
      <Button
        disabled={loading || !canRemoveActualDateCurrently?.value}
        isLoading={loading}
        onClick={handleClear}
        variant="secondary"
      >
        Clear actual date
      </Button>
    );

    if (canRemoveActualDateCurrently?.reason) {
      const tooltipContent: ReactNode = (
        <div className="max-w-56">{removeActualDateReasonLabelMapping[canRemoveActualDateCurrently.reason]}</div>
      );

      return <Tooltip tooltipContent={tooltipContent}>{button}</Tooltip>;
    }

    return button;
  };

  const handleSuccess = (data: unknown, values: DateWithTimeFields): void => {
    onSuccess?.();
    onMilestoneDateChange(milestoneId, values);
  };

  return (
    <DateWithTimeForm
      additionalActionButton={renderActionButton()}
      buttonText={buttonText}
      dateTooltip={tooltip}
      disabledDates={disabledDates}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      timeConfig={[TimeOption.NO_TIME, TimeOption.POINT_IN_TIME]}
      timeZone={timeZone}
      title={title}
      variant="compact"
    />
  );
};

export default ActualDateForm;
