import type { FC } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useUpdateWebhookMutation } from '../graphql';
import type { Webhook } from '../types';
import WebhookForm from '../WebhookForm';

interface Props {
  accountId: string;
  item: Webhook;
  onClose: () => void;
}

const EditWebhook: FC<Props> = ({ accountId, item, onClose }) => {
  const { addSuccess, addError } = useNotification();
  const [updateWebhook] = useUpdateWebhookMutation();

  const handleSubmit = async (values: Webhook): Promise<IOkOrErrorResult> => {
    const { id, topics, callbackUrl, contactEmail, name, headers } = values;

    return performFormMutation({
      mutationFn: () =>
        updateWebhook({
          awaitRefetchQueries: true,
          refetchQueries: ['getWebhooks'],
          variables: {
            input: {
              webhookId: id,
              topics,
              webhookInput: {
                name,
                callbackUrl,
                contactEmail,
                headers
              }
            }
          }
        }),
      onError: () => addError()
    });
  };

  const handleSuccess = (): void => {
    addSuccess('Webhook updated successfully.');
    onClose();
  };

  return (
    <WebhookForm
      accountId={accountId}
      buttonText="Update Webhook"
      initialValues={item}
      isBusinessUnitFieldVisible={false}
      onCancel={onClose}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
    />
  );
};

export default EditWebhook;
