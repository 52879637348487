import type { FC, ReactNode } from 'react';

import type { FormInstance } from '@zen/Components';
import { Form, FormButtons, FormRadioGroup } from '@zen/Components';
import type { RadioGroupOption } from '@zen/Components/RadioGroup';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

export const invitedCompanyId = 'INVITED_COMPANY_NAME';

interface Props {
  companyName: string;
  duplicates: { id: string; tradingName: string }[];
  onCancel: () => void;
  onSubmit: ({ companyId }: { companyId: string }) => Promise<IOkOrErrorResult>;
}

const DuplicatedAccountsForm: FC<Props> = ({ companyName, duplicates, onCancel, onSubmit }) => {
  const options: RadioGroupOption[] = duplicates.map(({ id, tradingName }) => ({ label: tradingName, value: id }));

  const renderFormButtons = ({ isSubmitting }: FormInstance<object>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Confirm">
      <Button data-testid="cancel-button" onClick={onCancel} variant="ghost">
        Go back
      </Button>
    </FormButtons>
  );

  return (
    <Form formButtons={renderFormButtons} initialValues={{ companyId: invitedCompanyId }} onSubmit={onSubmit}>
      {() => (
        <>
          <FormRadioGroup
            label="What you entered"
            name="companyId"
            options={[{ label: companyName, value: invitedCompanyId }]}
            radioAlignment="column"
            radioType="tile"
          />
          <FormRadioGroup label="Or did you mean?" name="companyId" options={options} radioAlignment="column" radioType="tile" />
        </>
      )}
    </Form>
  );
};

export default DuplicatedAccountsForm;
