import type { FC } from 'react';
import { useState } from 'react';

import Form from '@zen/Components/Form/Form';
import FormTextarea from '@zen/Components/Form/FormTextarea';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import QuoteOptionItemField from '../QuoteOptionItemField';

export interface DescriptionFormData {
  description: string;
  id: string;
  zencargoReference: string;
}

interface Props {
  initialValues: DescriptionFormData;
  isReadOnly: boolean;
  onClose?: (value: boolean) => void;
  onSubmit: (values: DescriptionFormData) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
}

const DescriptionForm: FC<Props> = (props) => {
  const { initialValues, isReadOnly, onSubmit, onSuccess, onClose = () => null } = props;

  const isNewQuoteOption: boolean = !initialValues.id;
  const [showEditMode, setShowEditMode] = useState<boolean>(false);
  const descriptionField = (
    <div className="flex-1 mr-6">
      <QuoteOptionItemField className="flex flex-col" content={initialValues.description} label="Description" />
    </div>
  );

  if (isReadOnly) {
    return descriptionField;
  }

  if (!showEditMode && !isNewQuoteOption) {
    return (
      <div>
        {descriptionField}
        <div className="mt-4">
          <Button onClick={() => setShowEditMode(true)} variant="secondary">
            Edit description
          </Button>
        </div>
      </div>
    );
  }

  const handleCancel = () => {
    if (isNewQuoteOption) {
      onClose(false);
    } else {
      setShowEditMode(false);
    }
  };

  const handleSuccess = (): void => {
    setShowEditMode(false);

    onSuccess();
  };

  return (
    <Form
      enableReinitialize={true}
      formButtons={() => null}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={handleSuccess}
    >
      {() => {
        return (
          <>
            <FormTextarea label="Description:" name="description" />
            <div className="flex">
              <Button className="mr-4" type="submit">
                Update description
              </Button>
              <Button onClick={handleCancel} type="reset" variant="secondary">
                Cancel
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
};

export default DescriptionForm;
