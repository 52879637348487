import cx from 'classnames';
import type { FC } from 'react';
import { useRef } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useWindowScroll } from 'react-use';

import type { ActivityFeedOriginViewType } from '@zen/ActivityFeed';
import { Role } from '@zen/Auth';
import { checkPermission } from '@zen/Auth/authHelper';
import useRole from '@zen/Auth/hooks/useRole';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { BookingStageEnum } from '@zen/Booking';
import AutomaticAccountSwitcher from '@zen/Components/AutomaticAccountSwitcher';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { Container } from '@zen/DesignSystem';
import Column from '@zen/Layout/components/Column';
import Row from '@zen/Layout/components/Row';
import OrderDetailsContainer from '@zen/Orders/OrderDetailsContainer';
import QuoteOptionsContainer from '@zen/Quotes/QuoteOptionsContainer';
import { shipmentRoutes } from '@zen/Routes';
import type { Nullable, Optional } from '@zen/utils/typescript';

import BookingOverview from './BookingOverview';
import CargoOverview from './CargoOverview';
import ActivityFeedContainer from './components/ActivityFeedContainer';
import ArchivedShipmentBanner from './components/ArchivedShipmentBanner';
import BookingActionButtons from './components/BookingActionButtons';
import BookingHeader from './components/BookingHeader';
import BookingRequestButtonsWrapper from './components/BookingRequestButtonsWrapper';
import BookingRequestRejected from './components/BookingRequestRejected';
import Notes from './components/Notes';
import BookingDetailsLoadingSkeleton from './components/skeletons/BookingDetailsLoadingSkeleton';
import { useBookingDetailsQuery } from './graphql';
import { calculateActivityFeedHeight, prepareTrackingDetails } from './helpers';
import type { BookingDetailsData, TrackingDetails } from './types';
import { BookingDetailsPageSection } from './types';

type RouteParams = {
  id: string;
};

interface Props {
  activityFeedViewType?: ActivityFeedOriginViewType;
}

const BookingDetails: FC<Props> = ({ activityFeedViewType }) => {
  const { id } = useParams<RouteParams>() as RouteParams;
  const { y: windowScrollPosition } = useWindowScroll();
  const activityFeedContainerRef = useRef<HTMLDivElement>(null);
  const { check } = useGlobalPermissions();
  const role = useRole();

  const { data, error, loading } = useBookingDetailsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { zencargoReference: id }
  });

  const bookingData: Optional<BookingDetailsData> = data?.bookings?.nodes?.[0];

  return (
    <QueryHandler data={bookingData} error={!!error} isLoading={loading} loadingComponent={<BookingDetailsLoadingSkeleton />}>
      {(booking: BookingDetailsData) => {
        const {
          accountUuid,
          archivedAt,
          bookingRequest,
          bookingStage,
          clientReference,
          consolidatedShipment,
          customsOnly,
          modeOfTransport,
          pinned,
          quoteRequired,
          zencargoReference
        } = booking;

        const isCustomerOnBooking: boolean = !!bookingData?.permissions?.isCustomerOnBooking?.value;
        const isSupplierOnBooking: boolean = !!bookingData?.permissions?.isSupplierOnBooking?.value;

        const canClone: boolean = checkPermission<BookingDetailsData>(booking, 'canClone');
        const canPin: boolean = checkPermission<BookingDetailsData>(booking, 'canPin');
        const canViewNotes: boolean = checkPermission<BookingDetailsData>(booking, 'canViewNotes');
        const canArchive: boolean = checkPermission<BookingDetailsData>(booking, 'canArchiveShipment');
        const canRevertRejection: boolean = !!bookingRequest && checkPermission(bookingRequest, 'canRevertRejection');
        const canRevertRejectionAsManufacturer: boolean =
          !!bookingRequest && checkPermission(bookingRequest, 'canRevertRejectionAsManufacturer');
        const canAccessBackoffice: boolean = check('backoffice.canAccessRoute');
        const canViewTaskList: boolean = role === Role.ADMIN;
        const canToggleTracking: boolean = !!bookingData?.oceanTracking?.canToggleTracking;
        const isBookingRequested: boolean = bookingStage?.name === BookingStageEnum.BOOKING_REQUESTED;
        const shouldDisplayBookingRequestButtons: boolean =
          isBookingRequested && !quoteRequired && !bookingRequest?.rejected && role !== Role.MANUFACTURER;
        const shouldDisplayQuotes: boolean = isBookingRequested && !!quoteRequired;
        const shouldDisplayBookingRequestRejected: boolean = canRevertRejection || canRevertRejectionAsManufacturer;
        const trackingDetails: Nullable<TrackingDetails> = prepareTrackingDetails(booking);
        const forwarderApprovalRequired: boolean = !!bookingRequest?.forwarderApprovalRequired;
        const forwarderApproved: boolean = !!bookingRequest?.forwarderApproved;
        const showNewQuoteButton: boolean = !(forwarderApprovalRequired && !forwarderApproved) && canAccessBackoffice;

        return (
          <>
            {accountUuid && <AutomaticAccountSwitcher accountIdForUrl={accountUuid} />}
            <Page
              actionButtons={
                <BookingActionButtons
                  canArchive={canArchive}
                  canClone={canClone}
                  canPin={canPin}
                  canToggleTracking={canToggleTracking}
                  canViewTaskList={canViewTaskList}
                  isArchived={!!archivedAt}
                  isPinned={pinned}
                  modeOfTransport={modeOfTransport}
                  trackingDetails={trackingDetails}
                  zencargoReference={zencargoReference}
                />
              }
              browserTitle={zencargoReference}
              defaultBackUrl={shipmentRoutes.shipmentIndex.getUrl()}
              stickyHeader={
                <BookingHeader
                  clientReference={clientReference}
                  consolidatedShipment={consolidatedShipment}
                  modeOfTransport={modeOfTransport}
                  sticky={true}
                  trackingDetails={trackingDetails}
                  zencargoReference={zencargoReference}
                />
              }
              title={
                <BookingHeader
                  clientReference={clientReference}
                  consolidatedShipment={consolidatedShipment}
                  modeOfTransport={modeOfTransport}
                  trackingDetails={trackingDetails}
                  zencargoReference={zencargoReference}
                />
              }
              width="extra-wide"
            >
              <Row>
                <Column className="flex flex-col space-y-6 2xl:col-span-9" span={8}>
                  {archivedAt && <ArchivedShipmentBanner />}
                  {shouldDisplayBookingRequestRejected && (
                    <BookingRequestRejected
                      canRevertRejection={canRevertRejection}
                      canRevertRejectionAsManufacturer={canRevertRejectionAsManufacturer}
                      rejectedAdditionalInfo={bookingRequest?.rejectedAdditionalInfo}
                      rejectedReason={bookingRequest?.rejectedReason}
                      zencargoReference={zencargoReference}
                    />
                  )}
                  {shouldDisplayBookingRequestButtons && (
                    <BookingRequestButtonsWrapper bookingRequest={bookingRequest} zencargoReference={zencargoReference} />
                  )}
                  <BookingOverview
                    accountId={accountUuid}
                    showNewQuoteButton={showNewQuoteButton}
                    zencargoReference={zencargoReference}
                  />
                  {shouldDisplayQuotes && (
                    <Container data-testid="quotes-container" title="Quotes">
                      <QuoteOptionsContainer
                        accountUuid={accountUuid}
                        showAddNewQuote={showNewQuoteButton}
                        zencargoReference={zencargoReference}
                      />
                    </Container>
                  )}
                  <CargoOverview
                    bookingStage={bookingStage?.name}
                    customsOnly={customsOnly}
                    modeOfTransport={modeOfTransport}
                    zencargoReference={zencargoReference}
                  />
                </Column>
                <Column className="flex flex-col space-y-6 2xl:col-span-3" span={4}>
                  <div ref={activityFeedContainerRef} className={cx({ 'sticky top-36': !canViewNotes })}>
                    <ActivityFeedContainer
                      accountId={accountUuid}
                      activityFeedHeight={calculateActivityFeedHeight(windowScrollPosition, canViewNotes)}
                      activityFeedViewType={activityFeedViewType}
                      targetPermissions={{ isCustomerOnTarget: isCustomerOnBooking, isSupplierOnTarget: isSupplierOnBooking }}
                      zencargoReference={zencargoReference}
                    />
                  </div>
                  {canViewNotes && <Notes zencargoReference={zencargoReference} />}
                </Column>
              </Row>
            </Page>
            <Routes>
              <Route
                element={
                  <OrderDetailsContainer
                    originPathname={shipmentRoutes.shipmentDetailsPage.getUrl(
                      zencargoReference,
                      BookingDetailsPageSection.ORDERS
                    )}
                  />
                }
                path={shipmentRoutes.shipmentDetailsPageOrders.relativePath}
              />
            </Routes>
          </>
        );
      }}
    </QueryHandler>
  );
};

export default BookingDetails;
