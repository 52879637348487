import type { FC } from 'react';

import type { TerminalTypeValue } from '@zen/Networks/types';
import type { Nullable } from '@zen/utils/typescript';

import type { Terminal } from '../../TerminalSelect';
import TerminalSelect from '../../TerminalSelect';
import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import type { FormInstance } from '../types';

interface Props extends FormFieldProps {
  isClearable?: boolean;
  isDisabled?: boolean;
  renderMenuInPortal?: boolean;
  terminalTypes?: TerminalTypeValue[];
}

const FormTerminalsSelect: FC<Props> = (props) => {
  const { label, ...rest } = props;

  return (
    <FormField {...props}>
      {(field: { error: boolean; label: string; name: string; value: Nullable<Terminal> }, form: FormInstance<unknown>) => {
        const { label: fieldLabel, ...fieldRest } = field;

        return (
          <TerminalSelect
            {...rest}
            {...fieldRest}
            hasError={field.error}
            onChange={(value: Nullable<Terminal>) => {
              form.setFieldValue(field.name, value);
            }}
          />
        );
      }}
    </FormField>
  );
};

export default FormTerminalsSelect;
