import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';

import type { Nullable } from '@zen/utils/typescript';

import CargoReadyDateSlideout from '../CargoReadyDateSlideout';
import type { CargoReadyDateValues } from '../components/CargoReadyDateForm';
import UpdateCRDContext from '../contexts/UpdateCRDContext';

interface Props {
  handleSuccess?: (values: unknown) => void;
}

const UpdateCRDProvider: FC<PropsWithChildren<Props>> = ({ handleSuccess, children }) => {
  const [crdValues, setCRDValues] = useState<Nullable<CargoReadyDateValues>>(null);

  const setCargoReadyDateValues = (values: CargoReadyDateValues) => setCRDValues(values);

  const renderCargoReadyDateSlideout = () => {
    if (crdValues) {
      const { lotIds, cargoReadyDate: date, orderDate, orderReferenceNumber } = crdValues;

      return (
        <CargoReadyDateSlideout
          initialValues={{ date }}
          isVisible={!!crdValues}
          lotIds={lotIds}
          onClose={() => setCRDValues(null)}
          onSuccess={handleSuccess}
          orderDate={orderDate}
          orderReferenceNumber={orderReferenceNumber}
        />
      );
    }
  };

  return (
    <UpdateCRDContext.Provider value={{ setCargoReadyDateValues }}>
      {children}
      {crdValues && renderCargoReadyDateSlideout()}
    </UpdateCRDContext.Provider>
  );
};

export default UpdateCRDProvider;
