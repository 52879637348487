import type { FC } from 'react';
import { useState } from 'react';

import type { IssueActivityMetadataType } from '@zen/ActivityFeed';
import { ActivityFeedTargetTypeEnum } from '@zen/ActivityFeed';
import ActivityFeedItemDetails from '@zen/ActivityFeed/components/ActivityFeedItemDetails';
import ActivityFeedItemIcon from '@zen/ActivityFeed/components/ActivityFeedItemIcon';
import ActivityFeedItemWrapper from '@zen/ActivityFeed/components/ActivityFeedItemWrapper';
import type { Activity } from '@zen/ActivityFeed/types';
import { Button, Dialog } from '@zen/DesignSystem';
import IssuePill from '@zen/Issues/components/IssuePill';
import type { GetBookingIssueQueryResult, GetPurchaseOrderIssueQueryResult } from '@zen/Issues/graphql';
import { useGetBookingIssueLazyQuery, useGetPurchaseOrderIssueLazyQuery } from '@zen/Issues/graphql';
import IssueModalContainer from '@zen/Issues/IssueModalContainer';
import type { Issue } from '@zen/Issues/types';

import { issueTitle } from './helpers';
import IssueManagers from './IssueManagers';

interface Props {
  activityMetaData: Activity;
}

function isOrderIssueResult(
  result: GetBookingIssueQueryResult | GetPurchaseOrderIssueQueryResult
): result is GetPurchaseOrderIssueQueryResult {
  return (result as GetPurchaseOrderIssueQueryResult)?.purchaseOrderIssue !== undefined;
}

const IssueFeedItem: FC<Props> = ({ activityMetaData }) => {
  const { metadata, itemId, createdAt, itemType, targetType } = activityMetaData;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { category, openedBy, problemDescription, resolvedBy, startedBy, severity, state, title } =
    metadata as IssueActivityMetadataType;

  const issuePillData = { category, severity, state, title };
  const isPurchaseOrder: boolean = targetType === ActivityFeedTargetTypeEnum.PURCHASE_ORDER;

  const getIssueQuery = isPurchaseOrder ? useGetPurchaseOrderIssueLazyQuery : useGetBookingIssueLazyQuery;

  const [getIssue, { data, refetch, error }] = getIssueQuery({
    variables: { id: itemId },
    onError: () => {
      setOpenModal(true);
    }
  });

  const handleViewIssueClick = () => {
    setOpenModal(true);
    getIssue();
  };

  const closeModal = () => setOpenModal(false);
  // @ts-expect-error ts-migrate(2322) FIXME: Type '({ __typename?: "PurchaseOrderIssue" | undef... Remove this comment to see the full error message
  const issue: Issue = isOrderIssueResult(data) ? data?.purchaseOrderIssue : data?.bookingIssue;

  return (
    <ActivityFeedItemWrapper>
      <div className="flex justify-between">
        <div>
          <ActivityFeedItemDetails
            additionalText={problemDescription}
            createdAt={createdAt}
            icon={<ActivityFeedItemIcon color="text-orange-base" iconName="zicon-warning-oval" />}
            subtitle="What is the issue?"
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            title={issueTitle[itemType]}
          />
        </div>
        <div>
          <div className="flex flex-col items-end ml-12">
            <IssuePill issue={issuePillData} />
            <IssueManagers openedBy={openedBy} resolvedBy={resolvedBy} startedBy={startedBy} />
            <div className="flex justify-end mt-4">
              <Button onClick={handleViewIssueClick}>View issue</Button>
            </div>
          </div>
        </div>
      </div>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ issue: Issue; onClose: () => void; onIssue... Remove this comment to see the full error message */}
      {issue && openModal && <IssueModalContainer issue={issue} onClose={closeModal} onIssueStateChange={refetch} />}
      {error && (
        <Dialog
          confirmLabel="Close"
          hasCancelButton={false}
          header="This issue has been restricted by Zencargo and is no longer visible to you."
          isOpen={openModal}
          message="If you believe this has been done in error please contact a member of the operations team."
          onClose={closeModal}
          onConfirm={closeModal}
        />
      )}
    </ActivityFeedItemWrapper>
  );
};

export default IssueFeedItem;
