import cx from 'classnames';
import { keyBy } from 'lodash';
import type { FC, ReactNode } from 'react';

import { FormSelect } from '@zen/Components';
import type { Option } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import { useUserGroupsOptions } from './useUserGroupsOptions';

interface Props {
  accountId: string;
  label: string;
  name: string;
  onChange?: (value: Nullable<string>) => void;
}

const FormAccountUserGroupsSelect: FC<Props> = ({ accountId, ...props }) => {
  const options = useUserGroupsOptions(accountId);
  const optionsDictionary = keyBy(options, 'value');

  const formatOptionLabel = ({ value }: Option<string>, isSelected: boolean, context: 'menu' | 'value'): ReactNode => {
    const option = optionsDictionary[value];

    const descriptionClassNames: string = cx({
      'text-grey-base': !isSelected,
      'text-white': isSelected
    });

    if (context === 'value') {
      return option.renderLabel();
    }

    return option.renderOptionLabel(descriptionClassNames);
  };

  return <FormSelect formatOptionLabel={formatOptionLabel} options={options} renderMenuInPortal={true} {...props} />;
};

export default FormAccountUserGroupsSelect;
