const stringCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const possibleCharactersCount = stringCharacters.length;

export function generateRandomString(length: number = 7) {
  let result = '';

  const randomNumbers = window.crypto.getRandomValues(new Uint32Array(length));

  for (let i = 0; i < length; i++) {
    result += stringCharacters.charAt(randomNumbers[i] % possibleCharactersCount);
  }

  return result;
}
