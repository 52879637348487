import cx from 'classnames';

import type { MenuItemType, TableColumn } from '@zen/DesignSystem';
import { ContextMenu } from '@zen/DesignSystem';
import {
  formatChargeBasis,
  formatChargeType,
  formatCost,
  formatIncoterms,
  formatPort,
  formatUnit
} from '@zen/RateCards/LegacyRateCards/utils/tableDataFormatting';
import type { IconName } from '@zen/Styleguide';

import type { PortChargePayload } from '../../RateCardForm/reducer/types';
import { getPortChargeTableColumnTitleConfig } from './helpers';
import type { PortChargeTableColumnTitleConfig } from './types';

type PortChargeTableActions = {
  onDelete?: (index: number) => void;
  onDisable?: (id: string, value: boolean) => void;
  onRemoveCustomPrice?: (index: number) => void;
  onUpdate?: (index: number, values: PortChargePayload) => void;
};

export const getPortChargeTableColumns = (
  type: 'origin' | 'destination',
  { onDisable, onUpdate, onDelete, onRemoveCustomPrice }: PortChargeTableActions = {},
  isEditable: boolean = false
): TableColumn<PortChargePayload>[] => {
  const columnTitleConfig: PortChargeTableColumnTitleConfig = getPortChargeTableColumnTitleConfig(type, isEditable);

  return [
    {
      key: 'port',
      title: columnTitleConfig.port,
      render: (_, { port }: PortChargePayload) => {
        return formatPort(port);
      }
    },
    {
      key: 'chargeType',
      title: columnTitleConfig.chargeType,
      render: (_, { chargeType }: PortChargePayload) => {
        return formatChargeType(chargeType);
      }
    },
    {
      key: 'chargeBasis',
      title: columnTitleConfig.chargeBasis,
      render: (_, { chargeType }: PortChargePayload) => {
        return formatChargeBasis(chargeType);
      }
    },

    {
      key: 'unit',
      title: columnTitleConfig.unit,
      render: (_, { chargeType }: PortChargePayload) => {
        return formatUnit(chargeType);
      }
    },
    {
      key: 'incoterms',
      title: columnTitleConfig.incoterms,
      render: (_, { incoterms }: PortChargePayload) => {
        return formatIncoterms(incoterms);
      }
    },
    ...(isEditable
      ? [
          {
            title: 'Custom price',
            key: 'customPrice',
            render: (_: unknown, { customChargeValue, customCurrency }: PortChargePayload) => {
              return formatCost(customChargeValue, customCurrency);
            },
            sortable: false
          }
        ]
      : []),
    {
      key: 'cost',
      alignment: 'right',
      title: columnTitleConfig.cost,
      sortable: false,
      render: (_, { chargeValue, currency, customChargeValue, customCurrency }: PortChargePayload) => {
        const isChargeValueEquals: boolean = !!customChargeValue && customChargeValue !== chargeValue;
        const isCurrencyEquals: boolean = !!customChargeValue && currency !== customCurrency;
        const hasCustomPrice: boolean = isChargeValueEquals || isCurrencyEquals;
        const className: string = cx({
          'line-through': hasCustomPrice
        });

        return <div className={className}>{formatCost(chargeValue, currency)}</div>;
      }
    },
    ...(isEditable
      ? [
          {
            title: '',
            key: 'actions',
            render: (_: unknown, item: PortChargePayload, index: number) => {
              const { centralPortChargeId, disabled, customChargeValue } = item;
              const isCustomizedPrice: boolean = !!customChargeValue;
              const isCustomCharge: boolean = !centralPortChargeId;
              const canRevertPrice: boolean = !isCustomCharge && isCustomizedPrice;
              const priceUpdateLabel: string = isCustomizedPrice ? 'Edit custom price' : 'Set custom price';
              const items: MenuItemType[] = [
                {
                  label: isCustomCharge ? 'Edit custom charge' : priceUpdateLabel,
                  icon: 'zicon-edit',
                  disabled: item.disabled,
                  onClick: () => {
                    onUpdate?.(index, item);
                  }
                }
              ];

              if (canRevertPrice) {
                items.push({
                  label: 'Revert to default price',
                  icon: 'zicon-undo',
                  disabled: item.disabled,
                  onClick: () => {
                    onRemoveCustomPrice?.(index);
                  }
                });
              }

              const getContextItem = (): MenuItemType => {
                const label: string = item.disabled ? 'Unhide' : 'Hide';
                const icon: IconName = item.disabled ? 'zicon-visible' : 'zicon-hidden';

                if (centralPortChargeId) {
                  return {
                    label,
                    icon,
                    onClick: () => {
                      onDisable?.(centralPortChargeId, !disabled);
                    }
                  };
                }

                return {
                  label: 'Delete',
                  icon: 'zicon-trash',
                  onClick: () => {
                    onDelete?.(index);
                  }
                };
              };

              items.push(getContextItem());

              return <ContextMenu inline={false} items={items} />;
            },
            sortable: false
          }
        ]
      : [])
  ];
};
