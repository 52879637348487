import type { FC } from 'react';
import { useState } from 'react';

import { IconButton } from '@zen/DesignSystem';

import { AccountConnectionTypeEnum } from '../../types';
import DeleteConnectionModal from '../DeleteConnectionModal';

interface Props {
  connectionType: AccountConnectionTypeEnum;
  sourceAccountId: string;
  targetAccountId: string;
  targetAccountName: string;
}

const DeleteConnectionButton: FC<Props> = (props) => {
  const { connectionType, sourceAccountId, targetAccountId, targetAccountName } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isCustomerConnection: boolean = connectionType === AccountConnectionTypeEnum.CUSTOMER;
  const buttonTitle: string = isCustomerConnection ? 'Customers cannot be deleted' : 'Delete';

  const toggleModal = (): void => setIsModalOpen((isOpen: boolean) => !isOpen);

  return (
    <>
      <DeleteConnectionModal
        connectionType={connectionType}
        isOpen={isModalOpen}
        onClose={toggleModal}
        sourceAccountId={sourceAccountId}
        targetAccountId={targetAccountId}
        targetAccountName={targetAccountName}
      />
      <IconButton
        disabled={isCustomerConnection}
        icon="zicon-trash"
        onClick={toggleModal}
        size="medium"
        title={buttonTitle}
        variant="ghost"
      />
    </>
  );
};

export default DeleteConnectionButton;
export type { Props as DeleteConnectionButtonProps };
