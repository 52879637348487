import type { FC } from 'react';

import NoResults from '@zen/Components/NoResults';

import Image from './log.svg?react';

const NoMilestoneHistory: FC = () => {
  return (
    <NoResults
      headline="There is no activity for this milestone"
      image={<Image />}
      tagline="This milestone has never been changed."
    />
  );
};

export default NoMilestoneHistory;
