import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type BookingSummaryQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type BookingSummaryQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<
            GraphQLTypes.Booking,
            | 'accountUuid'
            | 'customsOnly'
            | 'forwarderReference'
            | 'houseBillOfLading'
            | 'modeOfTransport'
            | 'multiStop'
            | 'shipmentType'
            | 'tradeRole'
            | 'truckSwap'
            | 'zencargoReference'
          > & {
              archivedAt?: GraphQLTypes.Maybe<{ __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date'>>;
              bookingManagementSettings?: GraphQLTypes.Maybe<
                { __typename?: 'BookingManagementSettings' } & Pick<GraphQLTypes.BookingManagementSettings, 'pricingRequired'>
              >;
              bookingStages?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'BookingStage' } & Pick<GraphQLTypes.BookingStage, 'current' | 'name' | 'started'> & {
                      checklist: Array<
                        { __typename?: 'BookingStageCheck' } & Pick<GraphQLTypes.BookingStageCheck, 'name' | 'completed'>
                      >;
                      startedAt?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date'>
                      >;
                    }
                >
              >;
              calculatedInfo: { __typename?: 'CalculatedInfo' } & {
                cargoSummary?: GraphQLTypes.Maybe<
                  { __typename?: 'CargoSummary' } & Pick<GraphQLTypes.CargoSummary, 'label' | 'totalActualCbm' | 'type'> & {
                      totalChargeableWeight?: GraphQLTypes.Maybe<
                        Array<{ __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'value' | 'metric'>>
                      >;
                      totalGrossWeight?: GraphQLTypes.Maybe<
                        Array<{ __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'value' | 'metric'>>
                      >;
                    }
                >;
              };
              canManageCargo: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateBookingManagementSettings: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              canUpdateHouseBillOfLading: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              canUpdateIncoterms: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateModeOfTransport: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateMultiStop: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdatePricingRequired: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateShipmentType: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateTruckSwap: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateTradeRole: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewContractId: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'> & {
                    consolidatedCargo?: GraphQLTypes.Maybe<
                      { __typename?: 'ConsolidatedCargo' } & {
                        consolidatedCargoItems?: GraphQLTypes.Maybe<
                          Array<
                            { __typename?: 'CargoConsolidatedItemType' } & Pick<
                              GraphQLTypes.CargoConsolidatedItemType,
                              'quantity' | 'type' | 'subType'
                            >
                          >
                        >;
                        consolidatedLooseCargoItems?: GraphQLTypes.Maybe<
                          Array<
                            { __typename?: 'ConsolidatedLooseCargoItem' } & Pick<
                              GraphQLTypes.ConsolidatedLooseCargoItem,
                              'quantity' | 'type'
                            >
                          >
                        >;
                      }
                    >;
                  }
              >;
              coreCargos?: GraphQLTypes.Maybe<Array<{ __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'id'>>>;
              cargoJourneys?: GraphQLTypes.Maybe<
                { __typename?: 'CargoJourneys' } & {
                  journeys: Array<
                    { __typename?: 'CargoJourney' } & {
                      journey?: GraphQLTypes.Maybe<
                        { __typename?: 'Journey' } & {
                          legs?: GraphQLTypes.Maybe<
                            Array<
                              { __typename?: 'JourneyLeg' } & {
                                from: { __typename?: 'JourneyStop' } & Pick<GraphQLTypes.JourneyStop, 'locationType'> & {
                                    terminal?: GraphQLTypes.Maybe<
                                      { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode'>
                                    >;
                                    warehouse?: GraphQLTypes.Maybe<
                                      { __typename?: 'NetworksOrgLoc' } & {
                                        label?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksLabelType' } & Pick<
                                            GraphQLTypes.NetworksLabelType,
                                            'short' | 'long'
                                          >
                                        >;
                                      }
                                    >;
                                    warehouseLocation?: GraphQLTypes.Maybe<
                                      { __typename?: 'AccountLocation' } & {
                                        label?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksLabelType' } & Pick<
                                            GraphQLTypes.NetworksLabelType,
                                            'short' | 'long'
                                          >
                                        >;
                                      }
                                    >;
                                  };
                                to: { __typename?: 'JourneyStop' } & Pick<GraphQLTypes.JourneyStop, 'locationType'> & {
                                    terminal?: GraphQLTypes.Maybe<
                                      { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode'>
                                    >;
                                    warehouse?: GraphQLTypes.Maybe<
                                      { __typename?: 'NetworksOrgLoc' } & {
                                        label?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksLabelType' } & Pick<
                                            GraphQLTypes.NetworksLabelType,
                                            'short' | 'long'
                                          >
                                        >;
                                      }
                                    >;
                                    warehouseLocation?: GraphQLTypes.Maybe<
                                      { __typename?: 'AccountLocation' } & {
                                        label?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksLabelType' } & Pick<
                                            GraphQLTypes.NetworksLabelType,
                                            'short' | 'long'
                                          >
                                        >;
                                      }
                                    >;
                                  };
                              }
                            >
                          >;
                        }
                      >;
                    }
                  >;
                }
              >;
              cargoJourneysSummary?: GraphQLTypes.Maybe<
                { __typename?: 'CargoJourneysSummary' } & Pick<GraphQLTypes.CargoJourneysSummary, 'zencargoReference'>
              >;
              forwarder?: GraphQLTypes.Maybe<
                | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
              >;
              incoterms?: GraphQLTypes.Maybe<{ __typename?: 'Incoterms' } & Pick<GraphQLTypes.Incoterms, 'value'>>;
              milestones?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'VoyageMilestone' } & Pick<GraphQLTypes.VoyageMilestone, 'id' | 'name'> & {
                      actual?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                      >;
                      latestEstimate?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                      >;
                      originalEstimate?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                      >;
                    }
                >
              >;
              networksDestination?: GraphQLTypes.Maybe<
                | ({ __typename?: 'Account' } & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
                | ({ __typename?: 'AccountLocation' } & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
                | ({ __typename?: 'NetworksOrgLoc' } & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
                | ({ __typename?: 'NetworksTerminal' } & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              >;
              networksOrigin?: GraphQLTypes.Maybe<
                | ({ __typename?: 'Account' } & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
                | ({ __typename?: 'AccountLocation' } & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
                | ({ __typename?: 'NetworksOrgLoc' } & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
                | ({ __typename?: 'NetworksTerminal' } & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              >;
              roadTracking?: GraphQLTypes.Maybe<
                { __typename?: 'RoadTrackingDetails' } & {
                  trackingInput?: GraphQLTypes.Maybe<
                    { __typename?: 'RoadTrackingInput' } & {
                      trackingSubscription?: GraphQLTypes.Maybe<
                        { __typename?: 'RoadTrackingSubscription' } & Pick<GraphQLTypes.RoadTrackingSubscription, 'mapUrl'>
                      >;
                    }
                  >;
                }
              >;
              specialInstructions?: GraphQLTypes.Maybe<
                { __typename?: 'BookingSpecialInstructions' } & Pick<GraphQLTypes.BookingSpecialInstructions, 'message'>
              >;
              voyageMilestone?: GraphQLTypes.Maybe<
                { __typename?: 'VoyageMilestone' } & Pick<GraphQLTypes.VoyageMilestone, 'name'>
              >;
            }
        >
      >
    >;
  };
};

export const BookingSummaryDocument = /* #__PURE__ */ gql`
  query bookingSummary($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        accountUuid
        archivedAt {
          date
        }
        bookingManagementSettings {
          pricingRequired
        }
        bookingStages {
          checklist {
            name
            completed
          }
          current
          name
          started
          startedAt {
            date
          }
        }
        calculatedInfo {
          cargoSummary {
            label
            totalActualCbm
            totalChargeableWeight {
              value
              metric
            }
            totalGrossWeight {
              value
              metric
            }
            type
          }
        }
        canManageCargo {
          value
        }
        canUpdateBookingManagementSettings {
          value
        }
        canUpdateHouseBillOfLading {
          value
        }
        canUpdateIncoterms {
          value
        }
        canUpdateModeOfTransport {
          value
        }
        canUpdateMultiStop {
          value
        }
        canUpdatePricingRequired {
          value
        }
        canUpdateShipmentType {
          value
        }
        canUpdateTruckSwap {
          value
        }
        canUpdateTradeRole {
          value
        }
        canViewContractId {
          value
        }
        cargo {
          consolidatedCargo {
            consolidatedCargoItems {
              quantity
              type
              subType
            }
            consolidatedLooseCargoItems {
              quantity
              type
            }
          }
          mode
        }
        coreCargos {
          id
        }
        cargoJourneys {
          journeys {
            journey {
              legs {
                from {
                  locationType
                  terminal {
                    unlocode
                  }
                  warehouse {
                    label {
                      short
                      long
                    }
                  }
                  warehouseLocation {
                    label {
                      short
                      long
                    }
                  }
                }
                to {
                  locationType
                  terminal {
                    unlocode
                  }
                  warehouse {
                    label {
                      short
                      long
                    }
                  }
                  warehouseLocation {
                    label {
                      short
                      long
                    }
                  }
                }
              }
            }
          }
        }
        cargoJourneysSummary {
          zencargoReference
        }
        customsOnly
        forwarder {
          id
          label {
            long
          }
        }
        forwarderReference
        houseBillOfLading
        incoterms {
          value
        }
        milestones {
          id
          name
          actual {
            date
            timeZone
          }
          latestEstimate {
            date
            timeZone
          }
          originalEstimate {
            date
            timeZone
          }
        }
        modeOfTransport
        multiStop
        networksDestination {
          label {
            long
          }
        }
        networksOrigin {
          label {
            long
          }
        }
        roadTracking {
          trackingInput {
            trackingSubscription {
              mapUrl
            }
          }
        }
        shipmentType
        specialInstructions {
          message
        }
        tradeRole
        truckSwap
        voyageMilestone {
          name
        }
        zencargoReference
      }
    }
  }
`;

/**
 * __useBookingSummaryQuery__
 *
 * To run a query within a React component, call `useBookingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingSummaryQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useBookingSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<BookingSummaryQueryResult, BookingSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BookingSummaryQueryResult, BookingSummaryQueryVariables>(BookingSummaryDocument, options);
}
export function useBookingSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingSummaryQueryResult, BookingSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<BookingSummaryQueryResult, BookingSummaryQueryVariables>(BookingSummaryDocument, options);
}
export type BookingSummaryQueryHookResult = ReturnType<typeof useBookingSummaryQuery>;
export type BookingSummaryLazyQueryHookResult = ReturnType<typeof useBookingSummaryLazyQuery>;
