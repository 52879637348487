import { isEmpty } from 'lodash';
import type { FC, KeyboardEventHandler } from 'react';
import React from 'react';
import CreatableSelect from 'react-select/creatable';

import { ClearIndicator, getMultiValue } from '../components';
import { customTheme, multiSelectStyles } from '../select-styles';
import type { Option } from '../types';
import { keyMapper } from './helpers';
import type { Separator } from './types';

const createOption = (label: string): Option<string> => ({
  label,
  value: label
});

interface Props {
  autoFocus?: boolean;
  className?: string;
  hasError?: boolean;
  isDisabled?: boolean;
  name?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  renderMenuInPortal?: boolean;
  separateOn?: Separator[];
  value?: string[];
}

const MultiText: FC<Props> = (props) => {
  const {
    autoFocus,
    className,
    hasError,
    isDisabled,
    name,
    onBlur,
    onChange,
    placeholder = 'value1, value2...',
    renderMenuInPortal = false,
    value = [],
    separateOn = ['Tab']
  } = props;
  const [inputValue, setInputValue] = React.useState('');

  const handleInputChange = (): void => {
    const isDuplicateValue: boolean = !!value?.some((val) => val === inputValue);

    if (!isDuplicateValue && inputValue !== '') {
      onChange([...value, inputValue]);
    }
    setInputValue('');
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;

    const mappedSeparatorKeys = keyMapper(separateOn);

    if (mappedSeparatorKeys.includes(event.key)) {
      handleInputChange();
      event.preventDefault();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    handleInputChange();
    onBlur?.(event);
  };

  // Create options from the string values. This is how react-select needs to recieve them
  const preparedValue: readonly Option<string>[] = value.map((stringValue: string) => createOption(stringValue));

  // Abstract away the options so that the parent component only sees an array of strings
  const handleChange = (options: readonly Option<string>[]) => {
    const stringValues: string[] = options.map((option: Option<string>) => option.value);

    onChange(stringValues);
  };

  return (
    <CreatableSelect
      autoFocus={autoFocus}
      className={className}
      components={{
        DropdownIndicator: null,
        ClearIndicator,
        MultiValue: getMultiValue()
      }}
      inputValue={inputValue}
      isClearable={true}
      isDisabled={isDisabled}
      isMulti={true}
      menuIsOpen={false}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      styles={multiSelectStyles(hasError, 'default', !isEmpty(value))}
      theme={customTheme}
      value={preparedValue}
      {...(renderMenuInPortal ? { menuPortalTarget: document.body } : {})}
    />
  );
};

export default MultiText;
export type { Props as MultiTextProps };
