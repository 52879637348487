import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type MilestoneDateLogQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  milestoneId: GraphQLTypes.Scalars['String'];
}>;

export type MilestoneDateLogQueryResult = { __typename?: 'Query' } & {
  journeyMilestoneDateLog?: GraphQLTypes.Maybe<
    { __typename?: 'JourneyMilestoneDateLogConnection' } & Pick<GraphQLTypes.JourneyMilestoneDateLogConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            { __typename?: 'JourneyMilestoneDateLog' } & Pick<
              GraphQLTypes.JourneyMilestoneDateLog,
              'createdAt' | 'dateType' | 'eventType' | 'source'
            > & {
                createdBy?: GraphQLTypes.Maybe<
                  { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
                >;
                createdByUser?: GraphQLTypes.Maybe<
                  { __typename?: 'AccountMember' } & Pick<GraphQLTypes.AccountMember, 'firstName' | 'lastName'>
                >;
                date?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeRange' } & {
                    endDateTime?: GraphQLTypes.Maybe<
                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                    >;
                    startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
                  }
                >;
                secondaryDate?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeRange' } & {
                    endDateTime?: GraphQLTypes.Maybe<
                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                    >;
                    startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
                  }
                >;
              }
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const MilestoneDateLogDocument = /* #__PURE__ */ gql`
  query milestoneDateLog($after: String, $before: String, $first: Int, $last: Int, $milestoneId: String!) {
    journeyMilestoneDateLog(after: $after, before: $before, first: $first, last: $last, milestoneId: $milestoneId) {
      nodes {
        createdAt
        createdBy {
          firstName
          lastName
        }
        createdByUser {
          firstName
          lastName
        }
        date {
          endDateTime {
            date
            time
          }
          startDateTime {
            date
            time
          }
        }
        dateType
        eventType
        secondaryDate {
          endDateTime {
            date
            time
          }
          startDateTime {
            date
            time
          }
        }
        source
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useMilestoneDateLogQuery__
 *
 * To run a query within a React component, call `useMilestoneDateLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilestoneDateLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilestoneDateLogQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      milestoneId: // value for 'milestoneId'
 *   },
 * });
 */
export function useMilestoneDateLogQuery(
  baseOptions: Apollo.QueryHookOptions<MilestoneDateLogQueryResult, MilestoneDateLogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<MilestoneDateLogQueryResult, MilestoneDateLogQueryVariables>(MilestoneDateLogDocument, options);
}
export function useMilestoneDateLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MilestoneDateLogQueryResult, MilestoneDateLogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<MilestoneDateLogQueryResult, MilestoneDateLogQueryVariables>(MilestoneDateLogDocument, options);
}
export type MilestoneDateLogQueryHookResult = ReturnType<typeof useMilestoneDateLogQuery>;
export type MilestoneDateLogLazyQueryHookResult = ReturnType<typeof useMilestoneDateLogLazyQuery>;
