import type { FC } from 'react';

import AccountContactsSelect from '@zen/Accounts/components/forms/AccountContactsSelect';
import AccountsSelect from '@zen/Accounts/components/forms/AccountsSelect';
import type { FormFieldInputProps, FormInstance } from '@zen/Components';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import FormField from '@zen/Components/Form/FormField';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import type { NetworksOrgLoc, NetworksOrgLocOption } from '@zen/Networks/types';
import type { Nullable } from '@zen/utils/typescript';

import type { NetworksContactInputProps } from '../NetworksContactInput';
import NetworksContactInput from '../NetworksContactInput';

type EntityType = 'account' | 'location' | 'legacyLocation';

interface Props extends FormFieldProps, Omit<NetworksContactInputProps, 'onChange'> {
  displayHeadquartersAtTop?: boolean;
  entityType?: EntityType;
  excludeAccountId?: string;
  onSelect?: (name: string, value: Nullable<NetworksOrgLocOption>) => void;
}

const NetworksContactFormInput: FC<Props> = (props) => {
  const isCreateAccountFeatureEnabled: boolean = useFeatureIsOn('create-account');

  const { label, entityType = 'legacyLocation', onSelect, ...rest } = props;

  const getComponentName = () => {
    if (!isCreateAccountFeatureEnabled) return NetworksContactInput;

    const componentMapping = {
      account: AccountsSelect,
      location: AccountContactsSelect,
      legacyLocation: NetworksContactInput
    };

    return componentMapping[entityType];
  };

  return (
    <FormField {...props}>
      {(field: FormFieldInputProps<NetworksOrgLoc>, form: FormInstance<NetworksOrgLoc>) => {
        const { label: fieldLabel, ...fieldRest } = field;

        const InputComponent = getComponentName();

        const handleBlur = (): void => {
          form.setFieldTouched(field.name, true);
        };

        return (
          <InputComponent
            {...fieldRest}
            {...rest}
            hasError={field.error}
            onBlur={handleBlur}
            onChange={(value: Nullable<NetworksOrgLoc>) => {
              form.setFieldValue(field.name, value);

              onSelect?.(field.name, value);
            }}
          />
        );
      }}
    </FormField>
  );
};

export default NetworksContactFormInput;
