import { find, findLast, findLastIndex, maxBy, min, minBy, uniqBy } from 'lodash';

import type { ModeOfTransport } from '@zen/Booking';
import type { CargoOverviewCargoReadyDate } from '@zen/Booking/BookingDetails/CargoOverview/types';
import type { Option, SelectOption } from '@zen/DesignSystem';
import { getDateType, isDeliveryMilestone } from '@zen/Journey/helpers';
import { getToday } from '@zen/utils/date';
import type { Optional } from '@zen/utils/typescript';

import type { JourneyLeg, JourneyMilestoneWithMetadata, JourneyShippingMilestone, JourneyStop } from '../../types';
import { JourneyLegTypeEnum, JourneyShippingMilestoneNameEnum, JourneyStopActionEnum, MilestoneDateType } from '../../types';
import DisabledDateTooltip from '../DisabledDateTooltip';
import { getLocationName } from '../JourneyLegs/helpers';
import { getMilestoneLabel } from '../MilestoneName/helpers';
import type { DateEndpoint, DisabledDatesParams, MilestoneDisabledDateProps } from './types';

export const getCarriageLeg = (legs: JourneyLeg[]) => {
  const carriageLeg = legs.find(({ type }: JourneyLeg) => type === JourneyLegTypeEnum.CARRIAGE);

  return {
    canManagePlannedDates: !!carriageLeg?.canManagePlannedDates,
    id: carriageLeg?.id || '',
    vehicleId: carriageLeg?.vehicle?.id || ''
  };
};

const getCargoReadyDateMilestoneOption = (
  cargoReadyDate: Optional<CargoOverviewCargoReadyDate>,
  modeOfTransport: Optional<ModeOfTransport>
): Option<string>[] => [
  ...(cargoReadyDate
    ? [
        {
          label: getMilestoneLabel({
            name: cargoReadyDate.name,
            modeOfTransport,
            stopAction: JourneyStopActionEnum.COLLECTION,
            completed: cargoReadyDate.completed
          }),
          value: cargoReadyDate?.id
        }
      ]
    : [])
];

const getMilestoneOption = (
  milestone: JourneyShippingMilestone,
  stopAction: JourneyStopActionEnum,
  modeOfTransport: Optional<ModeOfTransport>
): Option<string> => {
  return {
    label: getMilestoneLabel({ ...milestone, modeOfTransport, stopAction }),
    value: milestone.id
  };
};

export const getMilestoneOptions = (
  cargoReadyDate: Optional<CargoOverviewCargoReadyDate>,
  legs: JourneyLeg[],
  modeOfTransport: Optional<ModeOfTransport>,
  postLegsMilestones: JourneyShippingMilestone[],
  preLegsMilestones: JourneyShippingMilestone[]
): SelectOption<string>[] => {
  const stops = getStops(legs);

  const cargoReadyDateOption = getCargoReadyDateMilestoneOption(cargoReadyDate, modeOfTransport);

  const postOptions = postLegsMilestones.map((milestone) =>
    getMilestoneOption(milestone, JourneyStopActionEnum.DELIVERY, modeOfTransport)
  );

  const preOptions = preLegsMilestones.map((milestone) =>
    getMilestoneOption(milestone, JourneyStopActionEnum.COLLECTION, modeOfTransport)
  );

  const options: SelectOption<string>[] = stops.map((stop: JourneyStop) => {
    return {
      label: getLocationName(stop)?.long || `Confirmation on ${stop.locationType.toLocaleLowerCase()} pending`,
      options: stop.shippingMilestones.map((milestone: JourneyShippingMilestone) =>
        getMilestoneOption(milestone, stop.stopAction, modeOfTransport)
      )
    };
  });

  return [...cargoReadyDateOption, ...preOptions, ...options, ...postOptions];
};

export const getMilestones = (legs: JourneyLeg[]): JourneyMilestoneWithMetadata[] => {
  const milestones = legs.reduce((prev: JourneyMilestoneWithMetadata[], { from, to }: JourneyLeg) => {
    const fromStopMilestones: JourneyMilestoneWithMetadata[] = from.shippingMilestones.map((milestone) => ({
      ...milestone,
      stopAction: from.stopAction
    }));

    const toStopMilestones: JourneyMilestoneWithMetadata[] = to.shippingMilestones.map((milestone) => ({
      ...milestone,
      stopAction: to.stopAction
    }));

    return prev.concat(fromStopMilestones, toStopMilestones);
  }, []);

  return uniqBy(milestones, 'id');
};

export const getStops = (legs: JourneyLeg[]): JourneyStop[] => {
  return legs.reduce((journeyStops: JourneyStop[], currentLeg: JourneyLeg, currentIndex: number, array: JourneyLeg[]) => {
    const { from, to } = currentLeg;

    const isFirstLeg: boolean = currentIndex === 0;
    const nextLeg: Optional<JourneyLeg> = array[currentIndex + 1];

    const toStop = nextLeg
      ? {
          ...to,
          shippingMilestones: [...to.shippingMilestones, ...nextLeg.from.shippingMilestones]
        }
      : to;

    if (isFirstLeg) {
      return [from, toStop];
    }

    return [...journeyStops, toStop];
  }, []);
};

export const getDeliveryStopTimeZone = (legs: JourneyLeg[]): Optional<string> => {
  const stops = getStops(legs);

  return stops.find((stop: JourneyStop) => stop.stopAction === JourneyStopActionEnum.DELIVERY)?.warehouse?.timeZone;
};

const getMilestoneName = (
  milestone: Optional<JourneyMilestoneWithMetadata>,
  modeOfTransport: Optional<ModeOfTransport>
): string => {
  if (!milestone) return '';

  return getMilestoneLabel({ ...milestone, modeOfTransport });
};

const milestoneWithDate = ({ actual, planned, requested }: JourneyMilestoneWithMetadata): boolean => {
  return !!actual?.date || !!planned?.startDateTime.date || !!requested?.startDateTime.date;
};

export const getMilestoneMostRelevantDate = (milestone: Optional<JourneyMilestoneWithMetadata>): Optional<string> => {
  if (!milestone) return;

  const { actual, planned, requested } = milestone;

  return actual?.date || planned?.startDateTime.date || requested?.startDateTime.date;
};

const getMaxDateForGateOutEmptyMilestone = (
  milestones: JourneyMilestoneWithMetadata[],
  modeOfTransport: Optional<ModeOfTransport>
) => {
  const maxDateMilestone = find(milestones, milestoneWithDate);

  return {
    date: getMilestoneMostRelevantDate(maxDateMilestone),
    dateType: maxDateMilestone ? getDateType(maxDateMilestone) : null,
    milestoneName: getMilestoneName(maxDateMilestone, modeOfTransport)
  };
};

const getMinDateForGateInEmptyMilestone = (
  milestones: JourneyMilestoneWithMetadata[],
  modeOfTransport: Optional<ModeOfTransport>
) => {
  const milestonesWithoutDelivery = milestones.filter((milestone) => !isDeliveryMilestone(milestone));
  const minDateMilestone = findLast(milestonesWithoutDelivery, milestoneWithDate);

  return {
    date: getMilestoneMostRelevantDate(minDateMilestone),
    dateType: minDateMilestone ? getDateType(minDateMilestone) : null,
    milestoneName: getMilestoneName(minDateMilestone, modeOfTransport)
  };
};

const getMilestoneMinAndMaxDates = (
  milestones: JourneyMilestoneWithMetadata[],
  modeOfTransport: Optional<ModeOfTransport>,
  milestoneId?: string
) => {
  const milestoneIndex: number = milestones.findIndex(({ id }) => id === milestoneId);
  const previousMilestones: JourneyMilestoneWithMetadata[] = milestoneId ? milestones.slice(0, milestoneIndex) : milestones;
  const followingMilestones: JourneyMilestoneWithMetadata[] = milestoneId ? milestones.slice(milestoneIndex + 1) : milestones;

  const minDateMilestone: Optional<JourneyMilestoneWithMetadata> = findLast(previousMilestones, milestoneWithDate);
  const maxDateMilestone: Optional<JourneyMilestoneWithMetadata> = find(followingMilestones, milestoneWithDate);

  return {
    minDate: {
      date: getMilestoneMostRelevantDate(minDateMilestone),
      dateType: minDateMilestone ? getDateType(minDateMilestone) : null,
      milestoneName: getMilestoneName(minDateMilestone, modeOfTransport)
    },
    maxDate: {
      date: getMilestoneMostRelevantDate(maxDateMilestone),
      dateType: maxDateMilestone ? getDateType(maxDateMilestone) : null,
      milestoneName: getMilestoneName(maxDateMilestone, modeOfTransport)
    }
  };
};

export const getMilestoneMinAndMaxDatesAcrossBooking = (
  cargoMilestones: Record<string, JourneyMilestoneWithMetadata[]>,
  modeOfTransport: Optional<ModeOfTransport>,
  milestoneId?: string
) => {
  const dates = Object.values(cargoMilestones).map((milestones: JourneyMilestoneWithMetadata[]) => {
    return getMilestoneMinAndMaxDates(milestones, modeOfTransport, milestoneId);
  });

  return {
    minDate: maxBy(dates, 'minDate.date')?.minDate,
    maxDate: minBy(dates, 'maxDate.date')?.maxDate
  };
};

const verifyMilestoneById = (milestoneId: string, milestone: Optional<JourneyShippingMilestone>): boolean => {
  return !!milestone && milestone.id === milestoneId;
};

interface MinDateParams {
  cargoReadyDate: Optional<string>;
  gateOutEmptyMilestone: Optional<JourneyMilestoneWithMetadata>;
  modeOfTransport: Optional<ModeOfTransport>;
}

export const getMinDateOutsideJourney = (params: MinDateParams): Optional<DateEndpoint> => {
  const { cargoReadyDate, gateOutEmptyMilestone, modeOfTransport } = params;

  const dates: DateEndpoint[] = [
    {
      date: cargoReadyDate,
      dateType: MilestoneDateType.ACTUAL,
      milestoneName: 'Cargo ready for collection'
    },
    {
      date: gateOutEmptyMilestone?.actual?.date,
      dateType: MilestoneDateType.ACTUAL,
      milestoneName: getMilestoneName(gateOutEmptyMilestone, modeOfTransport)
    }
  ].filter(({ date }) => date);

  return maxBy(dates, 'date');
};

const getMaxDateIncludingPostLegsMilestones = (
  gateInEmptyMilestone: Optional<JourneyMilestoneWithMetadata>,
  maxDateWithinJourney: Optional<DateEndpoint>,
  modeOfTransport: Optional<ModeOfTransport>
) => {
  const dates = [
    maxDateWithinJourney,
    {
      date: gateInEmptyMilestone?.actual?.date,
      dateType: MilestoneDateType.ACTUAL,
      milestoneName: getMilestoneName(gateInEmptyMilestone, modeOfTransport)
    }
  ].filter((date) => date?.date);

  return minBy(dates, 'date');
};

interface Params {
  dateType: MilestoneDateType;
  deliveryMilestone: boolean;
  gateInEmptyDate: Optional<string>;
  maxDateWithinJourney: Optional<string>;
  today: string;
}

const getMaxDate = ({ dateType, maxDateWithinJourney, today, gateInEmptyDate, deliveryMilestone }: Params) => {
  if (dateType === MilestoneDateType.ACTUAL) {
    const dates = deliveryMilestone ? [today] : [today, maxDateWithinJourney, gateInEmptyDate];

    return min(dates.filter(Boolean));
  }

  const dates = deliveryMilestone ? [] : [maxDateWithinJourney, gateInEmptyDate];

  return min(dates.filter(Boolean));
};

export const getLatestGateOutEmptyMilestone = (cargoPreLegsMilestones: Record<string, JourneyMilestoneWithMetadata[]>) => {
  const gateOutEmptyMilestones: JourneyMilestoneWithMetadata[] = Object.values(cargoPreLegsMilestones).flat();

  return maxBy(gateOutEmptyMilestones, 'actual.date');
};

export const getDisabledDates = (params: DisabledDatesParams): MilestoneDisabledDateProps => {
  const {
    cargoReadyDate,
    cargoMilestones,
    cargoPreLegsMilestones,
    dateType,
    gateInEmptyMilestone,
    gateOutEmptyMilestone,
    milestoneId,
    milestones,
    modeOfTransport
  } = params;

  const milestoneIndex: number = milestones.findIndex(({ id }) => id === milestoneId);
  const milestone: JourneyMilestoneWithMetadata = milestones[milestoneIndex];
  const milestoneName: JourneyShippingMilestoneNameEnum = milestone?.name;
  const sharedMilestone: boolean = isSharedMilestone(milestoneName);
  const gateInEmptyDate: Optional<string> = gateInEmptyMilestone?.actual?.date;
  const today: string = getToday();
  const isGateOutEmptyMilestone: boolean = verifyMilestoneById(milestoneId, gateOutEmptyMilestone);
  const isGateInEmptyMilestone: boolean = verifyMilestoneById(milestoneId, gateInEmptyMilestone);

  if (isGateOutEmptyMilestone) {
    const maxDate = getMaxDateForGateOutEmptyMilestone(milestones, modeOfTransport);

    return {
      disabledDates: {
        maxDate: maxDate?.date && maxDate.date < today ? maxDate.date : today
      },
      tooltip: [
        ...(maxDate?.date
          ? [
              {
                matcher: { maxDate: maxDate.date },
                message: (
                  <DisabledDateTooltip dateConstraint="max" dateType={maxDate.dateType} milestoneName={maxDate.milestoneName} />
                )
              }
            ]
          : []),
        {
          matcher: { maxDate: today },
          message: 'Selected date cannot be in the future.'
        }
      ]
    };
  }

  if (isGateInEmptyMilestone) {
    const minDate = getMinDateForGateInEmptyMilestone(milestones, modeOfTransport);

    return {
      disabledDates: {
        minDate: minDate?.date,
        maxDate: today
      },
      tooltip: [
        ...(minDate?.date
          ? [
              {
                matcher: { minDate: minDate.date },
                message: (
                  <DisabledDateTooltip dateConstraint="max" dateType={minDate.dateType} milestoneName={minDate.milestoneName} />
                )
              }
            ]
          : []),
        {
          matcher: { maxDate: today },
          message: 'Selected date cannot be in the future.'
        }
      ]
    };
  }

  if (milestoneIndex === -1) {
    return {};
  }

  const { minDate: minDateWithinJourney, maxDate } = sharedMilestone
    ? getMilestoneMinAndMaxDatesAcrossBooking(cargoMilestones, modeOfTransport, milestoneId)
    : getMilestoneMinAndMaxDates(milestones, modeOfTransport, milestoneId);

  const minDateOutsideJourney = getMinDateOutsideJourney({
    cargoReadyDate,
    gateOutEmptyMilestone: sharedMilestone ? getLatestGateOutEmptyMilestone(cargoPreLegsMilestones) : gateOutEmptyMilestone,
    modeOfTransport
  });
  const maxDateIncludingPostLegsMilestones = getMaxDateIncludingPostLegsMilestones(
    gateInEmptyMilestone,
    maxDate,
    modeOfTransport
  );
  const minDate = maxBy(
    [minDateWithinJourney, minDateOutsideJourney].filter((dataEndpoint) => dataEndpoint?.date),
    'date'
  );

  return {
    disabledDates: {
      minDate: minDate?.date,
      maxDate: getMaxDate({
        dateType,
        deliveryMilestone: isDeliveryMilestone(milestone),
        gateInEmptyDate,
        maxDateWithinJourney: maxDate?.date,
        today
      })
    },
    tooltip: [
      ...(minDate?.date
        ? [
            {
              matcher: { minDate: minDate.date },
              message: (
                <DisabledDateTooltip dateConstraint="min" dateType={minDate.dateType} milestoneName={minDate.milestoneName} />
              )
            }
          ]
        : []),
      ...(maxDateIncludingPostLegsMilestones?.date && !isDeliveryMilestone(milestone)
        ? [
            {
              matcher: { maxDate: maxDateIncludingPostLegsMilestones?.date },
              message: (
                <DisabledDateTooltip
                  dateConstraint="max"
                  dateType={maxDateIncludingPostLegsMilestones.dateType}
                  milestoneName={maxDateIncludingPostLegsMilestones.milestoneName}
                />
              )
            }
          ]
        : []),
      ...(dateType === MilestoneDateType.ACTUAL
        ? [
            {
              matcher: { maxDate: today },
              message: 'Selected date cannot be in the future'
            }
          ]
        : [])
    ]
  };
};

export const isSharedMilestone = (milestoneName: JourneyShippingMilestoneNameEnum): boolean => {
  return [
    JourneyShippingMilestoneNameEnum.AIRCRAFT_ARRIVED,
    JourneyShippingMilestoneNameEnum.AIRCRAFT_DEPARTED,
    JourneyShippingMilestoneNameEnum.TRAIN_ARRIVED,
    JourneyShippingMilestoneNameEnum.TRAIN_DEPARTED,
    JourneyShippingMilestoneNameEnum.VESSEL_ARRIVED,
    JourneyShippingMilestoneNameEnum.VESSEL_DEPARTED
  ].includes(milestoneName);
};

export const isFirstJourneyMilestone = (milestones: JourneyShippingMilestone[], milestoneId: string): boolean => {
  return milestones[0]?.id === milestoneId;
};

export const isLastJourneyMilestone = (milestones: JourneyShippingMilestone[], milestoneId: string): boolean => {
  const milestoneIndex: number = milestones.findIndex(({ id }) => id === milestoneId);

  return milestoneIndex === milestones.length - 1;
};

export const isLastCompletedJourneyMilestone = (milestones: JourneyShippingMilestone[], milestoneId: string): boolean => {
  const milestoneIndex: number = milestones.findIndex(({ id }) => id === milestoneId);
  const lastCompletedMilestoneIndex: number = findLastIndex(milestones, ({ completed }: JourneyShippingMilestone) => completed);

  return milestoneIndex === lastCompletedMilestoneIndex;
};
