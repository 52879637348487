import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import type { Account } from '@zen/Accounts/types';
import type { Activity, ActivityFeedItemTypeEnum, ActivityFeedOriginViewType, PermittedParties } from '@zen/ActivityFeed';
import { ActivityFeedTargetTypeEnum, SkeletonActivityFeedLoading } from '@zen/ActivityFeed';
import ActivityFeed from '@zen/ActivityFeed/ActivityFeed';
import createPermittedParties from '@zen/ActivityFeed/helpers/createPermittedParties';
import useRole from '@zen/Auth/hooks/useRole';
import QueryHandler from '@zen/Components/QueryHandler';
import type { NetworksOrgLoc } from '@zen/Networks';

import type { TargetPermissions } from '../ActivityFeedContainer/ActivityFeedContainer';
import { useShipmentActivityFeedItemsLazyQuery, useShipmentActivityFeedPermittedPartiesQuery } from './graphql';
import { mergeItemsData, prepareActivityItemsIds } from './helpers/shipmentActivityFeed.helper';
import type { ActivityFeedDataState, ShipmentPermittedPartiesData } from './types';

interface Props {
  hideTimeline?: boolean;
  itemTypes?: ActivityFeedItemTypeEnum[];
  targetPermissions?: TargetPermissions;
  viewType?: ActivityFeedOriginViewType;
  zencargoReference: string;
}

const ShipmentActivityFeed: FC<Props> = (props) => {
  const { viewType, zencargoReference, targetPermissions, itemTypes, hideTimeline } = props;

  const [queryActivityFeedItems, activityFeedItemsResult] = useShipmentActivityFeedItemsLazyQuery({
    fetchPolicy: 'no-cache'
  });

  const {
    data: activityFeedPermittedParties,
    loading: permittedPartiesLoading,
    error: permittedPartiesError
  } = useShipmentActivityFeedPermittedPartiesQuery({
    variables: { zencargoReference }
  });

  const [activityFeed, setActivityFeed] = useState<Activity[]>([]);
  const [feedData, setFeedData] = useState<ActivityFeedDataState>({});
  const previousZencargoReference = usePrevious(zencargoReference);
  const role = useRole();

  const { data: shipmentActivityFeedItems, loading } = activityFeedItemsResult;

  useEffect(() => {
    if (shipmentActivityFeedItems && !loading) {
      setFeedData(mergeItemsData(feedData, shipmentActivityFeedItems, activityFeed));
    }
  }, [shipmentActivityFeedItems, loading]); // eslint-disable-line

  useEffect(() => {
    if (previousZencargoReference !== zencargoReference) {
      // switched shipments, clear any stored items data
      setActivityFeed([]);
      setFeedData({});
    }
  }, [previousZencargoReference, zencargoReference]);

  useEffect(() => {
    if (activityFeed.length) {
      const feedIds = prepareActivityItemsIds(activityFeed);
      const variables = {
        actionItemsIds: feedIds.actionItemsIds,
        quoteOptionsIds: feedIds.quoteOptionsIds,
        zencargoReferences: [zencargoReference],
        textMessagesIdsByTarget: [
          {
            targetId: zencargoReference,
            targetType: ActivityFeedTargetTypeEnum.BOOKING,
            ids: feedIds.textMessagesIds
          }
        ]
      };

      queryActivityFeedItems({ variables });
    }
  }, [activityFeed, zencargoReference]); // eslint-disable-line

  const fetchActivityFeedItemsData = (activityFeedItems: Activity[]) => {
    setActivityFeed([...activityFeedItems, ...activityFeed]);
  };

  return (
    <QueryHandler
      data={activityFeedPermittedParties?.bookings?.nodes?.[0]}
      error={!!permittedPartiesError}
      isLoading={permittedPartiesLoading}
      loadingComponent={<SkeletonActivityFeedLoading />}
    >
      {(permittedPartiesData: ShipmentPermittedPartiesData) => {
        const {
          originAgent,
          manufacturers,
          canSendTextMessageToAgentForwarder,
          canSendTextMessageToCustomerUser,
          canSendTextMessageToManufacturer,
          customer
        } = permittedPartiesData;

        const permittedParties: PermittedParties = createPermittedParties(
          {
            manufacturers: manufacturers as NetworksOrgLoc[],
            originAgent: originAgent as NetworksOrgLoc,
            organisationName: customer?.name,
            permissions: {
              canSendTextMessageToAgentForwarder,
              canSendTextMessageToCustomerUser,
              canSendTextMessageToManufacturer
            },
            supplier: manufacturers[0] as Account
          },
          role,
          targetPermissions
        );

        return (
          <ActivityFeed<ActivityFeedDataState>
            activityFeedItemsResult={feedData}
            fetchActivityItemsData={fetchActivityFeedItemsData}
            hideTimeline={hideTimeline}
            itemTypes={itemTypes}
            permittedParties={permittedParties}
            targetId={zencargoReference}
            targetPermissions={targetPermissions}
            targetType={ActivityFeedTargetTypeEnum.BOOKING}
            viewType={viewType}
          />
        );
      }}
    </QueryHandler>
  );
};

export default ShipmentActivityFeed;
