import type { FC } from 'react';

import { Dialog } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useArchiveAccountLocationMutation } from '../../graphql';

interface Props {
  isOpen: boolean;
  locationId: string;
  locationName: string;
  onClose: () => void;
}

const ArchiveLocationModal: FC<Props> = (props) => {
  const { isOpen, onClose, locationId, locationName } = props;
  const { addError, addSuccess } = useNotification();
  const [archiveLocation, { loading }] = useArchiveAccountLocationMutation({ refetchQueries: ['getAccountLocations'] });

  const handleArchiveLocation = async (id: string): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        archiveLocation({
          variables: {
            locationId: id
          }
        }),
      onError: () =>
        addError('Location could not be archived.', { label: 'Try again', onClick: () => handleArchiveLocation(id) }),
      onSuccess: () => {
        addSuccess('Location was successfully archived.');
        onClose();
      }
    });
  };

  const handleConfirm = async (): Promise<void> => {
    if (locationId) {
      await handleArchiveLocation(locationId);
    }
  };

  return (
    <Dialog
      confirmLabel="Archive"
      header={`Archive '${locationName}'?`}
      isLoading={loading}
      isOpen={isOpen}
      message="This location will no longer be accessible for future bookings. Existing bookings associated with this location will be retained. You can unarchive this location at any time if needed, restoring its availability for new bookings."
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
};

export default ArchiveLocationModal;
export type { Props as ArchiveLocationModalProps };
