import { useParams } from 'react-router-dom';

import useAccount from '@zen/utils/hooks/useAccount';

import type { RateCardQueryHookResult } from '..';
import { useRateCardQuery } from '..';
import type { RateCardDetailsQueryHookVariables } from './types';

const useRateCardDetails = (variables: RateCardDetailsQueryHookVariables = {}): RateCardQueryHookResult => {
  const { rateCardId } = useParams<{ rateCardId?: string }>();
  const { accountUuid: customerId } = useAccount();

  return useRateCardQuery({
    variables: { id: rateCardId || '', customerId, ...variables },
    skip: !rateCardId,
    fetchPolicy: 'no-cache'
  });
};

export default useRateCardDetails;
