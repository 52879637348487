import type { FC } from 'react';
import { useState } from 'react';

import ContextAction from '@zen/Tasks/components/ContextAction';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useDeleteScimApiKeyMutation } from '../../graphql/deleteScimApiKey.generated';
import DeleteKeyModal from '../DeleteKeyModal';

interface Props {
  apiKeyId: string;
}

const DeleteKeyButton: FC<Props> = (props) => {
  const { apiKeyId } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addSuccess, addError } = useNotification();
  const [deleteScimApiKey] = useDeleteScimApiKeyMutation({ refetchQueries: ['getAccountDetails'] });

  const handleDeleteAPIKey = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () => deleteScimApiKey({ variables: { id: apiKeyId } }),
      onError: () =>
        addError('API key could not be deleted.', {
          label: 'Try again',
          onClick: () => handleDeleteAPIKey()
        }),
      onSuccess: () => addSuccess('API key was deleted.')
    });
  };

  return (
    <>
      <DeleteKeyModal
        apiKeyId={apiKeyId}
        closeModal={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
        onConfirm={handleDeleteAPIKey}
      />
      <ContextAction
        icon="zicon-trash"
        iconActiveColor="red"
        onClick={() => setIsModalOpen(true)}
        tooltipContent="Delete API key"
      />
    </>
  );
};

export default DeleteKeyButton;
