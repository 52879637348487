import type { FC } from 'react';

import { BookingDetailsPageSection } from '@zen/Booking/BookingDetails/types';
import type { ReferenceListItem } from '@zen/Components/ReferencesList';
import ReferencesList from '@zen/Components/ReferencesList';
import { shipmentRoutes } from '@zen/Routes';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Optional } from '@zen/utils/typescript';

import type { PurchaseOrderReference, PurchaseOrderReferences } from './types';
import { PurchaseOrderReferenceTrackingAction, PurchaseOrderReferenceTrackingCategory } from './types';

interface Props {
  detailsUrl: (id: string, tab?: string) => string;
  orderReferencesList: Optional<PurchaseOrderReferences>;
  zencargoReference: string;
}

const OrdersReferencesList: FC<Props> = ({ detailsUrl, orderReferencesList, zencargoReference }) => {
  const { trackEvent } = useTracking();

  const handleClick = (orderReferenceNumber: string) => {
    trackEvent({
      category: PurchaseOrderReferenceTrackingCategory,
      action: PurchaseOrderReferenceTrackingAction,
      label: orderReferenceNumber,
      properties: {
        zencargoReference,
        orderReferenceNumber
      }
    });
  };

  const prepareOrderReferencesList = (
    referencesList: Optional<PurchaseOrderReferences>,
    getUrl: (id: string, tab?: string) => string
  ): ReferenceListItem[] =>
    referencesList && referencesList?.nodes && referencesList?.nodes?.length
      ? referencesList.nodes.map((order: Optional<PurchaseOrderReference>) => ({
          referenceLabel: order?.orderReferenceNumber || '',
          url: order?.id ? getUrl(order?.id, 'items') : ''
        }))
      : [];

  return (
    <ReferencesList
      allDetailsLink={shipmentRoutes.shipmentDetailsPage.getUrl(zencargoReference, BookingDetailsPageSection.ORDERS)}
      onClick={handleClick}
      referencesList={prepareOrderReferencesList(orderReferencesList, detailsUrl)}
      totalCount={orderReferencesList?.totalCount}
    />
  );
};

export default OrdersReferencesList;
