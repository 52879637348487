import type { FC } from 'react';

import type { FormInstance } from '@zen/Components';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import FormField from '@zen/Components/Form/FormField';
import type { Option } from '@zen/DesignSystem';
import type { Nullable, Optional } from '@zen/utils/typescript';

import CargoOptionsSelect from '../CargoOptionsSelect';
import type { CargoOptionEnum, ChargeBasisGroup } from '../types';

interface Props extends FormFieldProps {
  chargeBasisGroup?: Optional<ChargeBasisGroup>;
  isDisabled?: boolean;
}

const FormCargoOptionsSelect: FC<Props> = (props) => {
  const { chargeBasisGroup, isDisabled } = props;

  return (
    <FormField {...props}>
      {(
        field: { error: boolean; name: string; options: Option<CargoOptionEnum>[]; value: CargoOptionEnum[] },
        form: FormInstance<unknown>
      ) => (
        <CargoOptionsSelect
          {...field}
          chargeBasisGroup={chargeBasisGroup}
          hasError={field.error}
          isDisabled={isDisabled}
          onChange={(value: Nullable<string[]>) => {
            form.setFieldValue(field.name, value || null);
          }}
          value={field.value}
        />
      )}
    </FormField>
  );
};

export default FormCargoOptionsSelect;
