import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { Element, scroller } from 'react-scroll';

import useRole from '@zen/Auth/hooks/useRole';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import type { TargetPermissions } from '@zen/Booking/BookingDetails/components/ActivityFeedContainer/ActivityFeedContainer';
import { useNotificationsMarkAsReadMutation } from '@zen/Notifications';

import ActivityFeedList from './ActivityFeedList';
import ActivityFeedInput from './components/ActivityFeedInput';
import EmptyState from './components/EmptyState';
import PermittedPartiesContext from './context/PermittedPartiesContext';
import { useActivityAddedSubscription } from './graphql';
import { getActivityFeedUserInformation } from './helpers/getActivityFeedUserInformation';
import useActivityQuery from './hooks/useActivityQuery';
import { SkeletonActivityFeedLoading } from './SkeletonActivityFeedLoading';
import {
  type Activity,
  type ActivityFeedItemTypeEnum,
  type ActivityFeedOriginViewType,
  type ActivityFeedTargetTypeEnum,
  type ActivityType,
  type PermittedParties,
  Role
} from './types';

interface Props<T> {
  activityFeedItemsResult: T;
  fetchActivityItemsData: (activityFeed: Activity[]) => void;
  hideTimeline?: boolean;
  itemTypes?: ActivityFeedItemTypeEnum[];
  permittedParties: PermittedParties;
  targetId: string;
  targetPermissions?: TargetPermissions;
  targetType: ActivityFeedTargetTypeEnum;
  viewType?: ActivityFeedOriginViewType;
}

const ActivityFeed = <T extends {}>(props: Props<T>): ReactElement => {
  const {
    viewType,
    targetId,
    targetPermissions,
    targetType,
    permittedParties,
    fetchActivityItemsData,
    activityFeedItemsResult,
    itemTypes,
    hideTimeline
  } = props;

  const [activityFeed, hasMoreResults, refetch, isLoading] = useActivityQuery(targetId, targetType, itemTypes);
  const [updatedFeed, setUpdatedFeed] = useState<Activity[]>([]);

  const [markAsRead] = useNotificationsMarkAsReadMutation();
  const hasActivityFeedItems = activityFeed.length > 0;
  const shouldDisplayList = !isLoading && hasActivityFeedItems;
  const role = useRole();

  const { check } = useGlobalPermissions();
  const canManageActivityFeed: boolean = check('accounts.canCreateActivityFeed') || role === Role.AGENT_FORWARDER;
  const runMarkAsReadMutation = (list: Activity[]) => {
    const lastItem: Activity = list[list.length - 1];

    const variables = {
      input: {
        targetId,
        lastNotificationItemCreatedAt: lastItem.createdAt
      }
    };

    markAsRead({ variables });
  };

  useEffect(() => {
    if (shouldDisplayList) {
      runMarkAsReadMutation(activityFeed);
    }
  }, [shouldDisplayList]); // eslint-disable-line

  useEffect(() => {
    if (updatedFeed.length) {
      runMarkAsReadMutation(updatedFeed);
      scroller.scrollTo('listStart', {
        containerId: 'activity-feed-list'
      });
    }
  }, [updatedFeed]); // eslint-disable-line

  useActivityAddedSubscription({
    fetchPolicy: 'no-cache',
    variables: { targetId, targetType },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.activityAdded) {
        const item = subscriptionData.data.activityAdded as Activity;

        if (!updatedFeed.find((feedItem) => feedItem.id === item.id)) {
          if (itemTypes && !itemTypes.find((itemType: ActivityFeedItemTypeEnum) => item.itemType === itemType)) {
            return;
          }
          setUpdatedFeed([...updatedFeed, item]);
        }
      }
    }
  });

  const list: ActivityType[] = [...activityFeed, ...updatedFeed];
  const hasItems: boolean = list.length > 0;
  const activityFeedItems: Activity[] = list.map(({ legacyUser, user, userDetails, ...rest }: ActivityType) => {
    return {
      ...rest,
      user: getActivityFeedUserInformation(legacyUser, user, userDetails)
    };
  });

  return (
    <PermittedPartiesContext.Provider value={permittedParties}>
      <div className="relative flex flex-col h-full" data-testid="activity-feed">
        {isLoading && <SkeletonActivityFeedLoading />}
        <div
          className="flex flex-col-reverse flex-1 -ml-8 -mr-4 overflow-x-hidden overflow-y-auto activity-feed"
          id="activity-feed-list"
        >
          <div className="w-full h-0">
            &nbsp; <Element name="listStart" />
          </div>
          {!hasItems && !isLoading && <EmptyState targetType={targetType} />}
          {hasItems && (
            <ActivityFeedList<T>
              activityFeed={activityFeedItems}
              activityFeedItemsResult={activityFeedItemsResult}
              fetchActivityItemsData={fetchActivityItemsData}
              hasMoreResults={hasMoreResults}
              hideTimeline={hideTimeline}
              refetch={refetch}
            />
          )}
        </div>
        {canManageActivityFeed && (
          <div className="z-40 w-full">
            <ActivityFeedInput
              permittedParties={permittedParties}
              targetId={targetId}
              targetPermissions={targetPermissions}
              targetType={targetType}
              viewType={viewType}
            />
          </div>
        )}
      </div>
    </PermittedPartiesContext.Provider>
  );
};

export default ActivityFeed;
