import type { FC } from 'react';

import type {
  BookingCollectionMetadataType,
  BookingDeliveryMetadataType,
  CollectionAndDeliveryStatus
} from '@zen/ActivityFeed/types';
import { ActivityFeedItemTypeEnum } from '@zen/ActivityFeed/types';
import { formatDate } from '@zen/utils/dateTime';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';
import type { Nullable, Optional } from '@zen/utils/typescript';

interface CollectionDeliveryCompletedItem {
  dateTime: string;
  labelPart: string;
}

type CollectionOrDeliveryMetadata = BookingCollectionMetadataType | BookingDeliveryMetadataType;

interface Props {
  collectionOrDeliveryStatus: Nullable<CollectionAndDeliveryStatus>;
  itemType: ActivityFeedItemTypeEnum;
  metadata: CollectionOrDeliveryMetadata;
  scheduledAt: Optional<string>;
}

const DateLabel: FC<Props> = (props) => {
  const { collectionOrDeliveryStatus, itemType, metadata, scheduledAt } = props;
  const isCollectionCompletedType: boolean = itemType === ActivityFeedItemTypeEnum.COMPLETED_COLLECTION;
  const isDeliveryCompletedType: boolean = itemType === ActivityFeedItemTypeEnum.COMPLETED_DELIVERY;
  const classNames: string = 'text-sm text-grey-dark whitespace-pre-wrap';

  const getCollectionOrDeliveryDateTime = (arg: CollectionOrDeliveryMetadata): Optional<string> => {
    if ('collectedOn' in arg) {
      return arg.collectedOn;
    }

    if ('deliveredOn' in arg) {
      return arg.deliveredOn;
    }
  };

  if (!isCollectionCompletedType && !isDeliveryCompletedType && collectionOrDeliveryStatus) {
    return (
      <div className={classNames}>
        {removeSpecialCharactersAndUpperFirst(collectionOrDeliveryStatus)} for {scheduledAt}
      </div>
    );
  }

  const dateTime: string = getCollectionOrDeliveryDateTime(metadata) || '';

  const config: { [key: string]: CollectionDeliveryCompletedItem } = {
    [ActivityFeedItemTypeEnum.COMPLETED_COLLECTION]: {
      dateTime,
      labelPart: 'collected'
    },
    [ActivityFeedItemTypeEnum.COMPLETED_DELIVERY]: {
      dateTime,
      labelPart: 'delivered'
    }
  };

  const item: CollectionDeliveryCompletedItem = config[itemType];
  const completionDate = formatDate(item.dateTime, 'D_MMMM_YYYY');

  return (
    <div className={classNames}>
      {removeSpecialCharactersAndUpperFirst(item.labelPart)} on {completionDate}
    </div>
  );
};

export default DateLabel;
