import type { FC, ReactNode } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import { Form, FormButtons, FormInput } from '@zen/Components/Form';
import FormTextarea from '@zen/Components/Form/FormTextarea';
import { Button } from '@zen/DesignSystem';
import type { ShipmentActionType } from '@zen/graphql/types.generated';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { validationSchema } from './shipmentActionTypeForm.validation';
import type { ShipmentActionTypeFormValues } from './types';

const defaultInitialValues: ShipmentActionTypeFormValues = {
  name: '',
  title: '',
  description: ''
};

interface Props {
  buttonLabel?: string;
  initialValues?: ShipmentActionType;
  onCancel: () => void;
  onSubmit: (values: ShipmentActionTypeFormValues) => Promise<IOkOrErrorResult>;
  onSuccess?: () => void;
}

const ShipmentActionTypeForm: FC<Props> = (props) => {
  const { initialValues = defaultInitialValues, onSubmit, onCancel, onSuccess, buttonLabel } = props;

  const renderFormButtons = ({ isSubmitting }: FormInstance<ShipmentActionTypeFormValues>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text={buttonLabel}>
      <Button onClick={onCancel} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const formFields: ReactNode = (
    <>
      <FormInput isRequired={true} name="name" placeholder="Add name" />
      <FormInput isRequired={true} name="title" placeholder="Add title" />
      <FormTextarea isRequired={true} name="description" placeholder="Add description" />
    </>
  );

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {() => formFields}
    </Form>
  );
};

export default ShipmentActionTypeForm;
