import type { FC } from 'react';

import { ModeOfTransportIcon } from '@zen/Booking/BookingDetails';
import PageTitle from '@zen/Components/PageTitle';

import type { ModeOfTransport } from './types';

interface Props {
  modeOfTransport: ModeOfTransport;
  rateCardName: string;
}

const RateCardTitle: FC<Props> = ({ modeOfTransport, rateCardName }) => {
  return (
    <div className="flex space-x-4" data-testid="rate-card-title">
      {modeOfTransport && <ModeOfTransportIcon modeOfTransport={modeOfTransport} />}
      <PageTitle tagline="Rate card" title={rateCardName} />
    </div>
  );
};

export default RateCardTitle;
