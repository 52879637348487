import type { SegmentedControlOption, SegmentedControlProps } from '@zen/DesignSystem';
import { SegmentedControl } from '@zen/DesignSystem';

import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import type { FormInstance } from '../types';

interface Props<T> extends FormFieldProps {
  options: SegmentedControlOption<T>[];
}

interface Field<T> extends SegmentedControlProps<T> {}

function FormSegmentedControl<T = string>(props: Props<T>) {
  return (
    <FormField {...props} type="radio">
      {(field: Field<T>, form: FormInstance<unknown>) => {
        const handleChange = (value: T): void => form.setFieldValue(field.name || '', value);

        return <SegmentedControl {...field} onChange={handleChange} />;
      }}
    </FormField>
  );
}

export default FormSegmentedControl;
