import { omit } from 'lodash';

import { formatBusinessHours, hasStandardOpeningHours } from '@zen/Accounts/AccountDetails/AccountLocation/helpers';
import { defaultMapLatitude, defaultMapLongitude } from '@zen/Components/Map';
import type {
  CreateAccountLocationInput,
  UpdateAccountLocationAddressInput,
  UpdateAccountLocationDetailsInput
} from '@zen/graphql/types.generated';
import type { Optional } from '@zen/utils/typescript';

import { getDefaultLatLng, getDefaultWorkDays, prepareAddressForSubmission } from '../../AccountForms/helpers';
import type { AccountLocationDetails } from '../../AccountForms/types';
import type { AccountLocation } from '../types';

export const prepareCreateLocationValues = (
  values: AccountLocationDetails,
  accountId: string,
  accountName: string
): CreateAccountLocationInput => {
  const { shippingLocationAddress, shippingLocation, businessHours } = values;
  const { geolocation, businessHoursOption, ...rest } = shippingLocation;

  return {
    accountId,
    location: {
      ...rest,
      businessHours,
      companyName: accountName,
      geolocation: omit(geolocation, 'isPinPlacementConfirmed') || getDefaultLatLng(),
      address: shippingLocationAddress
    }
  };
};

export const prepareShippingLocationInitialValues = (location: Optional<Omit<AccountLocation, 'members'>>) => {
  if (!location) {
    return {
      locationId: '',
      shippingLocationAddress: {
        city: '',
        country: null,
        countyOrState: '',
        line1: '',
        line2: '',
        postalCodeOrZip: ''
      },
      shippingLocation: {
        businessHoursOption: 'standard' as const,
        geolocation: { isPinPlacementConfirmed: false, latitude: defaultMapLatitude, longitude: defaultMapLongitude },
        name: '',
        usageContext: null
      },
      businessHours: getDefaultWorkDays()
    };
  }

  const { id, address, geolocation, name, businessHours, usageContext } = location;

  return {
    locationId: id,
    shippingLocationAddress: prepareAddressForSubmission(address),
    shippingLocation: {
      businessHoursOption: hasStandardOpeningHours(formatBusinessHours(businessHours)) ? 'standard' : 'custom',
      geolocation: { isPinPlacementConfirmed: true, ...geolocation },
      name,
      usageContext
    },
    businessHours
  };
};

export const prepareLocationAddressValues = (values: AccountLocationDetails): UpdateAccountLocationAddressInput => {
  const { locationId, shippingLocation, shippingLocationAddress } = values;
  const { geolocation } = shippingLocation;

  return {
    locationId,
    address: shippingLocationAddress,
    geolocation: omit(geolocation, 'isPinPlacementConfirmed') || getDefaultLatLng()
  };
};

export const prepareLocationDetailsValues = (
  values: AccountLocationDetails,
  accountName: string
): UpdateAccountLocationDetailsInput => {
  const { locationId, shippingLocation } = values;
  const { name, usageContext } = shippingLocation;

  return {
    companyName: accountName,
    locationId,
    name,
    usageContext
  };
};
