import type { DocumentNode } from 'graphql';
import { get, uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useMountedState } from 'react-use';

import { apolloClient as apollo } from '@zen/graphql/GraphQLProvider';

import type { Nullable } from '../typescript';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface IUseInfiniteScrollArgs {
  dependencies: any[];
  isReverse?: boolean;
  query: DocumentNode;
  responsePath?: string;
  variables: any;
}

const useInfiniteScroll = <T extends {}>(props: IUseInfiniteScrollArgs): any[] => {
  const { query, isReverse = false, variables, dependencies, responsePath = 'data' } = props;
  const [data, setData] = useState<Partial<T>[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [hasMoreResults, setHasMoreResults] = useState<boolean>(false);
  const [afterCursor, setAfterCursor] = useState<Nullable<string>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isMounted = useMountedState();

  const fetchData = async (after: Nullable<string> = afterCursor, abortController = new AbortController()) => {
    if (!after) {
      setIsLoading(true);
    }

    try {
      const QUERY = {
        query,
        variables: {
          ...variables,
          after,
          requestId: uniqueId()
        },
        context: {
          fetchOptions: {
            signal: abortController.signal
          }
        }
      };

      const response = await apollo.query(QUERY);

      // bail out if component was unmounted while the request was happening
      if (!isMounted()) {
        return;
      }
      const {
        pageInfo: { hasNextPage, endCursor },
        nodes
      } = get(response, responsePath);

      if (!after) {
        const newNodes = isReverse ? nodes.reverse() : nodes;

        setData([...newNodes]);
      } else {
        const updatedNodes = isReverse ? [...nodes.reverse(), ...data] : [...data, ...nodes];

        setData(updatedNodes);
      }
      setHasMoreResults(hasNextPage);
      setAfterCursor(endCursor);
      setError(false);
    } catch {
      // bail out if component was unmounted in the meantime
      if (isMounted()) {
        setError(true);
      }
    }
    // bail out if component was unmounted in the meantime
    if (isMounted()) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    fetchData(null, abortController);

    return () => {
      abortController.abort();
    };
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  return [data, hasMoreResults, fetchData, isLoading, error, setData];
};

export default useInfiniteScroll;
