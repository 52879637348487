import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ShipmentActivityFeedPermittedPartiesQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type ShipmentActivityFeedPermittedPartiesQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              originAgent?: GraphQLTypes.Maybe<
                | { __typename?: 'Account' }
                | { __typename?: 'AccountLocation' }
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      typename: 'NetworksLegacyOrgLoc';
                    } & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLegacyOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksLegacyOrgLocOrganisation,
                          'name'
                        >
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      typename: 'NetworksOrgLoc';
                    } & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocOrganisation' } & Pick<GraphQLTypes.NetworksOrgLocOrganisation, 'name'>
                      >;
                    })
                | { __typename?: 'NetworksTerminal' }
              >;
              manufacturers?: GraphQLTypes.Maybe<
                Array<
                  | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'tradingName'> & { typename: 'Account' })
                  | { __typename?: 'AccountLocation' }
                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                        typename: 'NetworksLegacyOrgLoc';
                      } & {
                        organisation?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLegacyOrgLocOrganisation' } & Pick<
                            GraphQLTypes.NetworksLegacyOrgLocOrganisation,
                            'name'
                          >
                        >;
                      })
                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                        typename: 'NetworksOrgLoc';
                      } & {
                        organisation?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksOrgLocOrganisation' } & Pick<GraphQLTypes.NetworksOrgLocOrganisation, 'name'>
                        >;
                      })
                  | { __typename?: 'NetworksTerminal' }
                >
              >;
              canSendTextMessageToAgentForwarder: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              canSendTextMessageToCustomerUser: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              canSendTextMessageToManufacturer: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              customer?: GraphQLTypes.Maybe<
                { __typename?: 'BookingCustomerType' } & Pick<GraphQLTypes.BookingCustomerType, 'name' | 'uuid'>
              >;
            }
        >
      >
    >;
  };
};

export const ShipmentActivityFeedPermittedPartiesDocument = /* #__PURE__ */ gql`
  query shipmentActivityFeedPermittedParties($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        originAgent {
          ... on NetworksLegacyOrgLoc {
            typename: __typename
            id
            organisation {
              name
            }
          }
          ... on NetworksOrgLoc {
            typename: __typename
            id
            organisation {
              name
            }
          }
        }
        manufacturers {
          ... on Account {
            typename: __typename
            tradingName
          }
          ... on NetworksLegacyOrgLoc {
            typename: __typename
            id
            organisation {
              name
            }
          }
          ... on NetworksOrgLoc {
            typename: __typename
            id
            organisation {
              name
            }
          }
        }
        canSendTextMessageToAgentForwarder {
          value
        }
        canSendTextMessageToCustomerUser {
          value
        }
        canSendTextMessageToManufacturer {
          value
        }
        customer {
          name
          uuid
        }
      }
    }
  }
`;

/**
 * __useShipmentActivityFeedPermittedPartiesQuery__
 *
 * To run a query within a React component, call `useShipmentActivityFeedPermittedPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentActivityFeedPermittedPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentActivityFeedPermittedPartiesQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useShipmentActivityFeedPermittedPartiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShipmentActivityFeedPermittedPartiesQueryResult,
    ShipmentActivityFeedPermittedPartiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ShipmentActivityFeedPermittedPartiesQueryResult, ShipmentActivityFeedPermittedPartiesQueryVariables>(
    ShipmentActivityFeedPermittedPartiesDocument,
    options
  );
}
export function useShipmentActivityFeedPermittedPartiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShipmentActivityFeedPermittedPartiesQueryResult,
    ShipmentActivityFeedPermittedPartiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ShipmentActivityFeedPermittedPartiesQueryResult, ShipmentActivityFeedPermittedPartiesQueryVariables>(
    ShipmentActivityFeedPermittedPartiesDocument,
    options
  );
}
export type ShipmentActivityFeedPermittedPartiesQueryHookResult = ReturnType<typeof useShipmentActivityFeedPermittedPartiesQuery>;
export type ShipmentActivityFeedPermittedPartiesLazyQueryHookResult = ReturnType<
  typeof useShipmentActivityFeedPermittedPartiesLazyQuery
>;
