import type { FC } from 'react';

import type { SegmentedControlOption } from '@zen/DesignSystem';
import { SegmentedControl } from '@zen/DesignSystem';

import { ViewMode } from './types';

export const viewOptions: SegmentedControlOption<ViewMode>[] = [
  { value: ViewMode.LIST, label: 'List' },
  { value: ViewMode.TABLE, label: 'Table' }
];

interface Props {
  onChange: (value: string) => void;
  selectedView: string;
}

const ViewSwitcher: FC<Props> = ({ onChange, selectedView }) => {
  return <SegmentedControl onChange={onChange} options={viewOptions} value={selectedView} />;
};

export default ViewSwitcher;
