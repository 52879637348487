import type { ObjectSchema } from 'yup';
import { array, object, string } from 'yup';

const secureUrlRegExp = /^(https:\/\/)?(www.)?[a-z0-9-]+\.[a-z]/;

export const createValidationSchema = (isBusinessUnitFieldVisible: boolean): ObjectSchema<{}> =>
  object().shape({
    businessUnit: isBusinessUnitFieldVisible ? string().required('Please select a business unit.') : string(),
    name: string().required('Webhook name is required.'),
    callbackUrl: string().matches(secureUrlRegExp, 'Please provide a valid, secure url.').required('Callback Url is required.'),
    topics: array().min(1, 'Please select at least one topic you want to subscribe to.'),
    contactEmail: string().email('Please provide a valid email address.').required('Email is required.'),
    headers: array().of(
      object().shape({
        name: string().required('Header name is required'),
        value: string().required('Header value is required')
      })
    )
  });
