import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { BusinessHoursFragmentDoc } from '../../../graphql/fragments/BusinessHours.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetAccountLocationQueryVariables = GraphQLTypes.Exact<{
  locationId: GraphQLTypes.Scalars['String'];
}>;

export type GetAccountLocationQueryResult = { __typename?: 'Query' } & {
  accountLocation?: GraphQLTypes.Maybe<
    { __typename?: 'AccountLocation' } & Pick<
      GraphQLTypes.AccountLocation,
      'addressLabel' | 'companyName' | 'id' | 'isArchived' | 'name' | 'usageContext'
    > & {
        address: { __typename?: 'Address' } & Pick<
          GraphQLTypes.Address,
          'line1' | 'line2' | 'city' | 'countyOrState' | 'postalCodeOrZip'
        > & { country?: GraphQLTypes.Maybe<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>> };
        businessHours?: GraphQLTypes.Maybe<
          { __typename?: 'BusinessHours' } & {
            monday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
            tuesday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
            wednesday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
            thursday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
            friday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
            saturday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
            sunday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
          }
        >;
        geolocation?: GraphQLTypes.Maybe<
          { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
        >;
        permissions?: GraphQLTypes.Maybe<
          { __typename?: 'LocationPermissions' } & {
            canEditAddress?: GraphQLTypes.Maybe<
              { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
            >;
            canEditBusinessHours?: GraphQLTypes.Maybe<
              { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
            >;
            canEditDetails?: GraphQLTypes.Maybe<
              { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
            >;
          }
        >;
      }
  >;
};

export const GetAccountLocationDocument = /* #__PURE__ */ gql`
  query getAccountLocation($locationId: String!) {
    accountLocation(locationId: $locationId) {
      address {
        line1
        line2
        city
        countyOrState
        postalCodeOrZip
        country {
          code
          name
        }
      }
      addressLabel
      businessHours {
        ...BusinessHours
      }
      companyName
      geolocation {
        latitude
        longitude
      }
      id
      isArchived
      name
      permissions {
        canEditAddress {
          value
        }
        canEditBusinessHours {
          value
        }
        canEditDetails {
          value
        }
      }
      usageContext
    }
  }
  ${BusinessHoursFragmentDoc}
`;

/**
 * __useGetAccountLocationQuery__
 *
 * To run a query within a React component, call `useGetAccountLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetAccountLocationQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountLocationQueryResult, GetAccountLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAccountLocationQueryResult, GetAccountLocationQueryVariables>(GetAccountLocationDocument, options);
}
export function useGetAccountLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountLocationQueryResult, GetAccountLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAccountLocationQueryResult, GetAccountLocationQueryVariables>(
    GetAccountLocationDocument,
    options
  );
}
export type GetAccountLocationQueryHookResult = ReturnType<typeof useGetAccountLocationQuery>;
export type GetAccountLocationLazyQueryHookResult = ReturnType<typeof useGetAccountLocationLazyQuery>;
