import type { FC } from 'react';

import TabViewController from '@zen/Components/TabViewController';
import type { TabViewConfig } from '@zen/Components/TabViewController/types';

import type { ContainerProps } from '../Container';
import Container from '../Container';

type Props = Omit<ContainerProps, 'children' | 'control'> & { onTabChange?: () => void; tabViewConfig: TabViewConfig[] };

const ContainerWithTabs: FC<Props> = ({ onTabChange, tabViewConfig, ...containerProps }) => {
  return (
    <TabViewController onTabChange={onTabChange} tabViewConfig={tabViewConfig}>
      {({ tabs, content }) => (
        <Container control={<div className="-mt-1">{tabs}</div>} data-testid="container-with-tabs" {...containerProps}>
          {content}
        </Container>
      )}
    </TabViewController>
  );
};

export default ContainerWithTabs;
