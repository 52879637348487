import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateContractIdsMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateContractInput;
}>;

export type UpdateContractIdsMutationResult = { __typename?: 'Mutation' } & {
  updateContract?: GraphQLTypes.Maybe<
    { __typename?: 'ContractUpdatePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      contract?: GraphQLTypes.Maybe<
        { __typename?: 'ContractPayload' } & Pick<
          GraphQLTypes.ContractPayload,
          'carrierId' | 'contractId' | 'contractType' | 'id' | 'simplifiedContractId'
        > & {
            oceanCarrier?: GraphQLTypes.Maybe<
              { __typename?: 'OceanCarrier' } & Pick<GraphQLTypes.OceanCarrier, 'oceanCarrierName'>
            >;
          }
      >;
    }
  >;
};

export const UpdateContractIdsDocument = /* #__PURE__ */ gql`
  mutation updateContractIds($input: UpdateContractInput!) {
    updateContract(input: $input) {
      errors {
        message
        path
      }
      contract {
        carrierId
        contractId
        contractType
        id
        oceanCarrier {
          oceanCarrierName
        }
        simplifiedContractId
      }
    }
  }
`;

/**
 * __useUpdateContractIdsMutation__
 *
 * To run a mutation, you first call `useUpdateContractIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractIdsMutation, { data, loading, error }] = useUpdateContractIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContractIdsMutationResult, UpdateContractIdsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateContractIdsMutationResult, UpdateContractIdsMutationVariables>(
    UpdateContractIdsDocument,
    options
  );
}
export type UpdateContractIdsMutationHookResult = ReturnType<typeof useUpdateContractIdsMutation>;
