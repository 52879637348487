import type { ObjectSchema } from 'yup';
import { object, string } from 'yup';

import { addressValidation } from '../networks.validation';

export const validationSchema: ObjectSchema<{}> = object().shape({
  name: string().nullable().required('Organisation name is required'),
  type: string().nullable().required('Organisation type is required'),
  ...addressValidation
});
