import { isObject } from 'lodash';

import type { OceanChargeFormValues } from '../OceanChargeForm';
import type { OceanChargeItemFormValues } from '../OceanChargeForm/types';
import type { Applicability, CostTrackingLocation, CreateDefaultPortChargeInput, NetworksOrgLoc, Terminal } from './types';

export const prepareLocation = (location: Terminal | NetworksOrgLoc): CostTrackingLocation => {
  const { id, label } = location;
  const locationLabel: NetworksOrgLoc['label'] = isObject(label) ? label : { long: label, short: label };

  return {
    id: id?.toString() || '',
    label: locationLabel
  };
};

export const prepareCreateOceanChargeInput = (
  applicability: Applicability,
  values: OceanChargeFormValues
): CreateDefaultPortChargeInput[] => {
  const { chargeItems, location } = values;

  return chargeItems.map((chargeItem: OceanChargeItemFormValues) => {
    const { basisGroup, chargeType, unitPrice, ...rest } = chargeItem;

    return {
      applicability,
      chargeTypeId: chargeType.id,
      currency: unitPrice.currency,
      locationId: prepareLocation(location).id || '',
      value: unitPrice.value,
      ...rest
    };
  });
};
