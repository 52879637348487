import type { FC } from 'react';
import { useCallback, useEffect } from 'react';

import type { ChargeTypeEnum } from '@zen/Accruals/ChargeTypes/types';
import { FormChargeTypeSelect, FormCurrencyInput } from '@zen/Components';
import FormIncotermSelect from '@zen/Components/Form/FormIncotermSelect';
import LabelledValue from '@zen/Components/LabelledValue';
import type { Terminal } from '@zen/Components/TerminalSelect/types';
import { Banner } from '@zen/DesignSystem';
import FormFreightChargePortSelect from '@zen/RateCards/LegacyRateCards/FormFreightChargePortSelect';
import type { PortChargePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';
import { formatChargeBasis, formatUnit } from '@zen/RateCards/LegacyRateCards/utils/tableDataFormatting';

import { getCreateFormLabelConfig } from '../helpers';
import type { PortChargeFormFieldLabelConfig, PortChargeFormLabelConfig, PortChargeFormValues } from '../types';

interface Props {
  chargeType: ChargeTypeEnum;
  labelConfig: PortChargeFormFieldLabelConfig;
  portCharges: PortChargePayload[];
  ports: Terminal[];
  setConfig: (value: PortChargeFormLabelConfig) => void;
  setFieldValue: (fieldName: string, value: unknown) => void;
  values: PortChargeFormValues;
}

const CreatePortChargeFields: FC<Props> = (props) => {
  const { values, setFieldValue, labelConfig, ports, portCharges, chargeType, setConfig } = props;

  const getMatchingPortCharge = useCallback(
    (chargeTypeId: string, unlocode: string): PortChargePayload | undefined => {
      return portCharges.find(
        (portCharge: PortChargePayload) => portCharge.chargeType.id === chargeTypeId && portCharge.port.unlocode === unlocode
      );
    },
    [portCharges]
  );

  const matchingPortCharge: PortChargePayload | undefined =
    values.chargeType && values.port ? getMatchingPortCharge?.(values.chargeType.id, values.port.unlocode) : undefined;

  useEffect(() => {
    setConfig(getCreateFormLabelConfig(matchingPortCharge));

    if (matchingPortCharge) {
      setFieldValue('incoterms', matchingPortCharge.incoterms);
    }
  }, [matchingPortCharge, setConfig, setFieldValue, values]);

  return (
    <div data-testid="create-port-charge-fields">
      {matchingPortCharge && (
        <div className="mt-2 mb-6">
          <Banner
            icon="zicon-alert"
            message="We found an existing charge with the same details you're trying to add. You can either cancel the process or proceed to set a custom price."
          />
        </div>
      )}
      <FormChargeTypeSelect label={labelConfig.chargeType} name="chargeType" type={chargeType} />
      <FormFreightChargePortSelect label={labelConfig.port} name="port" ports={ports} />
      <div className="grid grid-cols-2 mb-6 gap-x-4" data-testid="form-meta-data">
        <LabelledValue label="Charge basis">{formatChargeBasis(values.chargeType)}</LabelledValue>
        <LabelledValue label="Unit">{formatUnit(values.chargeType)}</LabelledValue>
      </div>
      <div className="grid grid-cols-2 gap-x-4">
        <FormIncotermSelect
          isDisabled={!!matchingPortCharge}
          isMulti={true}
          label={labelConfig.incoterms}
          name="incoterms"
          showIncotermsExplanation={false}
        />
        <FormCurrencyInput label={labelConfig.price} name="price" />
      </div>
    </div>
  );
};

export default CreatePortChargeFields;
