import type { FC, ReactNode } from 'react';

import type { FormInstance } from '@zen/Components';
import { Form, FormButtons, FormHeadline } from '@zen/Components';
import { Button } from '@zen/DesignSystem';
import type { ModeOfTransport } from '@zen/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { CreateContainerCargoItemMutationResult } from '../../../graphql';
import LooseFormFields from '../../LooseCargo/LooseFormFields/LooseFormFields';
import FullContainerFormFields from '../FullContainerFormFields';
import { getFullContainerFormValidationSchema } from './fullContainerForm.validation';
import { defaultFullContainerFormInitialValues } from './helpers';
import type { FullContainerFormInitialValues, FullContainerFormValues } from './types';

interface Props {
  initialValues?: FullContainerFormInitialValues;
  modeOfTransport: ModeOfTransport;
  onCancel: () => void;
  onSubmit: (values: FullContainerFormValues) => Promise<IOkOrErrorResult>;
  onSuccess?: (cargoId: string) => void;
}

const FullContainerForm: FC<Props> = ({ initialValues, onCancel, onSubmit, onSuccess, modeOfTransport }) => {
  const handleSuccess = (results: CreateContainerCargoItemMutationResult['cargoCreateContainerCargoItem']) => {
    onSuccess?.(results?.cargoItem?.id || '');
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<FullContainerFormValues>): ReactNode => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Save">
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  const formInitialValues: FullContainerFormInitialValues = initialValues || defaultFullContainerFormInitialValues;

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={formInitialValues}
      onSubmit={onSubmit}
      onSuccess={handleSuccess}
      validationSchema={getFullContainerFormValidationSchema(formInitialValues.loose, modeOfTransport)}
    >
      {() => (
        <div className="space-y-6">
          <FullContainerFormFields showContainerDetails={true} />
          {initialValues && (
            <>
              <FormHeadline tagline="Select the type and size to ship your goods" text="Cargo details" />
              <LooseFormFields showCargoParams={false} />
            </>
          )}
        </div>
      )}
    </Form>
  );
};

export default FullContainerForm;
