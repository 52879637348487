import cx from 'classnames';
import type { FC, ReactNode, SyntheticEvent } from 'react';

import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent } from '@zen/utils/tracking';

import { IconButton } from '../Button';
import Popover from '../Popover';
import MenuList from './MenuList/MenuList';
import type { ContextMenuState, MenuItemType } from './types';

interface RenderProps {
  className?: string;
  disabled?: boolean;
  footer?: ReactNode;
  inline?: boolean;
  items: MenuItemType[];
  menuOffset?: [number, number];
  onClose?: () => void;
  onOpen?: () => void;
  togglerSize?: 'medium' | 'large';
}

interface Props extends RenderProps {
  children?: ReactNode | undefined | ((props: ContextMenuState) => ReactNode);
}

export const displayName = 'Design System/ContextMenu';

const ContextMenu: FC<Props> = (props) => {
  const {
    className,
    disabled = false,
    children = <IconButton icon="zicon-dots" size={props.togglerSize || 'large'} variant="ghost" />,
    footer,
    inline = true,
    menuOffset = [0, 6],
    items,
    onOpen,
    onClose
  } = props;

  const { trackEvent } = useTracking();

  const handleMenuChange = (isVisible: boolean): void => {
    return isVisible ? onOpen?.() : onClose?.();
  };

  const handleClick = (event: SyntheticEvent): void => {
    if (!disabled) {
      const trackingEvent = createTrackingEvent(displayName, 'click', event);

      trackEvent(trackingEvent);
    }
  };

  const renderMenu = ({ close }: { close: () => void }) => <MenuList closeMenu={close} footer={footer} items={items} />;
  const wrapperClassNames: string = cx(className);

  return (
    <div className={wrapperClassNames} data-testid="context-menu">
      <Popover
        disabled={disabled}
        interactive={false}
        offset={menuOffset}
        onVisibilityChange={handleMenuChange}
        placement="bottom-end"
        popover={renderMenu}
        popoverClassNames="min-w-40 w-max max-w-x py-1 border border-solid border-grey-lighter"
        renderInPortal={!inline}
      >
        {({ isPopoverVisible }) => {
          const classNames: string = cx(
            {
              'cursor-not-allowed text-grey-lighter': disabled,
              'bg-grey-lightest text-grey-dark': isPopoverVisible,
              'text-navy-base active:bg-grey-light hover:bg-grey-lighter': !isPopoverVisible && !disabled,
              'text-navy-base': !disabled
            },
            'transition-all duration-200 ease-linear rounded'
          );

          const renderChildren = (): ReactNode => {
            if (typeof children === 'function') {
              return children({ isExpanded: isPopoverVisible });
            }

            return <div className={classNames}>{children}</div>;
          };

          return (
            <div data-component="context-menu-toggler" data-testid="dropdown-toggler" onClick={handleClick}>
              {renderChildren()}
            </div>
          );
        }}
      </Popover>
    </div>
  );
};

export type { Props as ContextMenuProps };

export default ContextMenu;
