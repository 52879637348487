import { isEqual } from 'lodash';
import type { FC } from 'react';
import { useState } from 'react';

import type { FormInstance } from '@zen/Components';
import { Form, FormButtons } from '@zen/Components';
import type { Terminal } from '@zen/Components/TerminalSelect';
import { Button, Modal } from '@zen/DesignSystem';
import { ChargeTypeEnum } from '@zen/graphql/types.generated';
import type { PortChargePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';
import { Currency } from '@zen/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import CreatePortChargeFields from './CreatePortChargeFields';
import EditPortChargeFields from './EditPortChargeFields';
import { getPortChargeFormLabels, prepareInitialValues, preparePayload } from './helpers';
import { getValidationSchema } from './portChargeForm.validation';
import type {
  PortChargeFormFieldLabelConfig,
  PortChargeFormInitialValues,
  PortChargeFormLabelConfig,
  PortChargeFormValues
} from './types';

interface Props {
  initialValues?: PortChargePayload;
  isOpen: boolean;
  onCancel?: () => void;
  onClose: () => void;
  onSubmit: (values: PortChargePayload) => void;
  onSuccess: () => void;
  portCharges: PortChargePayload[];
  ports: Terminal[];
  type: 'origin' | 'destination';
}

const defaultInitialValues: PortChargeFormInitialValues = {
  chargeType: null,
  price: {
    value: null,
    currency: Currency.USD
  },
  incoterms: null,
  port: null
};

const PortChargeForm: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  initialValues,
  onSuccess,
  type,
  onCancel,
  portCharges,
  ports
}) => {
  const [formLabelConfig, setFormLabelConfig] = useState<PortChargeFormLabelConfig>({ buttonTitle: '', title: '' });
  const chargeType: ChargeTypeEnum = type === 'origin' ? ChargeTypeEnum.ORIGIN : ChargeTypeEnum.DESTINATION;
  const labelConfig: PortChargeFormFieldLabelConfig = getPortChargeFormLabels(type);

  const handleSubmit = (values: PortChargeFormValues): Promise<IOkOrErrorResult> => {
    const portCharge: PortChargePayload = preparePayload(values);

    onSubmit(portCharge);

    return Promise.resolve({ ok: true, error: null });
  };

  const setLabelConfiguration = (value: PortChargeFormLabelConfig): void => {
    if (!isEqual(formLabelConfig, value)) {
      setFormLabelConfig(value);
    }
  };

  const initialFormValues: PortChargeFormInitialValues = initialValues
    ? prepareInitialValues(initialValues)
    : defaultInitialValues;

  const renderFormButtons = ({ isSubmitting }: FormInstance<PortChargeFormInitialValues>) => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text={formLabelConfig.buttonTitle}>
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Modal closeOnClickAway={false} isOpen={isOpen} modalOverflowY="scroll" onClose={onClose} title={formLabelConfig.title}>
      <Form
        formButtons={renderFormButtons}
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        validationSchema={getValidationSchema(labelConfig)}
      >
        {({ values, setFieldValue }) =>
          initialValues ? (
            <EditPortChargeFields
              initialValues={initialValues}
              labelConfig={labelConfig}
              setConfig={setLabelConfiguration}
              values={values}
            />
          ) : (
            <CreatePortChargeFields
              chargeType={chargeType}
              labelConfig={labelConfig}
              portCharges={portCharges}
              ports={ports}
              setConfig={setLabelConfiguration}
              setFieldValue={setFieldValue}
              values={values}
            />
          )
        }
      </Form>
    </Modal>
  );
};

export default PortChargeForm;
