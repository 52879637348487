import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import OperationsSettingsSearch from '@zen/Components/OperationsSettingsSearch';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Button, Table } from '@zen/DesignSystem';
import opsSettingsRoutes from '@zen/Routes/opsSettings';
import type { SortInput } from '@zen/types';
import { SortingOrder } from '@zen/types';
import usePagination from '@zen/utils/hooks/pagination/usePagination';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import type { GetContactsQueryResult, GetContactsQueryVariables } from '../graphql';
import { useGetContactsQuery } from '../graphql';
import type { NetworkContact } from '../types';
import { getColumnsConfiguration } from './contactListTableConfiguration';

const initialOrder: SortInput = {
  field: 'name',
  direction: SortingOrder.ASC
};

const ContactList: FC = () => {
  const { navigate } = useNavigationHistory();

  const [order, setOrder] = useState<SortInput>(initialOrder);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { loading, error, nodes, paginationInfo, totalCount } = usePagination<
    GetContactsQueryResult,
    GetContactsQueryVariables,
    NetworkContact
  >(useGetContactsQuery, 'contacts', { order, searchQuery }, 20);

  const handleEditContact = (contactId: string): void => {
    navigate(opsSettingsRoutes.contactsEdit.getUrl(contactId));
  };

  const newContactButton: ReactNode = (
    <Button onClick={() => navigate(opsSettingsRoutes.contactsCreate.getUrl())} variant="secondary">
      New contact
    </Button>
  );

  const columns = getColumnsConfiguration(handleEditContact);

  return (
    <Page
      actionButtons={newContactButton}
      defaultBackUrl={opsSettingsRoutes.opsSettingsIndex.getUrl()}
      tagline="A list of all contacts."
      title="Contacts"
    >
      <OperationsSettingsSearch onSearch={setSearchQuery} placeholder="Search for a contact..." />
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        loadingComponent={<SkeletonTableLoading columns={columns} order={order} tableId="contactList" />}
      >
        {(contactList: NetworkContact[]) => (
          <Table<NetworkContact>
            columns={columns}
            data={contactList}
            onOrderChange={setOrder}
            order={order}
            paginationInfo={paginationInfo}
            tableId="contactList"
            totalCountConfig={{
              totalCount,
              entityName: 'contact'
            }}
          />
        )}
      </QueryHandler>
    </Page>
  );
};

export default ContactList;
