import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import { Form, FormButtons, FormSelect } from '@zen/Components/Form';
import type { FormButtonsLayout } from '@zen/Components/Form/FormButtons';
import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import FormRadioGroup from '@zen/Components/Form/FormRadioGroup';
import FormTextarea from '@zen/Components/Form/FormTextarea';
import type { Option } from '@zen/DesignSystem';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { validationSchema } from './dateForm.validation';
import { confidenceOptions, dateFormInitialValues } from './helpers';
import type { DateFormType } from './types';
import { ConfidenceLevel } from './types';

interface Props {
  className?: string;
  delayReasonOptions?: Option<string>[];
  displayDelayReasonFields?: boolean;
  formButtonsLayout?: FormButtonsLayout;
  initialValues?: DateFormType;
  isInitialDateEntry?: boolean;
  maxDate?: string;
  minDate?: string;
  onCancel: () => void;
  onSubmit: (values: DateFormType) => Promise<IOkOrErrorResult>;
  onSuccess?: () => void;
}

const DateForm: FC<Props> = (props) => {
  const {
    formButtonsLayout = 'static',
    initialValues = dateFormInitialValues,
    onSuccess,
    onCancel,
    isInitialDateEntry = false,
    onSubmit,
    delayReasonOptions = [],
    displayDelayReasonFields = true,
    minDate,
    maxDate,
    className
  } = props;
  const classNames: string = cx(className);

  const renderFormButtons = ({ isSubmitting }: FormInstance<DateFormType>): ReactNode => (
    <FormButtons containerClassName="mt-6" isSubmitting={isSubmitting} layout={formButtonsLayout} text="Submit">
      <Button data-testid="cancel-button" onClick={onCancel} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <div className={classNames}>
      <Form
        formButtons={renderFormButtons}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        validationSchema={validationSchema}
      >
        {({ values }) => {
          const shouldRenderDetailsFields: boolean =
            !isInitialDateEntry && displayDelayReasonFields && values.confidence === ConfidenceLevel.ESTIMATED;

          return (
            <>
              {!isInitialDateEntry && (
                <FormRadioGroup
                  label="Are you providing an estimate or actual date?"
                  name="confidence"
                  options={confidenceOptions}
                  radioAlignment="row"
                />
              )}
              <FormDatePickerInput
                className="w-1/2"
                disabledDates={{ minDate, maxDate }}
                isRequired={true}
                label="New date"
                name="date"
              />
              {shouldRenderDetailsFields && (
                <FormSelect isClearable={true} label="Delay reason" name="reason" options={delayReasonOptions} />
              )}

              {shouldRenderDetailsFields && <FormTextarea label="Additional information" name="description" />}
            </>
          );
        }}
      </Form>
    </div>
  );
};

export type { Props as DateFormProps };
export default DateForm;
