import type { FC } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import { Form, FormButtons } from '@zen/Components/Form';
import { Button, Modal } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import FormAccrualTemplateSelect from '../AccrualTemplateModal/FormAccrualTemplateSelect';
import type { AccrualTemplate } from '../types';
import { validationSchema } from './newAccrualModal.validation';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreate: () => void;
  onTemplateSelect: (accrualTemplateId: string) => void;
  title?: string;
}

const NewAccrualModal: FC<Props> = (props) => {
  const { isOpen, onClose, onCreate, onTemplateSelect, title = 'Select accrual template' } = props;

  const handleSubmit = ({ template }: { template: AccrualTemplate }): Promise<IOkOrErrorResult> => {
    onTemplateSelect(template.id);

    return Promise.resolve({ ok: {}, error: null });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<{ template: AccrualTemplate }>) => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Use template">
        <Button onClick={onClose} variant="ghost">
          Cancel
        </Button>
        <Button className="!mr-auto" iconLeft="zicon-add" onClick={() => onCreate()} variant="ghost">
          Blank document
        </Button>
      </FormButtons>
    );
  };

  return (
    <Modal isOpen={isOpen} modalOverflowY="visible" onClose={onClose} title={title}>
      <Form
        formButtons={renderFormButtons}
        initialValues={{ template: null }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {() => {
          return <FormAccrualTemplateSelect label="Name" name="template" />;
        }}
      </Form>
    </Modal>
  );
};

export default NewAccrualModal;
