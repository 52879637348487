import type { FC } from 'react';

import { Role } from '@zen/Auth';
import useRole from '@zen/Auth/hooks/useRole';
import type { CargoModeEnum, ModeOfTransport } from '@zen/Booking';
import QueryHandler from '@zen/Components/QueryHandler';
import { Button, TextLink } from '@zen/DesignSystem';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { useExportRateCard } from '@zen/RateCards/LegacyRateCards/ExportRateCard';
import type { RateCardFilters } from '@zen/RateCards/LegacyRateCards/RateCardDetails/Filters/types';
import { rateCardRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import { stringifyQueryParams } from '@zen/utils/QueryParams';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useBookingRateCardQuery, useRateCardForBookingQuery } from '../graphql';
import { usePriceCalculation } from '../PriceCalculationSection/hooks';
import { EmptyState, RateCardError, RateCardLoader } from './components';
import type { BookingRateCardType, RateCardForBookingType, Terminal } from './types';

interface Props {
  cargoType: CargoModeEnum;
  destinationPort: Nullable<Terminal>;
  editable?: boolean;
  isBookingConfirmed: boolean;
  modeOfTransport: ModeOfTransport;
  onCreateAccrual?: (rateCardId?: string, params?: string) => void;
  originPort: Nullable<Terminal>;
  zencargoReference: string;
}

const BookingRateCard: FC<Props> = (props) => {
  const {
    cargoType,
    destinationPort,
    editable = true,
    isBookingConfirmed,
    modeOfTransport,
    onCreateAccrual,
    originPort,
    zencargoReference
  } = props;
  const { accountUuid: accountId } = useAccount();
  const role = useRole();
  const { date } = usePriceCalculation(zencargoReference);
  const [exportRateCard] = useExportRateCard();
  const isRateCardsEnabled: boolean = useFeatureIsOn('rate-cards');

  const getLocationValue = (location: Nullable<Terminal>): string => {
    const value: Optional<string> = !isRateCardsEnabled ? location?.unlocode : location?.id?.toString();

    return value || '';
  };

  const canCreateAccrual = role === Role.ADMIN;
  const hasSailingSchedule: boolean = !!originPort && !!destinationPort;
  const destinationPortValue: string = getLocationValue(destinationPort);
  const originPortValue: string = getLocationValue(originPort);

  const shouldSkipRateCardQuery: boolean = !hasSailingSchedule && !date;
  const {
    data: legacyData,
    loading: legacyLoading,
    error: legacyError
  } = useBookingRateCardQuery({
    variables: {
      cargoType,
      modeOfTransport,
      accountId,
      originPort: originPortValue,
      destinationPort: destinationPortValue,
      validOn: date
    },
    skip: shouldSkipRateCardQuery || isRateCardsEnabled
  });

  const { data, loading, error } = useRateCardForBookingQuery({
    variables: {
      cargoType,
      modeOfTransport,
      customerId: accountId,
      originLocationId: originPortValue,
      destinationLocationId: destinationPortValue,
      validOn: date
    },
    skip: shouldSkipRateCardQuery || !isRateCardsEnabled
  });

  const legacyRateCardData: Optional<BookingRateCardType> = date ? legacyData?.legacyGetRateCards?.nodes?.[0] : null;
  const rateCardData: Optional<RateCardForBookingType> = date ? data?.getRateCards?.nodes?.[0] : null;

  return (
    <QueryHandler
      data={legacyRateCardData || rateCardData}
      error={!!legacyError || !!error}
      errorComponent={<RateCardError />}
      isLoading={legacyLoading || loading}
      loadingComponent={<RateCardLoader />}
      noResults={
        <EmptyState
          editable={editable}
          hasPriceCalculationPoint={!!date}
          hasSailingSchedule={hasSailingSchedule}
          isBookingConfirmed={isBookingConfirmed}
        />
      }
    >
      {({ rateCardId, name }: BookingRateCardType | RateCardForBookingType) => {
        const filters: RateCardFilters = { destinationPorts: [destinationPortValue], originPorts: [originPortValue] };
        const params: string = stringifyQueryParams({ filters });
        const linkToRateCard: string = `${rateCardRoutes.details.getUrl(rateCardId)}${params}`;
        const handleExport = () => {
          exportRateCard(rateCardId, accountId);
        };

        return (
          <div className="flex items-center justify-between">
            <TextLink isExternal={true} linkTo={linkToRateCard}>
              {name}
            </TextLink>
            <div className="flex space-x-2">
              {canCreateAccrual && (
                <Button
                  onClick={() => {
                    onCreateAccrual?.(rateCardId, params);
                  }}
                  variant="secondary"
                >
                  Create V&A
                </Button>
              )}
              {!isRateCardsEnabled && (
                <Button onClick={handleExport} variant="secondary">
                  Export rate card
                </Button>
              )}
            </div>
          </div>
        );
      }}
    </QueryHandler>
  );
};

export default BookingRateCard;
