import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { LocationDescriptor, LocationDescriptorObject } from '@zen/types';

import type { Undefinable } from '../typescript';
import NavigationHistoryContext from './NavigationHistoryContext';
import type { NavigationRecord } from './types';

const makeDescriptorObject = (path: string): LocationDescriptorObject => {
  const [pathname, search] = path.split('?');

  return {
    pathname,
    search
  };
};

const NavigationHistoryProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { pathname, search, state } = useLocation();
  const navigate = useNavigate();
  const [navigationRecords, setNavigationRecords] = useState<Array<NavigationRecord>>([]);
  const NAVIGATION_RECORDS_SIZE = 10;

  const appendNavigationRecord = (target: LocationDescriptor) => {
    const path: string = typeof target === 'string' ? target : target.pathname;
    const records: NavigationRecord[] = [{ to: path, from: { pathname, search, state } }, ...navigationRecords];

    if (navigationRecords.length > NAVIGATION_RECORDS_SIZE) {
      records.pop();
    }

    setNavigationRecords(records);
  };

  const findNavigationRecord = (key: string): Undefinable<NavigationRecord> => {
    const record: Undefinable<NavigationRecord> = navigationRecords.find((navigationRecord: NavigationRecord) =>
      navigationRecord.to.includes(key)
    );

    if (record) {
      const records = navigationRecords.filter((navigationRecord: NavigationRecord) => navigationRecord.to !== record.to);

      setNavigationRecords(records);
    }

    return record;
  };

  const navigateToUrl = (target: LocationDescriptor): void => {
    appendNavigationRecord(target);

    const locationState: unknown = typeof target === 'string' ? undefined : target.state;

    navigate(target, { state: locationState });
  };

  const navigateBack = (
    key: string,
    defaultPath: string,
    modifyTargetLocation: (location: LocationDescriptorObject) => LocationDescriptorObject = (location) => location
  ): void => {
    const record: Undefinable<NavigationRecord> = findNavigationRecord(key);
    const backPath: LocationDescriptorObject = record ? modifyTargetLocation(record.from) : makeDescriptorObject(defaultPath);

    navigate(backPath, { state: backPath?.state });
  };

  return (
    <NavigationHistoryContext.Provider value={{ navigate: navigateToUrl, navigateBack }}>
      {children}
    </NavigationHistoryContext.Provider>
  );
};

export default NavigationHistoryProvider;
