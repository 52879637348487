import cx from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';

import { Icon, Tooltip } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  icon: IconName;
  iconActiveColor: 'red' | 'green';
  isDisabled?: boolean;
  onClick: Function;
  tooltipContent: string;
}

const ContextAction: FC<Props> = (props) => {
  const { onClick, iconActiveColor, icon, tooltipContent, isDisabled } = props;
  const [iconWasClicked, setIconWasClicked] = useState(false);

  const handleClick = async (): Promise<void> => {
    setIconWasClicked(true);

    await onClick();

    setIconWasClicked(false);
  };

  const iconColor: string = cx({
    'text-red-base': iconActiveColor === 'red' && iconWasClicked,
    'text-green-base': iconActiveColor === 'green' && iconWasClicked,
    'hover:text-red-base': iconActiveColor === 'red',
    'hover:text-green-base': iconActiveColor === 'green'
  });

  const iconBaseClassName: string = cx('py-4 -my-4 cursor-pointer');
  const iconClassName: string = cx(iconBaseClassName, iconColor, {
    'text-grey-light': !iconWasClicked
  });

  const DURATION_IN_MS: number = 1000;

  return (
    <Tooltip delayShow={DURATION_IN_MS} placement="bottom-end" tooltipContent={tooltipContent}>
      <Icon className={iconClassName} disabled={isDisabled} icon={icon} onClick={handleClick} />
    </Tooltip>
  );
};

export type { Props as ContextActionProps };
export default ContextAction;
