import pluralize from 'pluralize';
import type { FC } from 'react';

import type { BarChartConfig } from '@zen/DesignSystem';
import { BarChart } from '@zen/DesignSystem';

interface Props {
  bookedLotCount: number;
  lotCount: number;
}

export const LotsBooked: FC<Props> = ({ lotCount, bookedLotCount }) => {
  const percentageBooked: number = (bookedLotCount / lotCount) * 100;
  const config: BarChartConfig = {
    percentage: percentageBooked,
    type: 'info'
  };

  return (
    <>
      <BarChart className="h-1 mb-1" configs={[config]} />
      <div className="text-grey-base text-xs leading-normal">
        {bookedLotCount}/{lotCount} {pluralize('lot', lotCount)} booked
      </div>
    </>
  );
};

export default LotsBooked;
