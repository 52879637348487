import type { FC } from 'react';
import { useState } from 'react';

import { Modal } from '@zen/DesignSystem';
import { categoryLabelMapping, severityLabelMapping } from '@zen/Issues/issueHelpers';
import useTracking from '@zen/utils/hooks/useTracking';

import IssueActionButtons from '../components/IssueActionButtons';
import IssueDetails from '../components/IssueDetails';
import { buildStateChangesList, generateLabel } from '../components/IssueStateDescription/helper';
import type { StateDescription } from '../components/IssueStateDescription/types';
import EditIssue from '../EditIssue';
import type { Issue } from '../types';
import { IssueState } from '../types';

interface Props {
  associatedObjectReference?: string;
  issue: Issue;
  onClose: () => void;
  onIssueChange: () => void;
}

const IssueModalContainer: FC<Props> = ({ issue, onClose, associatedObjectReference, onIssueChange }) => {
  const { trackEvent } = useTracking();
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(!!issue);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);

  const { category, severity, state, title } = issue;

  const handleIssueStateChange = (): void => {
    setDetailsOpen(false);
    onIssueChange();
    onClose();
  };

  const handleDetailsModalClose = (): void => {
    setDetailsOpen(false);
    onClose();
  };

  const handleIssueEdit = (): void => {
    trackEvent({
      action: 'EditIssue',
      category: 'Issues',
      label: issue.category
    });

    setDetailsOpen(false);
    setEditOpen(true);
  };

  const handleEditModalClose = (): void => {
    setEditOpen(false);
    onClose();
  };

  const handleIssueEditSuccess = (): void => {
    handleEditModalClose();
    onIssueChange();
  };

  const { currentState }: StateDescription = buildStateChangesList(issue);

  const isArchived: boolean = IssueState.ARCHIVED === state;
  const titleSuffix: string = title || `${categoryLabelMapping[category]} issue`;

  const modalTitle: string = `${severityLabelMapping[severity]} - ${titleSuffix}`;
  const modalTagline: string = isArchived ? 'This issue has been archived' : generateLabel(currentState);

  return (
    <>
      <Modal
        closeOnClickAway={false}
        footer={
          <IssueActionButtons
            issue={issue}
            onCancel={handleDetailsModalClose}
            onEdit={handleIssueEdit}
            onStateChange={handleIssueStateChange}
          />
        }
        isOpen={isDetailsOpen}
        onClose={handleDetailsModalClose}
        tagline={modalTagline}
        title={modalTitle}
      >
        <IssueDetails issue={issue} />
      </Modal>

      <Modal
        isOpen={isEditOpen}
        onClose={handleEditModalClose}
        tagline={associatedObjectReference || issue.associatedId}
        title="Editing an issue"
      >
        <EditIssue issue={issue} onCancel={handleEditModalClose} onSuccess={handleIssueEditSuccess} />
      </Modal>
    </>
  );
};

export default IssueModalContainer;
