import { findLast, flatten } from 'lodash';

import type { DateWithTimeFields } from '@zen/Components/DateWithTimeForm';
import type { Optional } from '@zen/utils/typescript';

import type {
  CargoJourneyDetails,
  JourneyLegStop,
  JourneyMilestoneWithMetadata,
  JourneyStop,
  LegWithMetaData,
  LocalDateTimeRangeInput,
  MilestoneWithMetadata
} from './types';
import {
  JourneyShippingMilestoneNameEnum,
  JourneyShippingMilestoneRequestedByEnum,
  JourneyStopActionEnum,
  JourneyStopLocationTypeEnum,
  MilestoneDateType,
  ShippingMilestoneTypeEnum
} from './types';

export const getDateType = ({ actual, planned }: JourneyMilestoneWithMetadata): MilestoneDateType => {
  if (actual?.date) return MilestoneDateType.ACTUAL;

  return planned?.startDateTime?.date ? MilestoneDateType.PLANNED : MilestoneDateType.REQUESTED;
};

export const getStopTimeZone = (stop: Optional<JourneyStop>): Optional<string> => {
  return stop?.locationType === JourneyStopLocationTypeEnum.TERMINAL ? stop.terminal?.timeZone : stop?.warehouse?.timeZone;
};

export const isCargoMilestone = (type: ShippingMilestoneTypeEnum) => type === ShippingMilestoneTypeEnum.CARGO;

export const isCarriageDepartureStop = (stopAction: JourneyStopActionEnum): boolean =>
  stopAction === JourneyStopActionEnum.CARRIAGE_DEPARTURE;

export const isCarriageArrivalStop = (stopAction: JourneyStopActionEnum): boolean =>
  stopAction === JourneyStopActionEnum.CARRIAGE_ARRIVAL;

export const isCarriageTransshipmentStop = (stopAction: JourneyStopActionEnum): boolean =>
  stopAction === JourneyStopActionEnum.CARRIAGE_TRANSSHIPMENT;

export const isCollectionStop = (stopAction: JourneyStopActionEnum): boolean => stopAction === JourneyStopActionEnum.COLLECTION;

export const isCollectionAndDeliveryStop = (stopAction: JourneyStopActionEnum): boolean =>
  stopAction === JourneyStopActionEnum.COLLECTION_DELIVERY;

export const isCustomsStop = (stopAction: JourneyStopActionEnum): boolean => stopAction === JourneyStopActionEnum.CUSTOMS;

export const isDeliveryStop = (stopAction: JourneyStopActionEnum): boolean => stopAction === JourneyStopActionEnum.DELIVERY;

export const isVehicleMilestone = (type: ShippingMilestoneTypeEnum) => type === ShippingMilestoneTypeEnum.VEHICLE;

export const isVehicleChangeStop = (stopAction: JourneyStopActionEnum): boolean =>
  stopAction === JourneyStopActionEnum.VEHICLE_CHANGE;

export const isVehicleArrivedAtWarehouse = (name: JourneyShippingMilestoneNameEnum) =>
  name === JourneyShippingMilestoneNameEnum.VEHICLE_ARRIVED_AT_WAREHOUSE;

export const requestedByLabelMapping: Record<JourneyShippingMilestoneRequestedByEnum, string> = {
  [JourneyShippingMilestoneRequestedByEnum.CUSTOMER]: 'Customer',
  [JourneyShippingMilestoneRequestedByEnum.CUSTOMERS_SUPPLIER]: "Customer's supplier",
  [JourneyShippingMilestoneRequestedByEnum.THIRD_PARTY_WAREHOUSE]: 'Third-party warehouse',
  [JourneyShippingMilestoneRequestedByEnum.ZENCARGO]: 'Zencargo'
};

export const prepareDateAndTimePayload = (values: DateWithTimeFields): LocalDateTimeRangeInput => {
  const { date, endTime, startTime, time: pointInTime } = values;
  const time: Optional<string> = pointInTime || startTime;

  return {
    startDateTime: {
      date,
      ...(time ? { time } : {})
    },
    ...(endTime
      ? {
          endDateTime: {
            date,
            time: endTime
          }
        }
      : {})
  };
};

const getShippingMilestonesWithMetaData = <T extends { name: JourneyShippingMilestoneNameEnum }, K extends JourneyLegStop<T>>(
  stop: K
) => {
  const { shippingMilestones, ...rest } = stop;

  return (
    shippingMilestones?.map((shippingMilestone) => ({
      ...shippingMilestone,
      ...rest
    })) || []
  );
};

const getLegShippingMilestonesWithMetaData = <T extends { name: JourneyShippingMilestoneNameEnum }, K extends JourneyLegStop<T>>(
  leg: LegWithMetaData<T, K>
) => {
  const fromShippingMilestones = leg.from ? getShippingMilestonesWithMetaData(leg.from) : [];
  const toShippingMilestones = leg.to ? getShippingMilestonesWithMetaData(leg.to) : [];

  return flatten([fromShippingMilestones, toShippingMilestones]);
};

export const isDeliveryMilestone = (shippingMilestone: {
  name: JourneyShippingMilestoneNameEnum;
  stopAction: JourneyStopActionEnum;
}): boolean => {
  return (
    shippingMilestone.stopAction === JourneyStopActionEnum.DELIVERY &&
    shippingMilestone.name === JourneyShippingMilestoneNameEnum.VEHICLE_ARRIVED_AT_WAREHOUSE
  );
};

const isArrivingAtPortOfDestinationMilestone = (shippingMilestone: {
  name: JourneyShippingMilestoneNameEnum;
  stopAction: JourneyStopActionEnum;
}): boolean => {
  return (
    shippingMilestone.stopAction === JourneyStopActionEnum.CARRIAGE_ARRIVAL &&
    (shippingMilestone.name === JourneyShippingMilestoneNameEnum.VESSEL_ARRIVED ||
      shippingMilestone.name === JourneyShippingMilestoneNameEnum.AIRCRAFT_ARRIVED ||
      shippingMilestone.name === JourneyShippingMilestoneNameEnum.TRAIN_ARRIVED)
  );
};

const isCollectionMilestone = (shippingMilestone: {
  name: JourneyShippingMilestoneNameEnum;
  stopAction: JourneyStopActionEnum;
}): boolean => {
  return (
    shippingMilestone.stopAction === JourneyStopActionEnum.COLLECTION &&
    (shippingMilestone.name === JourneyShippingMilestoneNameEnum.VEHICLE_DEPARTED_FROM_WAREHOUSE ||
      shippingMilestone.name === JourneyShippingMilestoneNameEnum.VEHICLE_ARRIVED_AT_WAREHOUSE)
  );
};

const findMilestone = <T extends { name: JourneyShippingMilestoneNameEnum }, K extends JourneyLegStop<T>>(
  cargoJourney: CargoJourneyDetails<T, K>,
  milestoneType: 'delivery' | 'arriving-at-port-of-destination' | 'collection'
): MilestoneWithMetadata<T, K> => {
  const shippingMilestones = flatten(cargoJourney?.journey?.legs?.map((leg) => getLegShippingMilestonesWithMetaData(leg)));

  const matcher = {
    delivery: isDeliveryMilestone,
    'arriving-at-port-of-destination': isArrivingAtPortOfDestinationMilestone,
    collection: isCollectionMilestone
  };

  const milestone = findLast(shippingMilestones, matcher[milestoneType]) as MilestoneWithMetadata<T, K>;

  return milestone;
};

export const getDeliveryShippingMilestone = <T extends { name: JourneyShippingMilestoneNameEnum }, K extends JourneyLegStop<T>>(
  cargoJourney: CargoJourneyDetails<T, K>
): MilestoneWithMetadata<T, K> => {
  return findMilestone(cargoJourney, 'delivery');
};

export const getArrivingToPortOfDestinationShippingMilestone = <
  T extends { name: JourneyShippingMilestoneNameEnum },
  K extends JourneyLegStop<T>
>(
  cargoJourney: CargoJourneyDetails<T, K>
): MilestoneWithMetadata<T, K> => {
  return findMilestone(cargoJourney, 'arriving-at-port-of-destination');
};

export const getCollectionShippingMilestone = <T extends { name: JourneyShippingMilestoneNameEnum }, K extends JourneyLegStop<T>>(
  cargoJourney: CargoJourneyDetails<T, K>
): MilestoneWithMetadata<T, K> | undefined => {
  return findMilestone(cargoJourney, 'collection');
};
