import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ShipmentsTableCollectionLocationsQueryVariables = GraphQLTypes.Exact<{
  accountIds: Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String'];
  searchTerm?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  filterBy?: GraphQLTypes.InputMaybe<GraphQLTypes.ShipmentsTableFiltersInput>;
}>;

export type ShipmentsTableCollectionLocationsQueryResult = { __typename?: 'Query' } & {
  shipmentsTableCollectionLocationFilterValues: { __typename?: 'ShipmentsTableFilterValueConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'ShipmentsTableFilterValue' } & Pick<GraphQLTypes.ShipmentsTableFilterValue, 'key' | 'value'>
        >
      >
    >;
  };
};

export const ShipmentsTableCollectionLocationsDocument = /* #__PURE__ */ gql`
  query shipmentsTableCollectionLocations($accountIds: [String!]!, $searchTerm: String, $filterBy: ShipmentsTableFiltersInput) {
    shipmentsTableCollectionLocationFilterValues(
      accountIds: $accountIds
      searchTerm: $searchTerm
      filterBy: $filterBy
      first: 10000
    ) {
      nodes {
        key
        value
      }
    }
  }
`;

/**
 * __useShipmentsTableCollectionLocationsQuery__
 *
 * To run a query within a React component, call `useShipmentsTableCollectionLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentsTableCollectionLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentsTableCollectionLocationsQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      searchTerm: // value for 'searchTerm'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useShipmentsTableCollectionLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShipmentsTableCollectionLocationsQueryResult,
    ShipmentsTableCollectionLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ShipmentsTableCollectionLocationsQueryResult, ShipmentsTableCollectionLocationsQueryVariables>(
    ShipmentsTableCollectionLocationsDocument,
    options
  );
}
export function useShipmentsTableCollectionLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShipmentsTableCollectionLocationsQueryResult,
    ShipmentsTableCollectionLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ShipmentsTableCollectionLocationsQueryResult, ShipmentsTableCollectionLocationsQueryVariables>(
    ShipmentsTableCollectionLocationsDocument,
    options
  );
}
export type ShipmentsTableCollectionLocationsQueryHookResult = ReturnType<typeof useShipmentsTableCollectionLocationsQuery>;
export type ShipmentsTableCollectionLocationsLazyQueryHookResult = ReturnType<
  typeof useShipmentsTableCollectionLocationsLazyQuery
>;
