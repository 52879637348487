import type { FC, ReactNode } from 'react';

import type { FormArrayHelpers, FormInstance } from '@zen/Components/Form';
import { Form, FormArray, FormButtons } from '@zen/Components/Form';
import type { UpdateLotsInput } from '@zen/graphql/types.generated';
import type { LotInput } from '@zen/Orders/types';
import type { Money } from '@zen/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Optional } from '@zen/utils/typescript';

import { validationSchema } from './lotForm.validation';
import LotItem from './LotItem';
import Summary from './Summary';

interface InitialValues {
  lots: LotInput[];
}

const emptyLineItem: LotInput = {
  quantityFulfilled: null,
  cbm: null
};

const getIds = (items: LotInput[]): string[] => items.filter(({ id }: LotInput) => !!id).map(({ id }) => id as string);

const calculateFulfilledPercentage = (quantityFulfilled: Optional<number>, quantityOrdered: number): number => {
  if (!quantityFulfilled) {
    return 0;
  }

  return (quantityFulfilled / quantityOrdered) * 100;
};

interface Props {
  initialValues: InitialValues;
  onSubmit: (values: UpdateLotsInput) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
  productPrice: Money;
  quantityOrdered: number;
}

const LotForm: FC<Props> = (props) => {
  const { initialValues, onSubmit, onSuccess, productPrice, quantityOrdered } = props;

  const renderFormButtons = ({ isSubmitting }: FormInstance<UpdateLotsInput>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Submit" />
  );

  const handleSubmit = (values: UpdateLotsInput): Promise<IOkOrErrorResult> => {
    const initialIds = getIds(initialValues.lots);
    const ids = getIds(values.lots);
    const lotIdsToDelete = initialIds.filter((id: string) => !ids.includes(id));

    const payload = {
      ...values,
      lotIdsToDelete
    };

    return onSubmit(payload);
  };

  return (
    <Form
      enableReinitialize={true}
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {({ values: { lots } }: FormInstance<InitialValues>) => (
        <>
          <FormArray addButtonText="Add new lot" empty={emptyLineItem} path="lots" values={lots}>
            {({ value: lot, index, remove, getFieldName }: FormArrayHelpers<LotInput>) => {
              const percentage = calculateFulfilledPercentage(lot.quantityFulfilled, quantityOrdered);
              const lastItem = lots.length === 1;

              return (
                <LotItem getFieldName={getFieldName} index={index} lastItem={lastItem} percentage={percentage} remove={remove} />
              );
            }}
          </FormArray>
          <Summary lots={lots} productPrice={productPrice} quantityOrdered={quantityOrdered} />
        </>
      )}
    </Form>
  );
};

export default LotForm;
