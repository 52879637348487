import type { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import useRoutePermissions from '@zen/Auth/RoutePermissions/useRoutePermissions';

import AccessDenied from '../AccessDenied';

interface Props {
  children: ReactElement;
  path: string;
  redirectUrl?: string;
}

const RouteAuthoriser: FC<Props> = ({ redirectUrl, children, path }) => {
  const { canAccessRoute } = useRoutePermissions();

  if (canAccessRoute(path)) {
    return children;
  }

  if (redirectUrl) {
    return <Navigate replace={true} to={redirectUrl} />;
  }

  return <AccessDenied />;
};

export default RouteAuthoriser;
