import type { RateCardCharge } from '../reducer';
import type { RateCardChargeItemTypes, RateCardChargeItemTypesKeys } from '../reducer/types';
import type { FormattedRateCardDetails, RateCard } from './types';
import { ChargeClassification } from './types';

const mapRateCardToIncludeChargesGroups = (charges: RateCardCharge[]): RateCardChargeItemTypes => {
  const chargeGroupsMapping: Record<RateCardChargeItemTypesKeys, RateCardCharge[]> = {
    freightCharges: [],
    originHaulageCharges: [],
    originCharges: [],
    destinationCharges: [],
    destinationHaulageCharges: [],
    otherCharges: []
  };

  const classificationToChargeListMapping: Record<ChargeClassification, RateCardCharge[]> = {
    [ChargeClassification.FREIGHT]: chargeGroupsMapping.freightCharges,
    [ChargeClassification.ORIGIN_HAULAGE]: chargeGroupsMapping.originHaulageCharges,
    [ChargeClassification.ORIGIN_PORT]: chargeGroupsMapping.originCharges,
    [ChargeClassification.ORIGIN]: chargeGroupsMapping.originCharges,
    [ChargeClassification.DESTINATION]: chargeGroupsMapping.destinationCharges,
    [ChargeClassification.DESTINATION_PORT]: chargeGroupsMapping.destinationCharges,
    [ChargeClassification.DESTINATION_HAULAGE]: chargeGroupsMapping.destinationHaulageCharges,
    [ChargeClassification.OTHER]: chargeGroupsMapping.otherCharges
  };

  charges.forEach((charge: RateCardCharge) => {
    const { chargeClassification } = charge;

    if (chargeClassification) {
      classificationToChargeListMapping[chargeClassification as ChargeClassification].push(charge);
    }
  });

  return chargeGroupsMapping;
};

export const updateRateCardsChargePricing = (charge: RateCardCharge, isEditable: boolean): RateCardCharge => {
  const { currency: customCurrency, defaultCharge, unitPrice: customUnitPrice } = charge;
  const defaultUnitPrice: number | undefined = !isEditable && defaultCharge?.unitPrice ? defaultCharge.unitPrice : undefined;

  return {
    ...charge,
    currency: customCurrency !== defaultCharge?.currency ? customCurrency : defaultCharge?.currency,
    unitPrice: customUnitPrice !== defaultCharge?.unitPrice ? customUnitPrice : defaultUnitPrice
  };
};

const updateRateCardsChargesPricing = (charges: RateCardCharge[], isEditable: boolean): RateCardCharge[] => {
  return charges.map((charge: RateCardCharge) => updateRateCardsChargePricing(charge, isEditable));
};

export const formatRateCardData = (rateCard: RateCard, isEditable: boolean = true): FormattedRateCardDetails => {
  const { charges, ...rest } = rateCard;

  const formattedRateCardCharges: RateCardCharge[] = updateRateCardsChargesPricing(charges, isEditable);
  const rateCardWithChargesGroups: RateCardChargeItemTypes = mapRateCardToIncludeChargesGroups(formattedRateCardCharges);

  return {
    ...rest,
    ...rateCardWithChargesGroups
  };
};
