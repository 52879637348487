import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import type { SegmentedControlOption } from '@zen/DesignSystem';
import { SegmentedControl } from '@zen/DesignSystem';
import type { JourneyShippingMilestone } from '@zen/graphql/types.generated';
import type { BusinessHours } from '@zen/Networks';
import { getToday } from '@zen/utils/date';
import type { Nullable } from '@zen/utils/typescript';

import type { JourneyStopActionEnum } from '../../types';
import { MilestoneDateType } from '../../types';
import ActualDateForm from '../forms/ActualDateForm';
import NoDateForm from '../forms/NoDateForm';
import PlannedDateForm from '../forms/PlannedDateForm';
import RequestedDateForm from '../forms/RequestedDateForm';
import { useJourneyContext } from '../JourneyDetails/JourneyDetailsContext';
import { getMilestoneLabel } from '../MilestoneName/helpers';
import { getActiveOption, getOptions, prepareActualDateInitialValues, prepareEstimatedDateInitialValues } from './helpers';

interface Props {
  businessHours: BusinessHours;
  milestone: JourneyShippingMilestone;
  onClose: () => void;
  stopAction: JourneyStopActionEnum;
  timeZone?: Nullable<string>;
}

const MilestoneDatesDialog: FC<Props> = ({ businessHours, milestone, onClose, stopAction, timeZone }) => {
  const { milestones, modeOfTransport } = useJourneyContext();
  const options: SegmentedControlOption<MilestoneDateType>[] = getOptions(milestone, milestones, modeOfTransport);

  const [activeTab, setActiveTab] = useState<MilestoneDateType>(getActiveOption(options));

  const {
    actual,
    canRemoveActualDateCurrently,
    canRemovePlannedDateCurrently,
    canRemoveRequestedDateCurrently,
    canUpdateRequestedBy,
    completed,
    name,
    planned,
    requested,
    requestedBy,
    id
  } = milestone;

  const isHeaderVisible: boolean = options.length > 1;
  const today: string = getToday();
  const title: string = getMilestoneLabel({ completed, modeOfTransport, name, stopAction });

  const componentMapping: Record<MilestoneDateType, ReactNode> = {
    [MilestoneDateType.DRAFT]: <NoDateForm />,
    [MilestoneDateType.REQUESTED]: (
      <RequestedDateForm
        businessHours={businessHours}
        canRemoveRequestedDate={!!canRemoveRequestedDateCurrently?.value}
        canUpdateRequestedBy={!!canUpdateRequestedBy}
        initialValues={{ ...prepareEstimatedDateInitialValues(requested), requestedBy }}
        milestoneId={id}
        milestoneName={name}
        onSuccess={onClose}
        timeZone={timeZone}
        title={title}
      />
    ),
    [MilestoneDateType.PLANNED]: (
      <PlannedDateForm
        businessHours={businessHours}
        canRemovePlannedDateCurrently={!!canRemovePlannedDateCurrently?.value}
        hasPlannedDate={!!planned?.startDateTime?.date}
        initialValues={prepareEstimatedDateInitialValues(planned, requested)}
        milestoneId={id}
        milestoneName={name}
        onSuccess={onClose}
        timeZone={timeZone}
        title={title}
      />
    ),
    [MilestoneDateType.ACTUAL]: (
      <ActualDateForm
        canRemoveActualDateCurrently={canRemoveActualDateCurrently}
        hasActualDate={!!actual?.date}
        initialValues={prepareActualDateInitialValues(actual, planned?.startDateTime?.date, today)}
        milestoneId={id}
        onSuccess={onClose}
        timeZone={timeZone}
        title={title}
      />
    )
  };

  return (
    <div className="border border-solid border-grey-lighter rounded w-[40rem]">
      {isHeaderVisible && (
        <div className="flex justify-center px-6 py-2 border-b border-solid border-grey-lighter">
          <SegmentedControl onChange={setActiveTab} options={options} value={activeTab} />
        </div>
      )}
      <div className="text-left">{componentMapping[activeTab]}</div>
    </div>
  );
};

export default MilestoneDatesDialog;
