import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type RateCardForBookingQueryVariables = GraphQLTypes.Exact<{
  cargoType: GraphQLTypes.CargoModeEnum;
  customerId: GraphQLTypes.Scalars['String'];
  destinationLocationId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  originLocationId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  modeOfTransport: GraphQLTypes.ModeOfTransport;
  validOn?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Date']>;
}>;

export type RateCardForBookingQueryResult = { __typename?: 'Query' } & {
  getRateCards?: GraphQLTypes.Maybe<
    { __typename?: 'RateCardSummaryConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<GraphQLTypes.Maybe<{ __typename?: 'RateCardSummary' } & Pick<GraphQLTypes.RateCardSummary, 'name' | 'rateCardId'>>>
      >;
    }
  >;
};

export const RateCardForBookingDocument = /* #__PURE__ */ gql`
  query rateCardForBooking(
    $cargoType: CargoModeEnum!
    $customerId: String!
    $destinationLocationId: String
    $originLocationId: String
    $modeOfTransport: ModeOfTransport!
    $validOn: Date
  ) {
    getRateCards(
      cargoType: $cargoType
      customerId: $customerId
      destinationLocationId: $destinationLocationId
      originLocationId: $originLocationId
      modeOfTransport: $modeOfTransport
      validOn: $validOn
    ) {
      nodes {
        name
        rateCardId
      }
    }
  }
`;

/**
 * __useRateCardForBookingQuery__
 *
 * To run a query within a React component, call `useRateCardForBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateCardForBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateCardForBookingQuery({
 *   variables: {
 *      cargoType: // value for 'cargoType'
 *      customerId: // value for 'customerId'
 *      destinationLocationId: // value for 'destinationLocationId'
 *      originLocationId: // value for 'originLocationId'
 *      modeOfTransport: // value for 'modeOfTransport'
 *      validOn: // value for 'validOn'
 *   },
 * });
 */
export function useRateCardForBookingQuery(
  baseOptions: Apollo.QueryHookOptions<RateCardForBookingQueryResult, RateCardForBookingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RateCardForBookingQueryResult, RateCardForBookingQueryVariables>(RateCardForBookingDocument, options);
}
export function useRateCardForBookingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RateCardForBookingQueryResult, RateCardForBookingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RateCardForBookingQueryResult, RateCardForBookingQueryVariables>(
    RateCardForBookingDocument,
    options
  );
}
export type RateCardForBookingQueryHookResult = ReturnType<typeof useRateCardForBookingQuery>;
export type RateCardForBookingLazyQueryHookResult = ReturnType<typeof useRateCardForBookingLazyQuery>;
