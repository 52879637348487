import type { FC, ReactNode } from 'react';

import type { FormInstance } from '@zen/Components';
import { Form, FormButtons, FormInput } from '@zen/Components';
import { Banner, Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { InviteAccountConnectionInput } from '../../graphql';
import { useCheckForDuplicateAccountsLazyQuery } from '../../graphql';
import { inviteCompanyConnectionValidation } from './inviteCompanyConnectionForm.validation';
import type { DuplicatedAccount, InviteConnectionFormValues } from './types';

interface Props {
  accountId: string;
  onCancel: () => void;
  onCompanyMatch: ({ companyName, email, duplicates }: InviteConnectionFormValues & { duplicates: DuplicatedAccount[] }) => void;
  onSubmit: (values: InviteConnectionFormValues) => Promise<IOkOrErrorResult>;
}

const InviteCompanyConnectionForm: FC<Props> = ({ accountId, onCancel, onCompanyMatch, onSubmit }) => {
  const [checkForDuplicates] = useCheckForDuplicateAccountsLazyQuery({ fetchPolicy: 'no-cache' });

  const handleCheckForDuplicates = async ({ companyName, email }: InviteConnectionFormValues): Promise<IOkOrErrorResult> => {
    const { data } = await checkForDuplicates({
      variables: {
        input: {
          accountId,
          accountTradingName: companyName,
          contactEmail: email
        }
      }
    });

    const duplicates: DuplicatedAccount[] = data?.checkForDuplicateAccounts?.possibleDuplicates || [];

    if (duplicates.length > 0) {
      onCompanyMatch({ companyName, email, duplicates });

      return Promise.resolve({ ok: true, error: null });
    }

    return onSubmit({ companyName, email });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<InviteAccountConnectionInput>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Add connection">
      <Button data-testid="cancel-button" onClick={onCancel} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={{ companyName: '', email: '' }}
      onSubmit={handleCheckForDuplicates}
      validationSchema={inviteCompanyConnectionValidation}
    >
      {() => {
        return (
          <>
            <FormInput label="Company name" name="companyName" />
            <FormInput
              helperText="Ensure the recipient is able to set up the account on behalf of their company."
              label="Email"
              name="email"
            />
            <Banner message="Connections you invite will be listed as 'Supplier' in your connections. Once they accept and activate their account, they will see you as their 'Customer'." />
          </>
        );
      }}
    </Form>
  );
};

export default InviteCompanyConnectionForm;
export type { Props as InviteCompanyConnectionFormProps };
