import type { FC, ReactNode } from 'react';

import type { ModeOfTransport } from '@zen/Booking';
import { isAirBooking, isOceanBooking, isRailBooking } from '@zen/Booking';
import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import type { FormInstance } from '@zen/Components';
import { Form, FormArray, FormButtons, FormDatePickerInput, FormInput } from '@zen/Components';
import type { FormFieldTooltipConfig } from '@zen/Components/Form/FormField';
import FormTerminalsSelect from '@zen/Components/Form/FormTerminalsSelect';
import { Banner, Button, Icon } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Optional } from '@zen/utils/typescript';

import type { MilestoneDisabledDateProps } from '../../JourneyDetails/types';
import VerticalPathProvider from '../../VerticalPathProvider';
import AirScheduleFormHeader from '../AirScheduleFormHeader';
import FormOceanCarrierSelect from '../FormOceanCarrierSelect';
import FormRailwayCompanySelect from '../FormRailwayCompanySelect';
import {
  createEmptyStop,
  getArrivalDateLabel,
  getCarrierLabel,
  getDepartureDateLabel,
  getDisabledDatesAcrossBooking,
  getDisabledDatesForArrivalDate,
  getDisabledDatesForDepartureDate,
  getLocationLabel
} from './helpers';
import { getValidationSchema } from './plannedScheduleForm.validation';
import type { PlannedScheduleValues, Stop } from './types';
import { AirScheduleCarrier } from './types';

interface Props {
  arrivalMilestoneId: string;
  departureMilestoneId: string;
  initialValues: PlannedScheduleValues;
  isAirScheduleDisabled?: boolean;
  modeOfTransport: Optional<ModeOfTransport>;
  onCancel: () => void;
  onSubmit: (values: PlannedScheduleValues) => Promise<IOkOrErrorResult>;
}

const PlannedScheduleForm: FC<Props> = (props) => {
  const {
    arrivalMilestoneId,
    departureMilestoneId,
    initialValues,
    isAirScheduleDisabled = false,
    modeOfTransport,
    onCancel,
    onSubmit
  } = props;

  const { cargoMilestones, cargoPreLegsMilestones, cargoReadyDate } = useCargoOverviewContext();

  const disabledDatesOutsideSchedule: MilestoneDisabledDateProps = getDisabledDatesAcrossBooking({
    arrivalMilestoneId,
    cargoMilestones,
    cargoPreLegsMilestones,
    cargoReadyDate,
    departureMilestoneId,
    modeOfTransport
  });

  const renderFormButtons = ({ isSubmitting }: FormInstance<PlannedScheduleValues>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Submit">
      <Button onClick={onCancel} variant="secondary">
        Cancel
      </Button>
    </FormButtons>
  );

  const renderCarrierField = (): ReactNode => {
    const label: string = getCarrierLabel(modeOfTransport);
    const fieldName: string = 'carrier';

    if (isOceanBooking(modeOfTransport)) {
      return <FormOceanCarrierSelect label={label} name={fieldName} />;
    }

    if (isRailBooking(modeOfTransport)) {
      return <FormRailwayCompanySelect label={label} name={fieldName} />;
    }

    if (isAirBooking(modeOfTransport)) {
      return <AirScheduleFormHeader isAirScheduleDisabled={isAirScheduleDisabled} />;
    }
  };

  const getTooltipConfig = (isDisabled: Optional<boolean>): FormFieldTooltipConfig | undefined => {
    if (!isDisabled) return;

    return { content: 'Please enter this field in Route and cargo details.' };
  };

  return (
    <Form
      enableReinitialize={true}
      formButtons={renderFormButtons}
      formName="PlannedScheduleForm"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(modeOfTransport)}
    >
      {({ values }: FormInstance<PlannedScheduleValues>) => {
        return (
          <VerticalPathProvider dependencies={[values]}>
            <div data-testid="planned-schedule-form">
              <div className="mb-4">
                <Banner message="This will update all cargo in the booking and add delays to updated dates." />
              </div>
              {renderCarrierField()}
              <FormArray
                className="mb-4 last:mb-0"
                empty={createEmptyStop()}
                hideAddButton={true}
                path="stops"
                values={values.stops}
              >
                {({ getFieldName, index, insert, remove }) => {
                  const isFirstStop: boolean = index === 0;
                  const isLastStop: boolean = values.stops.length - 1 === index;
                  const currentStop: Stop = values.stops[index];
                  const nextStop: Stop = values.stops[index + 1];
                  const departureDateDisabledDates: MilestoneDisabledDateProps = getDisabledDatesForDepartureDate(
                    values.stops,
                    index,
                    disabledDatesOutsideSchedule
                  );
                  const arrivalDateDisabledDates: MilestoneDisabledDateProps = getDisabledDatesForArrivalDate(
                    values.stops,
                    index,
                    disabledDatesOutsideSchedule
                  );
                  const flightNumberClassNames: string | undefined = isFirstStop ? 'grid gap-4 grid-cols-2' : undefined;
                  const isTerminalDisabled: boolean = !!(currentStop.arrivalDateDisabled || currentStop.departureDateDisabled);

                  return (
                    <div key={index} className="flex w-full space-x-4">
                      <div>
                        <Icon
                          className="relative z-10 inline-block py-1 bg-white text-grey-light"
                          data-routedatapoint={true}
                          icon="zicon-pin"
                        />
                      </div>
                      <div className="flex-1">
                        <FormTerminalsSelect
                          isClearable={true}
                          isDisabled={isTerminalDisabled}
                          label={getLocationLabel(isFirstStop, isLastStop, modeOfTransport)}
                          name={getFieldName('location')}
                          renderMenuInPortal={true}
                          tooltipConfig={getTooltipConfig(isTerminalDisabled)}
                        />
                        <div className="grid grid-cols-2 gap-4">
                          {!isFirstStop && (
                            <FormDatePickerInput
                              disabled={currentStop.arrivalDateDisabled}
                              label={getArrivalDateLabel(isLastStop, modeOfTransport)}
                              name={getFieldName('arrivalDate')}
                              noMargin={!!isLastStop}
                              renderInPortal={true}
                              tooltipConfig={getTooltipConfig(currentStop.arrivalDateDisabled)}
                              {...arrivalDateDisabledDates}
                            />
                          )}
                          {!isLastStop && (
                            <FormDatePickerInput
                              disabled={currentStop.departureDateDisabled}
                              label={getDepartureDateLabel(isFirstStop, modeOfTransport)}
                              name={getFieldName('departureDate')}
                              renderInPortal={true}
                              tooltipConfig={getTooltipConfig(currentStop.departureDateDisabled)}
                              {...departureDateDisabledDates}
                            />
                          )}
                        </div>
                        {!isLastStop && (
                          <>
                            {isOceanBooking(modeOfTransport) && (
                              <div className="grid grid-cols-2 gap-4">
                                <FormInput className="flex-1" label="Vessel name" name={getFieldName('vesselName')} />
                                <FormInput className="flex-1" label="Voyage number" name={getFieldName('voyageNumber')} />
                              </div>
                            )}
                            {isAirBooking(modeOfTransport) && values.airScheduleCarrierType !== AirScheduleCarrier.COURIER && (
                              <div className={flightNumberClassNames}>
                                <FormInput className="flex-1" label="Flight number" name={getFieldName('flightNumber')} />
                              </div>
                            )}
                          </>
                        )}
                        <div className="flex justify-between">
                          {!isLastStop && (
                            <Button
                              disabled={nextStop && nextStop.arrivalDateDisabled}
                              iconLeft="zicon-add"
                              onClick={insert}
                              size="compact"
                              variant="secondary"
                            >
                              Add stop
                            </Button>
                          )}
                          {!isFirstStop && !isLastStop && (
                            <Button
                              disabled={currentStop.departureDateDisabled || currentStop.arrivalDateDisabled}
                              iconLeft="zicon-trash"
                              onClick={remove}
                              size="compact"
                              variant="secondary"
                            >
                              Delete stop
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }}
              </FormArray>
            </div>
          </VerticalPathProvider>
        );
      }}
    </Form>
  );
};

export default PlannedScheduleForm;
