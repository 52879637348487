import type { FC, ReactNode } from 'react';
import { useContext } from 'react';

import DetailsInfoHeader from '@zen/Components/DetailsInfoHeader';
import ForwarderInfoHeader from '@zen/Components/ForwarderInfoHeader';
import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import type { OrderListViewLot } from '@zen/Orders/types';
import { formatDate } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

import LotLineItem from './LotLineItem';

interface Props {
  canViewForwarder: boolean;
  orderLots: OrderListViewLot[];
}

const OrderListItemDetails: FC<Props> = ({ canViewForwarder, orderLots }) => {
  const { purchaseOrder } = orderLots[0];
  const lastUpdatedDate: Optional<string> = purchaseOrder?.lastUpdatedAt?.date;
  const { isBasketMode } = useContext(BasketContext);

  return (
    <div className="bg-white" data-testid="order-list-item-details">
      {canViewForwarder && (
        <DetailsInfoHeader className="pl-16">
          <ForwarderInfoHeader forwarderName={purchaseOrder?.forwarder?.label?.short} />
        </DetailsInfoHeader>
      )}

      <div className="my-4 pt-6 pl-4 pr-1 bg-grey-lightest">
        <div className="flex font-bold text-sm mb-1 text-grey-base">
          {isBasketMode && <div className="w-16" />}
          <div className="w-32">Qty / CBM</div>
          <div className="w-1/2 grow-0">Product / SKU</div>
          <div className="w-1/5 grow-0">Booking status</div>
          <div className="w-1/5 grow-0">Order status</div>
          <div className="w-1/5 grow-0">Delivery</div>
          <div className="w-12" />
        </div>
        {orderLots.map(
          (lot: OrderListViewLot): ReactNode => (
            <LotLineItem key={lot.id} lot={lot} />
          )
        )}
      </div>
      {lastUpdatedDate && (
        <div className="text-right text-grey-dark text-xs pr-4 pb-4" data-testid="last-updated-date">
          Last updated {formatDate(lastUpdatedDate)}
        </div>
      )}
    </div>
  );
};

export default OrderListItemDetails;
