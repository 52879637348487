import { getTrackingParentAttributeValue, getTrackingParents, PARENT_ATTRIBUTE } from './parent';

const LABEL_ATTRIBUTE = 'data-tracking-label';
const LABEL_ATTRIBUTE_NO_CONTENT = 'data-tracking-label-no-content';

const isTrackingLabel = (target: EventTarget): target is HTMLElement => {
  if (target instanceof Document || target instanceof Window) {
    return false;
  }

  const element = target as HTMLElement;

  return element.hasAttribute(LABEL_ATTRIBUTE);
};

const isTrackingLabelWithNoContent = (element: HTMLElement): boolean => {
  return isTrackingLabel(element) && element.hasAttribute(LABEL_ATTRIBUTE_NO_CONTENT);
};

const findTrackingLabel = (parentEl: Element): HTMLElement | null => {
  if (!parentEl) return null;

  const parentType: string = getTrackingParentAttributeValue(parentEl);
  let labelEl: HTMLElement | null;

  // find a first child that is a tracking-label, but:
  // - ignore ones that are children of a different nested tracking parent
  // - ignore ones that are nested parent and label at the same time (effectively a label for a nested parent))
  try {
    labelEl = parentEl.querySelector(
      `[${LABEL_ATTRIBUTE}]` +
        `:not([${PARENT_ATTRIBUTE}=${parentType}] [${PARENT_ATTRIBUTE}] [${LABEL_ATTRIBUTE}])` +
        `:not([${PARENT_ATTRIBUTE}=${parentType}] [${PARENT_ATTRIBUTE}][${LABEL_ATTRIBUTE}])`
    );
  } catch {
    labelEl = null;
  }

  // check if parent is not a label itself
  if (!labelEl && isTrackingLabel(parentEl)) {
    return parentEl;
  }

  return labelEl;
};

const getLabelAttributeValue = (labelEl: Element): string => {
  const attrValue: string | null = labelEl.getAttribute(LABEL_ATTRIBUTE);

  if (!attrValue) {
    return '';
  }

  return attrValue !== 'true' ? attrValue : '';
};

const getTrackingLabelValue = (labelEl: HTMLElement | null): string => {
  if (!labelEl) {
    return '';
  }

  let label;

  // if label was marked explicitly as one without content, we will not use its innerText
  if (!isTrackingLabelWithNoContent(labelEl)) {
    label = labelEl.innerText;
  }

  return label || getLabelAttributeValue(labelEl);
};

export const createTrackingLabel = (nativeEvent: Event, includeParentType: boolean = false): string => {
  return getTrackingParents(nativeEvent)
    .map((parentEl: Element) => {
      const labels: string[] = [];
      const labelEl: HTMLElement | null = findTrackingLabel(parentEl);
      const labelText: string = getTrackingLabelValue(labelEl);

      if (includeParentType) {
        labels.push(getTrackingParentAttributeValue(parentEl));
      }
      if (labelText) {
        labels.push(labelText);
      }

      return labels.join(':');
    })
    .filter(Boolean)
    .reverse()
    .join('|');
};

export const createTrackingLabelAttribute = (
  label?: string,
  options?: { ignoreContent: boolean }
): {
  [key: string]: boolean | string;
} => {
  const attributes: {
    [key: string]: boolean | string;
  } = {};

  attributes[`${LABEL_ATTRIBUTE}`] = label || true;
  if (options?.ignoreContent) {
    attributes[`${LABEL_ATTRIBUTE_NO_CONTENT}`] = true;
  }

  return attributes;
};
