import type { FC } from 'react';
import { useEffect, useReducer, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import NavigationPrompt from '@zen/Components/NavigationPrompt';
import type { DateRange } from '@zen/DesignSystem';
import { Button } from '@zen/DesignSystem';
import RateCardNotes from '@zen/RateCards/RateCardNotes';
import TermsAndConditionsLink from '@zen/RateCards/TermsAndConditionsLink';
import useAccount from '@zen/utils/hooks/useAccount';

import { prepareFreightPorts } from '../helpers';
import FreightChargeSection from './FreightChargeSection';
import { useValidityDatesQuery } from './graphql';
import HaulageChargeSection from './HaulageSection/HaulageSection';
import { getNextAvailableDateRange, prepareInitialState, preparePortChargeFilters, shouldAddCharges } from './helpers';
import PortChargeSection from './PortChargeSection';
import RateCardOverview from './RateCardOverview';
import { rateCardReducer } from './reducer';
import type {
  FreightChargePayload,
  HaulageChargePayload,
  PortChargePayload,
  RateCardInputUpdatePayload,
  RateCardReducerState
} from './reducer/types';
import { ActionType } from './reducer/types';

interface Props {
  initialValues?: RateCardReducerState;
  onSubmit: (values: RateCardReducerState) => void;
}

const RateCardForm: FC<Props> = ({ onSubmit, initialValues }) => {
  const { accountUuid, userProfile } = useAccount();
  const { uuid: currentUserId } = userProfile;
  const [shouldPromptOnLeave, setShouldPromptOnLeave] = useState<boolean>(true);
  const initialState: RateCardReducerState = prepareInitialState(currentUserId, initialValues);
  const [state, dispatch] = useReducer(rateCardReducer, initialState);
  const { originPortChargeFilters, destinationPortChargeFilters } = preparePortChargeFilters(state.freightCharges);
  const { data } = useValidityDatesQuery({
    variables: { customerDivisionId: accountUuid, modeOfTransport: state.modeOfTransport, cargoType: state.cargoType },
    fetchPolicy: 'no-cache'
  });

  const prepareExistingValidityDates = (): DateRange[] => {
    const existingRateCardValidityDates: DateRange[] = data?.legacyGetValidityDates?.validityDates || [];

    if (initialValues) {
      return existingRateCardValidityDates.filter(
        ({ endDate, startDate }: DateRange) => endDate !== initialValues.endDate && startDate !== initialValues.startDate
      );
    }

    return existingRateCardValidityDates;
  };

  const existingDateRanges: DateRange[] = prepareExistingValidityDates();

  useEffect(() => {
    if (!state.startDate && !state.endDate) {
      const { startDate, endDate }: DateRange = getNextAvailableDateRange(existingDateRanges);

      dispatch({ type: ActionType.UPDATE_RATE_CARD_INPUT, payload: { startDate, endDate } });
    }
  }, [data, existingDateRanges, state.endDate, state.startDate]);

  useDeepCompareEffect(() => {
    dispatch({ type: ActionType.REINITIALIZE, payload: initialState });
  }, [initialValues]);

  const handleSubmit = async (): Promise<void> => {
    await setShouldPromptOnLeave(false);

    onSubmit(state);
  };

  const handleOverviewChange = (payload: RateCardInputUpdatePayload): void => {
    dispatch({ type: ActionType.UPDATE_RATE_CARD_INPUT, payload });
  };

  const handleAddFreightCharge = (values: FreightChargePayload): void => {
    dispatch({ type: ActionType.ADD_FREIGHT_CHARGE, payload: values });
  };

  const handleDeleteFreightCharge = (index: number): void => {
    dispatch({ type: ActionType.DELETE_FREIGHT_CHARGE, payload: { atIndex: index } });
  };

  const handleUpdateFreightCharge = (index: number, value: FreightChargePayload): void => {
    dispatch({ type: ActionType.UPDATE_FREIGHT_CHARGE, payload: { atIndex: index, value } });
  };

  const handleTermsAndConditionsChange = (value: string): void => {
    dispatch({ type: ActionType.UPDATE_RATE_CARD_INPUT, payload: { note: value } });
  };

  const handleAddOriginHaulageCharge = (value: HaulageChargePayload): void => {
    dispatch({ type: ActionType.ADD_ORIGIN_HAULAGE_CHARGE, payload: value });
  };

  const handleUpdateOriginHaulageCharge = (index: number, value: HaulageChargePayload): void => {
    dispatch({ type: ActionType.UPDATE_ORIGIN_HAULAGE_CHARGE, payload: { atIndex: index, value } });
  };

  const handleDeleteOriginHaulageCharge = (index: number): void => {
    dispatch({ type: ActionType.DELETE_ORIGIN_HAULAGE_CHARGE, payload: { atIndex: index } });
  };

  const handleAddDestinationHaulageCharge = (value: HaulageChargePayload): void => {
    dispatch({ type: ActionType.ADD_DESTINATION_HAULAGE_CHARGE, payload: value });
  };

  const handleUpdateDestinationHaulageCharge = (index: number, value: HaulageChargePayload): void => {
    dispatch({ type: ActionType.UPDATE_DESTINATION_HALUAGE_CHARGE, payload: { atIndex: index, value } });
  };

  const handleDeleteDestinationHaulageCharge = (index: number): void => {
    dispatch({ type: ActionType.DELETE_DESTINATION_HALUAGE_CHARGE, payload: { atIndex: index } });
  };

  const handleAddOriginCharges = (values: PortChargePayload[]): void => {
    dispatch({ type: ActionType.ADD_ORIGIN_CHARGES, payload: values });
  };

  const handleAddDestinationCharges = (values: PortChargePayload[]): void => {
    dispatch({ type: ActionType.ADD_DESTINATION_CHARGES, payload: values });
  };

  const handleAddCustomOriginCharge = (value: PortChargePayload): void => {
    dispatch({ type: ActionType.ADD_CUSTOM_ORIGIN_CHARGE, payload: value });
  };

  const handleAddCustomDestinationCharges = (value: PortChargePayload): void => {
    dispatch({ type: ActionType.ADD_CUSTOM_DESTINATION_CHARGE, payload: value });
  };

  const disableOriginCharge = (id: string): void => {
    dispatch({ type: ActionType.DISABLE_ORIGIN_CHARGE, payload: { id } });
  };

  const enableOriginCharge = (id: string) => {
    dispatch({ type: ActionType.ENABLE_ORIGIN_CHARGE, payload: { id } });
  };

  const handleDisableOriginCharge = (id: string, disable: boolean) => {
    return disable ? disableOriginCharge(id) : enableOriginCharge(id);
  };

  const disableDestinationCharge = (id: string): void => {
    dispatch({ type: ActionType.DISABLE_DESTINATION_CHARGE, payload: { id } });
  };

  const enableDestinationCharge = (id: string): void => {
    dispatch({ type: ActionType.ENABLE_DESTINATION_CHARGE, payload: { id } });
  };

  const handleDisableDestinationCharge = (id: string, disable: boolean) => {
    return disable ? disableDestinationCharge(id) : enableDestinationCharge(id);
  };

  const handleUpdateOriginPortCharge = (index: number, value: PortChargePayload): void => {
    dispatch({ type: ActionType.UPDATE_ORIGIN_PORT_CHARGE, payload: { atIndex: index, value } });
  };

  const handleUpdateDestinationPortCharge = (index: number, value: PortChargePayload): void => {
    dispatch({ type: ActionType.UPDATE_DESTINATION_PORT_CHARGE, payload: { atIndex: index, value } });
  };

  const handleDeleteCustomOriginCharge = (index: number): void => {
    dispatch({ type: ActionType.DELETE_CUSTOM_ORIGIN_CHARGE, payload: { atIndex: index } });
  };

  const handleDeleteCustomDestinationCharge = (index: number) => {
    dispatch({ type: ActionType.DELETE_CUSTOM_DESTINATION_CHARGE, payload: { atIndex: index } });
  };

  const handleRemoveCustomDestinationPortPrice = (index: number): void => {
    dispatch({ type: ActionType.REMOVE_CUSTOM_DESTINATION_PORT_CHARGE_PRICE, payload: { atIndex: index } });
  };

  const handleRemoveCustomOriginPortPrice = (index: number) => {
    dispatch({ type: ActionType.REMOVE_CUSTOM_ORIGIN_PORT_CHARGE_PRICE, payload: { atIndex: index } });
  };

  const hasOriginHaulageCharge: boolean = state.originHaulageCharges.length > 0;
  const hasDestinationHaulageCharge: boolean = state.destinationHaulageCharges.length > 0;
  const shouldAddOriginCharges: boolean = shouldAddCharges(state.freightCharges, 'origin');
  const shouldAddDestinationCharges: boolean = shouldAddCharges(state.freightCharges, 'destination');

  const shouldDisplayOriginHaulageSection: boolean = shouldAddOriginCharges || hasOriginHaulageCharge;
  const shouldDisplayDestinationHaulageSection: boolean = shouldAddDestinationCharges || hasDestinationHaulageCharge;

  const { originPorts, destinationPorts } = prepareFreightPorts(state.freightCharges);

  return (
    <div className="space-y-6">
      <RateCardOverview
        disabledDateRanges={prepareExistingValidityDates()}
        endDate={state.endDate}
        issuedBy={state.issuedBy}
        onChange={handleOverviewChange}
        startDate={state.startDate}
      />
      <FreightChargeSection
        freightCharges={state.freightCharges}
        onAdd={handleAddFreightCharge}
        onDelete={handleDeleteFreightCharge}
        onUpdate={handleUpdateFreightCharge}
      />
      {shouldDisplayOriginHaulageSection && (
        <HaulageChargeSection
          haulageCharges={state.originHaulageCharges}
          onAdd={handleAddOriginHaulageCharge}
          onDelete={handleDeleteOriginHaulageCharge}
          onUpdate={handleUpdateOriginHaulageCharge}
          ports={originPorts}
          type="origin"
        />
      )}
      {shouldAddOriginCharges && (
        <PortChargeSection
          filters={originPortChargeFilters}
          onAddCustomPortCharge={handleAddCustomOriginCharge}
          onAddPortCharges={handleAddOriginCharges}
          onDelete={handleDeleteCustomOriginCharge}
          onDisable={handleDisableOriginCharge}
          onRemoveCustomPrice={handleRemoveCustomOriginPortPrice}
          onUpdate={handleUpdateOriginPortCharge}
          portCharges={state.originCharges}
          ports={originPorts}
          title="Origin charges"
          type="origin"
        />
      )}
      {shouldAddDestinationCharges && (
        <PortChargeSection
          filters={destinationPortChargeFilters}
          onAddCustomPortCharge={handleAddCustomDestinationCharges}
          onAddPortCharges={handleAddDestinationCharges}
          onDelete={handleDeleteCustomDestinationCharge}
          onDisable={handleDisableDestinationCharge}
          onRemoveCustomPrice={handleRemoveCustomDestinationPortPrice}
          onUpdate={handleUpdateDestinationPortCharge}
          portCharges={state.destinationCharges}
          ports={destinationPorts}
          title="Destination charges"
          type="destination"
        />
      )}
      {shouldDisplayDestinationHaulageSection && (
        <HaulageChargeSection
          haulageCharges={state.destinationHaulageCharges}
          onAdd={handleAddDestinationHaulageCharge}
          onDelete={handleDeleteDestinationHaulageCharge}
          onUpdate={handleUpdateDestinationHaulageCharge}
          ports={destinationPorts}
          type="destination"
        />
      )}
      <RateCardNotes editable={true} onChange={handleTermsAndConditionsChange} value={state.note || ''} />
      <div className="flex items-center justify-between">
        <Button onClick={handleSubmit}>Submit</Button>
        <TermsAndConditionsLink />
      </div>
      <NavigationPrompt
        confirmationNeeded={shouldPromptOnLeave}
        description="All unsaved changes will be lost."
        header="Leave page?"
        leaveLabel="Leave page"
        stayLabel="Stay"
      />
    </div>
  );
};

export default RateCardForm;
