import type { FC, ReactNode } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import Page from '@zen/Components/Page';
import { Button, Table } from '@zen/DesignSystem';
import { rateCardRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import useRateCards from './graphql/hooks/useRateCards';
import RateCardActions from './RateCardActions';
import { getRateCardsTableConfiguration } from './rateCardsTableConfiguration';
import type { RateCardsTableActions } from './types';

const RateCards: FC = () => {
  const { navigate } = useNavigationHistory();
  const { check } = useGlobalPermissions();
  const { accountUuid } = useAccount();
  const canCreateRateCards: boolean = check('accounts.canCreateRateCards');
  const { nodes, loading, paginationInfo, refetch, totalCount } = useRateCards({
    customerId: accountUuid
  });

  const handleNewRateCardClick = (): void => {
    navigate(rateCardRoutes.new.getUrl());
  };

  const newRateCardButton: ReactNode = (
    <Button onClick={handleNewRateCardClick} variant="secondary">
      New rate card
    </Button>
  );
  const actionButtons: ReactNode = canCreateRateCards ? newRateCardButton : null;

  return (
    <RateCardActions onDeleteCompleted={refetch}>
      {({ handleClone, handleDelete, handleEdit }) => {
        const canDeleteRateCards: boolean = check('accounts.canDeleteRateCards');
        const rateCardActions: RateCardsTableActions = {
          ...(canCreateRateCards ? { onClone: handleClone, onEdit: handleEdit } : {}),
          ...(canDeleteRateCards ? { onDelete: handleDelete } : {})
        };

        return (
          <Page actionButtons={actionButtons} title="Rate cards">
            <Table
              columns={getRateCardsTableConfiguration(rateCardActions)}
              data={nodes || []}
              loading={loading}
              paginationInfo={paginationInfo}
              tableId="rateCardsTable"
              totalCountConfig={{ entityName: 'rate card', totalCount }}
            />
          </Page>
        );
      }}
    </RateCardActions>
  );
};

export default RateCards;
