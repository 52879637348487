import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@zen/DesignSystem';

import { getOrdersUrl } from '../bookingButtonHelper';

const BookingRequestButton: FC = () => {
  const navigate = useNavigate();

  const handleClick = (): void => {
    navigate(getOrdersUrl());
  };

  return (
    <Button className="w-full outline-none" data-testid="request-booking-button" onClick={handleClick} variant="secondary">
      Request booking
    </Button>
  );
};

export default BookingRequestButton;
