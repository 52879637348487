import type { FC } from 'react';

import type { TableColumn } from '@zen/DesignSystem';
import { Table } from '@zen/DesignSystem';
import { createRateCardTableId } from '@zen/RateCards/LegacyRateCards/helpers';
import type { HaulageChargePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';
import ChargeTableActions from '@zen/RateCards/RateCardForm/components/ChargeTableActions';

import { getHaulageChargeTableColumns } from './haulageChargeTableConfig';
import { getHaulageTableColumnTitleConfig } from './helpers';
import type { HaulageTableColumnTitleConfig } from './types';

interface Props {
  editable: boolean;
  haulageCharges: HaulageChargePayload[];
  onAdd?: () => void;
  onDelete?: (index: number) => void;
  onUpdate?: (index: number, payload: HaulageChargePayload) => void;
  title: string;
  type: 'origin' | 'destination';
}

const HaulageChargeTable: FC<Props> = ({ editable, onDelete, onUpdate, haulageCharges, onAdd, title, type }) => {
  const columnTitles: HaulageTableColumnTitleConfig = getHaulageTableColumnTitleConfig(type);

  const columns: TableColumn<HaulageChargePayload>[] = getHaulageChargeTableColumns(
    columnTitles,
    { onDelete, onUpdate },
    editable
  );

  return (
    <Table
      actions={editable && <ChargeTableActions onAdd={onAdd} />}
      columns={columns}
      data={haulageCharges}
      tableId={createRateCardTableId(columns, 'haulage-charges')}
      title={title}
      totalCountConfig={{ totalCount: haulageCharges.length, entityName: 'item' }}
    />
  );
};

export default HaulageChargeTable;
