import type { FC } from 'react';

import NoResults from '@zen/Components/NoResults';
import type { TableColumn } from '@zen/DesignSystem';
import { Table } from '@zen/DesignSystem';
import { bookingRequestRoutes } from '@zen/Routes';
import type { PageInfo, SortInput } from '@zen/types';

import type { BookingRequest } from '../types';
import { getColumns } from './bookingRequestsTableColumns';

interface Props {
  bookingRequests: BookingRequest[];
  onOrderChange: (order: SortInput) => void;
  order: SortInput;
  paginationInfo: PageInfo;
  totalCount: number;
}

const BookingRequestsTable: FC<Props> = ({ bookingRequests, order, onOrderChange, paginationInfo, totalCount }) => {
  const emptyList = !bookingRequests || (bookingRequests && bookingRequests.length === 0);

  if (emptyList) {
    return (
      <div className="h-screen">
        <NoResults headline="You don’t have any booking requests at the moment" />
      </div>
    );
  }

  const { rejected, forwarderApproved } = bookingRequests[0].bookingRequest;

  const getShipmentDetailsPageLink = (zencargoReference: string, sectionName?: string): string => {
    const { rejectedDetailsPage, manufacturerRequestsDetailsPage, readyForApprovalDetailsPage } = bookingRequestRoutes;

    if (rejected) {
      return rejectedDetailsPage.getUrl(zencargoReference, sectionName);
    }

    if (!forwarderApproved) {
      return manufacturerRequestsDetailsPage.getUrl(zencargoReference, sectionName);
    }

    return readyForApprovalDetailsPage.getUrl(zencargoReference, sectionName);
  };

  const columns: TableColumn<BookingRequest>[] = getColumns(getShipmentDetailsPageLink);

  const hiddenColumns: string[] = [];

  if (!rejected) {
    hiddenColumns.push('rejectedReason');
  }

  return (
    <Table<BookingRequest>
      columns={columns}
      data={bookingRequests}
      hiddenColumns={hiddenColumns}
      onOrderChange={onOrderChange}
      order={order}
      paginationInfo={paginationInfo}
      tableId="bookingRequests"
      totalCountConfig={{
        totalCount,
        entityName: 'booking request'
      }}
    />
  );
};

export default BookingRequestsTable;
