import { first, orderBy, take } from 'lodash';
import type { ReactNode } from 'react';
import { useLocalStorage } from 'react-use';

import type { VisitedAccount } from './types';
import VisitedAccountsContext from './VisitedAccountsContext';

interface Props {
  children: ReactNode;
}

const VISITED_ACCOUNTS_KEY = 'visited-accounts';

const VisitedAccountsProvider = ({ children }: Props) => {
  const [visitedAccountsRecord, setVisitedAccountsRecord] = useLocalStorage<Record<string, VisitedAccount[]>>(
    VISITED_ACCOUNTS_KEY,
    {
      'default-visited-account-user': []
    }
  );

  const getVisitedAccounts = (userId: string): VisitedAccount[] => {
    return visitedAccountsRecord?.[userId] || [];
  };

  const updateVisitedAccounts = (userId: string, account: VisitedAccount['account']) => {
    const visitedAccounts = getVisitedAccounts(userId);
    const lastVisitedAccount = first(visitedAccounts);

    if (lastVisitedAccount?.account.id === account.id) return;

    let updatedExistingRecord: boolean = false;

    const updatedVisitedAccounts = visitedAccounts.map((visitedAccount) => {
      if (visitedAccount.account.id !== account.id) return visitedAccount;

      updatedExistingRecord = true;

      return {
        account,
        count: visitedAccount.count + 1,
        lastVisitedTime: Date.now()
      };
    });

    const newVisitedAccounts: VisitedAccount[] = updatedExistingRecord
      ? updatedVisitedAccounts
      : [...visitedAccounts, { account, lastVisitedTime: Date.now(), count: 1 }];

    setVisitedAccountsRecord({ [userId]: orderBy(newVisitedAccounts, 'lastVisitedTime', 'desc') });
  };

  const getLastVisitedAccounts = (userId: string) => take(orderBy(getVisitedAccounts(userId), 'lastVisitedTime', 'desc'), 3);

  const getMostVisitedAccounts = (userId: string) => take(orderBy(getVisitedAccounts(userId), 'count', 'desc'), 3);

  const context = {
    getVisitedAccounts,
    updateVisitedAccounts,
    getLastVisitedAccounts,
    getMostVisitedAccounts
  };

  return <VisitedAccountsContext.Provider value={context}>{children}</VisitedAccountsContext.Provider>;
};

export default VisitedAccountsProvider;
