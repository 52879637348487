import type { Option } from '@zen/DesignSystem';

import type { PurchaseOrderDelayReasonOptionsQueryVariables } from '../graphql';
import { usePurchaseOrderDelayReasonOptionsQuery } from '../graphql';
import type { PurchaseOrderDelayReasonOption } from '../types';

export const usePurchaseOrderDelayOptions = (variables: PurchaseOrderDelayReasonOptionsQueryVariables = {}) => {
  const { data, loading, refetch } = usePurchaseOrderDelayReasonOptionsQuery();

  const purchaseOrderDelayReasons = (data?.purchaseOrderDelayReasons?.nodes || []) as PurchaseOrderDelayReasonOption[];

  const options: Option<string>[] = purchaseOrderDelayReasons.map((purchaseOrderDelayReason) => ({
    label: purchaseOrderDelayReason?.reason || '',
    value: purchaseOrderDelayReason.id
  }));

  return { options, loading, refetch };
};
