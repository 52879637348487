import type { Optional } from '@zen/utils/typescript';

import type { CargoDimensionsType, CargoWeightType } from '../../../types';
import { CargoDimensionsUnitEnum, CargoWeightUnitEnum, PalletTypeEnum } from '../../../types';

const palletTypeDimensionsMapping: Record<PalletTypeEnum, CargoDimensionsType> = {
  [PalletTypeEnum.EURO]: {
    length: 120,
    width: 80,
    metric: CargoDimensionsUnitEnum.CM
  },
  [PalletTypeEnum.UK]: {
    length: 120,
    width: 100,
    metric: CargoDimensionsUnitEnum.CM
  },
  [PalletTypeEnum.US]: {
    length: 48,
    width: 40,
    metric: CargoDimensionsUnitEnum.IN
  },
  [PalletTypeEnum.OTHER]: {
    length: null,
    width: null,
    metric: CargoDimensionsUnitEnum.CM
  }
};

export const initialDimensions: CargoDimensionsType = {
  length: null,
  width: null,
  height: null,
  metric: CargoDimensionsUnitEnum.CM
};

export const getPalletDimensions = (
  palletType: PalletTypeEnum,
  dimensions?: Optional<CargoDimensionsType>,
  previousPalletType?: Optional<PalletTypeEnum>
): CargoDimensionsType => {
  const hasStandardDimensions: boolean = palletType !== PalletTypeEnum.OTHER;

  if (hasStandardDimensions) {
    return {
      ...palletTypeDimensionsMapping[palletType],
      height: dimensions?.height
    };
  }
  const shouldPersistDimensions: boolean = !!dimensions && !previousPalletType;
  const dimensionValues = shouldPersistDimensions ? dimensions : initialDimensions;

  return {
    ...dimensionValues,
    height: dimensions?.height || null
  };
};

export const getDefaultGrossWeight = (palletType: Optional<PalletTypeEnum>, weightValue: Optional<number>): CargoWeightType => {
  const metric: CargoWeightUnitEnum = palletType === PalletTypeEnum.US ? CargoWeightUnitEnum.LBS : CargoWeightUnitEnum.KG;

  return {
    value: weightValue,
    metric
  };
};

export const isTemplatedPalletType = (palletType: Optional<PalletTypeEnum>): boolean => {
  if (!palletType) {
    return false;
  }

  return [PalletTypeEnum.EURO, PalletTypeEnum.UK, PalletTypeEnum.US].includes(palletType);
};
