import type { FC } from 'react';

import ActivityFeedDates from '@zen/ActivityFeed/components/ActivityFeedDates';
import ActivityFeedItemDetails from '@zen/ActivityFeed/components/ActivityFeedItemDetails';
import ActivityFeedItemIcon from '@zen/ActivityFeed/components/ActivityFeedItemIcon/ActivityFeedItemIcon';
import ActivityFeedItemWrapper from '@zen/ActivityFeed/components/ActivityFeedItemWrapper';
import type { Activity, ShipmentEtaMetadataType } from '@zen/ActivityFeed/types';
import { ActivityFeedItemTypeEnum } from '@zen/ActivityFeed/types';
import { ConfidenceLevel } from '@zen/Orders';

interface Props {
  activityMetadata: Activity;
}

const EtaItem: FC<Props> = ({ activityMetadata }) => {
  const { createdAt, user, itemType } = activityMetadata;
  const metadata: ShipmentEtaMetadataType = activityMetadata.metadata as ShipmentEtaMetadataType;

  let title: string = 'Estimated time of arrival ';

  if (itemType === ActivityFeedItemTypeEnum.ETA_REMOVED_ITEM) {
    title += 'removed';
  } else if (itemType === ActivityFeedItemTypeEnum.ETA_UPDATED_ITEM) {
    title += 'updated';
  }

  return (
    <ActivityFeedItemWrapper className="flex justify-between">
      <ActivityFeedItemDetails
        createdAt={createdAt}
        icon={<ActivityFeedItemIcon color="text-orange-base" iconName="zicon-warning-triangle" />}
        title={title}
        user={user}
      />
      {metadata.newEstimate && (
        <ActivityFeedDates
          actualDate={metadata.newEstimate.date}
          confidenceLevel={ConfidenceLevel.ESTIMATED}
          dateLabel="Arrival date"
          hasInitialDateForAll={true}
          initialDate={metadata.previousEstimate?.date}
          timeZone={metadata.newEstimate.timeZone}
        />
      )}
    </ActivityFeedItemWrapper>
  );
};

export default EtaItem;
