import './utils/tracing';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

const domNode: HTMLElement | null = document.getElementById('react-app-root');

if (domNode) {
  createRoot(domNode).render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}
