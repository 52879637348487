import type { FC, ReactNode } from 'react';

import type { Group } from '@zen/DesignSystem';
import { Icon, Select } from '@zen/DesignSystem';
import { responsiblePartyLabels } from '@zen/OperationsSettings';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { getOptions, responsiblePartyIconMapping } from './helpers';
import { useMilestoneDelays } from './hooks';
import type { ShippingDelayReasonResponsiblePartyEnum } from './types';

interface Props {
  isDisabled?: boolean;
  isLoading?: boolean;
  onChange: (delayReasonId: Nullable<string>) => void;
  placeholder?: string;
  size?: 'default' | 'compact';
  value: Optional<string>;
  variant?: 'default' | 'inline';
}

const MilestoneDelayReasonSelect: FC<Props> = (props) => {
  const {
    isDisabled = false,
    isLoading = false,
    onChange,
    placeholder = 'Add reason',
    size = 'compact',
    variant = 'inline',
    value
  } = props;

  const { data, isLoading: isLoadingOptions } = useMilestoneDelays();
  const loading: boolean = isLoadingOptions || isLoading;

  const formatGroupLabel = ({ label }: Group<string>): ReactNode => {
    const responsibleParty = label as ShippingDelayReasonResponsiblePartyEnum;

    return (
      <div className="flex items-center min-w-0 px-1 space-x-2 text-grey-base">
        <Icon className="text-base" icon={responsiblePartyIconMapping[responsibleParty]} />
        <div className="text-sm truncate">{responsiblePartyLabels[responsibleParty]} issues</div>
      </div>
    );
  };

  return (
    <Select
      formatGroupLabel={formatGroupLabel}
      isClearable={true}
      isDisabled={isDisabled || loading}
      isLoading={loading}
      onChange={onChange}
      options={getOptions(data)}
      placeholder={placeholder}
      renderMenuInPortal={true}
      size={size}
      value={value}
      variant={variant}
    />
  );
};

export default MilestoneDelayReasonSelect;
