import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateAccountDemoModeMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateAccountDemoModeInput;
}>;

export type UpdateAccountDemoModeMutationResult = { __typename?: 'Mutation' } & {
  updateAccountDemoMode?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateAccountDemoModePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const UpdateAccountDemoModeDocument = /* #__PURE__ */ gql`
  mutation updateAccountDemoMode($input: UpdateAccountDemoModeInput!) {
    updateAccountDemoMode(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateAccountDemoModeMutation__
 *
 * To run a mutation, you first call `useUpdateAccountDemoModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountDemoModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountDemoModeMutation, { data, loading, error }] = useUpdateAccountDemoModeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountDemoModeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountDemoModeMutationResult, UpdateAccountDemoModeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateAccountDemoModeMutationResult, UpdateAccountDemoModeMutationVariables>(
    UpdateAccountDemoModeDocument,
    options
  );
}
export type UpdateAccountDemoModeMutationHookResult = ReturnType<typeof useUpdateAccountDemoModeMutation>;
