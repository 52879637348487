import type { NetworksRoutes } from '@zen/Routes';
import {
  accountsRoutes,
  exchangeRatesRoutes,
  networks,
  opsDashboardRoutes,
  opsQuoteRoutes,
  opsSettingsRoutes,
  opsShipmentRoutes,
  productSettingsRoutes,
  rulesEngineRoutes,
  taskListRoutes,
  workflowsRoutes
} from '@zen/Routes';

import type { SidebarLink } from '../types';

const zencargoNetworkRoutes: NetworksRoutes = networks('zencargo');

export const config: SidebarLink[] = [
  {
    icon: 'zicon-dashboard',
    label: 'Ops dashboard',
    url: opsDashboardRoutes.dashboardIndex.getUrl()
  },
  {
    icon: 'zicon-tasks',
    label: 'Tasks',
    url: '',
    isExpandedByDefault: true,
    children: [
      {
        label: 'Your tasks',
        url: taskListRoutes.taskListYourTasks.getUrl(),
        counter: 'taskListCount'
      },
      {
        label: 'Unassigned tasks',
        url: taskListRoutes.taskListUnassignedTasks.getUrl(),
        counter: 'unassignedTaskListCount'
      },
      {
        label: 'All open tasks',
        url: taskListRoutes.taskListAllOpenTasks.getUrl(),
        counter: 'allTaskListCount'
      }
    ]
  },
  {
    icon: 'zicon-quotes',
    label: 'Ops quotes',
    url: opsQuoteRoutes.quoteIndex.getUrl()
  },
  {
    icon: 'zicon-shipment',
    label: 'Ops shipments',
    url: opsShipmentRoutes.allShipments.getUrl()
  },
  {
    icon: 'zicon-adjust',
    label: 'Zencargo admin',
    url: '',
    children: [
      {
        label: 'All accounts',
        url: accountsRoutes.accountsIndex.getUrl()
      },
      {
        label: 'Zen network',
        url: zencargoNetworkRoutes.myOrganisation.index.getUrl()
      },
      {
        label: 'Rules engine',
        url: rulesEngineRoutes.index.getUrl()
      },
      {
        label: 'Operations settings',
        url: opsSettingsRoutes.opsSettingsIndex.getUrl()
      },
      {
        label: 'Product settings',
        url: productSettingsRoutes.productSettingsIndex.getUrl()
      },
      {
        label: 'Exchange rates',
        url: exchangeRatesRoutes.manageExchangeRatesIndex.getUrl()
      }
    ]
  },
  {
    icon: 'zicon-workflows',
    label: 'Workflows',
    url: '',
    children: [
      {
        label: 'Flows',
        url: workflowsRoutes.workflowsFlows.getUrl()
      },
      {
        label: 'Triggers',
        url: workflowsRoutes.workflowsTriggers.getUrl()
      },
      {
        label: 'Rules',
        url: workflowsRoutes.workflowRulesIndex.getUrl()
      }
    ]
  }
];
