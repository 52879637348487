import { get } from 'lodash';

import { dashboardRoutes } from '@zen/Routes';
import type { Location, LocationDescriptor, LocationDescriptorObject } from '@zen/types';

import type { Optional } from './typescript';

export const getPreviousUrl = (location: Location): string | undefined => get(location, 'state.previousUrl');

export const extractFirstLevelPath = (pathname: string): string => {
  const targetPathRegExp = /^\/dashboard(\/[^/]*)?/;

  const matches = targetPathRegExp.exec(pathname);

  // if the path name does not match regexp, we fall back to `/dashboard`
  return matches ? matches[0] : dashboardRoutes.dashboard.getUrl();
};

interface ToAndState {
  state?: Optional<object>;
  to: string | Omit<LocationDescriptorObject, 'state'>;
}

export const extractToAndState = (linkTo: LocationDescriptor): ToAndState => {
  let to: string | Omit<LocationDescriptorObject, 'state'>;
  let state: Optional<object>;

  if (typeof linkTo === 'object') {
    const { state: urlState, ...urlRest } = linkTo;

    to = urlRest;
    state = urlState;
  } else {
    to = linkTo;
  }

  return { to, state };
};
