import { uniqueId } from 'lodash';

import { prepareLocation } from '@zen/OperationsSettings';
import type { LocationType } from '@zen/RateCards/components/types';
import { findPort } from '@zen/RateCards/PortCharges/PortChargeForm';
import { getEmptyChargeItem } from '@zen/RateCards/RateCardForm/components/RateCardChargeFormItems';
import type { ChargeItemFormValues } from '@zen/RateCards/RateCardForm/types';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import {
  Applicability,
  type CostTrackingLocation,
  type HaulageChargeFormInitialValues,
  type HaulageChargeLocationConfig,
  type PayloadProps,
  type PortFieldKey,
  type WarehouseFieldKey
} from './types';

export const getInitialValues = (
  portFieldName: PortFieldKey,
  warehouseFieldName: WarehouseFieldKey
): HaulageChargeFormInitialValues => {
  return {
    [portFieldName]: null,
    [warehouseFieldName]: null,
    chargeItems: [getEmptyChargeItem()]
  };
};

export const preparePayload = ({ values, ports, applicability, locationType }: PayloadProps): RateCardCharge[] => {
  const isOrigin: boolean = applicability === Applicability.ORIGIN;
  const { portFieldName, warehouseFieldName } = locationsConfig[locationType];
  const chargeWarehouse: CostTrackingLocation | null = values[warehouseFieldName]
    ? prepareLocation(values[warehouseFieldName])
    : null;
  const chargePort: CostTrackingLocation | null = values[portFieldName] ? findPort(ports, values[portFieldName]) : null;

  return values.chargeItems.map((chargeItem: ChargeItemFormValues) => {
    const { chargeBasis, cargoOptions, chargeName, chargeType, unitPrice } = chargeItem;

    return {
      applicability: [applicability],
      chargeBasis,
      cargoOptions,
      chargeType,
      chargeTypeId: chargeType?.id,
      currency: unitPrice.currency,
      fromLocation: isOrigin ? chargeWarehouse : chargePort,
      id: uniqueId(),
      itemName: chargeName,
      toLocation: isOrigin ? chargePort : chargeWarehouse,
      unitPrice: unitPrice.value
    };
  });
};

export const locationsConfig: Record<LocationType, HaulageChargeLocationConfig> = {
  origin: {
    warehouseLabel: 'Collection',
    warehouseFieldName: 'fromLocation',
    portLabel: 'Port of origin',
    portFieldName: 'toLocationId'
  },
  destination: {
    warehouseLabel: 'Destination',
    warehouseFieldName: 'toLocation',
    portLabel: 'Port of destination',
    portFieldName: 'fromLocationId'
  }
};
