import { isNil } from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';

import type { OrderFilters } from '@zen/Orders';
import { PurchaseOrderStatusEnum } from '@zen/Orders';
import { getDateRangeInput } from '@zen/utils/date';
import { ALL_DAYS_IN_THE_PAST } from '@zen/utils/Filtering';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';
import useAccount from '@zen/utils/hooks/useAccount';
import type { Nullable } from '@zen/utils/typescript';

import { useOrderListSavedFiltersCountQuery } from './graphql';
import SavedFilterItem from './SavedFilterItem';
import { compareFilters, getSavedFiltersConfig } from './savedFilters.helper';
import type { OrderListSavedFiltersCountQueryVariables, SavedFilterConfigItem } from './types';

interface Props {
  clearFilters: () => void;
}

const SavedFilters: FC<Props> = ({ clearFilters }) => {
  const { accountUuid: accountId } = useAccount();
  const { appliedFilters, setAppliedFilters } = useAppliedFilters<OrderFilters>();
  const [activeFilterIndex, setActiveFilterIndex] = useState<Nullable<number>>(null);
  const yesterday: Moment = moment().add(-1, 'days');

  const variables: OrderListSavedFiltersCountQueryVariables = {
    ...(accountId && { customerUuid: accountId }),
    cargoReadyDateBetweenPast: getDateRangeInput(ALL_DAYS_IN_THE_PAST, yesterday),
    cargoReadyDateBetween2Weeks: getDateRangeInput(14),
    cargoReadyDateUpdatedInLast3Days: getDateRangeInput(-3),
    status: PurchaseOrderStatusEnum.OPEN
  };

  const { data, refetch } = useOrderListSavedFiltersCountQuery({
    variables
  });

  const savedFiltersConfig: SavedFilterConfigItem[] = getSavedFiltersConfig(data);

  useEffect(() => {
    const matchedFiltersIndex: number = savedFiltersConfig.findIndex((item: SavedFilterConfigItem) =>
      compareFilters(item, appliedFilters)
    );

    const indexToUpdate: Nullable<number> = matchedFiltersIndex !== -1 ? matchedFiltersIndex : null;

    setActiveFilterIndex(indexToUpdate);
  }, [appliedFilters, savedFiltersConfig]);

  const renderConfigItem = (item: SavedFilterConfigItem, index: number): ReactNode => {
    const { filters, count } = item;
    const hasItems: boolean = count > 0;
    const isSelectedFilter: boolean = !isNil(activeFilterIndex) && index === activeFilterIndex;
    const isDisabled: boolean = !isSelectedFilter || isNil(activeFilterIndex) || !hasItems;

    const handleSelect = () => {
      if (hasItems) {
        setAppliedFilters(filters);
      }
    };

    return (
      <SavedFilterItem key={index} isDisabled={isDisabled} item={item} onCounterClick={() => refetch()} onSelect={handleSelect} />
    );
  };

  const isFilterActive: boolean = activeFilterIndex !== null;

  return (
    <div className="flex flex-col leading-normal">
      <p className="mb-2 text-sm font-bold">Filter by</p>
      <div className="flex">
        {savedFiltersConfig.map(renderConfigItem)}
        {isFilterActive && (
          <div className="self-center font-bold cursor-pointer text-navy-base" onClick={clearFilters}>
            Clear filters
          </div>
        )}
      </div>
    </div>
  );
};

export default SavedFilters;
