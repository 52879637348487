import type { FC, ReactNode } from 'react';

import type { TableColumn } from '@zen/DesignSystem';
import { Table } from '@zen/DesignSystem';
import { useRateCardContext } from '@zen/RateCards/RateCardContext';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { ChargeTableProps } from '../../components/types';
import ChargeTableActions from '../../RateCardForm/components/ChargeTableActions';
import { getOtherChargeTableColumns } from './otherChargeTableConfig';

const OtherChargeTable: FC<ChargeTableProps> = ({ charges, onAdd, onUpdate, onDelete }) => {
  const { hasFreightCharges, isEditable } = useRateCardContext();

  const columns: TableColumn<RateCardCharge>[] = getOtherChargeTableColumns({ onDelete, onUpdate }, isEditable);
  const renderActions: ReactNode = isEditable && (
    <ChargeTableActions actionConfig={{ title: 'Add charge', type: 'secondary' }} isDisabled={!hasFreightCharges} onAdd={onAdd} />
  );

  return (
    <Table
      actions={renderActions}
      columns={columns}
      data={charges}
      rowKey="id"
      tableId="other-charges"
      title="Other charges"
      totalCountConfig={{ totalCount: charges.length, entityName: 'item' }}
    />
  );
};

export default OtherChargeTable;
