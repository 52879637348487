import moment from 'moment';
import type { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import OperationsSettingsSearch from '@zen/Components/OperationsSettingsSearch';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Button, Table } from '@zen/DesignSystem';
import opsSettingsRoutes from '@zen/Routes/opsSettings';
import type { SortInput } from '@zen/types';
import { SortingOrder } from '@zen/types';
import usePagination from '@zen/utils/hooks/pagination/usePagination';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import type { GetChargeTypesQueryResult, GetChargeTypesQueryVariables } from '../graphql';
import { useFinanceUpdateChargeTypeMutation, useGetChargeTypesQuery } from '../graphql';
import type { ChargeType, ChargeTypeInput } from '../types';
import { getColumnsConfiguration } from './chargeTypeListTableConfiguration';

const initialOrder: SortInput = {
  field: 'name',
  direction: SortingOrder.ASC
};

const ChargeTypeList: FC = () => {
  const { navigate } = useNavigationHistory();

  const [order, setOrder] = useState<SortInput>(initialOrder);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { addError, addSuccess } = useNotification();

  const { loading, error, nodes, paginationInfo, totalCount, refetch } = usePagination<
    GetChargeTypesQueryResult,
    GetChargeTypesQueryVariables,
    ChargeType
  >(useGetChargeTypesQuery, 'chargeTypes', { order, searchQuery }, 20, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const [updateChargeType] = useFinanceUpdateChargeTypeMutation();

  const handleEditChargeType = (chargeTypeId: string): void => {
    navigate(opsSettingsRoutes.chargeTypesEdit.getUrl(chargeTypeId));
  };

  const handleChargeTypeDelete = async (chargeTypeItem: ChargeType): Promise<IOkOrErrorResult> => {
    const { id, ...chargeTypeParams } = chargeTypeItem;

    return performMutation({
      mutationFn: () =>
        updateChargeType({
          variables: {
            input: {
              id,
              chargeTypeParams: {
                ...chargeTypeParams,
                deletedAt: moment().format() // yes, that is the API
              } as ChargeTypeInput
            }
          }
        }),
      onError: () => {
        addError('Error deleting charge type.');
      },
      onSuccess: () => {
        addSuccess('Charge type successfully deleted.');
        refetch();
      }
    });
  };

  const newChargeTypeButton: ReactNode = (
    <Button onClick={() => navigate(opsSettingsRoutes.chargeTypesCreate.getUrl())} variant="secondary">
      New charge type
    </Button>
  );
  const columns = getColumnsConfiguration(handleEditChargeType, handleChargeTypeDelete);

  return (
    <Page
      actionButtons={newChargeTypeButton}
      defaultBackUrl={opsSettingsRoutes.opsSettingsIndex.getUrl()}
      tagline="A list of all charge types."
      title="Charge types"
    >
      <>
        <OperationsSettingsSearch onSearch={setSearchQuery} placeholder="Search for a charge type..." />
        <QueryHandler
          data={nodes}
          error={!!error}
          isLoading={loading}
          loadingComponent={<SkeletonTableLoading columns={columns} order={order} tableId="chargeTypeList" />}
        >
          {(chargeTypeList: ChargeType[]): ReactElement => (
            <Table<ChargeType>
              columns={columns}
              data={chargeTypeList}
              onOrderChange={setOrder}
              order={order}
              paginationInfo={paginationInfo}
              tableId="chargeTypeList"
              totalCountConfig={{
                totalCount,
                entityName: 'charge type'
              }}
            />
          )}
        </QueryHandler>
      </>
    </Page>
  );
};

export default ChargeTypeList;
