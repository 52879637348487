import type { FC } from 'react';

import type { ModeOfTransport } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useCreateContainerCargoItemMutation } from '../../../graphql';
import {
  CargoItemTypeEnum,
  CargoManagementTrackingAction,
  CargoManagementTrackingCategory,
  type CreateContainerCargoItemInput
} from '../../../types';
import FullContainerForm from '../FullContainerForm';
import type { FullContainerFormValues } from '../FullContainerForm/types';
import { prepareFullContainerFormValues } from '../helpers';

interface Props {
  modeOfTransport: ModeOfTransport;
  onCancel: () => void;
  onSuccess: (cargoId: string) => void;
  zencargoReference: string;
}

const NewFullContainer: FC<Props> = ({ zencargoReference, onSuccess, onCancel, modeOfTransport }) => {
  const [createContainer] = useCreateContainerCargoItemMutation();
  const { addSuccess } = useNotification();
  const { trackEvent } = useTracking();

  const handleSubmit = async (values: FullContainerFormValues): Promise<IOkOrErrorResult> => {
    const input: CreateContainerCargoItemInput = {
      zencargoReference,
      ...prepareFullContainerFormValues(values)
    };

    return performFormMutation({
      mutationFn: () =>
        createContainer({
          awaitRefetchQueries: true,
          variables: {
            input
          },
          refetchQueries: ['cargoJourneysSummary', 'cargoOverview', 'cargoSummary']
        })
    });
  };

  const handleSuccess = (cargoId: string): void => {
    addSuccess('New container has been created.');
    onSuccess(cargoId);

    trackEvent({
      category: CargoManagementTrackingCategory,
      action: CargoManagementTrackingAction.ADD_NEW_CARGO_ITEM,
      label: CargoItemTypeEnum.CONTAINER
    });
  };

  return (
    <FullContainerForm modeOfTransport={modeOfTransport} onCancel={onCancel} onSubmit={handleSubmit} onSuccess={handleSuccess} />
  );
};

export default NewFullContainer;
