import type { ModeOfTransport } from '@zen/Booking';
import { isRoadBooking } from '@zen/Booking';
import type { DateWithTimeFields } from '@zen/Components/DateWithTimeForm';
import type { SegmentedControlOption } from '@zen/DesignSystem';
import { formatTime } from '@zen/utils/dateTime';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type {
  JourneyMilestoneWithMetadata,
  JourneyShippingMilestone,
  JourneyStopActionEnum,
  LocalDateTimeRange,
  LocalDateTimeType
} from '../../types';
import {
  JourneyActualDateReasonEnum,
  JourneyManageDatesReasonEnum,
  JourneyPlannedDateReasonEnum,
  JourneyRemoveActualDateReasonEnum,
  JourneyRequestedDateReasonEnum,
  JourneyShippingMilestoneNameEnum,
  MilestoneDateType,
  ShippingMilestoneTypeEnum
} from '../../types';

export const removeActualDateReasonLabelMapping: Record<JourneyRemoveActualDateReasonEnum, string> = {
  [JourneyRemoveActualDateReasonEnum.ACTUAL_DATE_PRESENT_ON_FOLLOWING_MILESTONE]:
    'First you need to clear the actual date for the following milestone.',
  [JourneyRemoveActualDateReasonEnum.MILESTONE_CANNOT_HAVE_ACTUAL_DATE]: ''
};

const milestoneTypeDateTypeMapping: Record<ShippingMilestoneTypeEnum, MilestoneDateType[]> = {
  [ShippingMilestoneTypeEnum.CARGO]: [MilestoneDateType.ACTUAL],
  [ShippingMilestoneTypeEnum.VEHICLE]: [MilestoneDateType.REQUESTED, MilestoneDateType.PLANNED, MilestoneDateType.ACTUAL]
};

const emptyCargoReadyDateLabel: string = 'First you need to enter the cargo ready date.';
const journeyCollectionLocationMissingLabel: string = 'First you need to enter collection location.';
const journeyDeliveryLocationMissingLabel: string = 'First you need to enter delivery location.';
const journeyNotBookedLabel: string = 'First you need to confirm the booking.';
const missingActualDateLabel: string = 'First enter the actual for all preceding milestones.';
const plannedDateMissingLabel: string = 'First you need to add a booked date.';
const plannedScheduleMissingLabel: string = 'First you need to enter the planned schedule.';

export const getRequestedDateTypeLabel = (name: JourneyShippingMilestoneNameEnum) => {
  if (
    [
      JourneyShippingMilestoneNameEnum.AIRCRAFT_ARRIVED,
      JourneyShippingMilestoneNameEnum.AIRCRAFT_DEPARTED,
      JourneyShippingMilestoneNameEnum.TRAIN_ARRIVED,
      JourneyShippingMilestoneNameEnum.TRAIN_DEPARTED,
      JourneyShippingMilestoneNameEnum.VESSEL_ARRIVED,
      JourneyShippingMilestoneNameEnum.VESSEL_DEPARTED
    ].includes(name)
  ) {
    return 'Planned';
  }

  return 'Requested';
};

const getJourneyBookedLabel = (stopAction: JourneyStopActionEnum) => {
  const stopActionLabel = stopAction.toLowerCase();

  return `You cannot request ${stopActionLabel} once the ${stopActionLabel} slot has been booked.`;
};

export const getDatesTooltipLabel = (reason: Optional<JourneyManageDatesReasonEnum>, stopAction: JourneyStopActionEnum) => {
  if (!reason) return null;

  const labelMapping: Record<JourneyManageDatesReasonEnum, string> = {
    [JourneyManageDatesReasonEnum.ACTUAL_DATE_MISSING_ON_PREVIOUS_MILESTONE]: missingActualDateLabel,
    [JourneyManageDatesReasonEnum.EMPTY_CARGO_READY_DATE]: emptyCargoReadyDateLabel,
    [JourneyManageDatesReasonEnum.EMPTY_COLLECTION_LOCATION]: journeyCollectionLocationMissingLabel,
    [JourneyManageDatesReasonEnum.EMPTY_DELIVERY_LOCATION]: journeyDeliveryLocationMissingLabel,
    [JourneyManageDatesReasonEnum.JOURNEY_BOOKED]: getJourneyBookedLabel(stopAction),
    [JourneyManageDatesReasonEnum.JOURNEY_NOT_BOOKED]: journeyNotBookedLabel,
    [JourneyManageDatesReasonEnum.SCHEDULE_NOT_ADDED]: plannedScheduleMissingLabel,
    [JourneyManageDatesReasonEnum.USER_CANNOT_UPDATE_DATES]: ''
  };

  return labelMapping[reason];
};

const getRequestedDateTooltipLabel = (
  reason: Optional<JourneyRequestedDateReasonEnum>,
  modeOfTransport: Optional<ModeOfTransport>,
  name: JourneyShippingMilestoneNameEnum
): Nullable<string> => {
  if (!reason) return null;

  const plannedDateNotNullLabel: string =
    isRoadBooking(modeOfTransport) ||
    [
      JourneyShippingMilestoneNameEnum.AIRCRAFT_ARRIVED,
      JourneyShippingMilestoneNameEnum.AIRCRAFT_DEPARTED,
      JourneyShippingMilestoneNameEnum.TRAIN_ARRIVED,
      JourneyShippingMilestoneNameEnum.TRAIN_DEPARTED,
      JourneyShippingMilestoneNameEnum.VESSEL_ARRIVED,
      JourneyShippingMilestoneNameEnum.VESSEL_DEPARTED
    ].includes(name)
      ? 'First you need to cancel the booking.'
      : 'First you need to clear the booked date.';

  const labelMapping: Record<JourneyRequestedDateReasonEnum, string> = {
    [JourneyRequestedDateReasonEnum.EMPTY_CARGO_READY_DATE]: emptyCargoReadyDateLabel,
    [JourneyRequestedDateReasonEnum.EMPTY_COLLECTION_LOCATION]: journeyCollectionLocationMissingLabel,
    [JourneyRequestedDateReasonEnum.EMPTY_DELIVERY_LOCATION]: journeyDeliveryLocationMissingLabel,
    [JourneyRequestedDateReasonEnum.MILESTONE_CANNOT_HAVE_REQUESTED_DATE]: '',
    [JourneyRequestedDateReasonEnum.PLANNED_DATE_NOT_NULL]: plannedDateNotNullLabel,
    [JourneyRequestedDateReasonEnum.SCHEDULE_NOT_ADDED]: plannedScheduleMissingLabel
  };

  return labelMapping[reason];
};

const getPlannedDateTooltipLabel = (reason: Optional<JourneyPlannedDateReasonEnum>): Nullable<string> => {
  if (!reason) return null;

  const labelMapping: Record<JourneyPlannedDateReasonEnum, string> = {
    [JourneyPlannedDateReasonEnum.ACTUAL_DATE_NOT_NULL]: 'First you need to clear the actual date.',
    [JourneyPlannedDateReasonEnum.EMPTY_CARGO_READY_DATE]: emptyCargoReadyDateLabel,
    [JourneyPlannedDateReasonEnum.JOURNEY_NOT_BOOKED]: journeyNotBookedLabel,
    [JourneyPlannedDateReasonEnum.MILESTONE_CANNOT_HAVE_PLANNED_DATE]: '',
    [JourneyPlannedDateReasonEnum.MISSING_CARRIER_BOOKING_REFERENCE]: 'First you need to enter carrier booking reference.',
    [JourneyPlannedDateReasonEnum.EMPTY_COLLECTION_LOCATION]: journeyCollectionLocationMissingLabel,
    [JourneyPlannedDateReasonEnum.EMPTY_DELIVERY_LOCATION]: journeyDeliveryLocationMissingLabel
  };

  return labelMapping[reason];
};

const getActualDateTooltipLabel = (
  reason: Optional<JourneyActualDateReasonEnum>,
  modeOfTransport: Optional<ModeOfTransport>
): Nullable<string> => {
  if (!reason) return null;

  const missingPlannedDateLabel: string = isRoadBooking(modeOfTransport) ? journeyNotBookedLabel : plannedDateMissingLabel;

  const labelMapping: Record<JourneyActualDateReasonEnum, string> = {
    [JourneyActualDateReasonEnum.ACTUAL_DATE_MISSING_ON_PREVIOUS_MILESTONE]: missingActualDateLabel,
    [JourneyActualDateReasonEnum.EMPTY_CARGO_READY_DATE]: emptyCargoReadyDateLabel,
    [JourneyActualDateReasonEnum.EMPTY_COLLECTION_LOCATION]: journeyCollectionLocationMissingLabel,
    [JourneyActualDateReasonEnum.EMPTY_DELIVERY_LOCATION]: journeyDeliveryLocationMissingLabel,
    [JourneyActualDateReasonEnum.JOURNEY_NOT_BOOKED]: journeyNotBookedLabel,
    [JourneyActualDateReasonEnum.PLANNED_DATE_NULL]: missingPlannedDateLabel,
    [JourneyActualDateReasonEnum.SCHEDULE_NOT_ADDED]: plannedScheduleMissingLabel
  };

  return labelMapping[reason];
};

export const getActiveOption = (options: SegmentedControlOption<MilestoneDateType>[]): MilestoneDateType => {
  return options.find(({ isDisabled }) => !isDisabled)?.value || options[0]?.value;
};

export const getOptions = (
  milestone: JourneyShippingMilestone,
  milestones: JourneyMilestoneWithMetadata[],
  modeOfTransport: Optional<ModeOfTransport>
): SegmentedControlOption<MilestoneDateType>[] => {
  const {
    canUpdateActualDate,
    canUpdatePlannedDate,
    canUpdateRequestedDate,
    canUpdateActualDateCurrently,
    canUpdatePlannedDateCurrently,
    canUpdateRequestedDateCurrently,
    milestoneType,
    name
  } = milestone;

  const availableDateTypes: MilestoneDateType[] = milestoneTypeDateTypeMapping[milestoneType];

  const config: Array<SegmentedControlOption<MilestoneDateType> & { isVisible: boolean }> = [
    {
      label: 'No date',
      value: MilestoneDateType.DRAFT,
      isVisible: false
    },
    {
      isDisabled: !canUpdateRequestedDateCurrently?.value,
      isVisible: !!canUpdateRequestedDate,
      label: getRequestedDateTypeLabel(milestone.name),
      tooltip: getRequestedDateTooltipLabel(canUpdateRequestedDateCurrently?.reason, modeOfTransport, name),
      value: MilestoneDateType.REQUESTED
    },
    {
      isDisabled: !canUpdatePlannedDateCurrently?.value,
      isVisible: !!canUpdatePlannedDate,
      label: 'Booked',
      tooltip: getPlannedDateTooltipLabel(canUpdatePlannedDateCurrently?.reason),
      value: MilestoneDateType.PLANNED
    },
    {
      isDisabled: !canUpdateActualDateCurrently?.value,
      isVisible: !!canUpdateActualDate,
      label: 'Actual',
      tooltip: getActualDateTooltipLabel(canUpdateActualDateCurrently?.reason, modeOfTransport),
      value: MilestoneDateType.ACTUAL
    }
  ];

  return config
    .filter(({ isVisible, value }) => isVisible && availableDateTypes.includes(value))
    .map(({ isVisible, ...rest }) => rest);
};

const prepareTime = (time: Optional<string>): string => {
  if (!time) return '';

  return formatTime(time, false) || '';
};

export const prepareEstimatedDateInitialValues = (
  dateAndTime: Optional<LocalDateTimeRange>,
  previousDateAndTime?: Optional<LocalDateTimeRange>
): DateWithTimeFields => {
  if (dateAndTime?.startDateTime?.date) {
    const startTime: string = prepareTime(dateAndTime?.startDateTime?.time);
    const endTime: string = prepareTime(dateAndTime?.endDateTime?.time);

    return {
      date: dateAndTime?.startDateTime?.date || '',
      time: endTime ? '' : startTime,
      startTime: endTime ? startTime : '',
      endTime
    };
  }

  const endTime: string = prepareTime(previousDateAndTime?.endDateTime?.time);

  return {
    date: previousDateAndTime?.startDateTime?.date || '',
    time: '',
    startTime: endTime ? prepareTime(previousDateAndTime?.startDateTime?.time) : '',
    endTime: prepareTime(previousDateAndTime?.endDateTime?.time)
  };
};

export const prepareActualDateInitialValues = (
  actualDateAndTime: Optional<LocalDateTimeType>,
  previousDate: Optional<string>,
  today: string
): DateWithTimeFields => {
  const alternativeDate: Optional<string> = previousDate && previousDate > today ? '' : previousDate;

  return {
    date: actualDateAndTime?.date || alternativeDate || '',
    time: prepareTime(actualDateAndTime?.time),
    startTime: '',
    endTime: ''
  };
};
