import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetOceanCarrierQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type GetOceanCarrierQueryResult = { __typename?: 'Query' } & {
  oceanCarrier?: GraphQLTypes.Maybe<
    { __typename?: 'OceanCarrier' } & Pick<
      GraphQLTypes.OceanCarrier,
      'carrierScac' | 'id' | 'isTrackable' | 'oceanCarrierName' | 'synonyms'
    >
  >;
};

export const GetOceanCarrierDocument = /* #__PURE__ */ gql`
  query getOceanCarrier($id: String!) {
    oceanCarrier(id: $id) {
      carrierScac
      id
      isTrackable
      oceanCarrierName
      synonyms
    }
  }
`;

/**
 * __useGetOceanCarrierQuery__
 *
 * To run a query within a React component, call `useGetOceanCarrierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOceanCarrierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOceanCarrierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOceanCarrierQuery(
  baseOptions: Apollo.QueryHookOptions<GetOceanCarrierQueryResult, GetOceanCarrierQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetOceanCarrierQueryResult, GetOceanCarrierQueryVariables>(GetOceanCarrierDocument, options);
}
export function useGetOceanCarrierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOceanCarrierQueryResult, GetOceanCarrierQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetOceanCarrierQueryResult, GetOceanCarrierQueryVariables>(GetOceanCarrierDocument, options);
}
export type GetOceanCarrierQueryHookResult = ReturnType<typeof useGetOceanCarrierQuery>;
export type GetOceanCarrierLazyQueryHookResult = ReturnType<typeof useGetOceanCarrierLazyQuery>;
