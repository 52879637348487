import { useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { checkPermission } from '@zen/Auth/authHelper';
import QueryHandler from '@zen/Components/QueryHandler';
import { useSubscriptionContext } from '@zen/Shipments/contexts/SubscriptionContext';
import type { SortInput } from '@zen/types';
import { SortingOrder } from '@zen/types';
import useUrlPagination from '@zen/utils/hooks/pagination/useUrlPagination';
import useAccount from '@zen/utils/hooks/useAccount';
import useTracking from '@zen/utils/hooks/useTracking';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import NoShipments from './components/NoShipments';
import { prepareFilterVariables } from './Filters/helpers';
import type { GetShipmentsQueryResult, GetShipmentsQueryVariables } from './graphql';
import { useControlTowerPermissionsQuery, useGetShipmentsQuery, useShipmentsCountQuery } from './graphql';
import { buildFiltersTrackingPayload, prepareFlatShipments } from './helpers';
import ShipmentsQuickFilters from './ShipmentsQuickFilters';
import TableView from './TableView';
import { getColumns } from './TableView/tableConfiguration';
import type { FlatShipment, Shipment, ShipmentFilters, ShipmentsListCapabilities } from './types';
import { ShipmentFilterStatus, ShipmentsTrackingAction, ShipmentsTrackingCategory } from './types';

export const shipmentsPathPrefix = '/dashboard/shipments';

const initialOrder: SortInput = {
  field: 'createdAt',
  direction: SortingOrder.ASC
};

const Shipments = () => {
  const { accountUuid: accountId } = useAccount();
  const { showSubscribed } = useSubscriptionContext();
  const { filters, search } = useUrlFilters<ShipmentFilters>({ status: ShipmentFilterStatus.ACTIVE });
  const [order, setOrder] = useState<SortInput>(initialOrder);
  const initialRender = useRef(true);
  const { trackEvent } = useTracking();

  const {
    data,
    loading: totalCountLoading,
    error: totalCountError
  } = useShipmentsCountQuery({ variables: { ...(accountId && { customerUuid: accountId }) } });
  const hasShipment: boolean = !!(data?.bookings.totalCount || 0);
  const results = useUrlPagination<GetShipmentsQueryResult, GetShipmentsQueryVariables, Shipment>(
    useGetShipmentsQuery,
    'bookings',
    {
      ...(accountId && { customerUuid: accountId }),
      ...prepareFilterVariables(filters),
      showSubscribed,
      order,
      textContains: search
    },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' },
    20
  );

  const { nodes, paginationInfo, totalCount, loading, error } = results;

  const { data: controlTowerPermissions } = useControlTowerPermissionsQuery({
    variables: { uuid: accountId },
    skip: !accountId
  });

  const controlTower = controlTowerPermissions?.legacyAccount?.controlTower;
  const canViewForwarder = controlTower
    ? checkPermission<typeof controlTower>(controlTower, 'canViewForwardersOnShipments')
    : false;
  const capabilities: ShipmentsListCapabilities = { canViewForwarder };

  const columns = getColumns({ capabilities });

  useDeepCompareEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;

      return;
    }

    trackEvent({
      action: ShipmentsTrackingAction.FILTERS_APPLIED,
      category: ShipmentsTrackingCategory,
      label: 'ShipmentFilters',
      properties: buildFiltersTrackingPayload(filters)
    });
  }, [filters]);

  const renderTable = () => {
    const flatShipments: FlatShipment[] = prepareFlatShipments(nodes || []);

    return (
      <TableView
        capabilities={capabilities}
        columns={columns}
        error={!!error}
        loading={loading}
        onOrderChange={setOrder}
        order={order}
        paginationInfo={paginationInfo}
        shipments={flatShipments}
        totalCount={totalCount}
      />
    );
  };

  return (
    <QueryHandler data={hasShipment} error={!!totalCountError} isLoading={totalCountLoading} noResults={<NoShipments />}>
      {() => (
        <>
          <ShipmentsQuickFilters />
          {renderTable()}
        </>
      )}
    </QueryHandler>
  );
};

export default Shipments;
