import type { TargetPermissions } from '@zen/Booking/BookingDetails/components/ActivityFeedContainer/ActivityFeedContainer';

import type { ActivityFeedOriginViewType } from '../types';
import { Role, StageValue } from '../types';

export function createPermissions(role: Role, targetPermissions?: TargetPermissions, viewType?: ActivityFeedOriginViewType) {
  const isAgent = Role.AGENT_FORWARDER === role;
  const isManufacturer = Role.MANUFACTURER === role || !!targetPermissions?.isSupplierOnTarget;
  const isZencargo = Role.ADMIN === role;
  const isCustomer = Role.CUSTOMER_USER === role || !!targetPermissions?.isCustomerOnTarget;

  switch (viewType) {
    case StageValue.QUOTE_REQUESTED: {
      return {
        customerUser: isZencargo || isCustomer,
        // Agent and manufacturer shouldn't access quotes so value is false for them
        agentForwarder: false,
        manufacturer: false
      };
    }

    case StageValue.BOOKING_REQUESTED: {
      return {
        // Customer shouldn't access booking requests page so value is false
        customerUser: isCustomer,
        agentForwarder: isManufacturer || isAgent || isZencargo,
        manufacturer: isManufacturer || isAgent || isZencargo
      };
    }

    default: {
      return {
        customerUser: isZencargo || isCustomer,
        agentForwarder: isManufacturer || isAgent,
        manufacturer: isAgent || isManufacturer
      };
    }
  }
}
