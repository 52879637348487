import type { FC } from 'react';

import type { CargoItemDetailsType, CargoModeEnum } from '@zen/Cargo';
import CargoTypeImage from '@zen/Cargo/components/CargoTypeImage';
import type { Option } from '@zen/DesignSystem';
import { Tile } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import { cargoVariantTileOptions } from './helpers';

interface Props {
  cargoMode: CargoModeEnum;
  hasError: boolean;
  isDisabled?: boolean;
  onChange: (cargoTypeOption: CargoItemDetailsType) => void;
  value: Nullable<string>;
}

const CargoVariantTiles: FC<Props> = (props) => {
  const { hasError, isDisabled, onChange, value, cargoMode } = props;
  const options: Option<CargoItemDetailsType>[] = cargoVariantTileOptions[cargoMode];

  return (
    <div className="grid grid-cols-3 gap-4" data-testid="cargo-variant-select">
      {options.map((option: Option<CargoItemDetailsType>) => {
        const { label, value: optionValue } = option;

        const handleClick = (): void => {
          onChange(optionValue);
        };

        return (
          <Tile
            key={optionValue}
            hasError={hasError}
            isDisabled={isDisabled}
            isSelected={value === optionValue}
            onClick={handleClick}
            title={label}
          >
            <CargoTypeImage cargoType={optionValue} className="w-16" />
          </Tile>
        );
      })}
    </div>
  );
};

export default CargoVariantTiles;
