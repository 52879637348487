import type { FC, ReactNode } from 'react';

import type { FormInstance } from '@zen/Components';
import { Form, FormButtons, FormSelect } from '@zen/Components';
import { ChargeGroupItemType, FormCostTrackingLocationSelect } from '@zen/CostTracking';
import { Button } from '@zen/DesignSystem';
import { preparePortOptions } from '@zen/RateCards/PortCharges/PortChargeForm';
import { getChargeItemApplicability } from '@zen/RateCards/PortCharges/PortChargeForm/helpers';
import RateCardChargeFormItems from '@zen/RateCards/RateCardForm/components/RateCardChargeFormItems';
import type { RateCardCharge } from '@zen/RateCards/reducer';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { LocationType } from '../../components/types';
import { getValidationSchema } from './haulageCharge.validation';
import { getInitialValues, locationsConfig, preparePayload } from './helpers';
import type { Applicability, CostTrackingLocation, HaulageChargeFormInitialValues, HaulageChargeFormValues } from './types';
import { CostTrackingLocationType } from './types';

interface Props {
  locationType: LocationType;
  onCancel: () => void;
  onSubmit: (values: RateCardCharge[]) => void;
  onSuccess: () => void;
  ports: CostTrackingLocation[];
}

const HaulageChargeForm: FC<Props> = (props) => {
  const { locationType, onSubmit, onSuccess, onCancel, ports } = props;
  const chargeTypeApplicability: Applicability = getChargeItemApplicability(locationType);
  const isOrigin: boolean = locationType === 'origin';

  const handleSubmit = (values: HaulageChargeFormValues): Promise<IOkOrErrorResult> => {
    const haulageChargePayload = preparePayload({
      values,
      ports,
      applicability: chargeTypeApplicability,
      locationType
    });

    onSubmit(haulageChargePayload);

    return Promise.resolve({ ok: {}, error: null });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<HaulageChargeFormInitialValues>) => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Add charges">
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  const { portFieldName, portLabel, warehouseFieldName, warehouseLabel } = locationsConfig[locationType];

  const renderPortSelect: ReactNode = (
    <FormSelect className="flex-1" label={portLabel} name={portFieldName} options={preparePortOptions(ports)} />
  );

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={getInitialValues(portFieldName, warehouseFieldName)}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      validationSchema={getValidationSchema(portFieldName, warehouseFieldName)}
    >
      {({ values }: FormInstance<HaulageChargeFormValues>) => {
        const { chargeItems } = values;

        return (
          <>
            <div className="grid grid-cols-2 gap-x-4 pr-14">
              {!isOrigin && renderPortSelect}
              <FormCostTrackingLocationSelect
                label={warehouseLabel}
                locationType={CostTrackingLocationType.SHIPPABLE_LOCATION}
                name={warehouseFieldName}
              />
              {isOrigin && renderPortSelect}
            </div>
            <RateCardChargeFormItems
              chargeItems={chargeItems}
              chargeTypeApplicability={chargeTypeApplicability}
              chargeTypeSearchGroups={[ChargeGroupItemType.HAULAGE]}
            />
          </>
        );
      }}
    </Form>
  );
};

export type { Props as HaulageChargeFormProps };

export default HaulageChargeForm;
