import type { FC, ReactNode } from 'react';

import type { RateCardCharge } from '../reducer';
import type { CargoModeEnum, ModeOfTransport } from '../types';
import { RateCardContext } from './RateCardContext';
import type { ContextType } from './types';

interface Props {
  cargoType: CargoModeEnum;
  children: ReactNode;
  freightCharges?: RateCardCharge[];
  isEditable: boolean;
  modeOfTransport: ModeOfTransport;
}

const RateCardProvider: FC<Props> = (props) => {
  const { cargoType, children, freightCharges = [], isEditable, modeOfTransport } = props;

  const contextValues: ContextType = {
    hasFreightCharges: !!freightCharges.length,
    cargoType,
    isEditable,
    modeOfTransport
  };

  return <RateCardContext.Provider value={contextValues}>{children}</RateCardContext.Provider>;
};

export default RateCardProvider;
