import { createContext } from 'react';

import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

const AccountPermissionsContext = createContext<unknown>(undefined);

const useAccountPermissionsContext = () => {
  const accountPermissions = useContextOrThrowError(
    AccountPermissionsContext,
    'useAccountPermissionsContext must be used inside AccountPermissionsContextProvider'
  );

  return accountPermissions;
};

const AccountPermissionsContextProvider = AccountPermissionsContext.Provider;

export { AccountPermissionsContextProvider, useAccountPermissionsContext };
