import type { ChangeEvent, FC, KeyboardEvent } from 'react';

import type { Nullable } from '@zen/utils/typescript';
import { convertStringToNumber } from '@zen/utils/utils';

import type { InputProps } from '../Input';
import Input from '../Input';
import { getInputSymbols, getPlaceholder, prepareInputDigitsPattern } from './helpers';

interface Props extends Omit<InputProps, 'type' | 'onChange'> {
  max?: number | string;
  maxDecimalDigits?: number;
  maxWholeDigits?: number;
  min?: number | string;
  onChange: (value: Nullable<number>) => void;
  step?: string;
}

const NumberInput: FC<Props> = (props) => {
  const { min, max, onChange, onKeyDown, placeholder, step = '0.01', value: initialValue } = props;
  const { maxDecimalDigits, maxWholeDigits, ...rest } = props;
  const shouldCheckValueAgainstPattern: boolean = !!maxDecimalDigits || !!maxWholeDigits;
  const symbolsToExclude: string[] = getInputSymbols(maxDecimalDigits, min);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const testRegex: boolean = prepareInputDigitsPattern(maxDecimalDigits, maxWholeDigits).test(e.target.value);

    if (shouldCheckValueAgainstPattern && !testRegex) {
      e.preventDefault();

      return;
    }

    const newValue: Nullable<number> = convertStringToNumber(e.currentTarget.value);

    onChange(newValue);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (symbolsToExclude.includes(event.key)) {
      event.preventDefault();
    }

    onKeyDown?.(event);
  };

  return (
    <Input
      {...rest}
      data-component="number-input"
      max={max}
      min={min}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={getPlaceholder(maxDecimalDigits, placeholder)}
      step={step}
      type="number"
      value={initialValue}
    />
  );
};

export type { Props as NumberInputProps };

export default NumberInput;
