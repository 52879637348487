import type { ReactNode } from 'react';

import { Pill, type TableColumn, TableLink } from '@zen/DesignSystem';

import type { AccountLocationSummary } from '../types';
import AccountLocationsActions from './AccountLocationsActions';

interface ColumConfiguration {
  accountId: string;
  pathname: string;
}

const getColumnsConfiguration = ({ accountId, pathname }: ColumConfiguration): TableColumn<AccountLocationSummary>[] => {
  return [
    {
      key: 'name',
      title: 'Name',
      render: (_, { addressLabel, name, id: locationId }: AccountLocationSummary): ReactNode => {
        return <TableLink linkTo={`${pathname}/${locationId}`}>{name || addressLabel}</TableLink>;
      }
    },
    {
      key: 'isRegisteredLegalAddress',
      sortable: false,
      title: '',
      render: (_, { isRegisteredLegalAddress }: AccountLocationSummary): ReactNode => {
        if (!isRegisteredLegalAddress) {
          return null;
        }

        return (
          <Pill className="capitalize" type="pending">
            HQ
          </Pill>
        );
      },
      width: 80
    },
    {
      key: 'companyName',
      title: 'Linked to'
    },
    {
      key: 'status',
      title: 'Status',
      sortable: false,
      render: (_, { permissions }: AccountLocationSummary): ReactNode => {
        if (!permissions?.canEditDetails?.value) {
          return <Pill type="problem">Archived</Pill>;
        }

        return <Pill type="success">Active</Pill>;
      }
    },
    {
      key: 'actions',
      title: '',
      render: (_, { companyName, id, isRegisteredLegalAddress, name, permissions }: AccountLocationSummary): ReactNode => {
        return (
          <AccountLocationsActions
            accountId={accountId}
            canArchiveLocation={!!permissions?.canArchive?.value}
            canEdit={!!permissions?.canEditDetails?.value}
            canUnarchiveLocation={!!permissions?.canUnarchive?.value}
            companyName={companyName}
            isRegisteredLegalAddress={isRegisteredLegalAddress}
            locationId={id}
            locationName={name}
          />
        );
      },
      sortable: false,
      width: '5%'
    }
  ];
};

export default getColumnsConfiguration;
