import type { ObjectSchema } from 'yup';
import { array, number, object, string } from 'yup';

import type { Terminal } from '@zen/Networks';
import type { FreightChargeValidationValues } from '@zen/RateCards/LegacyRateCards/RateCardForm/FreightChargeSection/types';
import type { Nullable } from '@zen/utils/typescript';

const destinationPortRequiredMessage: string = 'Destination port is required.';
const incotermsValidationMessage: string = 'Incoterms are required';

const containerValueValidator = object({ value: number().nullable() }).test(
  'container value test',
  'Price is required.',
  (containerValue) => {
    return containerValue.value !== null;
  }
);

const getDestinationPortValidator = (selectedLanes: FreightChargeValidationValues[]) =>
  object({ unlocode: string() })
    .test('destination port test', function testDestinationValue(destinationValue) {
      if (!destinationValue) {
        return this.createError({ message: destinationPortRequiredMessage });
      }

      if (this.parent.originPort) {
        const existingFreightCharge = selectedLanes.find(
          (tradelane) =>
            tradelane.destinationUnlocode === destinationValue.unlocode &&
            tradelane.originUnlocode === this.parent.originPort.unlocode &&
            tradelane.chargeTypeId === this.parent.chargeType.id
        );

        if (existingFreightCharge) {
          return this.createError({
            message: 'Tradelane with the same charge already exists. Please select another port or charge.'
          });
        }
      }

      return true;
    })
    .nullable();

export const getValidationSchema = (selectedLanes: FreightChargeValidationValues[]): ObjectSchema<{}> => {
  const originPorts = selectedLanes.map((selectedLane) => selectedLane.originUnlocode);

  return object().shape({
    chargeType: object().nullable().required('Charge type is required.'),
    originPort: object().nullable().required('Origin port is required.'),
    destinationPort: object()
      .nullable()
      .required(destinationPortRequiredMessage)
      .when('originPort', {
        is: (val: Nullable<Terminal>) => val && originPorts.includes(val.unlocode),
        then: () => getDestinationPortValidator(selectedLanes).required()
      }),
    incoterms: array().of(string()).nullable().min(1, incotermsValidationMessage).required(incotermsValidationMessage),
    containerCharges: array().of(
      object().shape({
        unitType: string().nullable().required('Container type is required.'),
        containerValue: containerValueValidator
      })
    )
  });
};
