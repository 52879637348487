import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import SubscriptionsContainer from '@zen/ActivityFeed/Subscriptions/SubscriptionsContainer';
import type { ActivityFeedOriginViewType } from '@zen/ActivityFeed/types';
import { ActivityFeedItemTypeEnum, ActivityFeedTargetTypeEnum, Role } from '@zen/ActivityFeed/types';
import useRole from '@zen/Auth/hooks/useRole';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import Slideout, { SlideoutHeader } from '@zen/Components/Slideout';
import { Button, Container } from '@zen/DesignSystem';

import { BookingDetailsPageSection } from '../../types';
import ShipmentActivityFeed from '../ShipmentActivityFeed';

export interface TargetPermissions {
  isCustomerOnTarget: boolean;
  isSupplierOnTarget: boolean;
}

interface Props {
  accountId: string;
  activityFeedHeight?: number | string;
  activityFeedViewType?: ActivityFeedOriginViewType;
  targetPermissions?: TargetPermissions;
  zencargoReference: string;
}

const ActivityFeedContainer: FC<Props> = (props) => {
  const { accountId, activityFeedHeight = '35rem', activityFeedViewType, targetPermissions, zencargoReference } = props;
  const { sectionName } = useParams<{ sectionName: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const role = useRole();

  const { check } = useGlobalPermissions();
  const canManageActivityFeed: boolean = check('accounts.canCreateActivityFeed') || role === Role.AGENT_FORWARDER;

  useEffect(() => {
    if (sectionName === BookingDetailsPageSection.ACTIVITY_FEED_EXPANDED) {
      setSeeAllActivity(true);
    }
  }, [sectionName]);

  const [seeAllActivity, setSeeAllActivity] = useState<boolean>(false);

  const activityFeedAllActivityUrl: string = `${BookingDetailsPageSection.ACTIVITY_FEED_EXPANDED}`;

  const handleOpenActivityFeedAllActivity = (): void => {
    setSeeAllActivity(true);
    navigate(activityFeedAllActivityUrl);
  };

  const handleCloseActivityFeedAllActivity = (): void => {
    setSeeAllActivity(false);
    navigate(location.pathname.replace(`/${BookingDetailsPageSection.ACTIVITY_FEED_EXPANDED}`, ''));
  };

  return (
    <Container
      actions={
        canManageActivityFeed && (
          <>
            <Button onClick={handleOpenActivityFeedAllActivity} size="compact" variant="secondary">
              All activity
            </Button>
            <SubscriptionsContainer
              accountId={accountId}
              targetId={zencargoReference}
              targetType={ActivityFeedTargetTypeEnum.BOOKING}
            />
          </>
        )
      }
      compactView={true}
      title="Messages"
    >
      <div
        className="relative z-0 px-6 pb-6 duration-500 transition-height ease"
        data-testid="custom-activity-feed"
        style={{ height: activityFeedHeight }}
      >
        <ShipmentActivityFeed
          hideTimeline={true}
          itemTypes={[ActivityFeedItemTypeEnum.TEXT_MESSAGE, ActivityFeedItemTypeEnum.ZENSEI_TEXT_MESSAGE]}
          targetPermissions={targetPermissions}
          viewType={activityFeedViewType}
          zencargoReference={zencargoReference}
        />
      </div>

      <Slideout onOutsideClick={handleCloseActivityFeedAllActivity} show={seeAllActivity}>
        <div className="h-full w-220">
          <SlideoutHeader
            actionButtons={
              <SubscriptionsContainer
                accountId={accountId}
                targetId={zencargoReference}
                targetType={ActivityFeedTargetTypeEnum.BOOKING}
              />
            }
            onClose={handleCloseActivityFeedAllActivity}
            title="All activity"
          />
          <div className="relative z-0 h-full px-6 pb-[5.5rem]" data-testid="activity-feed">
            <ShipmentActivityFeed
              targetPermissions={targetPermissions}
              viewType={activityFeedViewType}
              zencargoReference={zencargoReference}
            />
          </div>
        </div>
      </Slideout>
    </Container>
  );
};

export default ActivityFeedContainer;
