import cx from 'classnames';
import { toNumber } from 'lodash';
import type { ChangeEvent, FC } from 'react';
import { useRef, useState } from 'react';

import { Icon } from '@zen/DesignSystem';
import { trimValue } from '@zen/utils';

const MAX_ROWS: number = 9;
const DEFAULT_ROWS_COUNT: number = 1;

interface Props {
  customBorder?: string;
  customButtonClasses?: string;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  onSubmit: (trimmedValue: string) => void;
  placeholder: string;
  value?: string;
}

const SubmittableInput: FC<Props> = (props) => {
  const {
    isDisabled = false,
    onChange = () => {},
    placeholder,
    onSubmit,
    value = '',
    customBorder = null,
    customButtonClasses = null
  } = props;

  const ref = useRef<HTMLTextAreaElement>(null);
  const [rows, setRows] = useState<number>(DEFAULT_ROWS_COUNT);

  const getRowsCount = (newValue: string): number => {
    if (!newValue) return DEFAULT_ROWS_COUNT;

    const scrollHeight = ref?.current?.scrollHeight || 0;
    const rowHeight = (ref.current?.clientHeight || 0) / rows;

    const checkedRows = toNumber((scrollHeight / rowHeight).toFixed(0));

    return checkedRows <= MAX_ROWS ? checkedRows : MAX_ROWS;
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value: inputValue } = event.target;
    const newRows: number = getRowsCount(inputValue);

    setRows(newRows);

    onChange(inputValue);
  };

  const handleSubmit = () => {
    const trimmedValue: string = trimValue(value);

    setRows(DEFAULT_ROWS_COUNT);

    if (!trimmedValue) {
      onChange('');

      return;
    }

    onSubmit(trimmedValue);
  };

  const buttonClasses: string = cx(customButtonClasses, 'absolute bottom-0 right-0 p-3 pl-4');

  const classNames: string = cx(
    {
      'border border-solid border-grey-lighter rounded': !customBorder
    },
    customBorder,
    'flex relative items-center z-50 px-4 py-3 min-h-12'
  );

  return (
    <div className={classNames}>
      <textarea
        ref={ref}
        className="w-full outline-none resize-none mr-4 text-sm leading-normal"
        onChange={handleChange}
        placeholder={placeholder}
        rows={rows}
        value={value}
      />
      <div className={buttonClasses}>
        <Icon className="text-navy-base cursor-pointer" disabled={isDisabled} icon="zicon-send" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default SubmittableInput;
