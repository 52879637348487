import type { FC } from 'react';
import { useEffect, useState } from 'react';

import type { Terminal } from '@zen/Components/TerminalSelect';
import { Dialog } from '@zen/DesignSystem';
import type { PortChargeFilter } from '@zen/graphql/types.generated';
import { usePortCharges } from '@zen/RateCards/LegacyRateCards/graphql/hooks';
import PortChargeForm from '@zen/RateCards/LegacyRateCards/PortCharge/PortChargeForm';
import PortChargeTable from '@zen/RateCards/LegacyRateCards/PortCharge/PortChargeTable';

import type { PortChargePayload } from '../reducer/types';
import { preparePortChargePayloads } from './helpers';

interface Props {
  filters: PortChargeFilter[];
  onAddCustomPortCharge: (value: PortChargePayload) => void;
  onAddPortCharges?: (values: PortChargePayload[]) => void;
  onDelete?: (index: number) => void;
  onDisable?: (id: string, value: boolean) => void;
  onRemoveCustomPrice?: (index: number) => void;
  onUpdate?: (index: number, values: PortChargePayload) => void;
  portCharges: PortChargePayload[];
  ports: Terminal[];
  title: string;
  type: 'origin' | 'destination';
}

type ModalConfig = { index?: number; type: 'create' | 'update'; values?: PortChargePayload };
type DeleteConfig = { index?: number; isVisible: boolean };

const PortChargeSection: FC<Props> = (props) => {
  const {
    filters,
    onAddPortCharges,
    onDisable,
    portCharges,
    title,
    onUpdate,
    ports,
    type,
    onAddCustomPortCharge,
    onDelete,
    onRemoveCustomPrice
  } = props;
  const { data, isLoading } = usePortCharges({ portChargeFilters: filters });
  const [config, setConfig] = useState<ModalConfig>();
  const [deleteConfig, setDeleteConfig] = useState<DeleteConfig>();

  useEffect(() => {
    if (data && !isLoading) {
      const charges: PortChargePayload[] = preparePortChargePayloads(data);

      onAddPortCharges?.(charges);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  const closeModal = (): void => setConfig(undefined);

  const handleUpdateClick = (index: number, values: PortChargePayload): void => {
    setConfig({ type: 'update', index, values });
  };

  const handleAddClick = (): void => {
    setConfig({ type: 'create' });
  };

  const handleSubmit = (value: PortChargePayload): void => {
    if (config?.type === 'create') {
      onAddCustomPortCharge?.(value);
    }

    if (config?.index !== undefined && config?.values) {
      onUpdate?.(config.index, value);
    }
  };

  const openDeleteConfirmation = (index: number): void => {
    setDeleteConfig({ index, isVisible: true });
  };

  const closeDeleteConfirmation = (): void => {
    setDeleteConfig({ isVisible: false });
  };

  const handleDelete = (): void => {
    if (deleteConfig?.index !== undefined) {
      onDelete?.(deleteConfig.index);
    }

    closeDeleteConfirmation();
  };

  return (
    <>
      <PortChargeTable
        editable={true}
        onAdd={handleAddClick}
        onDelete={openDeleteConfirmation}
        onDisable={onDisable}
        onRemoveCustomPrice={onRemoveCustomPrice}
        onUpdate={handleUpdateClick}
        portCharges={portCharges}
        title={title}
        type={type}
      />
      <PortChargeForm
        initialValues={config?.values}
        isOpen={!!config}
        onCancel={closeModal}
        onClose={closeModal}
        onSubmit={handleSubmit}
        onSuccess={closeModal}
        portCharges={portCharges}
        ports={ports}
        type={type}
      />
      <Dialog
        confirmLabel="Delete"
        header="Delete charge?"
        isOpen={!!deleteConfig?.isVisible}
        message="This charge will be removed from the rate card."
        onClose={closeDeleteConfirmation}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default PortChargeSection;
