import cx from 'classnames';
import type { FC } from 'react';

import ProgressStatusIcon from '@zen/Components/Icons/ProgressStatusIcon';
import type { OrderLotBooking, ProgressStatus } from '@zen/Orders/types';
import { ConfidenceLevel, StageValue, Status } from '@zen/Orders/types';
import { formatDate } from '@zen/utils/dateTime';
import type { Nullable } from '@zen/utils/typescript';

const getCurrentStageIndex = (stages: ProgressStatus[]) => stages.findIndex((stage) => stage.currentStage);
const isEstimatedDate = (confidenceLevel: ConfidenceLevel) => confidenceLevel === ConfidenceLevel.ESTIMATED;
const isOnRoute = (booking: OrderLotBooking) => booking?.stage?.value === StageValue.ON_ROUTE_TO_FINAL_DESTINATION;
const isReadyStage = (currentStageIndex: number) => currentStageIndex === 1;
const isArrivedStage = (currentStageIndex: number) => currentStageIndex === 3;
const isDeliveredStage = (currentStageIndex: number) => currentStageIndex === 4;

interface Props {
  booking: OrderLotBooking;
  className?: string;
  stages: ProgressStatus[];
}

const Timeline: FC<Props> = ({ booking, stages, className }) => {
  const getProgressBarClassName = (index: number) => {
    const stageIndex = getCurrentStageIndex(stages);

    const isHalf = () => {
      if (!booking || isOnRoute(booking)) {
        return false;
      }

      return isReadyStage(stageIndex) || isArrivedStage(stageIndex) || isDeliveredStage(stageIndex);
    };

    return cx({
      'h-2 z-10 bg-azure-base': index <= stageIndex,
      'rounded-r-full': index === stageIndex,
      'w-[55%]': index === stageIndex && isHalf(),
      'rounded-l-full': index === 0
    });
  };

  const renderDate = ({ confidenceLevel, date }: { confidenceLevel?: Nullable<ConfidenceLevel>; date?: Nullable<string> }) => {
    const confidenceClassName = cx({
      italic: confidenceLevel === ConfidenceLevel.ESTIMATED,
      'font-bold': confidenceLevel === ConfidenceLevel.ACTUAL
    });

    return (
      <p className={confidenceClassName}>
        {confidenceLevel && isEstimatedDate(confidenceLevel) && 'est. '}
        {date && formatDate(date)}
      </p>
    );
  };

  const renderStages = (stage: ProgressStatus, index: number, { length }: ProgressStatus[]) => {
    const { name, statusType, date, label } = stage;

    const labelClassName = cx(
      'text-sm',
      { 'text-red-base font-bold': statusType === Status.ALERT },
      { 'text-red-base font-bold': statusType === Status.PROBLEM },
      { 'text-orange-base font-bold': statusType === Status.WARNING }
    );

    const centerLineClassName = cx(
      'my-8 mx-0 relative w-full h-2 bg-azure-lighter',
      { 'w-9/12 ml-[25%] rounded-l-full': index === 0 },
      { 'w-9/12 rounded-r-full': index === length - 1 }
    );

    return (
      <div key={index} className="flex-1 text-center">
        <p className="text-grey-dark text-base mb-2">{name}</p>
        <div>{date?.date ? renderDate(date) : '...'}</div>
        <div className="relative">
          <div className={centerLineClassName}>
            <div className={getProgressBarClassName(index)} />
          </div>
          <div className="absolute w-full z-10 justify-around flex -top-3">
            {statusType && <ProgressStatusIcon type={statusType} />}
          </div>
        </div>
        <p className={labelClassName}>{label}</p>
      </div>
    );
  };

  return (
    <div className={cx('flex flex-1', className)} data-testid="timeline">
      {stages.map(renderStages)}
    </div>
  );
};

export default Timeline;
