import type { FC } from 'react';

import Page from '@zen/Components/Page';
import { rateCardRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { useRateCardDetails } from '../RateCardDetails/hooks';
import type { RateCard } from '../RateCardDetails/types';
import RateCardForm from '../RateCardForm';
import { getInitialValues, prepareRateCardInput } from '../RateCardForm/helpers';
import type { CreateRateCardInput } from '../RateCardForm/types';
import type { RateCardReducerState } from '../reducer';
import type { RateCardCreateMutationResult } from './graphql';
import { useRateCardCreateMutation } from './graphql';

interface Props {}

const NewRateCard: FC<Props> = () => {
  const { accountUuid: accountId, userProfile } = useAccount();
  const { addError, addSuccess } = useNotification();
  const { navigate } = useNavigationHistory();
  const [createRateCard, { loading }] = useRateCardCreateMutation();
  const { data } = useRateCardDetails();

  const { registeredCompanyName, uuid: currentUserId } = userProfile;

  const defaultBackUrl: string = rateCardRoutes.index.getUrl();

  const handleSuccess = (id: string): void => {
    const successMessage: string = data?.getRateCard?.rateCard.rateCardId
      ? 'Rate card has been successfully cloned'
      : 'Rate card has been successfully created';

    addSuccess(successMessage);
    navigate(rateCardRoutes.details.getUrl(id));
  };

  const handleSubmit = async (values: RateCardReducerState): Promise<void> => {
    const { cargoType, modeOfTransport } = values;

    const rateCardInput: CreateRateCardInput = {
      ...prepareRateCardInput(values, registeredCompanyName),
      cargoType,
      createdBy: userProfile.uuid,
      modeOfTransport
    };

    const result: IOkOrErrorResult<RateCardCreateMutationResult> = await performMutation({
      mutationFn: () => createRateCard({ variables: { input: rateCardInput } }),
      onError: () => addError()
    });

    if (result.ok?.createRateCard?.rateCardId) {
      const id: string = result.ok.createRateCard?.rateCardId;

      handleSuccess(id);
    }
  };

  const rateCardData: Nullable<RateCard> = data?.getRateCard?.rateCard
    ? { ...data?.getRateCard?.rateCard, endDate: '', startDate: '' }
    : null;

  return (
    <Page defaultBackUrl={defaultBackUrl} title="New rate card">
      <RateCardForm
        initialValues={getInitialValues(accountId, currentUserId, rateCardData)}
        isLoading={loading}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};

export default NewRateCard;
