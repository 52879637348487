import type { FunctionComponent, SVGProps } from 'react';

import type { Error as GraphQLError } from '@zen/graphql/types.generated';
import type { IconName } from '@zen/Styleguide';
import type { DivisionQueryResult } from '@zen/utils/hooks/graphql';
import type { DeepNonNullable, Undefinable } from '@zen/utils/typescript';

export interface Location<T = object> {
  hash: string;
  key?: Undefinable<string>;
  pathname: string;
  search: string;
  state: T;
}

export interface LocationDescriptorObject<T = object> {
  pathname: string;
  search?: Undefinable<string>;
  state?: Undefinable<T>;
}

export type LocationDescriptor<T = object> = string | LocationDescriptorObject<T>;

export type {
  ControlTower,
  CountryCode,
  DateRangeInput,
  Error,
  Money,
  RichDateTime,
  SortInput,
  Terminal
} from '@zen/graphql/types.generated';
export {
  CargoItemSubTypeEnum,
  CargoItemTypeEnum,
  CargoModeEnum,
  ContainerTypeEnum,
  Currency,
  IncotermsValue,
  LooseCargoTypeEnum,
  ModeOfTransport,
  PalletTypeEnum,
  Shipment,
  SortingOrder,
  TerminalTypeValue,
  TradeRole,
  VehicleTypeEnum
} from '@zen/graphql/types.generated';

export type ServerError = {} | GraphQLError | GraphQLError[];

export enum ProductTabs {
  ANALYTICS = 'analytics',
  DETAILS = 'details',
  PO = 'po',
  WEEKLY = 'weekly'
}

export interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNext: () => void;
  onPrevious: () => void;
}

export interface BooleanMap {
  [key: string]: boolean;
}

export type ButtonClick = React.MouseEvent<HTMLButtonElement, MouseEvent>;

export type IconType = IconName | React.ReactElement;

export * from './labels';

export enum PageSize {
  TEN = 10,
  TWENTY = 20,
  FIFTY = 50
}

export enum Breakpoint {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  XXL = 1536,
  XXXL = 1920
}

export type Division = DeepNonNullable<DivisionQueryResult, 'division'>['legacyAccount']['division'];

export const ErrorsTrackingCategory = 'Errors';

export enum ErrorsTrackingAction {
  GRAPHQL_MUTATION_ERROR = 'GraphQLMutationError',
  GRAPHQL_QUERY_ERROR = 'GraphQLQueryError',
  JAVASCRIPT_ERROR = 'JavascriptError'
}

export type SVGComponent = FunctionComponent<SVGProps<SVGSVGElement>>;

export enum Env {
  DEV = 'development',
  PROD = 'production',
  TEST = 'test'
}
