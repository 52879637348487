import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useCreateWebhookMutation } from '../graphql';
import type { Webhook } from '../types';
import WebhookForm from '../WebhookForm';

const initialValues: Webhook = {
  businessUnit: '',
  callbackUrl: '',
  contactEmail: '',
  isDisabled: false,
  headers: [],
  id: '',
  name: '',
  topics: []
};

interface Props {
  accountHasBusinessUnits: boolean;
  accountId: string;
  onClose: () => void;
}

const CreateWebhook: FC<Props> = ({ accountHasBusinessUnits, accountId, onClose }) => {
  const { addError, addSuccess } = useNotification();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [createWebhook] = useCreateWebhookMutation();

  const handleSubmit = async (values: Webhook): Promise<IOkOrErrorResult> => {
    const { businessUnit, topics, callbackUrl, contactEmail, name, headers } = values;

    return performFormMutation({
      mutationFn: () =>
        createWebhook({
          refetchQueries: ['getWebhooks'],
          awaitRefetchQueries: true,
          variables: {
            input: {
              customerAccountId: accountHasBusinessUnits ? businessUnit || '' : accountId,
              topics,
              webhookInput: {
                name,
                callbackUrl,
                contactEmail,
                headers
              }
            }
          }
        }),
      onError: () => addError()
    });
  };

  const handleSuccess = (data: { signToken: string }): void => {
    addSuccess('Webhook has been created.');

    navigate(pathname, {
      state: {
        secretToken: data.signToken
      }
    });

    onClose();
  };

  return (
    <WebhookForm
      accountId={accountId}
      buttonText="Create Webhook"
      initialValues={initialValues}
      isBusinessUnitFieldVisible={accountHasBusinessUnits}
      onCancel={onClose}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
    />
  );
};

export default CreateWebhook;
