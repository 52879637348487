import type { FC, PropsWithChildren } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { Height } from 'react-animate-height';
import AnimateHeight from 'react-animate-height';

interface Props {
  animateOpacity?: boolean;
  className?: string;
  collapsedHeight?: Height;
  delay?: number;
  duration?: number;
  isOpened: boolean;
}

const CollapsibleElement: FC<PropsWithChildren<Props>> = ({
  animateOpacity = true,
  isOpened,
  children,
  delay = 0,
  duration = 300,
  className,
  collapsedHeight = 0
}) => {
  const [renderChildren, setRenderChildren] = useState<boolean>(isOpened);
  const timeoutRef = useRef<number>(0);

  const visibleByDefault: boolean = parseInt(String(collapsedHeight), 10) > 0;
  const openedHeight: Height = renderChildren ? 'auto' : collapsedHeight;
  const height: Height = isOpened ? openedHeight : collapsedHeight;
  const shouldRenderChildren: boolean = visibleByDefault || renderChildren;

  const openElement = (): void => setRenderChildren(true);

  const closeElement = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => setRenderChildren(false), delay + duration);
  }, [setRenderChildren, delay, duration]);

  const toggleChildren = isOpened ? openElement : closeElement;

  useEffect(() => {
    toggleChildren();

    return () => {
      if (timeoutRef?.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isOpened, toggleChildren, closeElement]);

  return (
    <AnimateHeight
      animateOpacity={animateOpacity}
      className={className}
      data-testid="collapsible-element"
      delay={delay}
      duration={duration}
      height={height}
    >
      {shouldRenderChildren && children}
    </AnimateHeight>
  );
};

export type { Props as CollapsibleElementProps };
export default CollapsibleElement;
