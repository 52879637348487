import { isEqual } from 'lodash';
import { type ReactNode, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import type { FilterOptionsType } from '@zen/Components/Filters/types';
import { getAllOptions, type Option, Popover } from '@zen/DesignSystem';
import type { ArrayElement, Nullable } from '@zen/utils/typescript';

import type { FilterComponentProps } from '../../types';
import { buildOptionListSections } from '../helpers';
import OptionList from '../OptionList';
import type { OptionListSection } from '../OptionList/types';
import FilterMultiSelectLabel from './FilterMultiSelectLabel';
import { usePrioritisedValues } from './hooks';

type FilterMultiSelectProps<T> = {
  className?: string;
  loading?: boolean;
  onSearch?: (query: string) => void;
  options: FilterOptionsType<ArrayElement<T>>;
  searchPlaceholder?: string;
  selectAllLabel?: string;
  selectAllOption?: boolean;
};

type Props<T> = {
  onChange: (value: Nullable<ArrayElement<T>[]>) => void;
  value: Nullable<ArrayElement<T>[]>;
} & FilterComponentProps &
  FilterMultiSelectProps<T>;

const FilterMultiSelect = <T,>({
  active = false,
  className = '',
  children,
  deselectFilter,
  disabled,
  onChange,
  onDelete,
  onSearch,
  options,
  loading,
  placement,
  searchPlaceholder,
  selectAllLabel,
  selectAllOption,
  value
}: Props<T>) => {
  const [prioritisedValues, setPrioritisedValues] = usePrioritisedValues(value);
  const [selectedOptions, setSelectedOptions] = useState<Option<ArrayElement<T>>[]>([]);
  const selectedValues = selectedOptions.map((selectedOption) => selectedOption.value);
  const allOptions = getAllOptions(options);

  useDeepCompareEffect(() => {
    if (value) {
      const initialOptions: Option<ArrayElement<T>>[] = allOptions.filter(
        (option) => !!value.find((selectedValue) => isEqual(option.value, selectedValue))
      );

      setSelectedOptions(() => {
        return initialOptions;
      });
    }
  }, [options, value]);

  const deselectOption = (option: Option<ArrayElement<T>>): void => {
    const updatedOptions = selectedOptions.filter((selectedOption) => !isEqual(selectedOption.value, option.value));

    handleOptionsChange(updatedOptions);
  };

  const selectOption = (option: Option<ArrayElement<T>>): void => {
    const updatedOptions = [option, ...selectedOptions];

    handleOptionsChange(updatedOptions);
  };

  const handleSelect = (option: Option<ArrayElement<T>>): void => {
    if (isSelected(option)) {
      deselectOption(option);
    } else {
      selectOption(option);
    }
  };

  const handleOptionsChange = (values: Option<ArrayElement<T>>[]) => {
    setSelectedOptions(values);
    onChange(values.map((option) => option.value));
  };

  const handleClear = (): void => {
    handleOptionsChange([]);
  };

  const isSelected = (option: Option<ArrayElement<T>>): boolean => {
    return !!selectedValues.find((selectedValue) => isEqual(option.value, selectedValue));
  };

  const isPrioritised = (option: Option<ArrayElement<T>>): boolean => {
    return !!prioritisedValues.find((prioritisedValue) => isEqual(option.value, prioritisedValue));
  };

  const buildLabel = (): ReactNode => {
    const labels: string[] = selectedOptions.map((option) => option.label);

    if (!labels.length) {
      return null;
    }

    return <FilterMultiSelectLabel labels={labels} />;
  };

  const popover = (): ReactNode => {
    const list: OptionListSection[] = buildOptionListSections(options, {
      isSelected,
      onClick: handleSelect,
      isPrioritised
    });

    const isAllOptionsSelected: boolean = isEqual(selectedOptions, allOptions);

    const handleSelectAll = (): void => {
      const values: Option<ArrayElement<T>>[] = isAllOptionsSelected ? [] : allOptions;

      handleOptionsChange(values);
    };

    const selectAllItem: OptionListSection[] = selectAllOption
      ? [
          {
            items: [
              {
                isSelected: isAllOptionsSelected,
                label: selectAllLabel || 'Select all',
                onClick: handleSelectAll
              }
            ]
          }
        ]
      : [];

    return (
      <div data-testid="filter-multi-select-popover">
        <OptionList
          list={[...selectAllItem, ...list]}
          loading={loading}
          onClear={handleClear}
          onSearch={onSearch}
          searchable={true}
          searchPlaceholder={searchPlaceholder}
          selection="checkbox"
        />
      </div>
    );
  };

  const handleVisibilityChange = (isVisible: boolean): void => {
    if (!isVisible && !selectedValues.length) {
      onDelete();
    }

    if (!isVisible) {
      deselectFilter();
      setPrioritisedValues(selectedValues);
    }
  };

  return (
    <Popover
      disabled={disabled}
      isVisible={active}
      onVisibilityChange={handleVisibilityChange}
      placement={placement}
      popover={popover}
      popoverClassNames="border border-solid border-grey-lighter"
      renderInPortal={true}
      triggerClassName={className}
    >
      {children(buildLabel())}
    </Popover>
  );
};

export default FilterMultiSelect;
export type { FilterMultiSelectProps };
