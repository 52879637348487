import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ResetPriceCalculationDateMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ResetPriceCalculationDateInput;
}>;

export type ResetPriceCalculationDateMutationResult = { __typename?: 'Mutation' } & {
  resetPriceCalculationDate?: GraphQLTypes.Maybe<
    { __typename?: 'ResetPriceCalculationDatePayload' } & {
      errors?: GraphQLTypes.Maybe<
        Array<GraphQLTypes.Maybe<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>
      >;
    }
  >;
};

export const ResetPriceCalculationDateDocument = /* #__PURE__ */ gql`
  mutation resetPriceCalculationDate($input: ResetPriceCalculationDateInput!) {
    resetPriceCalculationDate(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useResetPriceCalculationDateMutation__
 *
 * To run a mutation, you first call `useResetPriceCalculationDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPriceCalculationDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPriceCalculationDateMutation, { data, loading, error }] = useResetPriceCalculationDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPriceCalculationDateMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPriceCalculationDateMutationResult, ResetPriceCalculationDateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ResetPriceCalculationDateMutationResult, ResetPriceCalculationDateMutationVariables>(
    ResetPriceCalculationDateDocument,
    options
  );
}
export type ResetPriceCalculationDateMutationHookResult = ReturnType<typeof useResetPriceCalculationDateMutation>;
