import moment from 'moment';

import { Role } from '@zen/Auth/role';
import { toPermissionKey } from '@zen/Components/RolePermissions/helpers';
import type { PillType } from '@zen/DesignSystem';
import type { IssueFlatData, IssueTemplateFormData, IssueVisibility, LabelableIssue } from '@zen/Issues/types';
import { IssueCategory, IssueSeverity, IssueState } from '@zen/Issues/types';
import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';

type CategoryToString = Record<IssueCategory, string>;
type SeverityToPillType = Record<IssueSeverity, PillType>;
type SeverityToString = Record<IssueSeverity, string>;
type StateToPillType = Record<IssueState, PillType>;
type StateToString = Record<IssueState, string>;

export const categoryLabelMapping: CategoryToString = {
  [IssueCategory.AGENT]: 'Agent',
  [IssueCategory.CARRIER]: 'Carrier',
  [IssueCategory.CUSTOMER]: 'Customer',
  [IssueCategory.CUSTOMS]: 'Customs',
  [IssueCategory.HAULIER]: 'Haulier',
  [IssueCategory.MANUFACTURER]: 'Manufacturer',
  [IssueCategory.OTHER]: 'Unnamed',
  [IssueCategory.PORT]: 'Port',
  [IssueCategory.ZENCARGO]: 'Zencargo'
};

export const severityLabelMapping: SeverityToString = {
  [IssueSeverity.HIGH]: 'Severe',
  [IssueSeverity.MEDIUM]: 'Moderate',
  [IssueSeverity.LOW]: 'Minor'
};

export const variantPillMapping: SeverityToPillType = {
  [IssueSeverity.HIGH]: 'problem',
  [IssueSeverity.MEDIUM]: 'warning',
  [IssueSeverity.LOW]: 'default'
};

export const stateLabelMapping: StateToString = {
  [IssueState.OPEN]: 'Open',
  [IssueState.STARTED]: 'Investigating',
  [IssueState.RESOLVED]: 'Resolved',
  [IssueState.ARCHIVED]: 'Archived'
};

export const statePillMapping: StateToPillType = {
  [IssueState.OPEN]: 'problem',
  [IssueState.STARTED]: 'warning',
  [IssueState.RESOLVED]: 'pending',
  [IssueState.ARCHIVED]: 'pending'
};

export const buildIssueLabel = (issue: LabelableIssue): string => {
  return issue.title || `${severityLabelMapping[issue.severity]} ${categoryLabelMapping[issue.category].toLowerCase()} issue`;
};

const buildDefaultVisibility = (currentRole?: Role): IssueVisibility => {
  const visibility: IssueVisibility = {
    manufacturer: false,
    agentForwarder: false,
    customerUser: false
  };

  // skip if no role known or admin, as admin key does not exist in visibility hash
  if (!currentRole || currentRole === Role.ADMIN) {
    return visibility;
  }
  // otherwise set true for current role,
  // so the creator can see back the issue they created
  const permissionKey = toPermissionKey(currentRole);

  return {
    ...visibility,
    [permissionKey]: true
  };
};

export const createNewIssueData = (currentRole?: Role): IssueFlatData => ({
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'IssueCatego... Remove this comment to see the full error message
  category: null,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'IssueSeveri... Remove this comment to see the full error message
  severity: null,
  state: IssueState.OPEN,
  occurredOn: moment().format(FORMAT_DATE_TRANSFERABLE),
  problemDescription: '',
  solutionDescription: '',
  visibility: buildDefaultVisibility(currentRole)
});

export const createNewIssueTemplateData = (initialData?: Partial<IssueTemplateFormData>): IssueTemplateFormData => ({
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
  ruleId: null,
  active: true,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'IssueCatego... Remove this comment to see the full error message
  category: null,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'IssueSeveri... Remove this comment to see the full error message
  severity: null,
  problemDescription: '',
  visibility: {
    manufacturer: false,
    agentForwarder: false,
    customerUser: false
  },
  title: '',
  ...initialData
});

export const getActiveIssues = <T extends { state: IssueState }>(issues: T[]): T[] => {
  return issues.filter(({ state }) => {
    return state === IssueState.OPEN || state === IssueState.STARTED;
  });
};
