import type { ObjectSchema } from 'yup';
import { object, string } from 'yup';

import { getChargeItemsValidator } from '@zen/RateCards/FreightCharges/FreightChargeForm/freightCharge.validation';
import type { PortFieldKey } from '@zen/RateCards/HaulageCharges/HaulageChargeForm/types';

export const getValidationSchema = (locationKey: PortFieldKey): ObjectSchema<{}> =>
  object().shape({
    chargeItems: getChargeItemsValidator(),
    [locationKey]: string().nullable().required('Please select a port.')
  });
