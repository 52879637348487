import { LocationLink } from '../../components/CompanyNameFields/helpers';
import type { LocationDetailsFormValues, UpdateAccountLocationDetailsInput } from './types';

interface Params {
  accountName: string;
  formValues: LocationDetailsFormValues;
  locationId: string;
}

const prepareValues = (params: Params): UpdateAccountLocationDetailsInput => {
  const { accountName, formValues, locationId } = params;
  const { companyName, linkType, name, usage } = formValues;

  return {
    companyName: linkType === LocationLink.MY_COMPANY ? accountName : companyName,
    locationId,
    name,
    usageContext: usage
  };
};

export { prepareValues };
