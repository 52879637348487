import type { FC, ReactNode } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import { Form, FormButtons, FormInput } from '@zen/Components/Form';
import FormHeadline from '@zen/Components/Form/FormHeadline';
import type { Option } from '@zen/DesignSystem';
import { Button } from '@zen/DesignSystem';
import type { OrganisationTypeValue } from '@zen/Networks/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { DeepNullable } from '@zen/utils/typescript';

import type { IOrganisationFormResponse } from '../EditOrganisationForm/EditOrganisationForm';
import FormAddressFields from '../FormAddressFields';
import OrganisationTypeSelect from '../OragnisationTypeSelect';
import { validationSchema } from './organisationForm.validation';
import type { IOrganisationFormFields } from './types';

interface Props {
  initialValues: DeepNullable<IOrganisationFormFields>;
  onCancel: () => void;
  onSubmit: (values: IOrganisationFormFields) => Promise<IOkOrErrorResult>;
  onSuccess: (values: IOrganisationFormResponse) => void;
  organisationTypes: Option<OrganisationTypeValue>[];
}

const OrganisationForm: FC<Props> = (props) => {
  const { initialValues, organisationTypes, onCancel, onSubmit, onSuccess } = props;

  const renderFormButtons = ({ isSubmitting }: FormInstance<IOrganisationFormFields>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting}>
      <Button onClick={onCancel} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <div className="w-3/4">
      <Form
        className="mt-4"
        formButtons={renderFormButtons}
        formName="OrganisationForm"
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        validationSchema={validationSchema}
      >
        {() => (
          <>
            <div className="mb-16">
              <FormInput isRequired={true} label="Organisation name" name="name" />
              <OrganisationTypeSelect options={organisationTypes} />
            </div>

            <FormHeadline
              tagline="Please specify where your organisation is based.
              This information will be used in bills of lading and other documents."
              text="Address"
            />

            <FormAddressFields showLatLngFields={true} />
          </>
        )}
      </Form>
    </div>
  );
};

export default OrganisationForm;
