import { ConnectionSourceTargetEnum } from '../../types';
import type { AccountFilterItem } from './types';

const buildFilters = (): AccountFilterItem[] => {
  return [
    {
      title: 'Type',
      key: 'connectionSourceTarget',
      props: {
        options: [
          { value: ConnectionSourceTargetEnum.SOURCE, label: 'Customer' },
          { value: ConnectionSourceTargetEnum.TARGET, label: 'Supplier' },
          { value: ConnectionSourceTargetEnum.BOTH, label: 'Both' }
        ]
      },
      componentType: 'select'
    }
  ];
};

export { buildFilters };
